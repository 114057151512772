import { commitMutation, graphql } from 'react-relay';
const mutation = graphql `
	mutation RecalcInvoiceAmountMutation($id: String!) {
		recalcInvoiceAmount(id: $id) {
			id
			amount
			priceReservedDueDateTime
			state
			transactions {
				id
				amount
			}
		}
	}
`;
const commit = (params) => commitMutation(params.environment, {
    mutation,
    variables: params.variables,
    onCompleted: params.onCompleted,
    onError: params.onError
});
export default { commit };
