import React from 'react';
import styles from './PasswordHints.module.scss';
import t from './i18n';
const PasswordHints = (props) => {
    const { lowerCase, upperCase, digit, length } = props;
    return (React.createElement("aside", { className: styles.container },
        React.createElement("ul", { className: styles.list },
            React.createElement("li", { className: lowerCase ? 'valid' : 'list-item' }, t.oneLowerCaseCharacter),
            React.createElement("li", { className: upperCase ? 'valid' : 'list-item' }, t.oneUpperCaseCharacter),
            React.createElement("li", { className: digit ? 'valid' : 'list-item' }, t.oneNumber),
            React.createElement("li", { className: length ? 'valid' : 'list-item' }, t.eightCharactersMinimun))));
};
export default PasswordHints;
