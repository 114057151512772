import React from 'react';
import { Icon } from '../../../components/Icon';
import StepLabel from './StepLabel';
import styles from './CheckoutHeader.module.scss';
import t from './i18n';
const CheckoutHeader = ({ currentStep, isReadyToNext, onChangeStep }) => (React.createElement("div", { className: styles.stepperContainer },
    React.createElement(StepLabel, { step: 1, text: t.deliveryInfo, currentStep: currentStep, isReadyToNext: isReadyToNext, onChangeStep: onChangeStep }),
    React.createElement(Icon, { type: "arrowRight" }),
    React.createElement(StepLabel, { step: 2, text: t.summary, currentStep: currentStep, isReadyToNext: isReadyToNext, onChangeStep: onChangeStep }),
    React.createElement(Icon, { type: "arrowRight" }),
    React.createElement(StepLabel, { step: 3, text: t.paymentInfo, currentStep: currentStep, isReadyToNext: isReadyToNext, onChangeStep: onChangeStep })));
export default CheckoutHeader;
