import { graphql, commitMutation } from 'react-relay';
const mutation = graphql `
    mutation SetCouponInCartMutation($input: SetCouponInCartInput!) {
        setCouponInCart(input: $input) {
            id
            productsCost
            deliveryCost
            totalCount
            totalCost
            totalCostWithoutDiscounts
            couponsDiscounts
            stores {
                edges {
                    node {
                        id
                        rawId
                        productsCost
                        deliveryCost
                        totalCost
                        totalCount
                        ...CartStore_store
                    }
                }
            }
        }
    }
`;
const commit = (params) => commitMutation(params.environment, {
    mutation,
    variables: params.variables,
    onCompleted: params.onCompleted,
    onError: params.onError
});
export default { commit };
