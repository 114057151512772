/**
 * @generated SignedSource<<bd52e7589a73ca61ca8fe3f326dc076d>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawId",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "concreteType": "Translation",
        "kind": "LinkedField",
        "name": "name",
        "plural": true,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lang",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "price",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "after"
            },
            {
                "defaultValue": "",
                "kind": "LocalArgument",
                "name": "autocompleteValue"
            },
            {
                "defaultValue": 8,
                "kind": "LocalArgument",
                "name": "first"
            },
            {
                "defaultValue": {
                    "name": ""
                },
                "kind": "LocalArgument",
                "name": "searchTerm"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                {
                    "count": "first",
                    "cursor": "after",
                    "direction": "forward",
                    "path": [
                        "findProduct"
                    ]
                }
            ]
        },
        "name": "StoreItems_shop",
        "selections": [
            {
                "alias": "findProduct",
                "args": [
                    {
                        "kind": "Variable",
                        "name": "searchTerm",
                        "variableName": "searchTerm"
                    },
                    {
                        "kind": "Literal",
                        "name": "visibility",
                        "value": "active"
                    }
                ],
                "concreteType": "BaseProductsSearchConnection",
                "kind": "LinkedField",
                "name": "__StoreItems_findProduct_connection",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "BaseProductsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "BaseProduct",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v0 /*: any*/),
                                    (v1 /*: any*/),
                                    (v2 /*: any*/),
                                    (v3 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Store",
                                        "kind": "LinkedField",
                                        "name": "store",
                                        "plural": false,
                                        "selections": [
                                            (v3 /*: any*/)
                                        ],
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Category",
                                        "kind": "LinkedField",
                                        "name": "category",
                                        "plural": false,
                                        "selections": [
                                            (v1 /*: any*/)
                                        ],
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "storeId",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": [
                                            {
                                                "kind": "Literal",
                                                "name": "first",
                                                "value": 1
                                            }
                                        ],
                                        "concreteType": "ProductsConnection",
                                        "kind": "LinkedField",
                                        "name": "products",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "ProductsEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "Product",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                            (v0 /*: any*/),
                                                            (v1 /*: any*/),
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "kind": "ScalarField",
                                                                "name": "discount",
                                                                "storageKey": null
                                                            },
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "kind": "ScalarField",
                                                                "name": "photoMain",
                                                                "storageKey": null
                                                            },
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "kind": "ScalarField",
                                                                "name": "cashback",
                                                                "storageKey": null
                                                            },
                                                            (v4 /*: any*/),
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "concreteType": "CustomerPrice",
                                                                "kind": "LinkedField",
                                                                "name": "customerPrice",
                                                                "plural": false,
                                                                "selections": [
                                                                    (v4 /*: any*/),
                                                                    (v2 /*: any*/)
                                                                ],
                                                                "storageKey": null
                                                            },
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "concreteType": "ProdAttrValue",
                                                                "kind": "LinkedField",
                                                                "name": "attributes",
                                                                "plural": true,
                                                                "selections": [
                                                                    {
                                                                        "alias": null,
                                                                        "args": null,
                                                                        "concreteType": "Attribute",
                                                                        "kind": "LinkedField",
                                                                        "name": "attribute",
                                                                        "plural": false,
                                                                        "selections": [
                                                                            (v0 /*: any*/)
                                                                        ],
                                                                        "storageKey": null
                                                                    },
                                                                    {
                                                                        "alias": null,
                                                                        "args": null,
                                                                        "kind": "ScalarField",
                                                                        "name": "value",
                                                                        "storageKey": null
                                                                    }
                                                                ],
                                                                "storageKey": null
                                                            }
                                                        ],
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": "products(first:1)"
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "rating",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__typename",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfoProductsSearch",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Literal",
                        "name": "first",
                        "value": 8
                    },
                    {
                        "kind": "Variable",
                        "name": "name",
                        "variableName": "autocompleteValue"
                    }
                ],
                "concreteType": "FullNameConnection",
                "kind": "LinkedField",
                "name": "autoCompleteProductName",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "FullNameEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "node",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Store",
        "abstractKey": null
    };
})();
node.hash = "81bac2655bd61a80c7afb4f1051ee15a";
export default node;
