/**
 * @generated SignedSource<<36a4242c06b86ffe652c1427d98aba42>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "productsCost",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deliveryCost",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCost",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCostWithoutDiscounts",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawId",
        "storageKey": null
    }, v9 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lang",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "text",
            "storageKey": null
        }
    ], v10 = {
        "alias": null,
        "args": null,
        "concreteType": "Translation",
        "kind": "LinkedField",
        "name": "name",
        "plural": true,
        "selections": (v9 /*: any*/),
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "DeleteFromCartMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Cart",
                    "kind": "LinkedField",
                    "name": "deleteFromCart",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "Cart_cart"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "DeleteFromCartMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Cart",
                    "kind": "LinkedField",
                    "name": "deleteFromCart",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Cart",
                            "kind": "LinkedField",
                            "name": "fiat",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                (v3 /*: any*/),
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "productsCostWithoutDiscounts",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "couponsDiscounts",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CartStoresConnection",
                                    "kind": "LinkedField",
                                    "name": "stores",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "CartStoresEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "CartStore",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v2 /*: any*/),
                                                        (v8 /*: any*/),
                                                        (v4 /*: any*/),
                                                        (v5 /*: any*/),
                                                        (v6 /*: any*/),
                                                        (v3 /*: any*/),
                                                        (v7 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "couponsDiscount",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "CartProduct",
                                                            "kind": "LinkedField",
                                                            "name": "products",
                                                            "plural": true,
                                                            "selections": [
                                                                (v2 /*: any*/),
                                                                (v8 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "selected",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": [
                                                                        {
                                                                            "kind": "Literal",
                                                                            "name": "visibility",
                                                                            "value": "active"
                                                                        }
                                                                    ],
                                                                    "concreteType": "BaseProduct",
                                                                    "kind": "LinkedField",
                                                                    "name": "baseProduct",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v2 /*: any*/),
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "isShippingAvailable",
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "storageKey": "baseProduct(visibility:\"active\")"
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "baseProductId",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "subtotal",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "subtotalWithoutDiscounts",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "couponDiscount",
                                                                    "storageKey": null
                                                                },
                                                                (v10 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "photoMain",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "price",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "preOrder",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "preOrderDays",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "quantity",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "comment",
                                                                    "storageKey": null
                                                                },
                                                                (v5 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "ProdAttrValue",
                                                                    "kind": "LinkedField",
                                                                    "name": "attributes",
                                                                    "plural": true,
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "value",
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "metaField",
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "Attribute",
                                                                            "kind": "LinkedField",
                                                                            "name": "attribute",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                (v10 /*: any*/),
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "valueType",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "concreteType": "AttributeMetaField",
                                                                                    "kind": "LinkedField",
                                                                                    "name": "metaField",
                                                                                    "plural": false,
                                                                                    "selections": [
                                                                                        {
                                                                                            "alias": null,
                                                                                            "args": null,
                                                                                            "kind": "ScalarField",
                                                                                            "name": "values",
                                                                                            "storageKey": null
                                                                                        },
                                                                                        {
                                                                                            "alias": null,
                                                                                            "args": null,
                                                                                            "kind": "ScalarField",
                                                                                            "name": "uiElement",
                                                                                            "storageKey": null
                                                                                        },
                                                                                        {
                                                                                            "alias": null,
                                                                                            "args": null,
                                                                                            "concreteType": "TranslatedValue",
                                                                                            "kind": "LinkedField",
                                                                                            "name": "translatedValues",
                                                                                            "plural": true,
                                                                                            "selections": [
                                                                                                {
                                                                                                    "alias": null,
                                                                                                    "args": null,
                                                                                                    "concreteType": "Translation",
                                                                                                    "kind": "LinkedField",
                                                                                                    "name": "translations",
                                                                                                    "plural": true,
                                                                                                    "selections": (v9 /*: any*/),
                                                                                                    "storageKey": null
                                                                                                }
                                                                                            ],
                                                                                            "storageKey": null
                                                                                        }
                                                                                    ],
                                                                                    "storageKey": null
                                                                                },
                                                                                (v2 /*: any*/)
                                                                            ],
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "Coupon",
                                                                    "kind": "LinkedField",
                                                                    "name": "coupon",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v2 /*: any*/),
                                                                        (v8 /*: any*/),
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "code",
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "title",
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "scope",
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "percent",
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "CompaniesPackages",
                                                                    "kind": "LinkedField",
                                                                    "name": "companyPackage",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v2 /*: any*/),
                                                                        (v8 /*: any*/)
                                                                    ],
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "AvailablePackageForUser",
                                                                    "kind": "LinkedField",
                                                                    "name": "selectPackage",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v2 /*: any*/),
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "shippingId",
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        (v10 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "rating",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "logo",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "011131ad12001c5fb525958ec0892d67",
            "id": null,
            "metadata": {},
            "name": "DeleteFromCartMutation",
            "operationKind": "mutation",
            "text": "mutation DeleteFromCartMutation(\n  $input: DeleteFromCartInput!\n) {\n  deleteFromCart(input: $input) {\n    ...Cart_cart\n    id\n  }\n}\n\nfragment CartProduct_product on CartProduct {\n  id\n  rawId\n  baseProduct(visibility: \"active\") {\n    id\n    isShippingAvailable\n  }\n  baseProductId\n  subtotal\n  subtotalWithoutDiscounts\n  couponDiscount\n  name {\n    lang\n    text\n  }\n  photoMain\n  price\n  preOrder\n  preOrderDays\n  quantity\n  comment\n  selected\n  deliveryCost\n  attributes {\n    value\n    metaField\n    attribute {\n      name {\n        lang\n        text\n      }\n      valueType\n      metaField {\n        values\n        uiElement\n        translatedValues {\n          translations {\n            lang\n            text\n          }\n        }\n      }\n      id\n    }\n  }\n  coupon {\n    id\n    rawId\n    code\n    title\n    scope\n    percent\n  }\n  companyPackage {\n    id\n    rawId\n  }\n  selectPackage {\n    id\n    shippingId\n  }\n}\n\nfragment CartStore_store on CartStore {\n  id\n  rawId\n  productsCost\n  deliveryCost\n  totalCost\n  totalCount\n  totalCostWithoutDiscounts\n  couponsDiscount\n  products {\n    id\n    rawId\n    selected\n    ...CartProduct_product\n  }\n  name {\n    lang\n    text\n  }\n  rating\n  logo\n}\n\nfragment Cart_cart on Cart {\n  id\n  totalCount\n  fiat {\n    id\n    productsCost\n    deliveryCost\n    totalCount\n    totalCost\n    totalCostWithoutDiscounts\n    productsCostWithoutDiscounts\n    couponsDiscounts\n    stores {\n      edges {\n        node {\n          id\n          ...CartStore_store\n          productsCost\n          deliveryCost\n          totalCost\n          totalCount\n          products {\n            id\n            selected\n            baseProduct(visibility: \"active\") {\n              id\n              isShippingAvailable\n            }\n            quantity\n          }\n        }\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "8d6dc7042c4ad04d4f487da407e73565";
export default node;
