import React, { Component } from 'react';
import { isEmpty, pathOr, toUpper } from 'ramda';
import { withRouter } from 'found';
import { v4 as uuidv4 } from 'uuid';
import { Environment } from 'relay-runtime';
import PropTypes from 'prop-types';
import { ManageStore } from '../../index';
import { Page } from '../../../../../components/App';
import { debug, error, fromRelayError } from '../../../../../utils';
import { withShowAlert } from '../../../../../components/Alerts/AlertContext';
import { CreateStoreMutation } from '../../../../../relay/mutations';
import Form from '../Form';
import t from './i18n';
class NewStore extends Component {
    static contextTypes = {
        environment: PropTypes.instanceOf(Environment).isRequired,
        directories: PropTypes.object,
        currentUser: PropTypes.shape({
            id: PropTypes.string,
            rawId: PropTypes.number
        }),
        showAlert: PropTypes.func
    };
    constructor(props) {
        super(props);
        this.state = {
            serverValidationErrors: {},
            isLoading: false
        };
    }
    handleSave = ({ form, optionLanguage }) => {
        const { environment, currentUser } = this.context;
        const { newStoreName, newStoreLogo } = this.props;
        if (!newStoreName) {
            this.props.showAlert({
                type: 'danger',
                text: t.somethingGoingWrong,
                link: { text: t.close }
            });
            return;
        }
        const { longDescription, shortDescription, defaultLanguage, slug, slogan } = form;
        this.setState(() => ({
            isLoading: true,
            serverValidationErrors: {}
        }));
        const params = {
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    userId: parseInt(String(currentUser.rawId), 10),
                    name: [{
                            lang: optionLanguage,
                            text: newStoreName
                        }],
                    defaultLanguage: toUpper(defaultLanguage),
                    longDescription: [{
                            lang: optionLanguage,
                            text: longDescription
                        }],
                    shortDescription: [{
                            lang: optionLanguage,
                            text: shortDescription
                        }],
                    slug,
                    slogan,
                    logo: newStoreLogo,
                    addressFull: {}
                }
            },
            environment,
            onCompleted: (response, errors) => {
                debug({
                    response,
                    errors
                });
                this.setState({ isLoading: false });
                const relayErrors = fromRelayError({ source: { errors } });
                debug({ relayErrors });
                const validationErrors = pathOr({}, ['100', 'messages'], relayErrors);
                if (!isEmpty(validationErrors)) {
                    this.setState({ serverValidationErrors: validationErrors });
                    return;
                }
                const statusError = pathOr('', ['100', 'status'], relayErrors);
                if (!isEmpty(statusError)) {
                    this.props.showAlert({
                        type: 'danger',
                        text: `${t.error} "${statusError}"`,
                        link: { text: t.close }
                    });
                    return;
                }
                const parsingError = pathOr(null, ['300', 'message'], relayErrors);
                if (parsingError) {
                    debug('parsingError:', { parsingError });
                    this.props.showAlert({
                        type: 'danger',
                        text: t.somethingGoingWrong,
                        link: { text: t.close }
                    });
                    return;
                }
                const storeId = pathOr(null, ['createStore', 'rawId'], response);
                if (storeId) {
                    this.props.router.push(`/manage/store/${storeId}`);
                }
                this.props.showAlert({
                    type: 'success',
                    text: t.storeCreated,
                    link: { text: '' }
                });
            },
            onError: (err) => {
                error(err);
                this.setState({ isLoading: false });
                this.props.showAlert({
                    type: 'danger',
                    text: t.somethingGoingWrong,
                    link: { text: t.close }
                });
            }
        };
        CreateStoreMutation.commit(params);
    };
    render() {
        const { isLoading, serverValidationErrors } = this.state;
        return (React.createElement(Form, { onSave: this.handleSave, isLoading: isLoading, serverValidationErrors: serverValidationErrors, handleNewStoreNameChange: this.props.handleNewStoreNameChange }));
    }
}
export default withShowAlert(Page(withRouter(ManageStore({
    OriginalComponent: NewStore,
    active: 'settings',
    title: 'Settings'
}))));
