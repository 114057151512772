// import { Match } from 'found';
import { filter, keys, map, assoc, isEmpty } from 'ramda';
export function getQueryRefParams(query) {
    let result = {};
    const referral = query.ref ? parseFloat(query.ref) : null;
    if (referral) {
        result = assoc('referral', referral, result);
    }
    const utmKeys = filter((item) => item.substr(0, 4) === 'utm_', keys(query));
    if (!isEmpty(utmKeys)) {
        const utmMarks = map((item) => ({
            key: item,
            value: query[item]
        }), utmKeys);
        result = assoc('utmMarks', utmMarks, result);
    }
    return result;
}
