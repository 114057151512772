import React from 'react';
import { isNil } from 'ramda';
import styles from './FormWrapper.module.scss';
import { classNames } from '../../../../utils';
const FormWrapper = ({ title, description, children, firstForm = false, secondForm = false, thirdForm = false }) => (React.createElement("div", { className: classNames(styles, 'formWrapper', {
        firstForm: firstForm,
        secondForm: secondForm,
        thirdForm: thirdForm
    }) },
    React.createElement("div", { className: styles.headerTitle }, title),
    React.createElement("div", { className: styles.headerDescription }, description),
    !isNil(children) && children));
export default FormWrapper;
