import React, { PureComponent } from 'react';
import { dissoc } from 'ramda';
import { Icon } from '../../Icon';
import styles from './Button.module.scss';
import { classNames } from '../../../utils';
class Button extends PureComponent {
    static defaultProps = {
        isLoading: false,
        disabled: false,
        href: '',
        contour: false,
        small: false,
        extraSmall: false,
        big: false,
        iconic: false,
        white: false,
        pink: false,
        wireframe: false,
        onClick: () => {
        },
        type: 'button',
        dataTest: 'stqButton',
        add: false,
        target: '_self'
    };
    render() {
        const { type, onClick, onMouseDown, disabled, children, iconic, href, load, contour, big, small, extraSmall, dataTest, white, wireframe, isLoading, pink, fullWidth, add, target } = this.props;
        const props = {
            type,
            disabled,
            onClick,
            onMouseDown,
            href,
            children
        };
        const className = classNames(styles, 'container', {
            iconic,
            disabled,
            load,
            contour,
            big,
            small,
            extraSmall,
            white,
            wireframe,
            isLoading,
            pink,
            fullWidth,
            add,
            transparent: isLoading
        });
        if (href !== '') {
            return (React.createElement("a", { className: className, "data-test": dataTest, target: target, ...props }, children));
        }
        return (React.createElement("button", { className: className, disabled: disabled || isLoading, type: "button", "data-test": dataTest, ...dissoc('href', props) },
            React.createElement("div", { className: styles.spinner }),
            React.createElement("div", { className: styles.children }, children),
            add && (React.createElement("div", { className: styles.plusIcon },
                React.createElement(Icon, { type: "plus", size: 24 })))));
    }
}
export default Button;
