import { isNil } from 'ramda';
export const setRegion = (admin1, admin2) => {
    let administrativeAreaLevel = '';
    if (!isNil(admin1) && !isNil(admin2)) {
        administrativeAreaLevel = `${admin1}, ${admin2}`;
    }
    if (!isNil(admin1) && isNil(admin2)) {
        administrativeAreaLevel = admin1;
    }
    if (isNil(admin1) && !isNil(admin2)) {
        administrativeAreaLevel = admin2;
    }
    return administrativeAreaLevel;
};
