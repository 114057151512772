import React, { Component } from 'react';
import { filter, includes, isNil, map, prop, sortBy } from 'ramda';
import { Select } from '../../../../components/common/Select';
import styles from './ProductMaterial.module.scss';
import { classNames } from '../../../../utils';
class ProductMaterial extends Component {
    handleSelect = (selected) => {
        const { selectedValue } = this.props;
        if (!selected.id) {
            if (!selectedValue) {
                return;
            }
            this.props.onSelect({
                attributeId: this.props.id,
                attributeValue: selectedValue
            });
        }
        else {
            this.props.onSelect({
                attributeId: this.props.id,
                attributeValue: selected.label
            });
        }
    };
    render() {
        const { title, options, isOnSelected } = this.props;
        const items = filter((item) => includes(item.label, this.props.availableValues), map((item) => ({
            id: item.label,
            label: item.label
        }), sortBy(prop('label'), options)));
        const activeItem = this.props.selectedValue
            ? {
                id: this.props.selectedValue,
                label: this.props.selectedValue
            }
            : {
                id: 'placeholder',
                label: 'Choose item'
            };
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { id: title, className: classNames(styles, 'title', { isOnSelected }) },
                React.createElement("strong", null, title)),
            isNil(options) ? null : (React.createElement(Select, { forForm: true, withEmpty: true, activeItem: activeItem, items: items, onSelect: this.handleSelect, fullWidth: true, dataTest: "productMaterialSelect" }))));
    }
}
export default ProductMaterial;
