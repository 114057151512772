/**
 * @generated SignedSource<<091648dfaddb09226df0c4a6a6e65b5e>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "countryCode"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "size"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "weight"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "companyPackageId",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "companyPackageRawId",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "logo",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "label",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "alpha3",
        "storageKey": null
    }, v8 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "countryCode",
                    "variableName": "countryCode"
                },
                {
                    "kind": "Variable",
                    "name": "size",
                    "variableName": "size"
                },
                {
                    "kind": "Variable",
                    "name": "weight",
                    "variableName": "weight"
                }
            ],
            "concreteType": "AvailablePackagesOutput",
            "kind": "LinkedField",
            "name": "availablePackages",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "AvailablePackages",
                    "kind": "LinkedField",
                    "name": "local",
                    "plural": true,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "AvailablePackages",
                    "kind": "LinkedField",
                    "name": "international",
                    "plural": true,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Country",
                            "kind": "LinkedField",
                            "name": "deliveriesTo",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Country",
                                    "kind": "LinkedField",
                                    "name": "children",
                                    "plural": true,
                                    "selections": [
                                        (v6 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Country",
                                            "kind": "LinkedField",
                                            "name": "children",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "parent",
                                                    "storageKey": null
                                                },
                                                (v7 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "alpha2",
                                                    "storageKey": null
                                                },
                                                (v6 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v7 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v5 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "fetchPackages_Query",
            "selections": (v8 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "fetchPackages_Query",
            "selections": (v8 /*: any*/)
        },
        "params": {
            "cacheID": "ae2dade8b3097126833ef243ac7519ad",
            "id": null,
            "metadata": {},
            "name": "fetchPackages_Query",
            "operationKind": "query",
            "text": "query fetchPackages_Query(\n  $countryCode: String!\n  $size: Int!\n  $weight: Int!\n) {\n  availablePackages(countryCode: $countryCode, size: $size, weight: $weight) {\n    local {\n      companyPackageId\n      companyPackageRawId\n      name\n      logo\n      currency\n    }\n    international {\n      companyPackageId\n      companyPackageRawId\n      name\n      logo\n      deliveriesTo {\n        children {\n          label\n          children {\n            parent\n            alpha3\n            alpha2\n            label\n          }\n          alpha3\n        }\n      }\n      currency\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "a2b129891a1f7b88205daf3e501ee91e";
export default node;
