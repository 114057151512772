import { t } from '../../../translation/utils';
const translations = {
    en: {
        oopsSomethingGoneWrong: 'Oops! Something gone wrong and page has been',
        crushed: 'crushed. Try to start again from main page.',
        startFromMainPage: 'Start from main page'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
