/**
 * @generated SignedSource<<30766cdb1bfaccaec04b19cb56a1ff0c>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawId",
        "storageKey": null
    }, v2 = [
        (v1 /*: any*/),
        {
            "alias": null,
            "args": null,
            "concreteType": "Translation",
            "kind": "LinkedField",
            "name": "name",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lang",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "text",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        (v0 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "routes_StoreOrders_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "StoreOrders_me"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "routes_StoreOrders_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Store",
                            "kind": "LinkedField",
                            "name": "myStore",
                            "plural": false,
                            "selections": [
                                (v0 /*: any*/),
                                (v1 /*: any*/),
                                {
                                    "alias": null,
                                    "args": [
                                        {
                                            "kind": "Literal",
                                            "name": "currentPage",
                                            "value": 1
                                        },
                                        {
                                            "kind": "Literal",
                                            "name": "itemsCount",
                                            "value": 10
                                        },
                                        {
                                            "kind": "Literal",
                                            "name": "searchTermOptions",
                                            "value": {}
                                        }
                                    ],
                                    "concreteType": "OrderSearchConnection",
                                    "kind": "LinkedField",
                                    "name": "orders",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "OrdersEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "GraphQLOrder",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "slug",
                                                            "storageKey": null
                                                        },
                                                        (v0 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "currency",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "state",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "price",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "quantity",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "subtotal",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "totalAmount",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "createdAt",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "paymentStatus",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "deliveryCompany",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Product",
                                                            "kind": "LinkedField",
                                                            "name": "product",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "BaseProduct",
                                                                    "kind": "LinkedField",
                                                                    "name": "baseProduct",
                                                                    "plural": false,
                                                                    "selections": (v2 /*: any*/),
                                                                    "storageKey": null
                                                                },
                                                                (v0 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Store",
                                                            "kind": "LinkedField",
                                                            "name": "store",
                                                            "plural": false,
                                                            "selections": (v2 /*: any*/),
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PageInfoOrdersSearch",
                                            "kind": "LinkedField",
                                            "name": "pageInfo",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "totalPages",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "currentPage",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "pageItemsCount",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": "orders(currentPage:1,itemsCount:10,searchTermOptions:{})"
                                }
                            ],
                            "storageKey": null
                        },
                        (v0 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "944f7b793a5e03d962a69425aab803ec",
            "id": null,
            "metadata": {},
            "name": "routes_StoreOrders_Query",
            "operationKind": "query",
            "text": "query routes_StoreOrders_Query {\n  me {\n    ...StoreOrders_me\n    id\n  }\n}\n\nfragment StoreOrders_me on User {\n  myStore {\n    id\n    rawId\n    orders(currentPage: 1, itemsCount: 10, searchTermOptions: {}) {\n      edges {\n        node {\n          slug\n          id\n          currency\n          state\n          price\n          quantity\n          subtotal\n          totalAmount\n          createdAt\n          paymentStatus\n          deliveryCompany\n          product {\n            baseProduct {\n              rawId\n              name {\n                lang\n                text\n              }\n              id\n            }\n            id\n          }\n          store {\n            rawId\n            name {\n              lang\n              text\n            }\n            id\n          }\n        }\n      }\n      pageInfo {\n        totalPages\n        currentPage\n        pageItemsCount\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "9992964abccb3eb8068a5ac65d296f09";
export default node;
