import React, { Component } from 'react';
import { isNil, propOr } from 'ramda';
import { Button } from '../../common/Button';
import Input from '../Input';
import styles from '../Authorization.module.scss';
import t from './i18n';
class RecoverPassword extends Component {
    static defaultProps = {
        onBlur: () => { }
    };
    constructor(props) {
        super(props);
        this.state = { autocomplete: false };
    }
    handleClick = () => {
    };
    render() {
        const { email, errors, formValid, onClick, onChange, onBack, onBlur } = this.props;
        const { autocomplete } = this.state;
        return (React.createElement("div", { className: styles.signIn },
            React.createElement("div", { className: styles.inputBlock },
                React.createElement(Input, { thisFocus: true, label: t.labelEmail, name: "email", type: "email", model: email, onChange: onChange, autocomplete: autocomplete, errors: !isNil(errors) ? propOr(null, 'email', errors) : [], onBlur: onBlur, validate: "email" })),
            React.createElement("div", { className: styles.recoverPasswordButtons },
                React.createElement(Button, { wireframe: true, big: true, onClick: onBack, dataTest: "recoverPasswordButtonBack" }, t.back),
                React.createElement(Button, { onClick: onClick, big: true, disabled: !formValid, dataTest: "recoverPasswordButtonSendEmail" }, t.sendEmail))));
    }
}
export default RecoverPassword;
