import { t } from '../../../../translation/utils';
const translations = {
    en: {
        newTicketWasSuccessfullySent: 'New ticket was successfully sent.',
        ok: 'Ok',
        somethingIsGoingWrong: 'Something is going wrong :(',
        support: 'Support',
        labelTicketTitle: 'Ticket Title',
        labelYourProblem: 'Your Problem',
        createTicket: 'Create Ticket'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
