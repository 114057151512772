import React, { PureComponent } from 'react';
import { assoc, find, head, isEmpty, propEq } from 'ramda';
import { Button, InputPrice } from '../../../../../../../components/common';
import Countries from '../Countries';
import ShippingLocalSelect from '../ShippingLocalSelect';
import ShippingInterSelect from '../ShippingInterSelect';
import { convertCountriesToArrCodes, convertCountriesToStringLabels } from '../utils';
import styles from './FixPriceForm.module.scss';
import t from './i18n';
import { classNames } from '../../../../../../../utils';
class FixPriceForm extends PureComponent {
    constructor(props) {
        super(props);
        const { company, services } = props;
        let service = null;
        if (company) {
            service = find(propEq(company.service?.id, 'id'))(services);
        }
        else {
            service = !isEmpty(services) ? head(services) : null;
        }
        const countries = (service && service.countries) || null;
        this.state = {
            price: (company && company.price) || 0,
            service,
            countries
        };
    }
    componentDidUpdate(prevProps, prevState) {
        const { services } = this.props;
        if (JSON.stringify(prevProps.services) !== JSON.stringify(services)) {
            const newService = find(propEq(prevState.service ? prevState.service.id : null, 'id'))(services);
            if (newService) {
                this.updateState({ service: newService });
                return;
            }
            const service = !isEmpty(services) ? head(services) : null;
            this.updateState({ service });
        }
    }
    updateState = (newState) => {
        this.setState(newState);
    };
    handleSaveCompany = () => {
        const { company, onSaveCompany } = this.props;
        const { service, price, countries } = this.state;
        let newCompany = {
            service,
            price
        };
        if (countries) {
            newCompany = assoc('countries', countries, newCompany);
        }
        if (company) {
            newCompany = assoc('id', company.id, newCompany);
        }
        else {
            this.setState({ price: 0 });
        }
        onSaveCompany(newCompany);
    };
    handleOnSelectLocalService = (service) => {
        this.setState({ service: { ...service } });
    };
    handleOnSelectInterService = (service) => {
        this.setState({ service });
    };
    handlePriceChange = (price) => {
        this.setState({ price });
    };
    handleOnChangeCountries = (countries) => {
        const isCountries = !isEmpty(convertCountriesToArrCodes({ countries }));
        this.setState({ countries: isCountries ? countries : null });
    };
    render() {
        const { services, company, onRemoveEditableItem, inter, currency } = this.props;
        const { price, service, countries } = this.state;
        let isInterCompanyDisabled = true;
        let isLocalCompanyDisabled = true;
        if (company && company.service && service) {
            const companyServiceId = company.service.id;
            isInterCompanyDisabled = (convertCountriesToStringLabels(company.countries)
                === convertCountriesToStringLabels(countries)
                && companyServiceId === service.id
                && company.price === price)
                || isEmpty(convertCountriesToStringLabels(countries));
            isLocalCompanyDisabled = company && companyServiceId === service.id && company.price === price;
        }
        const withCompanySaveButtonDisabled = inter === true ? isInterCompanyDisabled : isLocalCompanyDisabled;
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.selects },
                React.createElement("div", { className: styles.serviceSelect }, inter === true ? (React.createElement(ShippingInterSelect, { services: services, service: service, handleOnSelectService: this.handleOnSelectInterService })) : (React.createElement(ShippingLocalSelect, { services: services, service: service, handleOnSelectService: this.handleOnSelectLocalService }))),
                React.createElement("div", { className: styles.inputPrice },
                    React.createElement(InputPrice, { onChangePrice: this.handlePriceChange, price: price, currency: currency, dataTest: `shipping${inter === true ? 'Inter' : 'Local'}ServicePrice` }))),
            inter === true && (React.createElement("div", { className: classNames(styles, 'countries') },
                React.createElement(Countries, { countries: (service && service.countries) || null, onChange: this.handleOnChangeCountries, company: company }))),
            React.createElement("div", { className: styles.buttons },
                React.createElement(Button, { wireframe: !company, big: true, add: !company, onClick: this.handleSaveCompany, disabled: company
                        ? withCompanySaveButtonDisabled
                        : inter === true && !countries, dataTest: `shipping${company ? 'Save' : 'Add'}CompanyButton_${inter === true ? 'inter' : 'local'}` }, company ? t.save : t.addCompany),
                company && (React.createElement("button", { className: styles.cancelButton, onClick: onRemoveEditableItem, "data-test": "shippingEditFormCancel" }, t.cancel)))));
    }
}
export default FixPriceForm;
