/**
 * @generated SignedSource<<e90f0b180124981840a5713dbc076d60>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "Fee",
            "kind": "LinkedField",
            "name": "chargeFee",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "orderId",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "amount",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currency",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "chargeId",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ChargeFeeMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ChargeFeeMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "2747d5143121dd26d0933e7d353f6103",
            "id": null,
            "metadata": {},
            "name": "ChargeFeeMutation",
            "operationKind": "mutation",
            "text": "mutation ChargeFeeMutation(\n  $input: ChargeFeeInput!\n) {\n  chargeFee(input: $input) {\n    id\n    orderId\n    amount\n    status\n    currency\n    chargeId\n  }\n}\n"
        }
    };
})();
node.hash = "67e7954dc0c94b65d0577351a43beb20";
export default node;
