/**
 * @generated SignedSource<<3aa7d7bf45ae835c4375a9f44e6d571d>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "slug"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "slug",
            "variableName": "slug"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawId",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lang",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "state",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "routes_StoreOrder_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        {
                            "args": (v1 /*: any*/),
                            "kind": "FragmentSpread",
                            "name": "StoreOrder_me"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "routes_StoreOrder_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Store",
                            "kind": "LinkedField",
                            "name": "myStore",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": (v1 /*: any*/),
                                    "concreteType": "GraphQLOrder",
                                    "kind": "LinkedField",
                                    "name": "order",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "slug",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "deliveryCompany",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "storeId",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Product",
                                            "kind": "LinkedField",
                                            "name": "product",
                                            "plural": false,
                                            "selections": [
                                                (v3 /*: any*/),
                                                (v2 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "BaseProduct",
                                                    "kind": "LinkedField",
                                                    "name": "baseProduct",
                                                    "plural": false,
                                                    "selections": [
                                                        (v2 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Translation",
                                                            "kind": "LinkedField",
                                                            "name": "name",
                                                            "plural": true,
                                                            "selections": [
                                                                (v5 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Category",
                                                            "kind": "LinkedField",
                                                            "name": "category",
                                                            "plural": false,
                                                            "selections": [
                                                                (v2 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "Translation",
                                                                    "kind": "LinkedField",
                                                                    "name": "name",
                                                                    "plural": true,
                                                                    "selections": [
                                                                        (v5 /*: any*/),
                                                                        (v6 /*: any*/)
                                                                    ],
                                                                    "storageKey": null
                                                                },
                                                                (v3 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        (v3 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "price",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ProdAttrValue",
                                                    "kind": "LinkedField",
                                                    "name": "attributes",
                                                    "plural": true,
                                                    "selections": [
                                                        (v7 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Attribute",
                                                            "kind": "LinkedField",
                                                            "name": "attribute",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "Translation",
                                                                    "kind": "LinkedField",
                                                                    "name": "name",
                                                                    "plural": true,
                                                                    "selections": [
                                                                        (v6 /*: any*/),
                                                                        (v5 /*: any*/)
                                                                    ],
                                                                    "storageKey": null
                                                                },
                                                                (v3 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "photoMain",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "receiverName",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "receiverPhone",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Address",
                                            "kind": "LinkedField",
                                            "name": "addressFull",
                                            "plural": false,
                                            "selections": [
                                                (v7 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "country",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "administrativeAreaLevel1",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "administrativeAreaLevel2",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "locality",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "political",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "postalCode",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "route",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "streetNumber",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "placeId",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "createdAt",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "trackId",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "quantity",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "subtotal",
                                            "storageKey": null
                                        },
                                        (v8 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "paymentStatus",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "totalAmount",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "deliveryPrice",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "couponDiscount",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "OrderHistoryItemsConnection",
                                            "kind": "LinkedField",
                                            "name": "history",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "OrderHistoryItemsEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "OrderHistoryItem",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                                (v8 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "committedAt",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "committerRole",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "comment",
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Fee",
                                            "kind": "LinkedField",
                                            "name": "fee",
                                            "plural": false,
                                            "selections": [
                                                (v3 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "orderId",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "amount",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "status",
                                                    "storageKey": null
                                                },
                                                (v4 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "chargeId",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "OrderBilling",
                                            "kind": "LinkedField",
                                            "name": "orderBilling",
                                            "plural": false,
                                            "selections": [
                                                (v3 /*: any*/),
                                                (v8 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "stripeFee",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "sellerCurrency",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                (v3 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "938b474021762a376ff083ae22a021c3",
            "id": null,
            "metadata": {},
            "name": "routes_StoreOrder_Query",
            "operationKind": "query",
            "text": "query routes_StoreOrder_Query(\n  $slug: Int!\n) {\n  me {\n    ...StoreOrder_me_20J5Pl\n    id\n  }\n}\n\nfragment StoreOrder_me_20J5Pl on User {\n  myStore {\n    rawId\n    order(slug: $slug) {\n      id\n      currency\n      slug\n      deliveryCompany\n      storeId\n      product {\n        id\n        rawId\n        baseProduct {\n          rawId\n          name {\n            text\n          }\n          category {\n            rawId\n            name {\n              text\n              lang\n            }\n            id\n          }\n          id\n        }\n        price\n        attributes {\n          value\n          attribute {\n            name {\n              lang\n              text\n            }\n            id\n          }\n        }\n        photoMain\n      }\n      receiverName\n      receiverPhone\n      addressFull {\n        value\n        country\n        administrativeAreaLevel1\n        administrativeAreaLevel2\n        locality\n        political\n        postalCode\n        route\n        streetNumber\n        placeId\n      }\n      createdAt\n      trackId\n      quantity\n      subtotal\n      state\n      paymentStatus\n      totalAmount\n      deliveryPrice\n      couponDiscount\n      history {\n        edges {\n          node {\n            state\n            committedAt\n            committerRole\n            comment\n          }\n        }\n      }\n      fee {\n        id\n        orderId\n        amount\n        status\n        currency\n        chargeId\n      }\n      orderBilling {\n        id\n        state\n        stripeFee\n        sellerCurrency\n      }\n    }\n    id\n  }\n}\n"
        }
    };
})();
node.hash = "c77ca99412334155920701fd1307ff5d";
export default node;
