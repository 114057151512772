import React from 'react';
import { Button } from '../../../../../components/common/Button';
import styles from './ProductsHeader.module.scss';
import t from './i18n';
const ProductsHeader = ({ onAdd }) => (React.createElement("header", { className: styles.container },
    React.createElement("h3", { className: styles.subtitle },
        React.createElement("strong", null, t.goodsList)),
    React.createElement("div", { className: styles.addItem },
        React.createElement(Button, { wireframe: true, big: true, onClick: onAdd, dataTest: "addProductButton" }, t.addItem))));
export default ProductsHeader;
