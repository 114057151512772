/**
 * @generated SignedSource<<e82ff04f4113eefc416161006e2cb680>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lang",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "concreteType": "Translation",
        "kind": "LinkedField",
        "name": "name",
        "plural": true,
        "selections": [
            (v0 /*: any*/),
            (v1 /*: any*/)
        ],
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawId",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "parentId",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "level",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "ThirdForm_allCategories",
        "selections": [
            (v2 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "Category",
                "kind": "LinkedField",
                "name": "children",
                "plural": true,
                "selections": [
                    (v3 /*: any*/),
                    (v4 /*: any*/),
                    (v5 /*: any*/),
                    (v6 /*: any*/),
                    (v2 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "Category",
                        "kind": "LinkedField",
                        "name": "children",
                        "plural": true,
                        "selections": [
                            (v3 /*: any*/),
                            (v4 /*: any*/),
                            (v5 /*: any*/),
                            (v6 /*: any*/),
                            (v2 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Category",
                                "kind": "LinkedField",
                                "name": "children",
                                "plural": true,
                                "selections": [
                                    (v3 /*: any*/),
                                    (v4 /*: any*/),
                                    (v5 /*: any*/),
                                    (v6 /*: any*/),
                                    (v2 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Attribute",
                                        "kind": "LinkedField",
                                        "name": "getAttributes",
                                        "plural": true,
                                        "selections": [
                                            (v3 /*: any*/),
                                            (v4 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Translation",
                                                "kind": "LinkedField",
                                                "name": "name",
                                                "plural": true,
                                                "selections": [
                                                    (v1 /*: any*/),
                                                    (v0 /*: any*/)
                                                ],
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "AttributeMetaField",
                                                "kind": "LinkedField",
                                                "name": "metaField",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "values",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "TranslatedValue",
                                                        "kind": "LinkedField",
                                                        "name": "translatedValues",
                                                        "plural": true,
                                                        "selections": [
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "concreteType": "Translation",
                                                                "kind": "LinkedField",
                                                                "name": "translations",
                                                                "plural": true,
                                                                "selections": [
                                                                    (v1 /*: any*/)
                                                                ],
                                                                "storageKey": null
                                                            }
                                                        ],
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Category",
        "abstractKey": null
    };
})();
node.hash = "eb30c5a97052953350ce1b34c6e4a8a5";
export default node;
