const locale = {
    en: {
        graame_market: 'Graame market',
        sections: 'Sections',
        services: 'Services'
    },
    hi: {
        graame_market: 'ग्रामः',
        sections: 'Разделы',
        services: 'Сервисы'
    },
    ja: {
        graame_market: 'ストリオカ市場',
        sections: 'セクション',
        services: 'サービス'
    },
    kk: {
        graame_market: 'Graame нарығы',
        sections: 'Бөлімдер',
        services: 'Қызметтер'
    }
};
export default locale;
