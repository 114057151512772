import React, { Component } from 'react';
import { Environment } from 'relay-runtime';
import PropTypes from 'prop-types';
import { find, head, isEmpty, length, map, pathOr, propEq, whereEq } from 'ramda';
import { Select, SpinnerCircle } from '../../../../components/common';
import { classNames, debug, error, formatPrice, getCookie } from '../../../../utils';
import { fetchAvailableShippingForUser } from '../../../../relay/queries';
import CheckedIcon from './img/checked.svg';
import styles from './Delivery.module.scss';
import t from './i18n';
class Delivery extends Component {
    static contextTypes = { environment: PropTypes.instanceOf(Environment).isRequired };
    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            country: undefined,
            deliveryPackage: undefined,
            deliveryPackages: undefined
        };
    }
    componentDidMount() {
        if (this.state.country) {
            return;
        }
        const { deliveryData } = this.props;
        if (!deliveryData.country) {
            this.fetchData();
        }
    }
    fetchData = () => {
        const { baseProductRawId } = this.props;
        const countryCode = pathOr(null, ['address', 'countryCode'], this.props.userAddress);
        if (countryCode) {
            this.fetchAvailableShipping(baseProductRawId, countryCode);
        }
        else {
            const cookiesCountry = getCookie('COUNTRY_IP');
            debug({ cookiesCountry });
            const country = find(propEq(cookiesCountry, 'alpha2'))(this.props.countries);
            if (country) {
                this.fetchAvailableShipping(baseProductRawId, country.alpha3);
            }
        }
    };
    fetchAvailableShipping = (baseProductRawId, countryCode) => {
        fetchAvailableShippingForUser({
            destinationCountry: countryCode,
            baseProductId: baseProductRawId,
            environment: this.context.environment
        })
            .then((deliveryPackages) => {
            const country = find(propEq(countryCode, 'alpha3'))(this.props.countries);
            let deliveryPackage = null;
            if (deliveryPackages && length(deliveryPackages) === 1) {
                deliveryPackage = head(deliveryPackages);
            }
            this.setState({
                deliveryPackages,
                deliveryPackage,
                country: country ? {
                    id: country.alpha3,
                    label: country.label
                } : null
            }, () => {
                this.handleOnSavePackage();
            });
            return true;
        })
            .finally(() => {
            this.setState({ isFetching: false });
        })
            .catch((err) => {
            error(err);
        });
    };
    handleOnChangeCountry = (country) => {
        if (JSON.stringify(country) === JSON.stringify(this.state.country)) {
            return;
        }
        this.setState({
            country,
            deliveryPackage: null
        }, () => {
            if (country) {
                this.setState({ isFetching: true });
                const { baseProductRawId } = this.props;
                this.fetchAvailableShipping(baseProductRawId, country.id);
                return;
            }
            this.setState({
                deliveryPackage: null,
                deliveryPackages: null
            });
        });
    };
    handleOnSelectPackage = (item) => {
        const { deliveryPackages } = this.state;
        const deliveryPackage = find(whereEq({ companyPackageRawId: parseInt(item.id, 10) }), deliveryPackages || []);
        this.setState({ deliveryPackage }, () => {
            this.handleOnSavePackage();
        });
    };
    handleOnSavePackage = () => {
        this.props.onChangeDeliveryData({
            deliveryPackage: this.state.deliveryPackage,
            country: this.state.country,
            deliveryPackages: this.state.deliveryPackages || []
        });
    };
    render() {
        const { currency } = this.props;
        debug(this.state);
        const { country, deliveryPackage, deliveryPackages, isFetching } = this.state;
        const countries = map((item) => ({
            id: item.alpha3,
            label: item.label
        }), this.props.countries);
        const transportCompanies = map((item) => ({
            id: `${item.companyPackageRawId}`,
            label: item.name
        }), deliveryPackages || []);
        const deliveryPackageSelectItem = deliveryPackage
            ? {
                id: `${deliveryPackage.companyPackageRawId}`,
                label: deliveryPackage.name
            }
            : null;
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.title },
                React.createElement("strong", null, t.delivery)),
            React.createElement("div", { className: styles.selectsWrapper },
                React.createElement("div", { className: styles.chooseCountry },
                    React.createElement("div", { className: styles.select },
                        React.createElement(Select, { withInput: true, forForm: true, fullWidth: true, label: "Country of delivery", items: countries, onSelect: this.handleOnChangeCountry, activeItem: country, dataTest: "productDeliveryCountrySelect" })),
                    isFetching && (React.createElement("div", { className: styles.loading },
                        React.createElement(SpinnerCircle, { additionalStyles: {
                                width: '3rem',
                                height: '3rem'
                            }, containerStyles: {
                                marginLeft: '3rem',
                                marginTop: '3rem'
                            } })))),
                React.createElement("div", { className: styles.chooseDeliveryCompany },
                    React.createElement("div", { className: styles.select }, !isFetching
                        && deliveryPackages instanceof Array
                        && deliveryPackages.length > 0 && (React.createElement(Select, { forForm: true, fullWidth: true, label: "Transport company", items: transportCompanies, onSelect: debug, activeItem: deliveryPackageSelectItem, dataTest: "productDeliveryPackageSelect", maxItemsHeight: 18.5, renderSelectItem: (item) => {
                            const pkgType = find(whereEq({ companyPackageRawId: parseInt(item.id, 10) }), this.state.deliveryPackages || []);
                            const isChecked = deliveryPackage && parseInt(item.id, 10) === deliveryPackage.companyPackageRawId;
                            return (pkgType && (React.createElement("div", { className: styles.deliveryCompanyItem, key: pkgType.id, onClick: () => {
                                    this.handleOnSelectPackage(item);
                                } },
                                React.createElement("div", { className: styles.companyNameRow },
                                    React.createElement("div", { className: classNames(styles, 'checkedIcon', { hidden: !isChecked }) },
                                        React.createElement(CheckedIcon, null)),
                                    React.createElement("div", { className: styles.companyNameWrap },
                                        React.createElement("div", { className: classNames(styles, 'companyName', { selected: isChecked }) }, isChecked === true ? (React.createElement("strong", null, pkgType.name)) : (React.createElement("span", null, pkgType.name))),
                                        React.createElement("div", { className: classNames(styles, 'price', { selected: isChecked }) }, `${formatPrice(pkgType.price, 2)} ${currency || ''}`))))));
                        } }))))),
            country
                && !isFetching
                && isEmpty(deliveryPackages) && (React.createElement("div", { className: styles.notShipping }, t.sellerDoesNotShip)),
            deliveryPackage && (React.createElement(React.Fragment, null,
                React.createElement("div", { key: deliveryPackage.id, className: styles.deliveryPackage },
                    React.createElement("div", { className: styles.logoWrapper }, deliveryPackage.logo !== null
                        && deliveryPackage.logo !== '' && (React.createElement("img", { src: deliveryPackage.logo, alt: "", className: styles.logo }))),
                    React.createElement("div", { className: styles.textWrapper },
                        React.createElement("div", { className: styles.pkgName }, t.price),
                        React.createElement("div", { className: styles.pkgPrice },
                            formatPrice(deliveryPackage.price, 2),
                            ' ',
                            currency))),
                React.createElement("div", { className: styles.warning }, t.warning)))));
    }
}
export default Delivery;
