import React, { PureComponent } from 'react';
import { find, head, is, isNil, map, pathOr, propOr, whereEq } from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import { Environment } from 'relay-runtime';
import PropTypes from 'prop-types';
import ShowMore from '../../../components/ShowMore';
import Stepper from '../../../components/Stepper';
import { Input } from '../../../components/common/Input';
import { Col, Container, Row } from '../../../layout';
import { convertCountries, formatPrice } from '../../../utils';
import { AppContext } from '../../../components/App';
import CartProductAttribute from '../CartProductAttribute';
import { DeliveryCompaniesSelect } from '../../Checkout/CheckoutContent/DeliveryCompaniesSelect';
import CheckoutContext from '../../Checkout/CheckoutContext';
import setDeliveryPackageInCartMutation from '../../Checkout/CheckoutContent/DeliveryCompaniesSelect/mutations/SetDeliveryPackageInCart';
import removeDeliveryMethodForProductMutation from '../../Checkout/CheckoutContent/DeliveryCompaniesSelect/mutations/RemoveDeliveryMethodForProduct';
import styles from './ProductInfo.module.scss';
import t from './i18n';
class ProductInfo extends PureComponent {
    static contextTypes = { environment: PropTypes.instanceOf(Environment).isRequired };
    static defaultProps = { withDeliveryCompaniesSelect: false };
    handlePackageSelect = (pkg) => {
        const productId = pathOr(null, ['rawId'], this.props.product);
        if (!isNil(pkg) && !isNil(productId) && is(Number, productId)) {
            return setDeliveryPackageInCartMutation({
                environment: this.context.environment,
                variables: {
                    input: {
                        clientMutationId: uuidv4(),
                        productId: parseInt(productId.toString(), 10),
                        companyPackageId: pkg.companyPackageRawId,
                        shippingId: pkg.shippingId
                    }
                }
            })
                .then(() => Promise.resolve(true))
                .catch((err) => Promise.reject(err));
        }
        return Promise.resolve(true);
    };
    handlePackageFetching = (packages) => {
        const shippingId = pathOr(null)(['product', 'selectPackage', 'shippingId'])(this.props);
        const currentProductRawId = pathOr(null, ['product', 'rawId'])(this.props);
        if (shippingId != null) {
            const isProductPackageExists = !isNil(find(whereEq({ shippingId }), packages));
            if (!isProductPackageExists && currentProductRawId) {
                removeDeliveryMethodForProductMutation({
                    environment: this.context.environment,
                    variables: {
                        input: {
                            clientMutationId: uuidv4(),
                            productId: currentProductRawId
                        }
                    }
                });
            }
        }
    };
    render() {
        const { product, onQuantityChange, onChangeComment, comment, withDeliveryCompaniesSelect, currency } = this.props;
        const attrs = map((attr) => ({
            title: head(attr.attribute.name).text,
            value: attr.value.toString()
        }), product.attributes);
        const isShippingAvailable = pathOr(false, ['baseProduct', 'isShippingAvailable'], product);
        return (React.createElement(ShowMore, { isOpen: true, height: 400, dataTest: `cart-product-${product.rawId}-showMore` },
            React.createElement(Container, { correct: true },
                React.createElement(Row, null,
                    React.createElement(Col, { size: 12, xl: 8 },
                        React.createElement(Row, null,
                            React.createElement(Col, { size: 6, xl: 12 },
                                React.createElement("div", { className: styles.contentBlock },
                                    React.createElement("div", { className: styles.productSummaryAttributes },
                                        React.createElement("div", { className: styles.cartProductTitle }, t.aboutProduct),
                                        product.preOrder
                                            && product.preOrderDays && (React.createElement("div", { className: styles.preOrder },
                                            React.createElement("div", { className: styles.preOrderText },
                                                React.createElement("div", null, t.availableForPreOrder),
                                                React.createElement("div", null,
                                                    t.leadTime,
                                                    ' ',
                                                    React.createElement("span", { className: styles.preOrderDays }, product.preOrderDays))))),
                                        (attrs.length > 0 && (React.createElement(Row, null, attrs.map((attr) => (React.createElement(Col, { key: `attr-${attr.value}`, size: 12, xl: 6 },
                                            React.createElement(CartProductAttribute, { ...attr }))))))) || React.createElement("div", { className: styles.empty })))),
                            React.createElement(Col, { size: 6, xlHidden: true },
                                React.createElement("div", { className: styles.contentBlock },
                                    React.createElement("div", { className: styles.cartProductTitle }, t.price),
                                    React.createElement(CartProductAttribute, { title: t.count, value: (React.createElement(Stepper, { value: product.quantity, min: 0, max: 9999, onChange: (newVal) => onQuantityChange(newVal) })) }),
                                    React.createElement(CartProductAttribute, { title: t.price, value: `${formatPrice(product.subtotalWithoutDiscounts || 0, 2)} ${currency || ''}` }),
                                    React.createElement(CartProductAttribute, { title: t.delivery, value: `${formatPrice(product.deliveryCost || 0, 2)} ${currency || ''}` }),
                                    product.couponDiscount !== 0 && (React.createElement(CartProductAttribute, { title: t.couponDiscount, value: `${formatPrice(product.couponDiscount || 0, 2)} ${currency || ''}` })))),
                            React.createElement(Col, { size: 12 },
                                React.createElement("div", { className: styles.contentBlock },
                                    React.createElement("div", null,
                                        withDeliveryCompaniesSelect === false
                                            && !isShippingAvailable && (React.createElement(React.Fragment, null,
                                            React.createElement("div", { className: styles.cartProductTitle }, t.delivery),
                                            React.createElement(Row, null,
                                                React.createElement(Col, { size: 11 },
                                                    React.createElement("div", { className: styles.noDeliveryAvailableAlert },
                                                        React.createElement("span", { className: styles.error }, t.attention),
                                                        "\u00A0",
                                                        t.noShippingAvailable))))),
                                        withDeliveryCompaniesSelect && (React.createElement(AppContext.Consumer, null, ({ directories }) => (React.createElement(CheckoutContext.Consumer, null, ({ country }) => {
                                            const currentAddressCountry = find(whereEq({ label: country }), convertCountries(directories.countries));
                                            const currentCountryAlpha3 = propOr(null, 'alpha3', currentAddressCountry);
                                            return currentCountryAlpha3 != null ? (React.createElement(React.Fragment, null,
                                                React.createElement("div", { className: styles.cartProductTitle }, t.delivery),
                                                React.createElement(Row, null,
                                                    React.createElement(Col, { size: 11 },
                                                        React.createElement(DeliveryCompaniesSelect, { currency: currency, baseProductId: product.baseProductId, country: currentCountryAlpha3, onPackagesFetched: this.handlePackageFetching, onPackageSelect: this.handlePackageSelect, selectedCompanyShippingRawId: pathOr(null, ['selectPackage', 'shippingId'], product) }))))) : (React.createElement("span", null, t.noCountryFound));
                                        })))),
                                        React.createElement("div", { className: styles.comment },
                                            React.createElement(Input, { fullWidth: true, id: "customerComment", onChange: onChangeComment, value: comment, label: t.labelCostumerComment }))))))),
                    React.createElement(Col, { size: 4, xlVisibleOnly: true },
                        React.createElement("div", { className: styles.contentBlock },
                            React.createElement("div", { className: styles.cartProductTitle }, t.price),
                            React.createElement(CartProductAttribute, { title: t.count, value: (React.createElement(Stepper, { value: product.quantity, min: 0, max: 9999, onChange: (newVal) => onQuantityChange(newVal) })) }),
                            React.createElement(CartProductAttribute, { title: t.price, value: `${formatPrice(product.subtotal || 0, 2)} ${currency || ''}` }),
                            React.createElement(CartProductAttribute, { title: t.delivery, value: `${formatPrice(product.deliveryCost || 0, 2)} ${currency || ''}` }),
                            product.couponDiscount !== 0 && (React.createElement(CartProductAttribute, { title: t.couponDiscount, value: `−${formatPrice(product.couponDiscount || 0, 2)} ${currency || ''}` }))))))));
    }
}
export default ProductInfo;
