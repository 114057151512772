import React from 'react';
import styles from './SpinnerCircle.module.scss';
import { classNames } from '../../../utils';
/*type PropsType = {
  additionalStyles?: {[key: string]: number | string | boolean},
  containerStyles?: {[key: string]: number | string | boolean},
  forPaid?: boolean,
  small?: boolean,
};*/
const SpinnerCircle = ({ additionalStyles = undefined, containerStyles = undefined, forPaid = undefined, small = undefined }) => (React.createElement(React.Fragment, null, forPaid === true ? (React.createElement("div", { className: classNames(styles, 'paidSpinner', { small }) })) : (React.createElement("div", { className: styles.container, style: containerStyles || {} },
    React.createElement("div", { className: styles.spinner, style: additionalStyles || {} })))));
export default SpinnerCircle;
