import { t } from '../../../translation/utils';
const translations = {
    en: {
        seeAll: 'See All',
        recommendationSettings: 'Recommendations settings'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
