const locale = {
    en: {
        most_popular: 'Most popular',
        sale: 'Sale',
        see_all: 'See all'
    },
    hi: {
        most_popular: 'Популярные',
        sale: 'Распродажа',
        see_all: 'Все'
    },
    ja: {
        most_popular: '最も人気のある',
        sale: '販売',
        see_all: 'すべてを見る'
    },
    kk: {
        most_popular: 'Ең танымал',
        sale: 'Сатылым',
        see_all: 'Барлығын көру'
    }
};
export default locale;
