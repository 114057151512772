import { t } from '../../../../../translation/utils';
const translations = {
    en: {
        nameMustNotBeEmpty: 'Name must not be empty',
        shortDescriptionMustNotBeEmpty: 'Short description must not be empty',
        longDescriptionMustNotBeEmpty: 'Long description must not be empty',
        slugMustNotBeEmpty: 'Slug must not be empty',
        uploadMainPhoto: 'Upload main photo',
        stronglyRecommendToUpload: 'Strongly recommend to upload:',
        size1360x350: '1360px × 350px | .jpg .jpeg .png',
        altStoreCover: 'Store Cover',
        labelStoreName: 'Store Name',
        labelLanguage: 'Language',
        labelSlogan: 'Slogan',
        labelShortDescription: 'Short Description',
        labelLongDescription: 'Long Description',
        save: 'Save',
        close: 'Close.',
        sendToModeration: 'Send to moderation',
        storeIsOnModeration: 'Store is on moderation',
        storeIsBlocked: 'Store is blocked, contact the support service',
        longDescriptionLimitError: 'Long Description should be less than 8000 characters.',
        shopEditor: 'Shop Editor'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
