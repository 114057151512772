import React, { Component } from 'react';
import axios from 'axios';
import { Button, Input, Textarea } from '../../../../components/common';
import styles from './OpenTicketModal.module.scss';
import t from './i18n';
class OpenTicketModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ticketTitleText: '',
            ticketProblemText: '',
            isLoading: false,
            success: false
        };
    }
    handleTicketTitleChange = (e) => {
        const { value } = e.target;
        if (value.length > 50) {
            return;
        }
        this.setState({ ticketTitleText: value });
    };
    handleTicketProblemChange = (e) => {
        const { value } = e.target;
        this.setState({ ticketProblemText: value });
    };
    handleCreateTicket = () => {
        this.setState({ isLoading: true });
        const { email, showAlert } = this.props;
        const { ticketTitleText, ticketProblemText } = this.state;
        const authEmail = process.env.REACT_APP_ZENDESK_EMAIL;
        const token = process.env.REACT_APP_ZENDESK_TOKEN;
        const api = axios.create({
            baseURL: 'https://graame.zendesk.com',
            headers: { 'Content-Type': 'application/json' },
            auth: {
                username: `${authEmail || ''}/token`,
                password: token
            }
        });
        api
            .post('/api/v2/tickets.json', {
            ticket: {
                subject: `[db-platform] ${ticketTitleText}`,
                comment: { body: `[${email}] ${ticketProblemText}` }
            }
        })
            .then(() => {
            this.setState({
                isLoading: false,
                success: true
            });
            showAlert({
                type: 'success',
                text: t.newTicketWasSuccessfullySent,
                link: { text: t.ok }
            });
            return true;
        })
            .catch(() => {
            this.setState({ isLoading: false });
            this.props.showAlert({
                type: 'danger',
                text: t.somethingIsGoingWrong,
                link: { text: t.ok }
            });
        });
    };
    render() {
        const { ticketTitleText, ticketProblemText, isLoading, success } = this.state;
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.title },
                React.createElement("small", null, t.support)),
            React.createElement("div", { className: styles.ticketTitle },
                React.createElement(Input, { fullWidth: true, value: ticketTitleText, label: t.labelTicketTitle, onChange: this.handleTicketTitleChange, limit: 50 })),
            React.createElement("div", { className: styles.ticketText },
                React.createElement(Textarea, { fullWidth: true, value: ticketProblemText, label: t.labelYourProblem, onChange: this.handleTicketProblemChange })),
            success && (React.createElement("div", { className: styles.success }, t.newTicketWasSuccessfullySent)),
            React.createElement("div", { className: styles.createTicketButton },
                React.createElement(Button, { big: true, inline: true, disabled: !ticketTitleText || !ticketProblemText || success, isLoading: isLoading, onClick: this.handleCreateTicket }, t.createTicket))));
    }
}
export default OpenTicketModal;
