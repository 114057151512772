/**
 * @generated SignedSource<<d060f30a093354ed3a6efa8c363b5efa>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "id"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "id"
                }
            ],
            "concreteType": "UserDeliveryAddress",
            "kind": "LinkedField",
            "name": "deleteUserDeliveryAddressFull",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rawId",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "DeleteUserDeliveryAddressFullMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "DeleteUserDeliveryAddressFullMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "038bc6d363382103c7e2073f9abfb765",
            "id": null,
            "metadata": {},
            "name": "DeleteUserDeliveryAddressFullMutation",
            "operationKind": "mutation",
            "text": "mutation DeleteUserDeliveryAddressFullMutation(\n  $id: Int!\n) {\n  deleteUserDeliveryAddressFull(id: $id) {\n    id\n    rawId\n  }\n}\n"
        }
    };
})();
node.hash = "1ef873da2eb7ac97ecf6bfe31510b0e8";
export default node;
