import React, { Component } from 'react';
import { pathOr } from 'ramda';
import { Input } from '../../../../components/common/Input';
import { Textarea } from '../../../../components/common/Textarea';
import { InputSlug } from '../../../../components/common/InputSlug';
import FormWrapper from '../FormWrapper';
import styles from './Form.module.scss';
import t from './i18n';
class FirstForm extends Component {
    static prepareState = (props, state) => {
        const newState = {
            ...props,
            ...state
        };
        return newState;
    };
    static getDerivedStateFromProps(nextProps, prevState) {
        return FirstForm.prepareState(nextProps.initialData, prevState);
    }
    constructor(props) {
        super(props);
        this.state = { ...props.initialData };
    }
    handleOnChange = (e) => {
        const { onChange } = this.props;
        const { target: { value, name } } = e;
        this.setState({ [name]: value }, () => {
            onChange({ ...this.state });
        });
    };
    handleOnChangeSlug = (slugValue) => {
        const { onChange } = this.props;
        this.setState({ slug: slugValue }, () => {
            onChange({ ...this.state });
        });
    };
    render() {
        const { name, shortDescription } = this.state;
        const { errors } = this.props;
        const slug = pathOr('', ['initialData', 'slug'], this.props);
        return (React.createElement(FormWrapper, { firstForm: true, title: t.giveYourStoreAname, description: t.makeAbrightName },
            React.createElement("div", { className: styles.form },
                React.createElement("div", { className: styles.formItem },
                    React.createElement(Input, { id: "name", value: name || '', label: (React.createElement("span", null,
                            t.storeName,
                            " ",
                            React.createElement("span", { className: styles.red }, "*"))), onChange: this.handleOnChange, fullWidth: true, errors: errors && errors.store })),
                React.createElement("div", { className: styles.formItem },
                    React.createElement(InputSlug, { slug: slug, onChange: this.handleOnChangeSlug })),
                React.createElement("div", null,
                    React.createElement(Textarea, { id: "shortDescription", value: shortDescription || '', label: (React.createElement("span", null,
                            t.shortDescription,
                            " ",
                            React.createElement("span", { className: styles.red }, "*"))), onChange: this.handleOnChange, fullWidth: true, errors: errors && errors.shortDescription })))));
    }
}
export default FirstForm;
