// TODO: отрефакторить таким образом, чтобы hidden input и вызов uploadFile с
// отображением ошибок рулились из этого компонента.
// другими словами, сделать полностью автономным
import React from 'react';
import { Icon } from '../Icon';
import styles from './UploadWrapper.module.scss';
import { classNames } from '../../utils';
const UploadWrapper = ({ buttonLabel, onUpload, buttonHeight, buttonWidth, buttonIconType, id, dataTest, customUnit = false, square = false, buttonIconSize = 32, children = undefined, fullWidth = undefined, overPicture = undefined, noIndents = null, disabled = null, loading = false, multiple = undefined }) => (React.createElement("div", { className: classNames(styles, 'wrapper', { square }) },
    loading && (React.createElement("div", { className: styles.uploadIndicator },
        React.createElement("div", { className: styles.spinner }),
        ' ')),
    React.createElement("div", { className: classNames(styles, 'uploadContainer', { noIndents }) },
        React.createElement("label", { htmlFor: id, className: classNames(styles, 'uploadButton', { fullWidth }), style: {
                height: `${buttonHeight}${customUnit ? '' : 'rem'}`,
                width: !fullWidth && `${buttonWidth}${customUnit ? '' : 'rem'}`
            }, "data-test": dataTest },
            buttonIconType
                && !overPicture && React.createElement(Icon, { type: buttonIconType, size: buttonIconSize }),
            !overPicture && (React.createElement("span", { className: classNames(styles, 'buttonLabel') }, buttonLabel)),
            overPicture && (React.createElement("div", { className: styles.overPictureWrap, style: {
                    height: `${buttonHeight}rem`,
                    width: `${buttonWidth}rem`
                } },
                overPicture && (React.createElement("span", { className: styles.overlay },
                    React.createElement("span", { onClick: () => {
                        }, onKeyPress: () => {
                        }, role: "button", className: styles.delete, tabIndex: -1 },
                        React.createElement(Icon, { type: "basket", size: 24 })))),
                React.createElement("img", { className: styles.overPicture, src: overPicture, alt: "img" })))),
        !disabled && (React.createElement("input", { style: { display: 'none' }, multiple: multiple, id: id, type: "file", onChange: onUpload }))),
    React.createElement("div", { className: styles.childrenConainer },
        React.createElement("div", { className: styles.upoloadChildren }, children))));
export default UploadWrapper;
