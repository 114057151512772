import { t } from '../../../translation/utils';
const translations = {
    en: {
        labelNewPassword: 'New Password',
        labelRepeatPassword: 'New Password Again',
        submitNewPassword: 'Submit New Password',
        errorNotMatch: 'Not Match'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
