import { createElement } from 'react';
import locale from '../locales';
import { getCookie } from '../../utils/cookiesOp';
const supplant = (s, d = {}) => {
    let ss = s;
    Object.keys(d)
        .forEach((p) => {
        if ({}.hasOwnProperty.call(d, p)) {
            ss = ss.replace(new RegExp(`{${p}}`, 'g'), d[p]);
        }
    });
    return ss;
};
const createHTMLMarkup = (html) => ({ __html: html });
export const tt = (key, props) => {
    if (props) {
        const { placeholders, isHTML, tagName } = props;
        const finalResult = placeholders ? supplant(key, placeholders) : key;
        return isHTML
            ? createElement(tagName || 'span', { dangerouslySetInnerHTML: createHTMLMarkup(finalResult) }, null)
            : finalResult;
    }
    return key;
};
export const getLocale = () => {
    const localeValue = getCookie('locale') || 'en';
    return locale[localeValue].messages;
};
export const getCurrentLang = () => getCookie('locale') || 'en';
export function t(bundle) {
    return bundle[getCurrentLang()] || bundle.en;
}
