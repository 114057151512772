import { t } from '../../../translation/utils';
const translations = {
    en: {
        oopsItSeemsThatThePageDoesntExist: '"Oops! Seems that the page you searching doesn\'t exist."',
        tryToStartAgain: 'Try to start again from main page or use search tool.',
        startFromMainPage: 'Start from main page',
        back: 'Back'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
