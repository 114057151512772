import { t } from '../../../../translation/utils';
const translations = {
    en: {
        giveYourStoreAname: 'Give your store a name',
        makeAbrightName: 'Make a bright name for your store to attend your customers and increase your sales',
        storeName: 'Store name',
        shortDescription: 'Short Description'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
