/**
 * @generated SignedSource<<c760dbf643c0543a01713cc76ba9876b>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "concreteType": "Cart",
        "kind": "LinkedField",
        "name": "cart",
        "plural": false,
        "selections": [
            (v2 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "CartStoresConnection",
                "kind": "LinkedField",
                "name": "stores",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "CartStoresEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "CartStore",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v2 /*: any*/)
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amount",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "priceReservedDueDateTime",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "state",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "concreteType": "Transaction",
        "kind": "LinkedField",
        "name": "transactions",
        "plural": true,
        "selections": [
            (v2 /*: any*/),
            (v4 /*: any*/)
        ],
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "BuyNowMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateOrdersOutput",
                    "kind": "LinkedField",
                    "name": "buyNow",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Invoice",
                            "kind": "LinkedField",
                            "name": "invoice",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "GraphQLOrder",
                                    "kind": "LinkedField",
                                    "name": "orders",
                                    "plural": true,
                                    "selections": [
                                        (v8 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "BuyNowMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateOrdersOutput",
                    "kind": "LinkedField",
                    "name": "buyNow",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Invoice",
                            "kind": "LinkedField",
                            "name": "invoice",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "GraphQLOrder",
                                    "kind": "LinkedField",
                                    "name": "orders",
                                    "plural": true,
                                    "selections": [
                                        (v8 /*: any*/),
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "e2bfcc2d69eaebe60aa9e4e0892567d8",
            "id": null,
            "metadata": {},
            "name": "BuyNowMutation",
            "operationKind": "mutation",
            "text": "mutation BuyNowMutation(\n  $input: BuyNowInput!\n) {\n  buyNow(input: $input) {\n    cart {\n      id\n      totalCount\n      stores {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n    }\n    invoice {\n      id\n      amount\n      priceReservedDueDateTime\n      state\n      transactions {\n        id\n        amount\n      }\n      orders {\n        slug\n        id\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "67037348cf7696cefb92bbc09e155135";
export default node;
