import React, { Component } from 'react';
import { map, addIndex, find, whereEq, slice } from 'ramda';
import { Icon } from '../../Icon';
import t from '../i18n';
import styles from './AccordionBlock.module.scss';
import { classNames } from '../../../utils';
const mapIndexed = addIndex(map);
class AccordionBlock extends Component {
    constructor(props) {
        super(props);
        // check for isExpanded accordionBlock
        // isExpanded имеет приоритет по отношению к проверке на активный элемент
        if (props.active && props.tree.children) {
            const isContains = find(whereEq({ id: props.active }), props.tree.children || []);
            this.state = {
                isExpanded: props.isExpanded === undefined
                    ? Boolean(isContains)
                    : props.isExpanded,
                showAll: false
            };
        }
        else {
            this.state = {
                isExpanded: Boolean(props.isExpanded),
                showAll: false
            };
        }
    }
    handleOnToggle = () => {
        this.setState((prevState) => ({ isExpanded: !prevState.isExpanded }));
    };
    handleOnShowAll = () => {
        this.setState((prevState) => ({ showAll: !prevState.showAll }));
    };
    render() {
        const { tree, active, onClick } = this.props;
        const { isExpanded, showAll } = this.state;
        const slicer = showAll ? slice(0, Infinity) : slice(0, 5);
        return (React.createElement("div", null,
            React.createElement("div", { onClick: this.handleOnToggle, className: styles.parentTitleContainer, onKeyDown: () => {
                }, role: "button", tabIndex: 0 },
                React.createElement("div", { className: styles.parentTitle, "data-test": "categoryLevelTwoButton" }, tree.name),
                isExpanded && React.createElement(Icon, { type: "minus", size: 16 }),
                !isExpanded && React.createElement(Icon, { type: "plus", size: 16 })),
            isExpanded && (React.createElement("div", { className: styles.childrenContainer },
                tree.children && mapIndexed((child) => (React.createElement("div", { key: child.id, className: classNames(styles, 'item', { active: active === child.id }), onClick: () => onClick(child), onKeyDown: () => {
                    }, role: "button", tabIndex: 0, "data-test": "categoryLevelThreeButton" }, child.name)), slicer(tree.children)),
                tree.children && tree.children.length > 5 && (React.createElement("div", { className: classNames(styles, 'item', { active: true }), onClick: this.handleOnShowAll, onKeyDown: () => {
                    }, role: "button", tabIndex: 0 }, t.showAll))))));
    }
}
export default AccordionBlock;
