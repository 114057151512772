import { t } from '../../../translation/utils';
const translations = {
    en: {
        labelAptSuiteOther: 'Apt / Suite / Other',
        labelStreetAddress: 'Street Address',
        labelLocality: 'Locality',
        labelRegionState: 'Region / State',
        labelAreaDistrict: 'Area / District',
        labelPostalCode: 'Postal Code'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
