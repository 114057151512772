import { assocPath, complement, filter, find, fromPairs, has, head, isNil, last, map, omit, path, pathOr, pipe, reduce, split, toPairs, when, whereEq } from 'ramda';
import { renameKeys } from "./rename";
const byLang = (lang) => find(whereEq({ lang }));
export const getNameText = (arr, lang) => {
    if (!arr || !lang) {
        return null;
    }
    let name = byLang(lang)(arr);
    if (!name || !name.text)
        name = byLang('EN')(arr);
    return name ? name.text : null;
};
export const flattenFunc = reduce((acc, nextItem) => {
    if (nextItem.children) {
        return [...acc, nextItem, ...flattenFunc(nextItem.children)];
    }
    return [...acc, nextItem];
}, []);
export const findCategory = (pred, tree) => {
    if (tree.children && tree.children.length > 0) {
        const flatArr = flattenFunc(tree.children);
        const result = find(pred, flatArr);
        return result || null;
    }
    return null;
};
const urlToAttr = item => {
    const left = item.split('=').length === 2 ? item.split('=')[0] : null;
    const right = item.split('=').length === 2 ? item.split('=')[1] : null;
    if (!left || !right)
        return null;
    const methodName = left.split('.').length === 2 ? left.split('.')[0] : null;
    const id = left.split('.').length === 2 ? parseInt(left.split('.')[1], 10) : null;
    if (!methodName || !id)
        return null;
    return {
        id,
        [methodName]: {
            values: right.split(',')
        }
    };
};
const parseAttrFiltersFromUrl = pipe(path(['attrFilters']), split(';'), map(urlToAttr), filter(complement(isNil)));
const assocInt = (arr, getterValue) => obj => {
    const value = getterValue(obj);
    const res = assocPath(arr, value ? parseFloat(getterValue(obj)) : null)(obj);
    return res;
};
const assocStr = (arr, getterValue) => obj => assocPath(arr, getterValue(obj))(obj);
export const urlToInput = (queryObj) => {
    const modifQueryObj = fromPairs(map(item => [head(item), last(item) || ''], toPairs(queryObj)));
    return pipe(renameKeys({ search: 'name' }), when(has('category'), assocInt(['options', 'categoryId'], path(['category']))), when(has('maxValue'), assocInt(['options', 'priceFilter', 'maxValue'], path(['maxValue']))), when(has('minValue'), assocInt(['options', 'priceFilter', 'minValue'], path(['minValue']))), when(has('sortBy'), assocStr(['options', 'sortBy'], path(['sortBy']))), when(has('country'), assocStr(['options', 'country'], path(['country']))), when(has('attrFilters'), assocStr(['options', 'attrFilters'], parseAttrFiltersFromUrl)), omit([
        'category',
        'maxValue',
        'minValue',
        'attrFilters',
        'sortBy',
        'country'
    ]))({ ...modifQueryObj, search: modifQueryObj.search || '' });
};
export const inputToUrl = (obj) => {
    const range = pathOr(null, ['options', 'priceFilter'], obj);
    const attrFilters = pathOr(null, ['options', 'attrFilters'], obj);
    const categoryId = pathOr(null, ['options', 'categoryId'], obj);
    const sortBy = pathOr(null, ['options', 'sortBy'], obj);
    const country = pathOr(null, ['options', 'country'], obj);
    const pushCategory = str => `${str}&category=${categoryId}`;
    const pushCountry = str => `${str}&country=${country}`;
    const pushSort = str => `${str}&sortBy=${sortBy}`;
    const pushRange = str => `${str}&minValue=${range.minValue}&maxValue=${range.maxValue}`;
    const pushFilters = str => reduce((acc, next) => `${acc}equal.${next.id}=${next.equal.values.join(',')};`, `${str}&attrFilters=`, attrFilters);
    // pipe for result get str
    return pipe(str => `${str}search=${obj.name}`, when(() => complement(isNil)(categoryId), pushCategory), when(() => complement(isNil)(sortBy), pushSort), when(() => complement(isNil)(country), pushCountry), when(() => complement(isNil)(range), pushRange), when(() => complement(isNil)(attrFilters), pushFilters))('?');
};
export const searchPathByParent = (arr, rawId, stack = []) => {
    const result = find(whereEq({ rawId }), arr);
    if (!result)
        return stack;
    stack.unshift(result);
    if (result && result.parentId) {
        searchPathByParent(arr, result.parentId, stack);
    }
    return stack;
};
