import React, { PureComponent } from 'react';
import { keys, pathOr } from 'ramda';
import ArrowExpand from './svg/arrowExpand.svg';
import ArrowLeft from './svg/arrowLeft.svg';
import ArrowRight from './svg/arrowRight.svg';
import ArrowSelect from './svg/arrowSelect.svg';
import Basket from './svg/basket.svg';
import BurgerMenu from './svg/menu-button.svg';
import Camera from './svg/camera.svg';
import CameraPlus from './svg/cameraPlus.svg';
import Cart from './svg/cart.svg';
import Cats from './svg/cats.svg';
import CloseArrow from './svg/closeArrow.svg';
import Controls from './svg/controls.svg';
import Cross from './svg/cross.svg';
import CrossWhite from './svg/crossWhite.svg';
import Email from './svg/email.svg';
import Eye from './svg/eye.svg';
import EyeBlue from './svg/eyeBlue.svg';
import Facebook from './svg/facebook.svg';
import FacebookGray from './svg/facebook_gray.svg';
import MediumGray from './svg/medium_gray.svg';
import RedditGray from './svg/reddit_gray.svg';
import Google from './svg/google.svg';
import Heart from './svg/heart.svg';
import Instagram from './svg/instagram.svg';
import InstagramGray from './svg/instagram_gray.svg';
import YoutubeGray from './svg/youtube_gray.svg';
import LeftArrowSlider from './svg/leftArrowSlider.svg';
import Login from './svg/login.svg';
import Logo from './svg/logo.svg';
import Logout from './svg/logout.svg';
import Magnifier from './svg/magnifier.svg';
import Minus from './svg/minus.svg';
import Next from './svg/next.svg';
import Note from './svg/note.svg';
import OpenArrow from './svg/openArrow.svg';
import Pencil from './svg/pencil.svg';
import Person from './svg/person.svg';
import Phone from './svg/phone.svg';
import PinterestGray from './svg/pinterest_gray.svg';
import Plus from './svg/plus.svg';
import Prev from './svg/prev.svg';
import QA from './svg/qa.svg';
import QualityAssurance from './svg/quality_assurance.svg';
import RightArrowSlider from './svg/rightArrowSlider.svg';
import Share from './svg/share.svg';
import Telegram from './svg/telegram.svg';
import Twitter from './svg/twitter.svg';
import TwitterGray from './svg/twitter_gray.svg';
import Upload from './svg/upload.svg';
import VkGray from './svg/vk_gray.svg';
import Move from './svg/move.svg';
import EmptyCart from './svg/emptyCart.svg';
import VerifiedShop from './svg/verifiedShop.svg';
import SearchNoResults from './svg/searchNoResults.svg';
import Status from './svg/status.svg';
import Calendar from './svg/calendar.svg';
import Copy from './svg/copy.svg';
import Coupon from './svg/coupon.svg';
// For product
import MainFoto from './svg/product/mainFoto.svg';
import AngleView from './svg/product/angleView.svg';
import ShowDetails from './svg/product/showDetails.svg';
import ShowInScene from './svg/product/showInScene.svg';
import ShowInUse from './svg/product/showInUse.svg';
import ShowSizes from './svg/product/showSizes.svg';
import ShowVariety from './svg/product/showVariety.svg';
import SortArrows from './svg/sortArrows.svg';
import User from './svg/user.svg';
import Chat from './svg/chat.svg';
import AddVariant from './svg/addVariant.svg';
import styles from './Icon.module.scss';
import { classNames } from '../../utils';
const iconsMap = {
    arrowExpand: React.createElement(ArrowExpand, null),
    arrowLeft: React.createElement(ArrowLeft, null),
    arrowRight: React.createElement(ArrowRight, null),
    arrowSelect: React.createElement(ArrowSelect, null),
    basket: React.createElement(Basket, null),
    burgerMenu: React.createElement(BurgerMenu, null),
    camera: React.createElement(Camera, null),
    cameraPlus: React.createElement(CameraPlus, null),
    cart: React.createElement(Cart, null),
    cats: React.createElement(Cats, null),
    chat: React.createElement(Chat, null),
    closeArrow: React.createElement(CloseArrow, null),
    controls: React.createElement(Controls, null),
    cross: React.createElement(Cross, null),
    crossWhite: React.createElement(CrossWhite, null),
    email: React.createElement(Email, null),
    eye: React.createElement(Eye, null),
    eyeBlue: React.createElement(EyeBlue, null),
    facebook: React.createElement(Facebook, null),
    facebookGray: React.createElement(FacebookGray, null),
    mediumGray: React.createElement(MediumGray, null),
    redditGray: React.createElement(RedditGray, null),
    google: React.createElement(Google, null),
    heart: React.createElement(Heart, null),
    instagram: React.createElement(Instagram, null),
    instagramGray: React.createElement(InstagramGray, null),
    youtubeGray: React.createElement(YoutubeGray, null),
    leftArrowSlider: React.createElement(LeftArrowSlider, null),
    login: React.createElement(Login, null),
    logo: React.createElement(Logo, null),
    //
    logout: React.createElement(Logout, null),
    magnifier: React.createElement(Magnifier, null),
    minus: React.createElement(Minus, null),
    next: React.createElement(Next, null),
    note: React.createElement(Note, null),
    openArrow: React.createElement(OpenArrow, null),
    pencil: React.createElement(Pencil, null),
    person: React.createElement(Person, null),
    phone: React.createElement(Phone, null),
    pinterestGray: React.createElement(PinterestGray, null),
    plus: React.createElement(Plus, null),
    prev: React.createElement(Prev, null),
    qa: React.createElement(QA, null),
    qualityAssurance: React.createElement(QualityAssurance, null),
    rightArrowSlider: React.createElement(RightArrowSlider, null),
    share: React.createElement(Share, null),
    verifiedShop: React.createElement(VerifiedShop, null),
    sortArrows: React.createElement(SortArrows, null),
    telegram: React.createElement(Telegram, null),
    twitter: React.createElement(Twitter, null),
    twitterGray: React.createElement(TwitterGray, null),
    upload: React.createElement(Upload, null),
    user: React.createElement(User, null),
    vkGray: React.createElement(VkGray, null),
    move: React.createElement(Move, null),
    emptyCart: React.createElement(EmptyCart, null),
    searchNoResults: React.createElement(SearchNoResults, null),
    status: React.createElement(Status, null),
    calendar: React.createElement(Calendar, null),
    addVariant: React.createElement(AddVariant, null),
    copy: React.createElement(Copy, null),
    coupon: React.createElement(Coupon, null),
    // For product
    mainFoto: React.createElement(MainFoto, null),
    angleView: React.createElement(AngleView, null),
    showDetails: React.createElement(ShowDetails, null),
    showInScene: React.createElement(ShowInScene, null),
    showInUse: React.createElement(ShowInUse, null),
    showSizes: React.createElement(ShowSizes, null),
    showVariety: React.createElement(ShowVariety, null)
};
export const iconNames = keys(iconsMap);
export class Icon extends PureComponent {
    static defaultProps = {
        inline: false,
        size: 16,
        dataTest: 'icon'
    };
    render() {
        const { type, size, inline, dataTest } = this.props;
        return (React.createElement("div", { "data-test": dataTest, className: classNames(styles, 'container', {
                [`size-${size || '16'}`]: type !== 'logo',
                isLogo: type === 'logo',
                inline
            }) }, pathOr(null, [type], iconsMap)));
    }
}
