import { t } from '../../../../../translation/utils';
const translations = {
    en: {
        error: 'Error: ',
        close: 'Close.',
        saved: 'Saved!',
        somethingGoingWrong: 'Something going wrong :(',
        storeNotFound: 'Store not found :(',
        storeHasBeenSentToModeration: 'Store has been sent to moderation'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
