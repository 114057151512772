import React, { PureComponent } from 'react';
import { pathOr } from 'ramda';
import { Row, Col } from '../../../layout';
import StoresProducts from '../StoresProducts';
import styles from './StoresRow.module.scss';
import StoresData from '../StoresData';
class StoresRow extends PureComponent {
    render() {
        const { store } = this.props;
        const findMostViewedProducts = pathOr(null, ['findMostViewedProducts', 'edges'], store);
        const storeId = store.rawId;
        return (React.createElement("div", { className: styles.container, key: store.id },
            React.createElement(Row, null,
                React.createElement(Col, { size: 12, sm: 12, md: 6 },
                    React.createElement(StoresData, { store: store })),
                React.createElement(Col, { size: 12, sm: 12, md: 6, mdVisible: true }, findMostViewedProducts && (React.createElement(StoresProducts, { storeId: storeId, findMostViewedProducts: findMostViewedProducts }))))));
    }
}
export default StoresRow;
