import { t } from '../../../translation/utils';
const translations = {
    en: {
        myOrders: 'My orders',
        profileSettings: 'Profile Settings',
        myShop: 'My Shop',
        startSelling: 'Start Selling',
        logout: 'Logout'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
