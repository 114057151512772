import React, { Component } from 'react';
import { concat, dissoc, filter, find, head, includes, isEmpty, isNil, keys, map, pathOr, propEq, reject } from 'ramda';
import { validate } from '@sansare/libstqfrontend';
import { v4 as uuidv4 } from 'uuid';
import smoothscroll from '../../../../../../libs/smoothscroll';
import { classNames, debug, renameKeys, error, fromRelayError } from '../../../../../../utils';
import { CreateProductWithAttributesMutation, UpdateProductMutation } from '../../../../../../relay/mutations';
import { Icon } from '../../../../../../components/Icon';
import { Button, Input, InputPrice } from '../../../../../../components/common';
import Characteristics from '../Characteristics';
import Photos from '../Photos';
import Warehouses from '../Warehouses';
import PreOrder from '../PreOrder';
import styles from './VariantForm.module.scss';
import t from './i18n';
class VariantForm extends Component {
    constructor(props) {
        super(props);
        const { customAttributes, variant, mainVariant } = props;
        if (mainVariant && (!variant || variant === 'new')) {
            this.state = {
                vendorCode: null,
                price: null,
                cashback: null,
                discount: null,
                photoMain: mainVariant.photoMain,
                photos: mainVariant.additionalPhotos,
                attributeValues: !isEmpty(customAttributes)
                    ? this.resetAttrValues(customAttributes, null)
                    : [],
                preOrderDays: '',
                preOrder: false
            };
        }
        if (variant && variant !== 'new') {
            const { vendorCode, price, cashback, discount, photoMain, additionalPhotos, preOrderDays, preOrder } = variant;
            this.state = {
                vendorCode,
                price,
                cashback: Math.round((cashback || 0) * 100),
                discount: Math.round((discount || 0) * 100),
                photoMain,
                photos: additionalPhotos,
                attributeValues: !isEmpty(customAttributes)
                    ? this.resetAttrValues(customAttributes, variant)
                    : [],
                preOrderDays: `${preOrderDays || ''}`,
                preOrder
            };
        }
        this.state = {
            ...this.state,
            formErrors: {},
            isLoading: false,
            scrollArr: ['vendorCode', 'price', 'attributes']
        };
        if (process.env.BROWSER) {
            window.scroll({ top: 0 });
        }
    }
    handleAddMainPhoto = (url) => {
        this.setState({ photoMain: url });
    };
    handleAddPhoto = (photosUrls) => {
        this.setState((prevState) => ({ photos: concat(prevState.photos || [], photosUrls) }));
    };
    handleRemovePhoto = (url) => {
        const { photoMain, photos } = this.state;
        if (url === photoMain) {
            this.setState({ photoMain: null });
            return;
        }
        this.setState({ photos: reject((n) => url === n, photos) });
    };
    handleVendorCodeChange = (e) => {
        const { value } = e.target;
        this.setState((prevState) => ({
            formErrors: dissoc('vendorCode', prevState.formErrors),
            vendorCode: value
        }));
    };
    handlePriceChange = (value) => {
        this.setState((prevState) => ({
            formErrors: dissoc('price', prevState.formErrors),
            price: value
        }));
    };
    handlePercentChange = (id) => (e) => {
        const { value } = e.target;
        if (value === '') {
            this.setState({ [id]: null });
            return;
        }
        if (+value === 0) {
            this.setState({ [id]: 0 });
            return;
        }
        if (parseFloat(value) > 100) {
            this.setState({ [id]: 100 });
            return;
        }
        if (Number.isNaN(parseFloat(value))) {
            return;
        }
        this.setState({ [id]: parseFloat(value) });
    };
    resetAttrValues = (customAttributes, variant) => {
        const attributeValues = pathOr(null, ['form', 'attributeValues'], this.state);
        const attrValues = map((item) => {
            if (attributeValues) {
                const isAttributeValue = find(propEq(item.rawId, 'attrId'))(attributeValues);
                if (isAttributeValue) {
                    return isAttributeValue;
                }
            }
            return {
                attrId: item.rawId,
                ...this.valueForAttribute({
                    attr: item,
                    variant
                })
            };
        }, customAttributes);
        return attrValues;
    };
    valueForAttribute = (input) => {
        const { attr, variant } = input;
        const attrFromVariant = variant
            && variant.attributes
            && find((item) => item.attribute.rawId === attr.rawId, variant.attributes);
        if (attrFromVariant && attrFromVariant.value) {
            return {
                value: attrFromVariant.value,
                metaField: attrFromVariant.metaField
            };
        }
        const { values, translatedValues } = attr.metaField;
        if (values) {
            return { value: head(values) || '' };
        }
        if (translatedValues && !isEmpty(translatedValues)) {
            return {
                value: pathOr('', [0, 'translations', 0, 'text'], translatedValues || [])
            };
        }
        return { value: '' };
    };
    handleChangeValues = (values) => {
        this.setState((prevState) => ({
            attributeValues: values,
            formErrors: dissoc('attributes', prevState.formErrors)
        }));
    };
    handleChangePreOrder = (data) => {
        this.setState({
            preOrderDays: data.preOrderDays,
            preOrder: data.preOrder
        });
    };
    validate = () => {
        const { errors } = validate({
            vendorCode: [[(val) => Boolean(val), 'SKU is required']],
            price: [[(val) => Boolean(val), 'Price is required']]
        }, this.state);
        if (errors && !isEmpty(errors)) {
            this.scrollToError(errors);
        }
        return errors;
    };
    scrollToError = (errors) => {
        const { scrollArr } = this.state;
        const oneArr = filter((item) => includes(item, keys(errors)), scrollArr);
        if (!isEmpty(oneArr) && head(oneArr)) {
            smoothscroll.scrollTo(head(oneArr));
        }
    };
    handleCreateVariant = () => {
        const preValidationErrors = this.validate();
        if (preValidationErrors) {
            this.setState({ formErrors: preValidationErrors || {} });
            return;
        }
        const { productId, productRawId, cancelVariantForm } = this.props;
        const { price, vendorCode, photoMain, photos, cashback, discount, preOrder, preOrderDays, attributeValues } = this.state;
        this.setState({ isLoading: true });
        const params = {
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    product: {
                        baseProductId: productRawId,
                        price: price || 0,
                        vendorCode: vendorCode || '',
                        photoMain,
                        additionalPhotos: photos,
                        cashback: cashback ? cashback / 100 : null,
                        discount: discount ? discount / 100 : null,
                        preOrder,
                        preOrderDays: Number(preOrderDays)
                    },
                    attributes: attributeValues
                }
            },
            parentID: productId,
            environment: this.props.environment,
            onCompleted: (response, errors) => {
                this.setState({ isLoading: false });
                debug({
                    response,
                    errors
                });
                const relayErrors = fromRelayError({ source: { errors } });
                debug({ relayErrors });
                const validationErrors = pathOr({}, ['100', 'messages'], relayErrors);
                if (!isEmpty(validationErrors)) {
                    const formErrors = renameKeys({ vendor_code: 'vendorCode' }, validationErrors);
                    this.setState({ formErrors });
                    this.scrollToError(formErrors);
                    return;
                }
                const statusError = pathOr({}, ['100', 'status'], relayErrors);
                if (!isEmpty(statusError)) {
                    this.props.showAlert({
                        type: 'danger',
                        text: `Error: "${statusError}"`,
                        link: { text: 'Close.' }
                    });
                    return;
                }
                const parsingError = pathOr(null, ['300', 'message'], relayErrors);
                if (parsingError) {
                    debug('parsingError:', { parsingError });
                    this.props.showAlert({
                        type: 'danger',
                        text: 'Something going wrong :(',
                        link: { text: 'Close.' }
                    });
                    return;
                }
                if (errors) {
                    this.props.showAlert({
                        type: 'danger',
                        text: 'Something going wrong :(',
                        link: { text: 'Close.' }
                    });
                    return;
                }
                this.props.showAlert({
                    type: 'success',
                    text: 'Variant create!',
                    link: { text: '' }
                });
                cancelVariantForm();
            },
            onError: (err) => {
                this.setState({ isLoading: false });
                error(err);
                this.props.showAlert({
                    type: 'danger',
                    text: 'Something going wrong.',
                    link: { text: 'Close.' }
                });
            }
        };
        CreateProductWithAttributesMutation.commit(params);
    };
    handleUpdateVariant = () => {
        const preValidationErrors = this.validate();
        if (preValidationErrors) {
            this.setState({ formErrors: preValidationErrors || {} });
            return;
        }
        const { cancelVariantForm, variant } = this.props;
        if (!variant || variant === 'new') {
            return;
        }
        const { price, vendorCode, photoMain, photos, cashback, discount, preOrder, preOrderDays, attributeValues } = this.state;
        this.setState({ isLoading: true });
        const params = {
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    id: variant.id,
                    product: {
                        price,
                        vendorCode,
                        photoMain: photoMain || '',
                        additionalPhotos: photos,
                        cashback: cashback ? cashback / 100 : 0,
                        discount: discount ? discount / 100 : 0,
                        preOrder,
                        preOrderDays: Number(preOrderDays)
                    },
                    attributes: attributeValues
                }
            },
            environment: this.props.environment,
            onCompleted: (response, errors) => {
                this.setState({ isLoading: false });
                debug({
                    response,
                    errors
                });
                const relayErrors = fromRelayError({ source: { errors } });
                debug({ relayErrors });
                const validationErrors = pathOr({}, ['100', 'messages'], relayErrors);
                if (!isEmpty(validationErrors)) {
                    const formErrors = renameKeys({ vendor_code: 'vendorCode' }, validationErrors);
                    this.setState({ formErrors });
                    this.scrollToError(formErrors);
                    return;
                }
                const statusError = pathOr({}, ['100', 'status'], relayErrors);
                if (!isEmpty(statusError)) {
                    this.props.showAlert({
                        type: 'danger',
                        text: `Error: "${statusError}"`,
                        link: { text: 'Close.' }
                    });
                    return;
                }
                const parsingError = pathOr(null, ['300', 'message'], relayErrors);
                if (parsingError) {
                    debug('parsingError:', { parsingError });
                    this.props.showAlert({
                        type: 'danger',
                        text: 'Something going wrong :(',
                        link: { text: 'Close.' }
                    });
                    return;
                }
                if (errors) {
                    this.props.showAlert({
                        type: 'danger',
                        text: 'Something going wrong :(',
                        link: { text: 'Close.' }
                    });
                    return;
                }
                this.props.showAlert({
                    type: 'success',
                    text: 'Variant updated!',
                    link: { text: '' }
                });
                cancelVariantForm();
            },
            onError: (err) => {
                this.setState(() => ({ isLoading: false }));
                error(err);
                this.props.showAlert({
                    type: 'danger',
                    text: 'Something going wrong.',
                    link: { text: 'Close.' }
                });
            }
        };
        UpdateProductMutation.commit(params);
    };
    render() {
        const { cancelVariantForm, currency, customAttributes, variant } = this.props;
        const { photos, photoMain, vendorCode, price, formErrors, cashback, discount, attributeValues, isLoading, preOrder, preOrderDays } = this.state;
        return (React.createElement("div", { className: styles.container },
            React.createElement("button", { className: styles.cross, onClick: () => cancelVariantForm(true) },
                React.createElement(Icon, { type: "cross", size: 16 })),
            React.createElement("div", { className: styles.title },
                React.createElement("strong", null, t.productPhotos)),
            React.createElement("div", { className: styles.photos },
                React.createElement(Photos, { photos: photos, photoMain: photoMain, onAddMainPhoto: this.handleAddMainPhoto, onAddPhoto: this.handleAddPhoto, onRemovePhoto: this.handleRemovePhoto })),
            React.createElement("div", { className: classNames(styles, 'title', 'titleGeneral') },
                React.createElement("strong", null, t.generalSettings)),
            React.createElement("div", { className: styles.formItem },
                React.createElement(Input, { id: "vendorCode", fullWidth: true, label: (React.createElement("span", null,
                        t.SKU,
                        " ",
                        React.createElement("span", { className: styles.asterisk }, "*"))), value: vendorCode || '', onChange: this.handleVendorCodeChange, errors: formErrors && formErrors.vendorCode, dataTest: "vendorCode" })),
            React.createElement("div", { className: classNames(styles, 'title', 'titlePricing') },
                React.createElement("strong", null, t.pricing)),
            React.createElement("div", { className: styles.formItem },
                React.createElement(InputPrice, { id: "price", required: true, label: t.price, onChangePrice: this.handlePriceChange, price: parseFloat(String(price)) || 0, currency: currency, errors: formErrors && formErrors.price, dataTest: "price" })),
            React.createElement("div", { className: styles.formItem },
                React.createElement(Input, { fullWidth: true, id: "cashback", label: t.cashback, onChange: this.handlePercentChange('cashback'), value: !isNil(cashback) ? `${cashback}` : '', dataTest: "Cashback" }),
                React.createElement("span", { className: styles.inputPostfix }, t.percent)),
            React.createElement("div", { className: styles.formItem },
                React.createElement(Input, { fullWidth: true, id: "discount", label: t.discount, onChange: this.handlePercentChange('discount'), value: !isNil(discount) ? `${discount}` : '', dataTest: "Discount" }),
                React.createElement("span", { className: styles.inputPostfix }, "Percent")),
            !isEmpty(customAttributes) && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: classNames(styles, 'title', 'titleCharacteristics') },
                    React.createElement("strong", null, t.characteristics)),
                React.createElement("div", { className: styles.characteristics },
                    React.createElement(Characteristics, { customAttributes: customAttributes, values: attributeValues || [], onChange: this.handleChangeValues, errors: (formErrors && formErrors.attributes) || null })))),
            React.createElement("div", { className: styles.preOrder },
                React.createElement(PreOrder, { preOrderDays: preOrderDays, preOrder: preOrder, onChangePreOrder: this.handleChangePreOrder })),
            React.createElement("div", { className: styles.warehouses }, variant
                && !isEmpty(variant)
                && variant !== 'new' && React.createElement(Warehouses, { stocks: variant.stocks })),
            React.createElement("div", { className: styles.footer },
                React.createElement("div", { className: styles.buttons },
                    React.createElement("div", { className: styles.saveButton },
                        React.createElement(Button, { big: true, fullWidth: true, onClick: variant === 'new'
                                ? this.handleCreateVariant
                                : this.handleUpdateVariant, dataTest: "saveVariantButton", isLoading: isLoading }, t.save)),
                    React.createElement("div", { className: styles.cancelButton },
                        React.createElement(Button, { big: true, fullWidth: true, wireframe: true, onClick: () => {
                                cancelVariantForm(true);
                            }, dataTest: "cancelVariantButton" }, t.cancel))))));
    }
}
export default VariantForm;
