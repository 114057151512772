import { filter, pathEq, isEmpty } from 'ramda';
const codes = {
    jwtExpired: 111,
    jwtRevoked: 112
};
// indicates that user should refresh current token
const isJwtExpiredErrorInResponse = (response) => {
    const isErrorExists = response && response.errors && response.errors instanceof Array;
    if (isErrorExists == null || !isErrorExists) {
        return false;
    }
    const errorsWith111Code = filter(pathEq(codes.jwtExpired, ['data', 'code']), (response && response.errors) || []);
    return !isEmpty(errorsWith111Code);
};
// indicates that token can not be refreshed anymore
const isJwtRevokedInResponse = (response) => {
    const isErrorExists = response && response.errors && response.errors;
    if (!isErrorExists) {
        return false;
    }
    const errorsWith112Code = filter(pathEq(codes.jwtRevoked, ['data', 'code']), (response && response.errors) || []);
    return !isEmpty(errorsWith112Code);
};
export { isJwtExpiredErrorInResponse, isJwtRevokedInResponse };
