import { t } from '../../../../../translation/utils';
const translations = {
    en: {
        international: 'International',
        indian: 'Indian',
        save: 'Save',
        cancel: 'Cancel',
        edit: 'Edit',
        error: 'Error: ',
        close: 'Close.',
        somethingGoingWrong: 'Something going wrong :('
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
