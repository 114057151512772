import React, { Component } from 'react';
import { addIndex, assoc, complement, filter, findIndex, isNil, map, pathOr, propEq } from 'ramda';
import { convertSrc, error, uploadFile, warn } from '../../../../../../utils';
import { UploadWrapper } from '../../../../../../components/Upload';
import { Select } from '../../../../../../components/common/Select';
import { Icon } from '../../../../../../components/Icon';
import styles from './Characteristics.module.scss';
import t from './i18n';
class CharacteristicItem extends Component {
    constructor(props) {
        super(props);
        this.state = { isPhotoUploading: false };
    }
    getSelectItems = (attribute) => {
        const values = pathOr(null, ['metaField', 'values'], attribute);
        const translatedValues = pathOr([], ['metaField', 'translatedValues'], attribute);
        const mapIndexed = addIndex(map);
        if (values) {
            return mapIndexed((item, idx) => ({
                id: `${idx}`,
                label: item
            }), values);
        }
        const items = mapIndexed((item, idx) => {
            const text = pathOr(null, ['translations', 0, 'text'], item);
            if (text) {
                return {
                    id: `${idx}`,
                    label: text
                };
            }
            return null;
        }, translatedValues || []);
        return filter(complement(isNil), items);
    };
    handleSelect = (value) => {
        this.props.onSelect({
            ...this.props.value,
            value: value.label,
            attrId: this.props.attribute.rawId
        });
    };
    handleOnUpload = (e) => {
        e.preventDefault();
        this.setState({ isPhotoUploading: true });
        uploadFile(e.target.files[0])
            .then((result) => {
            if (!result || result.url == null) {
                error(result);
                alert('Error :(');
            }
            this.props.onSelect(assoc('metaField', result.url, this.props.value));
            return true;
        })
            .finally(() => {
            this.setState({ isPhotoUploading: false });
        })
            .catch(alert);
    };
    handleRemoveImg = () => {
        const { onSelect, value } = this.props;
        onSelect(assoc('metaField', '', value));
    };
    render() {
        const { attribute, value } = this.props;
        const { isPhotoUploading } = this.state;
        if (!value) {
            warn('CharacteristicItem', 'value is nil');
            return null;
        }
        const items = this.getSelectItems(attribute);
        const selectedItem = {
            id: `${findIndex(propEq(value.value, 'label'), items)}`,
            label: value.value
        };
        const { metaField: characteristicImg } = this.props.value;
        const name = pathOr('', ['name', 0, 'text'], attribute);
        return (React.createElement("div", { className: styles.item },
            React.createElement("div", { className: styles.characteristicImg },
                React.createElement("div", null,
                    React.createElement(UploadWrapper, { id: attribute.id, buttonLabel: t.labelAddPhoto, onUpload: this.handleOnUpload, buttonHeight: 10, buttonWidth: 10, buttonIconType: "upload", overPicture: convertSrc('small', characteristicImg), loading: isPhotoUploading, dataTest: "productCharacteristicImgUploader" })),
                characteristicImg && (React.createElement("div", { className: styles.remove },
                    React.createElement("div", { onClick: this.handleRemoveImg, onKeyDown: () => {
                        }, role: "button", tabIndex: 0 },
                        React.createElement(Icon, { type: "basket", size: 32 }))))),
            React.createElement("div", { className: styles.characteristicSelect },
                React.createElement(Select, { forForm: true, fullWidth: true, label: name, activeItem: selectedItem, items: items, onSelect: this.handleSelect, dataTest: "characteristicSelect" }))));
    }
}
export default CharacteristicItem;
