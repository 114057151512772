/**
 * @generated SignedSource<<6d4f324e58ba94e789042717531140f4>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "companyPackageId",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "price",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "label",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "alpha3",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "Shipping_baseProduct",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "ShippingOutput",
                "kind": "LinkedField",
                "name": "shipping",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "LocalShippingProducts",
                        "kind": "LinkedField",
                        "name": "local",
                        "plural": true,
                        "selections": [
                            (v0 /*: any*/),
                            (v1 /*: any*/)
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "InternationalShippingProducts",
                        "kind": "LinkedField",
                        "name": "international",
                        "plural": true,
                        "selections": [
                            (v0 /*: any*/),
                            (v1 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Country",
                                "kind": "LinkedField",
                                "name": "deliveriesTo",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Country",
                                        "kind": "LinkedField",
                                        "name": "children",
                                        "plural": true,
                                        "selections": [
                                            (v2 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Country",
                                                "kind": "LinkedField",
                                                "name": "children",
                                                "plural": true,
                                                "selections": [
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "parent",
                                                        "storageKey": null
                                                    },
                                                    (v3 /*: any*/),
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "alpha2",
                                                        "storageKey": null
                                                    },
                                                    (v2 /*: any*/)
                                                ],
                                                "storageKey": null
                                            },
                                            (v3 /*: any*/)
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PickupsOutput",
                        "kind": "LinkedField",
                        "name": "pickup",
                        "plural": false,
                        "selections": [
                            (v1 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "pickup",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "BaseProduct",
        "abstractKey": null
    };
})();
node.hash = "9487bd7316a4c96b6b44ea621f5873a2";
export default node;
