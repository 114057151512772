/**
 * @generated SignedSource<<bd839982e12dd905a7a8f93eb32731a8>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "WizardStore",
            "kind": "LinkedField",
            "name": "deleteWizardStore",
            "plural": false,
            "selections": [
                (v0 /*: any*/),
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Store",
                    "kind": "LinkedField",
                    "name": "store",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rawId",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "completed",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "DeleteWizardMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "DeleteWizardMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "fa845f3f7c5e4f0d9474f4bae9a85e66",
            "id": null,
            "metadata": {},
            "name": "DeleteWizardMutation",
            "operationKind": "mutation",
            "text": "mutation DeleteWizardMutation {\n  deleteWizardStore {\n    id\n    store {\n      id\n      rawId\n    }\n    completed\n  }\n}\n"
        }
    };
})();
node.hash = "0d57339bb4d2a4ea9aa74b88ba1423d5";
export default node;
