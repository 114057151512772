import { commitMutation, graphql } from 'react-relay';
const mutation = graphql `
	mutation UpdateProductMutation($input: UpdateProductWithAttributesInput!) {
		updateProduct(input: $input) {
			id
			rawId
			discount
			photoMain
			additionalPhotos
			vendorCode
			cashback
			price
			quantity
			preOrder
			preOrderDays
			baseProduct {
				id
				rawId
				status
				store {
					id
					warehouses {
						id
					}
				}
			}
			attributes {
				attrId
				value
				metaField
				attribute {
					id
					rawId
					name {
						lang
						text
					}
					metaField {
						values
						translatedValues {
							translations {
								text
							}
						}
					}
				}
			}
			stocks {
				id
				productId
				warehouseId
				warehouse {
					name
					addressFull {
						country
						countryCode
						administrativeAreaLevel1
						administrativeAreaLevel2
						political
						postalCode
						streetNumber
						value
						route
						locality
					}
				}
				quantity
			}
		}
	}
`;
const commit = (params) => commitMutation(params.environment, {
    mutation,
    variables: params.variables,
    onCompleted: params.onCompleted,
    onError: params.onError
});
const promise = (variables, environment) => new Promise((resolve, reject) => commit({
    variables,
    environment,
    onCompleted: (response, errors) => {
        if (response) {
            return resolve(response);
        }
        if (errors) {
            return reject(errors);
        }
        return reject([new Error('Unknown error')]);
    },
    onError: (err) => reject([err])
}));
export default {
    commit,
    promise
};
