import React from 'react';
import { Link } from 'found';
import { convertSrc } from '../../../utils';
import { Icon } from '../../Icon';
import styles from '../UserDropdown.module.scss';
import t from './i18n';
const ProfileMenu = ({ lastName, firstName, 
// messagesCount,
email, avatar, myStoreId, isWizardComplete }) => (React.createElement("div", { className: styles.menu },
    React.createElement("div", { className: styles.top },
        React.createElement("div", { className: styles.icon }, avatar ? (React.createElement("img", { className: styles.topImg, src: convertSrc('small', avatar), alt: "img" })) : (React.createElement(Icon, { type: "person", size: 32 }))),
        React.createElement("div", { className: styles.personalData },
            React.createElement("div", { className: styles.name },
                React.createElement("span", null, firstName || '\u00A0'),
                React.createElement("br", null),
                React.createElement("span", null, lastName || '\u00A0')),
            React.createElement("div", { className: styles.email }, email))),
    React.createElement("div", { className: styles.items },
        React.createElement(Link, { to: "/profile/orders", className: styles.item, "data-test": "header-user-menu-profileOrdersLink" }, t.myOrders),
        React.createElement(Link, { to: "/profile", className: styles.item, "data-test": "header-user-menu-profileLink" }, t.profileSettings),
        isWizardComplete && myStoreId ? (React.createElement(Link, { to: `/manage/store/${myStoreId}`, className: styles.item, "data-test": "header-user-menu-myShops" },
            React.createElement("span", null, t.myShop))) : (React.createElement(React.Fragment, null, !isWizardComplete && myStoreId ? (React.createElement(Link, { to: "/manage/wizard", className: styles.item, "data-test": "header-user-menu-sellingLink" }, t.startSelling)) : (React.createElement("a", { 
            // href="https://selling.graame.com/"
            href: "/start-selling", className: styles.item, "data-test": "header-user-menu-sellingLink" }, t.startSelling))))),
    React.createElement(Link, { className: styles.logout, to: "/logout", "data-test": "header-user-menu-logoutLink" },
        React.createElement(Icon, { inline: true, type: "logout", size: 24 }),
        React.createElement("span", { className: styles.logoutText }, t.logout))));
export default ProfileMenu;
