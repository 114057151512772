export default (productId) => {
    if (window) {
        (window.rrApiOnReady = window.rrApiOnReady || []).push(() => {
            try {
                // rrApi.view(productId);
            }
            catch (e) {
                //
            }
        });
    }
};
