import React, { createContext, PureComponent } from 'react';
const { Provider: AlertContextProvider, Consumer: AlertContextConsumer } = createContext({
    addAlert: () => {
    }
});
const withShowAlert = (Component) => class extends PureComponent {
    render() {
        return (React.createElement(AlertContextConsumer, null, (value) => (React.createElement(Component, { ...this.props, showAlert: value.addAlert }))));
    }
};
export { AlertContextProvider, AlertContextConsumer, withShowAlert };
