/**
 * @generated SignedSource<<f657782ec9c41e3aa4713115279573be>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "after"
    }, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "first"
    }, v2 = [
        {
            "kind": "Variable",
            "name": "after",
            "variableName": "after"
        },
        {
            "kind": "Variable",
            "name": "first",
            "variableName": "first"
        }
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lang",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "concreteType": "Translation",
        "kind": "LinkedField",
        "name": "name",
        "plural": true,
        "selections": [
            (v4 /*: any*/),
            (v5 /*: any*/)
        ],
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawId",
        "storageKey": null
    }, v8 = [
        (v5 /*: any*/),
        (v4 /*: any*/)
    ], v9 = {
        "alias": null,
        "args": null,
        "concreteType": "Translation",
        "kind": "LinkedField",
        "name": "name",
        "plural": true,
        "selections": (v8 /*: any*/),
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "Products_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        {
                            "args": (v2 /*: any*/),
                            "kind": "FragmentSpread",
                            "name": "Products_me"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "Products_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Store",
                            "kind": "LinkedField",
                            "name": "myStore",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "logo",
                                    "storageKey": null
                                },
                                (v6 /*: any*/),
                                {
                                    "alias": null,
                                    "args": (v2 /*: any*/),
                                    "concreteType": "BaseProductsConnection",
                                    "kind": "LinkedField",
                                    "name": "baseProducts",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "BaseProductsEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "BaseProduct",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        (v7 /*: any*/),
                                                        (v6 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Translation",
                                                            "kind": "LinkedField",
                                                            "name": "shortDescription",
                                                            "plural": true,
                                                            "selections": (v8 /*: any*/),
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Category",
                                                            "kind": "LinkedField",
                                                            "name": "category",
                                                            "plural": false,
                                                            "selections": [
                                                                (v3 /*: any*/),
                                                                (v7 /*: any*/),
                                                                (v9 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "storeId",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "currency",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": [
                                                                {
                                                                    "kind": "Literal",
                                                                    "name": "first",
                                                                    "value": 1
                                                                }
                                                            ],
                                                            "concreteType": "ProductsConnection",
                                                            "kind": "LinkedField",
                                                            "name": "products",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "ProductsEdge",
                                                                    "kind": "LinkedField",
                                                                    "name": "edges",
                                                                    "plural": true,
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "Product",
                                                                            "kind": "LinkedField",
                                                                            "name": "node",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                (v3 /*: any*/),
                                                                                (v7 /*: any*/),
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "price",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "discount",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "photoMain",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "additionalPhotos",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "vendorCode",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "cashback",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "concreteType": "ProdAttrValue",
                                                                                    "kind": "LinkedField",
                                                                                    "name": "attributes",
                                                                                    "plural": true,
                                                                                    "selections": [
                                                                                        {
                                                                                            "alias": null,
                                                                                            "args": null,
                                                                                            "kind": "ScalarField",
                                                                                            "name": "value",
                                                                                            "storageKey": null
                                                                                        },
                                                                                        {
                                                                                            "alias": null,
                                                                                            "args": null,
                                                                                            "kind": "ScalarField",
                                                                                            "name": "metaField",
                                                                                            "storageKey": null
                                                                                        },
                                                                                        {
                                                                                            "alias": null,
                                                                                            "args": null,
                                                                                            "concreteType": "Attribute",
                                                                                            "kind": "LinkedField",
                                                                                            "name": "attribute",
                                                                                            "plural": false,
                                                                                            "selections": [
                                                                                                (v3 /*: any*/),
                                                                                                (v7 /*: any*/),
                                                                                                (v9 /*: any*/),
                                                                                                {
                                                                                                    "alias": null,
                                                                                                    "args": null,
                                                                                                    "concreteType": "AttributeMetaField",
                                                                                                    "kind": "LinkedField",
                                                                                                    "name": "metaField",
                                                                                                    "plural": false,
                                                                                                    "selections": [
                                                                                                        {
                                                                                                            "alias": null,
                                                                                                            "args": null,
                                                                                                            "kind": "ScalarField",
                                                                                                            "name": "values",
                                                                                                            "storageKey": null
                                                                                                        },
                                                                                                        {
                                                                                                            "alias": null,
                                                                                                            "args": null,
                                                                                                            "concreteType": "TranslatedValue",
                                                                                                            "kind": "LinkedField",
                                                                                                            "name": "translatedValues",
                                                                                                            "plural": true,
                                                                                                            "selections": [
                                                                                                                {
                                                                                                                    "alias": null,
                                                                                                                    "args": null,
                                                                                                                    "concreteType": "Translation",
                                                                                                                    "kind": "LinkedField",
                                                                                                                    "name": "translations",
                                                                                                                    "plural": true,
                                                                                                                    "selections": [
                                                                                                                        (v4 /*: any*/)
                                                                                                                    ],
                                                                                                                    "storageKey": null
                                                                                                                }
                                                                                                            ],
                                                                                                            "storageKey": null
                                                                                                        }
                                                                                                    ],
                                                                                                    "storageKey": null
                                                                                                }
                                                                                            ],
                                                                                            "storageKey": null
                                                                                        }
                                                                                    ],
                                                                                    "storageKey": null
                                                                                }
                                                                            ],
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": "products(first:1)"
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "__typename",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "cursor",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PageInfo",
                                            "kind": "LinkedField",
                                            "name": "pageInfo",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "endCursor",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "hasNextPage",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v2 /*: any*/),
                                    "filters": null,
                                    "handle": "connection",
                                    "key": "Wizard_baseProducts",
                                    "kind": "LinkedHandle",
                                    "name": "baseProducts"
                                }
                            ],
                            "storageKey": null
                        },
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "d0aa4a8f8bee725110a14760e07d2eda",
            "id": null,
            "metadata": {},
            "name": "Products_Query",
            "operationKind": "query",
            "text": "query Products_Query(\n  $first: Int\n  $after: ID\n) {\n  me {\n    ...Products_me_2HEEH6\n    id\n  }\n}\n\nfragment Products_me_2HEEH6 on User {\n  myStore {\n    id\n    logo\n    name {\n      text\n      lang\n    }\n    baseProducts(first: $first, after: $after) {\n      edges {\n        node {\n          id\n          rawId\n          name {\n            text\n            lang\n          }\n          shortDescription {\n            lang\n            text\n          }\n          category {\n            id\n            rawId\n            name {\n              lang\n              text\n            }\n          }\n          storeId\n          currency\n          products(first: 1) {\n            edges {\n              node {\n                id\n                rawId\n                price\n                discount\n                photoMain\n                additionalPhotos\n                vendorCode\n                cashback\n                attributes {\n                  value\n                  metaField\n                  attribute {\n                    id\n                    rawId\n                    name {\n                      lang\n                      text\n                    }\n                    metaField {\n                      values\n                      translatedValues {\n                        translations {\n                          text\n                        }\n                      }\n                    }\n                  }\n                }\n              }\n            }\n          }\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "f4db2fe1332bb979e4b31c4a041ef6e0";
export default node;
