/**
 * @generated SignedSource<<fbf18a90e7127527d1689b9570d33a00>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Finances_me",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "Store",
            "kind": "LinkedField",
            "name": "myStore",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "StoreSubscription",
                    "kind": "LinkedField",
                    "name": "storeSubscription",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "walletAddress",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "productsCount",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Cards_me"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PaymentAccount_me"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Balance_me"
        }
    ],
    "type": "User",
    "abstractKey": null
};
node.hash = "5104b4d02f1a26d7d203b4ec68013630";
export default node;
