import React, { Component } from 'react';
import styles from './AuthorizationHeader.module.scss';
import { classNames } from '../../../utils';
class AuthorizationHeader extends Component {
    handleClick = (name, selected) => {
        const { onClick } = this.props;
        onClick(name, selected);
    };
    static defaultProps = {
        fullWidth: false
    };
    render() {
        const { tabs, selected, fullWidth } = this.props;
        return (React.createElement("div", { className: classNames(styles, 'container', { fullWidth }) },
            React.createElement("nav", { className: styles.tabsContainer },
                React.createElement("ul", { className: classNames(styles, 'tabs', { fullWidth }) }, tabs.map(({ id, name }, index) => (React.createElement("li", { tabIndex: -1, key: id, onClick: () => this.handleClick(name, index), onKeyPress: () => {
                    }, className: classNames(styles, 'tab', {
                        clicked: selected === index
                    }) }, name)))))));
    }
}
export default AuthorizationHeader;
