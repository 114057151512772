/**
 * @generated SignedSource<<6a820d2a8ffb4b66ffb73a2a2693dcf2>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "name"
        }
    ], v1 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "Search",
            "kind": "LinkedField",
            "name": "search",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "name",
                            "variableName": "name"
                        }
                    ],
                    "concreteType": "FullNameConnection",
                    "kind": "LinkedField",
                    "name": "autoCompleteStoreName",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "FullNameEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "node",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "fetchAutoCompleteStoreName_Query",
            "selections": (v1 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "fetchAutoCompleteStoreName_Query",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "01619be20c8e78216815f506c3e23b27",
            "id": null,
            "metadata": {},
            "name": "fetchAutoCompleteStoreName_Query",
            "operationKind": "query",
            "text": "query fetchAutoCompleteStoreName_Query(\n  $name: String!\n) {\n  search {\n    autoCompleteStoreName(name: $name) {\n      edges {\n        node\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "01029c7ab8477ec4e7b0ec90198a62ff";
export default node;
