/**
 * @generated SignedSource<<5c4b15501e53a45b138f371f5de9f943>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "storageSlug"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "concreteType": "Translation",
        "kind": "LinkedField",
        "name": "name",
        "plural": true,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lang",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawId",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "routes_StorageProducts_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        {
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "storageSlug",
                                    "variableName": "storageSlug"
                                }
                            ],
                            "kind": "FragmentSpread",
                            "name": "StorageProducts_me"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "routes_StorageProducts_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "slug",
                                    "variableName": "storageSlug"
                                }
                            ],
                            "concreteType": "GraphQLWarehouse",
                            "kind": "LinkedField",
                            "name": "warehouse",
                            "plural": false,
                            "selections": [
                                (v1 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": [
                                        {
                                            "kind": "Literal",
                                            "name": "first",
                                            "value": 8
                                        },
                                        {
                                            "kind": "Literal",
                                            "name": "name",
                                            "value": ""
                                        }
                                    ],
                                    "concreteType": "FullNameConnection",
                                    "kind": "LinkedField",
                                    "name": "autoCompleteProductName",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "FullNameEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "node",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": "autoCompleteProductName(first:8,name:\"\")"
                                },
                                {
                                    "alias": null,
                                    "args": [
                                        {
                                            "kind": "Literal",
                                            "name": "currentPage",
                                            "value": 1
                                        },
                                        {
                                            "kind": "Literal",
                                            "name": "itemsCount",
                                            "value": 10
                                        },
                                        {
                                            "kind": "Literal",
                                            "name": "visibility",
                                            "value": "active"
                                        }
                                    ],
                                    "concreteType": "StocksConnection",
                                    "kind": "LinkedField",
                                    "name": "products",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "StocksEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "GraphQLStock",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "productId",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Product",
                                                            "kind": "LinkedField",
                                                            "name": "product",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "BaseProduct",
                                                                    "kind": "LinkedField",
                                                                    "name": "baseProduct",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v2 /*: any*/),
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "Category",
                                                                            "kind": "LinkedField",
                                                                            "name": "category",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                (v2 /*: any*/),
                                                                                (v1 /*: any*/)
                                                                            ],
                                                                            "storageKey": null
                                                                        },
                                                                        (v1 /*: any*/)
                                                                    ],
                                                                    "storageKey": null
                                                                },
                                                                (v1 /*: any*/),
                                                                (v3 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "price",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "photoMain",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "currency",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "ProdAttrValue",
                                                                    "kind": "LinkedField",
                                                                    "name": "attributes",
                                                                    "plural": true,
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "attrId",
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "Attribute",
                                                                            "kind": "LinkedField",
                                                                            "name": "attribute",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                (v1 /*: any*/),
                                                                                (v3 /*: any*/),
                                                                                (v2 /*: any*/)
                                                                            ],
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "value",
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "quantity",
                                                            "storageKey": null
                                                        },
                                                        (v1 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PageInfoWarehouseProductSearch",
                                            "kind": "LinkedField",
                                            "name": "pageInfo",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "totalPages",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "currentPage",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "pageItemsCount",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": "products(currentPage:1,itemsCount:10,visibility:\"active\")"
                                }
                            ],
                            "storageKey": null
                        },
                        (v1 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "bbbc913c2b27ae3a752f5207d494b622",
            "id": null,
            "metadata": {},
            "name": "routes_StorageProducts_Query",
            "operationKind": "query",
            "text": "query routes_StorageProducts_Query(\n  $storageSlug: String!\n) {\n  me {\n    ...StorageProducts_me_2YjLTh\n    id\n  }\n}\n\nfragment StorageProducts_me_2YjLTh on User {\n  warehouse(slug: $storageSlug) {\n    id\n    name\n    autoCompleteProductName(first: 8, name: \"\") {\n      edges {\n        node\n      }\n    }\n    products(currentPage: 1, itemsCount: 10, visibility: \"active\") {\n      edges {\n        node {\n          productId\n          product {\n            baseProduct {\n              name {\n                lang\n                text\n              }\n              category {\n                name {\n                  lang\n                  text\n                }\n                id\n              }\n              id\n            }\n            id\n            rawId\n            price\n            photoMain\n            currency\n            attributes {\n              attrId\n              attribute {\n                id\n                rawId\n                name {\n                  lang\n                  text\n                }\n              }\n              value\n            }\n          }\n          quantity\n          id\n        }\n      }\n      pageInfo {\n        totalPages\n        currentPage\n        pageItemsCount\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "195acd14cc5e691a0f79e5480549a4fd";
export default node;
