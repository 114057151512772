import { t } from '../../../translation/utils';
const translations = {
    en: {
        unknownError: 'Unknown error',
        close: 'Close.',
        cantCreateVariantWithoutBaseProductId: 'Can\'t create variant without base product id',
        error: 'Error',
        productUpdated: 'Product updated!',
        somethingGoingWrong: 'Something going wrong :(',
        cantUploadPhoto: 'Can\'t upload photo',
        doYouReallyWantToLeaveThisPage: 'Do you really want to leave this page?',
        cancel: 'Cancel',
        publishMyStore: 'Publish my store'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
