import React, { Component } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { filter, isEmpty, pathOr, toUpper, whereEq } from 'ramda';
import { Link } from 'found';
import { v4 as uuidv4 } from 'uuid';
import { Button, Input } from '../../../components/common';
import { Rating } from '../../../components/common/Rating';
import { Icon } from '../../../components/Icon';
import { withShowAlert } from '../../../components/Alerts/AlertContext';
import { Col, Container, Row } from '../../../layout';
import { convertSrc, debug, error, formatPrice, fromRelayError, getNameText } from '../../../utils';
import SetCouponInCartMutation from '../../../relay/mutations/SetCouponInCartMutation';
import CartProduct from '../CartProduct';
import styles from './CartStore.module.scss';
import t from './i18n';
class CartStore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            couponCodeValue: '',
            couponCodeButtonDisabled: true,
            isLoadingCouponButton: false
        };
    }
    handleChangeCoupon = (e) => {
        const value = toUpper(e.target.value);
        if (!/^[A-Za-z0-9]*$/.test(value)) {
            return;
        }
        this.setState({
            couponCodeValue: toUpper(value),
            couponCodeButtonDisabled: !value
        });
    };
    handleSetCoupon = () => {
        this.setState({ isLoadingCouponButton: true });
        const storeId = pathOr(null, ['store', 'rawId'], this.props);
        const params = {
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    couponCode: this.state.couponCodeValue,
                    storeId
                }
            },
            environment: this.props.relay.environment,
            onCompleted: (response, errors) => {
                this.setState({ isLoadingCouponButton: false });
                debug({
                    response,
                    errors
                });
                const relayErrors = fromRelayError({ source: { errors } });
                debug({ relayErrors });
                const validationErrors = pathOr({}, ['100', 'messages'], relayErrors);
                if (!isEmpty(validationErrors)) {
                    this.props.showAlert({
                        type: 'danger',
                        text: t.somethingGoingWrong,
                        link: { text: t.close }
                    });
                    return;
                }
                const statusError = pathOr({}, ['100', 'status'], relayErrors);
                if (!isEmpty(statusError)) {
                    this.props.showAlert({
                        type: 'danger',
                        text: `${t.error} "${statusError}"`,
                        link: { text: t.close }
                    });
                    return;
                }
                const status400Error = pathOr(null, ['400', 'status'], relayErrors);
                if (status400Error) {
                    this.props.showAlert({
                        type: 'danger',
                        text: `${t.error} ${status400Error}`,
                        link: { text: t.close }
                    });
                    return;
                }
                if (errors) {
                    this.props.showAlert({
                        type: 'danger',
                        text: t.somethingGoingWrong,
                        link: { text: t.close }
                    });
                    return;
                }
                this.props.showAlert({
                    type: 'success',
                    text: t.couponApplied,
                    link: { text: '' }
                });
            },
            onError: (err) => {
                this.setState(() => ({ isLoadingCouponButton: false }));
                error(err);
                this.props.showAlert({
                    type: 'danger',
                    text: t.somethingGoingWrong,
                    link: { text: t.close }
                });
            }
        };
        SetCouponInCartMutation.commit(params);
    };
    render() {
        const { store, onlySelected, unselectable, isOpenInfo, withDeliveryCompaniesSelect, currency } = this.props;
        const { couponCodeValue, couponCodeButtonDisabled, isLoadingCouponButton } = this.state;
        const { products } = store;
        let filteredProducts = products;
        if (onlySelected) {
            filteredProducts = filter(whereEq({ selected: true }), [...products]);
        }
        if (filteredProducts.length === 0) {
            return null;
        }
        return (React.createElement("div", { className: styles.container },
            filteredProducts.map((product, idx) => (React.createElement("div", { key: idx, className: styles.divider },
                React.createElement(CartProduct, { product: product, onlySelected: onlySelected, unselectable: unselectable, isOpenInfo: isOpenInfo, withDeliveryCompaniesSelect: withDeliveryCompaniesSelect, currency: currency })))),
            React.createElement("div", { className: styles.footer },
                React.createElement(Container, { correct: true },
                    React.createElement(Row, { alignItems: "center" },
                        React.createElement(Col, { size: 12, sm: 3 },
                            React.createElement("div", { className: styles.storeInfo },
                                React.createElement(Link, { to: `/store/${store.rawId}` },
                                    store.logo ? (React.createElement("img", { src: convertSrc('small', store.logo), alt: "store_picture", className: styles.image })) : (React.createElement("div", { className: styles.noLogo },
                                        React.createElement(Icon, { type: "camera", size: 28 }))),
                                    React.createElement("div", { className: styles.storeDescription },
                                        React.createElement("div", { className: styles.storeName }, getNameText(store.name, 'EN')),
                                        React.createElement(Rating, { value: store.rating }))))),
                        React.createElement(Col, { size: 12, sm: 6 },
                            React.createElement("div", { className: styles.coupon },
                                React.createElement("div", { className: styles.couponIcon },
                                    React.createElement(Icon, { type: "coupon", size: 28 })),
                                React.createElement("div", { className: styles.couponInput },
                                    React.createElement(Input, { id: "couponInput", inline: true, fullWidth: true, value: couponCodeValue, onChange: this.handleChangeCoupon })),
                                React.createElement("div", { className: styles.couponButton },
                                    React.createElement(Button, { small: true, disabled: couponCodeButtonDisabled, onClick: this.handleSetCoupon, isLoading: isLoadingCouponButton, dataTest: "couponButton" }, t.applyCode)))),
                        React.createElement(Col, { size: 12, sm: 3 },
                            React.createElement("div", { className: styles.storeTotal },
                                React.createElement("div", { className: styles.storeTotalWrapper },
                                    React.createElement("div", { className: styles.label }, t.subtotal),
                                    Boolean(store.couponsDiscount) && (React.createElement("div", { className: styles.value },
                                        React.createElement("small", { className: styles.through }, `${formatPrice(store.totalCostWithoutDiscounts || 0, 2)} ${currency || ''}`))),
                                    React.createElement("div", { className: styles.value }, `${formatPrice(store.totalCost || 0, 2)} ${currency || ''}`)))))))));
    }
}
export default createFragmentContainer(withShowAlert(CartStore), {
    store: graphql `
			fragment CartStore_store on CartStore {
				id
				rawId
				productsCost
				deliveryCost
				totalCost
				totalCount
				totalCostWithoutDiscounts
				couponsDiscount
				products {
					id
					rawId
					selected
					...CartProduct_product
				}
				name {
					lang
					text
				}
				rating
				logo
			}
    `
});
