import React, { Component } from 'react';
import ImageLoader from '../../../libs/react-image-loader';
import { classNames, convertSrc } from '../../../utils';
import { Icon } from '../../../components/Icon';
import BannerLoading from '../../../components/Banner/BannerLoading';
import { SocialShare } from '../../../components/SocialShare';
import { Rating } from '../../../components/common/Rating';
import StoreContext from '../StoreContext';
import styles from './StoreHeaderInfo.module.scss';
class StoreHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            isOpened: false,
            isSearch: false,
            isShare: false
        };
    }
    handleClick = (iconName) => {
        const { isOpened, isSearch, isShare } = this.state;
        this.setState((preState) => ({
            isOpened: !(preState[iconName] && isOpened) || (!isSearch && !isShare),
            isSearch: iconName === 'isSearch' && !preState[iconName],
            isShare: iconName === 'isShare' && !preState[iconName]
        }));
    };
    handleInputChange = () => {
    };
    render() {
        const { isOpened, isShare } = this.state;
        return (React.createElement(StoreContext.Consumer, null, (s) => (React.createElement("div", { className: styles.container },
            React.createElement("span", { className: classNames(styles, 'share', { hide: !s.facebookUrl && !s.twitterUrl && !s.instagramUrl }), role: "button", onClick: () => this.handleClick('isShare'), onKeyPress: () => {
                }, tabIndex: -1 }, !isShare ? (React.createElement(Icon, { type: "share", size: 20 })) : (React.createElement(Icon, { type: "cross", size: 20 }))),
            React.createElement("span", { className: styles.heart },
                React.createElement(Icon, { type: "heart", size: 28 })),
            React.createElement("figure", { className: styles.shopLogo }, s.logo ? (React.createElement(ImageLoader, { fit: true, src: convertSrc('small', s.logo), loader: React.createElement(BannerLoading, null) })) : (React.createElement(Icon, { type: "camera", size: 56 }))),
            React.createElement("div", { className: classNames(styles, 'mobileSearch', { isOpened }) }, process.env.BROWSER ? (React.createElement("div", { className: styles.share },
                React.createElement(SocialShare, { noBorder: true, facebookUrl: s.facebookUrl, twitterUrl: s.twitterUrl, instagramUrl: s.instagramUrl }))) : null),
            React.createElement("div", { className: styles.shopTitleWrap },
                React.createElement("h2", { className: styles.shopTitle },
                    React.createElement("strong", null, s.name),
                    React.createElement("span", { className: styles.cartIcon },
                        React.createElement(Icon, { type: "verifiedShop", size: 20 }))),
                React.createElement("div", { className: styles.shopRating },
                    React.createElement("div", { className: styles.rating },
                        React.createElement(Rating, { value: s.rating }))))))));
    }
}
export default StoreHeader;
