/**
 * @generated SignedSource<<d0be3394ca20dd30ad7400d2ae175b43>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "Mock",
            "kind": "LinkedField",
            "name": "createDispute",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mock",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "CreateDisputeMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "CreateDisputeMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "b077cac559379e12f56731f03d796a2f",
            "id": null,
            "metadata": {},
            "name": "CreateDisputeMutation",
            "operationKind": "mutation",
            "text": "mutation CreateDisputeMutation(\n  $input: CreateDisputeInput!\n) {\n  createDispute(input: $input) {\n    mock\n  }\n}\n"
        }
    };
})();
node.hash = "e083e896283435611cb1473388a5794e";
export default node;
