import React, { PureComponent } from 'react';
import { addIndex, map, toLower } from 'ramda';
import { timeFromTimestamp, shortDateFromTimestamp } from '../../../../utils/formatDate';
import { StringLoadMore } from '../../../../components/StringLoadMore';
import styles from './StatusList.module.scss';
import t from './i18n';
class StatusList extends PureComponent {
    dateStringFromTimestamp = (timestamp) => `${shortDateFromTimestamp(timestamp)} ${timeFromTimestamp(timestamp)}`;
    renderTitle = () => (React.createElement("div", { className: styles.headerContainer },
        React.createElement("span", { className: styles.headerDate }, t.date),
        React.createElement("span", { className: styles.headerCommitter }, t.committer),
        React.createElement("span", { className: styles.headerStatus }, t.status),
        React.createElement("span", { className: styles.headerAdditionalInfo }, t.additionalInfo)));
    renderRows = (items) => {
        const indexedMap = addIndex(map);
        return indexedMap((item, idx) => (React.createElement("div", { className: styles.rowContainer, key: `order-status-${idx}` },
            React.createElement("div", { className: styles.rowDate }, this.dateStringFromTimestamp(item.date)),
            React.createElement("div", { className: styles.rowCommitter }, toLower(item.committer)),
            React.createElement("div", { className: styles.rowStatus }, item.status),
            React.createElement("div", { className: styles.rowAdditionalInfo }, item.additionalInfo ? (React.createElement(StringLoadMore, { text: item.additionalInfo })) : ('—')))), items);
    };
    render() {
        const { items } = this.props;
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.title },
                React.createElement("strong", null, t.statusHistory)),
            this.renderTitle(),
            this.renderRows(items)));
    }
}
export default StatusList;
