import React, { Component } from 'react';
import { adjust, assoc, map, pipe } from 'ramda';
import { Button } from '../../common/Button';
import { makeInput } from '../utils';
import styles from '../Authorization.module.scss';
import t from './i18n';
import Input from '../Input';
import Policy from '../Policy';
class SignUp extends Component {
    handleCheck = (privacy) => {
        this.props.onPrivacyCheck(privacy);
    };
    makeInputs = () => {
        const inputs = [
            {
                label: t.labelFirstName,
                type: 'text'
            },
            {
                label: t.labelLastName,
                type: 'text'
            },
            {
                label: t.labelEmail,
                type: 'email'
            },
            {
                label: t.labelPassword,
                type: 'password'
            }
        ];
        const makeInputFn = map(makeInput(this.props));
        const setFocus = adjust(0, assoc('thisFocus', true));
        return pipe(makeInputFn, setFocus)(inputs);
    };
    render() {
        const { onRegistrationClick, formValid, isPrivacyChecked, isTermsChecked } = this.props;
        return (React.createElement("div", { className: styles.signUp },
            this.makeInputs().map((input) => (React.createElement("div", { key: input.name, className: styles.inputBlock },
                React.createElement(Input, { ...input, model: this.props[input.name] })))),
            React.createElement(React.Fragment, null,
                React.createElement(Policy, { isPrivacyChecked: isPrivacyChecked, isTermsChecked: isTermsChecked, onCheck: this.handleCheck }),
                React.createElement("div", { className: styles.signUpGroup },
                    React.createElement(Button, { onClick: onRegistrationClick, type: "button", dataTest: "signUpButton", disabled: !(formValid && (isPrivacyChecked && isTermsChecked)), fullWidth: true },
                        React.createElement("span", null, t.signUp))))));
    }
}
export default SignUp;
