export default (data) => {
    if (window) {
        (window.rrApiOnReady = window.rrApiOnReady || []).push(() => {
            try {
                // rrApi.order({
                //               transaction: data.transactionId,
                //               items: data.items
                //             });
            }
            catch (e) {
                //
            }
        });
    }
};
