import { replace } from 'ramda';
const prepareQueryString = (input) => {
    const { url, callbackUrl } = input;
    const queryString = replace(`${callbackUrl}#`, '', url);
    if (queryString && !queryString.startsWith(callbackUrl)) {
        return queryString;
    }
    return replace(`${callbackUrl}?#`, '', url);
};
export default prepareQueryString;
