import React from 'react';
import Stepper from '../../../../components/Stepper';
import styles from './ProductQuantity.module.scss';
import t from './i18n';
import { classNames } from '../../../../utils';
const numberToWords = (val) => {
    if (val <= 10) {
        return `${val}`;
    }
    else if (val >= 11 && val <= 100) {
        return t.numbersInWords.dozens;
    }
    return t.numbersInWords.hundreds;
};
const ProductQuantity = (props) => {
    const isPreOrderAvailable = Boolean(props.quantity === 0 && props.preOrder && props.preOrderDays);
    return (React.createElement("div", { className: styles.container },
        isPreOrderAvailable ? null : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.title },
                React.createElement("strong", null, t.amount)),
            React.createElement("div", { className: styles.counter },
                (props.quantity || props.preOrder) && (React.createElement("div", { className: styles.stepper },
                    React.createElement(Stepper, { value: props.quantity === 0 ? 0 : props.cartQuantity, min: props.quantity === 0 ? 0 : 1, max: props.quantity, onChange: props.quantity === 0 ? () => {
                        } : props.onChangeQuantity }))),
                React.createElement("p", { className: styles.stock }, t.remainingStock),
                React.createElement("span", { className: classNames(styles, 'inStock', { zeroQuantity: props.quantity === 0 }) }, `${t.inStock} (${numberToWords(props.quantity)})`)))),
        isPreOrderAvailable && (React.createElement("div", { className: styles.preOrder },
            React.createElement("div", { className: styles.title },
                React.createElement("strong", null, t.preOrder)),
            React.createElement("div", { className: styles.preOrderText },
                React.createElement("div", null, t.availableForPreOrder),
                React.createElement("div", null,
                    t.leadTime,
                    " ",
                    React.createElement("span", { className: styles.preOrderDays }, props.preOrderDays)))))));
};
export default ProductQuantity;
