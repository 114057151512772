import { t } from '../../../translation/utils';
const translations = {
    en: {
        help: 'Help',
        startSelling: 'Sell on Graame'
    },
    hi: {
        help: 'Помощь',
        startSelling: 'Начать продавать'
    }
};
// TODO: made for non-production
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export default t(translations);
export { validate, translations };
