import React, { Component } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Environment } from 'relay-runtime';
import { filter, find, head, isEmpty, path, pathOr, prepend, propEq } from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { ManageStore } from '../../../index';
import { AppContext, Page } from '../../../../../../components/App';
import { withErrorBoundary } from '../../../../../../components/common/ErrorBoundaries';
import { debug, error, fromRelayError, renameKeys } from '../../../../../../utils';
import { UpdateBaseProductMutation, UpdateProductMutation, UpsertShippingMutation } from '../../../../../../relay/mutations';
import { withShowAlert } from '../../../../../../components/Alerts/AlertContext';
import fetchPackages from '../fetchPackages';
import Form from '../Form';
// import sendProductToDraftMutation from './mutations/SendProductToDraftMutation';
import styles from '../Product.module.scss';
import t from './i18n';
class EditProduct extends Component {
    static contextTypes = {
        directories: PropTypes.object,
        environment: PropTypes.instanceOf(Environment).isRequired
    };
    constructor(props) {
        super(props);
        const baseProduct = pathOr(null, ['me', 'baseProduct'], props);
        const { category, customAttributes } = baseProduct;
        const newCustomAttributes = filter((item) => Boolean(find(propEq(item.rawId, 'attributeId'))(customAttributes)), category.getAttributes);
        this.state = {
            formErrors: {},
            isLoading: false,
            availablePackages: null,
            isLoadingPackages: false,
            isLoadingShipping: false,
            shippingData: null,
            customAttributes: newCustomAttributes
        };
    }
    componentDidMount() {
        this.handleFetchPackages();
    }
    setLoadingPackages = (value) => {
        this.setState({ isLoadingPackages: value });
    };
    handleFetchPackages = (metrics) => {
        this.setState({ isLoadingPackages: true });
        const size = metrics
            ? metrics.lengthCm * metrics.widthCm * metrics.heightCm
            : 0;
        const weight = metrics ? metrics.weightG : 0;
        const baseProduct = pathOr(null, ['me', 'baseProduct'], this.props);
        const warehouses = pathOr(null, ['me', 'baseProduct', 'store', 'warehouses'], this.props);
        const warehouse = warehouses && !isEmpty(warehouses) ? head(warehouses) : null;
        const countryCode = pathOr(null, ['addressFull', 'countryCode'], warehouse);
        if (countryCode && process.env.BROWSER) {
            this.setLoadingPackages(true);
            const variables = {
                countryCode,
                size: metrics ? size : baseProduct.volumeCubicCm || 0,
                weight: metrics ? weight : baseProduct.weightG || 0
            };
            fetchPackages(this.props.environment, variables)
                .toPromise()
                .then((availablePackages) => {
                this.setState({
                    availablePackages: availablePackages.availablePackages || null,
                    isLoadingPackages: false
                });
                return true;
            })
                .catch(() => {
                this.setState({
                    availablePackages: null,
                    isLoadingPackages: false
                });
                this.props.showAlert({
                    type: 'danger',
                    text: t.somethingGoingWrong,
                    link: { text: t.close }
                });
            });
        }
        else {
            this.handlerOffLoadingPackages();
        }
    };
    handlerOffLoadingPackages = () => {
        this.setState({ isLoadingPackages: false });
    };
    handleSave = (form, withSavingShipping) => {
        this.setState({ formErrors: {} });
        const baseProduct = path(['me', 'baseProduct'], this.props);
        if (!baseProduct || !baseProduct.id) {
            this.props.showAlert({
                type: 'danger',
                text: t.somethingGoingWrong,
                link: { text: t.close }
            });
            return;
        }
        const { name, categoryId, seoTitle, seoDescription, shortDescription, longDescription, currency, metrics } = form;
        this.setState(() => ({ isLoading: true }));
        UpdateBaseProductMutation.commit({
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    id: baseProduct.id,
                    name: name ? [{
                            lang: 'EN',
                            text: name
                        }] : null,
                    shortDescription: shortDescription
                        ? [{
                                lang: 'EN',
                                text: shortDescription
                            }]
                        : null,
                    longDescription: [{
                            lang: 'EN',
                            text: longDescription
                        }],
                    categoryId,
                    seoTitle: seoTitle ? [{
                            lang: 'EN',
                            text: seoTitle
                        }] : null,
                    seoDescription: seoDescription
                        ? [{
                                lang: 'EN',
                                text: seoDescription
                            }]
                        : null,
                    currency,
                    lengthCm: metrics.lengthCm || null,
                    widthCm: metrics.widthCm || null,
                    heightCm: metrics.heightCm || null,
                    weightG: metrics.weightG || null
                }
            },
            environment: this.props.environment,
            onCompleted: (response, errors) => {
                this.setState({ isLoading: false });
                debug({
                    response,
                    errors
                });
                const relayErrors = fromRelayError({ source: { errors } });
                debug({ relayErrors });
                const validationErrors = pathOr({}, ['100', 'messages'], relayErrors);
                if (!isEmpty(validationErrors)) {
                    this.setState({
                        formErrors: renameKeys({
                            long_description: 'longDescription',
                            short_description: 'shortDescription'
                        }, validationErrors)
                    });
                    return;
                }
                const status = pathOr('', ['100', 'status'], relayErrors);
                if (status) {
                    this.props.showAlert({
                        type: 'danger',
                        text: `${t.error} "${status}"`,
                        link: { text: t.close }
                    });
                    return;
                }
                if (errors) {
                    this.props.showAlert({
                        type: 'danger',
                        text: t.somethingGoingWrong,
                        link: { text: t.close }
                    });
                    return;
                }
                if (form && form.rawIdMainVariant) {
                    this.handleUpdateVariant({
                        idMainVariant: form.idMainVariant,
                        rawIdMainVariant: form.rawIdMainVariant,
                        photoMain: form.photoMain,
                        photos: form.photos,
                        vendorCode: form.vendorCode,
                        price: form.price,
                        cashback: form.cashback,
                        discount: form.discount,
                        preOrderDays: form.preOrderDays,
                        preOrder: form.preOrder,
                        attributeValues: form.attributeValues
                    }, withSavingShipping);
                }
            },
            onError: (err) => {
                this.setState(() => ({ isLoading: false }));
                debug({ err });
                const relayErrors = fromRelayError(err);
                debug({ relayErrors });
                const validationErrors = pathOr({}, ['100', 'messages'], relayErrors);
                if (!isEmpty(validationErrors)) {
                    this.setState({ formErrors: validationErrors });
                    return;
                }
                this.props.showAlert({
                    type: 'danger',
                    text: t.somethingGoingWrong,
                    link: { text: t.close }
                });
            }
        });
    };
    handleUpdateVariant = (variantData, withSavingShipping) => {
        if (!variantData.idMainVariant) {
            this.props.showAlert({
                type: 'danger',
                text: t.somethingGoingWrong,
                link: { text: t.close }
            });
            return;
        }
        this.setState({ isLoading: true });
        const params = {
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    id: variantData.idMainVariant || '',
                    product: {
                        price: variantData.price,
                        vendorCode: variantData.vendorCode,
                        photoMain: variantData.photoMain || '',
                        additionalPhotos: variantData.photos,
                        cashback: variantData.cashback ? variantData.cashback / 100 : 0,
                        discount: variantData.discount ? variantData.discount / 100 : 0,
                        preOrder: variantData.preOrder,
                        preOrderDays: Number(variantData.preOrderDays)
                    },
                    attributes: variantData.attributeValues
                }
            },
            environment: this.props.environment,
            onCompleted: (response, errors) => {
                this.setState({ isLoading: false });
                debug({
                    response,
                    errors
                });
                const relayErrors = fromRelayError({ source: { errors } });
                debug({ relayErrors });
                const validationErrors = pathOr({}, ['100', 'messages'], relayErrors);
                if (!isEmpty(validationErrors)) {
                    const formErrors = renameKeys({
                        long_description: 'longDescription',
                        short_description: 'shortDescription',
                        seo_title: 'seoTitle',
                        seo_description: 'seoDescription',
                        vendor_code: 'vendorCode'
                    }, validationErrors);
                    this.setState({ formErrors });
                    return;
                }
                const statusError = pathOr({}, ['100', 'status'], relayErrors);
                if (!isEmpty(statusError)) {
                    this.props.showAlert({
                        type: 'danger',
                        text: `${t.error} "${statusError}"`,
                        link: { text: t.close }
                    });
                    return;
                }
                const parsingError = pathOr(null, ['300', 'message'], relayErrors);
                if (parsingError) {
                    debug('parsingError:', { parsingError });
                    this.props.showAlert({
                        type: 'danger',
                        text: t.somethingGoingWrong,
                        link: { text: t.close }
                    });
                    return;
                }
                if (errors) {
                    this.props.showAlert({
                        type: 'danger',
                        text: t.somethingGoingWrong,
                        link: { text: t.close }
                    });
                    return;
                }
                // change state to DRAFT after saving product with PUBLISHED state
                /* const status = pathOr(null, ['updateProduct', 'baseProduct', 'status'])(
                 response,
                 );
                 const baseProductId = pathOr(null, [
                 'updateProduct',
                 'baseProduct',
                 'rawId',
                 ])(response);
                 if (baseProductId && status === 'PUBLISHED') {
                 sendProductToDraftMutation({
                 environment: this.props.environment,
                 variables: {
                 ids: [baseProductId],
                 },
                 });
                 } */
                this.props.showAlert({
                    type: 'success',
                    text: t.productUpdated,
                    link: { text: '' }
                });
                if (withSavingShipping) {
                    this.handleSaveShipping();
                }
            },
            onError: (err) => {
                this.setState(() => ({ isLoading: false }));
                error(err);
                this.props.showAlert({
                    type: 'danger',
                    text: t.somethingGoingWrong,
                    link: { text: t.close }
                });
            }
        };
        UpdateProductMutation.commit(params);
    };
    handleSaveShipping = (onlyShippingSave) => {
        const { shippingData } = this.state;
        if (!shippingData) {
            this.props.showAlert({
                type: 'danger',
                text: t.somethingGoingWrong,
                link: { text: t.close }
            });
            return;
        }
        const productRawId = pathOr(null, ['me', 'baseProduct', 'rawId'], this.props);
        const storeRawId = pathOr(null, ['me', 'baseProduct', 'store', 'rawId'], this.props);
        const { local, international, pickup, withoutInter, withoutLocal } = shippingData;
        if (onlyShippingSave) {
            this.setState({ isLoadingShipping: true });
        }
        const params = {
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    local: withoutLocal ? [] : local,
                    international: withoutInter ? [] : international,
                    pickup: withoutLocal ? {
                        pickup: false,
                        price: 0
                    } : pickup,
                    baseProductId: productRawId,
                    storeId: storeRawId
                }
            },
            environment: this.props.environment,
            onCompleted: (response, errors) => {
                this.setState({
                    isLoading: false,
                    isLoadingShipping: false
                });
                debug({
                    response,
                    errors
                });
                const relayErrors = fromRelayError({ source: { errors } });
                debug({ relayErrors });
                const validationErrors = pathOr({}, ['100', 'messages'], relayErrors);
                if (!isEmpty(validationErrors)) {
                    this.props.showAlert({
                        type: 'danger',
                        text: t.validationError,
                        link: { text: t.close }
                    });
                    return;
                }
                const statusError = pathOr({}, ['100', 'status'], relayErrors);
                if (!isEmpty(statusError)) {
                    this.props.showAlert({
                        type: 'danger',
                        text: `${t.error} "${statusError}"`,
                        link: { text: t.close }
                    });
                    return;
                }
                const parsingError = pathOr(null, ['300', 'message'], relayErrors);
                if (parsingError) {
                    debug('parsingError:', { parsingError });
                    this.props.showAlert({
                        type: 'danger',
                        text: t.somethingGoingWrong,
                        link: { text: t.close }
                    });
                    return;
                }
                if (errors) {
                    this.props.showAlert({
                        type: 'danger',
                        text: t.somethingGoingWrong,
                        link: { text: t.close }
                    });
                    return;
                }
                if (onlyShippingSave) {
                    this.props.showAlert({
                        type: 'success',
                        text: t.deliveryUpdated,
                        link: { text: '' }
                    });
                    return;
                }
                this.props.showAlert({
                    type: 'success',
                    text: t.deliveryUpdated,
                    link: { text: '' }
                });
            },
            onError: (err) => {
                this.setState({
                    isLoading: false,
                    isLoadingShipping: false
                });
                error(err);
                this.props.showAlert({
                    type: 'danger',
                    text: t.somethingGoingWrong,
                    link: { text: t.close }
                });
            }
        };
        UpsertShippingMutation.commit(params);
    };
    handleChangeShipping = (shippingData) => {
        this.setState({ shippingData });
    };
    handleCreateAttribute = (attribute) => {
        this.setState((prevState) => ({ customAttributes: prepend(attribute, prevState.customAttributes) }));
    };
    handleRemoveAttribute = (id) => {
        this.setState((prevState) => ({
            customAttributes: filter((item) => item.id !== id, prevState.customAttributes)
        }));
    };
    handleResetAttribute = (newCategoryId) => {
        const baseProduct = pathOr(null, ['me', 'baseProduct'], this.props);
        const { category, customAttributes } = baseProduct;
        let newCustomAttributes = filter((item) => Boolean(find(propEq(item.rawId, 'attributeId'))(customAttributes)), category.getAttributes);
        if (category.rawId !== newCategoryId) {
            newCustomAttributes = [];
        }
        this.setState({ customAttributes: newCustomAttributes });
    };
    render() {
        const { me, router, match, allCategories } = this.props;
        const { isLoading, availablePackages, isLoadingPackages, shippingData, formErrors, customAttributes, isLoadingShipping } = this.state;
        let baseProduct = null;
        if (me && me.baseProduct) {
            ({ baseProduct } = me);
        }
        else {
            return React.createElement("span", null, t.productNotFound);
        }
        return (React.createElement(AppContext.Consumer, null, (c) => (React.createElement("div", { className: styles.wrap },
            React.createElement(Form, { baseProduct: baseProduct, onSave: this.handleSave, formErrors: formErrors, allCategories: allCategories, currencies: c.directories.sellerCurrencies, isLoading: isLoading, availablePackages: availablePackages, isLoadingPackages: isLoadingPackages, onChangeShipping: this.handleChangeShipping, shippingData: shippingData, customAttributes: customAttributes, onResetAttribute: this.handleResetAttribute, onCreateAttribute: this.handleCreateAttribute, onRemoveAttribute: this.handleRemoveAttribute, environment: c.environment, onSaveShipping: this.handleSaveShipping, router: router, match: match, isLoadingShipping: isLoadingShipping, onFetchPackages: this.handleFetchPackages })))));
    }
}
export default createFragmentContainer(withShowAlert(withErrorBoundary(Page(ManageStore({
    OriginalComponent: EditProduct,
    active: 'goods',
    title: 'Goods'
})))), {
    me: graphql `
      fragment EditProduct_me on User
      @argumentDefinitions(productID: { type: "Int!" }) {
        baseProduct(id: $productID) {
          id
          rawId
          customAttributes {
            id
            rawId
            attributeId
            attribute {
              id
              rawId
            }
          }
          status
          currency
          ...Shipping_baseProduct
          products(first: 100) @connection(key: "Wizard_products") {
            edges {
              node {
                stocks {
                  id
                  productId
                  warehouseId
                  warehouse {
                    name
                    slug
                    addressFull {
                      country
                      administrativeAreaLevel1
                      administrativeAreaLevel2
                      political
                      postalCode
                      streetNumber
                      value
                      route
                      locality
                    }
                  }
                  quantity
                }
                id
                rawId
                price
                discount
                photoMain
                additionalPhotos
                vendorCode
                cashback
                price
                preOrder
                preOrderDays
                attributes {
                  attrId
                  value
                  metaField
                  attribute {
                    id
                    rawId
                    name {
                      lang
                      text
                    }
                    metaField {
                      values
                      translatedValues {
                        translations {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          category {
            rawId
            id
            getAttributes {
              id
              rawId
              name {
                lang
                text
              }
              valueType
              metaField {
                values
                translatedValues {
                  translations {
                    lang
                    text
                  }
                }
                uiElement
              }
            }
          }
          store(visibility: "active") {
            id
            rawId
            logo
            name {
              text
              lang
            }
            warehouses {
              addressFull {
                countryCode
              }
            }
          }
          name {
            lang
            text
          }
          shortDescription {
            lang
            text
          }
          longDescription {
            lang
            text
          }
          seoTitle {
            lang
            text
          }
          seoDescription {
            lang
            text
          }
          lengthCm
          widthCm
          heightCm
          weightG
          volumeCubicCm
        }
      }
    `
});
