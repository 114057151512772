import React, { Component } from 'react';
import { withRouter } from 'found';
import { isEmpty, isNil, pathOr } from 'ramda';
import { graphql } from 'react-relay';
import { v4 as uuidv4 } from 'uuid';
import { classNames, convertSrc, debug, error, fromRelayError, getNameText, setWindowTag, uploadFile } from '../../../../utils';
import { UpdateStoreMainMutation } from '../../../../relay/mutations';
import { UploadWrapper } from '../../../../components/Upload';
import { withShowAlert } from '../../../../components/Alerts/AlertContext';
import { Collapse } from '../../../../components/Collapse';
import { Icon } from '../../../../components/Icon';
import { MobileUpload } from '../../../../components/MobileUpload';
import styles from './ManageStoreMenu.module.scss';
import t from './i18n';
import { menuItems } from './menuItems';
import manageStoreMenuFragment from './__generated__/ManageStoreMenu_me.graphql';
class ManageStoreMenu extends Component {
    state = {
        storeData: null,
        isMainPhotoUploading: false
    };
    UNSAFE_componentWillMount() {
        const store = this.props.environment.getStore();
        const meId = pathOr(null, ['me', '__ref'], store.getSource()
            .get('client:root'));
        if (meId) {
            // const queryUser = MANAGE_STORE_MENU_FRAGMENT.me();
            const snapshotUser = store.lookup({
                isWithinUnmatchedTypeRefinement: false,
                kind: '',
                owner: undefined,
                variables: undefined,
                dataID: meId,
                node: manageStoreMenuFragment
            });
            const { dispose: disposeUser } = store.subscribe(snapshotUser, (s) => {
                this.setState({ storeData: s.data });
                // tmp code
                setWindowTag('user', s.data);
                // end tmp code
            });
            this.disposeUser = disposeUser;
            this.setState({ storeData: snapshotUser.data });
            // tmp code
            setWindowTag('user', snapshotUser.data);
            // end tmp code
        }
    }
    componentWillUnmount() {
        if (this.disposeUser) {
            this.disposeUser();
        }
    }
    disposeUser;
    handleOnUpload = (e) => {
        const { storeData } = this.state;
        if (storeData && storeData.myStore.status === 'MODERATION') {
            this.props.showAlert({
                type: 'danger',
                text: `${t.error}${t.statusModerationCannotBeChanged}`,
                link: { text: t.close }
            });
            return;
        }
        e.preventDefault();
        this.setState({ isMainPhotoUploading: true });
        uploadFile(e.target.files[0])
            .then((result) => {
            if (!result || result.url == null) {
                error(result);
                alert('Error :(');
            }
            this.handleLogoUpload(result.url || '');
            return true;
        })
            .finally(() => {
            this.setState({ isMainPhotoUploading: false });
        })
            .catch((err) => {
            this.props.showAlert({
                type: 'danger',
                text: err.message,
                link: { text: t.close }
            });
        });
    };
    handleLogoUpload = (url) => {
        const { environment } = this.props;
        const storeId = pathOr(null, ['storeData', 'myStore', 'id'], this.state);
        if (!storeId) {
            this.props.showAlert({
                type: 'danger',
                text: t.somethingGoingWrong,
                link: { text: t.close }
            });
            return;
        }
        const params = {
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    id: storeId,
                    logo: url,
                    name: null,
                    longDescription: null,
                    shortDescription: null,
                    defaultLanguage: null,
                    slug: null,
                    slogan: null,
                    addressFull: {}
                }
            },
            environment,
            onCompleted: (response, errors) => {
                debug({
                    response,
                    errors
                });
                const relayErrors = fromRelayError({ source: { errors } });
                debug({ relayErrors });
                const statusError = pathOr('', ['100', 'status'], relayErrors);
                if (!isEmpty(statusError)) {
                    this.props.showAlert({
                        type: 'danger',
                        text: `Error: "${statusError}"`,
                        link: { text: t.close }
                    });
                    return;
                }
                const parsingError = pathOr(null, ['300', 'message'], relayErrors);
                if (parsingError) {
                    debug('parsingError:', { parsingError });
                    this.props.showAlert({
                        type: 'danger',
                        text: t.somethingGoingWrong,
                        link: { text: t.close }
                    });
                    return;
                }
                this.props.showAlert({
                    type: 'success',
                    text: t.saved,
                    link: { text: '' }
                });
            },
            onError: (err) => {
                error(err);
                this.props.showAlert({
                    type: 'danger',
                    text: t.somethingGoingWrong,
                    link: { text: t.close }
                });
            }
        };
        UpdateStoreMainMutation.commit(params);
    };
    handleClick = (item) => {
        const { link } = item;
        const { router: { replace }, match: { params: { storeId } } } = this.props;
        if (!isEmpty(link)) {
            if (!isNil(storeId)) {
                const storePath = `/manage/store/${storeId}`;
                const path = link === '/' ? storePath : `${storePath}${!isNil(link) ? link : ''}`;
                replace(path);
            }
        }
    };
    deleteAvatar = () => {
        const { storeData } = this.state;
        if (storeData && storeData.myStore.status === 'MODERATION') {
            this.props.showAlert({
                type: 'danger',
                text: `${t.error}${t.statusModerationCannotBeChanged}`,
                link: { text: t.close }
            });
            return;
        }
        this.handleLogoUpload('');
    };
    render() {
        const { activeItem } = this.props;
        const { storeData } = this.state;
        const { newStoreName, newStoreLogo, handleOnUpload, deleteAvatar } = this.props;
        if (!storeData) {
            return React.createElement("div", null);
        }
        const { myStore } = storeData;
        let storeId = '';
        let storeName = '';
        let storeLogo = '';
        if (myStore) {
            storeName = getNameText(myStore.name, 'EN');
            storeLogo = myStore.logo;
            storeId = myStore.rawId;
        }
        return (React.createElement("aside", { className: styles.container },
            React.createElement("h2", { className: styles.offscreen }, t.manage),
            React.createElement("div", { className: styles.mobileMenu },
                React.createElement(Collapse, { selected: activeItem, items: menuItems, onSelected: this.handleClick, isDisabled: isNil(storeId) }),
                React.createElement("div", { style: { margin: '1.05rem 0' } }),
                activeItem === 'settings' ? (React.createElement(MobileUpload, { img: convertSrc('small', storeLogo), iconType: "upload", id: "some", onUpload: this.handleOnUpload, onDelete: this.deleteAvatar })) : null),
            React.createElement("div", { className: styles.imgWrap },
                React.createElement(UploadWrapper, { id: "new-store-id", onUpload: myStore ? this.handleOnUpload : handleOnUpload, customUnit: true, square: true, buttonWidth: "100%", buttonIconSize: 48, buttonIconType: "upload", buttonLabel: t.buttonLabel, overPicture: myStore ? convertSrc('medium', storeLogo) || null : newStoreLogo, dataTest: "storeImgUploader", loading: this.state.isMainPhotoUploading, buttonHeight: "100%" }),
                ((myStore && storeLogo) || (!myStore && newStoreLogo)) && (React.createElement("div", { className: styles.trash, onClick: myStore ? this.deleteAvatar : deleteAvatar, onKeyDown: () => {
                    }, role: "button", tabIndex: 0 },
                    React.createElement(Icon, { type: "basket", size: 28 })))),
            React.createElement("div", { className: styles.title }, myStore ? storeName || '' : newStoreName),
            React.createElement("div", { className: styles.items }, menuItems.map((item) => {
                const isActive = item.id === activeItem;
                return (React.createElement("div", { key: item.id, className: classNames(styles, 'item', {
                        isActive,
                        isDisabled: item.disabled || (!storeId && item.id !== 'settings')
                    }), onClick: item.disabled ? null : () => this.handleClick(item), onKeyDown: () => {
                    }, role: "button", tabIndex: 0, "data-test": item.title }, item.title));
            }))));
    }
}
export default withRouter(withShowAlert(ManageStoreMenu));
const MANAGE_STORE_MENU_FRAGMENT = {
    me: graphql `
		fragment ManageStoreMenu_me on User {
			myStore {
				id
				rawId
				name {
					lang
					text
				}
				logo
				status
			}
		}
  `
};
