import { t } from '../../../../translation/utils';
const translations = {
    en: {
        passwordMustNotBeEmpty: 'Password must not be empty',
        notValidPassword: 'Not valid password',
        passwordHasNotChange: 'Password has not changed',
        notTheSamePassword: 'Not the same password',
        somethingGoingWrong: 'Something going wrong :(',
        close: 'Close.',
        error: 'Error: ',
        securitySettings: 'Security Settings',
        passwordSuccessfullyUpdated: 'Password successfully updated!',
        labelCurrentPassword: 'Current password',
        labelNewPassword: 'New password',
        labelRepeatPassword: 'Repeat new password',
        save: 'Save', //
        confirmationDescription: 'Are you sure you want to reset?',
        resetPassword: 'Reset Password',
        confirmText: 'Yes, please',
        cancelText: 'Cancel'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
