import React, { Children, Component } from 'react';
import { Icon } from '../Icon';
import handlerDropdown from './handleDropdownDecorator';
import styles from './Dropdown.module.scss';
import { classNames } from '../../utils';
class Dropdown extends Component {
    render() {
        const { withIcon, isContentOpen, dataTest } = this.props;
        return (React.createElement("div", { className: styles.container }, Children.map(this.props.children, (child) => {
            if (child.type === 'trigger') {
                return (React.createElement("div", { ref: this.props.triggerRef, className: classNames(styles, 'trigger', {
                        withIconTrigger: withIcon,
                        isContentOpen
                    }), "data-test": dataTest || '' },
                    child.props.children,
                    withIcon && (React.createElement("div", { className: styles.icon },
                        React.createElement(Icon, { type: "arrowExpand" })))));
            }
            else if (child.type === 'content' && isContentOpen) {
                return (React.createElement("div", { ref: this.props.contentRef, className: classNames(styles, 'content', { withIconContent: isContentOpen }), "data-test": dataTest || '' }, child.props.children));
            }
            return null;
        })));
    }
}
export default handlerDropdown(Dropdown);
