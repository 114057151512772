import Cookies from 'universal-cookie';
export function setCookie(name, value, expires, cookiesInstance) {
    let options = { path: '/' };
    if (expires) {
        options = {
            ...options,
            expires
        };
    }
    const cookies = cookiesInstance || new Cookies();
    console.log('set cookie ->', name, value);
    cookies.set(name, value, { ...options });
}
export function removeCookie(name, cookiesInstance) {
    const cookies = cookiesInstance || new Cookies();
    console.log('removed cookie ->', name, name);
    cookies.remove(name, { path: '/' });
}
export function getCookie(name) {
    const cookies = new Cookies();
    return cookies.get(name);
}
