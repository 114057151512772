import React from 'react';
import { Icon } from '../../Icon';
import { Col, Row } from '../../../layout';
import styles from './MobileSearchMenu.module.scss';
import { classNames } from '../../../utils';
const MobileSearchMenu = (props) => (React.createElement("div", { className: classNames(styles, 'container', { isOpen: props.isOpen }) },
    React.createElement(Row, null,
        React.createElement(Col, { size: 11, sm: 11, md: 11, lg: 11, xl: 11 }, props.children),
        React.createElement(Col, { size: 1, sm: 1, md: 1, lg: 1, xl: 1 },
            React.createElement("span", { onClick: props.onClick, onKeyPress: () => {
                }, role: "button", className: styles.close, tabIndex: -1 },
                React.createElement(Icon, { type: "cross", size: 16 }))))));
export default MobileSearchMenu;
