import React, { Component } from 'react';
import { any, assocPath, complement, equals, filter, find, pathOr, sort, where, whereEq } from 'ramda';
import { withRouter } from 'found';
import debounce from 'lodash.debounce';
import { categoryViewTracker } from '../../../rrHalper';
import { classNames, flattenFunc, getCurrentCurrency, getNameText, inputToUrl, urlToInput } from '../../../utils';
import { Accordion, prepareForAccordion } from '../../../components/Accordion';
import { RangeSlider } from '../../../components/Ranger';
import { AttributeControl } from '../../../components/AttributeControl';
import { Icon } from '../../../components/Icon';
import styles from './SearchSidebar.module.scss';
import t from './i18n';
class SearchSidebar extends Component {
    constructor(props) {
        super(props);
        const minValue = pathOr(0, [
            'search',
            'findProduct',
            'pageInfo',
            'searchFilters',
            'priceRange',
            'minValue'
        ], props);
        const maxValue = pathOr(0, [
            'search',
            'findProduct',
            'pageInfo',
            'searchFilters',
            'priceRange',
            'maxValue'
        ], props);
        let thumb1 = pathOr(undefined, ['match', 'location', 'query', 'minValue'], props);
        let thumb2 = pathOr(undefined, ['match', 'location', 'query', 'maxValue'], props);
        thumb1 = Number(thumb1 || minValue);
        thumb2 = Number(thumb2 || maxValue);
        if (thumb1 < minValue) {
            thumb1 = minValue;
        }
        if (thumb1 > maxValue) {
            thumb1 = maxValue;
        }
        if (thumb2 < minValue) {
            thumb2 = minValue;
        }
        if (thumb2 > maxValue) {
            thumb2 = maxValue;
        }
        if (thumb2 < thumb1) {
            thumb2 = thumb1;
        }
        this.state = {
            thumb1,
            thumb2,
            minValue,
            maxValue
        };
        this.setRangeUrl = debounce(this.setRangeUrl, 500);
        this.setRangeUrl(thumb1, thumb2, 'replace');
    }
    componentDidUpdate(prevProps, prevState) {
        const minValue = pathOr(0, [
            'search',
            'findProduct',
            'pageInfo',
            'searchFilters',
            'priceRange',
            'minValue'
        ], this.props);
        const maxValue = pathOr(0, [
            'search',
            'findProduct',
            'pageInfo',
            'searchFilters',
            'priceRange',
            'maxValue'
        ], this.props);
        // console.log('minValue', minValue);
        // console.log('maxValue', maxValue);
        if (minValue !== prevState.minValue || maxValue !== prevState.maxValue) {
            this.setRangeData(minValue, maxValue);
        }
    }
    setRangeData = (minValue, maxValue) => {
        this.setState({
            thumb1: minValue,
            thumb2: maxValue,
            minValue,
            maxValue
        }, () => {
            this.setRangeUrl(minValue, maxValue, 'replace');
        });
    };
    setRangeUrl = (thumb1, thumb2, type) => {
        const queryObj = pathOr('', ['match', 'location', 'query'], this.props);
        const oldPreparedObj = urlToInput(queryObj);
        const newPreparedObj = assocPath(['options', 'priceFilter'], {
            minValue: thumb1,
            maxValue: thumb2
        }, oldPreparedObj);
        const newUrl = inputToUrl(newPreparedObj);
        if (oldPreparedObj !== newPreparedObj) {
            if (type === 'replace') {
                if (process.env.BROWSER) {
                    this.props.router.replace(`/categories${newUrl}`);
                }
                return;
            }
            if (process.env.BROWSER) {
                this.props.router.push(`/categories${newUrl}`);
            }
        }
    };
    getSearchFilter = (filterProp) => {
        const searchFiltersPath = [
            'search',
            'findProduct',
            'pageInfo',
            'searchFilters'
        ];
        return pathOr(null, [...searchFiltersPath, filterProp], this.props);
    };
    generateTree = () => {
        // generate categories tree for render categories filter
        const categoryId = pathOr(null, ['match', 'location', 'query', 'category'], this.props);
        const categories = pathOr(null, [
            'search',
            'findProduct',
            'pageInfo',
            'searchFilters',
            'categories',
            'children'
        ], this.props);
        if (!categories) {
            return null;
        }
        // prepare array of all categories
        const flattenCategories = flattenFunc(categories);
        // function get level and return function for filtering
        // categories by level with no empty children
        const levelFilter = (level) => filter(where({
            level: equals(level),
            children: (i) => i && i.length !== 0
        }));
        // check that we need to render category 1 level with children in sidebar
        const isFirstCatPred = whereEq({
            level: 1,
            rawId: parseInt(categoryId, 10)
        });
        const isFirstCategory = any(isFirstCatPred, flattenCategories);
        if (isFirstCategory) {
            const filtered = levelFilter(1)(flattenCategories);
            return prepareForAccordion(filtered, 'EN');
        }
        const filtered = levelFilter(2)(flattenCategories);
        return prepareForAccordion(filtered, 'EN');
    };
    handleOnChangeCategory = (item) => {
        const { router, onClose } = this.props;
        const name = pathOr('', ['match', 'location', 'query', 'search'], this.props);
        router.push(`/categories?search=${name}&category=${item.id}`);
        this.handleClickCategory(item.id);
        onClose();
    };
    handleOnRangeChange = (data) => {
        this.setRangeUrl(data.thumb1, data.thumb2, 'replace');
    };
    prepareAttrsToUrlStr = (id, values) => {
        // getting current searchInput data change attrs and push to new url
        const queryObj = pathOr('', ['match', 'location', 'query'], this.props);
        const oldPreparedObj = urlToInput(queryObj);
        const oldAttrs = pathOr([], ['options', 'attrFilters'], oldPreparedObj);
        const newPreparedObj = assocPath(['options', 'attrFilters'], [
            ...filter(complement(whereEq({ id })), oldAttrs),
            {
                id,
                equal: { values }
            }
        ], oldPreparedObj);
        return inputToUrl(newPreparedObj);
    };
    handleOnChangeAttribute = (attrFilter) => {
        const { router } = this.props;
        const attributePath = ['attribute'];
        const id = pathOr(null, [...attributePath, 'id'], attrFilter);
        const rawId = pathOr(null, [...attributePath, 'rawId'], attrFilter);
        return (value) => {
            const newUrl = this.prepareAttrsToUrlStr(rawId, value);
            router.push(`/categories${newUrl}`);
            if (id) {
                this.setState({ [id]: value });
            }
        };
    };
    handleClickCategory = (categoryId) => {
        if (process.env.BROWSER && process.env.REACT_APP_RRPARTNERID && categoryId) {
            categoryViewTracker(parseInt(String(categoryId), 10));
        }
    };
    renderParentLink = () => {
        const { router } = this.props;
        const categoryId = pathOr(null, ['match', 'location', 'query', 'category'], this.props);
        const categories = pathOr(null, [
            'search',
            'findProduct',
            'pageInfo',
            'searchFilters',
            'categories',
            'children'
        ], this.props);
        const linkComponent = (obj) => (React.createElement("div", { className: styles.parentCategory, onClick: () => {
                if (!obj) {
                    router.push('/categories?search=');
                }
                else {
                    router.push(`/categories?search=&category=${obj.rawId}`);
                    this.handleClickCategory(obj.rawId);
                }
            }, onKeyDown: () => {
            }, role: "button", tabIndex: 0 },
            obj && getNameText(obj.name, 'EN'),
            !obj && t.allCategories));
        if (!categoryId) {
            return linkComponent();
        }
        const arr = flattenFunc(categories);
        const findCatPred = (rawId) => find(whereEq({ rawId }));
        const catObj = findCatPred(parseInt(categoryId, 10))(arr);
        let parentObj = null;
        if (catObj) {
            switch (catObj.level) {
                case 3:
                    parentObj = findCatPred(catObj.parentId)(arr);
                    parentObj = parentObj ? findCatPred(parentObj.parentId)(arr) : null;
                    break;
                case 2:
                    parentObj = find(whereEq({ rawId: catObj.parentId }), arr);
                    break;
                default:
                    break;
            }
        }
        if (!parentObj) {
            return linkComponent();
        }
        return linkComponent(parentObj);
    };
    render() {
        const { onClose, isOpen } = this.props;
        const { thumb1, thumb2, minValue, maxValue } = this.state;
        const attrFilters = this.getSearchFilter('attrFilters');
        const accordionItems = this.generateTree();
        const categoryId = pathOr(null, ['match', 'location', 'query', 'category'], this.props);
        const queryObj = pathOr(0, ['match', 'location', 'query'], this.props);
        const initialSearchInput = urlToInput(queryObj);
        const initialAttributes = pathOr([], ['options', 'attrFilters'], initialSearchInput);
        return (React.createElement("aside", { className: classNames(styles, 'container', { toggledSidebar: isOpen }) },
            React.createElement("header", { className: styles.header },
                React.createElement("h3", null, t.filters),
                React.createElement("span", { id: "close", onClick: onClose, onKeyPress: () => {
                    }, role: "button", tabIndex: -1 },
                    React.createElement(Icon, { type: "cross", size: 24 }))),
            this.renderParentLink(),
            accordionItems && (React.createElement(Accordion, { items: accordionItems, onClick: this.handleOnChangeCategory, activeId: categoryId ? parseInt(categoryId, 10) : null })),
            parseFloat(String(minValue)) !== parseFloat(String(maxValue)) && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles.blockTitle }, `Price (${getCurrentCurrency()})`),
                React.createElement(RangeSlider, { thumb1: thumb1, thumb2: thumb2, minValue: minValue, maxValue: maxValue, onChange: (data) => {
                        this.setRangeUrl(data.thumb1, data.thumb2, 'replace');
                    } }))),
            attrFilters && sort((a, b) => a.attribute.rawId - b.attribute.rawId, attrFilters)
                .map((attrFilter) => {
                const initialAttr = find(whereEq({ id: attrFilter.attribute.rawId }), initialAttributes);
                const initialValues = pathOr([], ['equal', 'values'], initialAttr);
                return (React.createElement("div", { key: attrFilter.attribute.id, className: styles.attrBlock },
                    React.createElement(AttributeControl, { attrFilter: attrFilter, initialValues: initialValues, onChange: this.handleOnChangeAttribute(attrFilter) })));
            })));
    }
}
export default withRouter(SearchSidebar);
