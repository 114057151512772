import { t } from '../../../translation/utils';
const translations = {
    en: {
        cookiePolicy: 'This website uses ‘cookies’ to give you best, most relevant experience. Using this website means you’re Ok with this. If you do not use cookies, you will not be able to access the website.',
        searchCategory_products: 'Products',
        searchCategory_shops: 'Shops'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
