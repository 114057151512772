import React from 'react';
import { Link } from 'found';
import { Container, Row } from '../../layout';
import { Icon } from '../../components/Icon';
import styles from './StartSellingHeader.module.scss';
const StartSellingHeader = ({ lang }) => (React.createElement(Container, null,
    React.createElement(Row, null,
        React.createElement("header", { className: styles.container },
            React.createElement("div", { className: styles.logo },
                React.createElement("div", { className: styles.logoIcon },
                    React.createElement(Link, { to: "/", "data-test": "logoLink" },
                        React.createElement(Icon, { type: "logo" }))),
                React.createElement("div", { className: styles.text },
                    React.createElement("strong", null, lang === 'hi'
                        ? 'Онлайн-маркетплейс'
                        : 'Online local marketplace')))))));
export default StartSellingHeader;
