import React from 'react';
import { Button } from '../../../components/common/Button';
import { Col, Row } from '../../../layout';
import styles from './CheckoutProducts.module.scss';
import AddressInfo from '../AddressInfo';
const CheckoutProducts = ({ addressFull, receiverName, email, replaceAddress }) => (React.createElement(Row, null,
    React.createElement(Col, { size: 12 },
        React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.infoContainer },
                React.createElement(Row, null,
                    React.createElement(Col, { size: 12, smVisible: true },
                        React.createElement("div", { className: styles.header },
                            React.createElement("div", { className: styles.title }, "Summary"),
                            React.createElement("div", { className: styles.wrapperAddressButton },
                                React.createElement("div", { className: styles.containerAddressButton },
                                    React.createElement(Button, { big: true, contour: true, onClick: replaceAddress, type: "button", dataTest: "changeAddress" },
                                        React.createElement("span", null, "Change address")))))),
                    React.createElement(Col, { size: 12 }, addressFull.value && (React.createElement("div", { className: styles.addressInfoWrapper },
                        React.createElement(AddressInfo, { addressFull: addressFull, receiverName: receiverName, email: email })))),
                    React.createElement(Col, { size: 12, smHidden: true },
                        React.createElement("div", { className: styles.containerAddressButton2 },
                            React.createElement(Button, { big: true, contour: true, whireframe: true, onClick: replaceAddress, type: "button", dataTest: "changeAddress" },
                                React.createElement("span", null, "Change address"))))))))));
export default CheckoutProducts;
