import React, { Component } from 'react';
import { find, map, propEq } from 'ramda';
import { Select } from '../../../../../../../components/common';
import t from './i18n';
class ShippingInterSelect extends Component {
    static getDerivedStateFromProps(nextProps) {
        const { services, service } = nextProps;
        return {
            countries: service ? service.countries : [],
            currency: service ? service.currency : null,
            services: map((item) => ({
                id: item.id,
                label: item.label
            }), services),
            service: service ? {
                id: service.id,
                label: service.label
            } : null
        };
    }
    handleOnSelectService = (item) => {
        if (item) {
            const service = find(propEq(item.id, 'id'))(this.props.services);
            if (service && service.countries && service.currency) {
                this.props.handleOnSelectService({
                    ...item,
                    countries: service.countries,
                    currency: service.currency
                });
            }
        }
        else {
            this.props.handleOnSelectService(null);
        }
    };
    render() {
        const { services, service } = this.state;
        return (React.createElement(Select, { forForm: true, fullWidth: true, label: t.service, items: services, activeItem: service, onSelect: this.handleOnSelectService, dataTest: "shippingInterServiceSelect" }));
    }
}
export default ShippingInterSelect;
