import { graphql } from 'react-relay';
import { basicMutation } from '../../../relay/mutations/basicMutation';
const mutation = graphql `
	mutation UpdateUserPhoneMutation($input: UpdateUserInput!) {
		updateUser(input: $input) {
			id
			phone
		}
	}
`;
const updateUserPhoneMutation = basicMutation(mutation, 'updateUser');
export default updateUserPhoneMutation;
