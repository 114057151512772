import React from 'react';
import styles from './AddressInfo.module.scss';
import t from './i18n';
const AddressInfo = ({ receiverName, addressFull, email }) => (React.createElement("div", { className: styles.infoContent },
    React.createElement("div", { className: styles.wrapper },
        React.createElement("div", { className: styles.label }, t.deliveryAddress),
        React.createElement("div", null,
            addressFull.country,
            ",",
            addressFull.locality),
        React.createElement("div", null, addressFull.value),
        React.createElement("div", null, addressFull.postalCode)),
    React.createElement("div", { className: styles.label }, t.name),
    React.createElement("div", { className: styles.name }, receiverName),
    React.createElement("div", { className: styles.email }, email)));
export default AddressInfo;
