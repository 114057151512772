import React from 'react';
import { Icon } from '../../Icon';
import styles from './SliderArrows.module.scss';
import { classNames } from '../../../utils';
const SliderArrows = ({ onClick }) => (React.createElement(React.Fragment, null,
    React.createElement("span", { className: classNames(styles, 'arrow', 'left'), onClick: () => onClick('prev'), onKeyDown: () => {
        }, role: "button", tabIndex: -1 },
        React.createElement(Icon, { type: "leftArrowSlider", size: 28 })),
    React.createElement("span", { className: classNames(styles, 'arrow', 'right'), onClick: () => onClick('next'), onKeyDown: () => {
        }, role: "button", tabIndex: -1 },
        React.createElement(Icon, { type: "rightArrowSlider", size: 28 }))));
export default SliderArrows;
