import React, { Component, Fragment } from 'react';
import { forEach, map } from 'ramda';
import { Checkbox } from '../../../../../../../components/common';
import { Icon } from '../../../../../../../components/Icon';
import { convertCountriesForSelect, convertCountriesToArrCodes } from '../utils';
import styles from './Countries.module.scss';
import { classNames } from '../../../../../../../utils';
class Countries extends Component {
    constructor(props) {
        super(props);
        const { countries, company } = props;
        const countriesForSelect = !company
            ? convertCountriesForSelect({ countries })
            : convertCountriesForSelect({
                countries,
                checkedCountries: convertCountriesToArrCodes({ countries: company.countries })
            });
        this.state = { countries: countriesForSelect };
        props.onChange(countriesForSelect);
    }
    componentDidUpdate(prevProps) {
        const { countries } = this.props;
        if (JSON.stringify(prevProps.countries) !== JSON.stringify(countries)) {
            this.updateState(countries);
        }
    }
    handleOpenContinent = (code) => {
        this.setState((prevState) => {
            const { countries } = prevState;
            const newChildren = map((item) => {
                if (code === item.alpha3) {
                    return {
                        ...item,
                        isOpen: !item.isOpen
                    };
                }
                return {
                    ...item,
                    isOpen: false
                };
            }, countries && countries.children ? countries.children : []);
            return {
                countries: {
                    ...prevState.countries,
                    children: newChildren
                }
            };
        });
    };
    handleCheckContinent = (code) => {
        this.setState((prevState) => {
            const { countries } = prevState;
            let isSelectedAll = true;
            const newChildren = map((item) => {
                if (code === item.alpha3) {
                    if (item.isSelected !== true) {
                        const newItem = {
                            ...item,
                            children: map((child) => ({
                                ...child,
                                isSelected: true
                            }), item.children)
                        };
                        return {
                            ...newItem,
                            isSelected: true
                        };
                    }
                    isSelectedAll = false;
                    const newItem = {
                        ...item,
                        children: map((child) => ({
                            ...child,
                            isSelected: false
                        }), item.children)
                    };
                    return {
                        ...newItem,
                        isSelected: false
                    };
                }
                forEach((child) => {
                    if (child.isSelected !== true) {
                        isSelectedAll = false;
                    }
                }, item.children);
                return item;
            }, countries && countries.children ? countries.children : []);
            return {
                countries: {
                    ...prevState.countries,
                    children: newChildren,
                    isSelected: isSelectedAll
                }
            };
        }, () => {
            this.props.onChange(this.state.countries);
        });
    };
    handleCheckCountry = (country) => {
        this.setState((prevState) => {
            const { countries } = prevState;
            let isSelectedAll = true;
            const newChildren = map((continent) => {
                if (country.parent === continent.alpha3) {
                    let isSelected = false;
                    const children = map((child) => {
                        if (country.alpha3 === child.alpha3) {
                            if (child.isSelected !== true) {
                                isSelected = true;
                            }
                            else {
                                isSelectedAll = false;
                            }
                            return {
                                ...child,
                                isSelected: !child.isSelected
                            };
                        }
                        if (child.isSelected === true) {
                            isSelected = true;
                        }
                        else {
                            isSelectedAll = false;
                        }
                        return child;
                    }, continent.children);
                    return {
                        ...continent,
                        children,
                        isSelected
                    };
                }
                forEach((child) => {
                    if (child.isSelected !== true) {
                        isSelectedAll = false;
                    }
                }, continent.children);
                return continent;
            }, countries && countries.children ? countries.children : []);
            return {
                countries: {
                    ...prevState.countries,
                    children: newChildren,
                    isSelected: isSelectedAll
                }
            };
        }, () => {
            this.props.onChange(this.state.countries);
        });
    };
    handleCheckAll = () => {
        this.setState((prevState) => {
            const { countries } = prevState;
            return {
                countries: convertCountriesForSelect({
                    countries: prevState.countries,
                    isSelected: countries ? !countries.isSelected : false
                })
            };
        }, () => {
            this.props.onChange(this.state.countries);
        });
    };
    updateState = (countries) => {
        const { company } = this.props;
        this.setState({
            countries: !company
                ? convertCountriesForSelect({ countries })
                : convertCountriesForSelect({
                    countries,
                    checkedCountries: convertCountriesToArrCodes({ countries: company.countries })
                })
        }, () => {
            this.props.onChange(this.state.countries);
        });
    };
    render() {
        const { company } = this.props;
        const { countries } = this.state;
        if (!countries) {
            return null;
        }
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: classNames(styles, 'checkbox', 'allCheckbox') },
                React.createElement(Checkbox, { id: `shipping-${company ? 'company' : ''}all-countries`, label: "Select all", isChecked: countries.isSelected, onChange: this.handleCheckAll })),
            map((item) => (React.createElement(Fragment, { key: item.alpha3 },
                React.createElement("div", { className: styles.continent },
                    React.createElement("div", { className: styles.checkbox },
                        React.createElement(Checkbox, { id: `shipping-${company ? 'company' : ''}continent-${item.alpha3}`, isChecked: item.isSelected, onChange: () => {
                                this.handleCheckContinent(item.alpha3);
                            } })),
                    React.createElement("div", { className: styles.label, onClick: () => {
                            this.handleOpenContinent(item.alpha3);
                        }, onKeyDown: () => {
                        }, role: "button", tabIndex: 0 },
                        React.createElement("strong", null, item.label),
                        React.createElement("div", { className: classNames(styles, 'icon', { rotateIcon: item.isOpen }) },
                            React.createElement(Icon, { type: "arrowExpand" })))),
                React.createElement("div", { className: classNames(styles, 'continentsCountries', { show: item.isOpen }) }, map((country) => (React.createElement("div", { key: country.alpha3, className: styles.country },
                    React.createElement("div", { className: styles.checkbox },
                        React.createElement(Checkbox, { id: `shipping-${company ? 'company' : ''}country-${country.alpha3}`, label: country.label, isChecked: country.isSelected, onChange: () => {
                                this.handleCheckCountry(country);
                            } })))), item.children)))), countries.children)));
    }
}
export default Countries;
