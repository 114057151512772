import React, { Component } from 'react';
import { addIndex, filter, find, head, isEmpty, map, propEq } from 'ramda';
import { Col, Container, Row } from '../../../../layout';
import { addressToString, classNames } from '../../../../utils';
import { Checkbox } from '../../../../components/common/Checkbox';
import { RadioButton } from '../../../../components/common/RadioButton';
import { Select } from '../../../../components/common/Select';
import { Input } from '../../../../components/common/Input';
import { AddressForm } from '../../../../components/AddressAutocomplete';
import { addressesToSelect } from '../../utils';
import AddressInfo from '../AddressInfo';
import styles from './CheckoutAddress.module.scss';
import t from './i18n';
class CheckoutAddress extends Component {
    constructor(props) {
        super(props);
        const { deliveryAddresses } = props;
        const addresses = addressesToSelect(deliveryAddresses);
        const selectedAddress = find(propEq('0', 'id'))(addresses) || head(addresses);
        this.state = {
            addresses,
            selectedAddress
        };
    }
    componentDidMount() {
        const { deliveryAddresses } = this.props;
        const addresses = addressesToSelect(deliveryAddresses);
        const selectedAddress = find(propEq('0', 'id'))(addresses) || head(addresses);
        this.handleOnSelectAddress(selectedAddress);
    }
    componentDidUpdate(prevProps) {
        const { isAddressSelect } = this.props;
        if (isAddressSelect && prevProps.isAddressSelect !== isAddressSelect) {
            const { deliveryAddresses } = this.props;
            const addresses = addressesToSelect(deliveryAddresses);
            const selectedAddress = find(propEq('0', 'id'))(addresses) || head(addresses);
            this.handleOnSelectAddress(selectedAddress);
        }
    }
    handleOnSelectAddress = (item) => {
        const { onChangeOrderInput, orderInput, deliveryAddresses } = this.props;
        this.setState({ selectedAddress: item });
        const newDeliveryAddresses = filter((newAddressItem) => Boolean(newAddressItem.needed), addIndex(map)((addressItem, idx) => ({
            ...addressItem,
            needed: (item && item.id === '0' && addressItem.isPriority)
                || (item && item.id === `${idx + 1}`)
        }), deliveryAddresses));
        const deliveryAddress = head(newDeliveryAddresses);
        if (deliveryAddress) {
            const addressFull = deliveryAddress.address;
            onChangeOrderInput({
                ...orderInput,
                addressFull
            });
        }
    };
    handleChangeReceiver = (e) => {
        const { onChangeOrderInput, orderInput } = this.props;
        onChangeOrderInput({
            ...orderInput,
            receiverName: e.target.value
        });
    };
    handleChangePhone = (e) => {
        const { onChangeOrderInput, orderInput } = this.props;
        const { value } = e.target;
        if (!/^\+?\d*$/.test(value)) {
            return;
        }
        onChangeOrderInput({
            ...orderInput,
            receiverPhone: value
        });
    };
    handleChangeData = (addressFullData) => {
        const { onChangeOrderInput, orderInput } = this.props;
        onChangeOrderInput({
            ...orderInput,
            addressFull: addressFullData
        });
    };
    handleOnChangeAddressType = () => {
        this.handleOnSelectAddress(this.state.selectedAddress);
        const { onChangeAddressType } = this.props;
        this.setState({ selectedAddress: null }, onChangeAddressType);
    };
    render() {
        const { me, isAddressSelect, isNewAddress, saveAsNewAddress, orderInput, onChangeSaveCheckbox, errors } = this.props;
        const { addressFull } = orderInput;
        const { addresses, selectedAddress } = this.state;
        const isEmptyDeliveryAddressesFull = isEmpty(me.deliveryAddressesFull);
        return (React.createElement(Container, { correct: true },
            React.createElement(Row, null,
                React.createElement(Col, { size: 12, xl: 6 },
                    React.createElement("div", { className: styles.addressWrapper },
                        React.createElement(Row, null,
                            React.createElement(Col, { size: 12 },
                                React.createElement("div", { className: styles.title }, t.deliveryInfo),
                                React.createElement("div", { className: styles.receiverContainer },
                                    React.createElement(Input, { fullWidth: true, id: "receiverName", label: (React.createElement("span", null,
                                            t.labelReceiverName,
                                            " ",
                                            React.createElement("span", { className: styles.red }, "*"))), onChange: this.handleChangeReceiver, value: orderInput.receiverName, limit: 50, errors: errors.receiverName })),
                                React.createElement("div", { className: styles.receiverContainer },
                                    React.createElement(Input, { fullWidth: true, id: "receiverPhone", label: (React.createElement("span", null,
                                            `${t.labelReceiverPhone} `,
                                            React.createElement("span", { className: styles.red }, "*"))), onChange: this.handleChangePhone, value: orderInput.receiverPhone, errors: errors.receiverPhone })),
                                !isEmptyDeliveryAddressesFull && (React.createElement("div", { className: styles.selectAddressContainer },
                                    React.createElement(RadioButton, { id: "existingAddressCheckbox", label: t.labelChooseYourAddress, isChecked: isAddressSelect, onChange: this.handleOnChangeAddressType }),
                                    isAddressSelect && (React.createElement("div", { className: styles.selectWrapper },
                                        React.createElement("div", null,
                                            React.createElement(Select, { label: t.labelAddress, items: addresses, activeItem: selectedAddress, onSelect: this.handleOnSelectAddress, forForm: true, containerStyle: { width: '26rem' }, dataTest: "selectExistingAddress" }))))))),
                            React.createElement(Col, { size: 12, sm: 9, md: 8, xl: 12 },
                                React.createElement("div", { className: classNames(styles, 'newAddressWrap', { isEmptyDeliveryAddressesFull }) },
                                    !isEmptyDeliveryAddressesFull && (React.createElement(RadioButton, { id: "newAddressCheckbox", label: t.labelOrFillFields, isChecked: isNewAddress, onChange: this.handleOnChangeAddressType })),
                                    isNewAddress && (React.createElement("div", { id: "deliveryAddress", className: styles.formWrapper },
                                        React.createElement(AddressForm, { isOpen: true, onChangeData: this.handleChangeData, country: addressFull ? addressFull.country : null, address: addressFull ? addressFull.value : null, addressFull: addressFull }),
                                        React.createElement("div", { className: styles.addressError }, errors.deliveryAddress
                                            ? head(errors.deliveryAddress)
                                            : ''),
                                        React.createElement("div", { className: styles.saveAddressWrapper },
                                            React.createElement(Checkbox, { id: "saveAddressCheckbox", label: t.labelSaveAsNewAddress, isChecked: saveAsNewAddress, onChange: onChangeSaveCheckbox }))))))))),
                React.createElement(Col, { size: 12, xl: 6 }, addressToString(orderInput.addressFull) && (React.createElement("div", { className: styles.addressInfoContainer },
                    React.createElement(AddressInfo, { addressFull: orderInput.addressFull, receiverName: orderInput.receiverName || `${me.firstName} ${me.lastName}`, email: me.email })))))));
    }
}
export default CheckoutAddress;
