// TODO: rename to UpdateStoreContactsMutation
import { commitMutation, graphql } from 'react-relay';
const mutation = graphql `
	mutation UpdateStoreMutation($input: UpdateStoreInput!) {
		updateStore(input: $input) {
			id
			rawId
			defaultLanguage
			email
			phone
			slug
			logo
			name {
				lang
				text
			}
			shortDescription {
				lang
				text
			}
			addressFull {
				country
				countryCode
				value
				administrativeAreaLevel1
				administrativeAreaLevel2
				locality
				political
				postalCode
				route
				streetNumber
			}
			address
			country
			facebookUrl
			twitterUrl
			instagramUrl
			baseProducts(first: 100) @connection(key: "Wizard_baseProducts") {
				edges {
					node {
						id
					}
				}
			}
		}
	}
`;
const commit = (params) => commitMutation(params.environment, {
    mutation,
    variables: params.variables,
    onCompleted: params.onCompleted,
    onError: params.onError
});
export default { commit };
