import { commitMutation, graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
const mutation = graphql `
	mutation DeactivateProductMutation($input: DeactivateProductInput!) {
		deactivateProduct(input: $input) {
			id
		}
	}
`;
const commit = (params) => commitMutation(params.environment, {
    mutation,
    variables: params.variables,
    onCompleted: params.onCompleted,
    onError: params.onError,
    updater: (relayStore) => {
        const deactivateProduct = relayStore.getRootField('deactivateProduct');
        const id = deactivateProduct.getValue('id');
        const { parentID } = params;
        const storeProxy = relayStore.get(parentID);
        const conn = ConnectionHandler.getConnection(storeProxy, 'Wizard_products');
        ConnectionHandler.deleteNode(conn, id);
    }
});
export default { commit };
