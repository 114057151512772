import { t } from '../../../../translation/utils';
const translations = {
    en: {
        setupStore: 'Set up store',
        defineFewSettings: 'Define a few settings that will make your sells effective and comfortable.',
        labelMainLanguage: 'Main language'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
