import { t } from '../../../../translation/utils';
const translations = {
    en: {
        firstNameMustNotBeEmpty: 'First name must not be empty',
        lastNameMustNotBeEmpty: 'Last name must not be empty',
        error: 'Error: ',
        close: 'Close.',
        save: 'Save',
        somethingGoingWrong: 'Something going wrong :(',
        userUpdated: 'User data updated!',
        personalDataSettings: 'Personal data settings',
        labelFirstName: 'First name',
        labelLastName: 'Last name',
        labelGender: 'Gender',
        labelBirthdate: 'Birthday',
        labelPhone: 'Phone',
        labelRefLink: 'Referral link',
        copied: 'Copied'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
