import { commitMutation, graphql } from 'react-relay';
const mutation = graphql `
	mutation SetProductQuantityInWarehouseMutation(
		$input: ProductQuantityInput!
	) {
		setProductQuantityInWarehouse(input: $input) {
			warehouseId
			quantity
			product {
				quantity
			}
		}
	}
`;
const commit = (params) => commitMutation(params.environment, {
    mutation,
    variables: params.variables,
    onCompleted: params.onCompleted,
    onError: params.onError
});
const promise = (variables, environment) => new Promise((resolve, reject) => {
    commit({
        variables,
        environment,
        onCompleted: (response, errors) => {
            if (errors) {
                reject(errors);
            }
            else if (response) {
                resolve(response);
            }
            else {
                reject([new Error('Unknown error')]);
            }
        },
        onError: (error) => {
            reject([error]);
        }
    });
});
export default {
    commit,
    promise
};
