import React, { PureComponent } from 'react';
import { Paginator } from '../../../components/common/Paginator';
import OrdersListHeader from './OrdersListHeader';
import TableTitle from './TableTitle';
import Table from './Table';
import styles from './OrdersList.module.scss';
class OrdersList extends PureComponent {
    handleSearchTermFilterChanged = (value) => {
        this.props.onSearchTermFilterChanged(value);
    };
    handleOrderStatusFilterChanged = (value) => {
        this.props.onOrderStatusFilterChanged(value);
    };
    handleOrderFromDateFilterChanged = (value) => {
        this.props.onOrderFromDateFilterChanged(value);
    };
    handleOrderToDateFilterChanged = (value) => {
        this.props.onOrderToDateFilterChanged(value);
    };
    render() {
        return (React.createElement("div", { className: styles.container },
            React.createElement(OrdersListHeader, { onSearchTermFilterChanged: this.handleSearchTermFilterChanged, onOrderStatusFilterChanged: this.handleOrderStatusFilterChanged, onOrderFromDateFilterChanged: this.handleOrderFromDateFilterChanged, onOrderToDateFilterChanged: this.handleOrderToDateFilterChanged }),
            React.createElement(TableTitle, null),
            React.createElement(Table, { items: this.props.orders, linkFactory: this.props.linkFactory }),
            React.createElement(Paginator, { pagesCount: this.props.pagesCount, currentPage: this.props.currentPage, onPageSelect: this.props.onPageSelect, isLoading: this.props.isLoadingPagination })));
    }
}
export default OrdersList;
