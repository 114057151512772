/**
 * @generated SignedSource<<f98319d3ed773ee0528027838d507ae5>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawId",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lang",
        "storageKey": null
    }, v5 = [
        (v3 /*: any*/),
        (v4 /*: any*/)
    ], v6 = {
        "alias": null,
        "args": null,
        "concreteType": "Translation",
        "kind": "LinkedField",
        "name": "name",
        "plural": true,
        "selections": (v5 /*: any*/),
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rating",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "price",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "Product_baseProduct",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isShippingAvailable",
                "storageKey": null
            },
            (v0 /*: any*/),
            (v1 /*: any*/),
            (v2 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "categoryId",
                "storageKey": null
            },
            (v6 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "Translation",
                "kind": "LinkedField",
                "name": "shortDescription",
                "plural": true,
                "selections": (v5 /*: any*/),
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "Translation",
                "kind": "LinkedField",
                "name": "longDescription",
                "plural": true,
                "selections": (v5 /*: any*/),
                "storageKey": null
            },
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Literal",
                        "name": "visibility",
                        "value": "active"
                    }
                ],
                "concreteType": "Store",
                "kind": "LinkedField",
                "name": "store",
                "plural": false,
                "selections": [
                    (v1 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "Translation",
                        "kind": "LinkedField",
                        "name": "name",
                        "plural": true,
                        "selections": [
                            (v4 /*: any*/),
                            (v3 /*: any*/)
                        ],
                        "storageKey": null
                    },
                    (v7 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "productsCount",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "logo",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "facebookUrl",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "twitterUrl",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "instagramUrl",
                        "storageKey": null
                    }
                ],
                "storageKey": "store(visibility:\"active\")"
            },
            (v7 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "Variants",
                "kind": "LinkedField",
                "name": "variants",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "all",
                        "plural": true,
                        "selections": [
                            (v0 /*: any*/),
                            (v1 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "photoMain",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "additionalPhotos",
                                "storageKey": null
                            },
                            (v8 /*: any*/),
                            (v2 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "CustomerPrice",
                                "kind": "LinkedField",
                                "name": "customerPrice",
                                "plural": false,
                                "selections": [
                                    (v8 /*: any*/),
                                    (v2 /*: any*/)
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "preOrder",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "preOrderDays",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cashback",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "discount",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "quantity",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProdAttrValue",
                                "kind": "LinkedField",
                                "name": "attributes",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "value",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "metaField",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Attribute",
                                        "kind": "LinkedField",
                                        "name": "attribute",
                                        "plural": false,
                                        "selections": [
                                            (v0 /*: any*/),
                                            (v6 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "AttributeMetaField",
                                                "kind": "LinkedField",
                                                "name": "metaField",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "values",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "uiElement",
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "BaseProduct",
        "abstractKey": null
    };
})();
node.hash = "f5cbecb8dca1cf4364591e4a10baccb3";
export default node;
