import { isNil, pick, isEmpty } from 'ramda';
export const formatStatus = (str) => str.replace(/[\W_]+/g, ' ')
    .toLowerCase();
export const pluckFromOrder = (odr) => (props) => {
    const order = !isNil(odr) ? { ...odr } : {};
    if (isEmpty(order)) {
        return order;
    }
    return pick(props, order);
};
export const setRegion = (admin1, admin2) => {
    let administrativeAreaLevel = '';
    if (!isNil(admin1) && !isNil(admin2)) {
        administrativeAreaLevel = `${admin1}, ${admin2}`;
    }
    if (!isNil(admin1) && isNil(admin2)) {
        administrativeAreaLevel = admin1;
    }
    if (isNil(admin1) && !isNil(admin2)) {
        administrativeAreaLevel = admin2;
    }
    return administrativeAreaLevel;
};
