import React, { Component } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { isEmpty, map, pathOr } from 'ramda';
import { withShowAlert } from '../../../../../../components/Alerts/AlertContext';
import { convertCountriesToArrCodes } from './utils';
import LocalShipping from './LocalShipping';
import InterShipping from './InterShipping';
class Shipping extends Component {
    constructor(props) {
        super(props);
        const shipping = pathOr(null, ['baseProduct', 'shipping'], props.baseProduct);
        this.state = {
            local: [],
            international: [],
            pickup: {
                pickup: false,
                price: 0
            },
            withoutInter: Boolean(shipping && isEmpty(shipping.international)),
            withoutLocal: Boolean(shipping && shipping.pickup && !shipping.pickup.pickup)
        };
        props.onChangeShipping(this.state);
    }
    componentDidUpdate(prevProps, prevState) {
        const { state } = this;
        if (JSON.stringify(state) !== JSON.stringify(prevState)) {
            this.props.onChangeShipping({ ...this.state });
        }
    }
    handleOnChangeShippingData = (data) => {
        const { companies, inter, pickup, withoutInter, withoutLocal } = data;
        if (withoutInter !== undefined) {
            this.setState({ withoutInter });
        }
        if (withoutLocal !== undefined) {
            this.setState({ withoutLocal });
        }
        if (companies) {
            if (inter === true) {
                const international = map((item) => ({
                    companyPackageId: item.companyPackageRawId,
                    price: item.price,
                    deliveriesTo: convertCountriesToArrCodes({ countries: item.countries })
                }), companies);
                this.setState({ international });
                return;
            }
            const local = map((item) => ({
                companyPackageId: item.companyPackageRawId,
                price: item.price
            }), companies);
            this.setState({ local });
        }
        if (pickup) {
            this.setState({ pickup });
        }
    };
    render() {
        const { currency, baseProduct, shippingErrors } = this.props;
        const localShipping = pathOr([], ['shipping', 'local'], baseProduct);
        const interShipping = pathOr([], ['shipping', 'international'], baseProduct);
        const pickupShipping = pathOr({}, ['shipping', 'pickup'], baseProduct);
        const localAvailablePackages = pathOr([], ['availablePackages', 'local'], this.props);
        const interAvailablePackages = pathOr([], ['availablePackages', 'international'], this.props);
        return (React.createElement("div", null,
            React.createElement(LocalShipping, { currency: currency, localShipping: localShipping, pickupShipping: pickupShipping, localAvailablePackages: localAvailablePackages, onChangeShippingData: this.handleOnChangeShippingData, error: shippingErrors ? shippingErrors.local : null }),
            React.createElement(InterShipping, { currency: currency, interShipping: interShipping, interAvailablePackages: interAvailablePackages, onChangeShippingData: this.handleOnChangeShippingData, error: shippingErrors ? shippingErrors.inter : null })));
    }
}
export default createFragmentContainer(withShowAlert(Shipping), {
    baseProduct: graphql `
			fragment Shipping_baseProduct on BaseProduct {
				shipping {
					local {
						companyPackageId
						price
					}
					international {
						companyPackageId
						price
						deliveriesTo {
							children {
								label
								children {
									parent
									alpha3
									alpha2
									label
								}
								alpha3
							}
						}
					}
					pickup {
						price
						pickup
					}
				}
			}
    `
});
