import { evolve, isEmpty, pathOr, reduce } from 'ramda';
import { debug, fromRelayError } from '../../../utils';
export const resposeLogger = (response, errors) => {
    debug({
        response,
        errors
    });
    const relayErrors = fromRelayError({ source: { errors } });
    debug({ relayErrors });
    const validationErrors = pathOr({}, ['100', 'messages'], relayErrors);
    const status = pathOr('', ['100', 'status'], relayErrors);
    if (validationErrors && !isEmpty(validationErrors)) {
        // this.setState({ formErrors: validationErrors });
        return validationErrors;
    }
    // if (status) {
    //   alert(`Error: "${status}"`);
    // }
    return null;
};
export const errorsLogger = (error) => {
    debug({ error });
    const relayErrors = fromRelayError(error);
    debug({ relayErrors });
    const validationErrors = pathOr(null, ['100', 'messages'], relayErrors);
    if (validationErrors) {
        // this.setState({ formErrors: validationErrors });
        return validationErrors;
    }
    // alert('Something going wrong :(');
    return null;
};
export const transformTranslated = (lang, arr, obj) => {
    const transformation = reduce((acc, next) => ({
        ...acc,
        [next]: (text) => ({
            lang,
            text
        })
    }), {}, arr);
    return evolve(transformation, obj);
};
