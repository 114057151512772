import React, { Component } from 'react';
import { isEmpty, map, pathOr } from 'ramda';
import { withRouter } from 'found';
import { classNames, flattenFunc, getNameText, searchPathByParent } from '../../utils';
import { categoryViewTracker } from '../../rrHalper';
import { SearchNoResults } from '../../components/SearchNoResults';
import { Button } from '../../components/common/Button';
import { Icon } from '../../components/Icon';
import styles from './SearchContent.module.scss';
import t from './i18n';
import CardProduct from '../../components/CardProduct/CardProduct';
class SearchContent extends Component {
    productsRefetch = () => {
        const { relay, productsPerRequest } = this.props;
        relay.loadMore(productsPerRequest);
    };
    renderBreadcrumbs = () => {
        const { router } = this.props;
        const categoryId = pathOr(null, ['match', 'location', 'query', 'category'], this.props);
        const categories = pathOr(null, [
            'search',
            'findProduct',
            'pageInfo',
            'searchFilters',
            'categories',
            'children'
        ], this.props);
        if (!categories || !categoryId) {
            return (React.createElement("div", { className: styles.breadcrumbs },
                React.createElement("div", { className: styles.item, onClick: () => router.push('/categories?search='), onKeyDown: () => {
                    }, role: "button", tabIndex: 0 }, t.allCategories)));
        }
        const arr = flattenFunc(categories);
        const pathArr = searchPathByParent(arr, parseInt(categoryId, 10));
        return (React.createElement("div", { className: styles.breadcrumbs },
            React.createElement("div", { className: styles.item, onClick: () => router.push('/categories?search='), onKeyDown: () => {
                }, role: "button", tabIndex: 0 }, t.allCategories),
            pathArr.length !== 0
                && pathArr.map((item) => (React.createElement("div", { key: item.rawId, className: styles.item },
                    React.createElement("span", { className: styles.separator }, "/"),
                    React.createElement("div", { className: classNames(styles, 'item', { active: item.rawId === parseInt(categoryId, 10) }), onClick: () => {
                            router.push(`/categories?search=&category=${item.rawId}`);
                            categoryViewTracker(parseInt(String(item.rawId), 10));
                        }, onKeyDown: () => { }, role: "button", tabIndex: 0 }, getNameText(item.name, 'EN')))))));
    };
    render() {
        const { relay, onFilterMenu } = this.props;
        const products = pathOr([], ['search', 'findProduct', 'edges'], this.props);
        const productsWithVariants = map((item) => item.node, products);
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.topContentContainer },
                this.renderBreadcrumbs(),
                React.createElement("span", { onClick: onFilterMenu, onKeyPress: () => {
                    }, role: "button", className: styles.filtersButton, tabIndex: -1 },
                    React.createElement(Icon, { type: "controls" }),
                    React.createElement("span", null, t.filters))),
            React.createElement("div", { className: styles.productsContainer }, !isEmpty(productsWithVariants) ? (map((item) => (React.createElement("div", { key: item.id, className: styles.cardWrapper },
                React.createElement(CardProduct, { item: { ...item }, isSearchPage: true }))), productsWithVariants)) : (React.createElement(SearchNoResults, null))),
            relay.hasMore() && (React.createElement("div", { className: styles.button },
                React.createElement(Button, { big: true, load: true, onClick: this.productsRefetch, dataTest: "searchProductLoadMoreButton", wireframe: true }, t.loadMore)))));
    }
}
export default withRouter(SearchContent);
