import React, { cloneElement, Component } from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import { complement, concat, filter, pathOr, pick, propEq } from 'ramda';
import { withRouter } from 'found';
import PropTypes from 'prop-types';
import { Environment } from 'relay-runtime';
import { AlertContextProvider } from '../Alerts/AlertContext';
import { getCookie, getQueryRefParams, setCookie } from '../../utils';
import AppContext from './AppContext';
import { AlertsContainer } from '../Alerts';
import './App.module.scss';
class App extends Component {
    state = { alerts: [] };
    static childContextTypes = {
        environment: PropTypes.instanceOf(Environment).isRequired,
        handleLogin: PropTypes.func,
        showAlert: PropTypes.func,
        directories: PropTypes.object,
        currentUser: PropTypes.shape({
            id: PropTypes.string,
            rawId: PropTypes.number
        })
    };
    getChildContext() {
        const { relay, me = {} } = this.props;
        return {
            environment: relay.environment,
            handleLogin: this.handleLogin,
            currentUser: pick(['id', 'rawId'], me || {}),
            directories: { ...this.makeDirectories() }
        };
    }
    componentDidMount() {
        const query = pathOr({}, ['match', 'location', 'query'], this.props);
        const queryRefParams = getQueryRefParams(query);
        if (process.env.BROWSER && document.referrer && !getCookie('REFERRER')) {
            setCookie('REFERRER', document.referrer);
        }
        if (queryRefParams.referral && !getCookie('REFERRAL')) {
            setCookie('REFERRAL', queryRefParams.referral);
        }
        if (queryRefParams.utmMarks && !getCookie('UTM_MARKS')) {
            setCookie('UTM_MARKS', queryRefParams.utmMarks);
        }
    }
    componentDidUpdate(prevProps) {
        const { match: { location: { pathname } } } = this.props;
        const { match: { location } } = prevProps;
        // ONLY scroll when paths are different
        if (pathname !== location.pathname) {
            if (process.env.BROWSER) {
                window.scrollTo(0, 0);
            }
        }
    }
    addAlert = (alert) => {
        this.setState((prevState) => ({
            alerts: concat([
                {
                    ...alert,
                    onClose: this.handleAlertClose,
                    createdAtTimestamp: Date.now() + Math.random() * 1000
                }
            ], prevState.alerts)
        }));
    };
    handleLogin = () => {
        this.props.relay.refetch({}, null, () => {
        }, { force: true });
    };
    handleAlertClose = (timestamp) => {
        this.setState((prevState) => ({
            alerts: filter(complement(propEq(timestamp, 'createdAtTimestamp')), prevState.alerts)
        }));
    };
    makeDirectories = () => {
        const { languages, currencies, sellerCurrencies, categories, orderStatuses, currencyExchange, countries } = this.props;
        return {
            categories,
            languages,
            orderStatuses,
            currencies,
            sellerCurrencies,
            currencyExchange,
            countries
        };
    };
    render() {
        const { me, mainPage, cart, children, categories, relay: { environment } } = this.props;
        const { handleLogin } = this;
        const directories = this.makeDirectories();
        return (React.createElement(AppContext.Provider, { value: {
                categories,
                environment,
                directories,
                handleLogin
            } },
            React.createElement(React.Fragment, null,
                React.createElement(AlertsContainer, { alerts: this.state.alerts }),
                React.createElement(AlertContextProvider, { value: { addAlert: this.addAlert } }, children && cloneElement(children, {
                    me,
                    mainPage,
                    cart
                })))));
    }
}
export default createRefetchContainer(withRouter(App), {
    me: graphql `
			fragment App_me on User {
				...Profile_me
				...ManageStoreMenu_me
				id
				rawId
				email
				firstName
				lastName
				avatar
				wizardStore {
					id
					completed
				}
				myStore {
					id
					rawId
				}
			}
    `
}, graphql `
		query App_me_Query {
			id
			me {
				...App_me
			}
		}
  `);
