import { t } from '../../../../translation/utils';
const translations = {
    en: {
        number: 'Number',
        date: 'Date',
        shop: 'Shop',
        delivery: 'Delivery',
        items: 'Items',
        price: 'Price',
        status: 'Status'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
