import React, { Component } from 'react';
import styles from './AutocompleteInput.module.scss';
import { classNames } from '../../../utils';
class AutocompleteInput extends Component {
    static getDerivedStateFromProps(nextProps, prevState) {
        if (Boolean(nextProps.value) !== prevState.labelFloat) {
            return {
                ...prevState,
                labelFloat: prevState.isFocus ? true : Boolean(nextProps.value)
            };
        }
        return null;
    }
    constructor(props) {
        super(props);
        this.state = {
            labelFloat: false,
            isFocus: false
        };
    }
    input;
    handleChange = (e) => {
        const { onChange } = this.props;
        onChange(e);
    };
    handleFocus = () => {
        const { onFocus } = this.props;
        const { labelFloat } = this.state;
        this.setState({
            labelFloat: !labelFloat || true,
            isFocus: true
        });
        if (onFocus) {
            onFocus();
        }
    };
    handleBlur = (e) => {
        const { value, onBlur } = this.props;
        this.setState({
            labelFloat: Boolean(value) && value.length > 0,
            isFocus: false
        });
        if (onBlur) {
            onBlur(e);
        }
    };
    render() {
        const { id, value, label, inputRef, onChange } = this.props;
        const { labelFloat, isFocus } = this.state;
        return (React.createElement("label", { htmlFor: id, className: classNames(styles, 'container', { isFocus }) },
            label && (React.createElement("span", { className: classNames(styles, 'label', { labelFloat: labelFloat || value }) }, label)),
            React.createElement("div", { className: styles.input },
                React.createElement("input", { type: "text", ref: inputRef, value: value || '', onChange: onChange, onFocus: this.handleFocus, onBlur: this.handleBlur, onKeyDown: this.props.onKeyDown, onClick: this.props.onClick, "data-test": "autocompleteAddress" }),
                React.createElement("hr", null))));
    }
}
export default AutocompleteInput;
