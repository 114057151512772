import React, { Component } from 'react';
import { withRouter } from 'found';
import { assoc, assocPath, concat, dissoc, drop, filter, find, head, includes, isEmpty, isNil, keys, length, map, omit, pathOr, propEq, propOr, values, whereEq } from 'ramda';
import { validate } from '@sansare/libstqfrontend';
import { createFragmentContainer, graphql } from 'react-relay';
import debounce from 'lodash.debounce';
import ModerationStatus from '../../../../../common/ModerationStatus';
import smoothscroll from '../../../../../../libs/smoothscroll';
import { withErrorBoundary } from '../../../../../../components/common/ErrorBoundaries';
import { Button, InputPrice, Select, SpinnerCircle } from '../../../../../../components/common';
import { CategorySelector } from '../../../../../../components/CategorySelector';
import { Icon } from '../../../../../../components/Icon';
import { Textarea } from '../../../../../../components/common/Textarea';
import { Input } from '../../../../../../components/common/Input';
import { withShowAlert } from '../../../../../../components/Alerts/AlertContext';
import { Modal } from '../../../../../../components/Modal';
import { RichEditor } from '../../../../../../components/RichEditor';
import { classNames, convertCurrenciesForSelect, error, findCategory, getNameText, vendorCodeGenerator } from '../../../../../../utils';
import { Shipping } from '../Shipping';
import Variants from '../Variants';
import Photos from '../Photos';
import Warehouses from '../Warehouses';
import Tabs from '../Tabs';
import Characteristics from '../Characteristics';
import VariantForm from '../VariantForm';
import AdditionalAttributes from '../AdditionalAttributes';
import PreOrder from '../PreOrder';
// import Metrics from '../Metrics';
import sendProductToModerationMutation from '../mutations/SendProductToModerationMutation';
import styles from '../Product.module.scss';
import t from './i18n';
class Form extends Component {
    static getDerivedStateFromProps(nextProps, prevState) {
        const currentFormErrors = prevState.formErrors;
        const nextFormErrors = nextProps.formErrors;
        if (isEmpty(currentFormErrors) && !isEmpty(nextFormErrors)) {
            const { scrollArr } = prevState;
            const oneArr = filter((item) => includes(item, keys(nextFormErrors)), scrollArr);
            if (!isEmpty(oneArr) && head(oneArr)) {
                smoothscroll.scrollTo(head(oneArr));
            }
            return {
                ...prevState,
                formErrors: nextFormErrors
            };
        }
        return null;
    }
    constructor(props) {
        super(props);
        const { baseProduct, currencies, customAttributes, onFetchPackages } = props;
        let currency = currencies[0];
        let form = {};
        let variantForForm = null;
        if (baseProduct) {
            ({ currency } = baseProduct);
            const { name, shortDescription, longDescription, seoTitle, seoDescription, category } = baseProduct;
            const allVariants = pathOr([], ['products', 'edges'], baseProduct);
            const filteredVariants = map((item) => item.node, allVariants);
            const mainVariant = head(filteredVariants);
            const { id, rawId, photoMain, additionalPhotos, vendorCode, price, cashback, discount, preOrderDays, preOrder } = mainVariant;
            const variantId = pathOr([], ['match', 'params', 'variantId'], props);
            variantForForm = this.getVariantForForm(variantId);
            form = {
                name: getNameText(name || [], 'EN') || '',
                shortDescription: getNameText(shortDescription || [], 'EN') || '',
                longDescription: getNameText(longDescription || [], 'EN') || '',
                seoTitle: getNameText(seoTitle || [], 'EN') || '',
                seoDescription: getNameText(seoDescription || [], 'EN') || '',
                categoryId: category ? category.rawId : null,
                idMainVariant: id,
                rawIdMainVariant: rawId,
                photoMain,
                photos: additionalPhotos || [],
                vendorCode,
                price,
                cashback: Math.round((cashback || 0) * 100),
                discount: Math.round((discount || 0) * 100),
                preOrderDays,
                preOrder,
                attributeValues: !isEmpty(customAttributes)
                    ? this.resetAttrValues(customAttributes, mainVariant)
                    : [],
                metrics: {
                    weightG: baseProduct.weightG || 0,
                    widthCm: baseProduct.widthCm || 0,
                    lengthCm: baseProduct.lengthCm || 0,
                    heightCm: baseProduct.heightCm || 0
                }
            };
        }
        else {
            form = {
                name: '',
                shortDescription: '',
                longDescription: '',
                seoTitle: '',
                seoDescription: '',
                categoryId: null,
                idMainVariant: null,
                rawIdMainVariant: null,
                photoMain: null,
                photos: [],
                vendorCode: '',
                price: 0,
                cashback: null,
                discount: null,
                quantity: null,
                preOrderDays: '',
                preOrder: false,
                attributeValues: !isEmpty(customAttributes)
                    ? this.resetAttrValues(customAttributes, null)
                    : [],
                metrics: {
                    weightG: 0,
                    widthCm: 0,
                    lengthCm: 0,
                    heightCm: 0
                }
            };
        }
        this.state = {
            form,
            formErrors: {},
            category: null,
            currency: {
                id: currency,
                label: currency
            },
            shippingErrors: null,
            scrollArr: [
                'name',
                'shortDescription',
                'longDescription',
                'vendorCode',
                'categoryId',
                'attributes',
                'price',
                'metrics'
            ],
            activeTab: 'variants',
            tabs: [
                {
                    id: 'variants',
                    label: t.labelVariants
                },
                {
                    id: 'delivery',
                    label: t.labelDelivery
                }
            ],
            variantForForm,
            isSendingToModeration: false,
            isShippingPopup: false
        };
        if (onFetchPackages) {
            this.onFetchPackages = debounce(onFetchPackages, 1000);
        }
    }
    componentDidUpdate(prevProps) {
        const { shippingData, customAttributes, baseProduct } = this.props;
        if (JSON.stringify(shippingData) !== JSON.stringify(prevProps.shippingData)) {
            this.resetShippingErrors();
        }
        if (JSON.stringify(prevProps.customAttributes)
            !== JSON.stringify(customAttributes)) {
            const attrValues = this.resetAttrValues(customAttributes);
            this.onChangeValues(attrValues);
        }
        const pathname = pathOr(null, ['match', 'location', 'pathname'], this.props);
        const prevPathname = pathOr(null, ['match', 'location', 'pathname'], prevProps);
        if (pathname !== prevPathname
            || (baseProduct
                && prevProps.baseProduct
                && JSON.stringify(prevProps.baseProduct.products)
                    !== JSON.stringify(baseProduct.products))) {
            const variantId = pathOr(null, ['match', 'params', 'variantId'], this.props);
            this.updateVariantForForm(this.getVariantForForm(variantId));
        }
    }
    onFetchPackages = undefined;
    onChangeValues = (attributeValues) => {
        this.setState((prevState) => assocPath(['form', 'attributeValues'], attributeValues, prevState));
    };
    getVariantForForm = (variantId) => {
        if (!variantId) {
            return null;
        }
        if (variantId === 'new') {
            return 'new';
        }
        const { baseProduct } = this.props;
        const allVariants = pathOr([], ['products', 'edges'], baseProduct);
        const filteredVariants = map((item) => item.node, allVariants);
        return find(propEq(parseFloat(variantId), 'rawId'))(filteredVariants);
    };
    updateVariantForForm = (variant) => {
        this.setState({ variantForForm: variant });
    };
    scrollToError = (errors) => {
        const { scrollArr } = this.state;
        const oneArr = filter((item) => includes(item, keys(errors)), scrollArr);
        if (!isEmpty(oneArr) && head(oneArr)) {
            smoothscroll.scrollTo(head(oneArr));
        }
    };
    resetAttrValues = (customAttributes, variant) => {
        const attributeValues = pathOr(null, ['form', 'attributeValues'], this.state);
        const attrValues = map((item) => {
            if (attributeValues) {
                const isAttributeValue = find(propEq(item.rawId, 'attrId'))(attributeValues);
                if (isAttributeValue) {
                    return isAttributeValue;
                }
            }
            return {
                attrId: item.rawId,
                ...this.valueForAttribute({
                    attr: item,
                    variant
                })
            };
        }, customAttributes);
        return attrValues;
    };
    valueForAttribute = (input) => {
        const { attr, variant } = input;
        const attrFromVariant = variant
            && find((item) => item.attribute.rawId === attr.rawId, variant.attributes);
        if (attrFromVariant && attrFromVariant.value) {
            return {
                value: attrFromVariant.value,
                metaField: attrFromVariant.metaField
            };
        }
        const { values: attributeValues, translatedValues } = attr.metaField;
        if (attributeValues) {
            return { value: head(attributeValues) || '' };
        }
        if (translatedValues && !isEmpty(translatedValues)) {
            return {
                value: pathOr('', [0, 'translations', 0, 'text'], translatedValues || [])
            };
        }
        return { value: '' };
    };
    resetShippingErrors = () => {
        this.setState({ shippingErrors: null });
    };
    validate = () => {
        const { errors } = validate({
            name: [[(val) => Boolean(val), t.nameIsRequired]],
            shortDescription: [[(val) => Boolean(val), t.shortDescriptionIsRequired]],
            longDescription: [[(val) => Boolean(val), t.longDescriptionIsRequired]],
            categoryId: [[(val) => Boolean(val), t.categoryIsRequired]],
            vendorCode: [[(val) => Boolean(val), t.SKUIsRequired]],
            price: [[(val) => Boolean(val), t.priceIsRequired]],
            metrics: [[(val) => !includes(0, values(val)), t.metricsError]]
        }, this.state.form);
        if (errors && !isEmpty(errors)) {
            this.scrollToError(errors);
        }
        return errors;
    };
    shippingValidate = () => {
        const { shippingData } = this.props;
        let shippingErrors;
        if (shippingData && !shippingData.withoutLocal && isEmpty(shippingData.local) && !shippingData.pickup.pickup) {
            shippingErrors = assoc('local', t.addAtLeastOneDeliveryServiceOrPickup, shippingErrors);
        }
        if (shippingData && !shippingData.withoutInter && isEmpty(shippingData.international)) {
            shippingErrors = assoc('inter', t.addAtLeastOneDeliveryDelivery, shippingErrors);
        }
        if (isEmpty(this.state.formErrors)) {
            if (shippingErrors && shippingErrors.local) {
                smoothscroll.scrollTo('localShippingError');
            }
            if (shippingErrors && !shippingErrors.local && shippingErrors.inter) {
                smoothscroll.scrollTo('interShippingError');
            }
        }
        return shippingErrors;
    };
    handleUpdateProduct = () => {
        // const { baseProduct } = this.props;
        // if (!baseProduct) {
        //   this.handleSave();
        //   return;
        // }
        // const { form } = this.state;
        // if (
        //   baseProduct.weightG !== form.metrics.weightG ||
        //   baseProduct.widthCm !== form.metrics.widthCm ||
        //   baseProduct.lengthCm !== form.metrics.lengthCm ||
        //   baseProduct.heightCm !== form.metrics.heightCm
        // ) {
        //   this.setState({ isShippingPopup: true });
        //   return;
        // }
        this.handleSave();
    };
    handleSave = (isAddVariant, withSavingShipping) => {
        const { baseProduct } = this.props;
        const { form, currency } = this.state;
        this.setState({
            formErrors: {},
            shippingErrors: null
        });
        const preValidationErrors = this.validate();
        const shippingValidationErrors = this.shippingValidate();
        if (preValidationErrors || shippingValidationErrors) {
            this.setState({
                formErrors: preValidationErrors || {},
                shippingErrors: shippingValidationErrors || undefined
            });
            return;
        }
        const savingData = {
            ...form,
            currency: currency ? currency.id : null
        };
        if (baseProduct) {
            this.props.onSave(savingData, withSavingShipping);
            return;
        }
        this.props.onSave(savingData, isAddVariant);
    };
    sendToModeration = () => {
        if (this.props.baseProduct != null && this.props.baseProduct.rawId != null) {
            this.setState({ isSendingToModeration: true });
            sendProductToModerationMutation({
                environment: this.props.environment,
                variables: {
                    // i have no idea why
                    id: this.props.baseProduct.rawId
                }
            })
                .then(() => {
                this.props.showAlert({
                    type: 'success',
                    text: 'Product has been sent to moderation',
                    link: { text: 'Close' }
                });
                return true;
            })
                .finally(() => {
                this.setState({ isSendingToModeration: false });
            })
                .catch(error);
        }
    };
    handleInputChange = (id) => (e) => {
        this.setState((prevState) => ({ formErrors: omit([id], prevState.formErrors) }));
        const { value } = e.target;
        this.setState((prevState) => assocPath(['form', id], value, prevState));
    };
    handleTextareaChange = (id) => (e) => {
        this.setState((preState) => ({ formErrors: omit([id], preState.formErrors) }));
        const { value } = e.target;
        this.setState((prevState) => assocPath(['form', id], value, prevState));
    };
    handleSelectedCategory = (categoryId) => {
        const { allCategories, onResetAttribute } = this.props;
        const category = findCategory(whereEq({ rawId: parseInt(String(categoryId), 10) }), allCategories);
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                categoryId
            },
            category: {
                id: category.id,
                rawId: category.rawId,
                getAttributes: category.getAttributes
            },
            formErrors: dissoc('categoryId', prevState.formErrors)
        }), () => {
            if (onResetAttribute) {
                onResetAttribute(categoryId);
            }
        });
    };
    handleOnSelectCurrency = (currency) => {
        this.setState({ currency });
    };
    handleAddMainPhoto = (url) => {
        this.setState((prevState) => assocPath(['form', 'photoMain'], url, prevState));
    };
    handleAddPhoto = (photosUrls) => {
        const { photos } = this.state.form;
        const newPhotos = concat(photos || [], photosUrls);
        this.setState((prevState) => assocPath(['form', 'photos'], newPhotos, prevState));
    };
    handleRemovePhoto = (url) => {
        const { photoMain, photos } = this.state.form;
        if (url === photoMain) {
            this.setState((prevState) => assocPath(['form', 'photoMain'], null, prevState));
            return;
        }
        const newPhotos = filter((item) => item !== url, photos || []);
        this.setState((prevState) => assocPath(['form', 'photos'], newPhotos, prevState));
    };
    handlePriceChange = (value) => {
        this.setState((prevState) => {
            const formErrors = dissoc('price', prevState.formErrors);
            return {
                ...assocPath(['form', 'price'], value, prevState),
                formErrors
            };
        });
    };
    handlePercentChange = (id) => (e) => {
        const { target: { value } } = e;
        if (value === '') {
            this.setState((prevState) => assocPath(['form', id], null, prevState));
            return;
        }
        if (value === 0) {
            this.setState((prevState) => assocPath(['form', id], 0, prevState));
            return;
        }
        if (value > 100) {
            this.setState((prevState) => assocPath(['form', id], 100, prevState));
            return;
        }
        if (Number.isNaN(parseFloat(value))) {
            return;
        }
        this.setState((prevState) => assocPath(['form', id], parseFloat(value), prevState));
    };
    handleQuantityChange = (e) => {
        const { target: { value } } = e;
        if (value === '') {
            this.setState((prevState) => assocPath(['form', 'quantity'], null, prevState));
            return;
        }
        if (value === '0') {
            this.setState((prevState) => assocPath(['form', 'quantity'], 0, prevState));
            return;
        }
        if (Number.isNaN(parseFloat(value))) {
            return;
        }
        this.setState((prevState) => assocPath(['form', 'quantity'], parseFloat(value), prevState));
    };
    handleChangeValues = (attributeValues) => {
        this.setState((prevState) => {
            const formErrors = dissoc('attributes', prevState.formErrors);
            return {
                ...assocPath(['form', 'attributeValues'], attributeValues, prevState),
                formErrors
            };
        });
    };
    handleChangeTab = (activeTab) => {
        this.setState({ activeTab });
    };
    addNewVariant = () => {
        this.setState({ variantForForm: 'new' });
        this.handleChangePathName('new');
    };
    handleCopyVariant = (variant) => {
        this.setState({
            variantForForm: {
                ...variant,
                vendorCode: vendorCodeGenerator()
            }
        }, () => {
            this.handleChangePathName('new');
        });
    };
    cancelVariantForm = (isClose) => {
        this.setState({ variantForForm: null }, () => {
            if (!isClose) {
                smoothscroll.scrollTo('tabs', () => {
                }, true);
            }
            else {
                window.scroll({ top: 0 });
            }
        });
        this.handleChangePathName();
    };
    expandClick = (id) => {
        this.handleChangePathName(id);
    };
    handleChangePathName = (variant) => {
        const baseProductRawId = pathOr(null, ['baseProduct', 'rawId'], this.props);
        const storeRawId = pathOr(null, ['baseProduct', 'store', 'rawId'], this.props);
        this.props.router.push(`/manage/store/${storeRawId}/products/${parseInt(baseProductRawId, 10)}${variant ? `/variant/${variant}` : ''}`);
    };
    handleSaveShipping = (onlyShippingSave) => {
        const shippingValidationErrors = this.shippingValidate();
        if (shippingValidationErrors) {
            this.setState({ shippingErrors: shippingValidationErrors || null });
            return;
        }
        this.props.onSaveShipping(onlyShippingSave);
    };
    isSaveAvailable = () => {
        const status = pathOr(null, ['baseProduct', 'status'], this.props);
        return status === 'DRAFT' || status === 'DECLINE' || status === 'PUBLISHED';
    };
    handleChangePreOrder = (data) => {
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                preOrderDays: data.preOrderDays,
                preOrder: data.preOrder
            }
        }));
    };
    handleChangeMetrics = (metrics) => {
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                metrics
            },
            formErrors: dissoc('metrics', prevState.formErrors)
        }), () => {
            if (this.onFetchPackages) {
                this.onFetchPackages(metrics);
            }
        });
    };
    handleCloseShippingPopup = () => {
        this.setState({ isShippingPopup: false });
    };
    handleLongDescription = (longDescription) => {
        const { formErrors } = this.state;
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                longDescription
            },
            formErrors: omit(['longDescription'], formErrors)
        }));
        console.log(this.state.form.longDescription);
    };
    handleError = (err) => {
        const { showAlert } = this.props;
        showAlert({
            type: 'danger',
            text: err.message,
            link: { text: t.close }
        });
    };
    renderInput = (props) => {
        const { id, label, limit, required } = props;
        const hereLabel = required ? (React.createElement("span", null,
            label,
            ' ',
            React.createElement("span", { className: styles.asterisk }, "*"))) : (label);
        const value = pathOr('', ['form', id], this.state);
        const errors = pathOr(undefined, ['formErrors', id], this.state);
        return (React.createElement(Input, { id: id, value: value, label: hereLabel, onChange: this.handleInputChange(id), errors: errors, limit: limit, fullWidth: true }));
    };
    renderTextarea = (props) => {
        const { id, label, required, limit } = props;
        const requiredLabel = (React.createElement("span", null,
            label,
            " ",
            React.createElement("span", { className: styles.asterisk }, "*")));
        const value = pathOr('', ['form', id], this.state);
        const errors = pathOr('', ['formErrors', id], this.state);
        return (React.createElement(Textarea, { id: id, value: value, label: required ? requiredLabel : label, onChange: this.handleTextareaChange(id), errors: errors, limit: limit, fullWidth: true }));
    };
    render() {
        const { isLoading, baseProduct, availablePackages, isLoadingPackages, onChangeShipping, onCreateAttribute, onRemoveAttribute, customAttributes, environment, isLoadingShipping, showAlert, currencies } = this.props;
        const { category, currency, shippingErrors, formErrors, form, activeTab, tabs, variantForForm, isSendingToModeration, isShippingPopup } = this.state;
        const variants = pathOr([], ['products', 'edges'], baseProduct);
        const filteredVariants = map((item) => item.node, variants) || [];
        const mainVariant = isEmpty(filteredVariants) ? null : head(filteredVariants);
        const restVariants = !isEmpty(filteredVariants) && length(filteredVariants) > 1 ? drop(1, filteredVariants) : null;
        const storeRawID = pathOr(null, ['store', 'rawId'], baseProduct);
        const baseProductRawID = pathOr(null, ['rawId'], baseProduct);
        const categoryEquality = !isNil(baseProduct) && baseProduct.category.rawId === form.categoryId;
        let defaultAttributes = null;
        const categoryAttributes = pathOr(null, ['getAttributes'], category);
        const baseProductAttributes = pathOr(null, ['category', 'getAttributes'], baseProduct);
        if (categoryAttributes && !isEmpty(categoryAttributes)) {
            defaultAttributes = categoryAttributes;
        }
        if (baseProductAttributes
            && !isEmpty(baseProductAttributes)
            && categoryEquality) {
            defaultAttributes = baseProductAttributes;
        }
        const { photos, photoMain, price, cashback, discount, quantity, attributeValues, preOrder, preOrderDays
        // metrics,
         } = form;
        const longDescriptionError = propOr(null, 'longDescription', this.state.formErrors);
        return (React.createElement("div", { className: styles.container },
            !variantForForm && (React.createElement("div", null,
                baseProduct && (React.createElement("div", { className: styles.status },
                    React.createElement(ModerationStatus, { status: baseProduct.status, dataTest: `productStatus_${baseProduct.status}`, link: process.env.REACT_APP_HOST && storeRawID && baseProductRawID
                            ? `${process.env.REACT_APP_HOST}/store/${storeRawID}/products/${baseProductRawID}`
                            : null }))),
                React.createElement("div", { className: styles.form },
                    React.createElement("div", { className: styles.title },
                        React.createElement("strong", null, t.productPhotos)),
                    React.createElement(Photos, { photos: photos, photoMain: photoMain, onAddMainPhoto: this.handleAddMainPhoto, onAddPhoto: this.handleAddPhoto, onRemovePhoto: this.handleRemovePhoto }),
                    React.createElement("div", { className: classNames(styles, 'title', 'titleGeneral') },
                        React.createElement("strong", null, t.generalSettings)),
                    React.createElement("div", { className: styles.formItem }, this.renderInput({
                        id: 'name',
                        label: t.labelProductName,
                        limit: 150,
                        required: true
                    })),
                    React.createElement("div", { className: classNames(styles, 'formItem', 'textArea') }, this.renderTextarea({
                        id: 'shortDescription',
                        label: t.labelShortDescription,
                        limit: 170,
                        required: true
                    })),
                    React.createElement("div", { className: styles.editor },
                        React.createElement("span", { id: "longDescription", className: styles.label },
                            t.labelLongDescription,
                            " ",
                            React.createElement("span", { className: styles.asterisk }, "*")),
                        React.createElement(RichEditor, { content: this.state.form.longDescription, onChange: this.handleLongDescription, onError: this.handleError }),
                        longDescriptionError && (React.createElement("div", { className: styles.error }, this.state.formErrors.longDescription[0]))),
                    React.createElement("div", { className: styles.formItem },
                        React.createElement(Select, { forForm: true, label: t.labelCurrency, activeItem: currency, items: convertCurrenciesForSelect(currencies), onSelect: this.handleOnSelectCurrency, dataTest: "productCurrencySelect", fullWidth: true })),
                    React.createElement("div", { className: styles.formItem }, this.renderInput({
                        id: 'vendorCode',
                        label: t.labelSKU,
                        limit: 50,
                        required: true
                    })),
                    React.createElement("div", { className: styles.formItem }, this.renderInput({
                        id: 'seoTitle',
                        label: t.labelSEOTitle,
                        limit: 50
                    })),
                    React.createElement("div", { className: classNames(styles, 'formItem', 'textArea') }, this.renderTextarea({
                        id: 'seoDescription',
                        label: t.labelSEODescription
                    })),
                    React.createElement("div", { className: styles.categorySelector },
                        React.createElement(CategorySelector, { id: "categoryId", categories: this.props.allCategories, category: baseProduct && baseProduct.category, onSelect: (itemId) => {
                                this.handleSelectedCategory(itemId);
                            } }),
                        !categoryEquality
                            && baseProduct
                            && restVariants && (React.createElement("div", { className: styles.categoryWarn }, t.categoryWarn)),
                        formErrors
                            && formErrors.categoryId && (React.createElement("div", { className: styles.categoryError }, formErrors.categoryId))),
                    defaultAttributes
                        && !isEmpty(defaultAttributes)
                        && !categoryEquality && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: classNames(styles, 'title', 'titleCharacteristics') },
                            React.createElement("strong", null, t.characteristics)),
                        React.createElement("div", { className: classNames(styles, 'formItem', 'additionalAttributes') },
                            React.createElement(AdditionalAttributes, { onlyView: categoryEquality, attributes: defaultAttributes, customAttributes: customAttributes, onCreateAttribute: onCreateAttribute, onRemoveAttribute: onRemoveAttribute })))),
                    !isEmpty(customAttributes) && (React.createElement("div", { className: styles.characteristics },
                        React.createElement(Characteristics, { customAttributes: customAttributes, values: attributeValues || [], onChange: this.handleChangeValues, errors: (formErrors && formErrors.attributes) || null }))),
                    React.createElement("div", { className: classNames(styles, 'title', 'titlePricing') },
                        React.createElement("strong", null, t.pricing)),
                    React.createElement("div", { className: styles.formItem },
                        React.createElement(InputPrice, { id: "price", required: true, label: t.labelPrice, onChangePrice: this.handlePriceChange, price: parseFloat(String(price)) || 0, currency: currency, errors: formErrors && formErrors.price, dataTest: "variantPriceInput" })),
                    React.createElement("div", { className: styles.formItem },
                        React.createElement(Input, { fullWidth: true, id: "cashback", label: t.labelCashback, onChange: this.handlePercentChange('cashback'), value: !isNil(cashback) ? `${cashback}` : '', dataTest: "variantCashbackInput" }),
                        React.createElement("span", { className: styles.inputPostfix }, t.percent)),
                    React.createElement("div", { className: styles.formItem },
                        React.createElement(Input, { fullWidth: true, id: "discount", label: t.labelDiscount, onChange: this.handlePercentChange('discount'), value: !isNil(discount) ? `${discount}` : '', dataTest: "variantDiscountInput" }),
                        React.createElement("span", { className: styles.inputPostfix }, t.percent)),
                    !baseProduct && (React.createElement("div", { className: styles.formItem },
                        React.createElement(Input, { fullWidth: true, id: "quantity", label: t.labelQuantity, onChange: this.handleQuantityChange, value: !isNil(quantity) ? `${quantity}` : '', dataTest: "variantQuantityInput" }))),
                    React.createElement("div", { className: styles.preOrder },
                        React.createElement(PreOrder, { preOrderDays: preOrderDays, preOrder: preOrder, onChangePreOrder: this.handleChangePreOrder })),
                    React.createElement("div", { className: styles.warehouses }, mainVariant && React.createElement(Warehouses, { stocks: mainVariant.stocks })),
                    React.createElement("div", { className: styles.buttonsWrapper },
                        React.createElement("div", { className: styles.button },
                            React.createElement(Button, { big: true, fullWidth: true, onClick: baseProduct
                                    ? this.handleUpdateProduct
                                    : () => {
                                        this.handleSave();
                                    }, disabled: baseProduct != null && !this.isSaveAvailable(), dataTest: "saveProductButton", isLoading: isLoading || isSendingToModeration }, baseProduct ? t.updateProduct : t.createProduct)),
                        baseProduct
                            && baseProduct.status === 'DRAFT' && (React.createElement("div", { className: classNames(styles, 'button', 'moderationButton') },
                            React.createElement(Button, { big: true, fullWidth: true, onClick: this.sendToModeration, dataTest: "sendToModerationProductButton", isLoading: isSendingToModeration || isLoading }, t.sendToModeration))),
                        baseProduct != null
                            && baseProduct.status === 'MODERATION' && (React.createElement("div", { className: styles.warnMessage }, t.baseProductIsOnModeration)),
                        baseProduct != null
                            && baseProduct.status === 'BLOCKED' && (React.createElement("div", { className: styles.warnMessage }, t.baseProductIsBlocked)))),
                React.createElement("div", { id: "tabs", className: styles.tabs },
                    React.createElement(Tabs, { tabs: tabs, activeTab: activeTab, onChangeTab: this.handleChangeTab },
                        React.createElement("div", { className: styles.tabsWrap },
                            React.createElement("div", { className: classNames(styles, 'variants', { hidden: activeTab !== 'variants' }) },
                                !restVariants && (React.createElement("div", { className: styles.noVariants },
                                    React.createElement("div", { className: styles.variantsIcon },
                                        React.createElement(Icon, { type: "addVariant", size: 80 })),
                                    React.createElement("div", { className: styles.variantsText },
                                        t.currentlyYouHaveNoVariantsForYourProduct,
                                        ".",
                                        React.createElement("br", null),
                                        t.addVariantsIfYouNeedSome),
                                    React.createElement("div", { className: styles.variantsButton },
                                        React.createElement(Button, { big: true, wireframe: true, fullWidth: true, onClick: baseProduct
                                                ? this.addNewVariant
                                                : () => {
                                                    this.handleSave(true);
                                                }, disabled: isEmpty(customAttributes)
                                                || isEmpty(attributeValues)
                                                || (baseProduct != null
                                                    && baseProduct.status === 'MODERATION')
                                                || (baseProduct != null
                                                    && baseProduct.status === 'BLOCKED'), dataTest: "addVariantButton" }, t.addVariant)),
                                    !baseProduct && (React.createElement(React.Fragment, null, (isEmpty(customAttributes)
                                        || isEmpty(attributeValues)) && (React.createElement("div", { className: styles.variantsWarnText }, t.variantTabWarnMessages.youCantAddVariant)))),
                                    baseProduct && (React.createElement(React.Fragment, null, (isEmpty(customAttributes)
                                        || isEmpty(attributeValues)) && (React.createElement("div", { className: styles.variantsWarnText },
                                        t.variantTabWarnMessages.thisCategory,
                                        React.createElement("br", null),
                                        t.variantTabWarnMessages.сurrentlyThisOption)))))),
                                restVariants
                                    && baseProduct && (React.createElement("div", { className: styles.isVariants },
                                    React.createElement(Variants, { variants: restVariants, productId: baseProduct.id, currency: currency, environment: environment, onExpandClick: this.expandClick, showAlert: showAlert, onCopyVariant: this.handleCopyVariant }),
                                    React.createElement("div", { className: styles.variantsButton },
                                        React.createElement(Button, { big: true, wireframe: true, fullWidth: true, onClick: this.addNewVariant, disabled: isEmpty(customAttributes)
                                                || isEmpty(attributeValues)
                                                || (baseProduct != null
                                                    && baseProduct.status === 'MODERATION')
                                                || (baseProduct != null
                                                    && baseProduct.status === 'BLOCKED'), dataTest: "addVariantButton" }, t.addVariant)),
                                    React.createElement(React.Fragment, null, (isEmpty(customAttributes)
                                        || isEmpty(attributeValues)) && (React.createElement("div", { className: styles.variantsWarnText },
                                        t.variantTabWarnMessages.thisCategory,
                                        React.createElement("br", null),
                                        t.variantTabWarnMessages.сurrentlyThisOption)))))),
                            React.createElement("div", { className: classNames(styles, 'delivery', { hidden: activeTab !== 'delivery' }) },
                                !isLoadingPackages && (React.createElement(React.Fragment, null,
                                    React.createElement(Shipping, { currency: currency, baseProduct: baseProduct, baseProductId: baseProductRawID, storeId: storeRawID, availablePackages: availablePackages, onChangeShipping: onChangeShipping, shippingErrors: shippingErrors }),
                                    React.createElement("div", { className: styles.deliveryButton },
                                        React.createElement(Button, { big: true, fullWidth: true, onClick: baseProduct
                                                ? () => {
                                                    this.handleSaveShipping(true);
                                                }
                                                : () => {
                                                    this.handleSave();
                                                }, dataTest: "saveShippingButton", isLoading: !baseProduct ? isLoading : isLoadingShipping }, t.save)))),
                                isLoadingPackages && (React.createElement("div", { className: styles.spinner },
                                    React.createElement(SpinnerCircle, { additionalStyles: {}, containerStyles: {} }))))))))),
            variantForForm
                && baseProduct && (React.createElement("div", { className: styles.variantForm },
                React.createElement(VariantForm, { cancelVariantForm: this.cancelVariantForm, customAttributes: customAttributes, mainVariant: mainVariant, variant: variantForForm, showAlert: showAlert, environment: environment, productId: baseProduct.id, productRawId: baseProduct.rawId, currency: currency }))),
            React.createElement(Modal, { showModal: isShippingPopup, onClose: this.handleCloseShippingPopup },
                React.createElement("div", { className: styles.shippingPopup },
                    React.createElement("div", { className: styles.shippingPopupWrapper },
                        React.createElement("div", { className: styles.shippingPopupDescription }, "After saving the lists of logistics companies will be updated. Do you want to continue?"),
                        React.createElement("div", { className: styles.shippingPopupButtons },
                            React.createElement(Button, { onClick: this.handleCloseShippingPopup, dataTest: "", wireframe: true, big: true },
                                React.createElement("span", null, "Cancel")),
                            React.createElement("div", { className: styles.shippingPopupOkButton },
                                React.createElement(Button, { onClick: () => {
                                        this.setState({ isShippingPopup: false }, () => {
                                            this.handleSave(undefined, true);
                                        });
                                    }, dataTest: "", big: true, isLoading: isLoadingPackages },
                                    React.createElement("span", null, "Ok")))))))));
    }
}
export default createFragmentContainer(withRouter(withErrorBoundary(withShowAlert(Form))), {
    allCategories: graphql `
			fragment Form_allCategories on Category {
				name {
					lang
					text
				}
				children {
					id
					rawId
					parentId
					level
					name {
						lang
						text
					}
					children {
						id
						rawId
						parentId
						level
						name {
							lang
							text
						}
						children {
							id
							rawId
							parentId
							level
							name {
								lang
								text
							}
							getAttributes {
								id
								rawId
								name {
									text
									lang
								}
								metaField {
									values
									translatedValues {
										translations {
											text
										}
									}
								}
							}
						}
					}
				}
			}
    `
});
