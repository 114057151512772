/**
 * @generated SignedSource<<82b89831d371ce2445ddc3839dc30004>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawId",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "storeId",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
    }, v5 = [
        (v0 /*: any*/)
    ], v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lang",
        "storageKey": null
    }, v8 = [
        (v7 /*: any*/),
        (v6 /*: any*/)
    ], v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "price",
        "storageKey": null
    }, v10 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    }, v11 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
    }, v12 = {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
            }
        ],
        "storageKey": null
    };
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                {
                    "count": null,
                    "cursor": null,
                    "direction": "forward",
                    "path": null
                },
                {
                    "count": null,
                    "cursor": null,
                    "direction": "forward",
                    "path": [
                        "wizardStore",
                        "store",
                        "baseProducts"
                    ]
                }
            ]
        },
        "name": "Wizard_me",
        "selections": [
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Cards_me"
            },
            (v0 /*: any*/),
            (v1 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "Store",
                "kind": "LinkedField",
                "name": "myStore",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    (v1 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "StoreSubscription",
                        "kind": "LinkedField",
                        "name": "storeSubscription",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/),
                            (v2 /*: any*/),
                            (v3 /*: any*/),
                            (v4 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "walletAddress",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "trialStartDate",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "status",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "Customer",
                "kind": "LinkedField",
                "name": "stripeCustomer",
                "plural": false,
                "selections": (v5 /*: any*/),
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "WizardStore",
                "kind": "LinkedField",
                "name": "wizardStore",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    (v1 /*: any*/),
                    (v2 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "slug",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "shortDescription",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "defaultLanguage",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "completed",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "Address",
                        "kind": "LinkedField",
                        "name": "addressFull",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "country",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "countryCode",
                                "storageKey": null
                            },
                            (v4 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "administrativeAreaLevel1",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "administrativeAreaLevel2",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "locality",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "political",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "postalCode",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "route",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "streetNumber",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "Store",
                        "kind": "LinkedField",
                        "name": "store",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/),
                            (v1 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "GraphQLWarehouse",
                                "kind": "LinkedField",
                                "name": "warehouses",
                                "plural": true,
                                "selections": (v5 /*: any*/),
                                "storageKey": null
                            },
                            {
                                "alias": "baseProducts",
                                "args": null,
                                "concreteType": "BaseProductsConnection",
                                "kind": "LinkedField",
                                "name": "__Wizard_baseProducts_connection",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "BaseProductsEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "BaseProduct",
                                                "kind": "LinkedField",
                                                "name": "node",
                                                "plural": false,
                                                "selections": [
                                                    (v0 /*: any*/),
                                                    (v1 /*: any*/),
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "Translation",
                                                        "kind": "LinkedField",
                                                        "name": "name",
                                                        "plural": true,
                                                        "selections": [
                                                            (v6 /*: any*/),
                                                            (v7 /*: any*/)
                                                        ],
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "Translation",
                                                        "kind": "LinkedField",
                                                        "name": "shortDescription",
                                                        "plural": true,
                                                        "selections": (v8 /*: any*/),
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "Category",
                                                        "kind": "LinkedField",
                                                        "name": "category",
                                                        "plural": false,
                                                        "selections": [
                                                            (v0 /*: any*/),
                                                            (v1 /*: any*/)
                                                        ],
                                                        "storageKey": null
                                                    },
                                                    (v2 /*: any*/),
                                                    (v3 /*: any*/),
                                                    {
                                                        "alias": "products",
                                                        "args": null,
                                                        "concreteType": "ProductsConnection",
                                                        "kind": "LinkedField",
                                                        "name": "__Wizard_products_connection",
                                                        "plural": false,
                                                        "selections": [
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "concreteType": "ProductsEdge",
                                                                "kind": "LinkedField",
                                                                "name": "edges",
                                                                "plural": true,
                                                                "selections": [
                                                                    {
                                                                        "alias": null,
                                                                        "args": null,
                                                                        "concreteType": "Product",
                                                                        "kind": "LinkedField",
                                                                        "name": "node",
                                                                        "plural": false,
                                                                        "selections": [
                                                                            (v0 /*: any*/),
                                                                            (v1 /*: any*/),
                                                                            (v9 /*: any*/),
                                                                            {
                                                                                "alias": null,
                                                                                "args": null,
                                                                                "kind": "ScalarField",
                                                                                "name": "discount",
                                                                                "storageKey": null
                                                                            },
                                                                            {
                                                                                "alias": null,
                                                                                "args": null,
                                                                                "kind": "ScalarField",
                                                                                "name": "quantity",
                                                                                "storageKey": null
                                                                            },
                                                                            {
                                                                                "alias": null,
                                                                                "args": null,
                                                                                "kind": "ScalarField",
                                                                                "name": "photoMain",
                                                                                "storageKey": null
                                                                            },
                                                                            {
                                                                                "alias": null,
                                                                                "args": null,
                                                                                "kind": "ScalarField",
                                                                                "name": "additionalPhotos",
                                                                                "storageKey": null
                                                                            },
                                                                            {
                                                                                "alias": null,
                                                                                "args": null,
                                                                                "kind": "ScalarField",
                                                                                "name": "vendorCode",
                                                                                "storageKey": null
                                                                            },
                                                                            {
                                                                                "alias": null,
                                                                                "args": null,
                                                                                "kind": "ScalarField",
                                                                                "name": "cashback",
                                                                                "storageKey": null
                                                                            },
                                                                            {
                                                                                "alias": null,
                                                                                "args": null,
                                                                                "concreteType": "CustomerPrice",
                                                                                "kind": "LinkedField",
                                                                                "name": "customerPrice",
                                                                                "plural": false,
                                                                                "selections": [
                                                                                    (v9 /*: any*/),
                                                                                    (v3 /*: any*/)
                                                                                ],
                                                                                "storageKey": null
                                                                            },
                                                                            {
                                                                                "alias": null,
                                                                                "args": null,
                                                                                "concreteType": "ProdAttrValue",
                                                                                "kind": "LinkedField",
                                                                                "name": "attributes",
                                                                                "plural": true,
                                                                                "selections": [
                                                                                    {
                                                                                        "alias": null,
                                                                                        "args": null,
                                                                                        "kind": "ScalarField",
                                                                                        "name": "attrId",
                                                                                        "storageKey": null
                                                                                    },
                                                                                    (v4 /*: any*/),
                                                                                    {
                                                                                        "alias": null,
                                                                                        "args": null,
                                                                                        "kind": "ScalarField",
                                                                                        "name": "metaField",
                                                                                        "storageKey": null
                                                                                    },
                                                                                    {
                                                                                        "alias": null,
                                                                                        "args": null,
                                                                                        "concreteType": "Attribute",
                                                                                        "kind": "LinkedField",
                                                                                        "name": "attribute",
                                                                                        "plural": false,
                                                                                        "selections": [
                                                                                            (v0 /*: any*/),
                                                                                            (v1 /*: any*/),
                                                                                            {
                                                                                                "alias": null,
                                                                                                "args": null,
                                                                                                "concreteType": "Translation",
                                                                                                "kind": "LinkedField",
                                                                                                "name": "name",
                                                                                                "plural": true,
                                                                                                "selections": (v8 /*: any*/),
                                                                                                "storageKey": null
                                                                                            },
                                                                                            {
                                                                                                "alias": null,
                                                                                                "args": null,
                                                                                                "concreteType": "AttributeMetaField",
                                                                                                "kind": "LinkedField",
                                                                                                "name": "metaField",
                                                                                                "plural": false,
                                                                                                "selections": [
                                                                                                    {
                                                                                                        "alias": null,
                                                                                                        "args": null,
                                                                                                        "kind": "ScalarField",
                                                                                                        "name": "values",
                                                                                                        "storageKey": null
                                                                                                    },
                                                                                                    {
                                                                                                        "alias": null,
                                                                                                        "args": null,
                                                                                                        "concreteType": "TranslatedValue",
                                                                                                        "kind": "LinkedField",
                                                                                                        "name": "translatedValues",
                                                                                                        "plural": true,
                                                                                                        "selections": [
                                                                                                            {
                                                                                                                "alias": null,
                                                                                                                "args": null,
                                                                                                                "concreteType": "Translation",
                                                                                                                "kind": "LinkedField",
                                                                                                                "name": "translations",
                                                                                                                "plural": true,
                                                                                                                "selections": [
                                                                                                                    (v6 /*: any*/)
                                                                                                                ],
                                                                                                                "storageKey": null
                                                                                                            }
                                                                                                        ],
                                                                                                        "storageKey": null
                                                                                                    }
                                                                                                ],
                                                                                                "storageKey": null
                                                                                            }
                                                                                        ],
                                                                                        "storageKey": null
                                                                                    }
                                                                                ],
                                                                                "storageKey": null
                                                                            },
                                                                            (v10 /*: any*/)
                                                                        ],
                                                                        "storageKey": null
                                                                    },
                                                                    (v11 /*: any*/)
                                                                ],
                                                                "storageKey": null
                                                            },
                                                            (v12 /*: any*/)
                                                        ],
                                                        "storageKey": null
                                                    },
                                                    (v10 /*: any*/)
                                                ],
                                                "storageKey": null
                                            },
                                            (v11 /*: any*/)
                                        ],
                                        "storageKey": null
                                    },
                                    (v12 /*: any*/)
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "User",
        "abstractKey": null
    };
})();
node.hash = "7451e742e252bbdb5c03c5f4f20a34c8";
export default node;
