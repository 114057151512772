import { t } from '../../../translation/utils';
const translations = {
    en: {
        labelWebAddress: 'Web Address',
        inUse: 'In Use',
        vacant: 'Vacant',
        oops: 'Oops! Someone has already using this address.',
        hoorah: 'Hoorah! Name is vacant!',
        incorrectFormat: 'Incorrect format',
        serverError: 'Server error'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
