import Cookies from 'universal-cookie';
import { forEach, isEmpty, pathOr } from 'ramda';
import { jwtCookieName } from './jwt';
import { error, info } from './log';
export function uploadMultipleFiles(files) {
    if (!files || isEmpty(files)) {
        return Promise.reject(new Error('Please, select files'));
    }
    info(files);
    // length
    if (files.length > 10) {
        return Promise.reject(new Error('Maximum number of images: 10'));
    }
    // 20MB
    forEach((file) => {
        info(file);
        if (file.size > 20 * 1024 * 1024) {
            return Promise.reject(new Error('Maximum file size is 20MB'));
        }
        return true;
    }, files);
    // type
    forEach((file) => {
        info(file);
        if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
            return Promise.reject(new Error('Only JPEG/PNG allowed'));
        }
        return true;
    }, files);
    const cookies = new Cookies();
    const jwt = pathOr(null, ['value'], cookies.get(jwtCookieName));
    const body = new FormData();
    forEach((file) => body.append('file', file), files);
    if (!process.env.REACT_APP_STATIC_IMAGES_ENDPOINT || !jwt) {
        return Promise.reject(new Error('Seems that you are not logged in'));
    }
    return fetch(process.env.REACT_APP_STATIC_IMAGES_ENDPOINT, {
        method: 'POST',
        body,
        headers: { Authorization: `Bearer ${String(jwt)}` }
    })
        .then((response) => {
        if (!response.ok) {
            // muted because it used only for loggin
            response
                .json()
                .then(error)
                .catch(error);
            return Promise.reject(new Error('There is a problem with uploading the photo'));
        }
        return response.json();
    });
}
