import React, { PureComponent, Fragment } from 'react';
import { slice } from 'ramda';
import { Banner } from '../Banner';
import { Container, Row, Col } from '../../layout';
import './BannersRow.scss';
class BannersRow extends PureComponent {
    render() {
        const { count = 1, items } = this.props;
        const visibleBanners = slice(0, count, items);
        return (React.createElement(Container, null,
            React.createElement(Row, null, visibleBanners
                && visibleBanners.length
                && visibleBanners.map((item) => (React.createElement(Fragment, { key: item.id },
                    React.createElement(Col, { md: 12 / count },
                        React.createElement(Banner, { item: item, type: "double" }))))))));
    }
}
export default BannersRow;
