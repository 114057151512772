import { addIndex, map } from 'ramda';
import { addressToString } from '../../utils';
export const addressesToSelect = (deliveryAddresses) => addIndex(map)((item, idx) => ({
    id: item.isPriority ? '0' : `${idx + 1}`,
    label: addressToString(item.address)
}), deliveryAddresses);
export const addressFullToString = (addressFull) => {
    if (!addressFull || !addressFull.country || !addressFull.locality || !addressFull.value) {
        return 'no address';
    }
    return `${addressFull.country}, ${addressFull.locality}, ${addressFull.value}`;
};
export const calcTotal = (stores, fieldName) => {
    let total = 0;
    for (let i = 0; i < stores.length; i++) {
        total += stores[i][fieldName];
    }
    return total;
};
