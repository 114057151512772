import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { pathOr } from 'ramda';
import { PaymentInfoFiat } from '../../../Checkout/PaymentInfoFiat';
import { Page } from '../../../../components/App';
class Invoice extends PureComponent {
    componentDidMount() {
        window.scroll({ top: 0 });
    }
    render() {
        const { me } = this.props;
        const invoice = pathOr(null, ['order', 'invoice'], me);
        const currency = pathOr(null, ['order', 'currency'], me);
        if (!invoice || !invoice.id || !currency) {
            return null;
        }
        const orderSlug = pathOr(null, ['order', 'slug'], me);
        const orderState = pathOr(null, ['order', 'state'], me);
        return (React.createElement(PaymentInfoFiat, { invoice: invoice, me: me, orderSlug: orderSlug, orderState: orderState }));
    }
}
export default createFragmentContainer(Page(Invoice), {
    me: graphql `
            fragment Invoice_me on User
            @argumentDefinitions(slug: { type: "Int!", defaultValue: 0 }) {
                ...PaymentInfo_me
                email
                firstName
                lastName
                order(slug: $slug) {
                    state
                    slug
                    currency
                    invoice {
                        id
                        amount
                        currency
                        paymentIntent {
                            id
                            clientSecret
                            status
                        }
                        state
                    }
                }
            }
        `
});
