/**
 * @generated SignedSource<<04dd4bba8de9b6b529b3a4f9c3b6a33a>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "state",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [
            {
                "defaultValue": 0,
                "kind": "LocalArgument",
                "name": "slug"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "Invoice_me",
        "selections": [
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PaymentInfo_me"
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
            },
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Variable",
                        "name": "slug",
                        "variableName": "slug"
                    }
                ],
                "concreteType": "GraphQLOrder",
                "kind": "LinkedField",
                "name": "order",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "slug",
                        "storageKey": null
                    },
                    (v1 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "Invoice",
                        "kind": "LinkedField",
                        "name": "invoice",
                        "plural": false,
                        "selections": [
                            (v2 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "amount",
                                "storageKey": null
                            },
                            (v1 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "PaymentIntent",
                                "kind": "LinkedField",
                                "name": "paymentIntent",
                                "plural": false,
                                "selections": [
                                    (v2 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "clientSecret",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "status",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            },
                            (v0 /*: any*/)
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "User",
        "abstractKey": null
    };
})();
node.hash = "0cfd6ca7d365e73b88f9ea28e6a884c2";
export default node;
