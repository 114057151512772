import React, { PureComponent } from 'react';
import { Link, withRouter } from 'found';
import { Col } from '../../../../layout';
import { stringFromTimestamp } from '../../../../utils/formatDate';
import { formatPrice } from '../../../../utils';
import { getStatusStringFromEnum } from '../../OrderPage/utils';
import styles from './TableRow.module.scss';
class TableRow extends PureComponent {
    render() {
        const rowItem = this.props.item;
        return (React.createElement("div", { role: "link", className: styles.container, tabIndex: -1, onClick: () => this.props.router.push(this.props.linkFactory(rowItem)), "data-test": `orderDataTest_${rowItem.number}` },
            React.createElement("div", { className: styles.rowWrapper },
                React.createElement(Col, { size: 2, sm: 4, md: 3, lg: 2, xl: 1 }, rowItem.number),
                React.createElement(Col, { size: 5, sm: 4, md: 3, lg: 3, xl: 1 },
                    React.createElement("span", { className: styles.date }, stringFromTimestamp({
                        timestamp: rowItem.date,
                        format: 'DD MMM YYYY HH:mm'
                    })),
                    React.createElement("span", { className: styles.dateFormatted }, stringFromTimestamp({
                        timestamp: rowItem.date,
                        format: 'DD.MM.YY'
                    }))),
                React.createElement(Col, { lg: 2, xl: 2, xlVisible: true },
                    React.createElement("span", { className: styles.link }, rowItem.shop.id ? (React.createElement(Link, { to: `/store/${rowItem.shop.id}`, onClick: (e) => e.stopPropagation() }, rowItem.shop.title)) : (React.createElement("span", { className: styles.noLink }, rowItem.shop.title)))),
                React.createElement(Col, { lg: 2, xl: 1, xlVisible: true }, rowItem.delivery || '—'),
                React.createElement(Col, { size: 5, sm: 4, md: 3, lg: 2, xl: 2 },
                    React.createElement("span", { className: styles.link }, rowItem.item.id ? (rowItem.shop.id ? (React.createElement(Link, { to: `/store/${rowItem.shop.id}/products/${rowItem.item.id}`, onClick: (e) => e.stopPropagation() }, rowItem.item.title)) : (React.createElement("div", { className: styles.noLink }, rowItem.item.title))) : (React.createElement("span", { className: styles.noLink }, rowItem.item.title)))),
                React.createElement(Col, { md: 2, lg: 2, xl: 1, mdVisible: true },
                    React.createElement("span", { className: styles.price }, `${formatPrice(rowItem.totalAmount)} ${rowItem.currency}`)),
                React.createElement(Col, { md: 2, lg: 3, xl: 2, lgVisible: true }, getStatusStringFromEnum(rowItem.status)),
                React.createElement("div", { className: styles.border }))));
    }
}
export default withRouter(TableRow);
