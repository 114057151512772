/**
 * @generated SignedSource<<083c70194fcbcce6f3b059edda1786b9>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "baseProductId"
    }, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "userCountry"
    }, v2 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "baseProductId",
                    "variableName": "baseProductId"
                },
                {
                    "kind": "Variable",
                    "name": "userCountry",
                    "variableName": "userCountry"
                }
            ],
            "concreteType": "AvailableShippingForUser",
            "kind": "LinkedField",
            "name": "availableShippingForUser",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "AvailablePackageForUser",
                    "kind": "LinkedField",
                    "name": "packages",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "price",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "logo",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "companyPackageRawId",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "shippingId",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "fetchAvailableShippingForUser_Query",
            "selections": (v2 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "fetchAvailableShippingForUser_Query",
            "selections": (v2 /*: any*/)
        },
        "params": {
            "cacheID": "a3a9d4fdc7bd94178fef74d216102553",
            "id": null,
            "metadata": {},
            "name": "fetchAvailableShippingForUser_Query",
            "operationKind": "query",
            "text": "query fetchAvailableShippingForUser_Query(\n  $userCountry: String!\n  $baseProductId: Int!\n) {\n  availableShippingForUser(userCountry: $userCountry, baseProductId: $baseProductId) {\n    packages {\n      id\n      name\n      price\n      logo\n      companyPackageRawId\n      shippingId\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "642fbb7fb1b661c22906c06b1fa690cf";
export default node;
