import React from 'react';
import styles from './ProductDiscount.module.scss';
import t from './i18n';
const ProductDiscount = ({ discount }) => (React.createElement("span", { className: styles.container },
    React.createElement("span", { className: styles.price },
        t.price,
        " ",
        React.createElement("br", null),
        " ",
        t.off,
        " ",
        React.createElement("br", null)),
    React.createElement("span", { style: { fontSize: 16 } }, `− ${Math.round(discount * 100)} %`)));
export default ProductDiscount;
