import React, { Component } from 'react';
import { head, isEmpty, pathOr } from 'ramda';
import { createFragmentContainer, graphql } from 'react-relay';
import { v4 as uuidv4 } from 'uuid';
import { ContextDecorator } from '../../../../../components/App';
// import { Button } from '../../../../../components/common';
// import { Icon } from '../../../../../components/Icon';
import { CreateCustomerWithSourceMutation, UpdateCustomerMutation } from '../../../../../relay/mutations';
import { withShowAlert } from '../../../../../components/Alerts/AlertContext';
import { classNames, debug, error, fromRelayError } from '../../../../../utils';
import NewCardForm from './NewCardForm';
import styles from './Cards.module.scss';
import t from './i18n';
class Cards extends Component {
    // static getDerivedStateFromProps(nextProps: PropsType, prevState: StateType) {
    //   const { stripeCustomer } = nextProps.me;
    //   const isCards = Boolean(stripeCustomer && !isEmpty(stripeCustomer.cards));
    //   let checked = null;
    //   if (isCards) {
    //     checked = pathOr(null, ['cards', 0, 'id'], stripeCustomer);
    //     if (checked !== prevState.checked) {
    //       return { checked };
    //     }
    //   }
    //   return null;
    // }
    constructor(props) {
        super(props);
        // const { stripeCustomer } = props.me;
        // const isCards = Boolean(stripeCustomer && !isEmpty(stripeCustomer.cards));
        // let checked = null;
        // if (isCards) {
        //   checked = pathOr(null, ['cards', 0, 'id'], stripeCustomer);
        // }
        this.state = {
            // checked,
            // isNewCardForm: !isCards,
            isLoading: false
        };
    }
    // handleChange = (id: string) => {
    //   this.setState({
    //     checked: id,
    //   });
    // };
    // handleOpenNewCardForm = () => {
    //   this.setState({
    //     isNewCardForm: true,
    //   });
    // };
    //
    // handleCancelNewCardForm = () => {
    //   this.setState({
    //     isNewCardForm: false,
    //   });
    // };
    handleSaveNewCard = (token) => {
        const { stripeCustomer, email } = this.props.me;
        const isCards = Boolean(stripeCustomer && !isEmpty(stripeCustomer.cards));
        this.setState({ isLoading: true });
        const params = {
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    cardToken: token.id,
                    email
                }
            },
            environment: this.props.environment,
            onCompleted: (response, errors) => {
                this.setState({ isLoading: false });
                debug({
                    response,
                    errors
                });
                const relayErrors = fromRelayError({ source: { errors } });
                debug({ relayErrors });
                const statusError = pathOr({}, ['100', 'status'], relayErrors);
                if (!isEmpty(statusError)) {
                    this.props.showAlert({
                        type: 'danger',
                        text: `${t.error} "${statusError}"`,
                        link: { text: t.close }
                    });
                    return;
                }
                const parsingError = pathOr(null, ['300', 'message'], relayErrors);
                if (parsingError) {
                    debug('parsingError:', { parsingError });
                    this.props.showAlert({
                        type: 'danger',
                        text: t.somethingGoingWrong,
                        link: { text: t.close }
                    });
                    return;
                }
                if (errors) {
                    this.props.showAlert({
                        type: 'danger',
                        text: t.somethingGoingWrong,
                        link: { text: t.close }
                    });
                    return;
                }
                this.props.showAlert({
                    type: 'success',
                    text: 'Your card was saved',
                    link: { text: '' }
                });
                // this.setState(() => ({ isNewCardForm: false }));
            },
            onError: (err) => {
                this.setState(() => ({ isLoading: false }));
                error(err);
                this.props.showAlert({
                    type: 'danger',
                    text: t.somethingGoingWrong,
                    link: { text: t.close }
                });
            }
        };
        if (isCards) {
            UpdateCustomerMutation.commit(params);
            return;
        }
        CreateCustomerWithSourceMutation.commit(params);
    };
    handleDeleteCard = (id) => {
        debug(id);
    };
    render() {
        const { wizard, me } = this.props;
        const { firstName, lastName, email, stripeCustomer } = me;
        const { isLoading } = this.state;
        const isCards = Boolean(stripeCustomer && !isEmpty(stripeCustomer.cards));
        const card = isCards ? head(stripeCustomer.cards) : null;
        const productsCount = pathOr(0, ['myStore', 'productsCount'], me);
        return (React.createElement("div", { className: classNames(styles, 'container', { wizard }) },
            React.createElement("div", { className: styles.cards },
                React.createElement("div", { className: styles.table }, card && (React.createElement("div", { className: styles.card },
                    React.createElement("div", { className: styles.brand }, card.brand),
                    React.createElement("div", { className: styles.last4 }, `xxxxxxxxxxxx${card.last4}`),
                    React.createElement("div", { className: styles.expirationDate }, `${card.expMonth}/${card.expYear}`),
                    React.createElement("div", { className: styles.successText },
                        React.createElement("span", null, t.successText))))),
                React.createElement("div", { className: styles.block },
                    React.createElement("div", { className: classNames(styles, 'newCardForm', { wizard }) },
                        React.createElement(NewCardForm, { wizard: wizard, name: `${firstName} ${lastName}`, email: email, onSave: this.handleSaveNewCard, isCards: isCards, isLoading: isLoading })),
                    wizard !== true && (React.createElement("div", { className: styles.totalPayment },
                        React.createElement("div", { className: classNames(styles, 'productsCount', 'totalPaymentBlock') },
                            React.createElement("div", { className: styles.count },
                                React.createElement("small", null, productsCount)),
                            React.createElement("div", { className: styles.label }, t.productsPublished)),
                        React.createElement("div", { className: classNames(styles, 'monthlyPayment', 'totalPaymentBlock') },
                            React.createElement("div", { className: styles.count },
                                React.createElement("small", null, "$0.00")),
                            React.createElement("div", { className: styles.label }, t.monthlyPayment))))))));
    }
}
export default createFragmentContainer(withShowAlert(ContextDecorator(Cards)), {
    me: graphql `
			fragment Cards_me on User {
				myStore {
					productsCount
				}
				firstName
				lastName
				email
				stripeCustomer {
					id
					cards {
						id
						brand
						country
						customer
						expMonth
						expYear
						last4
						name
					}
				}
			}
    `
});
