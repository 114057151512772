import React, { Component } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { isEmpty } from 'ramda';
import setup from './setup';
import filePickerCallback from './filePickerCallback';
const menu = {
    // file: {title: 'File', items: 'newdocument'},
    edit: {
        title: 'Edit',
        items: 'undo redo | cut copy paste pastetext | selectall'
    },
    insert: {
        title: 'Insert',
        items: 'link media | template hr'
    },
    // view: {title: 'View', items: 'visualaid'},
    format: {
        title: 'Format',
        items: 'bold italic underline strikethrough superscript subscript | removeformat'
    },
    table: {
        title: 'Table',
        items: 'inserttable tableprops deletetable | cell row column'
    },
    tools: {
        title: 'Tools',
        items: 'spellchecker code'
    }
};
const initialValue = `
  <p>
    <span style="font-size: 16px; color: rgb(80, 80, 80);" data-mce-style="font-size: 16px; color: #505050;">
      Write something...
    </span>
  </p>
`;
class RichEditor extends Component {
    handleChange = (content, editor) => this.props.onChange(content);
    isContentEmpty = () => {
        const { content } = this.props;
        return isEmpty(content);
    };
    handleInit = (content, editor) => {
        if (this.isContentEmpty()) {
            this.handleChange(content, editor);
        }
    };
    render() {
        return (React.createElement(React.Fragment, null,
            React.createElement(Editor, { apiKey: "yzk01bejzy7ayg96rpubdzzpxd2s22nrpc883t6r89ykzwe8", onInit: this.handleInit, 
                // initialValue={!this.props?.content ? initialValue : this.props.content}
                onEditorChange: this.handleChange, value: !this.props?.content ? initialValue : this.props.content, init: {
                    height: 400,
                    branding: false,
                    menubar: false,
                    preview_styles: 'font-size color',
                    fontsize_formats: '10px 12px 14px 16px 18px 24px',
                    plugins: ['advlist autolink lists link image charmap print preview anchor', 'searchreplace visualblocks code fullscreen', 'insertdatetime media table paste code help wordcount'],
                    toolbar: 'undo redo | bold italic underline | styleselect | alignleft aligncenter alignright | numlist bullist | link image media | fontsizeselect forecolor | table',
                    setup,
                    file_picker_types: 'image',
                    file_picker_callback: filePickerCallback(this.props.onError),
                    content_style: 'body { font-family:Roboto,\'Helvetica Neue\',Helvetica,Arial,sans-serif; font-size:16px; color=\'#505050\' }'
                } })));
    }
}
export default RichEditor;
/*
 export default function RichEditor(props: PropsType) {
 const editorRef = useRef(null);
 const isContentEmpty = (): boolean => isEmpty(props.content);
 const handleChange = (newValue: string) => props.onChange(newValue);

 const handleInit = (evt, editor) => {
 editorRef.current = editor;
 if (isContentEmpty()) {
 handleChange(editorRef.current.getContent());
 }
 };

 return (
 <>
 <Editor
 apiKey="yzk01bejzy7ayg96rpubdzzpxd2s22nrpc883t6r89ykzwe8"
 onInit={handleInit}
 initialValue={!isContentEmpty() ? props.content : initialValue}
 onEditorChange={(newValue: string, editor) => {
 handleChange(newValue);
 }}
 init={{
 height: 400,
 branding: false,
 menubar: false,
 preview_styles: 'font-size color',
 fontsize_formats: '10px 12px 14px 16px 18px 24px',
 plugins: ['advlist autolink lists link image charmap print preview anchor', 'searchreplace visualblocks code fullscreen', 'insertdatetime media table paste code help wordcount'],
 toolbar: 'undo redo | bold italic underline | styleselect | alignleft aligncenter alignright | numlist bullist | link image media | fontsizeselect forecolor | table',
 setup,
 file_picker_types: 'image',
 file_picker_callback: filePickerCallback(props.onError),
 content_style: 'body { font-family:Roboto,\'Helvetica Neue\',Helvetica,Arial,sans-serif; font-size:16px; color=\'#505050\' }'
 }}
 />
 </>
 );
 }
 */
