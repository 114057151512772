import React, { Component, createRef } from 'react';
import styles from './StickyBar.module.scss';
const STICKY_THRESHOLD_REM = 90;
const STICKY_PADDING_TOP_REM = 2;
const STICKY_PADDING_BOTTOM_REM = 2;
class StickyBar extends Component {
    wrapperRef = createRef();
    constructor(props) {
        super(props);
        this.handleScroll = this.handleScrollEvent.bind(this);
        this.state = {
            currentClass: 'top',
            sidebarWidth: undefined
        };
    }
    componentDidMount() {
        if (!window) {
            return;
        }
        window.addEventListener('scroll', this.handleScroll);
        this.setWidth();
    }
    componentWillUnmount() {
        if (!window) {
            return;
        }
        window.removeEventListener('scroll', this.handleScroll);
        if (this.dispose) {
            this.dispose();
        }
    }
    setWidth = () => {
        const sidebarWidth = this.wrapperRef.current.getBoundingClientRect().width;
        this.setState({ sidebarWidth });
    };
    setRef(ref) {
        this.ref = ref;
    }
    dispose;
    ref;
    scrolling = false;
    handleScroll;
    updateStickiness() {
        if (!window) {
            return;
        }
        if (!this.ref || !this.wrapperRef) {
            return;
        }
        const rem = parseFloat(window.getComputedStyle(document.documentElement).fontSize);
        const offset = window.pageYOffset;
        const rect = this.ref.getBoundingClientRect();
        const height = rect.bottom - rect.top;
        const { top: viewTop, bottom: viewBottom } = this.wrapperRef.current.getBoundingClientRect();
        if (viewBottom - viewTop < STICKY_THRESHOLD_REM * rem) {
            if (this.state.currentClass !== 'top') {
                this.setState({ currentClass: 'top' });
            }
            return;
        }
        const top = viewTop + (offset - STICKY_PADDING_TOP_REM * rem);
        const bottom = viewBottom
            + (offset - (STICKY_PADDING_TOP_REM + STICKY_PADDING_BOTTOM_REM) * rem);
        let currentClass = 'top';
        if (offset >= top) {
            currentClass = 'sticky';
        }
        if (offset + height >= bottom) {
            currentClass = 'bottom';
        }
        if (this.ref.className !== currentClass) {
            this.ref.className = currentClass;
        }
    }
    handleScrollEvent() {
        if (!this.scrolling) {
            window.requestAnimationFrame(() => {
                this.updateStickiness();
                this.scrolling = false;
            });
            this.scrolling = true;
        }
    }
    render() {
        const { children } = this.props;
        const { sidebarWidth } = this.state;
        return (React.createElement("div", { className: styles.wrapper, ref: this.wrapperRef },
            React.createElement("div", { className: styles.top, ref: (ref) => this.setRef(ref), style: { width: sidebarWidth || '100%' } }, children && children)));
    }
}
export default StickyBar;
