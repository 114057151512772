import React, { Component } from 'react';
import { isEmpty, propOr } from 'ramda';
import CloseIcon from './svg/close_icon.svg';
import styles from './Alert.module.scss';
import { classNames } from '../../utils';
const titlesHashMap = {
    default: 'Information.',
    success: 'Success!',
    warning: 'Warning!',
    danger: 'Attention!'
};
class Alert extends Component {
    static defaultProps = {
        isStatic: false,
        longText: false
    };
    constructor(props) {
        super(props);
        this.state = { isDisappearing: false };
    }
    componentDidMount() {
        const { isStatic } = this.props;
        if (!isStatic) {
            this.alertTimer = setTimeout(() => {
                this.props.onClose(this.props.createdAtTimestamp);
            }, 5000);
        }
    }
    componentWillUnmount() {
        if (this.alertTimer) {
            clearTimeout(this.alertTimer);
        }
    }
    onMouseOver = () => {
        if (this.alertTimer) {
            clearTimeout(this.alertTimer);
        }
    };
    onMouseOut = () => {
        this.alertTimer = setTimeout(() => {
            this.props.onClose(this.props.createdAtTimestamp);
        }, 5000);
    };
    alertTimer; // NodeJs.Timeout;
    render() {
        const { type = 'default', text, link, createdAtTimestamp, onClick, isStatic, longText } = this.props;
        const title = propOr('Information.', type, titlesHashMap);
        return (React.createElement("div", { className: classNames(styles, 'container', {
                disappering: this.state.isDisappearing,
                default: type === 'default',
                success: type === 'success',
                danger: type === 'danger',
                warning: type === 'warning'
            }), onMouseOver: this.onMouseOver, onMouseOut: this.onMouseOut, onBlur: () => {
            }, onFocus: () => {
            } },
            !isStatic ? (React.createElement("button", { name: "alertCloseButton", onClick: () => {
                    this.props.onClose(createdAtTimestamp);
                    if (onClick) {
                        onClick();
                    }
                }, className: styles.closeButton },
                React.createElement(CloseIcon, null))) : undefined,
            !isStatic ? (React.createElement("div", { className: styles.titleContainer },
                React.createElement("div", { className: styles.title }, title))) : undefined,
            React.createElement("div", { className: classNames(styles, 'alertMessage', { longText }) }, text),
            React.createElement("div", { className: styles.link }, !isEmpty(link.text) ? (React.createElement("button", { className: styles.buttonLink, onClick: () => {
                    this.props.onClose(createdAtTimestamp);
                    if (onClick) {
                        onClick();
                    }
                } },
                React.createElement("span", { className: styles.link }, link.text))) : undefined)));
    }
}
export default Alert;
