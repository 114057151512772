import React, { cloneElement, PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { find, pathOr, propEq, reject } from 'ramda';
import { KYC, PersonalData, Security, ShippingAddresses } from './items';
import { Orders } from './items/Orders';
import { Order } from './items/Order';
import Menu from './Menu';
import { Col, Container, Row } from '../../layout';
import { AppContext, Page } from '../../components/App';
import styles from './Profile.module.scss';
import t from './i18n';
const profileMenuMap = {
    'personal-data': React.createElement(PersonalData, null),
    'shipping-addresses': React.createElement(ShippingAddresses, null),
    security: React.createElement(Security, null),
    orders: React.createElement(Orders, null),
    order: React.createElement(Order, null),
    kyc: React.createElement(KYC, null)
};
class Profile extends PureComponent {
    checkMenuItems = () => {
        const menuItems = [
            {
                id: 'personal-data',
                title: t.personalData
            },
            {
                id: 'shipping-addresses',
                title: t.shippingAddresses
            },
            {
                id: 'security',
                title: t.security
            },
            {
                id: 'orders',
                title: t.myOrders
            }
            // { id: 'kyc', title: 'KYC' },
        ];
        const { me: { provider } } = this.props;
        if (provider === 'FACEBOOK' || provider === 'GOOGLE') {
            return reject((item) => item.id === 'security', menuItems);
        }
        return menuItems;
    };
    renderProfileItem = (subtitle) => {
        const { activeItem, me, isOrder } = this.props;
        const element = pathOr(null, [isOrder ? 'order' : activeItem], profileMenuMap);
        return cloneElement(element, {
            me,
            subtitle
        });
    };
    render() {
        const { activeItem, me } = this.props;
        const { title: subtitle } = find(propEq(activeItem, 'id'), this.checkMenuItems());
        return (React.createElement(AppContext.Consumer, null, ({ environment }) => (React.createElement("div", { className: styles.container },
            React.createElement(Container, null,
                React.createElement(Row, null,
                    React.createElement(Col, { md: 3, lg: 2, xl: 2 },
                        React.createElement(Menu, { activeItem: activeItem, avatar: me.avatar, environment: environment, firstName: me.firstName || '', id: me.id, lastName: me.lastName || '', menuItems: this.checkMenuItems(), provider: me.provider || null })),
                    React.createElement(Col, { md: 9, lg: 10, xl: 10 },
                        React.createElement("div", { className: styles.content },
                            React.createElement("div", { className: styles.header },
                                React.createElement("span", { className: styles.title }, subtitle)),
                            React.createElement("div", { className: styles.form }, this.renderProfileItem(subtitle))))))))));
    }
}
export default createFragmentContainer(Page(Profile), {
    me: graphql `
			fragment Profile_me on User
			@argumentDefinitions(slug: { type: "Int!", defaultValue: 0 }) {
				...Orders_me
				...Order_me @arguments(slug: $slug)
				...PersonalData_me
				...ShippingAddresses_me
				id
				rawId
				avatar
				firstName
				lastName
				provider
			}
    `
});
