import { withRouter } from 'found';
import React, { PureComponent } from 'react';
class Terms extends PureComponent {
    render() {
        return (React.createElement(React.Fragment, null,
            React.createElement("h1", null, "Conditions of Use"),
            React.createElement("div", null,
                React.createElement("p", { className: "lead" },
                    " The website Graame.com (\"Graame.com\") is operated by Graame Private Limited (\"Graame\" or \"us\" or \"we\" or \"our\"), having its registered office located in Rudrapur, Uttarakhand, India. Please read the Conditions of Use document carefully before using the Graame.com website. By using the Graame.com website, you signify your agreement to be bound by Graame's Conditions of Use. As described in our Privacy Notice, we share information with Graame.com, Inc. and the subsidiaries that Graame.com, Pvt Ltd. controls that are either subject to our Privacy Notice or follow practices at least as protective as those described in our Privacy Notice. We also share information with third-party service providers. For example, we use third-party service providers to fulfil orders for products or services, and to deliver packages. For any further details on our security practices please read our Privacy Notice. For any queries or issues relating to Graame.com, you can contact us by clicking ",
                    React.createElement("a", { href: "https://graame.com/contact" }, "here")),
                React.createElement("p", null, " Please read these conditions carefully before using the Graame.com website. By using the Graame.com website, you signify your agreement to be bound by these conditions."),
                React.createElement("p", null, " In addition, when you use any current or future Graame.com service (eg. Wish List or Marketplace) (\"Graame Service\"), you will also be subject to the terms, guidelines and conditions applicable to that Graame Service. (\"Terms\"). If these Conditions of Use are inconsistent with such Terms, the Terms will control."),
                React.createElement("p", null, " These \"Conditions of Use\" constitute an electronic record within the meaning of the applicable law. This electronic record is generated by a computer system and does not require any physical or digital signatures."),
                React.createElement("ul", null,
                    React.createElement("li", null,
                        React.createElement("span", { className: "a-list-item" }, " Conditions Relating to Your Use of Graame.com")),
                    React.createElement("li", null,
                        React.createElement("span", { className: "a-list-item" }, " Notice and Procedure for Notifying Graame.com of Objectionable Content"))),
                React.createElement("h2", null, " Conditions Relating to Your Use of Graame.com "),
                React.createElement("p", null,
                    React.createElement("strong", null, "1. Your Account")),
                React.createElement("p", null,
                    "If you use the website, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer to prevent unauthorised access to your account. You agree to accept responsibility for all activities that occur under your account or password. You should take all necessary steps to ensure that the password is kept confidential and secure and should inform us immediately if you have any reason to believe that your password has become known to anyone else, or if the password is being, or is likely to be, used in an unauthorised manner. Please ensure that the details you provide us with are correct and complete and inform us immediately of any changes to the information that you provided when registering. You can access and update much of the information you provided us with in the ",
                    React.createElement("span", null,
                        React.createElement("a", { href: "https://graame.com/login", target: "_blank" }, "Your Account")),
                    "area of the website. You agree and acknowledge that you will use your account on the website to purchase products only for your personal use and not for business purposes. Should you wish to order products for business purposes, please create a business account on",
                    React.createElement("span", null,
                        React.createElement("a", { href: "https://graame.com/start-selling/en", target: "_blank" }, "Graame.com/start-selling/en")),
                    ". Graame.com reserves the right to refuse access to the website, terminate accounts, remove or edit content at any time without notice to you."),
                React.createElement("p", null,
                    React.createElement("strong", null, "2. Privacy")),
                React.createElement("p", null, " Please review our Privacy Notice, which also governs your visit to Graame.com, to understand our practices. The personal information / data provided to us by you during the course of usage of Graame.com will be treated as strictly confidential and in accordance with the Privacy Notice and applicable laws and regulations. If you object to your information being transferred or used, please do not use the website."),
                React.createElement("p", null,
                    React.createElement("strong", null, "3. E-Platform for Communication")),
                React.createElement("p", null, "You agree, understand and acknowledge that the website is an online platform that enables you to purchase products listed on the website at the price indicated therein at any time from any location. You further agree and acknowledge that Graame is only a facilitator and is not and cannot be a party to or control in any manner any transactions on the website. Accordingly, the contract of sale of products on the website shall be a strictly bipartite contract between you and the sellers on Graame.com."),
                React.createElement("p", null,
                    React.createElement("strong", null, "4. Access to Graame.com")),
                React.createElement("p", null, " We will do our utmost to ensure that availability of the website will be uninterrupted and that transmissions will be error-free. However, due to the nature of the Internet, this cannot be guaranteed. Also, your access to the website may also be occasionally suspended or restricted to allow for repairs, maintenance, or the introduction of new facilities or services at any time without prior notice. We will attempt to limit the frequency and duration of any such suspension or restriction."),
                React.createElement("p", null,
                    React.createElement("strong", null, "5. Licence for website access")),
                React.createElement("p", null, "Subject to your compliance with these Conditions of Use and payment of applicable fees, if any, Graame Private Limited grants you a limited licence to access and make personal use of this website, but not to download (other than page caching) or modify it, or any portion of it, except with express written consent of Graame Private Limited and / or its affiliates, as may be applicable. This licence does not include any resale or commercial use of this website or its contents; any collection and use of any product listings, descriptions, or prices; any derivative use of this website or its contents; any downloading or copying of account information for the benefit of another seller; or any use of data mining, robots, or similar data gathering and extraction tools."),
                React.createElement("p", null, " This website or any portion of this website (including but not limited to any copyrighted material, trademarks, or other proprietary information) may not be reproduced, duplicated, copied, sold, resold, visited, distributed or otherwise exploited for any commercial purpose without express written consent of Graame Private Limited and / or its affiliates, as may be applicable."),
                React.createElement("p", null, "You may not frame or use framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of Graame.com and its affiliates without express written consent. You may not use any meta tags or any other \"hidden text\" utilising Graame Private Limited's or its affiliates' names or trademarks without the express written consent of Graame Private Limited and / or its affiliates, as applicable. Any unauthorised use terminates the permission or license granted by Graame Private Limited and / or its affiliates, as applicable."),
                React.createElement("p", null, " You are granted a limited, revocable, and non-exclusive right to create a hyperlink to the Welcome page of Graame.com as long as the link does not portray Graame Private Limited, Graame.com, their affiliates, or their products or services in a false, misleading, derogatory, or otherwise offensive matter. You may not use any Graame.com logo or other proprietary graphic or trademark as part of the link without express written consent of Graame Private Limited and / or its affiliates, as may be applicable."),
                React.createElement("p", null,
                    React.createElement("strong", null, "6. Your Conduct")),
                React.createElement("p", null, "You must not use the website in any way that causes, or is likely to cause, the website or access to it to be interrupted, damaged or impaired in any way You understand that you, and not Graame.com, are responsible for all electronic communications and content sent from your computer to us and you must use the website for lawful purposes only. You must not use the website for any of the following:"),
                React.createElement("ul", null,
                    React.createElement("li", null,
                        React.createElement("span", { className: "a-list-item" }, " for fraudulent purposes, or in connection with a criminal offense or other unlawful activity ")),
                    React.createElement("li", null,
                        React.createElement("span", { className: "a-list-item" }, " to  send, use or reuse any material that does not belong to you; or is illegal, offensive (including but not limited to material that is sexually explicit content or which promotes racism, bigotry, hatred or physical harm), deceptive, misleading, abusive, indecent, insulting or harassing, blasphemous, defamatory, libelous, obscene, pornographic, paedophilic, or menacing; ethnically objectionable, disparaging or in breach of copyright, trademark, patent, confidentiality, privacy or any other proprietary information or right; or is otherwise injurious to third parties; or relates to or promotes money laundering or gambling; or is harmful to minors in any way; or impersonates another person; or threatens the unity, integrity, defence, security or sovereignty of India or friendly relations with foreign States or public order or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or is insulting other nation; or objectionable or otherwise unlawful in any manner whatsoever; or which consists of or contains software viruses or any other computer code, file or program designed to interrupt, destroy or limit the functionality of any computer resource, political campaigning, commercial solicitation, chain letters, mass mailings or any \"spam; or is patently false and untrue.")),
                    React.createElement("li", null,
                        React.createElement("span", { className: "a-list-item" }, " to cause annoyance, inconvenience or needless anxiety"))),
                React.createElement("p", null,
                    React.createElement("strong", null, "7. Reviews, comments, communications and other content")),
                React.createElement("p", null, "Users of this website may post reviews, comments and other content; send communications; and submit suggestions, ideas, comments, questions, or other information, as long as the content is not illegal, obscene, abusive, threatening, defamatory, invasive of privacy, infringing of intellectual property rights, or otherwise injurious to third parties, or objectionable and does not consist of or contain software viruses, political campaigning, commercial solicitation, chain letters, mass mailings, or any form of \"spam.\" In the event a user uses a false e-mail address, impersonates any person or entity, or otherwise misleads as to the origin of any content. Graame.com reserves the right (but not the obligation) to remove, refuse, delete or edit any content that in the sole judgement of Graame.com violates these Conditions of use and, or terminate your permission to access or use this website."),
                React.createElement("p", null, " If you do post content or submit material, and unless we indicate otherwise, you"),
                React.createElement("ol", null,
                    React.createElement("li", null,
                        React.createElement("span", { className: "a-list-item" }, " (a) grant Graame Private Limited and its affiliates a non-exclusive, royalty-free, irrevocable, perpetual and fully sublicensable rights to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content throughout the world in any media; and")),
                    React.createElement("li", null,
                        React.createElement("span", { className: "a-list-item" }, " (b) Graame Private Limited and its affiliates and sublicensees the right to use the name that you submit in connection with such content, if they choose."))),
                React.createElement("p", null,
                    "You agree that the rights you grant above are irrevocable during the entire period of protection of your intellectual property rights associated with such content and material. You agree to waive your right to be identified as the author of such ",
                    React.createElement("u", null, "content and your right to object to derogatory treatment of such content"),
                    ". You agree to perform all further acts necessary to perfect any of the above rights granted by you to Graame Private Limited, including the execution of deeds and documents, at its request."),
                React.createElement("p", null, " You represent and warrant that you own or otherwise control all of the rights to the content that you post or that you otherwise provide on or through the website; that, as at the date that the content or material is submitted to Graame.com: (i) the content and material is accurate; (ii) use of the content and material you supply does not breach any applicable Graame.com policies or guidelines and will not cause injury to any person or entity (including that the content or material is not defamatory); (iii) the content is lawful. You agree to indemnify Graame Private Limited and its affiliates for all claims brought by a third party against it or its affiliates arising out of or in connection with a breach of any of these warranties."),
                React.createElement("p", null,
                    React.createElement("strong", null, "8. Claims against Objectionable Content")),
                React.createElement("p", null, "You can refer to the product detail page on Graame.com for checking any product details provided by the seller regarding the following:"),
                React.createElement("ul", null,
                    React.createElement("li", null, "the total price in single figure of any good or service, along with the breakup price for the good or service, showing all the compulsory and voluntary charges such as delivery charges, postage and handling charges, conveyance charges and the applicable tax, as applicable. These details are available on the invoice issued to you."),
                    React.createElement("li", null, "mandatory notices and information provided by applicable laws, and the expiry date of the good being offered for sale, where applicable; ."),
                    React.createElement("li", null, "goods and services offered for sale by the seller including country of origin which are necessary for enabling the consumer to make an informed decision at the pre-purchase stage; ."),
                    React.createElement("li", null, "importer and the guarantees related to the authenticity or genuineness of the imported products; and."),
                    React.createElement("li", null,
                        "guarantees or warranties applicable to such goods or services. Further, all contractual information required to be disclosed by law is incorporated as part of the Graame Services Business Solutions Agreement (GSBSA) executed by each seller on Graame.com, prior to listing its products.A copy of the GSBSA is available ",
                        React.createElement("a", { href: "https://https://graame.com/start-selling/en" }, "here"),
                        ".",
                        React.createElement("p", null, "Because Graame.com lists millions of products for sale offered by sellers on the website and hosts many thousands of comments, it is not possible for us to be aware of the contents of each product listed for sale, or each comment or review that is displayed. Accordingly, Graame.com operates on a \"notice and takedown\" basis. If you believe that any content on the website is illegal, offensive (including but not limited to material that is sexually explicit content or which promotes racism, bigotry, hatred or physical harm), deceptive, misleading, abusive, indecent, insulting or harassing, blasphemous, defamatory, libelous, obscene, pornographic, paedophilic, invasive of another's privacy or menacing; ethnically objectionable, disparaging; or in breach of a third party's confidential, proprietary information or right; or is otherwise injurious to third parties; or relates to or promotes money laundering or gambling; or is harmful to minors in any way; or impersonates another person; or threatens the unity, integrity, defence, security or sovereignty of India or friendly relations with foreign States, or public order, or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or in insulting other nation; or objectionable or otherwise unlawful in any manner whatsoever; or which consists of or contains software viruses or any other computer code, file or program designed to interrupt, destroy or limit the functionality of any computer resource; or is patently false and untrue (\"Objectionable Content\"), please notify us immediately by following our Notice and Procedure for Making Claims of Right Infringements. Once this procedure has been followed, Graame.com will make all reasonable endeavours to remove such Objectionable Content complained about within a reasonable time."),
                        React.createElement("p", null,
                            React.createElement("strong", null, "9. Copyright, authors' rights and database rights")),
                        React.createElement("p", null, " All content included on the website, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of Graame Private Limited, its affiliates or its content suppliers and is protected by India and international copyright, authors' rights and database right laws. The compilation of all content on this website is the exclusive property of Graame Private Limited and its affiliates and is protected by laws of India and international copyright and database right laws. All software used on this website is the property of Graame Private Limited, its affiliates or its software suppliers and is protected by India and international copyright and author' rights laws."),
                        React.createElement("p", null, " You may not systematically extract/ or re-utilise parts of the contents of the website without Graame Private Limited and / or its affiliate's (as may be applicable) express written consent. In particular, you may not utilise any data mining, robots, or similar data gathering and extraction tools to extract (whether once or many times) for re-utilisation of any substantial parts of this website, without Graame Private Limited and / or its affiliate's (as may be applicable) express written consent. You may also not create and/ or publish your own database that features substantial (eg: prices and product listings) parts of this website without Graame Private Limited and / or its affiliate's (as may be applicable) express written consent."),
                        React.createElement("p", null,
                            React.createElement("strong", null, "10. Intellectual Property Claims")),
                        React.createElement("p", null, " Graame Private Limited and its affiliates respect the intellectual property of others. If you believe that your intellectual property rights have been used in a way that gives rise to concerns of infringement, please follow our Notice and Procedure for Making Claims of Right Infringements."),
                        React.createElement("p", null,
                            React.createElement("strong", null, "11. Trademarks")),
                        React.createElement("p", null, " Graame.com, GRAAME, THE GRAAME LOGO, and other marks indicated on our website are trademarks or registered trademarks of Graame.com, or its subsidiaries (collectively \"Graame\"), in the European Union and/or other jurisdictions. Graame.com's graphics, logos, page headers, button icons, scripts and service names are the trademarks or trade dress of Graame. Graame's trademarks and trade dress may not be used in connection with any product or service that is not Graame's, in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits Graame. All other trademarks not owned by Graame that appear on this website are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by Graame."),
                        React.createElement("p", null,
                            React.createElement("strong", null, " 13. Disclaimer")),
                        React.createElement("p", null, " You acknowledge and undertake that you are accessing the services on the website and transacting at your own risk and are using your best and prudent judgment before entering into any transactions through the website. You further acknowledge and undertake that you will use the website to order products only for your personal use and not for business purposes. We shall neither be liable nor responsible for any actions or inactions of sellers nor any breach of conditions, representations or warranties by the sellers or manufacturers of the products and hereby expressly disclaim and any all responsibility and liability in that regard. We shall not mediate or resolve any dispute or disagreement between you and the sellers or manufacturers of the products."),
                        React.createElement("p", null, " We further expressly disclaim any warranties or representations (express or implied) in respect of quality, suitability, accuracy, reliability, completeness, timeliness, performance, safety, merchantability, fitness for a particular purpose, or legality of the products listed or displayed or transacted or the content (including product or pricing information and/or specifications) on the website. While we have taken precautions to avoid inaccuracies in content, this website, all content, information (including the price of products), software, products, services and related graphics are provided as is, without warranty of any kind. We do not implicitly or explicitly support or endorse the sale or purchase of any products on the website. At no time shall any right, title or interest in the products sold through or displayed on the website vest with Graame nor shall Graame have any obligations or liabilities in respect of any transactions on the website."),
                        React.createElement("p", null,
                            React.createElement("strong", null, "14. Indemnity and Release")),
                        React.createElement("p", null, " You shall indemnify and hold harmless Graame Private Limited, its subsidiaries, affiliates and their respective officers, directors, agents and employees, from any claim or demand, or actions including reasonable attorney's fees, made by any third party or penalty imposed due to or arising out of your breach of these Conditions of Use or any document incorporated by reference, or your violation of any law, rules, regulations or the rights of a third party."),
                        React.createElement("p", null, "You hereby expressly release Graame Private Limited and/or its affiliates and/or any of its officers and representatives from any cost, damage, liability or other consequence of any of the actions/inactions of the vendors and specifically waiver any claims or demands that you may have in this behalf under any statute, contract or otherwise."),
                        React.createElement("p", null,
                            React.createElement("strong", null, "15. Children")),
                        React.createElement("p", null, " Use of Graame.com is available only to persons who can form a legally binding contract under the Indian Contract Act, 1872. If you are a minor i.e. under the age of 18 years, you may use Graame.com only with the involvement of a parent or guardian."),
                        React.createElement("p", null,
                            React.createElement("strong", null, "16. Other Businesses")),
                        React.createElement("p", null, " Parties other than Graame Private Limited and its affiliates may operate stores, provide services, or sell product lines on Graame.com. For example, businesses and individuals offer products via Marketplace. In addition, we provide links to the websites of affiliated companies and certain other businesses. We are not responsible for examining or evaluating, and we do not warrant or endorse the offerings of any of these businesses or individuals, or the content of their websites. Graame Private Limited does not assume any responsibility or liability for the actions, products, and content of any of these and any other third-parties. You can tell when a third-party is involved in your transactions, and we may share customer information related to those transactions with that third-party. You should carefully review their privacy statements and other conditions of use."),
                        React.createElement("p", null,
                            React.createElement("strong", null, "17. Communications")),
                        React.createElement("p", null, " When you visit Graame.com, you are communicating with us electronically. You will be required to provide a valid phone number while placing an order with us. We may communicate with you by e-mail, SMS, phone call or by posting notices on the website or by any other mode of communication. For contractual purposes, you consent to receive communications (including transactional, promotional and/or commercial messages), from us with respect to your use of the website and/or your order placed on the website."),
                        React.createElement("p", null,
                            React.createElement("strong", null, "18. Losses")),
                        React.createElement("p", null, "We will not be responsible for any business loss (including loss of profits, revenue, contracts, anticipated savings, data, goodwill or wasted expenditure) or any other indirect or consequential loss that is not reasonably foreseeable to both you and us when you commenced using the website."),
                        React.createElement("p", null,
                            React.createElement("strong", null, "19. Alteration of Service or Amendments to the Conditions")),
                        React.createElement("p", null, "We reserve the right to make changes to our website, policies, and these Conditions of Use at any time. You will be subject to the policies and Conditions of Use in force at the time that you use the website or that you order goods from us, unless any change to those policies or these conditions is required to be made by law or government authority (in which case it will apply to orders previously placed by you). If any of these conditions is deemed invalid, void, or for any reason unenforceable, that condition will be deemed severable and will not affect the validity and enforceability of any remaining condition."),
                        React.createElement("p", null,
                            React.createElement("strong", null, "20. Events beyond our reasonable control")),
                        React.createElement("p", null, " We will not be held responsible for any delay or failure to comply with our obligations under these conditions if the delay or failure arises from any cause which is beyond our reasonable control. This condition does not affect your statutory rights."),
                        React.createElement("p", null,
                            React.createElement("strong", null, " 21. Waiver")),
                        React.createElement("p", null, " If you breach these conditions and we take no action, we will still be entitled to use our rights and remedies in any other situation where you breach these conditions."),
                        React.createElement("p", null,
                            React.createElement("strong", null, "22. Governing law and Jurisdiction")),
                        React.createElement("p", null, " These conditions are governed by and construed in accordance with the laws of India. You agree, as we do, to submit to the exclusive jurisdiction of the courts at Rudrapur, Uttarakhand."),
                        React.createElement("p", null,
                            React.createElement("strong", null, "23. Our Details")),
                        React.createElement("p", null, " This website is operated by Graame Private Limited."),
                        React.createElement("p", null,
                            "For the Graame.com website, you could contact us by visiting: ",
                            React.createElement("span", null,
                                React.createElement("a", { href: "https://graame.com/contacts", target: "_blank" }, "graame.com/contacts"))),
                        React.createElement("p", null,
                            React.createElement("strong", null, " 24. Customer Complaint Details")),
                        React.createElement("p", null, "The details pertaining to each customer complaint lodged (including the status of the complaint and tracking details) are provided in the return e-mails which are sent back to the customers."),
                        React.createElement("p", null,
                            React.createElement("strong", null, "25. Graame Software Terms")),
                        React.createElement("p", null, " In addition to these Conditions of Use, the terms found here apply to any software (including any updates or upgrades to the software and any related documentation) that we make available to you from time to time for your use in connection with Graame Services (the \"Graame Software\")."),
                        React.createElement("p", null,
                            React.createElement("strong", null, "1. Use of the Graame Software")),
                        React.createElement("p", null, " You may use Graame Software solely for purposes of enabling you to use and enjoy the Graame Services as provided by Graame, and as permitted by the Conditions of Use, these Graame Software Terms and any Terms. You may not incorporate any portion of the Graame Software into your own programs or compile any portion of it in combination with your own programs, transfer it for use with another service, or sell, rent, lease, lend, loan, distribute or sub-license the Graame Software or otherwise assign any rights to the Graame Software in whole or in part. You may not use the Graame Software for any illegal purpose. We may cease providing any Graame Software and we may terminate your right to use any Graame Software at any time. Your rights to use the Graame Software will automatically terminate without notice from us if you fail to comply with any of these Graame Software Terms, the Conditions of Use or any other Terms. Additional third party terms contained within or distributed with certain Graame Software that are specifically identified in related documentation may apply to that Graame Software (or software incorporated with the Graame Software) and will govern the use of such software in the event of a conflict with these Conditions of Use. All software used in any Graame Service is the property of Graame and/or its affiliates or its software suppliers and protected by laws of India including but not limited to any other applicable copyright laws."),
                        React.createElement("p", null,
                            React.createElement("strong", null, "2. Use of Third Party Services")),
                        React.createElement("p", null, " When you use the Graame Software, you may also be using the services of one or more third parties, such as a wireless carrier or a mobile platform provider. Your use of these third party services may be subject to the separate policies, terms of use, and fees of these third parties."),
                        React.createElement("p", null,
                            React.createElement("strong", null, "3. No Reverse Engineering")),
                        React.createElement("p", null, " You may not, and you will not encourage, assist or authorize any other person to copy, modify, reverse engineer, decompile or disassemble, or otherwise tamper with, the Graame Software, whether in whole or in part, or create any derivative works from or of the Graame Software."),
                        React.createElement("p", null,
                            React.createElement("strong", null, "Updates")),
                        React.createElement("p", null, " In order to keep the Graame Software up-to-date, we may offer automatic or manual updates at any time and without notice to you."),
                        React.createElement("h2", null, " Conditions of Sale (between Sellers and the Customer)"),
                        React.createElement("p", null,
                            React.createElement("strong", null, "Please read these conditions carefully before placing an order for any products with the Sellers (\"We\" or \"Our\" or \"Us\", wherever applicable) on the Graame.com (the website). These conditions signify your agreement to be bound by these conditions.")),
                        React.createElement("p", null, "In addition, when you use any current or future Graame.com service (eg: Wishlist or Marketplace), you will also be subject to the terms, guidelines and conditions applicable to that service (\"Terms\"). If these Conditions of Sale are inconsistent with such Terms, the Terms will control."),
                        React.createElement("p", null,
                            React.createElement("strong", null, "1. Conditions Relating to the Sale of Products to You")),
                        React.createElement("p", null, " This section deals with conditions relating to the sale of products on the website by us to you."),
                        React.createElement("p", null,
                            React.createElement("strong", null, "2. Our Contract")),
                        React.createElement("p", null, " Your order is an offer to us to buy the product(s) in your order. When you place an order to purchase a product from us, you will receive an e-mail confirming receipt of your order and containing the details of your order (the \"Order Confirmation E-mail\"). The Order Confirmation E-mail is acknowledgement that we have received your order, and does not confirm acceptance of your offer to buy the product(s) ordered. We only accept your offer, and conclude the contract of sale for a product ordered by you, when the product is dispatched to you and an e-mail confirmation is sent to you that the product has been dispatched to you (the \"Dispatch Confirmation E-mail\"). If your order is dispatched in more than one package, you may receive a separate Dispatch Confirmation E-mail for each package, and each Dispatch Confirmation E-mail and corresponding dispatch will conclude a separate contract of sale between you and us for the product(s) specified in that Dispatch Confirmation E-mail."),
                        React.createElement("p", null, " Your contract is with us (the Sellers) and you confirm that the product(s) ordered by you are purchased for your internal / personal purpose and not for re-sale or business purpose. You authorize us to declare and provide declaration to any governmental authority on your behalf stating the aforesaid purpose of the products ordered by you on the website."),
                        React.createElement("p", null, "You can cancel your order for a product at no cost any time before we send the Dispatch Confirmation E-mail relating to that product."),
                        React.createElement("p", null, " Please note that we sell products only in quantities which correspond to the typical needs of an average household. This applies both to the number of products ordered within a single order and the placing of several orders for the same product where the individual orders comprise a quantity typical for a normal household."),
                        React.createElement("p", null,
                            React.createElement("strong", null, "3. Returns")),
                        React.createElement("p", null, " Most items purchased from sellers listed on Graame.com are returnable within the return window, except those that are explicitly identified as not returnable. The return is processed only if:")),
                    React.createElement("li", null,
                        React.createElement("span", { className: "a-list-item" }, "it is determined that the product was not damaged while in your possession; ")),
                    React.createElement("li", null,
                        React.createElement("span", { className: "a-list-item" }, "the product is not different from what was shipped to you;")),
                    React.createElement("li", null,
                        React.createElement("span", { className: "a-list-item" }, "the product is returned in original condition (with brand's/manufacturer's box, MRP tag intact, user manual, warranty card and accessories)")),
                    React.createElement("p", null, " You can review return policy for products listed on Graame.com. For the products that are returned by the customer, the refund is issued to the original payment method (in case of pre-paid transactions) or to the bank account / as Graame Pay balance (in case of Pay on Delivery orders), the details for making such refund and the timelines are detailed in the refund policy available."),
                    React.createElement("p", null, " Please review our Returns Policy, which applies to products sold by us."),
                    React.createElement("p", null,
                        React.createElement("strong", null, "4. Pricing and availability")),
                    React.createElement("p", null, "We list availability information for products sold by us on the website, including on each product information page. Beyond what we say on that page or otherwise on the website, we cannot be more specific about availability. Please note that dispatch estimates are just that. They are not guaranteed dispatch times and should not be relied upon as such. As we process your order, you will be informed by e-mail if any products you order turn out to be unavailable."),
                    React.createElement("p", null, " All prices are inclusive of VAT/CST, service tax, Goods and Services Tax (\"GST\"), duties and cesses as applicable - unless stated otherwise."),
                    React.createElement("p", null,
                        React.createElement("strong", null, "5. Taxes")),
                    React.createElement("p", null, " You shall be responsible for payment of all fees/costs/charges associated with the purchase of products from us and you agree to bear any and all applicable taxes including but not limited to VAT/CST, service tax, GST, duties and cesses etc."),
                    React.createElement("p", null,
                        React.createElement("strong", null, "6. Health & Safety")),
                    React.createElement("p", null, " By purchasing any product from our Home & Kitchen shop, you acknowledge that you have read and understood our Health & Safety Guidelines."),
                    React.createElement("p", null,
                        React.createElement("strong", null, "7. Children")),
                    React.createElement("p", null, " Use of Graame.com is available only to persons who can form a legally binding contract under the Indian Contract Act, 1872. If you are a minor i.e. under the age of 18 years, you may purchase only with the involvement of a parent or guardian."),
                    React.createElement("p", null,
                        React.createElement("strong", null, "8. Communications")),
                    React.createElement("p", null, " When you visit Graame.com, you are communicating with us electronically. You will be required to provide a valid phone number while placing an order with us. We may communicate with you by e-mail, SMS, phone call or by posting notices on the website or by any other mode of communication. For contractual purposes, you consent to receive communications including SMS, e-mails or phone calls from us with respect to your order."),
                    React.createElement("p", null,
                        React.createElement("strong", null, "9. Losses")),
                    React.createElement("p", null, " We will not be responsible for any business loss (including loss of profits, revenue, contracts, anticipated savings, data, goodwill or wasted expenditure) or any other indirect or consequential loss that is not reasonably foreseeable to both you and us when a contract for the sale of goods by us to you was formed."),
                    React.createElement("p", null,
                        React.createElement("strong", null, "10. Alteration or Amendments to the Conditions")),
                    React.createElement("p", null, " We reserve the right to make changes to our policies, and these Conditions of Sale at any time. You will be subject to the policies and Conditions of Sale in force at the time you order goods from us, unless any change to those policies or these conditions is required to be made by law or government authority (in which case it will apply to orders previously placed by you). If any of these conditions is deemed invalid, void, or for any reason unenforceable, that condition will be deemed severable and will not affect the validity and enforceability of any remaining condition."),
                    React.createElement("p", null,
                        React.createElement("strong", null, "11. Events beyond our reasonable control")),
                    React.createElement("p", null, " We will not be held responsible for any delay or failure to comply with our obligations under these conditions if the delay or failure arises from any cause which is beyond our reasonable control. This condition does not affect your statutory rights."),
                    React.createElement("p", null,
                        React.createElement("strong", null, "12. Waiver")),
                    React.createElement("p", null, " If you breach these conditions and we take no action, we will still be entitled to use our rights and remedies in any other situation where you breach these conditions."),
                    React.createElement("p", null,
                        React.createElement("strong", null, "13. Governing Law and Jurisdiction")),
                    React.createElement("p", null, " These conditions are governed by and construed in accordance with the laws of India, and the application of the United Nations Convention on Contracts for the International Sale of Goods is expressly excluded. You agree, as we do, to submit to the exclusive jurisdiction of the courts at Rudrapur, Uttarakhand.")))));
    }
}
export default withRouter(Terms);
