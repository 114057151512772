import { t } from '../../translation/utils';
const translations = {
    en: {
        shop: 'Shop',
        items: 'Items',
        reviews: 'Reviews',
        actions: 'Actions',
        about: 'About',
        storeNotFound: 'Store Not Found'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
