import React, { Component } from 'react';
import { length, map, take } from 'ramda';
import { withRouter } from 'found';
import MediaQuery from 'react-responsive';
import CardProduct from '../../components/CardProduct/CardProduct';
import { Button } from '../../components/common';
import styles from './Goods.module.scss';
import { classNames } from '../../utils';
class Goods extends Component {
    constructor(props) {
        super(props);
        this.state = { loadedPagesCount: 1 };
    }
    loadMore = () => {
        this.setState((prevState) => ({ loadedPagesCount: prevState.loadedPagesCount < 3 ? prevState.loadedPagesCount + 1 : 0 }));
    };
    renderGoods = (count) => {
        const { items, seeAllUrl } = this.props;
        const { loadedPagesCount } = this.state;
        return (React.createElement("div", { className: styles.goods },
            React.createElement("div", { className: classNames(styles, 'section view') }, map((good) => (React.createElement("div", { key: good.rawId, className: styles.good, style: { width: `${100 * (2 / count)}%` } },
                React.createElement(CardProduct, { item: good }))), take(count * loadedPagesCount, this.props.items))),
            React.createElement("div", { className: styles.loadMoreButton },
                loadedPagesCount < 3
                    && loadedPagesCount < length(items) / count
                    && length(items) > count && (React.createElement(Button, { big: true, wireframe: true, load: true, onClick: this.loadMore }, "Load more")),
                seeAllUrl
                    && (loadedPagesCount > length(items) / count
                        || loadedPagesCount === 3) && (React.createElement(Button, { big: true, wireframe: true, load: true, onClick: () => {
                        this.props.router.push(this.props.seeAllUrl);
                    } }, "See all")))));
    };
    render() {
        const { items, title } = this.props;
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.header },
                React.createElement("div", { className: styles.title }, title)),
            React.createElement(MediaQuery, { maxWidth: 575 },
                React.createElement("div", { className: styles.nowrapGoods }, map((item) => (React.createElement("div", { key: item.rawId, className: styles.good },
                    React.createElement(CardProduct, { item: item }))), items))),
            React.createElement(MediaQuery, { maxWidth: 767, minWidth: 576 }, this.renderGoods(4)),
            React.createElement(MediaQuery, { maxWidth: 991, minWidth: 768 }, this.renderGoods(6)),
            React.createElement(MediaQuery, { maxWidth: 1199, minWidth: 992 }, this.renderGoods(8)),
            React.createElement(MediaQuery, { minWidth: 1200 }, this.renderGoods(10))));
    }
}
export default withRouter(Goods);
