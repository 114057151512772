import { t } from '../../../translation/utils';
const translations = {
    en: {
        error: 'Error',
        yourPaymentWasFailed: 'Your payment was failed :(',
        payment: 'Payment',
        someDescription: 'Some description',
        success: 'Success',
        yourPaymentWasSuccessfullyCompleted: 'Your payment was successfully completed.',
        alreadyPaid: 'Already paid',
        myOrders: 'My orders'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
