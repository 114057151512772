import moment from 'moment';
export const stringFromTimestamp = (input) => {
    // date object from timestamp
    const dateObj = moment.utc(input.timestamp);
    return dateObj.local().format(input.format || 'DD-MM-YYYY HH:mm');
};
// return `17:44`
export const timeFromTimestamp = (timestamp) => stringFromTimestamp({ timestamp, format: 'HH:mm' });
// return `18 Jan 1970`
export const shortDateFromTimestamp = (timestamp) => stringFromTimestamp({ timestamp, format: 'DD MMM YYYY' });
// return `18 January 1970`
export const fullDateFromTimestamp = (timestamp) => stringFromTimestamp({ timestamp, format: 'DD MMMM YYYY' });
