import React, { Component, Fragment } from 'react';
import ImageLoader from '../../libs/react-image-loader';
import { Banner } from '../Banner';
import BannerLoading from '../Banner/BannerLoading';
import styles from './Slider.module.scss';
import CardProduct from '../CardProduct/CardProduct';
import SliderContainer from './SliderContainer';
class Slider extends Component {
    constructor(props) {
        super(props);
        this.state = { dotIdx: 0 };
    }
    getValueDotIdx = (dotIdx) => {
        this.setState({ dotIdx });
    };
    render() {
        const { items, type, fade } = this.props;
        const { dotIdx } = this.state;
        const fadeData = fade ? { getValueDotIdx: this.getValueDotIdx, dotIdx } : {};
        return (React.createElement("div", { className: styles.container },
            React.createElement(SliderContainer, { ...this.props, ...fadeData }, items.map((item) => (React.createElement(Fragment, { key: item.rawId || item.id },
                type === 'products' && React.createElement(CardProduct, { item: item }),
                type === 'banners' && React.createElement(Banner, { item: item, type: "single" }),
                type === 'image' && (React.createElement(ImageLoader, { fit: true, src: item.img, loader: (React.createElement("div", { style: {
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0
                        } },
                        React.createElement(BannerLoading, null))) }))))))));
    }
}
export default Slider;
