import React, { PureComponent } from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import QRCode from 'qrcode.react';
import { map, pathOr } from 'ramda';
import { Link, withRouter } from 'found';
import { classNames, formatPrice } from '../../../utils';
import { RecalcInvoiceAmountMutation } from '../../../relay/mutations';
import { NotificationBlock } from '../../../components/NotificationBlock';
import styles from './PaymentInfo.module.scss';
import t from './i18n';
class PaymentInfo extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            // priceReservedDueDateTime: null,
            // timerValue: '',
            isFirstRefetch: true,
            isNotificationActive: true
        };
    }
    componentDidMount() {
        this.unmounted = false;
        // this.updateCountdown();
        this.refetchInvoice();
    }
    componentWillUnmount() {
        this.unmounted = true;
    }
    unmounted = true;
    refetchInvoice = () => {
        if (this.unmounted || !this.props.invoiceId || this.props.invoiceId === '') {
            return;
        }
        const state = pathOr(null, ['invoice', 'state'], this.props.me);
        if (state === 'AMOUNT_EXPIRED') {
            this.recalculateAmount(this.refetchInvoice);
            return;
        }
        this.props.relay.refetch({ id: this.props.invoiceId }, null, () => {
            setTimeout(() => {
                this.refetchInvoice();
            }, 5000);
            this.setState({ isFirstRefetch: false });
        }, { force: true });
    };
    recalculateAmount = (callback) => {
        const variables = { id: this.props.invoiceId };
        const params = {
            variables,
            environment: this.props.relay.environment,
            onCompleted: (response, errors) => {
                callback(!!response);
            },
            onError: (error) => {
                callback(false);
            }
        };
        RecalcInvoiceAmountMutation.commit(params);
    };
    // updateCountdown = () => {
    //   const { priceReservedDueDateTime } = this.state;
    //
    //   if (this.unmounted) {
    //     return;
    //   }
    //
    //   if (!priceReservedDueDateTime) {
    //     this.setState({ timerValue: '-' }, () => {
    //       setTimeout(this.updateCountdown, 1000);
    //     });
    //     return;
    //   }
    //
    //   const diff = moment(priceReservedDueDateTime)
    //     .utc()
    //     .diff(moment().utc(), 's');
    //   if (!diff || diff < 0) {
    //     this.setState({ timerValue: '-' }, () => {
    //       setTimeout(this.updateCountdown, 1000);
    //     });
    //     return;
    //   }
    //
    //   const minutes = parseInt(diff / 60, 10); // JS, I hate you
    //   const seconds = diff - minutes * 60;
    //   this.setState(
    //     { timerValue: `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}` },
    //     () => {
    //       setTimeout(this.updateCountdown, 1000);
    //     },
    //   );
    // };
    stateToString = (state) => {
        switch (state) {
            case 'TRANSACTION_PENDING':
                return 'In process';
            case 'PAYMENT_AWAITED':
                return 'Payment awaited';
            default:
                return '';
        }
    };
    handleHideNotification = () => {
        this.setState({ isNotificationActive: false });
    };
    renderLinks = () => (React.createElement("div", { className: styles.links },
        React.createElement("div", null,
            "You can pay with",
            ' ',
            React.createElement("a", { href: "https://turewallet.com", target: "_blank", rel: "noopener noreferrer" }, "Graame Wallet")),
        React.createElement("div", null,
            "Don\u2019t you have one yet?",
            ' ',
            React.createElement("a", { href: process.env.REACT_APP_WALLET_APPLE_URL, target: "_blank", rel: "noopener noreferrer" }, "Download"))));
    render() {
        const { orderState } = this.props;
        const { isFirstRefetch, isNotificationActive } = this.state;
        if (isFirstRefetch) {
            return (React.createElement("div", { className: styles.container },
                React.createElement("div", { className: styles.title }, "Payment"),
                React.createElement("div", { className: styles.info },
                    React.createElement("div", { className: styles.loader })),
                React.createElement("div", { className: styles.separator }),
                this.renderLinks()));
        }
        const invoice = pathOr(null, ['me', 'invoice'], this.props);
        if (!invoice || (orderState && orderState === 'AMOUNT_EXPIRED')) {
            return (React.createElement("div", { className: styles.container, "data-test": "PAYMENT_INFO_FAILED" },
                React.createElement("div", null,
                    React.createElement("div", { className: styles.title }, "Error"),
                    React.createElement("div", { className: styles.description }, "Your payment was failed :(")),
                React.createElement("div", { className: styles.separator }),
                this.renderLinks()));
        }
        let wallet;
        let amount;
        let amountCaptured;
        let transactions;
        let state;
        if (invoice) {
            ({
                wallet,
                amount,
                transactions,
                amountCaptured
            } = invoice);
            ({ state } = invoice);
        }
        const dataTest = (state === 'NEW' || state === 'PAYMENT_AWAITED' || state === 'PAID') ? state : '';
        return (React.createElement("div", { className: styles.container, "data-test": `PAYMENT_INFO_${dataTest}` },
            state !== 'PAID' && React.createElement("div", { className: styles.title }, "Payment"),
            React.createElement("div", { className: styles.info },
                (state === 'NEW' || state === 'AMOUNT_EXPIRED') && (React.createElement("div", { className: styles.loader })),
                wallet
                    && amount
                    && (state === 'TRANSACTION_PENDING'
                        || state === 'PAYMENT_AWAITED') && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: styles.paymentInfoWrapper },
                        React.createElement("div", { className: styles.qr },
                            React.createElement(QRCode, { value: `${invoice.currency === 'BTC' ? 'bitcoin' : 'ethereum'}:${wallet}?amount=${amount}`, renderAs: "svg", size: 165 })),
                        React.createElement("div", { className: styles.paymentInfo },
                            React.createElement("div", { className: styles.addressTitle }, "Address"),
                            React.createElement("div", { className: styles.address, "data-test": "walletNumberField" }, wallet),
                            React.createElement("div", { className: styles.amountTitle }, "Amount"),
                            React.createElement("div", { className: styles.amount },
                                React.createElement("strong", null, `${formatPrice(amount)} ${invoice.currency}`)),
                            React.createElement("div", { className: styles.amountTitle }, "Amount captured"),
                            React.createElement("div", { className: styles.amount }, `${formatPrice(amountCaptured)} ${invoice.currency}`))),
                    React.createElement("div", { className: styles.transactionsBlock },
                        React.createElement("div", { className: styles.statusBlock },
                            React.createElement("div", { className: styles.statusTitle }, "Payment status"),
                            React.createElement("div", { className: classNames(styles, 'statusValue', {
                                    statusInProcess: state === 'TRANSACTION_PENDING',
                                    statusPaymentAwaited: state === 'PAYMENT_AWAITED'
                                }) },
                                this.stateToString(state),
                                React.createElement("div", { className: styles.loaderSmall }))),
                        transactions
                            && transactions.length > 0 && (React.createElement("div", { className: styles.transactions },
                            React.createElement("div", { className: styles.row },
                                React.createElement("div", { className: styles.transactionsTitleTx }, "Transaction ID"),
                                React.createElement("div", { className: styles.transactionsTitleAmount }, "Amount")),
                            map((item) => (React.createElement("div", { key: item.id, className: styles.row },
                                React.createElement("div", { className: styles.rowTx },
                                    React.createElement("a", { href: `https://etherscan.io/tx/${item.id}`, target: "_blank", rel: "noreferrer" }, item.id)),
                                React.createElement("div", { className: styles.rowAmount },
                                    item.amount,
                                    " INR"))), transactions)))),
                    React.createElement("div", { className: styles.reserveInfo }, "The order must be paid in three days after creation."))),
                state === 'PAID' && (React.createElement("div", null,
                    React.createElement("div", { className: styles.title }, "Success"),
                    React.createElement("div", { className: styles.description }, "Your payment was successfully completed."),
                    React.createElement("div", { className: styles.ordersLinkWrap },
                        React.createElement(Link, { to: "/profile/orders", className: styles.ordersLink }, t.myOrders))))),
            React.createElement("div", { className: styles.separator }),
            this.renderLinks(),
            isNotificationActive ? (React.createElement("div", { className: styles.notification },
                React.createElement(NotificationBlock, { type: "warning", title: t.attention, longText: true, text: t.exchangeNotification, link: { text: t.ok }, onClick: this.handleHideNotification, hideCloseButton: true }))) : null));
    }
}
export default createRefetchContainer(withRouter(PaymentInfo), {
    me: graphql `
			fragment PaymentInfo_me on User
			@argumentDefinitions(id: { type: "String!", defaultValue: "" }) {
				invoice(id: $id) {
					id
					amount
					amountCaptured
					priceReservedDueDateTime
					state
					transactions {
						id
						amount
					}
					currency
				}
			}
    `
}, graphql `
		query PaymentInfo_Query($id: String!) {
			me {
				...PaymentInfo_me @arguments(id: $id)
			}
		}
  `);
