import React, { Component } from 'react';
import { isEmpty, map, pathOr } from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import { debug, fromRelayError } from '../../../../../../utils';
import { DeactivateProductMutation } from '../../../../../../relay/mutations';
import { Confirmation } from '../../../../../../components/Confirmation';
import Header from './Header';
import Row from './Row';
import styles from './Variants.module.scss';
import t from './i18n';
class Variants extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            dataToDelete: null
        };
    }
    deleteVariant = (id) => {
        const { environment, productId } = this.props;
        if (!productId || !id) {
            this.props.showAlert({
                type: 'danger',
                text: t.somethingWentWrong,
                link: { text: t.close }
            });
        }
        const params = {
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    id
                }
            },
            parentID: productId,
            environment,
            onCompleted: (response, errors) => {
                debug({
                    response,
                    errors
                });
                const relayErrors = fromRelayError({ source: { errors } });
                debug({ relayErrors });
                const statusError = pathOr({}, ['100', 'status'], relayErrors);
                if (!isEmpty(statusError)) {
                    this.props.showAlert({
                        type: 'danger',
                        text: `${t.error} "${statusError}"`,
                        link: { text: t.close }
                    });
                    return;
                }
                this.handleCloseModal();
                this.props.showAlert({
                    type: 'success',
                    text: t.variantDeleted,
                    link: { text: '' }
                });
            },
            onError: (error) => {
                debug({ error });
                this.props.showAlert({
                    type: 'danger',
                    text: t.somethingWentWrong,
                    link: { text: t.close }
                });
            }
        };
        DeactivateProductMutation.commit(params);
    };
    expandClick = (id) => {
        this.props.onExpandClick(id);
    };
    handleDelete = () => {
        const { dataToDelete } = this.state;
        this.deleteVariant(dataToDelete);
    };
    handleDeleteModal = (id) => {
        this.setState({
            showModal: true,
            dataToDelete: id
        });
    };
    handleCloseModal = () => {
        this.setState({
            showModal: false,
            dataToDelete: null
        });
    };
    render() {
        const { variants, onCopyVariant, currency } = this.props;
        const { showModal } = this.state;
        return (React.createElement("div", { className: styles.container },
            React.createElement(Header, { onSelectAllClick: () => { } }),
            React.createElement("div", null, map((item) => (React.createElement(Row, { key: item.rawId, variant: item, currency: currency, handleDeleteVariant: this.handleDeleteModal, onExpandClick: this.expandClick, onCopyVariant: onCopyVariant })), variants)),
            React.createElement(Confirmation, { showModal: showModal, onClose: this.handleCloseModal, title: t.deleteVariant, description: t.confirmationDescription, onCancel: this.handleCloseModal, onConfirm: this.handleDelete, confirmText: t.confirmText, cancelText: t.cancelText })));
    }
}
export default Variants;
