import React, { Component } from 'react';
import { map, find, propEq, isNil } from 'ramda';
import { Select } from '../Select';
import styles from './BirthdateSelect.module.scss';
import t from './i18n';
class BirthdateSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            years: this.generateYears(100, 1919),
            months: this.generateMonths(12, 1),
            days: this.generateDays(31, 1),
            yearValue: undefined,
            monthValue: undefined,
            dayValue: undefined
        };
    }
    UNSAFE_componentWillMount() {
        const { birthdate } = this.props;
        if (!isNil(birthdate)) {
            const date = new Date(birthdate);
            const { years, months, days } = this.state;
            const yearValue = `${date.getFullYear()}`;
            const monthValue = `${date.getMonth() + 1}`;
            const dayValue = `${date.getDate()}`;
            const yearSelectValue = find(propEq(yearValue, 'id'))(years);
            const monthSelectValue = find(propEq(monthValue.length === 1 ? `0${monthValue}` : monthValue, 'id'))(months);
            const daySelectValue = find(propEq(dayValue, 'id'))(days);
            this.setState({
                yearValue: yearSelectValue,
                monthValue: monthSelectValue,
                dayValue: daySelectValue
            });
        }
    }
    generateYears = (count, start) => {
        const items = [
            ...Array.from(Array(count)
                .keys(), (x) => count - 1 + start - x)
        ];
        return map((item) => ({
            id: `${item}`,
            label: `${item}`
        }), items);
    };
    generateMonths = (count, start) => {
        const monthNames = [
            t.january,
            t.february,
            t.march,
            t.april,
            t.may,
            t.june,
            t.july,
            t.august,
            t.september,
            t.october,
            t.november,
            t.december
        ];
        const briefMonthNames = [
            t.jan,
            t.feb,
            t.mar,
            t.apr,
            t.mayS,
            t.jun,
            t.jul,
            t.aug,
            t.sep,
            t.oct,
            t.nov,
            t.dec
        ];
        const items = [...Array.from(Array(count)
                .keys(), (x) => start + x)];
        const { brief } = this.props;
        return map((item) => ({
            id: `${item}`.length === 1 ? `0${item}` : `${item}`,
            label: !isNil(brief) ? briefMonthNames[item - 1] : monthNames[item - 1]
        }), items);
    };
    generateDays = (count, start) => {
        const items = [...Array.from(Array(count)
                .keys(), (x) => start + x)];
        return map((item) => ({
            id: `${item}`,
            label: `${item}`.length === 1 ? `0${item}` : `${item}`
        }), items);
    };
    handleSelect = (value, id) => {
        const { yearValue, monthValue, dayValue } = this.state;
        const year = yearValue ? yearValue.label : null;
        const month = monthValue ? monthValue.id : null;
        const day = dayValue ? dayValue.label : null;
        const checkDayMonth = (monthV, dayV) => {
            const bool1 = (monthV === '04'
                || monthV === '06'
                || monthV === '09'
                || monthV === '11')
                && dayV === '31';
            const bool2 = monthV === '02'
                && ((Number(year) % 4 !== 0
                    && (dayV === '29' || dayV === '30' || dayV === '31'))
                    || (Number(year) % 4 === 0 && (dayV === '30' || dayV === '31')));
            return bool1 || bool2;
        };
        if (id === 'year'
            && Number(value.label) % 4 !== 0
            && month === '02'
            && day === '29') {
            this.setState(() => ({ dayValue: null }), this.checkDate);
        }
        else if (id === 'month' && checkDayMonth(value.id, day)) {
            this.setState(() => ({ dayValue: null }), this.checkDate);
        }
        else if (id === 'day' && checkDayMonth(month, value.label)) {
            this.setState(() => ({ monthValue: null }), this.checkDate);
        }
        this.setState(() => ({ [`${id}Value`]: value }), this.checkDate);
    };
    checkDate = () => {
        const { yearValue, monthValue, dayValue } = this.state;
        if (yearValue && monthValue && dayValue) {
            this.props.handleBirthdateSelect(`${yearValue.label}-${monthValue.id}-${dayValue.label}`);
        }
    };
    renderSelect = (id, label) => (React.createElement("div", { className: styles.item },
        React.createElement(Select, { isBirthdate: true, forForm: true, label: label, activeItem: this.state[`${id}Value`], items: this.state[`${id}s`], onSelect: (value) => this.handleSelect(value, id), dataTest: `${this.props.dataTest}-${id}` })));
    render() {
        const { errors, label } = this.props;
        return (React.createElement("div", { className: styles.container },
            !isNil(label) && React.createElement("div", { className: styles.label }, label),
            React.createElement("div", { className: styles.items },
                this.renderSelect('year', t.year),
                this.renderSelect('month', t.month),
                this.renderSelect('day', t.day)),
            !isNil(errors)
                && errors.length && (React.createElement("div", { className: styles.errors }, map(((item, idx) => (React.createElement("div", { key: idx }, item))), errors)))));
    }
}
export default BirthdateSelect;
