import React, { PureComponent } from 'react';
import { filter, map, propEq } from 'ramda';
import CharacteristicItem from './CharacteristicItem';
import styles from './Characteristics.module.scss';
class Characteristics extends PureComponent {
    handleItemChange = (attribute) => {
        const { attrId, value, metaField } = attribute;
        const filteredItems = filter((item) => item.attrId !== attrId, this.props.values);
        filteredItems.push({
            attrId,
            value,
            metaField
        });
        this.props.onChange(filteredItems);
    };
    render() {
        const { errors, customAttributes } = this.props;
        return (React.createElement("div", { id: "attributes", className: styles.container },
            React.createElement("div", { className: styles.items }, map((item) => (React.createElement(CharacteristicItem, { key: item.id, attribute: item, onSelect: this.handleItemChange, value: filter(propEq(item.rawId, 'attrId'), this.props.values)[0] })), customAttributes)),
            React.createElement("div", { className: styles.errors }, errors
                && map((item) => (React.createElement("div", { key: item, className: styles.error }, item)), errors))));
    }
}
export default Characteristics;
