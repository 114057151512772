/**
 * @generated SignedSource<<0b27a260b7b3bf518a68cab030cd370c>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "concreteType": "Translation",
        "kind": "LinkedField",
        "name": "name",
        "plural": true,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lang",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawId",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [
            {
                "defaultValue": "",
                "kind": "LocalArgument",
                "name": "autocompleteValue"
            },
            {
                "defaultValue": 1,
                "kind": "LocalArgument",
                "name": "currentPage"
            },
            {
                "defaultValue": 10,
                "kind": "LocalArgument",
                "name": "itemsCount"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "searchTerm"
            },
            {
                "defaultValue": "",
                "kind": "LocalArgument",
                "name": "storageSlug"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "StorageProducts_me",
        "selections": [
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Variable",
                        "name": "slug",
                        "variableName": "storageSlug"
                    }
                ],
                "concreteType": "GraphQLWarehouse",
                "kind": "LinkedField",
                "name": "warehouse",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": [
                            {
                                "kind": "Literal",
                                "name": "first",
                                "value": 8
                            },
                            {
                                "kind": "Variable",
                                "name": "name",
                                "variableName": "autocompleteValue"
                            }
                        ],
                        "concreteType": "FullNameConnection",
                        "kind": "LinkedField",
                        "name": "autoCompleteProductName",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "FullNameEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "node",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": [
                            {
                                "kind": "Variable",
                                "name": "currentPage",
                                "variableName": "currentPage"
                            },
                            {
                                "kind": "Variable",
                                "name": "itemsCount",
                                "variableName": "itemsCount"
                            },
                            {
                                "kind": "Variable",
                                "name": "searchTerm",
                                "variableName": "searchTerm"
                            },
                            {
                                "kind": "Literal",
                                "name": "visibility",
                                "value": "active"
                            }
                        ],
                        "concreteType": "StocksConnection",
                        "kind": "LinkedField",
                        "name": "products",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "StocksEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "GraphQLStock",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "productId",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Product",
                                                "kind": "LinkedField",
                                                "name": "product",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "BaseProduct",
                                                        "kind": "LinkedField",
                                                        "name": "baseProduct",
                                                        "plural": false,
                                                        "selections": [
                                                            (v1 /*: any*/),
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "concreteType": "Category",
                                                                "kind": "LinkedField",
                                                                "name": "category",
                                                                "plural": false,
                                                                "selections": [
                                                                    (v1 /*: any*/)
                                                                ],
                                                                "storageKey": null
                                                            }
                                                        ],
                                                        "storageKey": null
                                                    },
                                                    (v0 /*: any*/),
                                                    (v2 /*: any*/),
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "price",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "photoMain",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "currency",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "ProdAttrValue",
                                                        "kind": "LinkedField",
                                                        "name": "attributes",
                                                        "plural": true,
                                                        "selections": [
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "kind": "ScalarField",
                                                                "name": "attrId",
                                                                "storageKey": null
                                                            },
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "concreteType": "Attribute",
                                                                "kind": "LinkedField",
                                                                "name": "attribute",
                                                                "plural": false,
                                                                "selections": [
                                                                    (v0 /*: any*/),
                                                                    (v2 /*: any*/),
                                                                    (v1 /*: any*/)
                                                                ],
                                                                "storageKey": null
                                                            },
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "kind": "ScalarField",
                                                                "name": "value",
                                                                "storageKey": null
                                                            }
                                                        ],
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "quantity",
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "PageInfoWarehouseProductSearch",
                                "kind": "LinkedField",
                                "name": "pageInfo",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "totalPages",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "currentPage",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "pageItemsCount",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "User",
        "abstractKey": null
    };
})();
node.hash = "852b3bbb01fa6f4d48ac475bb242a9b4";
export default node;
