import React, { Component, Fragment } from 'react';
import { assoc, assocPath, map, pathOr } from 'ramda';
import { Environment } from 'relay-runtime';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay';
import { v4 as uuidv4 } from 'uuid';
import { classNames, debug, error, fromRelayError } from '../../../../utils';
import { CreateUserDeliveryAddressFullMutation, DeleteUserDeliveryAddressFullMutation, UpdateUserDeliveryAddressFullMutation } from '../../../../relay/mutations';
import { AddressForm } from '../../../../components/AddressAutocomplete';
import { Checkbox } from '../../../../components/common/Checkbox';
import { Button } from '../../../../components/common/Button';
import { withShowAlert } from '../../../../components/Alerts/AlertContext';
import styles from '../../Profile.module.scss';
import t from './i18n';
const resetForm = {
    administrativeAreaLevel1: '',
    administrativeAreaLevel2: '',
    country: '',
    countryCode: '',
    locality: '',
    political: '',
    postalCode: '',
    route: '',
    streetNumber: '',
    value: '',
    isPriority: true
};
class ShippingAddresses extends Component {
    static contextTypes = { environment: PropTypes.instanceOf(Environment).isRequired };
    constructor(props) {
        super(props);
        this.state = {
            form: resetForm,
            isLoading: false,
            isOpenNewForm: false,
            editableAddressId: null
        };
    }
    handleSave = (id) => {
        const { environment } = this.context;
        const { me } = this.props;
        const { form } = this.state;
        const { country, countryCode, administrativeAreaLevel1, administrativeAreaLevel2, political, postalCode, streetNumber, value, route, locality, isPriority } = form;
        const availabilityErrors = {};
        if (!country || !postalCode || !countryCode) {
            if (!country) {
                assoc('country', t.countryIsRequiredParameter, availabilityErrors);
            }
            if (!postalCode) {
                assoc('postalCode', t.postalCodeIsRequiredParameter, availabilityErrors);
            }
            this.props.showAlert({
                type: 'danger',
                text: t.countryAndPostalCodeIsRequiredParameter,
                link: { text: '' }
            });
            return;
        }
        this.setState(() => ({ isLoading: true }));
        const input = {
            clientMutationId: uuidv4(),
            addressFull: {
                country,
                countryCode,
                administrativeAreaLevel1: administrativeAreaLevel1 || null,
                administrativeAreaLevel2: administrativeAreaLevel2 || null,
                political: political || null,
                postalCode,
                streetNumber: streetNumber || null,
                value: value || null,
                route: route || null,
                locality: locality || null
            },
            isPriority
        };
        const createInput = {
            ...input,
            userId: me.rawId
        };
        const updateInput = {
            ...input,
            id
        };
        const params = {
            variables: {
                input: id ? updateInput : createInput
            },
            environment,
            onCompleted: (response, errors) => {
                this.setState(() => ({ isLoading: false }));
                debug({
                    response,
                    errors
                });
                const relayErrors = fromRelayError({ source: { errors } });
                debug({ relayErrors });
                const validationErrors = pathOr({}, ['100', 'messages'], relayErrors);
                debug({ validationErrors });
                const status = pathOr('', ['100', 'status'], relayErrors);
                if (status) {
                    this.props.showAlert({
                        type: 'danger',
                        text: `${t.error} "${status}"`,
                        link: { text: t.close }
                    });
                    return;
                }
                if (errors) {
                    this.props.showAlert({
                        type: 'danger',
                        text: t.somethingWentWrong,
                        link: { text: t.close }
                    });
                    return;
                }
                this.resetForm();
                this.setState(() => ({
                    isLoading: false,
                    isOpenNewForm: false,
                    editableAddressId: null
                }));
                this.props.showAlert({
                    type: 'success',
                    text: id ? 'Address updated!' : 'Address created!',
                    link: { text: '' }
                });
                this.resetForm();
            },
            onError: (err) => {
                this.setState(() => ({ isLoading: false }));
                error(err);
                this.props.showAlert({
                    type: 'danger',
                    text: t.somethingWentWrong,
                    link: { text: t.close }
                });
            }
        };
        if (id) {
            UpdateUserDeliveryAddressFullMutation.commit(params);
        }
        else {
            CreateUserDeliveryAddressFullMutation.commit(params);
        }
    };
    handleDelete = (id) => {
        const { environment } = this.context;
        this.setState(() => ({ isLoading: true }));
        const params = {
            id,
            environment,
            onCompleted: (response, errors) => {
                debug({
                    response,
                    errors
                });
                this.setState(() => ({
                    isLoading: false,
                    editableAddressId: null
                }));
                const relayErrors = fromRelayError({ source: { errors } });
                debug({ relayErrors });
                const validationErrors = pathOr({}, ['100', 'messages'], relayErrors);
                debug({ validationErrors });
                const status = pathOr('', ['100', 'status'], relayErrors);
                if (status) {
                    this.props.showAlert({
                        type: 'danger',
                        text: `${t.error} "${status}"`,
                        link: { text: t.close }
                    });
                    return;
                }
                if (errors) {
                    this.props.showAlert({
                        type: 'danger',
                        text: t.somethingWentWrong,
                        link: { text: t.close }
                    });
                    return;
                }
                this.resetForm();
                this.props.showAlert({
                    type: 'success',
                    text: t.addressDeleted,
                    link: { text: '' }
                });
            },
            onError: (err) => {
                debug({ err });
                const relayErrors = fromRelayError(err);
                debug({ relayErrors });
                this.props.showAlert({
                    type: 'danger',
                    text: t.somethingWentWrong,
                    link: { text: t.close }
                });
            }
        };
        DeleteUserDeliveryAddressFullMutation.commit(params);
    };
    handleUpdateForm = (form) => {
        this.setState((preState) => ({
            form: {
                ...preState.form,
                ...form
            }
        }));
    };
    handleCheckboxClick = () => {
        this.setState((prevState) => assocPath(['form', 'isPriority'], !prevState.form.isPriority, prevState));
    };
    toggleEditAddressForm = (id, data) => {
        if (data) {
            const { address, isPriority } = data;
            this.handleUpdateForm({
                ...address,
                isPriority
            });
        }
        this.setState((prevState) => ({
            editableAddressId: id || null,
            isOpenNewForm: id ? false : prevState.isOpenNewForm
        }));
    };
    toggleNewAddressForm = () => {
        this.resetForm();
        this.setState((prevState) => ({
            editableAddressId: prevState.isOpenNewForm ? prevState.editableAddressId : null,
            isOpenNewForm: !prevState.isOpenNewForm
        }));
    };
    resetForm = () => {
        this.setState({ form: resetForm });
    };
    renderAddressForm = () => {
        const { form, isOpenNewForm, editableAddressId, isLoading } = this.state;
        return (React.createElement("div", { className: styles.addressForm },
            React.createElement(AddressForm, { isOpen: true, onChangeData: this.handleUpdateForm, country: form.country, address: form.value, addressFull: form }),
            React.createElement("div", { className: styles.priorityCheckbox },
                React.createElement(Checkbox, { id: "priority", isChecked: form.isPriority, onChange: this.handleCheckboxClick, label: "Priority address", dataTest: "priorityAddressCheckbox" })),
            React.createElement("div", { className: styles.saveButtons },
                React.createElement(Button, { big: true, wireframe: !editableAddressId, onClick: () => {
                        this.handleSave(editableAddressId || null);
                    }, isLoading: isLoading, dataTest: "saveShippingAddressButton" }, editableAddressId ? t.save : t.add),
                (editableAddressId || isOpenNewForm) && (React.createElement("button", { className: styles.cancelButton, onClick: (isOpenNewForm ? this.toggleNewAddressForm : this.toggleEditAddressForm), "data-test": "cancelShippingAddressButton" }, t.cancel)))));
    };
    render() {
        const { me } = this.props;
        const { editableAddressId, isOpenNewForm } = this.state;
        const { deliveryAddressesFull = [] } = me;
        return (React.createElement("div", { className: styles.shippingAddresses },
            deliveryAddressesFull.length === 0 && (React.createElement("div", { className: styles.subtitle },
                React.createElement("strong", null, t.shippingAddress))),
            deliveryAddressesFull.length > 0 && (React.createElement("div", { className: styles.addButton },
                React.createElement(Button, { disabled: isOpenNewForm, wireframe: true, big: true, onClick: this.toggleNewAddressForm, dataTest: "addShippingAddressButton" }, t.addAddress))),
            (deliveryAddressesFull.length === 0 || isOpenNewForm)
                && this.renderAddressForm(),
            deliveryAddressesFull.length > 0 && (React.createElement("div", { className: classNames(styles, 'addressesWrap', { isOpenNewForm }) },
                React.createElement("div", { className: styles.subtitle },
                    React.createElement("strong", null, t.savedAddresses)),
                React.createElement("div", { className: styles.addresses }, map((item) => {
                    const { country, streetNumber, locality, route, postalCode } = item.address;
                    return (React.createElement(Fragment, { key: item.rawId },
                        React.createElement("div", { className: styles.item },
                            item.isPriority && (React.createElement("div", { className: styles.priorityText }, t.priorityAddress)),
                            React.createElement("div", { className: styles.address },
                                `${country}, `,
                                locality && `${locality}`,
                                (streetNumber || route) && React.createElement("br", null),
                                streetNumber && `${streetNumber}, `,
                                route && `${route}`,
                                React.createElement("br", null),
                                postalCode && `${postalCode}`),
                            React.createElement("div", { className: styles.name }, `${me.firstName} ${me.lastName}`),
                            React.createElement("div", { className: styles.email },
                                React.createElement("i", null, me.email)),
                            React.createElement("div", { className: styles.editButtons },
                                React.createElement(Button, { wireframe: true, big: true, onClick: () => {
                                        this.toggleEditAddressForm(item.rawId, item);
                                    }, dataTest: "editShippingAddressButton" }, t.edit),
                                React.createElement("div", { className: styles.deleteButton },
                                    React.createElement(Button, { big: true, pink: true, wireframe: true, onClick: () => {
                                            this.handleDelete(item.rawId);
                                        }, dataTest: "deleteShippingAddressButton" }, t.delete)))),
                        editableAddressId === item.rawId && (React.createElement("div", { className: styles.editAddressForm }, this.renderAddressForm()))));
                }, deliveryAddressesFull)),
                deliveryAddressesFull.length > 0 && (React.createElement("div", { className: styles.addButtonMobile },
                    React.createElement(Button, { disabled: isOpenNewForm, wireframe: true, big: true, onClick: this.toggleNewAddressForm, dataTest: "addShippingAddressButton" }, t.addAddress)))))));
    }
}
export default createFragmentContainer(withShowAlert(ShippingAddresses), {
    me: graphql `
			fragment ShippingAddresses_me on User {
				rawId
				firstName
				lastName
				email
				deliveryAddressesFull {
					rawId
					id
					userId
					isPriority
					address {
						country
						countryCode
						administrativeAreaLevel1
						administrativeAreaLevel2
						political
						postalCode
						streetNumber
						value
						route
						locality
					}
				}
			}
    `
});
