import { commitMutation, graphql } from 'react-relay';
const mutation = graphql `
	mutation CreateCustomerWithSourceMutation(
		$input: CreateCustomerWithSourceInput!
	) {
		createCustomerWithSource(input: $input) {
			id
			cards {
				id
				brand
				country
				customer
				expMonth
				expYear
				last4
				name
			}
		}
	}
`;
const commit = (params) => commitMutation(params.environment, {
    mutation,
    variables: params.variables,
    onCompleted: params.onCompleted,
    onError: params.onError,
    updater: (relayStore) => {
        const createCustomerWithSource = relayStore.getRootField('createCustomerWithSource');
        const me = relayStore.getRoot()
            .getLinkedRecord('me');
        me.setLinkedRecord(createCustomerWithSource, 'stripeCustomer');
    }
});
export default { commit };
