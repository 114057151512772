import React, { Component } from 'react';
import { assoc, assocPath, omit, path } from 'ramda';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { Stripe } from '../../../../../common/StripeDecorator';
import { Button, Input } from '../../../../../../components/common';
import { classNames, debug, error } from '../../../../../../utils';
import styles from './NewCardForm.module.scss';
const cardElementOptional = {
    style: {
        base: {
            fontSize: '14px',
            color: '#505050',
            letterSpacing: '1px',
            fontFamily: 'Roboto',
            '::placeholder': { color: '#D3D2D3' }
        },
        invalid: { color: '#E62C6D' }
    }
};
class NewCardForm extends Component {
    constructor(props) {
        super(props);
        const { name, email } = props;
        this.state = {
            ownerName: name,
            ownerEmail: email,
            completeCard: false,
            isFocus: false,
            errors: {},
            isLoading: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        this.mounted = true;
    }
    componentWillUnmount() {
        this.mounted = false;
    }
    mounted = false;
    cardElement;
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ isLoading: true });
        const { ownerName: name, ownerEmail: email } = this.state;
        debug(name, email);
        this.props.stripe
            .createToken({ name })
            .then((payload) => {
            this.props.onSave(payload.token);
            this.cardElement.clear();
            return true;
        })
            .finally(() => {
            if (this.mounted) {
                this.setState({ isLoading: false });
            }
        })
            .catch(error);
    };
    handleBlur = () => {
        this.setState({ isFocus: false });
    };
    handleChange = (change) => {
        this.setState((prevState) => ({ errors: omit(['card'], prevState.errors) }));
        if (change.error) {
            this.setState((prevState) => ({ errors: assoc('card', change.error.message, prevState.errors) }));
        }
        this.setState({ completeCard: change.complete });
    };
    handleClick = () => {
    };
    handleFocus = () => {
        this.setState({ isFocus: true });
    };
    handleReady = (element) => {
        if (element) {
            this.cardElement = element;
        }
    };
    handleInputChange = (id) => (e) => {
        this.setState((prevState) => ({ errors: omit([id], prevState.errors) }));
        const { value } = e.target;
        this.setState((prevState) => assocPath([id], value, prevState));
    };
    isDisabledButton = () => {
        const { ownerName, ownerEmail, completeCard } = this.state;
        return ownerName === '' || ownerEmail === '' || !completeCard;
    };
    renderInput = (props) => {
        const { id, label, required } = props;
        const hereLabel = required ? (React.createElement("span", null,
            label,
            React.createElement("span", { className: styles.asterisk }, "*"))) : (label);
        const value = path([id], this.state);
        const errors = path(['errors', id], this.state);
        return (React.createElement(Input, { id: id, value: value || '', label: hereLabel, onChange: this.handleInputChange(id), errors: errors, fullWidth: true }));
    };
    render() {
        const { wizard } = this.props;
        const { isFocus, isLoading, errors } = this.state;
        return (React.createElement("form", { onSubmit: this.handleSubmit, className: classNames(styles, 'container', {
                isFocus,
                isError: errors.card,
                wizard
            }) },
            React.createElement("label", null,
                React.createElement("div", { className: styles.label },
                    "Card details ",
                    React.createElement("span", { className: styles.asterisk }, "*")),
                React.createElement("div", { className: classNames(styles, 'cardElement', { isFocus }) },
                    React.createElement(CardElement, { onBlur: this.handleBlur, onChange: this.handleChange, onFocus: this.handleFocus, onReady: this.handleReady, ...cardElementOptional }),
                    React.createElement("hr", null),
                    errors.card && React.createElement("div", { className: styles.cardError }, errors.card))),
            React.createElement("div", { className: styles.input }, this.renderInput({
                id: 'ownerName',
                label: 'Name',
                required: true
            })),
            React.createElement("div", { className: styles.footer },
                React.createElement("div", { className: styles.button },
                    React.createElement(Button, { type: "submit", fullWidth: true, dataTest: "payButton", isLoading: isLoading || this.props.isLoading, disabled: this.isDisabledButton() }, "Save")))));
    }
}
export default Stripe(injectStripe(NewCardForm));
