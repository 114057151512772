/**
 * @generated SignedSource<<11fcf924f167cb187f6ea4f06aa445ed>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "JWT",
            "kind": "LinkedField",
            "name": "getJwtbyEmail",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "token",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "GetJwtbyEmailMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "GetJwtbyEmailMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "ff4d3ff7f0c067e11b3560639b981f49",
            "id": null,
            "metadata": {},
            "name": "GetJwtbyEmailMutation",
            "operationKind": "mutation",
            "text": "mutation GetJwtbyEmailMutation(\n  $input: CreateJWTEmailInput!\n) {\n  getJwtbyEmail(input: $input) {\n    token\n  }\n}\n"
        }
    };
})();
node.hash = "50d5d884df57528955d959598faef643";
export default node;
