/**
 * @generated SignedSource<<7733159c66a1c66b34b44ec97a1abbb8>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "updateUser",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phone",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UpdateUserPhoneMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UpdateUserPhoneMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "7362a84a2a311e4182422bbbc51483fa",
            "id": null,
            "metadata": {},
            "name": "UpdateUserPhoneMutation",
            "operationKind": "mutation",
            "text": "mutation UpdateUserPhoneMutation(\n  $input: UpdateUserInput!\n) {\n  updateUser(input: $input) {\n    id\n    phone\n  }\n}\n"
        }
    };
})();
node.hash = "e1267848f8c741df203b681fbe7c9cf4";
export default node;
