import { commitMutation, graphql } from 'react-relay';
import { filter } from 'ramda';
const mutation = graphql `
	mutation DeleteWarehouseMutation($id: String!) {
		deleteWarehouse(id: $id) {
			id
		}
	}
`;
const commit = (params) => commitMutation(params.environment, {
    mutation,
    variables: params.variables,
    onCompleted: params.onCompleted,
    onError: params.onError,
    updater: (relayStore) => {
        const storageId = relayStore
            .getRootField('deleteWarehouse')
            .getValue('id');
        relayStore.delete(storageId);
        const me = relayStore.getRoot()
            .getLinkedRecord('me');
        const myStore = me.getLinkedRecord('myStore');
        const storages = myStore.getLinkedRecords('warehouses');
        const newStorages = filter((storage) => storage !== null, storages);
        myStore.setLinkedRecords(newStorages, 'warehouses');
    }
});
export default { commit };
