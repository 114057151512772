import React, { Component } from 'react';
import { difference, find, head, isEmpty, map, propEq } from 'ramda';
import { classNames, getNameText } from '../../../../../../utils';
import { Icon } from '../../../../../../components/Icon';
import { Select } from '../../../../../../components/common';
import styles from './AdditionalAttributes.module.scss';
import t from './i18n';
class Index extends Component {
    constructor(props) {
        super(props);
        const { attributes } = props;
        const selectableAttributes = this.updateAttributes(attributes);
        const activeAttribute = head(selectableAttributes);
        this.state = {
            selectableAttributes,
            activeAttribute
        };
    }
    componentDidUpdate(prevProps) {
        const { attributes, customAttributes } = this.props;
        const { activeAttribute } = this.state;
        if (JSON.stringify(prevProps.attributes) !== JSON.stringify(attributes)) {
            const selectableAttributes = this.updateAttributes(attributes);
            this.updateState(selectableAttributes, head(selectableAttributes));
        }
        if (JSON.stringify(prevProps.customAttributes)
            !== JSON.stringify(customAttributes)) {
            const selectableAttributes = this.updateAttributes(difference(attributes, customAttributes));
            this.updateState(selectableAttributes, find(propEq(activeAttribute?.id, 'id'))(selectableAttributes)
                ? activeAttribute
                : head(selectableAttributes));
        }
    }
    updateAttributes = (attributes) => map((item) => ({
        id: item.id,
        label: getNameText(item.name, 'EN')
    }), attributes);
    updateState = (selectableAttributes, activeAttribute) => {
        this.setState({
            selectableAttributes,
            activeAttribute
        });
    };
    handleOnSelect = (activeAttribute) => {
        this.setState({ activeAttribute });
    };
    handleCreateAttribute = () => {
        const { attributes } = this.props;
        const { activeAttribute } = this.state;
        if (activeAttribute) {
            const attribute = find(propEq(activeAttribute.id, 'id'))(attributes);
            if (attribute) {
                this.props.onCreateAttribute(attribute);
            }
        }
    };
    handleRemoveAttribute = (id) => {
        this.props.onRemoveAttribute(id);
    };
    render() {
        const { customAttributes, onlyView } = this.props;
        const { selectableAttributes, activeAttribute } = this.state;
        return (React.createElement("div", { className: classNames(styles, 'container', { onlyViewContainer: onlyView }) },
            !onlyView && (React.createElement("div", null,
                React.createElement("div", { className: classNames(styles, 'select', { hidePlane: isEmpty(selectableAttributes) }) },
                    React.createElement(Select, { forForm: true, label: t.additionalCharacteristics, activeItem: activeAttribute, items: selectableAttributes, onSelect: this.handleOnSelect, dataTest: "customAttributtesSelect", fullWidth: true })),
                activeAttribute && (React.createElement("button", { className: styles.button, onClick: this.handleCreateAttribute, "data-test": "customAttributtesAddButton" },
                    React.createElement("div", { className: styles.buttonIcon },
                        React.createElement(Icon, { inline: true, type: "plus", size: 20 })),
                    React.createElement("div", { className: styles.buttonText }, t.add))))),
            onlyView && (React.createElement("div", { className: styles.onlyViewTitle }, t.additionalCharacteristics)),
            React.createElement("div", { className: styles.customAttributes }, map((item) => (React.createElement("div", { key: item.id, className: classNames(styles, 'attribute', { onlyViewAttribute: onlyView }) },
                React.createElement("div", { className: styles.label }, getNameText(item.name, 'EN')),
                !onlyView && (React.createElement("button", { className: styles.cross, onClick: () => {
                        this.handleRemoveAttribute(item.id);
                    }, "data-test": "customAttributtesRemoveButton" },
                    React.createElement(Icon, { inline: true, type: "cross", size: 12 }))))), customAttributes))));
    }
}
export default Index;
