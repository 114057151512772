import React from 'react';
import styles from './StepLabel.module.scss';
import { classNames } from '../../../utils';
const StepLabel = ({ step, text, currentStep, isReadyToNext, onChangeStep }) => (React.createElement("div", { className: classNames(styles, 'item', { active: currentStep === step }), onClick: () => {
        if (isReadyToNext || step < currentStep) {
            onChangeStep(step);
        }
    }, onKeyDown: () => {
    }, role: "button", tabIndex: 0 },
    React.createElement("span", null, text)));
export default StepLabel;
