import React, { Children, cloneElement, Component } from 'react';
import { AppContext } from '../App';
import { convertCountries } from '../../utils';
class GoogleAPIWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isGoogleMapsApiScriptLoaded: false,
            isGoogleMapsApiScriptLoading: false
        };
    }
    componentDidMount() {
        this.fetchGoogleApi();
    }
    autocompleteService;
    geocoderService;
    fetchGoogleApi = async () => {
        if (google) {
            this.autocompleteService = new google.maps.places.AutocompleteService();
            this.geocoderService = new google.maps.Geocoder();
            const isGoogleMapsApiScriptLoaded = !!this.autocompleteService && !!this.geocoderService;
            const isGoogleMapsApiScriptLoading = !this.autocompleteService || !this.geocoderService;
            this.setState({
                isGoogleMapsApiScriptLoaded,
                isGoogleMapsApiScriptLoading
            });
        }
    };
    render() {
        const { children } = this.props;
        const { isGoogleMapsApiScriptLoading, isGoogleMapsApiScriptLoaded } = this.state;
        if ((isGoogleMapsApiScriptLoading && !isGoogleMapsApiScriptLoaded) ||
            (isGoogleMapsApiScriptLoading && !isGoogleMapsApiScriptLoaded)) {
            return React.createElement("div", null, "loading");
        }
        else if (isGoogleMapsApiScriptLoaded) {
            return Children.map(children, (child) => cloneElement(child, {
                autocompleteService: this.autocompleteService,
                geocoderService: this.geocoderService
            }));
        }
        return null;
    }
}
const wrapper = (WrappedComponent) => (props) => (React.createElement(AppContext.Consumer, null, ({ directories }) => (React.createElement(GoogleAPIWrapper, null,
    React.createElement(WrappedComponent, { ...props, countries: convertCountries(directories.countries) })))));
export default wrapper;
