import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay';
import { v4 as uuidv4 } from 'uuid';
import { assoc, dissoc, find, forEach, has, head, ifElse, isEmpty, isNil, pathOr, propEq } from 'ramda';
import { Environment } from 'relay-runtime';
import MediaQuery from 'react-responsive';
import smoothscroll from '../../../libs/smoothscroll';
import { withErrorBoundary } from '../../../components/common/ErrorBoundaries';
import { Col, Row } from '../../../layout';
import { AddInCartMutation } from '../../../relay/mutations';
import { convertCountries, debug, error, extractText, sanitizeHTML } from '../../../utils';
import { addToCartTracker, productViewTracker } from '../../../rrHalper';
import { withShowAlert } from '../../../components/Alerts/AlertContext';
import { AppContext, Page } from '../../../components/App';
import RRElement from './RRElement';
import { attributesFromVariants, availableAttributesFromVariants, filterVariantsByAttributes, isNoSelected, makeWidgets, sortByProp } from './utils';
import styles from './Product.module.scss';
import t from './i18n';
import ProductContext from './ProductContext';
import ProductBreadcrumbs from './ProductBreadcrumbs';
import ProductImage from './ProductImage';
import ProductDetails from './ProductDetails';
import ProductButtons from './ProductButtons';
import ProductStore from './ProductStore';
import Tabs from './Tabs';
import Tab from './Tab';
class Product extends Component {
    static contextTypes = { environment: PropTypes.instanceOf(Environment).isRequired };
    constructor(props) {
        super(props);
        this.state = this.getDefaultState();
    }
    componentDidMount() {
        const { productVariant, widgets } = this.state;
        const attributes = productVariant ? productVariant.attributes : [];
        if (productVariant) {
            const storeId = pathOr('', ['match', 'params', 'storeId'], this.props);
            const productId = pathOr('', ['match', 'params', 'productId'], this.props);
            this.props.router.replace(`/store/${storeId}/products/${productId}/variant/${productVariant.rawId}`);
            this.setProductViewTracker(productVariant.rawId);
        }
        const selectedAttributes = {};
        forEach((attr) => {
            const { value, attribute } = attr;
            const result = find(propEq(attribute.id, 'id'))(widgets);
            if (!isNil(result)) {
                selectedAttributes[attribute.id] = value;
            }
        }, attributes);
        this.setState({ selectedAttributes });
    }
    componentDidUpdate(prevProps, prevState) {
        const { productVariant } = this.state;
        const storeId = pathOr('', ['match', 'params', 'storeId'], this.props);
        const productId = pathOr('', ['match', 'params', 'productId'], this.props);
        if (productVariant
            && prevState.productVariant
            && productVariant.rawId !== prevState.productVariant.rawId) {
            this.props.router.replace(`/store/${storeId}/products/${productId}/variant/${productVariant.rawId}`);
            this.setProductViewTracker(productVariant.rawId);
        }
        const prevProductId = pathOr('', ['match', 'params', 'productId'], prevProps);
        if (productId !== prevProductId) {
            this.updateState(this.getDefaultState());
        }
    }
    getDefaultState = () => {
        const { baseProduct } = this.props;
        const variants = baseProduct ? baseProduct.variants.all : [];
        const variantId = pathOr('', ['match', 'params', 'variantId'], this.props);
        let productVariant = !isEmpty(variants) ? head(variants) : null;
        if (variantId) {
            const matchProductVariant = find(propEq(parseInt(variantId, 10), 'rawId'))(variants);
            if (matchProductVariant) {
                productVariant = matchProductVariant;
            }
        }
        return {
            widgets: makeWidgets(variants),
            productVariant,
            unselectedAttr: null,
            selectedAttributes: {},
            availableAttributes: attributesFromVariants(variants),
            isAddToCart: false,
            isLoading: false,
            isLoadingAddToCart: false,
            cartQuantity: 1,
            deliveryData: {
                deliveryPackage: null,
                country: null,
                deliveryPackages: []
            }
        };
    };
    setProductViewTracker = (id) => {
        if (process.env.BROWSER && process.env.REACT_APP_RRPARTNERID && id) {
            productViewTracker(id);
        }
    };
    getUserAddress = () => {
        const { me } = this.props;
        let userAddress = null;
        if (me) {
            const { deliveryAddressesFull } = me;
            if (deliveryAddressesFull && !isEmpty(deliveryAddressesFull)) {
                userAddress = find(propEq(true, 'isPriority'))(deliveryAddressesFull)
                    || head(deliveryAddressesFull);
            }
        }
        return userAddress;
    };
    updateState = (state) => {
        this.setState(state);
    };
    handleChangeQuantity = (quantity) => {
        this.setState({ cartQuantity: quantity });
    };
    handleChangeDeliveryData = (deliveryData) => {
        this.setState({ deliveryData });
    };
    handleAddToCart = (id) => {
        this.setState({ unselectedAttr: null });
        const { widgets, selectedAttributes, cartQuantity, deliveryData } = this.state;
        const unselectedAttr = isNoSelected(sortByProp('id')(widgets), selectedAttributes);
        if (isEmpty(widgets) || !unselectedAttr) {
            const shippingId = deliveryData.deliveryPackage
                ? deliveryData.deliveryPackage.shippingId
                : null;
            this.setState({ isLoadingAddToCart: true });
            const variables = {
                input: {
                    clientMutationId: uuidv4(),
                    productId: id,
                    value: cartQuantity,
                    shippingId
                }
            };
            AddInCartMutation.commit({
                variables,
                environment: this.context.environment,
                onCompleted: (response, errors) => {
                    debug('Success for IncrementInCart mutation');
                    if (response) {
                        debug('Response: ', response);
                    }
                    if (errors) {
                        debug('Errors: ', errors);
                    }
                    if (!errors && response) {
                        this.props.showAlert({
                            type: 'success',
                            text: t.productAddedToCart,
                            link: { text: '' }
                        });
                        this.setState({ isAddToCart: true });
                        this.setState({ isLoadingAddToCart: false });
                    }
                },
                onError: (err) => {
                    this.setState({ isLoadingAddToCart: false });
                    error('Error in IncrementInCart mutation');
                    error(err);
                    this.props.showAlert({
                        type: 'danger',
                        text: t.unableToAddProductToCart,
                        link: { text: t.close }
                    });
                }
            });
        }
        else {
            this.setState({ unselectedAttr });
            if (!isEmpty(unselectedAttr) && head(unselectedAttr)) {
                smoothscroll.scrollTo(head(unselectedAttr));
            }
        }
    };
    handleWidget = (item) => {
        const { selectedAttributes: prevSelectedAttributes } = this.state;
        const selectedAttributes = ifElse(propEq(item.attributeValue, item.attributeId), dissoc(item.attributeId), assoc(item.attributeValue, item.attributeId))(prevSelectedAttributes);
        const { baseProduct: { variants: { all: variants } } } = this.props;
        const matchedVariants = filterVariantsByAttributes(selectedAttributes, variants);
        if (isEmpty(matchedVariants)) {
            return;
        }
        const availableAttributes = availableAttributesFromVariants(selectedAttributes, variants);
        this.setState({
            selectedAttributes,
            availableAttributes,
            productVariant: head(matchedVariants),
            isAddToCart: false
        });
    };
    makeTabs = (longDescription) => {
        const modifLongDescription = extractText(longDescription, 'EN', t.noLongDescription)
            .replace(/\n/g, '<hr />');
        const html = sanitizeHTML(modifLongDescription);
        const tabs = [
            {
                id: '0',
                label: 'Description',
                content: (React.createElement("div", { className: styles.longDescription, dangerouslySetInnerHTML: { __html: html } }))
            }
        ];
        return (React.createElement(Tabs, null, tabs.map((tt) => (React.createElement(Tab, { key: tt.id, label: tt.label }, tt.content)))));
    };
    handleBuyNow = () => {
        const { widgets, selectedAttributes, cartQuantity, productVariant, deliveryData } = this.state;
        if (!productVariant) {
            return;
        }
        this.setState({ unselectedAttr: null });
        const unselectedAttr = isNoSelected(sortByProp('id')(widgets), selectedAttributes);
        let quantity = cartQuantity;
        if (productVariant.quantity === 0
            && productVariant.preOrder
            && productVariant.preOrderDays > 0) {
            quantity = 1;
        }
        if (isEmpty(widgets) || !unselectedAttr) {
            const userAddress = this.getUserAddress();
            const userCountryCode = pathOr(null, ['address', 'countryCode'], userAddress);
            const deliveryCountryCode = pathOr(null, ['country', 'id'], deliveryData);
            const isDeliveryQuery = Boolean(userCountryCode
                && deliveryCountryCode
                && userCountryCode === deliveryCountryCode);
            const baseProductRawId = pathOr(null, ['baseProduct', 'rawId'], this.props);
            this.props.router.push(`/buy-now?product=${baseProductRawId}&variant=${productVariant.rawId}&quantity=${quantity}${deliveryData.deliveryPackage && isDeliveryQuery
                ? `&delivery=${deliveryData.deliveryPackage.shippingId}`
                : ''}${deliveryData.deliveryPackage
                && deliveryData.country
                && isDeliveryQuery
                ? `&country=${deliveryData.country.id}`
                : ''}`);
        }
        else {
            this.setState({ unselectedAttr });
            if (!isEmpty(unselectedAttr) && head(unselectedAttr)) {
                smoothscroll.scrollTo(head(unselectedAttr));
            }
        }
    };
    handleAddToCartTracker = () => {
        const { productVariant } = this.state;
        if (process.env.BROWSER
            && process.env.REACT_APP_RRPARTNERID
            && productVariant
            && productVariant.rawId) {
            addToCartTracker(productVariant.rawId);
        }
    };
    render() {
        const { me, baseProduct, router } = this.props;
        const storeId = pathOr('', ['match', 'params', 'storeId'], this.props);
        const { unselectedAttr, isLoadingAddToCart, productVariant } = this.state;
        if (isNil(baseProduct)
            || isNil(productVariant)
            || storeId !== `${baseProduct.store.rawId}`) {
            return React.createElement("div", { className: styles.productNotFound }, t.productNotFound);
        }
        if (isNil(baseProduct.store)) {
            return React.createElement("div", { className: styles.productNotFound }, t.storeNotFound);
        }
        const { name, categoryId, shortDescription, longDescription, rating, store } = baseProduct;
        const { widgets, selectedAttributes, availableAttributes, isAddToCart, isLoading, cartQuantity, deliveryData } = this.state;
        const description = extractText(shortDescription, 'EN', t.noDescription);
        const userAddress = this.getUserAddress();
        return (React.createElement(AppContext.Consumer, null, ({ categories, directories }) => (React.createElement(ProductContext.Provider, { value: {
                store,
                productVariant,
                rating
            } },
            React.createElement("div", { className: styles.container },
                has('children')(categories) && !isNil(categories.children) ? (React.createElement(ProductBreadcrumbs, { categories: categories.children, categoryId: categoryId })) : null,
                React.createElement("div", { className: styles.productContent },
                    React.createElement(Row, null,
                        React.createElement(Col, { sm: 12, md: 7, lg: 6, xl: 7 },
                            React.createElement(ProductImage, { ...productVariant }),
                            React.createElement(MediaQuery, { minWidth: 768 }, this.makeTabs(longDescription))),
                        React.createElement(Col, { sm: 12, md: 5, lg: 6, xl: 5 },
                            React.createElement("div", { className: styles.detailsWrapper },
                                React.createElement(ProductDetails, { productTitle: extractText(name), productDescription: description, widgets: widgets, selectedAttributes: selectedAttributes, availableAttributes: availableAttributes, onWidgetClick: this.handleWidget, unselectedAttr: unselectedAttr, productVariant: productVariant, cartQuantity: cartQuantity, onChangeQuantity: this.handleChangeQuantity, userAddress: userAddress, baseProductRawId: baseProduct.rawId, countries: convertCountries(directories.countries), onChangeDeliveryData: this.handleChangeDeliveryData, deliveryData: deliveryData },
                                    (productVariant.quantity
                                        || productVariant.preOrder) && (React.createElement(ProductButtons, { onAddToCart: () => this.handleAddToCart(productVariant.rawId), onBuyNow: this.handleBuyNow, onAddToCartTracker: this.handleAddToCartTracker, unselectedAttr: unselectedAttr, quantity: productVariant.quantity, preOrder: productVariant.preOrder, preOrderDays: productVariant.preOrderDays, isAddToCart: isAddToCart, router: router, isLoading: isLoading, isLoadingAddToCart: isLoadingAddToCart })),
                                    React.createElement("div", { className: styles.line }),
                                    React.createElement(ProductStore, null)),
                                React.createElement(MediaQuery, { maxWidth: 767 }, this.makeTabs(longDescription)))))),
                React.createElement("div", { className: styles.rrBlock },
                    React.createElement(RRElement, { type: "alternative", productId: productVariant.rawId, title: "Similar goods", fullWidth: true })),
                React.createElement("div", { className: styles.rrBlock },
                    React.createElement(RRElement, { type: "related", productId: productVariant.rawId, title: "You also might like", fullWidth: true })))))));
    }
}
export default createFragmentContainer(withShowAlert(withErrorBoundary(Page(Product))), {
    baseProduct: graphql `
			fragment Product_baseProduct on BaseProduct {
				isShippingAvailable
				id
				rawId
				currency
				categoryId
				name {
					text
					lang
				}
				shortDescription {
					text
					lang
				}
				longDescription {
					text
					lang
				}
				store(visibility: "active") {
					rawId
					name {
						lang
						text
					}
					rating
					productsCount
					logo
					facebookUrl
					twitterUrl
					instagramUrl
				}
				rating
				variants {
					all {
						id
						rawId
						photoMain
						additionalPhotos
						price
						currency
						customerPrice {
							price
							currency
						}
						preOrder
						preOrderDays
						cashback
						discount
						quantity
						attributes {
							value
							metaField
							attribute {
								id
								name {
									text
									lang
								}
								metaField {
									values
									uiElement
								}
							}
						}
					}
				}
			}
    `
});
