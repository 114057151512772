import { commitMutation, graphql } from 'react-relay';
const mutation = graphql `
	mutation UpsertShippingMutation($input: NewShippingInput!) {
		upsertShipping(input: $input) {
			local {
				companyPackageId
				price
			}
			international {
				companyPackageId
				price
				deliveriesTo {
					alpha3
					label
				}
			}
			pickup {
				price
				pickup
			}
		}
	}
`;
const commit = (params) => commitMutation(params.environment, {
    mutation,
    variables: params.variables,
    onCompleted: params.onCompleted,
    onError: params.onError
});
export default { commit };
