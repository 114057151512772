import React from 'react';
import { Icon } from '../../../../../components/Icon';
import { Col } from '../../../../../layout';
import { addressToString } from '../../../../../utils';
import styles from './StoragesRow.module.scss';
import t from './i18n';
const StoragesRow = (props) => (React.createElement("div", { key: props.id, className: styles.container, onClick: (e) => {
        e.stopPropagation();
        props.onEdit(props.slug, false, e);
    }, onKeyDown: () => {
    }, role: "button", tabIndex: 0, "data-test": "editStorageField" },
    React.createElement(Col, { size: 12, sm: 12, md: 5, lg: 3, xl: 3 },
        React.createElement("div", { className: styles.storageName },
            React.createElement("div", null, props.name || `Storage ${props.slug}`))),
    React.createElement(Col, { size: 12, sm: 12, md: 4, lg: 8, xl: 8 },
        React.createElement("address", { className: styles.address },
            React.createElement("span", null, addressToString(props.addressFull) || t.addressNotSpecified))),
    React.createElement(Col, { size: 12, sm: 6, md: 3, lg: 1, xl: 1, mdVisible: true },
        React.createElement("div", { className: styles.buttonsWrapper },
            React.createElement("button", { className: styles.editButton, onClick: (e) => {
                    props.onEdit(props.slug, true, e);
                }, "data-test": "editStorageDataButton" },
                React.createElement(Icon, { type: "note", size: 32 }))))));
export default StoragesRow;
