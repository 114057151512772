import React, { Component } from 'react';
import { addIndex, map } from 'ramda';
import PropTypes from 'prop-types';
import { getEnumFromStatusString, getStatusStringFromEnum } from '../../OrderPage/utils';
import { Input } from '../../../../components/common/Input';
import { Select } from '../../../../components/common/Select';
import { BirthdateSelect } from '../../../../components/common/BirthdateSelect';
import { Icon } from '../../../../components/Icon';
import styles from './OrdersListHeader.module.scss';
import t from './i18n';
class OrdersListHeader extends Component {
    static contextTypes = { directories: PropTypes.object };
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: null,
            orderStatus: null,
            orderFromDate: null,
            orderToDate: null
        };
    }
    handleSearchTermChange = (e) => {
        e.persist();
        this.setState({ searchTerm: e.target.value }, () => this.props.onSearchTermFilterChanged(e.target.value));
    };
    handleOrderStatusChange = (item) => {
        if (!item.id) {
            this.setState({ orderStatus: null }, () => {
                this.props.onOrderStatusFilterChanged(null);
            });
            return;
        }
        if (getEnumFromStatusString(item.label)) {
            this.setState({ orderStatus: item }, () => this.props.onOrderStatusFilterChanged(getEnumFromStatusString(item.label)));
        }
    };
    handleOrderFromDateChange = (value) => {
        this.setState({ orderFromDate: value }, () => this.props.onOrderFromDateFilterChanged(value));
    };
    handleOrderToDateChange = (value) => {
        this.setState({ orderToDate: value }, () => this.props.onOrderToDateFilterChanged(value));
    };
    render() {
        const indexedMap = addIndex(map);
        const orderStatusesItems = indexedMap((label, id) => ({
            id: `order_status_${id}`,
            label: getStatusStringFromEnum(label)
        }), this.context.directories.orderStatuses);
        return (React.createElement("header", { className: styles.container },
            React.createElement("div", { className: styles.searchInput },
                React.createElement(Input, { id: "searchTermInput", label: t.labelSearchOrder, onChange: this.handleSearchTermChange, value: this.state.searchTerm || '', fullWidth: true, search: true, dataTest: "searchOrderInput" })),
            React.createElement("div", { className: styles.orderSelect },
                React.createElement("div", { className: styles.icon },
                    React.createElement(Icon, { type: "status", size: 32 })),
                React.createElement(Select, { items: orderStatusesItems, activeItem: this.state.orderStatus || undefined, dataTest: "ordersStatusSelect", forForm: true, onSelect: this.handleOrderStatusChange, label: t.labelOrderStatus, withEmpty: true, fullWidth: true })),
            React.createElement("div", { className: styles.dateRange },
                React.createElement("div", { className: styles.icon },
                    React.createElement(Icon, { type: "calendar", size: 32 })),
                React.createElement("div", { className: styles.calendars },
                    React.createElement("div", { className: styles.item },
                        React.createElement(BirthdateSelect, { brief: true, label: t.labelFrom, handleBirthdateSelect: this.handleOrderFromDateChange, birthdate: this.state.orderFromDate, dataTest: "ordersListDate" })),
                    React.createElement("div", { className: styles.item },
                        React.createElement(BirthdateSelect, { brief: true, label: t.labelTo, handleBirthdateSelect: this.handleOrderToDateChange, birthdate: this.state.orderToDate, dataTest: "ordersListDate" }))))));
    }
}
export default OrdersListHeader;
