import React from 'react';
import { makeRouteConfig, Redirect, RedirectException, Route } from 'found';
import { graphql } from 'react-relay';
import { find, isNil, last, pathEq, pathOr, pick } from 'ramda';
import SpinnerPage from '../pages/SpinnerPage';
import Start from '../pages/Start/Start';
import { EditStore, NewStore } from '../pages/Manage/Store/Settings';
import { clearJWT, urlToInput } from '../utils';
import { EditProduct, NewProduct } from '../pages/Manage/Store/Products/Product';
import Checkout from '../pages/Checkout/Checkout';
import { EditStorage, NewStorage, StorageProducts } from '../pages/Manage/Store/Storages/Storage';
import { Logout } from '../pages/Logout';
import { Showcase, Store, StoreAbout, StoreItems } from '../pages/Store';
import Cart from '../pages/Cart/Cart';
import Stores from '../pages/Stores/Stores';
import { StoreOrder, StoreOrders } from '../pages/Manage/Store/Orders';
import App from '../components/App/App';
import { Authorization, OAuthCallback } from '../components/Authorization';
import { Profile } from '../pages/Profile';
import { Products } from '../pages/Manage/Store/Products';
import { Storages } from '../pages/Manage/Store/Storages';
import { OrderInvoice } from '../pages/Manage/Store/OrderInvoice';
import { Contacts } from '../pages/Manage/Store/Contacts';
import { Finances } from '../pages/Manage/Store/Finances';
import { Wizard } from '../pages/Manage/Wizard';
import { Product as ProductCard } from '../pages/Store/Product';
import Categories from '../pages/Search/Categories';
import BuyNow from '../pages/BuyNow/BuyNow';
import { Error, Error404 } from '../pages/Errors';
import VerifyEmail from '../pages/VerifyEmail';
import { Invoice } from '../pages/Profile/items/Order';
import { StartSelling } from '../pages/StartSelling';
import { Login } from '../pages/Login';
import { PasswordReset } from '../pages/PasswordReset';
import { Privacy, Terms } from '../pages/PrivacyTerms';
export const routeConfig = makeRouteConfig(React.createElement(Route, null,
    React.createElement(Route, { path: "/", Component: App, query: graphql `
        query routes_App_Query {
          id
          me {
            id
            ...App_me
            wizardStore {
              id
              completed
            }
          }
          cart {
            id
            ...UserData_cart
          }
          mainPage {
            ...Start_mainPage
          }
          countries {
            children {
              children {
                alpha3
                alpha2
                label
              }
            }
          }
          languages {
            isoCode
          }
          currencies
          sellerCurrencies
          categories {
            name {
              lang
              text
            }
            children {
              id
              rawId
              parentId
              level
              name {
                lang
                text
              }
              children {
                id
                rawId
                parentId
                level
                name {
                  lang
                  text
                }
                children {
                  id
                  rawId
                  parentId
                  level
                  name {
                    lang
                    text
                  }
                  getAttributes {
                    id
                    rawId
                    name {
                      text
                      lang
                    }
                    metaField {
                      values
                      translatedValues {
                        translations {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          orderStatuses
          currencyExchange {
            code
            rates {
              code
              value
            }
          }
        }
      `, render: ({ error, Component, props }) => {
            if (error) {
                // error({error});
                const errors = pathOr([], ['source', 'errors'], error);
                if (find(pathEq('401', ['data', 'details', 'code']))(errors)) {
                    return React.createElement(Component, { ...props });
                }
            }
            return React.createElement(Component, { ...props });
        } },
        React.createElement(Route, { path: "/verify_email/:token", Component: VerifyEmail }),
        React.createElement(Route, { path: "/error", Component: Error }),
        React.createElement(Route, { Component: Start }),
        React.createElement(Route, { path: "/404", Component: Error404 }),
        React.createElement(Route, { path: "/privacy", Component: Privacy }),
        React.createElement(Route, { path: "/terms", Component: Terms }),
        React.createElement(Route, { path: "/cart", Component: Cart, query: graphql `
          query routes_Cart_Query {
            cart {
              ...Cart_cart
            }
          }
        ` }),
        React.createElement(Route, { path: "/checkout", Component: Checkout, render: ({ match, props, Component, resolving }) => {
                if (Component && resolving === true && props && !props.me) {
                    clearJWT();
                    throw new RedirectException(`/login?from=${match.location.pathname}`);
                }
                else if (!props) {
                    return React.createElement(SpinnerPage, null);
                }
                else {
                    return React.createElement(Component, { ...props });
                }
            }, query: graphql `
          query routes_Checkout_Query {
            me {
              ...Checkout_me
              ...UserData_me
            }
            cart {
              ...Checkout_cart
              ...UserData_cart
            }
          }
        ` }),
        React.createElement(Route, { path: "/buy-now", Component: BuyNow, render: ({ match, props, Component, resolving }) => {
                if (Component && resolving === true && props && !props.me) {
                    clearJWT();
                    throw new RedirectException(`/login?from=${match.location.pathname}${match.location.search}`);
                }
                else if (props && Component) {
                    return React.createElement(Component, { ...props });
                }
                else {
                    return undefined;
                }
            }, query: graphql `
          query routes_BuyNow_Query(
            $productID: Int!
            $variantId: Int!
            $quantity: Int!
            $shippingId: Int
          ) {
            me {
              ...UserData_me
              id
              rawId
              phone
              email
              firstName
              lastName
              deliveryAddressesFull {
                id
                address {
                  country
                  countryCode
                  value
                  administrativeAreaLevel1
                  administrativeAreaLevel2
                  locality
                  political
                  postalCode
                  route
                  streetNumber
                  placeId
                }
                isPriority
              }
            }
            baseProduct(id: $productID) {
              ...BuyNow_baseProduct
            }
            calculateBuyNow(
              productId: $variantId
              quantity: $quantity
              shippingId: $shippingId
            ) {
              product {
                id
                rawId
              }
              couponsDiscounts
              totalCost
              totalCostWithoutDiscounts
              totalCount
              deliveryCost
              subtotal
              subtotalWithoutDiscounts
              price
            }
          }
        `, prepareVariables: (...args) => {
                const queryObj = pathOr('', ['query'], last(args).location);
                return {
                    productID: parseFloat(queryObj.product || 0),
                    variantId: parseFloat(queryObj.variant),
                    quantity: parseFloat(queryObj.quantity),
                    shippingId: parseFloat(queryObj.delivery)
                };
            } }),
        React.createElement(Route, { path: "/categories", Component: Categories, query: graphql `
          query routes_Categories_Query($searchTerm: SearchProductInput!) {
            search {
              ...Categories_search @arguments(text: $searchTerm)
            }
          }
        `, prepareVariables: (...args) => {
                const queryObj = pathOr('', ['query'], last(args).location);
                const searchTerm = urlToInput(queryObj);
                return {
                    searchTerm: {
                        name: searchTerm.name,
                        options: pick([
                            'attrFilters',
                            'priceFilter',
                            'currency',
                            'categoryId',
                            'storeId',
                            'sortBy',
                            'status'
                        ], searchTerm.options || {})
                    }
                };
            } }),
        React.createElement(Route, { path: "/stores", Component: Stores, query: graphql `
          query routes_Stores_Query($input: SearchStoreInput!) {
            search {
              ...Stores_search @arguments(text: $input)
            }
          }
        `, prepareVariables: (_, { location }) => {
                const queryObj = pathOr('', ['query'], location);
                const searchTerm = urlToInput(queryObj);
                return {
                    input: {
                        ...searchTerm,
                        getStoresTotalCount: true
                    }
                };
            } }),
        React.createElement(Route, { path: "/store" },
            React.createElement(Route, { path: "/:storeId/products/:productId/:variant?/:variantId?", Component: ProductCard, query: graphql `
            query routes_ProductCard_Query($productID: Int!) {
              me {
                id
                rawId
                phone
                firstName
                lastName
                deliveryAddressesFull {
                  id
                  address {
                    country
                    countryCode
                    value
                    administrativeAreaLevel1
                    administrativeAreaLevel2
                    locality
                    political
                    postalCode
                    route
                    streetNumber
                    placeId
                  }
                  isPriority
                }
              }
              baseProduct(id: $productID, visibility: "active") {
                ...Product_baseProduct
              }
            }
          `, prepareVariables: (_, { params }) => ({
                    productID: parseInt(params.productId || 0, 10)
                }) })),
        React.createElement(Redirect, { from: "/start-selling", to: () => '/start-selling/en' }),
        React.createElement(Route, { path: "/start-selling/:lang", query: graphql `
              query routes_StartSelling_Query {
                me {
                  id
                  wizardStore {
                    id
                    rawId
                    completed
                    storeId
                  }
                }
              }
            `, Component: StartSelling, render: ({ props, Component }) => {
                if (props) {
                    const { me } = props;
                    if (!isNil(me) && !isNil(me.wizardStore) && me.wizardStore.completed) {
                        throw new RedirectException(`/manage/store/${me.wizardStore.storeId}`);
                    }
                    else {
                        return React.createElement(Component, null);
                    }
                }
                else {
                    return null;
                }
            } }),
        React.createElement(Route, { path: "/manage", query: graphql `
          query routes_Manage_Query {
            me {
              id
              ...UserData_me
            }
            cart {
              ...UserData_cart
            }
          }
        `, render: ({ match, props, Component, resolving }) => {
                if (Component && props) {
                    if (match.location.pathname === '/manage' || match.location.pathname === '/manage/') {
                        throw new RedirectException('/404');
                    }
                    if (!props.me && resolving === true) {
                        clearJWT();
                        throw new RedirectException(`/login?from=${match.location.pathname}`);
                    }
                }
                return undefined;
            }, Component: () => React.createElement("div", null) },
            React.createElement(Route, { path: "/wizard", query: graphql `
            query routes_Wizard_Query {
              languages {
                isoCode
              }
              me {
                id
                wizardStore {
                  id
                  completed
                  storeId
                }
                ...Wizard_me
                ...UserData_me
              }
              cart {
                ...UserData_cart
              }
              allCategories {
                ...ThirdForm_allCategories
              }
            }
          `, Component: Wizard, render: ({ props, Component }) => {
                    if (props) {
                        if (!props.me) {
                            throw new RedirectException('/login?from=/start-selling');
                        }
                        else if (props.me.wizardStore &&
                            props.me.wizardStore.completed) {
                            throw new RedirectException(`/manage/store/${props.me.wizardStore.storeId}/products`);
                        }
                        else {
                            return React.createElement(Component, { ...props });
                        }
                    }
                    else {
                        return null;
                    }
                } }),
            React.createElement(Route, { path: "/store" },
                React.createElement(Route, { path: "/new", exact: true, query: graphql `
              query routes_NewStore_Query {
                me {
                  myStore {
                    id
                    rawId
                  }
                }
              }
            `, render: ({ props, Component }) => {
                        const myStoreId = pathOr(null, ['me', 'myStore', 'rawId'], props);
                        if (myStoreId) {
                            throw new RedirectException(`/manage/store/${myStoreId}/products`);
                        }
                        else {
                            return React.createElement(Component, { ...props });
                        }
                    }, Component: NewStore }),
                React.createElement(Route, { path: "/:storeId", query: graphql `
              query routes_CheckStore_Query {
                me {
                  myStore {
                    id
                    rawId
                    logo
                    name {
                      lang
                      text
                    }
                  }
                }
              }
            `, render: ({ match, props, Component }) => {
                        const myStoreId = pathOr(null, ['me', 'myStore', 'rawId'], props);
                        const routeStoreId = pathOr(null, ['params', 'storeId'], match);
                        if (!myStoreId || myStoreId !== +routeStoreId) {
                            return React.createElement(Component, null);
                        }
                        return undefined;
                    }, Component: Error404 },
                    React.createElement(Route, { Component: EditStore, query: graphql `
                query routes_ManageStore_Query {
                  me {
                    id
                    ...EditStore_me
                  }
                }
              ` }),
                    React.createElement(Route, { path: "/contacts", Component: Contacts, query: graphql `
                query routes_Contacts_Query {
                  me {
                    ...Contacts_me
                  }
                }
              ` }),
                    React.createElement(Route, { path: "/finances", Component: Finances, query: graphql `
                query routes_Finances_Query {
                  me {
                    ...Finances_me
                  }
                }
              ` }),
                    React.createElement(Route, { path: "/orders", Component: StoreOrders, query: graphql `
                query routes_StoreOrders_Query {
                  me {
                    ...StoreOrders_me
                  }
                }
              ` }),
                    React.createElement(Route, { path: "/orders/:orderId", Component: StoreOrder, query: graphql `
                query routes_StoreOrder_Query($slug: Int!) {
                  me {
                    ...StoreOrder_me @arguments(slug: $slug)
                  }
                }
              `, prepareVariables: (_, { params }) => ({
                            slug: parseInt(params.orderId, 10) || 0
                        }) }),
                    React.createElement(Route, { path: "/orders/:orderId/invoice", Component: OrderInvoice, prepareVariables: (_, { params }) => ({
                            orderId: parseInt(params.orderId, 10) || 0
                        }), query: graphql `
                query routes_OrderInvoice_Query($orderId: Int!) {
                  me {
                    ...OrderInvoice_me @arguments(slug: $orderId)
                  }
                }
              ` }),
                    React.createElement(Route, { path: "/product/new", Component: ({ me, allCategories }) => (React.createElement(NewProduct, { me: me, allCategories: allCategories })), query: graphql `
                query routes_NewProduct_Query {
                  me {
                    ...NewProduct_me
                  }
                  allCategories {
                    ...Form_allCategories
                  }
                }
              ` }),
                    React.createElement(Route, { path: "/products", Component: Products, query: graphql `
                query routes_Products_Query {
                  me {
                    ...Products_me
                  }
                }
              ` }),
                    React.createElement(Route, { path: "/products/:productId/:tab?/:variantId?", Component: EditProduct, query: graphql `
                query routes_Product_Query($productID: Int!) {
                  me {
                    id
                    ...EditProduct_me @arguments(productID: $productID)
                  }
                  allCategories {
                    ...Form_allCategories
                  }
                }
              `, prepareVariables: (_, { params }) => ({
                            productID: parseInt(params.productId, 10) || 0
                        }) }),
                    React.createElement(Route, { path: "/storages", Component: Storages, query: graphql `
                query routes_Storages_Query {
                  me {
                    ...Storages_me
                  }
                }
              `, prepareVariables: (_, { params }) => ({
                            storeId: parseInt(params.storeId, 10) || 0
                        }) }),
                    React.createElement(Route, { path: "/storage/new", Component: NewStorage }),
                    React.createElement(Route, { path: "/storages/:storageSlug", Component: StorageProducts, query: graphql `
                query routes_StorageProducts_Query($storageSlug: String!) {
                  me {
                    ...StorageProducts_me @arguments(storageSlug: $storageSlug)
                  }
                }
              `, prepareVariables: (_, { params }) => ({
                            storageSlug: params.storageSlug
                        }) }),
                    React.createElement(Route, { path: "/storages/:storageSlug/edit", Component: EditStorage, query: graphql `
                query routes_EditStorage_Query($storageSlug: String!) {
                  me {
                    ...EditStorage_me @arguments(storageSlug: $storageSlug)
                  }
                }
              `, prepareVariables: (_, { params }) => ({
                            storageSlug: params.storageSlug
                        }) })))),
        React.createElement(Route, { path: "/registration", Component: Authorization, render: ({ Component, props, error }) => {
                if (error) {
                    const errors = pathOr(null, ['source', 'errors'], error);
                    if (find(pathEq('401', ['data', 'details', 'code']))(errors)) {
                        return React.createElement(Component, { isSignUp: true, ...props });
                    }
                }
                return React.createElement(Component, { isSignUp: true, ...props });
            } }),
        React.createElement(Route, { path: "/login", query: graphql `
          query routes_Login_Query {
            me {
              id
            }
          }
        `, Component: Login, render: ({ Component, props }) => {
                if (props && !isNil(props.me)) {
                    throw new RedirectException('/');
                }
                return React.createElement(Component, { noPopup: true, ...props });
            } }),
        React.createElement(Route, { path: "/logout", Component: Logout }),
        React.createElement(Route, { path: "/password_reset/:token", Component: PasswordReset }),
        React.createElement(Route, { path: "/oauth_callback/fb", Component: OAuthCallback, render: ({ props, Component }) => (React.createElement(Component, { provider: "FACEBOOK", ...props })) }),
        React.createElement(Route, { path: "/oauth_callback/google", Component: OAuthCallback, render: ({ props, Component }) => (React.createElement(Component, { provider: "GOOGLE", ...props })) }),
        React.createElement(Redirect, { from: "/profile", to: () => '/profile/personal-data' }),
        React.createElement(Route, { path: "/profile" },
            React.createElement(Route, { path: "/orders/:orderId/payment-info", Component: Invoice, render: ({ props, Component }) => {
                    if (!props) {
                        return undefined;
                    }
                    return React.createElement(Component, { ...props });
                }, query: graphql `
            query routes_Invoice_Query($slug: Int!) {
              me {
                ...Invoice_me @arguments(slug: $slug)
              }
            }
          `, prepareVariables: (_, { params }) => ({
                    slug: parseInt(params.orderId, 10)
                }) }),
            React.createElement(Route, { path: "/:item/:orderId?", Component: Profile, query: graphql `
            query routes_ProfileItem_Query($slug: Int!) {
              me {
                ...Profile_me @arguments(slug: $slug)
                ...UserData_me
              }
              cart {
                ...UserData_cart
              }
            }
          `, render: ({ match, props, Component }) => {
                    if (props) {
                        if (!props.me) {
                            clearJWT();
                            throw new RedirectException(`/login?from=${match.location.pathname}`);
                        }
                        else {
                            const isOrder = pathOr(null, ['params', 'orderId'], match);
                            const item = pathOr('personal-data', ['params', 'item'], match);
                            return (React.createElement(Component, { ...props, activeItem: isOrder && item === 'orders' ? 'orders' : item, isOrder: isOrder }));
                        }
                    }
                    else {
                        return undefined;
                    }
                }, prepareVariables: (_, { params }) => ({
                    slug: parseInt(params.orderId, 10) || 0
                }) })),
        React.createElement(Route, { path: "/store/:storeId", Component: Store, query: graphql `
          query routes_Store_Query($storeId: Int!) {
            store(id: $storeId, visibility: "active") {
              id
              rawId
              logo
              cover
              name {
                lang
                text
              }
              rating
              facebookUrl
              twitterUrl
              instagramUrl
              ...StoreItems_shop
              ...About_shop
              ...Showcase_shop
            }
          }
        `, render: ({ props, Component }) => {
                if (props) {
                    return React.createElement(Component, { ...props });
                }
                return undefined;
            }, prepareVariables: (_, { params }) => ({
                storeId: parseInt(params.storeId, 10)
            }) },
            React.createElement(Route, { Component: Showcase, render: ({ props, Component }) => React.createElement(Component, { ...props, key: "shop" }) }),
            React.createElement(Route, { path: "/about", Component: StoreAbout, render: ({ props, Component }) => (React.createElement(Component, { ...props, key: "about" })) }),
            React.createElement(Route, { path: "/items", Component: StoreItems, render: ({ props, Component }) => (React.createElement(Component, { ...props, key: "items" })) })))));
