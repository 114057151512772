/**
 * @generated SignedSource<<f38f1699728630e0b72bfdff4da381e6>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "couponCode"
    }, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "productId"
    }, v2 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "quantity"
    }, v3 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "shippingId"
    }, v4 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "couponCode",
                    "variableName": "couponCode"
                },
                {
                    "kind": "Variable",
                    "name": "productId",
                    "variableName": "productId"
                },
                {
                    "kind": "Variable",
                    "name": "quantity",
                    "variableName": "quantity"
                },
                {
                    "kind": "Variable",
                    "name": "shippingId",
                    "variableName": "shippingId"
                }
            ],
            "concreteType": "BuyNowCheckout",
            "kind": "LinkedField",
            "name": "calculateBuyNow",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Product",
                    "kind": "LinkedField",
                    "name": "product",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rawId",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "couponsDiscounts",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCost",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCostWithoutDiscounts",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deliveryCost",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subtotal",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subtotalWithoutDiscounts",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "price",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "fetchBuyNow_Query",
            "selections": (v4 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v0 /*: any*/),
                (v3 /*: any*/)
            ],
            "kind": "Operation",
            "name": "fetchBuyNow_Query",
            "selections": (v4 /*: any*/)
        },
        "params": {
            "cacheID": "07d893feb307201fe41aaa8621efc3cc",
            "id": null,
            "metadata": {},
            "name": "fetchBuyNow_Query",
            "operationKind": "query",
            "text": "query fetchBuyNow_Query(\n  $productId: Int!\n  $quantity: Int!\n  $couponCode: String\n  $shippingId: Int\n) {\n  calculateBuyNow(productId: $productId, quantity: $quantity, couponCode: $couponCode, shippingId: $shippingId) {\n    product {\n      id\n      rawId\n    }\n    couponsDiscounts\n    totalCost\n    totalCostWithoutDiscounts\n    totalCount\n    deliveryCost\n    subtotal\n    subtotalWithoutDiscounts\n    price\n  }\n}\n"
        }
    };
})();
node.hash = "d38c0c224512fee632baa95c41dbc648";
export default node;
