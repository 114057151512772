/**
 * @generated SignedSource<<b35409577f7ff3f61228be1f37837816>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawId",
        "storageKey": null
    }, v2 = [
        (v1 /*: any*/),
        {
            "alias": null,
            "args": null,
            "concreteType": "Translation",
            "kind": "LinkedField",
            "name": "name",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lang",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "text",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "argumentDefinitions": [
            {
                "defaultValue": 1,
                "kind": "LocalArgument",
                "name": "currentPage"
            },
            {
                "defaultValue": 10,
                "kind": "LocalArgument",
                "name": "itemsCount"
            },
            {
                "defaultValue": {},
                "kind": "LocalArgument",
                "name": "searchTermOptions"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "StoreOrders_me",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "Store",
                "kind": "LinkedField",
                "name": "myStore",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    (v1 /*: any*/),
                    {
                        "alias": null,
                        "args": [
                            {
                                "kind": "Variable",
                                "name": "currentPage",
                                "variableName": "currentPage"
                            },
                            {
                                "kind": "Variable",
                                "name": "itemsCount",
                                "variableName": "itemsCount"
                            },
                            {
                                "kind": "Variable",
                                "name": "searchTermOptions",
                                "variableName": "searchTermOptions"
                            }
                        ],
                        "concreteType": "OrderSearchConnection",
                        "kind": "LinkedField",
                        "name": "orders",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "OrdersEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "GraphQLOrder",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "slug",
                                                "storageKey": null
                                            },
                                            (v0 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "currency",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "state",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "price",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "quantity",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "subtotal",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "totalAmount",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "createdAt",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "paymentStatus",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "deliveryCompany",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Product",
                                                "kind": "LinkedField",
                                                "name": "product",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "BaseProduct",
                                                        "kind": "LinkedField",
                                                        "name": "baseProduct",
                                                        "plural": false,
                                                        "selections": (v2 /*: any*/),
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Store",
                                                "kind": "LinkedField",
                                                "name": "store",
                                                "plural": false,
                                                "selections": (v2 /*: any*/),
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "PageInfoOrdersSearch",
                                "kind": "LinkedField",
                                "name": "pageInfo",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "totalPages",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "currentPage",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "pageItemsCount",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "User",
        "abstractKey": null
    };
})();
node.hash = "f0c69b6a96ca0471db02a86146aa1d26";
export default node;
