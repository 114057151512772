import { commitMutation, graphql } from 'react-relay';
const mutation = graphql `
	mutation UpdateWarehouseMutation($input: UpdateWarehouseInput!) {
		updateWarehouse(input: $input) {
			id
			name
			storeId
			addressFull {
				country
				countryCode
				administrativeAreaLevel1
				administrativeAreaLevel2
				political
				postalCode
				streetNumber
				value
				route
				locality
			}
		}
	}
`;
const commit = (params) => commitMutation(params.environment, {
    mutation,
    variables: params.variables,
    onCompleted: params.onCompleted,
    onError: params.onError
});
export default { commit };
