import { assocPath, complement, has, identity, ifElse, isNil, path, propEq } from 'ramda';
import { COOKIE_CURRENCY } from '../constants';
import { getCookie } from './cookiesOp';
function setCurrency(item) {
    if (has('products', item)) {
        const node = path(['products', 'edges'], item);
        if (!isNil(node.customerPrice)) {
            const itemWithCurrency = assocPath(['products', 'edges'], [
                {
                    node: {
                        ...node,
                        price: node.customerPrice.price
                    }
                }
            ], item);
            return {
                ...itemWithCurrency,
                currency: node.customerPrice.currency
            };
        }
    }
    if (!isNil(item.customerPrice)) {
        return {
            ...item,
            currency: item.customerPrice.currency,
            price: item.customerPrice.price
        };
    }
    return item;
}
export const verifyItemCurrency = (item) => {
    const checkItemCurrency = (cookie) => ifElse(complement(propEq(cookie, 'currency')), setCurrency, identity);
    return checkItemCurrency(getCookie(COOKIE_CURRENCY))(item);
};
