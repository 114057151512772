/**
 * @generated SignedSource<<6a74db2e5419235cb06b58ed0dfbe14f>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "productsCost",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deliveryCost",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCost",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "Cart_cart",
        "selections": [
            (v0 /*: any*/),
            (v1 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "Cart",
                "kind": "LinkedField",
                "name": "fiat",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    (v2 /*: any*/),
                    (v3 /*: any*/),
                    (v1 /*: any*/),
                    (v4 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalCostWithoutDiscounts",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "productsCostWithoutDiscounts",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "couponsDiscounts",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "CartStoresConnection",
                        "kind": "LinkedField",
                        "name": "stores",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "CartStoresEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "CartStore",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                            (v0 /*: any*/),
                                            {
                                                "args": null,
                                                "kind": "FragmentSpread",
                                                "name": "CartStore_store"
                                            },
                                            (v2 /*: any*/),
                                            (v3 /*: any*/),
                                            (v4 /*: any*/),
                                            (v1 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "CartProduct",
                                                "kind": "LinkedField",
                                                "name": "products",
                                                "plural": true,
                                                "selections": [
                                                    (v0 /*: any*/),
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "selected",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": [
                                                            {
                                                                "kind": "Literal",
                                                                "name": "visibility",
                                                                "value": "active"
                                                            }
                                                        ],
                                                        "concreteType": "BaseProduct",
                                                        "kind": "LinkedField",
                                                        "name": "baseProduct",
                                                        "plural": false,
                                                        "selections": [
                                                            (v0 /*: any*/),
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "kind": "ScalarField",
                                                                "name": "isShippingAvailable",
                                                                "storageKey": null
                                                            }
                                                        ],
                                                        "storageKey": "baseProduct(visibility:\"active\")"
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "quantity",
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Cart",
        "abstractKey": null
    };
})();
node.hash = "f1ceae019de5a174358d93e387a16d7d";
export default node;
