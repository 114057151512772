import { t } from '../../../translation/utils';
const translations = {
    en: {
        checkHere: 'Check here to indicate that you have read and agree to the',
        termsOfUse: 'Terms of Use',
        and: 'and',
        privatePolicy: 'Privacy Policy',
        agree: 'I agree to my personal data being stored and used.'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
