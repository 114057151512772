/**
 * @generated SignedSource<<94b5f8d748b9ab2a77c85d012d189bd0>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "VerifyEmailApplyOutput",
            "kind": "LinkedField",
            "name": "verifyEmail",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "success",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "token",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "VerifyEmailMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "VerifyEmailMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "508136bbf99f0514d227e9b390ce2cb5",
            "id": null,
            "metadata": {},
            "name": "VerifyEmailMutation",
            "operationKind": "mutation",
            "text": "mutation VerifyEmailMutation(\n  $input: VerifyEmailApply!\n) {\n  verifyEmail(input: $input) {\n    success\n    email\n    token\n  }\n}\n"
        }
    };
})();
node.hash = "f3155ba34475ad4b130a52ffb06a306f";
export default node;
