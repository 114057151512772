import React, { PureComponent } from 'react';
import { withRouter } from 'found';
import { pathOr } from 'ramda';
import { Col, Container, Row } from '../../layout';
import { Footer } from '../../components/App';
import StartSellingHeader from './StartSellingHeader';
import StartSellingMarket from './StartSellingMarket';
import StartSellingTryStoriqa from './StartSellingTryStoriqa';
import StartSellingForSellers from './StartSellingForSellers';
import StartSellingFAQ from './StartSellingFAQ';
import StartSellingHeading from './StartSellingHeading';
import styles from './StartSelling.module.scss';
class StartSelling extends PureComponent {
    render() {
        const lang = pathOr('en', ['match', 'params', 'lang'], this.props);
        return (React.createElement("div", { className: styles.container },
            React.createElement(Container, null,
                React.createElement(StartSellingHeader, { lang: lang }),
                React.createElement("div", { className: styles.wrapper },
                    React.createElement(Row, null,
                        React.createElement(Col, { size: 1 }),
                        React.createElement(Col, { size: 12, sm: 12, md: 12, lg: 10, xl: 10 },
                            React.createElement(StartSellingHeading, { lang: lang }),
                            React.createElement(StartSellingMarket, { lang: lang }),
                            React.createElement(StartSellingTryStoriqa, { lang: lang }),
                            React.createElement(StartSellingForSellers, { lang: lang }),
                            React.createElement(StartSellingFAQ, { lang: lang }),
                            React.createElement(Footer, { isShopCreated: true })),
                        React.createElement(Col, { size: 1 }))))));
    }
}
export default withRouter(StartSelling);
