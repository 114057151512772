import { fetchQuery, graphql } from 'react-relay';
import { isNil, reject } from 'ramda';
import { debug } from '../../utils';
const PRODUCTS = graphql `
	query fetchProducts_Query($input: GetProductsInput!) {
		products(input: $input) {
			id
			rawId
			discount
			photoMain
			cashback
			price
			customerPrice {
				price
				currency
			}
			baseProduct(visibility: "published") {
				rawId
				storeId
				name {
					lang
					text
				}
				store {
					name {
						lang
						text
					}
				}
				currency
				rating
			}
		}
	}
`;
const fetchProducts = (input) => {
    debug();
    const variables = { input: { ids: input.ids } };
    return fetchQuery(input.environment, PRODUCTS, variables)
        .toPromise()
        .then((response) => {
        if (response && response.products && response.products instanceof Array) {
            return Promise.resolve(reject(isNil, response.products));
        }
        return Promise.reject(new Error('Unable to fetch packages'));
    });
};
export default fetchProducts;
