import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Link } from 'found';
import { isNil } from 'ramda';
import { Icon } from '../../../../components/Icon';
import { pluckFromOrder } from './utils';
import styles from './OrderInvoice.module.scss';
import t from './i18n';
import OrderInvoiceData from './OrderInvoiceData';
import OrderInvoiceAddress from './OrderInvoiceAddress';
import OrderInvoiceTableRow from './OrderInvoiceTableRow';
import InvoiceTotal from './InvoiceTotal';
import OrderInvoiceTable from './OrderInvoiceTable';
class OrderInvoice extends PureComponent {
    render() {
        const { me: { order } } = this.props;
        const fromOrder = pluckFromOrder(order);
        const clonedOrder = (!isNil(order) ? { ...order } : {});
        const invoiceData = fromOrder(['receiverName', 'slug', 'trackId', 'state']);
        const email = !isNil(order) ? order.receiverEmail : '';
        const address = !isNil(order) ? order.addressFull : {};
        const phone = !isNil(order) ? order.receiverPhone : '';
        const currency = !isNil(order) ? order.currency : '';
        const invoiceAddress = {
            ...address,
            email,
            phone
        };
        // const quantity = !isNil(order) ? order.quantity : 0;
        const totalAmount = !isNil(order) ? order.totalAmount : 0;
        // const total = product([quantity, totalAmount]);
        return (React.createElement("section", { className: styles.container },
            React.createElement("header", { className: styles.header },
                React.createElement("h2", { className: styles.title }, t.invoice),
                React.createElement("div", { className: styles.logo },
                    React.createElement(Link, { to: "/", "data-test": "logoLink" },
                        React.createElement(Icon, { type: "logo" })))),
            React.createElement("div", { className: styles.invoiceDetails },
                React.createElement(OrderInvoiceData, { ...invoiceData }),
                React.createElement(OrderInvoiceAddress, { ...invoiceAddress })),
            React.createElement("div", { className: styles.table },
                React.createElement("div", { className: styles.tableWrapper },
                    React.createElement(OrderInvoiceTable, null,
                        React.createElement(OrderInvoiceTableRow, { ...clonedOrder }),
                        React.createElement(InvoiceTotal, { total: totalAmount, currency: currency, shipping: "N/A" }))))));
    }
}
export default createFragmentContainer(OrderInvoice, {
    me: graphql `
			fragment OrderInvoice_me on User
			@argumentDefinitions(slug: { type: "Int!", defaultValue: 0 }) {
				order(slug: $slug) {
					slug
					currency
					receiverName
					receiverPhone
					receiverEmail
					trackId
					state
					quantity
					price
					totalAmount
					subtotal
					product {
						currency
						attributes {
							value
						}
						baseProduct {
							name {
								lang
								text
							}
						}
					}
					addressFull {
						value
						country
						administrativeAreaLevel1
						administrativeAreaLevel2
						locality
						political
						postalCode
						route
						streetNumber
						placeId
					}
				}
			}
    `
});
