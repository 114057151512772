import { applyMiddleware, compose, createStore } from 'redux';
import { createHistoryEnhancer, queryMiddleware } from 'farce';
import { createMatchEnhancer, Matcher } from 'found';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { routeConfig } from '../routes/routes';
import { reducers } from './reducers';
export function createReduxStore(historyProtocol, preloadedState) {
    const middlewares = [thunk];
    if (process.env.NODE_ENV !== 'production') {
        const logger = createLogger({ collapsed: true });
        middlewares.push(logger);
    }
    /*
     const composedEnhancers = compose(
     createHistoryEnhancer({
     protocol: historyProtocol,
     middlewares: [queryMiddleware]
     }),
     createMatchEnhancer(new Matcher(routesConfig)),
     applyMiddleware(...middlewares)
     );
     */
    /*
     const reducer = () => combineReducers({
     found: foundReducer,
     dummy
     });
  
     return createStore(
     reducer,
     initialState,
     composedEnhancers
     );
     */
    /*
     const reducers = combineReducers({
     found: foundReducer,
     dummy
     });
     */
    let enhancers;
    if (process.browser && window.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers = compose(createHistoryEnhancer({
            protocol: historyProtocol,
            middlewares: [queryMiddleware]
        }), window.__REDUX_DEVTOOLS_EXTENSION__(), applyMiddleware(...middlewares), createMatchEnhancer(new Matcher(routeConfig)));
    }
    else {
        enhancers = compose(createHistoryEnhancer({
            protocol: historyProtocol,
            middlewares: [queryMiddleware]
        }), applyMiddleware(...middlewares), createMatchEnhancer(new Matcher(routeConfig)));
    }
    return createStore(reducers, preloadedState, enhancers);
}
