import React, { PureComponent } from 'react';
import { includes, map, prop, sortBy } from 'ramda';
import styles from './ProductSize.module.scss';
import { classNames } from '../../../../utils';
class ProductSize extends PureComponent {
    render() {
        const { title, options, isOnSelected } = this.props;
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { id: title, className: classNames(styles, 'title', { 'isOnSelected': isOnSelected }) },
                React.createElement("strong", null, title)),
            React.createElement("div", { className: styles.sizes }, map((option) => {
                const available = includes(option.label, this.props.availableValues);
                const separator = () => available;
                return (React.createElement("button", { key: `${option.label}`, "data-test": `productSize${option.label}`, onClick: () => {
                        this.props.onClick({
                            attributeId: this.props.id,
                            attributeValue: option.label
                        });
                    }, className: classNames(styles, 'size', `${option.label === this.props.selectedValue ? 'clicked' : ''}`, `${!available ? 'disabled' : ''}`) },
                    option.label,
                    !available || separator() ? (React.createElement("span", { className: classNames(styles, 'separator', { 'highlighted': option.label === this.props.selectedValue }) })) : null));
            }, sortBy(prop('label'), options)))));
    }
}
export default ProductSize;
