import React, { Component } from 'react';
import debounce from 'lodash.debounce';
import { Input } from '../Input';
import { ContextDecorator } from '../../App';
import fetchStoreSlugExists from './fetchStoreSlugExists';
import styles from './InputSlug.module.scss';
import t from './i18n';
import { classNames } from '../../../utils';
class InputSlug extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFocus: false,
            value: props.slug,
            storeSlugExists: undefined,
            incorrectFormat: false,
            serverError: false
        };
        this.checkSlug = debounce(this.checkSlug, 250);
    }
    componentDidMount() {
        this.mounted = true;
    }
    componentWillUnmount() {
        this.mounted = false;
    }
    mounted = false;
    checkSlug = (slugValue) => {
        if (!slugValue) {
            this.setState({ storeSlugExists: false }, () => {
                this.props.onChange(undefined);
            });
            return;
        }
        if (/-$/i.test(slugValue)) {
            this.setState({ incorrectFormat: true }, () => {
                this.props.onChange(null);
            });
            return;
        }
        const { realSlug } = this.props;
        if (realSlug && realSlug === slugValue) {
            return;
        }
        fetchStoreSlugExists(this.props.environment, { slug: slugValue })
            .toPromise()
            .then(({ storeSlugExists }) => {
            if (!this.mounted) {
                return true;
            }
            if (!storeSlugExists) {
                this.setState((prevState) => ({
                    storeSlugExists: prevState.storeSlugExists !== false && prevState.isFocus
                        ? false
                        : undefined
                }), () => {
                    this.props.onChange(slugValue);
                });
            }
            else {
                this.setState({ storeSlugExists: true }, () => {
                    this.props.onChange(undefined);
                });
            }
            return true;
        })
            .catch(() => {
            this.setState({ serverError: true });
        });
    };
    handleChange = (e) => {
        this.setState({
            incorrectFormat: false,
            storeSlugExists: undefined,
            serverError: false
        });
        const { value } = e.target;
        const correctValue = value
            .toString()
            .toLowerCase()
            // Replace spaces with -
            .replace(/\s+/g, '-')
            // Replace multiple - with single -
            .replace(/(--)+/g, '-')
            // Trim - from start of text
            .replace(/^-+/, '')
            // Only english letters
            .replace(/[^-a-z]/gim, '');
        this.setState({
            value: correctValue,
            storeSlugExists: false
        }, () => {
            this.checkSlug(correctValue);
        });
    };
    handleFocus = () => {
        this.setState({ isFocus: true });
    };
    handleBlur = () => {
        this.setState((prevState) => ({
            isFocus: false,
            storeSlugExists: prevState.storeSlugExists === false ? null : prevState.storeSlugExists
        }));
    };
    render() {
        const { value, storeSlugExists, incorrectFormat, serverError } = this.state;
        const errors = storeSlugExists === true || incorrectFormat || serverError ? [] : undefined;
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.input },
                React.createElement(Input, { id: "slug", fullWidth: true, label: (React.createElement("span", null,
                        t.labelWebAddress,
                        ' ',
                        React.createElement("span", { className: styles.asterisk }, "*"))), value: value, onChange: this.handleChange, onFocus: this.handleFocus, onBlur: this.handleBlur, errors: errors }),
                storeSlugExists !== null && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: classNames(styles, 'light', {
                            green: !storeSlugExists,
                            red: storeSlugExists
                        }) }, storeSlugExists === true ? t.inUse : t.vacant),
                    React.createElement("div", { className: classNames(styles, 'hint', {
                            green: !storeSlugExists,
                            red: storeSlugExists
                        }) }, storeSlugExists === true ? t.oops : t.hoorah))),
                incorrectFormat && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: classNames(styles, 'light', 'red') }, "Oops"),
                    React.createElement("div", { className: classNames(styles, 'hint', 'red') }, t.incorrectFormat))),
                serverError && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: classNames(styles, 'light', 'red') }, "Oops"),
                    React.createElement("div", { className: classNames(styles, 'hint', 'red') }, t.serverError)))),
            React.createElement("div", { className: styles.domen },
                React.createElement("span", null, ".graame.com"))));
    }
}
export default ContextDecorator(InputSlug);
