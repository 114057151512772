import { commitMutation, graphql } from 'react-relay';
const mutation = graphql `
    mutation ResendEmailVerificationLinkMutation($input: VerifyEmailResend!) {
        resendEmailVerificationLink(input: $input) {
            success
        }
    }
`;
const commit = (params) => commitMutation(params.environment, {
    mutation,
    variables: params.variables,
    onCompleted: params.onCompleted,
    onError: params.onError
});
export default { commit };
