import { t } from '../../../../../translation/utils';
const translations = {
    en: {
        error: 'Error: ',
        close: 'Close.',
        somethingGoingWrong: 'Something going wrong :(',
        changeCardInfo: 'Change card info',
        successText: 'Card verified',
        productsPublished: 'Products published',
        monthlyPayment: 'Monthly payment',
        tableColumns: {
            cardTypeNumber: 'Card type & number',
            expirationDate: 'Expiration date',
            cardholderName: 'Cardholder name'
        }
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
