import { t } from '../../../../../../translation/utils';
const translations = {
    en: {
        somethingGoingWrong: 'Something going wrong :(',
        close: 'Close.',
        error: 'Error: ',
        productUpdated: 'Product updated!',
        validationError: 'Validation Error!',
        deliveryUpdated: 'Delivery updated!',
        productNotFound: 'Product not found'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
