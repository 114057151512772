import React, { Component } from 'react';
import { filter, find, findIndex, head, isEmpty, isNil, length, prepend, propEq, startsWith, toLower } from 'ramda';
import debounce from 'lodash.debounce';
import { Icon } from '../../Icon';
import { Input } from '../Input';
import styles from './Select.module.scss';
import { classNames } from '../../../utils';
const maxItemsCount = 5;
const itemHeight = 24;
class Select extends Component {
    static defaultProps = {
        onClick: () => {
        },
        isMobile: false,
        forForm: false,
        forSearch: false,
        forAutocomlete: false,
        fullWidth: false,
        transparent: false
    };
    button = null;
    itemsWrap = null;
    items = null;
    clickPlane = null;
    constructor(props) {
        super(props);
        const { withEmpty, activeItem, withInput, items } = props;
        const newItems = !isNil(withEmpty) ? prepend({
            id: '',
            label: '-'
        }, items) : items;
        this.state = {
            isExpanded: false,
            items: withInput === true
                ? this.updateItems(activeItem ? activeItem.label : '')
                : newItems,
            searchValue: '',
            inputValue: withInput === true && activeItem ? activeItem.label : undefined,
            isFocusInput: false,
            hoverItem: undefined,
            isOpenItems: false
        };
        if (process.env.BROWSER) {
            window.addEventListener('click', this.handleToggleExpand);
            window.addEventListener('keydown', this.handleKeydown);
        }
        this.resetSearchValue = debounce(this.resetSearchValue, 1000);
    }
    componentDidUpdate(prevProps, prevState) {
        const { isExpanded, items: stateItems, inputValue } = this.state;
        if (prevState.isExpanded !== isExpanded &&
            isExpanded &&
            prevProps.activeItem) {
            this.handleAutoScroll(this.getIndexFromItems(prevProps.activeItem, stateItems));
        }
        const { withInput, activeItem, items } = this.props;
        if (withInput === true && activeItem && !prevProps.activeItem) {
            this.updateInputValue(activeItem.label);
        }
        if (withInput !== true &&
            JSON.stringify(items) !== JSON.stringify(prevProps.items)) {
            if (prevProps.withEmpty === true) {
                this.updateStateItems(prepend({
                    id: '',
                    label: '-'
                }, items));
            }
            else {
                this.updateStateItems(items);
            }
        }
        if (withInput === true &&
            JSON.stringify(items) !== JSON.stringify(prevProps.items)) {
            this.updateInputValue(inputValue || '');
        }
    }
    componentWillUnmount() {
        if (process.env.BROWSER) {
            window.removeEventListener('click', this.handleToggleExpand);
            window.removeEventListener('keydown', this.handleKeydown);
        }
    }
    getIndexFromItems = (item, items) => (item ? findIndex(propEq(item.id, 'id'))(items) : -1);
    updateStateItems = (items) => {
        this.setState({
            items
        });
    };
    updateInputValue = (value) => {
        this.setState({
            inputValue: value,
            items: this.updateItems(value)
        });
    };
    handleKeydown = (event) => {
        const { withInput } = this.props;
        if (this.state.isExpanded && withInput !== true) {
            event.preventDefault();
            const { activeItem, onSelect } = this.props;
            const { items } = this.state;
            if (event.key === 'Backspace') {
                onSelect({
                    id: '',
                    label: ''
                });
                return;
            }
            if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
                const activeItemIdx = this.getIndexFromItems(activeItem, items);
                if (event.key === 'ArrowDown') {
                    // click down
                    const newActiveItemIdx = activeItemIdx === length(items) - 1 ? 0 : activeItemIdx + 1;
                    this.handleAutoScroll(newActiveItemIdx);
                    onSelect(items[newActiveItemIdx]);
                }
                if (event.key === 'ArrowUp') {
                    // click up
                    const newActiveItemIdx = activeItemIdx === 0 ? length(items) - 1 : activeItemIdx - 1;
                    this.handleAutoScroll(newActiveItemIdx);
                    onSelect(items[newActiveItemIdx]);
                }
            }
            this.setState((prevState) => ({
                searchValue: `${prevState.searchValue}${event.key}`
            }), this.handleKeyActiveItem);
            this.resetSearchValue();
            if (event.key === 'Escape' || event.key === 'Enter') {
                this.setState({ isExpanded: false });
            }
        }
        if (withInput === true) {
            const { activeItem, onSelect } = this.props;
            const { items, hoverItem, isOpenItems } = this.state;
            if (isOpenItems && (event.key === 'ArrowDown' || event.key === 'ArrowUp')) {
                const activeItemIdx = this.getIndexFromItems(hoverItem || activeItem, items);
                if (event.key === 'ArrowDown') {
                    // click down
                    const newActiveItemIdx = activeItemIdx === length(items) - 1 ? 0 : activeItemIdx + 1;
                    this.handleAutoScroll(newActiveItemIdx);
                    const newItem = items[newActiveItemIdx];
                    this.setState({ hoverItem: newItem });
                }
                if (event.key === 'ArrowUp') {
                    // click up
                    const newActiveItemIdx = activeItemIdx === 0 ? length(items) - 1 : activeItemIdx - 1;
                    this.handleAutoScroll(newActiveItemIdx);
                    const newItem = items[newActiveItemIdx];
                    this.setState({ hoverItem: newItem });
                }
            }
            if (event.key === 'Enter' && hoverItem) {
                this.setState({
                    inputValue: hoverItem.label,
                    hoverItem: undefined,
                    isOpenItems: false,
                    items: this.updateItems(hoverItem.label)
                }, () => {
                    onSelect(hoverItem);
                });
            }
        }
    };
    handleKeyActiveItem = () => {
        const { onSelect } = this.props;
        const { searchValue, items } = this.state;
        const filteredItems = filter((item) => new RegExp(`^${searchValue}`).test(toLower(item.label)), items);
        if (!isEmpty(filteredItems)) {
            const idx = this.getIndexFromItems(head(filteredItems), items);
            this.handleAutoScroll(idx);
            onSelect(items[idx]);
        }
    };
    resetSearchValue = () => {
        this.setState({ searchValue: '' });
    };
    handleAutoScroll = (idx, behavior) => {
        const visibleHeight = itemHeight * maxItemsCount;
        let itemsWrapScroll = 0;
        if (!isNil(this.itemsWrap)) {
            itemsWrapScroll = this.itemsWrap.scrollTop;
        }
        // new item is not included above
        if ((idx - 1) * itemHeight < itemsWrapScroll) {
            if (!isNil(this.itemsWrap) && !isNil(this.itemsWrap.scroll)) {
                this.itemsWrap.scroll({
                    top: idx * itemHeight,
                    behavior
                });
            }
        }
        // new item is not included below
        if ((idx + 1) * itemHeight > itemsWrapScroll + visibleHeight) {
            if (!isNil(this.itemsWrap) && !isNil(this.itemsWrap.scroll)) {
                this.itemsWrap.scroll({
                    top: (idx + 1) * itemHeight - visibleHeight,
                    behavior
                });
            }
        }
    };
    handleToggleExpand = (e) => {
        const { onClick, withInput, activeItem } = this.props;
        const isButtonClick = this.button && this.button.contains(e.target);
        const isItemsWrap = this.itemsWrap && this.itemsWrap.contains(e.target);
        const isItems = this.items && this.items.contains(e.target);
        const isСlickPlane = this.clickPlane && this.clickPlane.contains(e.target);
        if (withInput === true && !isButtonClick && !isItems && !isСlickPlane) {
            this.setState({
                isOpenItems: false,
                inputValue: activeItem ? activeItem.label : '',
                hoverItem: undefined,
                items: this.updateItems(activeItem ? activeItem.label : '')
            });
            return;
        }
        if (isButtonClick && !isItems && !isItemsWrap) {
            this.setState((prevState) => ({ isExpanded: !prevState.isExpanded }), onClick);
            return;
        }
        if ((!isButtonClick && !isItems) || isItemsWrap) {
            this.setState({ isExpanded: false });
        }
    };
    handleItemClick = (e) => {
        const { onSelect, withInput, activeItem } = this.props;
        const { items } = this.state;
        const result = find(propEq(e.target.id, 'id'), items);
        if (onSelect && !isNil(result)) {
            if (withInput === true) {
                this.setState({
                    inputValue: result.label,
                    isOpenItems: false,
                    items: this.updateItems(result.label)
                });
            }
            if (activeItem && activeItem.id === result.id) {
                return;
            }
            onSelect(result);
        }
    };
    handleChangeInput = (e) => {
        const { value } = e.target;
        const { activeItem } = this.props;
        const items = this.updateItems(value);
        this.setState({
            inputValue: value,
            items,
            isOpenItems: true,
            hoverItem: length(items) === 1 ? head(items) : undefined
        }, () => {
            const activeItemIdx = this.getIndexFromItems(activeItem, this.updateItems(value));
            this.handleAutoScroll(activeItemIdx);
        });
    };
    handleFocusInput = () => {
        this.setState({
            isFocusInput: true,
            isOpenItems: true
        });
    };
    handleBlurInput = () => {
        this.setState({
            isFocusInput: false,
            isExpanded: false
        });
    };
    updateItems = (value) => {
        const { items } = this.props;
        if (value === '') {
            return items;
        }
        const newItems = filter((item) => startsWith(toLower(value), toLower(item.label)), items);
        return newItems;
    };
    render() {
        const { transparent, label, activeItem, forForm, fullWidth, forSearch, forAutocomlete, containerStyle, dataTest, isBirthdate, isMobile, renderSelectItem, withInput, maxItemsHeight } = this.props;
        const { isExpanded, items, inputValue, isFocusInput, hoverItem, isOpenItems } = this.state;
        return (React.createElement("div", { ref: (node) => {
                this.button = node;
            }, className: classNames(styles, 'container', {
                forForm,
                forSearch,
                forAutocomlete,
                fullWidth,
                isBirthdate,
                isExpanded
            }), style: containerStyle, "data-test": dataTest },
            ((!isNil(label) && !isBirthdate) ||
                !(!isNil(isBirthdate) && activeItem)) && (React.createElement("div", { className: classNames(styles, 'label', {
                    labelFloat: withInput === true
                        ? (inputValue !== null && inputValue !== '') ||
                            isFocusInput ||
                            isOpenItems
                        : activeItem || isExpanded
                }) }, label)),
            React.createElement("div", { className: classNames(styles, 'wrap', { transparent }) },
                activeItem && activeItem.label && withInput !== true && (React.createElement("div", { className: styles.selected }, activeItem.label)),
                withInput === true && (React.createElement("div", { className: styles.inputWrap },
                    React.createElement(Input, { fullWidth: true, value: inputValue || '', onChange: this.handleChangeInput, onFocus: this.handleFocusInput, onBlur: this.handleBlurInput, dataTest: `${dataTest}Input`, onKeyDown: this.handleKeydown, onClick: this.handleToggleExpand }))),
                React.createElement("div", { className: classNames(styles, 'icon', {
                        rotateIcon: withInput === true ? isFocusInput : isExpanded
                    }) },
                    React.createElement(Icon, { type: "arrowExpand" })),
                React.createElement("div", { ref: (node) => {
                        this.items = node;
                    }, className: classNames(styles, 'items', {
                        hidden: withInput === true ? !isOpenItems || isEmpty(items) : !isExpanded,
                        isMobile
                    }) },
                    React.createElement("div", { ref: (node) => {
                            this.itemsWrap = node;
                        }, className: styles.itemsWrap, onClick: this.handleItemClick, onKeyDown: () => {
                        }, role: "button", tabIndex: 0, style: {
                            maxHeight: maxItemsHeight != null
                                ? `${maxItemsHeight}rem`
                                : `${maxItemsCount * itemHeight / 8}rem`
                        } }, items?.map((item) => {
                        const { id } = item;
                        return renderSelectItem ? (renderSelectItem(item)) : (React.createElement("div", { key: `${id}-${item.label}`, id: id, className: classNames(styles, 'item', {
                                active: withInput === true && hoverItem
                                    ? hoverItem.id === id
                                    : activeItem && activeItem.id === id
                            }), "data-test": `${dataTest}_item${label != null ? `_${label}` : ''}_${item.label}` }, item.label));
                    }))),
                React.createElement("div", { ref: (node) => {
                        this.clickPlane = node;
                    }, className: styles.clickPlane })),
            (!isNil(forForm) || !isNil(forSearch) || isBirthdate) &&
                withInput !== true && React.createElement("div", { className: styles.hr })));
    }
}
export default Select;
