import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { isNil, pathOr } from 'ramda';
import { CreateDisputeMutation, SetOrderStatusCompleteMutation } from '../../../relay/mutations';
import { withShowAlert } from '../../../components/Alerts/AlertContext';
import { Modal } from '../../../components/Modal';
import { Button, Textarea } from '../../../components/common';
import { Confirmation } from '../../../components/Confirmation';
import styles from './ManageOrderBlockForUser.module.scss';
import t from './i18n';
class ManageOrderBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCompleteInProgress: false,
            isCreateDisputeInProgress: false,
            showModal: false,
            isDisputeShowModal: false,
            comment: ''
        };
    }
    completeOrder = () => {
        this.setState({
            isCompleteInProgress: true,
            showModal: false
        });
        const params = {
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    orderSlug: this.props.orderSlug,
                    committerRole: 'CUSTOMER'
                }
            },
            environment: this.props.environment,
            onCompleted: (response, errors) => {
                this.setState({ isCompleteInProgress: false });
                this.props.onOrderComplete(!errors);
            },
            onError: () => {
                this.setState({ isCompleteInProgress: false });
                this.props.onOrderComplete(false);
            }
        };
        SetOrderStatusCompleteMutation.commit(params);
    };
    createDispute = () => {
        const { orderId, orderSlug } = this.props;
        const { comment } = this.state;
        this.setState({ isCreateDisputeInProgress: true });
        CreateDisputeMutation({
            environment: this.props.environment,
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    orderSlug,
                    comment
                }
            },
            updater: (relayStore) => {
                const orderProxy = relayStore.get(orderId);
                orderProxy.setValue('DISPUTE', 'state');
            }
        })
            .then(() => true)
            .finally(() => {
            this.setState({
                isCreateDisputeInProgress: false,
                isDisputeShowModal: false
            });
        })
            .catch(() => {
            this.props.showAlert({
                type: 'danger',
                text: t.somethingIsGoingWrong,
                link: { text: t.ok }
            });
        });
    };
    handleCloseModal = () => {
        this.setState({ showModal: false });
    };
    handleDisputModalClose = () => {
        this.setState({ isDisputeShowModal: false });
    };
    handleCommentChanged = (e) => {
        if (!isNil(e)) {
            const value = pathOr('', ['target', 'value'], e);
            this.setState({ comment: value });
        }
    };
    render() {
        const { isCompleteInProgress, showModal, isCreateDisputeInProgress, isDisputeShowModal, comment } = this.state;
        return (React.createElement("div", { className: styles.container },
            React.createElement(Modal, { showModal: isDisputeShowModal, onClose: this.handleDisputModalClose },
                React.createElement("div", { className: styles.sendOrderModal },
                    React.createElement("div", { className: styles.title }, t.createDispute),
                    React.createElement("div", { className: styles.description }, t.writeComment),
                    React.createElement("div", { className: styles.inputWrapperComment },
                        React.createElement(Textarea, { fullWidth: true, id: "disput-modal-comment", label: t.labelComment, onChange: this.handleCommentChanged, value: comment })),
                    React.createElement("div", { className: styles.disputButtonWrapper },
                        React.createElement(Button, { big: true, fullWidth: true, disabled: !comment, isLoading: isCreateDisputeInProgress, onClick: this.createDispute }, t.createDispute)))),
            React.createElement(Confirmation, { showModal: showModal, onClose: this.handleCloseModal, title: t.title, description: "", onCancel: this.handleCloseModal, onConfirm: this.completeOrder, confirmText: t.confirmText, cancelText: t.cancelText }),
            this.props.isAbleToSend && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles.buttonWrapper },
                    React.createElement(Button, { big: true, fullWidth: true, isLoading: isCompleteInProgress, onClick: () => {
                            this.setState({ showModal: true });
                        } }, t.complete)),
                React.createElement("div", { className: styles.buttonWrapper },
                    React.createElement(Button, { big: true, fullWidth: true, wireframe: true, isLoading: isCreateDisputeInProgress, onClick: () => {
                            this.setState({ isDisputeShowModal: true });
                        } }, t.dispute))))));
    }
}
export default withShowAlert(ManageOrderBlock);
