import React, { PureComponent } from 'react';
import { isEmpty } from 'ramda';
import CloseIcon from './svg/close_icon.svg';
import styles from './NotificationBlock.module.scss';
import { classNames } from '../../utils';
class NotificationBlock extends PureComponent {
    static defaultProps = {
        isStatic: false,
        isDisappearing: false,
        longText: false,
        hideCloseButton: false
    };
    render() {
        const { title, type, isDisappearing, isStatic, text, longText, link, onClick, hideCloseButton } = this.props;
        return (React.createElement("div", { className: classNames(styles, 'container', {
                disappering: isDisappearing,
                default: type === 'default',
                success: type === 'success',
                danger: type === 'danger',
                warning: type === 'warning'
            }) },
            !hideCloseButton ? (React.createElement("button", { name: "alertCloseButton", onClick: () => {
                }, className: styles.closeButton },
                React.createElement(CloseIcon, null))) : null,
            !isStatic ? (React.createElement("div", { className: styles.titleContainer },
                React.createElement("div", { className: styles.title }, title))) : null,
            React.createElement("div", { className: classNames(styles, 'alertMessage', { longText }) }, text),
            React.createElement("div", { className: styles.link }, !isEmpty(link.text) ? (React.createElement("button", { className: styles.buttonLink, onClick: onClick },
                React.createElement("span", { className: styles.link }, link.text))) : null)));
    }
}
export default NotificationBlock;
