export default (categoryId) => {
    if (window) {
        (window.rrApiOnReady = window.rrApiOnReady || []).push(() => {
            try {
                // rrApi.categoryView(categoryId);
            }
            catch (e) {
                //
            }
        });
    }
};
