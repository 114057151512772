import React, { PureComponent } from 'react';
import styles from './CartProductAttribute.module.scss';
class CartProductAttribute extends PureComponent {
    render() {
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.title }, this.props.title),
            React.createElement("div", { className: styles.value }, this.props.value)));
    }
}
export default CartProductAttribute;
