/**
 * @generated SignedSource<<bb2ff9ae652bd297b42772107c4b6b18>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "productID"
    }, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "quantity"
    }, v2 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "shippingId"
    }, v3 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "variantId"
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawId",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "phone",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
    }, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
    }, v10 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
    }, v11 = {
        "alias": null,
        "args": null,
        "concreteType": "UserDeliveryAddress",
        "kind": "LinkedField",
        "name": "deliveryAddressesFull",
        "plural": true,
        "selections": [
            (v4 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "Address",
                "kind": "LinkedField",
                "name": "address",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "country",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "countryCode",
                        "storageKey": null
                    },
                    (v10 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "administrativeAreaLevel1",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "administrativeAreaLevel2",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "locality",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "political",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "postalCode",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "route",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "streetNumber",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "placeId",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isPriority",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v12 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "productID"
        }
    ], v13 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "price",
        "storageKey": null
    }, v14 = {
        "alias": null,
        "args": [
            {
                "kind": "Variable",
                "name": "productId",
                "variableName": "variantId"
            },
            {
                "kind": "Variable",
                "name": "quantity",
                "variableName": "quantity"
            },
            {
                "kind": "Variable",
                "name": "shippingId",
                "variableName": "shippingId"
            }
        ],
        "concreteType": "BuyNowCheckout",
        "kind": "LinkedField",
        "name": "calculateBuyNow",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "product",
                "plural": false,
                "selections": [
                    (v4 /*: any*/),
                    (v5 /*: any*/)
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "couponsDiscounts",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCost",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCostWithoutDiscounts",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deliveryCost",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "subtotal",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "subtotalWithoutDiscounts",
                "storageKey": null
            },
            (v13 /*: any*/)
        ],
        "storageKey": null
    }, v15 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
    }, v16 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lang",
        "storageKey": null
    }, v17 = [
        (v15 /*: any*/),
        (v16 /*: any*/)
    ], v18 = {
        "alias": null,
        "args": null,
        "concreteType": "Translation",
        "kind": "LinkedField",
        "name": "name",
        "plural": true,
        "selections": (v17 /*: any*/),
        "storageKey": null
    }, v19 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rating",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "routes_BuyNow_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "UserData_me"
                        },
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        (v9 /*: any*/),
                        (v11 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v12 /*: any*/),
                    "concreteType": "BaseProduct",
                    "kind": "LinkedField",
                    "name": "baseProduct",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "BuyNow_baseProduct"
                        }
                    ],
                    "storageKey": null
                },
                (v14 /*: any*/)
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v3 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/)
            ],
            "kind": "Operation",
            "name": "routes_BuyNow_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        (v9 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "avatar",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "WizardStore",
                            "kind": "LinkedField",
                            "name": "wizardStore",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "completed",
                                    "storageKey": null
                                },
                                (v4 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Store",
                            "kind": "LinkedField",
                            "name": "myStore",
                            "plural": false,
                            "selections": [
                                (v5 /*: any*/),
                                (v4 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v11 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v12 /*: any*/),
                    "concreteType": "BaseProduct",
                    "kind": "LinkedField",
                    "name": "baseProduct",
                    "plural": false,
                    "selections": [
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "categoryId",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isShippingAvailable",
                            "storageKey": null
                        },
                        (v18 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Translation",
                            "kind": "LinkedField",
                            "name": "shortDescription",
                            "plural": true,
                            "selections": (v17 /*: any*/),
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Translation",
                            "kind": "LinkedField",
                            "name": "longDescription",
                            "plural": true,
                            "selections": (v17 /*: any*/),
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Store",
                            "kind": "LinkedField",
                            "name": "store",
                            "plural": false,
                            "selections": [
                                (v5 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Translation",
                                    "kind": "LinkedField",
                                    "name": "name",
                                    "plural": true,
                                    "selections": [
                                        (v16 /*: any*/),
                                        (v15 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v19 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "logo",
                                    "storageKey": null
                                },
                                (v4 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v19 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "currency",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Variants",
                            "kind": "LinkedField",
                            "name": "variants",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Product",
                                    "kind": "LinkedField",
                                    "name": "all",
                                    "plural": true,
                                    "selections": [
                                        (v4 /*: any*/),
                                        (v5 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "photoMain",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "additionalPhotos",
                                            "storageKey": null
                                        },
                                        (v13 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "preOrder",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "preOrderDays",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "cashback",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "discount",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "quantity",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ProdAttrValue",
                                            "kind": "LinkedField",
                                            "name": "attributes",
                                            "plural": true,
                                            "selections": [
                                                (v10 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "metaField",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Attribute",
                                                    "kind": "LinkedField",
                                                    "name": "attribute",
                                                    "plural": false,
                                                    "selections": [
                                                        (v4 /*: any*/),
                                                        (v18 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "AttributeMetaField",
                                                            "kind": "LinkedField",
                                                            "name": "metaField",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "values",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "uiElement",
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                (v14 /*: any*/)
            ]
        },
        "params": {
            "cacheID": "905fd2d49256fb31192830ea62ece7a9",
            "id": null,
            "metadata": {},
            "name": "routes_BuyNow_Query",
            "operationKind": "query",
            "text": "query routes_BuyNow_Query(\n  $productID: Int!\n  $variantId: Int!\n  $quantity: Int!\n  $shippingId: Int\n) {\n  me {\n    ...UserData_me\n    id\n    rawId\n    phone\n    email\n    firstName\n    lastName\n    deliveryAddressesFull {\n      id\n      address {\n        country\n        countryCode\n        value\n        administrativeAreaLevel1\n        administrativeAreaLevel2\n        locality\n        political\n        postalCode\n        route\n        streetNumber\n        placeId\n      }\n      isPriority\n    }\n  }\n  baseProduct(id: $productID) {\n    ...BuyNow_baseProduct\n    id\n  }\n  calculateBuyNow(productId: $variantId, quantity: $quantity, shippingId: $shippingId) {\n    product {\n      id\n      rawId\n    }\n    couponsDiscounts\n    totalCost\n    totalCostWithoutDiscounts\n    totalCount\n    deliveryCost\n    subtotal\n    subtotalWithoutDiscounts\n    price\n  }\n}\n\nfragment BuyNow_baseProduct on BaseProduct {\n  id\n  rawId\n  categoryId\n  isShippingAvailable\n  name {\n    text\n    lang\n  }\n  shortDescription {\n    text\n    lang\n  }\n  longDescription {\n    text\n    lang\n  }\n  store {\n    rawId\n    name {\n      lang\n      text\n    }\n    rating\n    logo\n    id\n  }\n  rating\n  currency\n  variants {\n    all {\n      id\n      rawId\n      photoMain\n      additionalPhotos\n      price\n      preOrder\n      preOrderDays\n      cashback\n      discount\n      quantity\n      attributes {\n        value\n        metaField\n        attribute {\n          id\n          name {\n            text\n            lang\n          }\n          metaField {\n            values\n            uiElement\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment UserData_me on User {\n  email\n  firstName\n  lastName\n  avatar\n  wizardStore {\n    completed\n    id\n  }\n  myStore {\n    rawId\n    id\n  }\n}\n"
        }
    };
})();
node.hash = "aae02d295fdf599df438d2bf8e0f6b2b";
export default node;
