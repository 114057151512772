import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { isEmpty, map, pathOr } from 'ramda';
import Goods from '../../Start/Goods';
import { SearchNoResults } from '../../../components/SearchNoResults';
import styles from './Showcase.module.scss';
import t from './i18n';
class Showcase extends PureComponent {
    render() {
        const { shop } = this.props;
        const mostViewedProducts = pathOr([], ['findMostViewedProducts', 'edges'], shop);
        const mostDiscountProducts = pathOr([], ['findMostDiscountProducts', 'edges'], shop);
        const discountProducts = map((item) => ({ ...item.node }), mostDiscountProducts);
        const viewedProducts = map((item) => ({ ...item.node }), mostViewedProducts);
        return (React.createElement("div", { className: styles.container },
            viewedProducts
                && !isEmpty(viewedProducts) && (React.createElement("div", { className: styles.item },
                React.createElement(Goods, { items: viewedProducts, title: t.mostPopular }))),
            discountProducts
                && !isEmpty(discountProducts) && (React.createElement("div", { className: styles.item },
                React.createElement(Goods, { items: discountProducts, title: t.sale }))),
            isEmpty(viewedProducts)
                && isEmpty(discountProducts) && React.createElement(SearchNoResults, null)));
    }
}
export default createFragmentContainer(Showcase, {
    shop: graphql `
			fragment Showcase_shop on Store {
				findMostViewedProducts(
					searchTerm: { options: { attrFilters: [] } }
					first: 30
				) {
					edges {
						node {
							rawId
							storeId
							name {
								lang
								text
							}
							store {
								name {
									lang
									text
								}
							}
							currency
							products(first: 1) {
								edges {
									node {
										id
										rawId
										discount
										photoMain
										cashback
										price
										customerPrice {
											price
											currency
										}
									}
								}
							}
							rating
						}
					}
				}
				findMostDiscountProducts(
					searchTerm: { options: { attrFilters: [] } }
					first: 30
				) {
					edges {
						node {
							rawId
							storeId
							name {
								lang
								text
							}
							store {
								name {
									lang
									text
								}
							}
							currency
							products(first: 1) {
								edges {
									node {
										id
										rawId
										discount
										photoMain
										cashback
										price
										customerPrice {
											price
											currency
										}
									}
								}
							}
							rating
						}
					}
				}
			}
    `
});
