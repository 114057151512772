/**
 * @generated SignedSource<<398361892ef09768d0824251d94b8943>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawId",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "discount",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "photoMain",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cashback",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "price",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
    }, v9 = {
        "alias": null,
        "args": null,
        "concreteType": "CustomerPrice",
        "kind": "LinkedField",
        "name": "customerPrice",
        "plural": false,
        "selections": [
            (v7 /*: any*/),
            (v8 /*: any*/)
        ],
        "storageKey": null
    }, v10 = [
        {
            "kind": "Literal",
            "name": "visibility",
            "value": "published"
        }
    ], v11 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "storeId",
        "storageKey": null
    }, v12 = {
        "alias": null,
        "args": null,
        "concreteType": "Translation",
        "kind": "LinkedField",
        "name": "name",
        "plural": true,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lang",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v13 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rating",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "fetchProducts_Query",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Product",
                    "kind": "LinkedField",
                    "name": "products",
                    "plural": true,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v9 /*: any*/),
                        {
                            "alias": null,
                            "args": (v10 /*: any*/),
                            "concreteType": "BaseProduct",
                            "kind": "LinkedField",
                            "name": "baseProduct",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                (v11 /*: any*/),
                                (v12 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Store",
                                    "kind": "LinkedField",
                                    "name": "store",
                                    "plural": false,
                                    "selections": [
                                        (v12 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v8 /*: any*/),
                                (v13 /*: any*/)
                            ],
                            "storageKey": "baseProduct(visibility:\"published\")"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "fetchProducts_Query",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Product",
                    "kind": "LinkedField",
                    "name": "products",
                    "plural": true,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v9 /*: any*/),
                        {
                            "alias": null,
                            "args": (v10 /*: any*/),
                            "concreteType": "BaseProduct",
                            "kind": "LinkedField",
                            "name": "baseProduct",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                (v11 /*: any*/),
                                (v12 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Store",
                                    "kind": "LinkedField",
                                    "name": "store",
                                    "plural": false,
                                    "selections": [
                                        (v12 /*: any*/),
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v8 /*: any*/),
                                (v13 /*: any*/),
                                (v2 /*: any*/)
                            ],
                            "storageKey": "baseProduct(visibility:\"published\")"
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "375d5a6bb0d2fd45f3832a28ef74a836",
            "id": null,
            "metadata": {},
            "name": "fetchProducts_Query",
            "operationKind": "query",
            "text": "query fetchProducts_Query(\n  $input: GetProductsInput!\n) {\n  products(input: $input) {\n    id\n    rawId\n    discount\n    photoMain\n    cashback\n    price\n    customerPrice {\n      price\n      currency\n    }\n    baseProduct(visibility: \"published\") {\n      rawId\n      storeId\n      name {\n        lang\n        text\n      }\n      store {\n        name {\n          lang\n          text\n        }\n        id\n      }\n      currency\n      rating\n      id\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "710fef96ff68f4ed6841cc636a922506";
export default node;
