import { isEmpty, keys, pathOr, values } from 'ramda';
// first arg is relayErrors - standart error object
// second arg is alertShow callback for show alerts in common cases
// third arg is callback for handling validation errors
export const errorsHandler = (relayErrors, // ProcessedErrorType,
alertHandler, callback) => {
    const displayAlert = (errorName) => {
        alertHandler({
            type: 'danger',
            text: `Error: "${errorName}"`,
            link: { text: 'Close.' }
        });
    };
    const handleErrorCode = (code) => {
        const status = pathOr(null, [code, 'status'], relayErrors);
        displayAlert(status);
        if (callback) {
            callback();
        }
    };
    if (!relayErrors) {
        return;
    }
    switch (keys(relayErrors)[0]) {
        case '100': {
            const { messages } = relayErrors['100'];
            if (!isEmpty(messages) && callback) {
                callback(messages);
                const [errorMessage] = values(messages);
                if (!isEmpty(errorMessage)) {
                    displayAlert(errorMessage);
                }
            }
            else {
                handleErrorCode('100');
            }
            break;
        }
        case '200':
            handleErrorCode('200');
            break;
        case '300':
            handleErrorCode('300');
            break;
        case '400':
            handleErrorCode('400');
            break;
        default: {
            break;
        }
    }
};
