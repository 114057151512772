/**
 * @generated SignedSource<<f9a7180b7fa26ca5621a9a0981742ec6>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "IndiaBillingInfo",
            "kind": "LinkedField",
            "name": "updateIndiaBillingInfo",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rawId",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "storeId",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bankName",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "branchName",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "swiftBic",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "taxId",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "correspondentAccount",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currentAccount",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "personalAccount",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "beneficiaryFullName",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UpdateIndiaBillingInfoMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UpdateIndiaBillingInfoMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "febdecb04e20410c6498da2eed0e4617",
            "id": null,
            "metadata": {},
            "name": "UpdateIndiaBillingInfoMutation",
            "operationKind": "mutation",
            "text": "mutation UpdateIndiaBillingInfoMutation(\n  $input: UpdateIndiaBillingInfoInput!\n) {\n  updateIndiaBillingInfo(input: $input) {\n    id\n    rawId\n    storeId\n    bankName\n    branchName\n    swiftBic\n    taxId\n    correspondentAccount\n    currentAccount\n    personalAccount\n    beneficiaryFullName\n  }\n}\n"
        }
    };
})();
node.hash = "231b3bdb7a4420bc30e7b78b81ab254f";
export default node;
