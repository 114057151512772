import React, { Component } from 'react';
import { Icon } from '../Icon';
import styles from './MobileSidebar.module.scss';
import { classNames } from '../../utils';
class MobileSidebar extends Component {
    static defaultProps = {
        left: false,
        title: ''
    };
    handleClick = (evt) => {
        const { onClose } = this.props;
        const { id } = evt.target;
        if (id === 'overlay') {
            onClose();
        }
    };
    render() {
        const { isOpen, left, onClose, title } = this.props;
        return (React.createElement("div", { id: "overlay", onClick: this.handleClick, onKeyPress: () => {
            }, role: "presentation", className: classNames(styles, 'container', isOpen ? 'toggled' : '') },
            React.createElement("aside", { className: classNames(styles, 'sidebar', {
                    left,
                    toggled: isOpen
                }) },
                React.createElement("header", { className: styles.header },
                    React.createElement("span", { id: "close", onClick: onClose, onKeyPress: () => {
                        }, role: "button", className: styles.close, tabIndex: -1 },
                        React.createElement(Icon, { type: "cross", size: 24 })),
                    React.createElement("h3", null, title)),
                this.props.children)));
    }
}
export default MobileSidebar;
