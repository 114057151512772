/**
 * @generated SignedSource<<cd68cecd3e11600598392c615dc9d47c>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "BaseProduct",
            "kind": "LinkedField",
            "name": "deactivateBaseProduct",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "DeactivateBaseProductMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "DeactivateBaseProductMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "8aa9ea01468045555248d419e3efee0c",
            "id": null,
            "metadata": {},
            "name": "DeactivateBaseProductMutation",
            "operationKind": "mutation",
            "text": "mutation DeactivateBaseProductMutation(\n  $input: DeactivateBaseProductInput!\n) {\n  deactivateBaseProduct(input: $input) {\n    id\n  }\n}\n"
        }
    };
})();
node.hash = "a2da5ee6e9a49a21106f4a14e5eb3563";
export default node;
