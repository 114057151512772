import { commitMutation, graphql } from 'react-relay';
const mutation = graphql `
	mutation SetOrderStatusCompleteMutation($input: OrderStatusCompleteInput!) {
		setOrderStatusComplete(input: $input) {
			id
			state
		}
	}
`;
const commit = (params) => commitMutation(params.environment, {
    mutation,
    variables: params.variables,
    onCompleted: params.onCompleted,
    onError: params.onError
});
export default { commit };
