import React from 'react';
import { Icon } from '../../Icon';
import t from './i18n';
import styles from './AuthButtons.module.scss';
const AuthButtons = ({ onOpenModal }) => (React.createElement("div", { className: styles.container },
    React.createElement("div", { className: styles.login, onClick: () => onOpenModal(true), onKeyDown: () => {
        }, role: "button", tabIndex: 0 },
        React.createElement(Icon, { type: "login", size: 24 })),
    React.createElement("div", { className: styles.signUpButton, onClick: () => onOpenModal(true), onKeyDown: () => {
        }, role: "button", tabIndex: 0, "data-test": "headerSignUpButton" }, t.signUp),
    React.createElement("div", { className: styles.signInButton, onClick: () => onOpenModal(false), onKeyDown: () => {
        }, role: "button", tabIndex: 0, "data-test": "headerSignInButton" },
        React.createElement("strong", null, t.signIn))));
export default AuthButtons;
