import React, { PureComponent } from 'react';
import { map } from 'ramda';
import styles from './Tabs.module.scss';
import { classNames } from '../../../../../../utils';
class Tabs extends PureComponent {
    render() {
        const { tabs, onChangeTab, activeTab } = this.props;
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.head }, map((item) => (React.createElement("div", { key: item.id, className: classNames(styles, 'tab', { active: item.id === activeTab }), onClick: () => {
                    onChangeTab(item.id);
                }, onKeyDown: () => {
                }, role: "button", tabIndex: 0, "data-test": `${item.id}Tab` }, item.label)), tabs)),
            React.createElement("div", { className: styles.body }, this.props.children)));
    }
}
export default Tabs;
