/**
 * @generated SignedSource<<d03159c2b38e2286fd9f39e8a6cee580>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawId",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "App_me",
        "selections": [
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Profile_me"
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ManageStoreMenu_me"
            },
            (v0 /*: any*/),
            (v1 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "avatar",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "WizardStore",
                "kind": "LinkedField",
                "name": "wizardStore",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "completed",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "Store",
                "kind": "LinkedField",
                "name": "myStore",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    (v1 /*: any*/)
                ],
                "storageKey": null
            }
        ],
        "type": "User",
        "abstractKey": null
    };
})();
node.hash = "2b1587b433eb5eec59c5a7e239c1e837";
export default node;
