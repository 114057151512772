import React from 'react';
import { map } from 'ramda';
import { Row, Col } from '../../layout';
import styles from './StartSellingTryStoriqa.module.scss';
const itemsEn = [
    {
        id: '1',
        title: 'Personal manager',
        text: 'We provide you with a&nbsp;personal manager that will help you craft high quality item descriptions as well as answer all of&nbsp;your questions to&nbsp;support you every step of&nbsp;the&nbsp;way.'
    },
    {
        id: '2',
        title: 'Payments through cards and cash',
        text: 'All shops accepts payments through cards and cash for their products line.'
    },
    {
        id: '3',
        title: '3 months free of&nbsp;promotional credits and&nbsp;product support.<br />Seller Feedback is prioritized and gets rewawded in&nbsp;credits!',
        text: 'We incentivise seller feedback, if&nbsp;you take the&nbsp;time to&nbsp;provide&nbsp;us input into your seller experience you are rewarded with credits that you are able to&nbsp;use to&nbsp;list products for&nbsp;free.'
    }
];
const itemsRu = [
    {
        id: '1',
        title: 'Персональный менеджер',
        text: 'Мы предоставим вам персонального менеджера с&nbsp;опытом в&nbsp;продажах и&nbsp;маркетинге, который поможет сделать качественное описание магазина и&nbsp;товаров, ответит на&nbsp;все вопросы и&nbsp;подскажет, как увеличить продажи со&nbsp;Graame.'
    },
    {
        id: '2',
        title: 'Расчеты в&nbsp;традиционных валютах и&nbsp;криптовалютах',
        text: 'Вы знали, что сотни тысяч держателей криптовалют по&nbsp;всему миру готовы платить ими? Мы&nbsp;дадим вам возможность первыми зарабатывать в&nbsp;STQ, ETH, BTC и&nbsp;других популярных криптовалютах! Кроме&nbsp;того, платформа будет поддерживать и&nbsp;традиционные валюты, такие как&nbsp;USD, RUR или&nbsp;SGD.'
    },
    {
        id: '3',
        title: '3 месяца бесплатно. Мы вам новых клиентов&nbsp;— вы&nbsp;нам обратную связь',
        text: 'Хотите получить канал продаж, где вы&nbsp;расплачиваетесь не&nbsp;деньгами, а&nbsp;информацией? В&nbsp;течение трех&nbsp;месяцев наши первые магазины будут продавать товары без комиссий. В&nbsp;обмен мы&nbsp;просим лишь обратную связь и&nbsp;30&nbsp;минут вашего времени в&nbsp;день.'
    }
];
const StartSellingTryStoriqa = ({ lang }) => (React.createElement("div", { className: styles.container },
    React.createElement("h2", { className: styles.title, dangerouslySetInnerHTML: {
            __html: lang === 'hi' ? 'Почему стоит начать сейчас?' : 'Why give Graame a shot?'
        } }),
    React.createElement("p", { className: styles.subtitle, dangerouslySetInnerHTML: {
            __html: lang === 'hi'
                ? 'Вместе с персональным менеджером вы&nbsp;выстроите персональную траекторию по&nbsp;маркетингу, увеличению продаж и&nbsp;поддержке клиентов.'
                : 'We deliver high quality services and support to&nbsp;our sellers, including marketing assistance and&nbsp;sales advice.'
        } }),
    React.createElement(Row, null,
        React.createElement("div", { className: styles.items }, map(item => (React.createElement(Col, { key: item.id, size: 12 },
            React.createElement("div", { className: styles.item },
                React.createElement("h4", { className: styles.itemTitle, dangerouslySetInnerHTML: { __html: item.title } }),
                React.createElement("p", { className: styles.itemText, dangerouslySetInnerHTML: { __html: item.text } })))), lang === 'hi' ? itemsRu : itemsEn)))));
export default StartSellingTryStoriqa;
