import React, { cloneElement, PureComponent } from 'react';
import { isNil, pathOr } from 'ramda';
import { Container } from '../../layout';
import { getNameText } from '../../utils';
import { Page } from '../../components/App';
import t from './i18n';
import StoreContext from './StoreContext';
import StoreHeader from './StoreHeader';
import styles from './Store.module.scss';
import { Collapse } from '../../components/Collapse';
const tabs = [
    {
        id: 'shop',
        title: t.shop,
        isNew: false,
        link: ''
    },
    {
        id: 'items',
        title: t.items,
        isNew: false,
        link: '/items'
    },
    // {
    //   id: 'reviews',
    //   title: 'Reviews',
    //   isNew: false
    // },
    // {
    //   id: 'actions',
    //   title: 'Actions',
    //   isNew: true
    // },
    {
        id: 'about',
        title: t.about,
        isNew: false,
        link: '/about'
    }
];
class Store extends PureComponent {
    onSelected = (item) => {
        const { store } = this.props;
        if (store) {
            this.props.router.push(`/store/${store.rawId}${item.link || ''}`);
        }
    };
    render() {
        const { children, store } = this.props;
        if (isNil(store)) {
            return React.createElement("div", { className: styles.storeNotFound }, t.storeNotFound);
        }
        const logo = pathOr(null, ['logo'], store);
        const cover = pathOr(null, ['cover'], store);
        const name = getNameText(store.name, 'EN');
        return (React.createElement(StoreContext.Provider, { value: {
                logo,
                cover,
                tabs,
                storeId: store.rawId,
                name,
                rating: store.rating,
                active: children.key,
                facebookUrl: store.facebookUrl || '',
                twitterUrl: store.twitterUrl || '',
                instagramUrl: store.instagramUrl || ''
            } },
            React.createElement("div", { className: styles.container },
                React.createElement(Container, null,
                    React.createElement(StoreHeader, null),
                    React.createElement("div", { className: styles.mobileTabs },
                        React.createElement(Collapse, { transparent: true, items: tabs, onSelected: this.onSelected, selected: !isNil(children) ? children.key : '' })),
                    children && cloneElement(children, { shop: store })))));
    }
}
export default Page(Store);
