import { graphql } from 'react-relay';
import { basicMutation } from '../../../relay/mutations/basicMutation';
const mutation = graphql `
	mutation GetJwtbyEmailMutation($input: CreateJWTEmailInput!) {
		getJwtbyEmail(input: $input) {
			token
		}
	}
`;
const getJwtbyEmailMutation = basicMutation(mutation, 'getJwtbyEmail');
export default getJwtbyEmailMutation;
