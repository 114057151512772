import React, { Component } from 'react';
import { isNil, prepend } from 'ramda';
import ImageLoader from '../../../../libs/react-image-loader';
import { classNames, convertSrc, isEmpty, isMobileBrowser } from '../../../../utils';
import { Slider } from '../../../../components/Slider';
import { ImageZoom } from '../../../../components/ImageZoom';
import { Icon } from '../../../../components/Icon';
import BannerLoading from '../../../../components/Banner/BannerLoading';
import ProductVariantThumbnails from '../ProductVariantThumbnails';
import ProductDiscount from '../ProductDiscount';
import ImageDetail from '../ImageDetail';
import { makeAdditionalPhotos } from '../utils';
import styles from './ProductImage.module.scss';
class ProductImage extends Component {
    constructor(props) {
        super(props);
        this.state = { selected: '' };
    }
    componentDidUpdate(prevProps) {
        const { rawId } = this.props;
        if (rawId !== prevProps.rawId) {
            this.clearSelected();
        }
    }
    clearSelected = () => {
        this.setState({ selected: '' });
    };
    handleClick = ({ image }) => {
        this.setState({ selected: image });
    };
    imgsToSlider = (imgs) => imgs.map((img) => ({
        id: img,
        img
    }));
    makeImageProps = ({ onMouseMove, onTouchMove, onTouchStart }) => {
        const { photoMain } = this.props;
        const { selected } = this.state;
        const src = !isEmpty(selected)
            ? convertSrc('large', selected)
            : convertSrc('large', photoMain);
        const loader = (React.createElement("div", { className: styles.loader },
            React.createElement(BannerLoading, null)));
        const imgProps = {
            fit: true,
            src,
            loader,
            onFocus: () => {
            }
        };
        return isMobileBrowser()
            ? {
                ...imgProps,
                onTouchMove,
                onTouchStart
            }
            : {
                ...imgProps,
                onMouseMove
            };
    };
    render() {
        const { photoMain, additionalPhotos, discount } = this.props;
        const { selected } = this.state;
        const showMobileSlider = !isNil(additionalPhotos) && !isNil(photoMain);
        const hasThumbnails = !isEmpty(additionalPhotos);
        return (React.createElement("div", { className: classNames(styles, 'container', { hasThumbnails }) },
            React.createElement("div", { className: classNames(styles, hasThumbnails ? 'thumbnailsWrapper' : 'noThumbnailsWrapper') }, hasThumbnails ? (React.createElement("div", { className: styles.thumbnailsContent },
                React.createElement(ProductVariantThumbnails, { isFirstSelected: true, isReset: isEmpty(selected), onClick: this.handleClick, options: makeAdditionalPhotos(prepend(photoMain || '', additionalPhotos || [])) }))) : null),
            React.createElement("div", { className: classNames(styles, 'imageWrapper', {
                    hasMobileSlider: showMobileSlider,
                    hasThumbnails
                }) },
                discount > 0 ? React.createElement(ProductDiscount, { discount: discount }) : null,
                React.createElement("div", { className: styles.imageContainer }, photoMain || selected ? (React.createElement(ImageZoom, null, (i) => (React.createElement("figure", { className: styles.image, style: {
                        backgroundPosition: i.backgroundPosition,
                        backgroundImage: i.backgroundImage === '' && !isNil(photoMain)
                            ? `url(${photoMain})`
                            : i.backgroundImage,
                        transition: 'opacity 0.3s ease-out',
                        backgroundSize: '220%'
                    } },
                    React.createElement(ImageLoader, { ...this.makeImageProps({
                            onMouseMove: i.onMouseMove,
                            onTouchMove: i.onTouchMove,
                            onTouchStart: i.onTouchStart
                        }) }))))) : (React.createElement("div", { className: styles.noImage },
                    React.createElement(Icon, { type: "camera", size: 80 })))),
                Boolean(photoMain || selected) && (React.createElement("div", { className: styles.imageDetail },
                    React.createElement(ImageDetail, null)))),
            showMobileSlider ? (React.createElement("div", { className: styles.imageSlider },
                React.createElement(Slider, { infinity: true, animationSpeed: 500, slidesToShow: 1, items: this.imgsToSlider(prepend(photoMain || '', additionalPhotos || [])), type: "image", arrows: true }))) : null));
    }
}
export default ProductImage;
