import React, { PureComponent } from 'react';
import { isNil } from 'ramda';
import { withRouter } from 'found';
import { Col, Container, Row } from '../../../layout';
import { Collapse } from '../../Collapse';
import { Button } from '../../common/Button';
import t from './i18n';
import styles from './Footer.module.scss';
import InfoBlock from '../InfoBlock';
import { Icon } from '../../Icon';
class Footer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    id: 'goods',
                    title: t.goods,
                    links: [
                        {
                            id: t.market_sales,
                            name: t.market_sales,
                            appLink: '/categories?search=&sortBy=DISCOUNT'
                        },
                        {
                            id: t.market_popular,
                            name: t.market_popular,
                            appLink: '/categories?search=&sortBy=VIEWS'
                        }
                    ]
                },
                {
                    id: 'marketplace',
                    title: t.marketplace,
                    links: [
                        {
                            id: 'termsOfUse',
                            name: t.marketplace_termsOfUse,
                            appLink: '/terms'
                        },
                        {
                            id: 'privacyPolicy',
                            name: t.marketplace_privacyPolicy,
                            appLink: '/privacy'
                        }
                    ]
                },
                {
                    id: 'services',
                    title: t.services,
                    links: [
                        {
                            id: 'services_sellingGuides',
                            name: t.services_sellingGuides,
                            link: 'https://graame.com/selling-guides'
                        },
                        {
                            id: 'services_blog',
                            name: t.services_blog,
                            link: 'https://graame.com/blog'
                        }
                    ]
                }
            ]
        };
    }
    render() {
        const { isShopCreated } = this.props;
        const { columns } = this.state;
        const FooterLogo = () => (React.createElement(Col, { lg: 9, xl: 9 },
            React.createElement("div", { className: styles.footerLogo },
                React.createElement(Icon, { type: "logo" }),
                React.createElement("p", { className: styles.storiqaGlobal }, t.graameIsAGlobalMarketPlace))));
        const FooterColumn = ({ title, links }) => (React.createElement(Col, { sm: 12, md: 4, lg: 4, xl: 4 },
            React.createElement("nav", { className: styles.footerColumn },
                React.createElement("header", { className: styles.navHeader },
                    React.createElement("h3", null, title)),
                React.createElement("ul", null, !isNil(links)
                    && links.map(({ id, name, appLink, pdfLink, link }) => (React.createElement("li", { key: id },
                        appLink != null && (React.createElement("button", { className: styles.navItem, onClick: () => {
                                this.props.router.push(appLink);
                            } }, name)),
                        pdfLink != null && (React.createElement("a", { href: pdfLink, target: "_blank", className: styles.navItem, rel: "noreferrer" }, name)),
                        link != null && (React.createElement("a", { href: link, target: "_blank", className: styles.navItem, rel: "noreferrer" }, name)))))))));
        const StartSellingButton = () => (React.createElement("div", { className: styles.startSellingButton },
            React.createElement("p", { className: styles.sellGoods }, t.wannaSellYourGoodsGlobally),
            React.createElement(Button, { href: "/start-selling", dataTest: "footerStartSellingButton" }, t.startSelling)));
        return (React.createElement("footer", { className: styles.container },
            React.createElement("h2", { className: styles.offscreen }, t.offscreenSections),
            React.createElement(Container, null,
                React.createElement("div", { className: styles.footerTop },
                    React.createElement(Row, null,
                        React.createElement(Col, { md: 12, lg: 3, xl: 3 },
                            React.createElement("div", { className: styles.logo },
                                React.createElement(Row, null,
                                    React.createElement(FooterLogo, null)))),
                        React.createElement(Col, { md: 12, lg: 9, xl: 6, mdVisible: true },
                            React.createElement(Row, null, columns.map((column) => (React.createElement(FooterColumn, { key: column.id, ...column }))))),
                        React.createElement(Col, { size: 12, sm: 12, md: 12, mdHidden: true },
                            React.createElement(Collapse, { grouped: true, menuTitle: "Menu", onSelected: () => {
                                }, items: columns })),
                        React.createElement(Col, { md: 12, lg: 12, xl: 3 }, isShopCreated ? null : React.createElement(StartSellingButton, null)))),
                React.createElement(Row, null,
                    React.createElement(Col, null,
                        React.createElement(InfoBlock, null),
                        React.createElement("div", { className: styles.rightsBlock },
                            t.copyRight,
                            " ",
                            t.allRightsReserved))))));
    }
}
export default withRouter(Footer);
