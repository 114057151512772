import React, { PureComponent, Fragment } from 'react';
import { slice, isEmpty } from 'ramda';
import { Link } from 'found';
import { Icon } from '../../../../components/Icon';
import { convertSrc, formatPrice } from '../../../../utils';
import styles from './ProductBlock.module.scss';
class ProductBlock extends PureComponent {
    renderAttributes = (attributes) => (React.createElement("div", { className: styles.attributes },
        React.createElement("div", { className: styles.names }, attributes.map((item, idx) => (React.createElement(Fragment, { key: `order-attribute-name-${idx}` },
            React.createElement("div", null, item.name))))),
        React.createElement("div", { className: styles.values }, attributes.map((item, idx) => (React.createElement(Fragment, { key: `order-attribute-value-${idx}` },
            React.createElement("div", null, item.value)))))));
    render() {
        const { product, currency, subtotal } = this.props;
        const attributes = slice(0, 3, product.attributes);
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.photoWrapper }, product.photoUrl ? (React.createElement("img", { src: convertSrc('small', product.photoUrl), alt: "", className: styles.photo })) : (React.createElement("div", { className: styles.emptyLogo },
                React.createElement(Icon, { type: "camera", size: 40 })))),
            React.createElement("div", { className: styles.productInfoBlock },
                React.createElement("div", { className: styles.productName },
                    React.createElement(Link, { to: `/store/${product.storeId}/products/${product.id}/variant/${product.rawId || ''}` },
                        React.createElement("strong", { className: styles.productNameText }, product.name))),
                React.createElement("div", { className: styles.productCategory },
                    React.createElement(Link, { to: `/categories?category=${product.category.id}&search=` }, product.category.name)),
                React.createElement("div", { className: styles.productPrice }, `${formatPrice(subtotal)} ${currency}`)),
            !isEmpty(attributes) && this.renderAttributes(attributes)));
    }
}
export default ProductBlock;
