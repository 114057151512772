import React, { Component } from 'react';
import { withRouter } from 'found';
import { all, assocPath, equals, isEmpty, omit, pathOr, propOr, values } from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { validate } from '@sansare/libstqfrontend';
import { Environment } from 'relay-runtime';
import { classNames, debug, fromRelayError, renameKeys } from '../../../../utils';
import { ChangePasswordMutation } from '../../../../relay/mutations';
import { Input } from '../../../../components/common/Input';
import { Button } from '../../../../components/common/Button';
import { Icon } from '../../../../components/Icon';
import { PasswordHints } from '../../../../components/PasswordHints';
import { withShowAlert } from '../../../../components/Alerts/AlertContext';
import { Confirmation } from '../../../../components/Confirmation';
import styles from '../../Profile.module.scss';
import t from './i18n';
class Security extends Component {
    static contextTypes = { environment: PropTypes.instanceOf(Environment).isRequired };
    constructor(props) {
        super(props);
        this.state = {
            form: {
                oldPassword: '',
                newPassword: '',
                repeatNewPassword: ''
            },
            formErrors: null,
            isLoading: false,
            newPasswordSee: false,
            repeatNewPasswordSee: false,
            isValidNewPassword: false,
            newPasswordValidParams: {
                lowerCase: false,
                upperCase: false,
                digit: false,
                length: false
            },
            newPasswordFocus: false,
            showModal: false
        };
    }
    handleSave = () => {
        const { environment } = this.context;
        const { form, isValidNewPassword } = this.state;
        const { oldPassword, newPassword, repeatNewPassword } = form;
        const { errors: formErrors } = validate({
            oldPassword: [
                [
                    (value) => value && value.length > 0,
                    t.passwordMustNotBeEmpty
                ]
            ],
            newPassword: [
                [
                    (value) => value && value.length > 0,
                    t.passwordMustNotBeEmpty
                ],
                [
                    (value) => value.length === 0 || isValidNewPassword,
                    t.notValidPassword
                ],
                [
                    (value) => value.length === 0 || value !== oldPassword,
                    t.passwordHasNotChange
                ]
            ],
            repeatNewPassword: [
                [
                    (value) => value && value.length > 0,
                    t.passwordMustNotBeEmpty
                ],
                [
                    (value) => value.length === 0 || value === newPassword,
                    t.notTheSamePassword
                ]
            ]
        }, {
            oldPassword,
            newPassword,
            repeatNewPassword
        });
        if (formErrors) {
            this.setState({
                formErrors,
                showModal: false
            });
            return;
        }
        this.setState(() => ({ isLoading: true }));
        const params = {
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    oldPassword,
                    newPassword
                }
            },
            environment,
            onCompleted: (response, errors) => {
                debug({
                    response,
                    errors
                });
                const relayErrors = fromRelayError({ source: { errors } });
                debug({ relayErrors });
                const validationErrors = pathOr({}, ['100', 'messages'], relayErrors);
                const status = pathOr('', ['100', 'status'], relayErrors);
                this.setState(() => ({ isLoading: false }));
                if (!isEmpty(validationErrors)) {
                    this.setState({
                        formErrors: renameKeys({
                            password: 'oldPassword',
                            new_password: 'newPassword'
                        }, validationErrors),
                        showModal: false
                    });
                    return;
                }
                else if (status) {
                    this.props.showAlert({
                        type: 'danger',
                        text: `${t.error} "${status}"`,
                        link: { text: t.close }
                    });
                    return;
                }
                else if (errors) {
                    this.props.showAlert({
                        type: 'danger',
                        text: t.somethingGoingWrong,
                        link: { text: t.close }
                    });
                    return;
                }
                this.props.showAlert({
                    type: 'success',
                    text: t.passwordSuccessfullyUpdated,
                    link: { text: '' }
                });
                this.setState(() => ({
                    showModal: false,
                    newPasswordSee: false,
                    repeatNewPasswordSee: false,
                    form: {
                        oldPassword: '',
                        newPassword: '',
                        repeatNewPassword: ''
                    }
                }), () => {
                    this.props.router.push('/logout');
                });
            },
            onError: (error) => {
                this.setState(() => ({ isLoading: false }));
                debug({ error });
                const relayErrors = fromRelayError(error);
                debug({ relayErrors });
                this.setState(() => ({ isLoading: false }));
                const validationErrors = pathOr({}, ['100', 'messages'], relayErrors);
                if (!isEmpty(validationErrors)) {
                    this.setState({
                        formErrors: renameKeys({
                            password: 'oldPassword',
                            new_password: 'newPassword'
                        }, validationErrors),
                        showModal: false
                    });
                    return;
                }
                this.props.showAlert({
                    type: 'danger',
                    text: t.somethingGoingWrong,
                    link: { text: t.close }
                });
                this.setState(() => ({
                    showModal: false,
                    newPasswordSee: false,
                    repeatNewPasswordSee: false
                }));
            }
        };
        ChangePasswordMutation.commit(params);
    };
    toggleSeePassword = (typePassword) => {
        const seeParam = `${typePassword}See`;
        this.setState((prevState) => ({ [seeParam]: !prevState[seeParam] }));
    };
    handleInputChange = (id) => (e) => {
        const { formErrors } = this.state;
        if (formErrors && id) {
            this.setState({ formErrors: omit([id], formErrors) });
        }
        const { value } = e.target;
        this.setState((prevState) => assocPath(['form', id], value.replace(/\s\s/, ' '), prevState), () => {
            if (id === 'newPassword') {
                this.newPasswordValidate();
            }
        });
    };
    newPasswordValidate = () => {
        const { newPassword } = this.state.form;
        const newPasswordValidParams = {
            lowerCase: /(?=.*?[a-z])/.test(newPassword),
            upperCase: /(?=.*?[A-Z])/.test(newPassword),
            digit: /(?=.*?[0-9])/.test(newPassword),
            length: newPassword.length >= 8
        };
        this.setState({
            isValidNewPassword: all(equals(true))(values(newPasswordValidParams)),
            newPasswordValidParams
        });
    };
    renderInput = ({ id, label }) => {
        const { formErrors, form } = this.state;
        const value = propOr('', id, form);
        const seeValue = this.state[`${id}See`];
        return (React.createElement(Input, { fullWidth: true, id: id, value: value, label: label, onChange: this.handleInputChange(id), errors: formErrors && id ? formErrors[id] : null, type: seeValue ? 'text' : 'password', onFocus: this.handleFocus, onBlur: this.handleBlur, dataTest: `${id}Input` }));
    };
    handleFocus = (e) => {
        if (e.target.name === 'newPassword') {
            this.setState({ newPasswordFocus: true });
        }
    };
    handleBlur = (e) => {
        if (e.target.name === 'newPassword') {
            this.setState({ newPasswordFocus: false });
        }
    };
    handleSaveModal = () => {
        this.setState({ showModal: true });
    };
    handleCloseModal = () => {
        this.setState({ showModal: false });
    };
    render() {
        const { isLoading, newPasswordSee, repeatNewPasswordSee, isValidNewPassword, newPasswordValidParams, newPasswordFocus, showModal } = this.state;
        return (React.createElement("div", { className: styles.security },
            React.createElement(Confirmation, { showModal: showModal, onClose: this.handleCloseModal, title: t.resetPassword, description: t.confirmationDescription, onCancel: this.handleCloseModal, onConfirm: this.handleSave, confirmText: t.confirmText, cancelText: t.cancelText }),
            React.createElement("div", { className: styles.subtitle },
                React.createElement("strong", null, t.securitySettings)),
            React.createElement("div", { className: styles.passwordInputs },
                React.createElement("div", { className: styles.passwordInput }, this.renderInput({
                    id: 'oldPassword',
                    label: t.labelCurrentPassword
                })),
                React.createElement("div", { className: styles.passwordInput },
                    this.renderInput({
                        id: 'newPassword',
                        label: t.labelNewPassword
                    }),
                    React.createElement("div", { className: classNames(styles, 'icon', { openIcon: newPasswordSee }), onClick: () => {
                            this.toggleSeePassword('newPassword');
                        }, onKeyDown: () => {
                        }, role: "button", tabIndex: 0 },
                        React.createElement(Icon, { type: "eye", size: 28 })),
                    React.createElement("div", null, !isValidNewPassword
                        && newPasswordFocus && (React.createElement(PasswordHints, { ...newPasswordValidParams })))),
                React.createElement("div", { className: styles.passwordInput },
                    this.renderInput({
                        id: 'repeatNewPassword',
                        label: t.labelRepeatPassword
                    }),
                    React.createElement("div", { className: classNames(styles, 'icon', { openIcon: repeatNewPasswordSee }), onClick: () => {
                            this.toggleSeePassword('repeatNewPassword');
                        }, onKeyDown: () => {
                        }, role: "button", tabIndex: 0 },
                        React.createElement(Icon, { type: "eye", size: 28 })))),
            React.createElement(Button, { big: true, disabled: !isValidNewPassword, onClick: this.handleSaveModal, isLoading: isLoading, dataTest: "saveSecuritySettingsButton" }, t.save)));
    }
}
export default withShowAlert(withRouter(Security));
