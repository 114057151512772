/**
 * @generated SignedSource<<aa4889091b3dca0e190903c07a8200a3>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "after"
    }, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "first"
    }, v2 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "text"
    }, v3 = {
        "kind": "Variable",
        "name": "after",
        "variableName": "after"
    }, v4 = {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
    }, v5 = [
        (v3 /*: any*/),
        (v4 /*: any*/),
        {
            "kind": "Variable",
            "name": "searchTerm",
            "variableName": "text"
        },
        {
            "kind": "Literal",
            "name": "visibility",
            "value": "published"
        }
    ], v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawId",
        "storageKey": null
    }, v8 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lang",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "text",
            "storageKey": null
        }
    ], v9 = {
        "alias": null,
        "args": null,
        "concreteType": "Translation",
        "kind": "LinkedField",
        "name": "name",
        "plural": true,
        "selections": (v8 /*: any*/),
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "Stores_edges_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Search",
                    "kind": "LinkedField",
                    "name": "search",
                    "plural": false,
                    "selections": [
                        {
                            "args": [
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                {
                                    "kind": "Variable",
                                    "name": "text",
                                    "variableName": "text"
                                }
                            ],
                            "kind": "FragmentSpread",
                            "name": "Stores_search"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/),
                (v2 /*: any*/)
            ],
            "kind": "Operation",
            "name": "Stores_edges_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Search",
                    "kind": "LinkedField",
                    "name": "search",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": (v5 /*: any*/),
                            "concreteType": "StoresWithTotalCountConnection",
                            "kind": "LinkedField",
                            "name": "findStore",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PageInfoStoresSearch",
                                    "kind": "LinkedField",
                                    "name": "pageInfo",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "StoresSearchFilters",
                                            "kind": "LinkedField",
                                            "name": "searchFilters",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "totalCount",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Category",
                                                    "kind": "LinkedField",
                                                    "name": "category",
                                                    "plural": false,
                                                    "selections": [
                                                        (v6 /*: any*/),
                                                        (v7 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Category",
                                                            "kind": "LinkedField",
                                                            "name": "children",
                                                            "plural": true,
                                                            "selections": [
                                                                (v6 /*: any*/),
                                                                (v7 /*: any*/),
                                                                (v9 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "country",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "endCursor",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "hasNextPage",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "StoresEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "cursor",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Store",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                (v6 /*: any*/),
                                                (v7 /*: any*/),
                                                (v9 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "logo",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "cover",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "slug",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Translation",
                                                    "kind": "LinkedField",
                                                    "name": "shortDescription",
                                                    "plural": true,
                                                    "selections": (v8 /*: any*/),
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": [
                                                        {
                                                            "kind": "Literal",
                                                            "name": "first",
                                                            "value": 4
                                                        },
                                                        {
                                                            "kind": "Literal",
                                                            "name": "searchTerm",
                                                            "value": {}
                                                        }
                                                    ],
                                                    "concreteType": "BaseProductsConnection",
                                                    "kind": "LinkedField",
                                                    "name": "findMostViewedProducts",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "BaseProductsEdge",
                                                            "kind": "LinkedField",
                                                            "name": "edges",
                                                            "plural": true,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "BaseProduct",
                                                                    "kind": "LinkedField",
                                                                    "name": "node",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v6 /*: any*/),
                                                                        (v7 /*: any*/),
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "ProductsConnection",
                                                                            "kind": "LinkedField",
                                                                            "name": "products",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "concreteType": "ProductsEdge",
                                                                                    "kind": "LinkedField",
                                                                                    "name": "edges",
                                                                                    "plural": true,
                                                                                    "selections": [
                                                                                        {
                                                                                            "alias": null,
                                                                                            "args": null,
                                                                                            "concreteType": "Product",
                                                                                            "kind": "LinkedField",
                                                                                            "name": "node",
                                                                                            "plural": false,
                                                                                            "selections": [
                                                                                                {
                                                                                                    "alias": null,
                                                                                                    "args": null,
                                                                                                    "kind": "ScalarField",
                                                                                                    "name": "photoMain",
                                                                                                    "storageKey": null
                                                                                                },
                                                                                                (v6 /*: any*/)
                                                                                            ],
                                                                                            "storageKey": null
                                                                                        }
                                                                                    ],
                                                                                    "storageKey": null
                                                                                }
                                                                            ],
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": "findMostViewedProducts(first:4,searchTerm:{})"
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "__typename",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": (v5 /*: any*/),
                            "filters": [
                                "searchTerm",
                                "visibility"
                            ],
                            "handle": "connection",
                            "key": "Stores_findStore",
                            "kind": "LinkedHandle",
                            "name": "findStore"
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "98706a77c41036a47aae6ec465ef165e",
            "id": null,
            "metadata": {},
            "name": "Stores_edges_Query",
            "operationKind": "query",
            "text": "query Stores_edges_Query(\n  $first: Int\n  $after: ID\n  $text: SearchStoreInput!\n) {\n  search {\n    ...Stores_search_3epZJP\n  }\n}\n\nfragment Stores_search_3epZJP on Search {\n  findStore(searchTerm: $text, first: $first, after: $after, visibility: \"published\") {\n    pageInfo {\n      searchFilters {\n        totalCount\n        category {\n          id\n          rawId\n          children {\n            id\n            rawId\n            name {\n              lang\n              text\n            }\n          }\n        }\n        country\n      }\n      endCursor\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        id\n        rawId\n        name {\n          lang\n          text\n        }\n        logo\n        cover\n        slug\n        shortDescription {\n          lang\n          text\n        }\n        findMostViewedProducts(first: 4, searchTerm: {}) {\n          edges {\n            node {\n              id\n              rawId\n              products {\n                edges {\n                  node {\n                    photoMain\n                    id\n                  }\n                }\n              }\n            }\n          }\n        }\n        __typename\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "bfc32e3eac2c9f450ae38938ab56382c";
export default node;
