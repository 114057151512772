import { createContext } from 'react';
const AppContext = createContext({
    directories: {
        countries: { children: [] },
        currencies: [],
        sellerCurrencies: [],
        languages: [],
        categories: {
            name: [],
            children: []
        },
        orderStatuses: undefined,
        currencyExchange: []
    },
    environment: undefined,
    handleLogin: () => {
    },
    categories: {
        name: [],
        children: []
    }
});
export default AppContext;
