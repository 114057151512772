import { fetchQuery, graphql } from 'react-relay';
const BUYNOW_QUERY = graphql `
	query fetchBuyNow_Query( $productId: Int! $quantity: Int! $couponCode: String $shippingId: Int ) {
		calculateBuyNow( productId: $productId quantity: $quantity couponCode: $couponCode shippingId: $shippingId ) {
			product {
				id
				rawId
			}
			couponsDiscounts
			totalCost
			totalCostWithoutDiscounts
			totalCount
			deliveryCost
			subtotal
			subtotalWithoutDiscounts
			price
		}
	}
`;
const fetchBuyNow = (environment, variables) => fetchQuery(environment, BUYNOW_QUERY, variables);
export default fetchBuyNow;
