import React, { Component } from 'react';
import { addIndex, filter, isEmpty, map, pathOr } from 'ramda';
import { createRefetchContainer, graphql } from 'react-relay';
import { v4 as uuidv4 } from 'uuid';
import { ManageStore } from '../../../index';
import { Paginator } from '../../../../../../components/common/Paginator';
import { withShowAlert } from '../../../../../../components/Alerts/AlertContext';
import { Page } from '../../../../../../components/App';
import { Autocomplete } from '../../../../../../components/common/Autocomplete';
import { debug, fromRelayError, getNameText } from '../../../../../../utils';
import { SetProductQuantityInWarehouseMutation } from '../../../../../../relay/mutations';
import styles from './StorageProducts.module.scss';
import t from './i18n';
import StorageProductsTableHeader from '../StorageProductsTableHeader';
import StorageProductsTableRow from '../StorageProductsTableRow';
const itemsPerPage = 10;
class StorageProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            autocompleteValue: '',
            searchTermValue: '',
            autocompleteItems: [],
            isLoadingPagination: false
        };
    }
    handleSave = (productId, quantity) => {
        const warehouseId = pathOr(null, ['me', 'warehouse', 'id'], this.props);
        // const { storageFocusValue: quantity } = this.state;
        const { environment } = this.props;
        const params = {
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    warehouseId,
                    productId,
                    quantity: parseInt(quantity, 10)
                }
            },
            environment,
            onCompleted: (response, errors) => {
                debug({
                    response,
                    errors
                });
                const relayErrors = fromRelayError({ source: { errors } });
                debug({ relayErrors });
                const statusError = pathOr({}, ['100', 'status'], relayErrors);
                if (!isEmpty(statusError)) {
                    this.props.showAlert({
                        type: 'danger',
                        text: `${t.error} "${statusError}"`,
                        link: { text: t.close }
                    });
                    return;
                }
                if (errors) {
                    this.props.showAlert({
                        type: 'danger',
                        text: t.somethingGoingWrong,
                        link: { text: t.close }
                    });
                }
            },
            onError: (error) => {
                debug({ error });
                this.props.showAlert({
                    type: 'danger',
                    text: t.somethingGoingWrong,
                    link: { text: t.close }
                });
            }
        };
        SetProductQuantityInWarehouseMutation.commit(params);
    };
    loadPage = (pageNumber) => {
        this.setState({ isLoadingPagination: true });
        const storageSlug = pathOr({}, ['match', 'params', 'storageSlug'], this.props);
        const { searchTermValue } = this.state;
        this.props.relay.refetch({
            currentPage: pageNumber,
            itemsCount: itemsPerPage,
            storageSlug,
            searchTerm: { name: searchTermValue },
            autocompleteValue: this.state.autocompleteValue
        }, null, () => {
            this.setState({ isLoadingPagination: false });
        }, { force: true });
    };
    handleOnChangeAutocomplete = (value) => {
        const storageSlug = pathOr({}, ['match', 'params', 'storageSlug'], this.props);
        this.setState({
            autocompleteValue: value,
            searchTermValue: value
        });
        this.props.relay.refetch({
            autocompleteValue: value,
            currentPage: this.state.currentPage,
            itemsCount: itemsPerPage,
            storageSlug,
            searchTerm: { name: value }
        }, null, () => {
            const { me } = this.props;
            const items = pathOr([], ['warehouse', 'autoCompleteProductName', 'edges'], me);
            this.setState({
                autocompleteItems: addIndex(map)((item, idx) => ({
                    id: `${idx}`,
                    label: item.node
                }), items)
            });
        }, { force: true });
    };
    handleOnSetAutocomplete = (value) => {
        const storageSlug = pathOr({}, ['match', 'params', 'storageSlug'], this.props);
        this.setState({
            autocompleteValue: value,
            searchTermValue: value
        });
        this.props.relay.refetch({
            autocompleteValue: value,
            currentPage: this.state.currentPage,
            itemsCount: itemsPerPage,
            storageSlug,
            searchTerm: { name: value }
        }, null, () => {
        }, { force: true });
    };
    render() {
        const { me } = this.props;
        const { autocompleteItems, isLoadingPagination } = this.state;
        const storageName = pathOr('Unnamed', ['warehouse', 'name'], me);
        const products = map((item) => {
            const productId = pathOr({}, ['node', 'productId'], item);
            const quantity = pathOr({}, ['node', 'quantity'], item);
            const product = pathOr(null, ['node', 'product'], item);
            if (!product) {
                return {};
            }
            const currency = pathOr('', ['currency'], product);
            const { photoMain, price } = product;
            const name = getNameText(pathOr(null, ['baseProduct', 'name'], product), 'EN');
            const categoryName = getNameText(pathOr(null, ['baseProduct', 'category', 'name'], product), 'EN');
            const attributes = map((attribute) => ({
                attrId: attribute.attrId,
                attributeName: getNameText(pathOr(null, ['attribute', 'name'], attribute), 'EN'),
                value: attribute.value
            }), product.attributes);
            return {
                id: product.id,
                productId,
                quantity,
                photoMain,
                name,
                categoryName,
                price,
                attributes,
                currency
            };
        }, pathOr([], ['warehouse', 'products', 'edges'], me));
        const pagesCount = pathOr(0, ['warehouse', 'products', 'pageInfo', 'totalPages'], me);
        const currentPage = pathOr(
        //
        0, ['warehouse', 'products', 'pageInfo', 'currentPage'], me);
        const filteredProducts = filter((item) => !isEmpty(item), products);
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.searchInput },
                React.createElement(Autocomplete, { autocompleteItems: autocompleteItems, onChange: this.handleOnChangeAutocomplete, onSet: this.handleOnSetAutocomplete, label: "Search item", search: true, fullWidth: true })),
            React.createElement("div", { className: styles.subtitle },
                React.createElement("strong", null, storageName)),
            React.createElement(StorageProductsTableHeader, null),
            !isEmpty(filteredProducts) ? (React.createElement(React.Fragment, null, map((item) => (React.createElement(StorageProductsTableRow, { key: item.productId, onSave: this.handleSave, item: item })), products))) : (React.createElement("div", { className: styles.emptyProductsBlock }, t.noProducts)),
            React.createElement(Paginator, { pagesCount: pagesCount, currentPage: currentPage, onPageSelect: this.loadPage, isLoading: isLoadingPagination })));
    }
}
export default createRefetchContainer(withShowAlert(Page(ManageStore({
    OriginalComponent: StorageProducts,
    active: 'storages',
    title: 'Storages'
}))), {
    me: graphql `
			fragment StorageProducts_me on User
			@argumentDefinitions(
				autocompleteValue: { type: "String!", defaultValue: "" }
				searchTerm: { type: "SearchProductInput" }
				storageSlug: { type: "String!", defaultValue: "" }
				currentPage: { type: "Int!", defaultValue: 1 }
				itemsCount: { type: "Int!", defaultValue: 10 }
			) {
				warehouse(slug: $storageSlug) {
					id
					name
					autoCompleteProductName(first: 8, name: $autocompleteValue) {
						edges {
							node
						}
					}
					products(
						searchTerm: $searchTerm
						currentPage: $currentPage
						itemsCount: $itemsCount
						visibility: "active"
					) {
						edges {
							node {
								productId
								product {
									baseProduct {
										name {
											lang
											text
										}
										category {
											name {
												lang
												text
											}
										}
									}
									id
									rawId
									price
									photoMain
									currency
									attributes {
										attrId
										attribute {
											id
											rawId
											name {
												lang
												text
											}
										}
										value
									}
								}
								quantity
							}
						}
						pageInfo {
							totalPages
							currentPage
							pageItemsCount
						}
					}
				}
			}
    `
}, graphql `
		query StorageProducts_Query(
			$autocompleteValue: String!
			$searchTerm: SearchProductInput
			$currentPage: Int!
			$itemsCount: Int!
			$storageSlug: String!
		) {
			me {
				...StorageProducts_me
				@arguments(
					autocompleteValue: $autocompleteValue
					searchTerm: $searchTerm
					currentPage: $currentPage
					itemsCount: $itemsCount
					storageSlug: $storageSlug
				)
			}
		}
  `);
