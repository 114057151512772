export const languages = [
    {
        id: 'en',
        label: 'ENG'
    },
    {
        id: 'hi',
        label: 'HIN'
    },
    {
        id: 'ja',
        label: 'JAP'
    },
    {
        id: 'kk',
        label: 'KAZ'
    }
];
