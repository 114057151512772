import React, { PureComponent } from 'react';
import { Link } from 'found';
import { head } from 'ramda';
import { classNames, convertSrc, formatPrice, getExchangePrice, getNameText, verifyItemCurrency } from '../../utils';
import { Rating } from '../common/Rating';
import BannerLoading from '../Banner/BannerLoading';
import ImageLoader from '../../libs/react-image-loader';
import { ContextDecorator } from '../App';
import styles from './CardProduct.module.scss';
import { CardProductCashback } from './CardProductCashback';
import { Icon } from '../Icon';
class CardProduct extends PureComponent {
    render() {
        const { item, isSearchPage, directories } = this.props;
        const { rawId, storeId, name, products, currency, rating, store } = verifyItemCurrency(item);
        let discount = null;
        let photoMain = null;
        let cashback = null;
        let price = null;
        const product = head(products.edges);
        if (product) {
            ({
                discount,
                photoMain,
                cashback,
                price
            } = product.node);
        }
        if (!storeId || !rawId || !currency || !price) {
            return null;
        }
        const lang = 'EN';
        const productLink = `/store/${storeId}/products/${rawId}${product ? `/variant/${product.node.rawId}` : ''}`;
        const discountedPrice = discount ? price * (1 - discount) : price;
        const discountValue = discount ? (discount * 100).toFixed(0) : undefined;
        const cashbackValue = cashback ? (cashback * 100).toFixed(0) : null;
        const priceExchanged = getExchangePrice({
            price,
            currency,
            currencyExchange: directories.currencyExchange
        });
        const discountedPriceExchanged = getExchangePrice({
            price: discountedPrice,
            currency,
            currencyExchange: directories.currencyExchange
        });
        return (React.createElement("div", { className: styles.container },
            React.createElement(Link, { to: productLink, className: styles.body, "data-test": `cardProduct_${rawId}`, exact: true },
                React.createElement("div", { className: styles.top },
                    discountValue && (React.createElement("div", { className: styles.discount },
                        React.createElement("b", null, `-${discountValue}%`))),
                    !photoMain ? (React.createElement("div", { className: styles.emptyFoto },
                        React.createElement(Icon, { type: "camera", size: 40 }))) : (React.createElement(ImageLoader, { fit: true, src: convertSrc('medium', photoMain || ''), loader: (React.createElement("div", { className: styles.bannerLoading },
                            React.createElement(BannerLoading, null))) }))),
                React.createElement("div", { className: styles.bottom },
                    React.createElement("div", { className: styles.levelOne },
                        React.createElement(Rating, { value: rating }),
                        Boolean(cashbackValue) && (React.createElement(CardProductCashback, { cashbackValue: cashbackValue }))),
                    name && (React.createElement("div", { className: styles.titleWrap },
                        React.createElement("div", { className: styles.title }, getNameText(name, lang)),
                        store
                            && store.name && (React.createElement("div", { className: styles.store }, getNameText(store.name, lang))))),
                    React.createElement("div", { className: styles.priceWrap },
                        React.createElement("div", { className: classNames(styles, 'price', { isSearchPage }) },
                            React.createElement("div", { className: styles.undiscountedPrice }, Boolean(discount) && (React.createElement(React.Fragment, null, priceExchanged ? (React.createElement("span", null, priceExchanged)) : (React.createElement("span", null,
                                formatPrice(price, 2),
                                " ",
                                currency))))),
                            React.createElement("div", { className: styles.priceDropdown },
                                React.createElement("div", { className: styles.actualPrice }, priceExchanged ? (React.createElement("span", null, discountedPriceExchanged)) : (React.createElement("span", null, discountedPrice === 0 ? 'FREE' : `${formatPrice(discountedPrice, 2)} ${currency}`))))))))));
    }
}
export default ContextDecorator(CardProduct);
