import React from 'react';
import { Link } from 'found';
import { Rating } from '../../../../components/common/Rating';
import { convertSrc, extractText } from '../../../../utils';
import ProductContext from '../ProductContext';
import styles from './ProductStore.module.scss';
import t from './i18n';
const ProductStore = () => (React.createElement(ProductContext.Consumer, null, ({ store }) => (React.createElement(React.Fragment, null, store ? (React.createElement("div", { className: styles.container },
    React.createElement("div", { className: styles.storeInfoWrapper },
        React.createElement(Link, { to: `/store/${store.rawId}`, className: styles.storeInfo },
            React.createElement("div", { role: "img", className: styles.image, style: {
                    backgroundImage: convertSrc('small', store.logo)
                        ? `url(${convertSrc('small', store.logo)})`
                        : 'none'
                } }),
            React.createElement("div", null,
                React.createElement("h4", { className: styles.storeName }, extractText(store.name)),
                React.createElement(Rating, { value: store.rating })))))) : (React.createElement("div", { className: styles.noStore }, t.noStore))))));
export default ProductStore;
