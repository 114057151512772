import { graphql } from 'react-relay';
import { basicMutation } from './basicMutation';
const mutation = graphql `
	mutation CreateDisputeMutation($input: CreateDisputeInput!) {
		createDispute(input: $input) {
			mock
		}
	}
`;
const CreateDisputeMutation = basicMutation(mutation, 'createDispute');
export default CreateDisputeMutation;
