/**
 * @generated SignedSource<<07e3bf7f09e2d0dbde61a425cf717176>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "ResetActionOutput",
            "kind": "LinkedField",
            "name": "requestPasswordReset",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "success",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "RequestPasswordResetMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "RequestPasswordResetMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "c7c3ffc0142686d081d3b210fdbce510",
            "id": null,
            "metadata": {},
            "name": "RequestPasswordResetMutation",
            "operationKind": "mutation",
            "text": "mutation RequestPasswordResetMutation(\n  $input: ResetRequest!\n) {\n  requestPasswordReset(input: $input) {\n    success\n  }\n}\n"
        }
    };
})();
node.hash = "ec67debe6274b2b664341666143bef6f";
export default node;
