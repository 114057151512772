import React, { Component } from 'react';
import { map } from 'ramda';
import { Select } from '../../Select';
import t from './i18n';
class CurrencySelect extends Component {
    static getDerivedStateFromProps(nextProps, prevState) {
        const { currency } = nextProps;
        if (JSON.stringify(currency) !== JSON.stringify(prevState.currency)) {
            return {
                ...prevState,
                currency
            };
        }
        return null;
    }
    constructor(props) {
        super(props);
        this.state = { currency: props.currency };
    }
    handleOnChange = (item) => {
        this.setState({ currency: item });
        this.props.onChangeCurrency(item);
    };
    render() {
        const { dataTest } = this.props;
        const { currency } = this.state;
        const currencies = map((item) => ({
            id: item,
            label: item
        }), this.props.currencies);
        return (React.createElement(Select, { forForm: true, fullWidth: true, label: t.labelCurrency, items: currencies, activeItem: currency, onSelect: this.handleOnChange, dataTest: dataTest }));
    }
}
export default CurrencySelect;
