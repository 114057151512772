import { t } from '../../../../translation/utils';
const translations = {
    en: {
        paymentOptions: 'PAYMENT OPTIONS',
        paymentAccount: 'PAYMENT ACCOUNT',
        balance: 'BALANCE',
        bankCards: 'Bank cards',
        copied: 'Copied'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
