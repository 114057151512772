import { t } from '../../../translation/utils';
const translations = {
    en: {
        order: 'Order',
        exchangeNotification: 'Cashback will be sent to the address used for a payment. Don\'t use exchanges addresses as we can\'t guarantee cashback receipt in this case.',
        attention: 'Attention!',
        ok: 'ok',
        myOrders: 'My orders'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
