import React, { Component } from 'react';
import { withRouter } from 'found';
import { createPaginationContainer, graphql } from 'react-relay';
import { addIndex, isEmpty, map, pathOr } from 'ramda';
import { Col, Row } from '../../../layout';
import { Button } from '../../../components/common/Button';
import { Autocomplete } from '../../../components/common/Autocomplete';
import { SearchNoResults } from '../../../components/SearchNoResults';
import styles from './StoreItems.module.scss';
import t from './i18n';
import CardProduct from '../../../components/CardProduct/CardProduct';
const productsPerRequest = 8;
class StoreItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            autocompleteValue: '',
            autocompleteItems: []
        };
    }
    productsRefetch = () => {
        const { autocompleteValue } = this.state;
        this.props.relay.refetchConnection(productsPerRequest, () => {
        }, {
            autocompleteValue,
            searchTerm: { name: autocompleteValue }
        });
    };
    handleOnChangeAutocomplete = (value) => {
        this.setState({ autocompleteValue: value }, () => {
            this.props.relay.refetchConnection(productsPerRequest, () => {
                const items = pathOr([], ['shop', 'autoCompleteProductName', 'edges'], this.props);
                this.setState({
                    autocompleteItems: addIndex(map)((item, idx) => ({
                        id: `${idx}`,
                        label: item.node
                    }), items)
                });
            }, {
                autocompleteValue: value,
                searchTerm: { name: value },
                after: null
            });
        });
    };
    handleOnSetAutocomplete = (value) => {
        this.setState({ autocompleteValue: value }, () => {
            this.props.relay.refetchConnection(productsPerRequest, () => {
            }, {
                autocompleteValue: value,
                searchTerm: { name: value },
                after: null
            });
        });
    };
    render() {
        const { autocompleteItems, autocompleteValue } = this.state;
        const products = map((item) => item.node, pathOr([], ['shop', 'findProduct', 'edges'], this.props));
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.searchInput },
                React.createElement(Autocomplete, { autocompleteItems: autocompleteItems, onChange: this.handleOnChangeAutocomplete, onSet: this.handleOnSetAutocomplete, label: t.labelSearchShopsProducts, search: true, fullWidth: true })),
            React.createElement(Row, null, products && !isEmpty(products) ? (map((item) => (React.createElement(Col, { key: item.rawId, size: 12, sm: 6, md: 4, xl: 3 },
                React.createElement("div", { key: item.id, className: styles.cardWrapper },
                    React.createElement(CardProduct, { item: { ...item } })))), products)) : (React.createElement(SearchNoResults, { value: autocompleteValue }))),
            this.props.relay.hasMore() && (React.createElement("div", { className: styles.loadButton },
                React.createElement(Button, { big: true, load: true, onClick: this.productsRefetch, dataTest: "shopProductsLoadMoreButton", wireframe: true }, t.loadMore)))));
    }
}
export default createPaginationContainer(withRouter(StoreItems), {
    shop: graphql `
			fragment StoreItems_shop on Store
			@argumentDefinitions(
				autocompleteValue: { type: "String!", defaultValue: "" }
				searchTerm: { type: "SearchProductInput!", defaultValue: { name: "" } }
				first: { type: "Int", defaultValue: 8 }
				after: { type: "ID", defaultValue: null }
			) {
				findProduct(
					first: $first
					after: $after
					searchTerm: $searchTerm
					visibility: "active"
				) @connection(key: "StoreItems_findProduct") {
					edges {
						node {
							id
							rawId
							currency
							name {
								text
								lang
							}
							store {
								name {
									text
									lang
								}
							}
							category {
								rawId
							}
							storeId
							products(first: 1) {
								edges {
									node {
										id
										rawId
										discount
										photoMain
										cashback
										price
										customerPrice {
											price
											currency
										}
										attributes {
											attribute {
												id
											}
											value
										}
									}
								}
							}
							rating
						}
					}
					pageInfo {
						endCursor
					}
				}
				autoCompleteProductName(first: 8, name: $autocompleteValue) {
					edges {
						node
					}
				}
			}
    `
}, {
    direction: 'forward',
    getConnectionFromProps: (props) => props.shop && props.shop.findProduct,
    getVariables: (props, c, prevFragmentVars) => ({
        storeId: prevFragmentVars.storeId,
        first: c.count,
        after: props.shop.findProduct.pageInfo.endCursor,
        autocompleteValue: prevFragmentVars.autocompleteValue,
        searchTerm: prevFragmentVars.searchTerm
    }),
    query: graphql `
			query StoreItems_shop_Query( $storeId: Int! $first: Int $after: ID $autocompleteValue: String! $searchTerm: SearchProductInput! ) {
				store(id: $storeId) {
					...StoreItems_shop
					@arguments(
						first: $first
						after: $after
						autocompleteValue: $autocompleteValue
						searchTerm: $searchTerm
					)
				}
			}
    `
});
