import React from 'react';
import { Icon } from '../../Icon';
import { Button } from '../../common/Button';
import t from './i18n';
import styles from './AuthorizationSocial.module.scss';
import { facebookLoginString, googleLoginString } from '../../../utils';
const buttons = [
    {
        id: '0',
        href: facebookLoginString(),
        iconType: 'facebook',
        dataTest: 'authFacebookButton',
        message: t.signInWithFacebook
    },
    {
        id: '1',
        href: googleLoginString(),
        iconType: 'google',
        dataTest: 'authGoogleButton',
        message: t.signInWithGoogle
    }
];
const AuthorizationSocial = (props) => (React.createElement("div", { className: styles.container }, buttons.map(({ id, href, dataTest, iconType, message }) => (React.createElement(Button, { iconic: true, key: id, href: href, dataTest: dataTest, onClick: props.handleSocialClick },
    React.createElement(Icon, { type: iconType }),
    React.createElement("span", null, message))))));
export default AuthorizationSocial;
