import React, { Component } from 'react';
import { isEmpty, pathOr, toUpper } from 'ramda';
import { createFragmentContainer, graphql } from 'react-relay';
import { v4 as uuidv4 } from 'uuid';
import { Environment } from 'relay-runtime';
import PropTypes from 'prop-types';
import { ManageStore } from '../../index';
import { withShowAlert } from '../../../../../components/Alerts/AlertContext';
import { Page } from '../../../../../components/App';
import { debug, renameKeys, fromRelayError } from '../../../../../utils';
import { UpdateStoreMainMutation } from '../../../../../relay/mutations';
import sendStoreToModerationByUserMutation from '../mutations/SendStoreToModerationByUserMutation';
import Form from '../Form';
import t from './i18n';
class EditStore extends Component {
    static contextTypes = {
        directories: PropTypes.object,
        environment: PropTypes.instanceOf(Environment).isRequired,
        currentUser: PropTypes.shape({
            id: PropTypes.string,
            rawId: PropTypes.number
        })
    };
    constructor(props) {
        super(props);
        this.state = {
            serverValidationErrors: {},
            isLoading: false
        };
    }
    handleSave = ({ form, optionLanguage }) => {
        const { logoUrl } = this.state;
        const { environment } = this.context;
        const { name, longDescription, shortDescription, defaultLanguage, slug, cover, slogan } = form;
        this.setState(() => ({
            isLoading: true,
            serverValidationErrors: {}
        }));
        const id = pathOr(null, ['me', 'myStore', 'id'], this.props);
        const params = {
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    id,
                    name: [{
                            lang: optionLanguage,
                            text: name
                        }],
                    defaultLanguage: toUpper(defaultLanguage),
                    longDescription: [{
                            lang: optionLanguage,
                            text: longDescription
                        }],
                    shortDescription: [{
                            lang: optionLanguage,
                            text: shortDescription
                        }],
                    slug,
                    cover,
                    slogan,
                    logo: logoUrl,
                    addressFull: {}
                }
            },
            environment,
            onCompleted: (response, errors) => {
                debug({
                    response,
                    errors
                });
                const relayErrors = fromRelayError({ source: { errors } });
                debug({ relayErrors });
                this.setState(() => ({ isLoading: false }));
                const validationErrors = pathOr({}, ['100', 'messages'], relayErrors);
                if (!isEmpty(validationErrors)) {
                    this.setState({
                        serverValidationErrors: renameKeys({
                            long_description: 'longDescription',
                            short_description: 'shortDescription'
                        }, validationErrors)
                    });
                    return;
                }
                const statusError = pathOr({}, ['100', 'status'], relayErrors);
                if (!isEmpty(statusError)) {
                    this.props.showAlert({
                        type: 'danger',
                        text: `${t.error} "${statusError}"`,
                        link: { text: t.close }
                    });
                    return;
                }
                this.props.showAlert({
                    type: 'success',
                    text: t.saved,
                    link: { text: '' }
                });
            },
            onError: (error) => {
                debug({ error });
                const relayErrors = fromRelayError(error);
                debug({ relayErrors });
                this.setState(() => ({ isLoading: false }));
                const validationErrors = pathOr({}, ['100', 'messages'], relayErrors);
                if (!isEmpty(validationErrors)) {
                    this.setState({ serverValidationErrors: validationErrors });
                    return;
                }
                this.props.showAlert({
                    type: 'danger',
                    text: t.somethingGoingWrong,
                    link: { text: t.close }
                });
            }
        };
        UpdateStoreMainMutation.commit(params);
    };
    handleSendToModeration = () => {
        if (this.props.me && this.props.me.myStore) {
            this.setState({ isLoading: true });
            sendStoreToModerationByUserMutation({
                environment: this.context.environment,
                variables: { id: this.props.me.myStore.rawId }
            })
                .then(() => {
                this.props.showAlert({
                    type: 'success',
                    text: t.storeHasBeenSentToModeration,
                    link: { text: t.close }
                });
                return true;
            })
                .finally(() => {
                this.setState({ isLoading: false });
            })
                .catch((error) => {
                const errMsg = pathOr(t.somethingGoingWrong, ['data', 'details'])(error);
                this.props.showAlert({
                    type: 'danger',
                    text: errMsg,
                    link: { text: t.close }
                });
            });
        }
    };
    render() {
        const { isLoading } = this.state;
        const store = pathOr(null, ['myStore'], this.props.me);
        if (!store) {
            return React.createElement("div", null, t.storeNotFound);
        }
        return (React.createElement(Form, { store: store, onSave: this.handleSave, onClickOnSendToModeration: this.handleSendToModeration, isLoading: isLoading, serverValidationErrors: this.state.serverValidationErrors }));
    }
}
export default createFragmentContainer(Page(withShowAlert(ManageStore({
    OriginalComponent: EditStore,
    active: 'settings',
    title: 'Settings'
}))), {
    me: graphql `
			fragment EditStore_me on User {
				id
				myStore {
					id
					rawId
					name {
						lang
						text
					}
					logo
					cover
					slogan
					defaultLanguage
					slug
					status
					shortDescription {
						lang
						text
					}
					longDescription {
						lang
						text
					}
				}
			}
    `
});
