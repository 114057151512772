import { withErrorBoundary } from 'react-error-boundary';
import { debug } from '../../../utils';
import DefaultErrorBoundary from './DefaultErrorBoundary';
const withErrorBoundaryHOC = (Component) => withErrorBoundary(Component, {
    FallbackComponent: DefaultErrorBoundary,
    onError(error, componentStack) {
        debug({
            error,
            componentStack
        });
    }
});
export default withErrorBoundaryHOC;
