import React, { PureComponent } from 'react';
import styles from './RadioButton.module.scss';
import { classNames } from '../../../utils';
class RadioCheckbox extends PureComponent {
    onChange = () => {
        const { id, onChange } = this.props;
        onChange(id);
    };
    render() {
        const { id, label, inline, isChecked } = this.props;
        return (React.createElement("div", { className: classNames(styles, 'container', { isChecked, inline }), onClick: this.onChange, onKeyDown: () => { }, role: "button", tabIndex: 0, id: id, "data-test": id },
            React.createElement("div", { className: styles.box },
                React.createElement("div", { className: styles.radio })),
            React.createElement("div", { className: styles.labelText }, label)));
    }
}
export default RadioCheckbox;
