import React from 'react';
import { isMobileBrowser } from '../../../../utils';
import Zoom from './svg/zoom.svg';
import styles from './ImageDetail.module.scss';
import t from './i18n';
const ImageDetail = () => (React.createElement("p", { className: styles.container },
    React.createElement("span", { className: styles.zoom },
        React.createElement(Zoom, null)),
    isMobileBrowser() ? t.touchAndDrag : t.moveYourMouse));
export default ImageDetail;
