import React, { PureComponent } from 'react';
import { pathOr } from 'ramda';
import { convertSrc } from '../../utils';
import ProfileMenu from './ProfileMenu';
import styles from './UserDropdown.module.scss';
import { Dropdown } from '../Dropdown';
import { Icon } from '../Icon';
class UserDropdown extends PureComponent {
    render() {
        const { user } = this.props;
        const { firstName, lastName, avatar, email, messagesCount, shopsCount } = user;
        const myStoreId = pathOr(null, ['myStore', 'rawId'], user);
        const isWizardComplete = pathOr(false, ['wizardStore', 'completed'], user);
        return (React.createElement("div", { className: styles.container },
            React.createElement(Dropdown, { withIcon: true, dataTest: "userDropdownButton" },
                React.createElement("trigger", null,
                    React.createElement("div", { className: styles.user },
                        React.createElement("div", { className: styles.avatar }, avatar ? (React.createElement("img", { className: styles.avatarImg, src: convertSrc('small', avatar), alt: "img" })) : (React.createElement(Icon, { type: "person", size: 16 }))),
                        (firstName || lastName) && (React.createElement("div", { className: styles.greeting }, `Hi, ${firstName} `)))),
                React.createElement("content", null,
                    React.createElement(ProfileMenu, { lastName: lastName, firstName: firstName, 
                        // messagesCount={messagesCount}
                        // shopsCount={shopsCount}
                        email: email, avatar: avatar, myStoreId: myStoreId, isWizardComplete: isWizardComplete })))));
    }
}
export default UserDropdown;
