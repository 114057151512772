import { fetchQuery, graphql } from 'react-relay';
const AUTOCOMPLETE_PRODUCT_NAME_QUERY = graphql `
  query fetchAutoCompleteProductName_Query($name: String!) {
    search {
      autoCompleteProductName(name: $name) {
        edges {
          node
        }
      }
    }
  }
`;
const fetchAutoCompleteProductName = (environment, variables) => fetchQuery(environment, AUTOCOMPLETE_PRODUCT_NAME_QUERY, variables);
export default fetchAutoCompleteProductName;
