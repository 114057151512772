import { assoc, filter, isEmpty, reduce, whereEq } from 'ramda';
const filterVariantsByAttributes = (selectedAttributes, variants) => {
    if (isEmpty(selectedAttributes)) {
        return variants;
    }
    return filter((variant) => {
        const attributes = reduce((acc, attribute) => assoc(attribute.attribute.id, attribute.value, acc), {}, variant.attributes);
        return whereEq(selectedAttributes, attributes);
    }, variants);
};
export default filterVariantsByAttributes;
