import { graphql } from 'react-relay';
import { basicMutation } from './basicMutation';
const mutation = graphql `
	mutation UpdateStoreSubscriptionMutation(
		$input: UpdateStoreSubscriptionInput!
	) {
		updateStoreSubscription(input: $input) {
			id
			storeId
			currency
			value
			walletAddress
			trialStartDate
			status
		}
	}
`;
const updateStoreSubscriptionMutation = basicMutation(mutation, 'updateStoreSubscription');
export default updateStoreSubscriptionMutation;
