import React from 'react';
import { join } from 'ramda';
import { Button } from '../../../../components/common/Button';
import styles from './ProductButtons.module.scss';
import t from './i18n';
const ProductButtons = (props) => {
    const isPreOrderAvailable = props.quantity === 0 && props.preOrder && props.preOrderDays;
    return (React.createElement("div", { className: styles.container },
        React.createElement("div", { className: styles.buttons },
            React.createElement(Button, { big: true, isLoading: props.isLoading, disabled: !props.quantity && !props.preOrder, onClick: props.onBuyNow, onMouseDown: props.onAddToCartTracker, dataTest: "product-BuyNow" }, isPreOrderAvailable ? t.preOrder : t.buyNow),
            React.createElement(Button, { id: "productAddToCart", wireframe: true, big: true, disabled: !props.quantity && !props.preOrder, onClick: !props.isAddToCart
                    ? props.onAddToCart
                    : () => {
                        props.router.push('/cart');
                    }, onMouseDown: props.onAddToCartTracker, dataTest: "product-addToCart", isLoading: props.isLoadingAddToCart }, !props.isAddToCart ? t.addToCart : t.viewCart)),
        props.unselectedAttr && (React.createElement("div", { className: styles.message },
            t.youMustSelectAnAttribute,
            " ",
            join(', ', props.unselectedAttr)))));
};
export default ProductButtons;
