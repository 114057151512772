import { t } from '../../../../../../translation/utils';
const translations = {
    en: {
        labelVariants: 'Variants',
        labelDelivery: 'Delivery',
        nameIsRequired: 'Name is required',
        shortDescriptionIsRequired: 'Short description is required',
        longDescriptionIsRequired: 'Long description is required',
        categoryIsRequired: 'Category is required',
        SKUIsRequired: 'SKU is required',
        priceIsRequired: 'Price is required',
        metricsError: 'Please, specify all metrics',
        addAtLeastOneDeliveryServiceOrPickup: 'Add at least one delivery service',
        addAtLeastOneDeliveryDelivery: 'Add at least one delivery service',
        productPhotos: 'Product photos',
        generalSettings: 'General settings',
        labelProductName: 'Product name',
        labelShortDescription: 'Short description',
        labelLongDescription: 'Long description',
        labelCurrency: 'Currency',
        labelSKU: 'SKU',
        labelSEOTitle: 'SEO title',
        labelSEODescription: 'SEO description',
        pricing: 'PRICING',
        labelPrice: 'Price',
        labelCashback: 'Cashback',
        percent: 'Percent',
        labelDiscount: 'Discount',
        labelQuantity: 'In stock',
        characteristics: 'Characteristics',
        availableForPreOrder: 'Available for pre-order',
        labelLeadTime: 'Lead time (days)',
        updateProduct: 'Update product',
        createProduct: 'Create product',
        currentlyYouHaveNoVariantsForYourProduct: 'Currently you have no variants for your product',
        addVariantsIfYouNeedSome: 'Add variants if you need some.',
        addVariant: 'Add variant',
        save: 'Save',
        sendToModeration: ' Send to moderation',
        variantTabWarnMessages: {
            youCantAddVariant: 'You can’t add variant until you set at least one item characteristic.',
            thisCategory: 'This category of goods does not require any characteristics.',
            сurrentlyThisOption: 'Currently this option is unavailable while item editing.'
        },
        baseProductIsOnModeration: 'Product is on moderation',
        baseProductIsBlocked: 'Product is blocked, contact the support service',
        close: 'Close.',
        categoryWarn: 'If you change the category, variants for this product will be deleted',
        preview: 'Preview'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
