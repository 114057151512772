import { fetchQuery, graphql } from 'react-relay';
const AUTOCOMPLETE_STORE_NAME_QUERY = graphql `
  query fetchAutoCompleteStoreName_Query($name: String!) {
    search {
      autoCompleteStoreName(name: $name) {
        edges {
          node
        }
      }
    }
  }
`;
const fetchAutoCompleteStoreName = (environment, variables) => fetchQuery(environment, AUTOCOMPLETE_STORE_NAME_QUERY, variables);
export default fetchAutoCompleteStoreName;
