import React from 'react';
import { formatStatus } from '../utils';
import styles from '../OrderInvoice.module.scss';
import t from './i18n';
const OrderInvoiceData = ({ receiverName, slug, trackId, state }) => (React.createElement("div", { className: styles.invoiceData },
    React.createElement("div", null,
        React.createElement("span", { className: styles.customerData },
            t.customer,
            ":",
            ' '),
        ' ',
        receiverName),
    React.createElement("div", null,
        React.createElement("span", { className: styles.customerData },
            t.order,
            ":",
            ' '),
        ' ',
        slug),
    React.createElement("div", null,
        React.createElement("span", { className: styles.customerData },
            t.trackID,
            ":",
            ' '),
        ' ',
        trackId),
    React.createElement("div", null,
        React.createElement("span", { className: styles.customerData },
            t.delivery,
            ":",
            ' ')),
    React.createElement("div", null,
        React.createElement("span", { className: styles.customerData },
            t.status,
            ":",
            ' '),
        formatStatus(state))));
export default OrderInvoiceData;
