import { uploadFile } from '../../utils';
const filePickerCallback = (onError) => (cb, value, meta) => {
    if (meta.filetype === 'image') {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.onchange = function onchange() {
            const file = this.files[0];
            uploadFile(file)
                .then((result) => {
                if (!result || result.url == null) {
                    alert('Error :(');
                }
                cb(result.url, { title: file.name });
                return result;
            })
                .finally(() => {
            })
                .catch((error) => {
                onError(error);
            });
        };
        input.click();
    }
};
export default filePickerCallback;
