import React, { PureComponent } from 'react';
import { withRouter } from 'found';
import { Button } from '../../../components/common/Button';
import Logo from '../svg/logo.svg';
import ErrorImage from '../svg/error.svg';
import styles from '../error.module.scss';
import t from './i18n';
class Error extends PureComponent {
    handleToMainPage = () => {
        this.props.router.replace('/');
    };
    render() {
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.logoWrapper },
                React.createElement("div", { className: styles.logo },
                    React.createElement(Logo, null))),
            React.createElement("div", { className: styles.messageWrapper },
                React.createElement("div", { className: styles.image },
                    React.createElement(ErrorImage, null)),
                React.createElement("div", { className: styles.text },
                    t.oopsSomethingGoneWrong,
                    "n",
                    React.createElement("br", null),
                    t.crushed),
                React.createElement("div", { className: styles.button },
                    React.createElement(Button, { big: true, type: "button", onClick: this.handleToMainPage, dataTest: "errorPageButton" }, t.startFromMainPage)))));
    }
}
export default withRouter(Error);
