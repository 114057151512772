import { commitMutation, graphql } from 'react-relay';
const mutation = graphql `
	mutation UpdateStoreMainMutation($input: UpdateStoreInput!) {
		updateStore(input: $input) {
			id
			name {
				lang
				text
			}
			longDescription {
				lang
				text
			}
			shortDescription {
				lang
				text
			}
			defaultLanguage
			slug
			slogan
			logo
			status
		}
	}
`;
const commit = (params) => commitMutation(params.environment, {
    mutation,
    variables: params.variables,
    onCompleted: params.onCompleted,
    onError: params.onError,
    updater: (relayStore) => {
        const updateStore = relayStore.getRootField('updateStore');
        const logo = updateStore.getValue('logo');
        const storeId = updateStore.getValue('id');
        const store = relayStore.get(storeId);
        store.setValue(logo, 'logo');
    }
});
export default { commit };
