/**
 * @generated SignedSource<<91d7bb5c9fe9b5e82e1d779989c9f755>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lang",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "concreteType": "Translation",
        "kind": "LinkedField",
        "name": "name",
        "plural": true,
        "selections": [
            (v1 /*: any*/),
            (v2 /*: any*/)
        ],
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawId",
        "storageKey": null
    }, v5 = [
        (v2 /*: any*/),
        (v1 /*: any*/)
    ], v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
            }
        ],
        "storageKey": null
    };
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                {
                    "count": null,
                    "cursor": null,
                    "direction": "forward",
                    "path": null
                },
                {
                    "count": null,
                    "cursor": null,
                    "direction": "forward",
                    "path": [
                        "myStore",
                        "baseProducts"
                    ]
                }
            ]
        },
        "name": "NewProduct_me",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "Store",
                "kind": "LinkedField",
                "name": "myStore",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "logo",
                        "storageKey": null
                    },
                    (v3 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "GraphQLWarehouse",
                        "kind": "LinkedField",
                        "name": "warehouses",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Address",
                                "kind": "LinkedField",
                                "name": "addressFull",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "countryCode",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": "baseProducts",
                        "args": null,
                        "concreteType": "BaseProductsConnection",
                        "kind": "LinkedField",
                        "name": "__Wizard_baseProducts_connection",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "BaseProductsEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "BaseProduct",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                            (v0 /*: any*/),
                                            (v4 /*: any*/),
                                            (v3 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Translation",
                                                "kind": "LinkedField",
                                                "name": "shortDescription",
                                                "plural": true,
                                                "selections": (v5 /*: any*/),
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Category",
                                                "kind": "LinkedField",
                                                "name": "category",
                                                "plural": false,
                                                "selections": [
                                                    (v0 /*: any*/),
                                                    (v4 /*: any*/)
                                                ],
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "storeId",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "currency",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": "products",
                                                "args": null,
                                                "concreteType": "ProductsConnection",
                                                "kind": "LinkedField",
                                                "name": "__Wizard_products_connection",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "ProductsEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": [
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "concreteType": "Product",
                                                                "kind": "LinkedField",
                                                                "name": "node",
                                                                "plural": false,
                                                                "selections": [
                                                                    (v0 /*: any*/),
                                                                    (v4 /*: any*/),
                                                                    {
                                                                        "alias": null,
                                                                        "args": null,
                                                                        "kind": "ScalarField",
                                                                        "name": "price",
                                                                        "storageKey": null
                                                                    },
                                                                    {
                                                                        "alias": null,
                                                                        "args": null,
                                                                        "kind": "ScalarField",
                                                                        "name": "preOrder",
                                                                        "storageKey": null
                                                                    },
                                                                    {
                                                                        "alias": null,
                                                                        "args": null,
                                                                        "kind": "ScalarField",
                                                                        "name": "preOrderDays",
                                                                        "storageKey": null
                                                                    },
                                                                    {
                                                                        "alias": null,
                                                                        "args": null,
                                                                        "kind": "ScalarField",
                                                                        "name": "discount",
                                                                        "storageKey": null
                                                                    },
                                                                    {
                                                                        "alias": null,
                                                                        "args": null,
                                                                        "kind": "ScalarField",
                                                                        "name": "photoMain",
                                                                        "storageKey": null
                                                                    },
                                                                    {
                                                                        "alias": null,
                                                                        "args": null,
                                                                        "kind": "ScalarField",
                                                                        "name": "additionalPhotos",
                                                                        "storageKey": null
                                                                    },
                                                                    {
                                                                        "alias": null,
                                                                        "args": null,
                                                                        "kind": "ScalarField",
                                                                        "name": "vendorCode",
                                                                        "storageKey": null
                                                                    },
                                                                    {
                                                                        "alias": null,
                                                                        "args": null,
                                                                        "kind": "ScalarField",
                                                                        "name": "cashback",
                                                                        "storageKey": null
                                                                    },
                                                                    {
                                                                        "alias": null,
                                                                        "args": null,
                                                                        "concreteType": "ProdAttrValue",
                                                                        "kind": "LinkedField",
                                                                        "name": "attributes",
                                                                        "plural": true,
                                                                        "selections": [
                                                                            {
                                                                                "alias": null,
                                                                                "args": null,
                                                                                "kind": "ScalarField",
                                                                                "name": "value",
                                                                                "storageKey": null
                                                                            },
                                                                            {
                                                                                "alias": null,
                                                                                "args": null,
                                                                                "kind": "ScalarField",
                                                                                "name": "metaField",
                                                                                "storageKey": null
                                                                            },
                                                                            {
                                                                                "alias": null,
                                                                                "args": null,
                                                                                "concreteType": "Attribute",
                                                                                "kind": "LinkedField",
                                                                                "name": "attribute",
                                                                                "plural": false,
                                                                                "selections": [
                                                                                    (v0 /*: any*/),
                                                                                    (v4 /*: any*/),
                                                                                    {
                                                                                        "alias": null,
                                                                                        "args": null,
                                                                                        "concreteType": "Translation",
                                                                                        "kind": "LinkedField",
                                                                                        "name": "name",
                                                                                        "plural": true,
                                                                                        "selections": (v5 /*: any*/),
                                                                                        "storageKey": null
                                                                                    },
                                                                                    {
                                                                                        "alias": null,
                                                                                        "args": null,
                                                                                        "concreteType": "AttributeMetaField",
                                                                                        "kind": "LinkedField",
                                                                                        "name": "metaField",
                                                                                        "plural": false,
                                                                                        "selections": [
                                                                                            {
                                                                                                "alias": null,
                                                                                                "args": null,
                                                                                                "kind": "ScalarField",
                                                                                                "name": "values",
                                                                                                "storageKey": null
                                                                                            },
                                                                                            {
                                                                                                "alias": null,
                                                                                                "args": null,
                                                                                                "concreteType": "TranslatedValue",
                                                                                                "kind": "LinkedField",
                                                                                                "name": "translatedValues",
                                                                                                "plural": true,
                                                                                                "selections": [
                                                                                                    {
                                                                                                        "alias": null,
                                                                                                        "args": null,
                                                                                                        "concreteType": "Translation",
                                                                                                        "kind": "LinkedField",
                                                                                                        "name": "translations",
                                                                                                        "plural": true,
                                                                                                        "selections": [
                                                                                                            (v1 /*: any*/)
                                                                                                        ],
                                                                                                        "storageKey": null
                                                                                                    }
                                                                                                ],
                                                                                                "storageKey": null
                                                                                            }
                                                                                        ],
                                                                                        "storageKey": null
                                                                                    }
                                                                                ],
                                                                                "storageKey": null
                                                                            }
                                                                        ],
                                                                        "storageKey": null
                                                                    },
                                                                    (v6 /*: any*/)
                                                                ],
                                                                "storageKey": null
                                                            },
                                                            (v7 /*: any*/)
                                                        ],
                                                        "storageKey": null
                                                    },
                                                    (v8 /*: any*/)
                                                ],
                                                "storageKey": null
                                            },
                                            (v6 /*: any*/)
                                        ],
                                        "storageKey": null
                                    },
                                    (v7 /*: any*/)
                                ],
                                "storageKey": null
                            },
                            (v8 /*: any*/)
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "User",
        "abstractKey": null
    };
})();
node.hash = "566c55b52ef823a08370fff9e46f42dd";
export default node;
