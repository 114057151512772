import { t } from '../../../../translation/utils';
const translations = {
    en: {
        labelSearchOrder: 'Search Order',
        labelOrderStatus: 'Order Status',
        labelFrom: 'From',
        labelTo: 'To'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
