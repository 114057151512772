import { t } from '../../../translation/utils';
const translations = {
    en: {
        paid: 'Paid',
        notPaid: 'Not paid',
        orderWasSuccessfullySent: 'Order was successfully sent.',
        orderWasSuccessfullyConfirm: 'Order was successfully confirm.',
        somethingIsGoingWrong: 'Something is going wrong :(',
        orderSuccessfullyCanceled: 'Order successfully canceled.',
        orderSuccessfullyComplete: 'Order successfully complete.',
        chargeFeefullyComplete: 'Charge fee successfully paid.',
        ok: 'Ok.',
        order: 'ORDER',
        orderStatusInfo: 'Order status info',
        status: 'Status',
        billingStatus: 'Billing status',
        paymentInfo: 'Payment Info',
        havingTroubles: 'Having Troubles?',
        openTicket: 'Open Ticket',
        invoice: 'Invoice',
        theProductWasDeleted: 'The product was deleted',
        thisProductWasBougthOnPreOrder: 'This product was bought on pre-order.',
        leadTime: 'Lead time (days):',
        labelCustomer: 'Customer',
        labelContacts: 'Contacts',
        labelDate: 'Date',
        labelTime: 'Time',
        labelDelivery: 'Delivery',
        labelDeliveryPrice: 'Delivery price',
        labelTrackID: 'Track ID',
        labelQuantity: 'Quantity',
        labelTotalAmount: 'Total amount',
        labelCouponDiscount: 'Coupon discount',
        //
        title: 'Are you sure to complete order?',
        confirmText: 'Complete',
        cancelText: 'Cancel',
        complete: 'Complete',
        dispute: 'Dispute',
        createDispute: 'Create dispute',
        writeComment: 'Please, write a comment',
        labelComment: 'Comment'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
