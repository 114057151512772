import React, { Component, createRef } from 'react';
import { Icon } from '../Icon';
import styles from './StringLoadMore.module.scss';
import t from './i18n';
class StringLoadMore extends Component {
    constructor(props) {
        super(props);
        this.container = createRef();
        this.text = createRef();
        this.state = { isButton: false };
    }
    componentDidMount() {
        const containerEl = this.container.current;
        const textEl = this.text.current;
        const widthContainerEl = containerEl.getBoundingClientRect().width;
        const widthTextEl = textEl.getBoundingClientRect().width;
        if (widthContainerEl < widthTextEl) {
            textEl.style.display = 'block';
            textEl.style.width = '100%';
            textEl.style.overflow = 'hidden';
            textEl.style.textOverflow = 'ellipsis';
            this.setState({ isButton: true });
        }
    }
    container;
    text;
    handleOnClick = () => {
        const textEl = this.text.current;
        textEl.style.display = 'inline-block';
        textEl.style.width = 'auto';
        textEl.style.overflow = '';
        textEl.style.textOverflow = 'inherit';
        textEl.style.whiteSpace = 'normal';
        this.setState({ isButton: false });
    };
    render() {
        const { isButton } = this.state;
        return (React.createElement("div", { className: styles.container, ref: this.container },
            React.createElement("span", { className: styles.text, ref: this.text }, this.props.text),
            isButton && (React.createElement("button", { className: styles.button, onClick: this.handleOnClick },
                React.createElement("span", { className: styles.buttonText }, t.readMore),
                React.createElement(Icon, { inline: true, type: "arrowExpand" })))));
    }
}
export default StringLoadMore;
