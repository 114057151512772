import React, { PureComponent } from 'react';
import { pathOr } from 'ramda';
import moment from 'moment';
import { getCookie, setCookie } from '../../../utils';
import styles from './PageDecorator.module.scss';
import AppContext from '../AppContext';
import UserData from '../UserData';
import HeaderDisclaimer from '../HeaderDisclaimer';
import Main from '../Main';
import Footer from '../Footer';
import Header from '../Header';
export default (OriginalComponent, config = { withoutCategories: false }) => class Page extends PureComponent {
    constructor(props) {
        super(props);
        const cookieLocale = getCookie('locale');
        if (!cookieLocale) {
            if (process.env.BROWSER) {
                // const browserLang = window.navigator
                //   ? window.navigator.language ||
                //     window.navigator.systemLanguage ||
                //     window.navigator.userLanguage
                //   : undefined;
                // const browserLocale = browserLang
                //   ? browserLang.substr(0, 2).toLowerCase()
                //   : 'en';
                // setCookie(
                //   'locale',
                //   browserLocale,
                //   moment()
                //     .utc()
                //     .add(30, 'd')
                //     .toDate(),
                // );
                // убрать потом
                setCookie('locale', 'en', moment()
                    .utc()
                    .add(30, 'd')
                    .toDate());
            }
            else {
                setCookie('locale', 'en', moment()
                    .utc()
                    .add(30, 'd')
                    .toDate());
            }
        }
    }
    setLang = (lang) => {
        const locale = getCookie('locale');
        if (locale && locale.value !== lang && process.env.BROWSER) {
            setCookie('locale', lang, moment()
                .utc()
                .add(30, 'd')
                .toDate());
            window.location.reload();
        }
    };
    render() {
        return (React.createElement(AppContext.Consumer, null, ({ environment }) => (React.createElement(UserData, { environment: environment, me: this.props.me }, ({ isShopCreated, userData, totalCount }) => (React.createElement("div", { className: styles.container },
            process.env.NODE_ENV === 'production' && (React.createElement(HeaderDisclaimer, null)),
            React.createElement(Header, { isShopCreated: isShopCreated, userData: userData, totalCount: totalCount, searchValue: pathOr('', ['match', 'location', 'query', 'search'], this.props), withoutCategories: config.withoutCategories, setLang: this.setLang }),
            React.createElement(Main, null,
                React.createElement(OriginalComponent, { ...this.props })),
            React.createElement(Footer, { isShopCreated: isShopCreated })))))));
    }
};
