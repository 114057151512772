import React, { Component } from 'react';
import { Count } from '../../Count';
import styles from './Tabs.module.scss';
import { classNames } from '../../../utils';
class Tabs extends Component {
    handleClick = (selected) => {
        this.props.onClick(selected);
    };
    renderTitles = () => {
        const { children, selected } = this.props;
        const buildLabel = (child, index) => {
            const { label, amount } = child.props;
            return (React.createElement("button", { key: index, className: classNames(styles, 'label', { active: selected === index }), onClick: () => {
                    this.handleClick(index);
                } },
                React.createElement("strong", { className: styles.labelText }, label),
                Boolean(amount) && (React.createElement("div", { className: styles.amount },
                    React.createElement(Count, { amount: amount, type: "blue" })))));
        };
        return React.createElement("div", { className: styles.labels }, children.map(buildLabel));
    };
    render() {
        const { selected, withoutPanel } = this.props;
        return (React.createElement("div", { className: styles.container },
            this.renderTitles(),
            !withoutPanel && (React.createElement("div", { className: styles.panel }, this.props.children[selected]))));
    }
}
export default Tabs;
