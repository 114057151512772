import React, { Component } from 'react';
import { isEmpty, isNil } from 'ramda';
import { Icon } from '../Icon';
import styles from './PopUpWrapper.module.scss';
import { classNames } from '../../utils';
class PopUpWrapper extends Component {
    click = () => {
    };
    static defaultProps = { description: '' };
    render() {
        const { title, description, onClose } = this.props;
        const closeButton = (React.createElement("div", { className: styles.close, onClick: onClose, onKeyDown: () => { }, role: "button", tabIndex: 0 },
            React.createElement(Icon, { type: "cross" })));
        return (React.createElement("aside", { className: styles.container },
            isNil(onClose) ? null : closeButton,
            React.createElement("h2", { className: classNames(styles, 'title', { hasDescription: !isEmpty(description) }) }, title),
            isEmpty(description) ? null : (React.createElement("p", { className: styles.description }, description)),
            this.props.render()));
    }
}
export default PopUpWrapper;
