import React, { Component } from 'react';
import { isNil, pathOr } from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import { Col, Row } from '../../../../layout';
import { classNames, formatPrice } from '../../../../utils';
import { CancelOrderMutation, ChargeFeeMutation, ConfirmOrderMutation, SendOrderMutation } from '../../../../relay/mutations';
import { Modal } from '../../../../components/Modal';
import { Input } from '../../../../components/common/Input';
import { Button } from '../../../../components/common/Button';
import { Confirmation } from '../../../../components/Confirmation';
import TextWithLabel from '../TextWithLabel';
import { getStatusStringFromEnum } from '../utils';
import styles from './ManageOrderBlock.module.scss';
import t from './i18n';
class ManageOrderBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSendInProgress: false,
            isCancelInProgress: false,
            isChargeFeeInProgress: false,
            isSendOrderModalShown: false,
            isCancelOrderModalShown: false,
            isChargeFeeModalShown: false,
            trackNumber: null,
            comment: null
        };
    }
    handleTrackIdChanged = (e) => {
        if (!isNil(e)) {
            const value = pathOr(null, ['target', 'value'], e);
            this.setState({ trackNumber: value });
        }
    };
    handleCommentChanged = (e) => {
        if (!isNil(e)) {
            const value = pathOr(null, ['target', 'value'], e);
            this.setState({ comment: value });
        }
    };
    handleSendOrderModalClose = () => {
        this.setState({ isSendOrderModalShown: false });
    };
    handleCancelOrderModalClose = () => {
        this.setState({ isCancelOrderModalShown: false });
    };
    handleChargeFeeModalClose = () => {
        this.setState({ isChargeFeeModalShown: false });
    };
    sendOrder = () => {
        this.setState({ isSendInProgress: true });
        const params = {
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    orderSlug: this.props.orderSlug,
                    trackId: this.state.trackNumber,
                    comment: this.state.comment
                }
            },
            environment: this.props.environment,
            onCompleted: (response, errors) => {
                this.setState({
                    isSendInProgress: false,
                    isSendOrderModalShown: false
                });
                this.props.onOrderSend(!errors);
            },
            onError: () => {
                this.setState({ isSendInProgress: false });
                this.props.onOrderSend(false);
            }
        };
        SendOrderMutation.commit(params);
    };
    confirmOrder = () => {
        this.setState({ isSendInProgress: true });
        const variables = {
            input: {
                clientMutationId: uuidv4(),
                orderSlug: this.props.orderSlug
            }
        };
        const params = {
            variables,
            environment: this.props.environment,
            onCompleted: (response, errors) => {
                this.setState({ isSendInProgress: false });
                this.props.onOrderConfirm(!errors);
            },
            onError: () => {
                this.setState({ isSendInProgress: false });
                this.props.onOrderConfirm(false);
            }
        };
        ConfirmOrderMutation.commit(params);
    };
    cancelOrder = () => {
        this.setState({
            isCancelInProgress: true,
            isCancelOrderModalShown: false
        });
        const params = {
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    orderSlug: this.props.orderSlug,
                    committerRole: 'SELLER'
                }
            },
            environment: this.props.environment,
            onCompleted: (response, errors) => {
                this.setState({ isCancelInProgress: false });
                this.props.onOrderCancel(!errors);
            },
            onError: () => {
                this.setState({ isCancelInProgress: false });
                this.props.onOrderCancel(false);
            }
        };
        CancelOrderMutation.commit(params);
    };
    chargeFee = () => {
        this.setState({
            isChargeFeeInProgress: true,
            isChargeFeeModalShown: false
        });
        const params = {
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    orderId: this.props.orderId
                }
            },
            environment: this.props.environment,
            onCompleted: (response, errors) => {
                this.setState({ isChargeFeeInProgress: false });
                this.props.onChargeFee(!errors);
            },
            onError: () => {
                this.setState({ isCancelInProgress: false });
                this.props.onChargeFee(false);
            }
        };
        ChargeFeeMutation.commit(params);
    };
    render() {
        const { orderFee, isAbleToSend, isAbleToConfirm, orderBilling } = this.props;
        const { isSendInProgress, isCancelInProgress, isCancelOrderModalShown, isChargeFeeModalShown, isChargeFeeInProgress } = this.state;
        return (React.createElement("div", { className: styles.container },
            React.createElement(Modal, { showModal: this.state.isSendOrderModalShown, onClose: this.handleSendOrderModalClose },
                React.createElement("div", { className: styles.sendOrderModal },
                    React.createElement("div", { className: styles.title },
                        t.sendYourProduct,
                        React.createElement("br", null),
                        t.toCustomer),
                    React.createElement("div", { className: styles.description }, t.doNotForgetToAttach),
                    React.createElement("div", { className: styles.inputWrapperTrackId },
                        React.createElement(Input, { fullWidth: true, id: "send-order-modal-trackId", label: t.labelTrackNumber, onChange: this.handleTrackIdChanged, value: this.state.trackNumber || '', limit: 50 })),
                    React.createElement("div", { className: styles.inputWrapperComment },
                        React.createElement(Input, { fullWidth: true, id: "send-order-modal-comment", label: t.labelComment, onChange: this.handleCommentChanged, value: this.state.comment || '', limit: 100 })),
                    React.createElement("div", { className: styles.sendOrderButtonWrapper },
                        React.createElement(Button, { big: true, isLoading: isSendInProgress, onClick: this.sendOrder }, t.sendOrder)))),
            React.createElement(Confirmation, { showModal: isCancelOrderModalShown, onClose: this.handleCancelOrderModalClose, title: t.cancelOrderTitle, description: t.cancelOrderDescription, onCancel: this.handleCancelOrderModalClose, onConfirm: this.cancelOrder, confirmText: t.cancelOrderConfirmText, cancelText: t.cancelOrderCancelText }),
            (isAbleToSend || isAbleToConfirm) && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles.title },
                    React.createElement("strong", null, t.manage)),
                React.createElement("div", { className: styles.buttons },
                    isAbleToSend && (React.createElement("div", { className: styles.sendButtonWrapper },
                        React.createElement(Button, { big: true, onClick: () => this.setState({ isSendOrderModalShown: true }) }, t.sendNow))),
                    isAbleToConfirm && (React.createElement("div", { className: styles.sendButtonWrapper },
                        React.createElement(Button, { big: true, onClick: this.confirmOrder, isLoading: isSendInProgress }, t.confirmOrder))),
                    isAbleToConfirm && (React.createElement("div", { className: styles.cancelButtonWrapper },
                        React.createElement(Button, { wireframe: true, big: true, isLoading: isCancelInProgress, onClick: () => {
                                this.setState({ isCancelOrderModalShown: true });
                            } }, t.cancelOrder)))))),
            orderFee && (React.createElement("div", { className: classNames(styles, 'orderFee', { alone: !isAbleToSend && !isAbleToConfirm }) },
                React.createElement(Confirmation, { showModal: isChargeFeeModalShown, onClose: this.handleChargeFeeModalClose, title: t.areYouSureToPayChargeFee, description: t.pleaseCheckCard, onCancel: this.handleChargeFeeModalClose, onConfirm: this.chargeFee, confirmText: t.payFee, cancelText: t.cancel }),
                React.createElement("div", { className: styles.title },
                    React.createElement("strong", null, t.chargeFee)),
                React.createElement("div", { className: styles.desc },
                    React.createElement("div", { className: styles.infoBlockItem },
                        React.createElement(Row, null,
                            React.createElement(Col, { size: 12, lg: 5 },
                                React.createElement(TextWithLabel, { label: t.storiqaFee, text: `${formatPrice(orderFee.amount)} ${orderFee.currency}` })),
                            React.createElement(Col, { size: 12, lg: 7 },
                                React.createElement(TextWithLabel, { label: t.status, text: getStatusStringFromEnum(orderFee.status) })))),
                    orderBilling
                        && orderBilling.stripeFee && (React.createElement("div", { className: styles.infoBlockItem },
                        React.createElement(Row, null,
                            React.createElement(Col, { size: 12, lg: 5 },
                                React.createElement(TextWithLabel, { label: t.bankTransactionFee, text: `${formatPrice(orderBilling.stripeFee)} ${orderBilling.sellerCurrency}` })))))),
                orderFee.status === 'NOT_PAID' && (React.createElement("div", { className: styles.cancelButtonWrapper },
                    React.createElement(Button, { big: true, isLoading: isChargeFeeInProgress, onClick: () => {
                            this.setState({ isChargeFeeModalShown: true });
                        } }, t.payFee)))))));
    }
}
export default ManageOrderBlock;
