import React from 'react';
import { Icon } from '../../../../../components/Icon';
import { Col } from '../../../../../layout';
import styles from './ProductsTableHeader.module.scss';
import t from './i18n';
import { classNames } from '../../../../../utils';
const ProductsTableHeader = () => (React.createElement("div", { className: styles.headerRowWrap },
    React.createElement(Col, { size: 1, sm: 2, md: 2, lg: 2, xl: 1, mdVisible: true }),
    React.createElement(Col, { size: 4, sm: 4, md: 4, lg: 3, xl: 2 },
        React.createElement("div", { className: classNames(styles, 'colColor', 'name') },
            React.createElement("span", null, t.name),
            React.createElement(Icon, { inline: true, type: "sortArrows" }))),
    React.createElement(Col, { size: 2, sm: 2, md: 2, lg: 2, xl: 2, xlVisible: true },
        React.createElement("div", { className: styles.colColor },
            React.createElement("span", null, t.category),
            React.createElement(Icon, { inline: true, type: "sortArrows" }))),
    React.createElement(Col, { size: 3, sm: 3, md: 3, lg: 3, xl: 2, mdVisible: true },
        React.createElement("div", { className: styles.colColor },
            React.createElement("span", null, t.price),
            React.createElement(Icon, { inline: true, type: "sortArrows" }))),
    React.createElement(Col, { size: 3, sm: 3, md: 3, lg: 3, xl: 2, lgVisible: true },
        React.createElement("div", { className: styles.colColor },
            React.createElement("span", null, t.cashback),
            React.createElement(Icon, { inline: true, type: "sortArrows" }))),
    React.createElement(Col, { size: 2, sm: 2, md: 2, lg: 2, xl: 2, xlVisible: true },
        React.createElement("div", { className: styles.colColor },
            React.createElement("span", null, t.characteristics),
            React.createElement(Icon, { inline: true, type: "sortArrows" })))));
export default ProductsTableHeader;
