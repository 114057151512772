import React, { Component } from 'react';
import { filter, head, map, pathOr, prop, propEq, sort } from 'ramda';
import moment from 'moment';
import { withRouter } from 'found';
import { fullDateFromTimestamp, shortDateFromTimestamp, stringFromTimestamp, timeFromTimestamp } from '../../../utils/formatDate';
import ProductBlock from './ProductBlock';
import StatusList from './StatusList';
import { Modal } from '../../../components/Modal';
import { Button } from '../../../components/common/Button';
import { Col, Row } from '../../../layout';
import { withShowAlert } from '../../../components/Alerts/AlertContext';
import { addressToString, formatPrice, getNameText } from '../../../utils';
import { AppContext } from '../../../components/App';
import TextWithLabel from './TextWithLabel';
import ManageOrderBlock from './ManageOrderBlock';
import ManageOrderBlockForUser from './ManageOrderBlockForUser';
import OpenTicketModal from './OpenTicketModal';
import { getStatusStringFromEnum } from './utils';
import styles from './OrderPage.module.scss';
import t from './i18n';
class OrderPage extends Component {
    static defaultProps = { showInvoice: false };
    constructor(props) {
        super(props);
        this.state = { isOpenTicketModalShown: false };
    }
    getDateFromTimestamp = (timestamp) => fullDateFromTimestamp(timestamp);
    getTimeFromTimestamp = (timestamp) => timeFromTimestamp(timestamp);
    getOrderDTO = (order) => {
        const { receiverPhone, receiverName } = order;
        const customerName = receiverName || '-';
        const customerAddress = addressToString(order.addressFull) || '—';
        // const customerPhone = customer.phone || null; // is replaced by: order.receiverPhone
        const attributes = map((item) => ({
            name: getNameText(pathOr([], ['attribute', 'name'], item), 'EN') || '',
            value: item.value
        }), pathOr([], ['product', 'attributes'], order));
        return {
            number: `${order.slug}`,
            product: {
                id: `${order.product && order.product.baseProduct
                    ? order.product.baseProduct.rawId
                    : 0}`,
                rawId: order.product ? order.product.rawId : null,
                storeId: order.storeId,
                name: pathOr(null, ['baseProduct', 'name', 0, 'text'], order.product),
                photoUrl: order.product && order.product.photoMain,
                category: {
                    id: `${pathOr(0, ['product', 'baseProduct', 'category', 'rawId'], order)}`,
                    name: prop('text', head(filter(propEq('EN', 'lang'), pathOr([], ['product', 'baseProduct', 'category', 'name'], order))))
                },
                price: order.product ? order.product.price : -1,
                attributes,
                preOrder: order.preOrder,
                preOrderDays: order.preOrderDays
            },
            customerName,
            customerAddress,
            customerPhone: receiverPhone,
            date: order.createdAt,
            delivery: order.deliveryCompany || '—',
            trackId: order.trackId || '—',
            quantity: order.quantity,
            subtotal: order.subtotal,
            totalAmount: order.totalAmount,
            deliveryPrice: order.deliveryPrice,
            couponPrice: order.couponDiscount,
            currency: order.currency,
            status: order.state,
            paymentStatus: order.paymentStatus ? t.paid : t.notPaid,
            statusHistory: map((historyEdge) => {
                const committer = historyEdge.node.committerRole;
                return {
                    date: `${shortDateFromTimestamp(historyEdge.node.committedAt)}\n${timeFromTimestamp(historyEdge.node.committedAt)}`,
                    committer,
                    status: getStatusStringFromEnum(historyEdge.node.state),
                    additionalInfo: historyEdge.node.comment
                };
            }, order && order.history ? sort((a, b) => moment(a.node.committedAt)
                .isBefore(b.node.committedAt), order.history.edges) : []),
            billingStatus: order && order.orderBilling ? order.orderBilling.state : null
        };
    };
    handleOrderSent = (success) => {
        if (success) {
            this.props.showAlert({
                type: 'success',
                text: t.orderWasSuccessfullySent,
                link: { text: t.ok }
            });
        }
        else {
            this.props.showAlert({
                type: 'danger',
                text: t.somethingIsGoingWrong,
                link: { text: t.ok }
            });
        }
    };
    handleOrderConfirm = (success) => {
        if (success) {
            this.props.showAlert({
                type: 'success',
                text: t.orderWasSuccessfullyConfirm,
                link: { text: t.ok }
            });
        }
        else {
            this.props.showAlert({
                type: 'danger',
                text: t.somethingIsGoingWrong,
                link: { text: t.ok }
            });
        }
    };
    handleOrderCanceled = (success) => {
        if (success) {
            this.props.showAlert({
                type: 'success',
                text: t.orderSuccessfullyCanceled,
                link: { text: t.ok }
            });
        }
        else {
            this.props.showAlert({
                type: 'danger',
                text: t.somethingIsGoingWrong,
                link: { text: t.ok }
            });
        }
    };
    handleOrderComplete = (success) => {
        if (success) {
            this.props.showAlert({
                type: 'success',
                text: t.orderSuccessfullyComplete,
                link: { text: 'Ok' }
            });
        }
        else {
            this.props.showAlert({
                type: 'danger',
                text: t.somethingIsGoingWrong,
                link: { text: 'Ok' }
            });
        }
    };
    handleChargeFee = (success) => {
        if (success) {
            this.props.showAlert({
                type: 'success',
                text: t.chargeFeefullyComplete,
                link: { text: 'Ok' }
            });
        }
        else {
            this.props.showAlert({
                type: 'danger',
                text: t.somethingIsGoingWrong,
                link: { text: 'Ok' }
            });
        }
    };
    invoice = () => {
        const { match: { location: { pathname } } } = this.props;
        if (process.env.BROWSER) {
            window.open(`${pathname}/invoice`);
        }
    };
    handlerOpenTicket = () => {
        this.setState({ isOpenTicketModalShown: true });
    };
    handleOpenTicketModalClose = () => {
        this.setState({ isOpenTicketModalShown: false });
    };
    render() {
        const { order: orderFromProps, isPaymentInfoCanBeShown, email, showAlert, showInvoice, isAbleToManageOrder } = this.props;
        const { isOpenTicketModalShown } = this.state;
        const order = this.getOrderDTO(orderFromProps);
        return (React.createElement(AppContext.Consumer, null, ({ environment }) => (React.createElement("div", { className: styles.container },
            React.createElement(Modal, { showModal: isOpenTicketModalShown, onClose: this.handleOpenTicketModalClose },
                React.createElement(OpenTicketModal, { email: email, showAlert: showAlert })),
            React.createElement("div", { className: styles.mainBlock },
                React.createElement("div", { className: styles.orderNumber },
                    React.createElement("strong", null,
                        t.order,
                        " #",
                        order.number)),
                React.createElement("div", { className: styles.statusBlock },
                    React.createElement("div", { className: styles.title },
                        React.createElement("strong", null, t.orderStatusInfo)),
                    React.createElement("div", { className: styles.statusPaymentBlock },
                        React.createElement("div", { className: styles.statusesBlock },
                            React.createElement("div", { className: styles.statusItem },
                                React.createElement("div", { className: styles.statusTitle }, t.status),
                                React.createElement("div", { className: styles.statusInfo }, getStatusStringFromEnum(order.status)))),
                        isAbleToManageOrder
                            && order.billingStatus && (React.createElement("div", { className: styles.statusesBlock },
                            React.createElement("div", { className: styles.statusItem },
                                React.createElement("div", { className: styles.statusTitle }, t.billingStatus),
                                React.createElement("div", { className: styles.statusInfo }, order.billingStatus === 'PAID_TO_SELLER'
                                    ? t.paid
                                    : t.notPaid)))),
                        isPaymentInfoCanBeShown
                            && (orderFromProps.state === 'NEW'
                                || orderFromProps.state === 'PAYMENT_AWAITED'
                                || orderFromProps.state === 'TRANSACTION_PENDING') && (React.createElement("div", { className: styles.paymentButtonWrapper },
                            React.createElement(Button, { big: true, fullWidth: true, onClick: () => {
                                    this.props.router.push(`/profile/orders/${order.number}/payment-info`);
                                } }, t.paymentInfo)))),
                    React.createElement("div", { className: styles.ticketButtonTitle }, t.havingTroubles),
                    React.createElement("div", { className: styles.ticketButtonWrapper },
                        React.createElement(Button, { big: true, wireframe: true, fullWidth: true, href: "https://graames.zendesk.com/hc/en-us/requests/new", target: "_blank" }, t.openTicket)),
                    showInvoice ? (React.createElement("div", { className: styles.ticketButtonWrapper },
                        React.createElement(Button, { big: true, wireframe: true, fullWidth: true, onClick: this.invoice }, t.invoice))) : null),
                order.product.name ? (React.createElement(ProductBlock, { subtotal: order.subtotal, product: order.product, currency: order.currency })) : (React.createElement("div", { className: styles.noProduct }, t.theProductWasDeleted)),
                order.product
                    && order.product.preOrder
                    && order.product.preOrderDays && (React.createElement("div", { className: styles.preOrder },
                    React.createElement("div", { className: styles.preOrderText },
                        React.createElement("div", null, t.thisProductWasBougthOnPreOrder),
                        React.createElement("div", null,
                            t.leadTime,
                            " ",
                            React.createElement("span", { className: styles.preOrderDays }, order.product.preOrderDays))))),
                React.createElement("div", { className: styles.infoBlock },
                    React.createElement("div", { className: styles.infoBlockItem },
                        React.createElement(Row, null,
                            React.createElement(Col, { size: 12, lg: 5 },
                                React.createElement(TextWithLabel, { label: t.labelCustomer, text: order.customerName })),
                            React.createElement(Col, { size: 12, lg: 7 },
                                React.createElement(TextWithLabel, { label: t.labelContacts, text: `${order.customerAddress}${order.customerPhone ? `, ${order.customerPhone}` : ''}` })))),
                    React.createElement("div", { className: styles.infoBlockItem },
                        React.createElement(Row, null,
                            React.createElement(Col, { size: 12, lg: 5 },
                                React.createElement(TextWithLabel, { label: t.labelDate, text: stringFromTimestamp({
                                        timestamp: order.date,
                                        format: 'DD MMMM YYYY'
                                    }) })),
                            React.createElement(Col, { size: 12, lg: 7 },
                                React.createElement(TextWithLabel, { label: t.labelTime, text: stringFromTimestamp({
                                        timestamp: order.date,
                                        format: 'HH:mm'
                                    }) })))),
                    React.createElement("div", { className: styles.infoBlockItem },
                        React.createElement(Row, null,
                            React.createElement(Col, { size: 12, lg: 5 },
                                React.createElement(TextWithLabel, { label: t.labelDelivery, text: order.delivery })),
                            React.createElement(Col, { size: 12, lg: 7 },
                                React.createElement(TextWithLabel, { label: t.labelDeliveryPrice, text: `${formatPrice(order.deliveryPrice)} ${order.currency}` })))),
                    React.createElement("div", { className: styles.infoBlockItem },
                        React.createElement(Row, null,
                            React.createElement(Col, { size: 12, lg: 5 },
                                React.createElement(TextWithLabel, { label: t.labelTrackID, text: order.trackId })),
                            React.createElement(Col, { size: 12, lg: 7 },
                                React.createElement(TextWithLabel, { label: t.labelQuantity, text: `${order.quantity}` })))),
                    React.createElement("div", { className: styles.infoBlockItem },
                        React.createElement(Row, null,
                            React.createElement(Col, { size: 12, lg: 5 },
                                React.createElement(TextWithLabel, { label: t.labelCouponDiscount, text: order.couponPrice
                                        ? `−${formatPrice(order.couponPrice)} ${order.currency}`
                                        : '—' })),
                            React.createElement(Col, { size: 12, lg: 7 },
                                React.createElement(TextWithLabel, { label: t.labelTotalAmount, text: `${formatPrice(order.totalAmount)} ${order.currency}` }))))),
                this.props.isAbleToManageOrder
                    && (orderFromProps.state === 'PAID'
                        || orderFromProps.state === 'IN_PROCESSING'
                        || orderFromProps.fee) && (React.createElement("div", { className: styles.manageBlock },
                    React.createElement(ManageOrderBlock, { environment: environment, isAbleToConfirm: orderFromProps.state === 'PAID', isAbleToSend: orderFromProps.state === 'IN_PROCESSING', isAbleToCancel: false, orderSlug: parseInt(order.number, 10), onOrderSend: this.handleOrderSent, onOrderConfirm: this.handleOrderConfirm, onOrderCancel: this.handleOrderCanceled, onChargeFee: this.handleChargeFee, orderFee: orderFromProps.fee || null, orderBilling: orderFromProps.orderBilling || null, orderId: orderFromProps.id }))),
                this.props.isAbleToManageOrderForUser
                    && orderFromProps.state !== 'COMPLETE' && (React.createElement("div", { className: styles.manageBlock },
                    React.createElement(ManageOrderBlockForUser, { environment: environment, isAbleToSend: orderFromProps.state === 'DELIVERED'
                            || orderFromProps.state === 'SENT', orderSlug: parseInt(order.number, 10), orderId: orderFromProps.id, onOrderComplete: this.handleOrderComplete }))),
                React.createElement("div", { className: styles.statusList },
                    React.createElement(StatusList, { items: order.statusHistory })))))));
    }
}
export default withShowAlert(withRouter(OrderPage));
