/**
 * @generated SignedSource<<b8a8336b9371d95dc08f79f8f7fe5884>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawId",
        "storageKey": null
    }, v4 = [
        {
            "kind": "Literal",
            "name": "visibility",
            "value": "active"
        }
    ], v5 = {
        "alias": null,
        "args": null,
        "concreteType": "GraphQLWarehouse",
        "kind": "LinkedField",
        "name": "warehouses",
        "plural": true,
        "selections": [
            (v2 /*: any*/)
        ],
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "productId",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "warehouseId",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
    }, v10 = {
        "alias": null,
        "args": null,
        "concreteType": "Address",
        "kind": "LinkedField",
        "name": "addressFull",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "country",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "countryCode",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "administrativeAreaLevel1",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "administrativeAreaLevel2",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "political",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "postalCode",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "streetNumber",
                "storageKey": null
            },
            (v9 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "route",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "locality",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v11 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "quantity",
        "storageKey": null
    }, v12 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "discount",
        "storageKey": null
    }, v13 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "photoMain",
        "storageKey": null
    }, v14 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "additionalPhotos",
        "storageKey": null
    }, v15 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "vendorCode",
        "storageKey": null
    }, v16 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cashback",
        "storageKey": null
    }, v17 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "price",
        "storageKey": null
    }, v18 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "preOrder",
        "storageKey": null
    }, v19 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "preOrderDays",
        "storageKey": null
    }, v20 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
    }, v21 = {
        "alias": null,
        "args": null,
        "concreteType": "ProdAttrValue",
        "kind": "LinkedField",
        "name": "attributes",
        "plural": true,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "attrId",
                "storageKey": null
            },
            (v9 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "metaField",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "Attribute",
                "kind": "LinkedField",
                "name": "attribute",
                "plural": false,
                "selections": [
                    (v2 /*: any*/),
                    (v3 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "Translation",
                        "kind": "LinkedField",
                        "name": "name",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lang",
                                "storageKey": null
                            },
                            (v20 /*: any*/)
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "AttributeMetaField",
                        "kind": "LinkedField",
                        "name": "metaField",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "values",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "TranslatedValue",
                                "kind": "LinkedField",
                                "name": "translatedValues",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Translation",
                                        "kind": "LinkedField",
                                        "name": "translations",
                                        "plural": true,
                                        "selections": [
                                            (v20 /*: any*/)
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "CreateProductWithAttributesMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Product",
                    "kind": "LinkedField",
                    "name": "createProduct",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": (v4 /*: any*/),
                            "concreteType": "BaseProduct",
                            "kind": "LinkedField",
                            "name": "baseProduct",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Store",
                                    "kind": "LinkedField",
                                    "name": "store",
                                    "plural": false,
                                    "selections": [
                                        (v5 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": "baseProduct(visibility:\"active\")"
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "GraphQLStock",
                            "kind": "LinkedField",
                            "name": "stocks",
                            "plural": true,
                            "selections": [
                                (v2 /*: any*/),
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "GraphQLWarehouse",
                                    "kind": "LinkedField",
                                    "name": "warehouse",
                                    "plural": false,
                                    "selections": [
                                        (v8 /*: any*/),
                                        (v10 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v11 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v12 /*: any*/),
                        (v13 /*: any*/),
                        (v14 /*: any*/),
                        (v15 /*: any*/),
                        (v16 /*: any*/),
                        (v17 /*: any*/),
                        (v18 /*: any*/),
                        (v19 /*: any*/),
                        (v21 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "CreateProductWithAttributesMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Product",
                    "kind": "LinkedField",
                    "name": "createProduct",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": (v4 /*: any*/),
                            "concreteType": "BaseProduct",
                            "kind": "LinkedField",
                            "name": "baseProduct",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Store",
                                    "kind": "LinkedField",
                                    "name": "store",
                                    "plural": false,
                                    "selections": [
                                        (v5 /*: any*/),
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": "baseProduct(visibility:\"active\")"
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "GraphQLStock",
                            "kind": "LinkedField",
                            "name": "stocks",
                            "plural": true,
                            "selections": [
                                (v2 /*: any*/),
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "GraphQLWarehouse",
                                    "kind": "LinkedField",
                                    "name": "warehouse",
                                    "plural": false,
                                    "selections": [
                                        (v8 /*: any*/),
                                        (v10 /*: any*/),
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v11 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v12 /*: any*/),
                        (v13 /*: any*/),
                        (v14 /*: any*/),
                        (v15 /*: any*/),
                        (v16 /*: any*/),
                        (v17 /*: any*/),
                        (v18 /*: any*/),
                        (v19 /*: any*/),
                        (v21 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "5695e6db678845c3e7a85b97189719ff",
            "id": null,
            "metadata": {},
            "name": "CreateProductWithAttributesMutation",
            "operationKind": "mutation",
            "text": "mutation CreateProductWithAttributesMutation(\n  $input: CreateProductWithAttributesInput!\n) {\n  createProduct(input: $input) {\n    id\n    rawId\n    baseProduct(visibility: \"active\") {\n      id\n      store {\n        warehouses {\n          id\n        }\n        id\n      }\n    }\n    stocks {\n      id\n      productId\n      warehouseId\n      warehouse {\n        name\n        addressFull {\n          country\n          countryCode\n          administrativeAreaLevel1\n          administrativeAreaLevel2\n          political\n          postalCode\n          streetNumber\n          value\n          route\n          locality\n        }\n        id\n      }\n      quantity\n    }\n    discount\n    photoMain\n    additionalPhotos\n    vendorCode\n    cashback\n    price\n    preOrder\n    preOrderDays\n    attributes {\n      attrId\n      value\n      metaField\n      attribute {\n        id\n        rawId\n        name {\n          lang\n          text\n        }\n        metaField {\n          values\n          translatedValues {\n            translations {\n              text\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "435c3a677dfe17974941371bf87c4174";
export default node;
