/**
 * @generated SignedSource<<803f646dd1a3dd87ea8b369b0adf7d86>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawId",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "level",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "parentId",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lang",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "concreteType": "Translation",
        "kind": "LinkedField",
        "name": "name",
        "plural": true,
        "selections": [
            (v3 /*: any*/),
            (v4 /*: any*/)
        ],
        "storageKey": null
    }, v6 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "minValue",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "maxValue",
            "storageKey": null
        }
    ], v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
    }, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "price",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "after"
            },
            {
                "defaultValue": 24,
                "kind": "LocalArgument",
                "name": "first"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "text"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                {
                    "count": "first",
                    "cursor": "after",
                    "direction": "forward",
                    "path": [
                        "findProduct"
                    ]
                }
            ]
        },
        "name": "Categories_search",
        "selections": [
            {
                "alias": "findProduct",
                "args": [
                    {
                        "kind": "Variable",
                        "name": "searchTerm",
                        "variableName": "text"
                    }
                ],
                "concreteType": "BaseProductsSearchConnection",
                "kind": "LinkedField",
                "name": "__Categories_findProduct_connection",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfoProductsSearch",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductsSearchFilters",
                                "kind": "LinkedField",
                                "name": "searchFilters",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "SearchCategory",
                                        "kind": "LinkedField",
                                        "name": "categories",
                                        "plural": false,
                                        "selections": [
                                            (v0 /*: any*/),
                                            (v1 /*: any*/),
                                            (v2 /*: any*/),
                                            (v5 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "SearchCategory",
                                                "kind": "LinkedField",
                                                "name": "children",
                                                "plural": true,
                                                "selections": [
                                                    (v0 /*: any*/),
                                                    (v1 /*: any*/),
                                                    (v2 /*: any*/),
                                                    (v5 /*: any*/),
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "SearchCategory",
                                                        "kind": "LinkedField",
                                                        "name": "children",
                                                        "plural": true,
                                                        "selections": [
                                                            (v0 /*: any*/),
                                                            (v1 /*: any*/),
                                                            (v2 /*: any*/),
                                                            (v5 /*: any*/),
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "concreteType": "SearchCategory",
                                                                "kind": "LinkedField",
                                                                "name": "children",
                                                                "plural": true,
                                                                "selections": [
                                                                    (v0 /*: any*/),
                                                                    (v1 /*: any*/),
                                                                    (v2 /*: any*/),
                                                                    (v5 /*: any*/)
                                                                ],
                                                                "storageKey": null
                                                            }
                                                        ],
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "RangeFilter",
                                        "kind": "LinkedField",
                                        "name": "priceRange",
                                        "plural": false,
                                        "selections": (v6 /*: any*/),
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "AttributeFilter",
                                        "kind": "LinkedField",
                                        "name": "attrFilters",
                                        "plural": true,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Attribute",
                                                "kind": "LinkedField",
                                                "name": "attribute",
                                                "plural": false,
                                                "selections": [
                                                    (v7 /*: any*/),
                                                    (v0 /*: any*/),
                                                    (v5 /*: any*/),
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "AttributeMetaField",
                                                        "kind": "LinkedField",
                                                        "name": "metaField",
                                                        "plural": false,
                                                        "selections": [
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "kind": "ScalarField",
                                                                "name": "uiElement",
                                                                "storageKey": null
                                                            }
                                                        ],
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "EqualFilter",
                                                "kind": "LinkedField",
                                                "name": "equal",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "values",
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "RangeFilter",
                                                "kind": "LinkedField",
                                                "name": "range",
                                                "plural": false,
                                                "selections": (v6 /*: any*/),
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "BaseProductsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "BaseProduct",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v7 /*: any*/),
                                    (v0 /*: any*/),
                                    (v8 /*: any*/),
                                    (v5 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Store",
                                        "kind": "LinkedField",
                                        "name": "store",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Translation",
                                                "kind": "LinkedField",
                                                "name": "name",
                                                "plural": true,
                                                "selections": [
                                                    (v4 /*: any*/),
                                                    (v3 /*: any*/)
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Category",
                                        "kind": "LinkedField",
                                        "name": "category",
                                        "plural": false,
                                        "selections": [
                                            (v0 /*: any*/)
                                        ],
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "storeId",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": [
                                            {
                                                "kind": "Literal",
                                                "name": "first",
                                                "value": 1
                                            }
                                        ],
                                        "concreteType": "ProductsConnection",
                                        "kind": "LinkedField",
                                        "name": "products",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "ProductsEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "Product",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                            (v7 /*: any*/),
                                                            (v0 /*: any*/),
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "kind": "ScalarField",
                                                                "name": "discount",
                                                                "storageKey": null
                                                            },
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "kind": "ScalarField",
                                                                "name": "photoMain",
                                                                "storageKey": null
                                                            },
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "kind": "ScalarField",
                                                                "name": "cashback",
                                                                "storageKey": null
                                                            },
                                                            (v9 /*: any*/),
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "concreteType": "CustomerPrice",
                                                                "kind": "LinkedField",
                                                                "name": "customerPrice",
                                                                "plural": false,
                                                                "selections": [
                                                                    (v9 /*: any*/),
                                                                    (v8 /*: any*/)
                                                                ],
                                                                "storageKey": null
                                                            },
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "concreteType": "ProdAttrValue",
                                                                "kind": "LinkedField",
                                                                "name": "attributes",
                                                                "plural": true,
                                                                "selections": [
                                                                    {
                                                                        "alias": null,
                                                                        "args": null,
                                                                        "concreteType": "Attribute",
                                                                        "kind": "LinkedField",
                                                                        "name": "attribute",
                                                                        "plural": false,
                                                                        "selections": [
                                                                            (v7 /*: any*/)
                                                                        ],
                                                                        "storageKey": null
                                                                    },
                                                                    {
                                                                        "alias": null,
                                                                        "args": null,
                                                                        "kind": "ScalarField",
                                                                        "name": "value",
                                                                        "storageKey": null
                                                                    }
                                                                ],
                                                                "storageKey": null
                                                            }
                                                        ],
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": "products(first:1)"
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "rating",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__typename",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Search",
        "abstractKey": null
    };
})();
node.hash = "291f07e73114fa2b63232f0c66e7a804";
export default node;
