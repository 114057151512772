import { commitMutation, graphql } from 'react-relay';
const mutation = graphql `
	mutation UpdateIndiaBillingInfoMutation(
		$input: UpdateIndiaBillingInfoInput!
	) {
		updateIndiaBillingInfo(input: $input) {
			id
			rawId
			storeId
			bankName
			branchName
			swiftBic
			taxId
			correspondentAccount
			currentAccount
			personalAccount
			beneficiaryFullName
		}
	}
`;
const commit = (params) => commitMutation(params.environment, {
    mutation,
    variables: params.variables,
    onCompleted: params.onCompleted,
    onError: params.onError,
    updater: (relayStore) => {
        const updateIndiaBillingInfo = relayStore.getRootField('updateIndiaBillingInfo');
        const me = relayStore.getRoot()
            .getLinkedRecord('me');
        const myStore = me.getLinkedRecord('myStore');
        myStore.setLinkedRecord(updateIndiaBillingInfo, 'indiaBillingInfo');
    }
});
export default { commit };
