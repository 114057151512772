/**
 * @generated SignedSource<<43517d4c7b2f9a1cc6f3fff897276aab>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawId",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "defaultLanguage",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "phone",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "logo",
        "storageKey": null
    }, v9 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lang",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "text",
            "storageKey": null
        }
    ], v10 = {
        "alias": null,
        "args": null,
        "concreteType": "Translation",
        "kind": "LinkedField",
        "name": "name",
        "plural": true,
        "selections": (v9 /*: any*/),
        "storageKey": null
    }, v11 = {
        "alias": null,
        "args": null,
        "concreteType": "Translation",
        "kind": "LinkedField",
        "name": "shortDescription",
        "plural": true,
        "selections": (v9 /*: any*/),
        "storageKey": null
    }, v12 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "country",
        "storageKey": null
    }, v13 = {
        "alias": null,
        "args": null,
        "concreteType": "Address",
        "kind": "LinkedField",
        "name": "addressFull",
        "plural": false,
        "selections": [
            (v12 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "countryCode",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "administrativeAreaLevel1",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "administrativeAreaLevel2",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "locality",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "political",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "postalCode",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "route",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "streetNumber",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v14 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "address",
        "storageKey": null
    }, v15 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "facebookUrl",
        "storageKey": null
    }, v16 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "twitterUrl",
        "storageKey": null
    }, v17 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "instagramUrl",
        "storageKey": null
    }, v18 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "BaseProductsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "BaseProduct",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ], v19 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 100
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UpdateStoreMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Store",
                    "kind": "LinkedField",
                    "name": "updateStore",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        (v10 /*: any*/),
                        (v11 /*: any*/),
                        (v13 /*: any*/),
                        (v14 /*: any*/),
                        (v12 /*: any*/),
                        (v15 /*: any*/),
                        (v16 /*: any*/),
                        (v17 /*: any*/),
                        {
                            "alias": "baseProducts",
                            "args": null,
                            "concreteType": "BaseProductsConnection",
                            "kind": "LinkedField",
                            "name": "__Wizard_baseProducts_connection",
                            "plural": false,
                            "selections": (v18 /*: any*/),
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UpdateStoreMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Store",
                    "kind": "LinkedField",
                    "name": "updateStore",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        (v10 /*: any*/),
                        (v11 /*: any*/),
                        (v13 /*: any*/),
                        (v14 /*: any*/),
                        (v12 /*: any*/),
                        (v15 /*: any*/),
                        (v16 /*: any*/),
                        (v17 /*: any*/),
                        {
                            "alias": null,
                            "args": (v19 /*: any*/),
                            "concreteType": "BaseProductsConnection",
                            "kind": "LinkedField",
                            "name": "baseProducts",
                            "plural": false,
                            "selections": (v18 /*: any*/),
                            "storageKey": "baseProducts(first:100)"
                        },
                        {
                            "alias": null,
                            "args": (v19 /*: any*/),
                            "filters": null,
                            "handle": "connection",
                            "key": "Wizard_baseProducts",
                            "kind": "LinkedHandle",
                            "name": "baseProducts"
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "b0f050b96601a92afb37c18392181fd6",
            "id": null,
            "metadata": {
                "connection": [
                    {
                        "count": null,
                        "cursor": null,
                        "direction": "forward",
                        "path": [
                            "updateStore",
                            "baseProducts"
                        ]
                    }
                ]
            },
            "name": "UpdateStoreMutation",
            "operationKind": "mutation",
            "text": "mutation UpdateStoreMutation(\n  $input: UpdateStoreInput!\n) {\n  updateStore(input: $input) {\n    id\n    rawId\n    defaultLanguage\n    email\n    phone\n    slug\n    logo\n    name {\n      lang\n      text\n    }\n    shortDescription {\n      lang\n      text\n    }\n    addressFull {\n      country\n      countryCode\n      value\n      administrativeAreaLevel1\n      administrativeAreaLevel2\n      locality\n      political\n      postalCode\n      route\n      streetNumber\n    }\n    address\n    country\n    facebookUrl\n    twitterUrl\n    instagramUrl\n    baseProducts(first: 100) {\n      edges {\n        node {\n          id\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "775ac0d641737f4baae8ed6854b6c996";
export default node;
