import React, { Component } from 'react';
import { find, isEmpty, whereEq } from 'ramda';
import { Environment } from 'relay-runtime';
import PropTypes from 'prop-types';
import { error } from '../../../../utils';
import { fetchAvailableShippingForUser } from '../../../../relay/queries';
import { Button, SpinnerCircle } from '../../../../components/common';
import Dropdown from './Dropdown';
import styles from './DeliveryCompaniesSelect.module.scss';
const Loading = () => (React.createElement("div", { className: styles.loadingWrapper },
    React.createElement("div", null, "loading..."),
    React.createElement(SpinnerCircle, { additionalStyles: {
            width: '2rem',
            height: '2rem'
        }, containerStyles: {
            width: '2rem',
            height: '2rem',
            marginLeft: 0
        } })));
const FetchError = ({ onRetryClick }) => (React.createElement("div", { className: styles.errorWrapper },
    React.createElement("div", null, "Error :( Please, retry"),
    React.createElement("div", null,
        React.createElement(Button, { small: true, onClick: onRetryClick }, "Retry"))));
class DeliveryCompaniesSelect extends Component {
    static contextTypes = { environment: PropTypes.instanceOf(Environment).isRequired };
    constructor(props) {
        super(props);
        this.state = {
            isFetching: true,
            isFetchingError: false,
            packages: [],
            isDropdownOpened: false,
            selectedPackage: null,
            isLoading: false,
            isError: false
        };
    }
    componentDidMount() {
        this.mounted = true;
        this.fetchData();
    }
    componentWillUnmount() {
        this.mounted = false;
    }
    mounted = false;
    fetchData = () => {
        this.setState({
            isFetching: true,
            isFetchingError: false
        });
        fetchAvailableShippingForUser({
            destinationCountry: this.props.country,
            baseProductId: this.props.baseProductId,
            environment: this.context.environment
        })
            .then((packages) => {
            this.setState({ packages }, () => {
                this.props.onPackagesFetched(packages);
            });
            return true;
        })
            .finally(() => {
            this.setState({ isFetching: false });
        })
            .catch((err) => {
            this.setState({ isFetchingError: true });
            error(err);
        });
    };
    render() {
        const { isFetching, isFetchingError, selectedPackage, packages } = this.state;
        const selectedPkg = find(whereEq({ shippingId: this.props.selectedCompanyShippingRawId }), packages);
        return (React.createElement("div", { className: styles.container },
            isFetching && React.createElement(Loading, null),
            isFetchingError && React.createElement(FetchError, { onRetryClick: this.fetchData }),
            !isFetching
                && !isFetchingError && (React.createElement(React.Fragment, null, !isEmpty(packages) ? (React.createElement(Dropdown, { currency: this.props.currency, packages: this.state.packages, selectedPackage: selectedPackage || selectedPkg, isOpen: this.state.isDropdownOpened, isLoading: this.state.isLoading, isError: this.state.isError, toggleExpand: (e) => {
                    e.stopPropagation();
                    const { selectedCompanyShippingRawId } = this.props;
                    this.setState((prevState) => {
                        if (prevState.isDropdownOpened) {
                            return {
                                selectedPackage: selectedCompanyShippingRawId == null
                                    ? null
                                    : find(whereEq({ selectedCompanyShippingRawId }), prevState.packages),
                                isDropdownOpened: !prevState.isDropdownOpened
                            };
                        }
                        return { isDropdownOpened: !prevState.isDropdownOpened };
                    });
                }, onPackageSelect: (id) => {
                    if (selectedPackage && selectedPackage.id === id) {
                        this.setState({ selectedPackage: null });
                    }
                    else {
                        this.setState((preState) => ({
                            selectedPackage: find(whereEq({ id }), preState.packages)
                        }));
                    }
                }, onAccept: () => {
                    this.setState({
                        isLoading: true,
                        isError: false
                    }, () => {
                        this.props
                            .onPackageSelect(this.state.selectedPackage)
                            .finally(() => {
                            this.setState({
                                isLoading: false,
                                isDropdownOpened: false,
                                selectedPackage: null
                            });
                        })
                            .catch(() => {
                            this.setState({ isError: true });
                        });
                    });
                } })) : (React.createElement("span", null, "Seller does not ship to selected country"))))));
    }
}
export default DeliveryCompaniesSelect;
