import React, { Component } from 'react';
import { isEmpty, pathOr } from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import { Environment } from 'relay-runtime';
import PropTypes from 'prop-types';
import { ManageStore } from '../../../index';
import { withShowAlert } from '../../../../../../components/Alerts/AlertContext';
import { Page } from '../../../../../../components/App';
import { debug, fromRelayError } from '../../../../../../utils';
import { CreateWarehouseMutation } from '../../../../../../relay/mutations';
import Form from '../Form';
import styles from './NewStorage.module.scss';
import t from './i18n';
class NewStorage extends Component {
    static contextTypes = { environment: PropTypes.instanceOf(Environment).isRequired };
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            formErrors: {},
            name: '',
            addressFull: {
                administrativeAreaLevel1: '',
                administrativeAreaLevel2: '',
                country: '',
                countryCode: '',
                locality: '',
                political: '',
                postalCode: '',
                route: '',
                streetNumber: '',
                value: ''
            }
        };
    }
    handleSave = (data) => {
        const { environment } = this.context;
        const storeId = pathOr(null, ['match', 'params', 'storeId'], this.props);
        const params = {
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    storeId: parseInt(storeId, 10),
                    name: data.name,
                    addressFull: { ...data.addressFull }
                }
            },
            environment,
            onCompleted: (response, errors) => {
                this.setState(() => ({ isLoading: false }));
                debug({
                    response,
                    errors
                });
                const relayErrors = fromRelayError({ source: { errors } });
                debug({ relayErrors });
                const validationErrors = pathOr({}, ['100', 'messages'], relayErrors);
                if (!isEmpty(validationErrors)) {
                    this.setState({ formErrors: validationErrors });
                    return;
                }
                const statusError = pathOr({}, ['100', 'status'], relayErrors);
                if (!isEmpty(statusError)) {
                    this.props.showAlert({
                        type: 'danger',
                        text: `${t.error} "${statusError}"`,
                        link: { text: t.close }
                    });
                    return;
                }
                this.props.showAlert({
                    type: 'success',
                    text: t.storageCreated,
                    link: { text: '' }
                });
                this.props.router.push(`/manage/store/${storeId}/storages`);
            },
            onError: (error) => {
                debug({ error });
                this.props.showAlert({
                    type: 'danger',
                    text: t.somethingGoingWrong,
                    link: { text: t.close }
                });
            }
        };
        CreateWarehouseMutation.commit(params);
    };
    handleCancel = () => {
        const storeId = pathOr(null, ['match', 'params', 'storeId'], this.props);
        if (storeId) {
            this.props.router.push(`/manage/store/${storeId}/storages`);
        }
    };
    render() {
        const { name, addressFull, isLoading, formErrors } = this.state;
        return (React.createElement("div", { className: styles.container },
            React.createElement(Form, { isLoading: isLoading, name: name, addressFull: addressFull, handleCancel: this.handleCancel, formErrors: formErrors, handleSave: this.handleSave })));
    }
}
export default withShowAlert(Page(ManageStore({
    OriginalComponent: NewStorage,
    active: 'storages',
    title: 'Storages'
})));
