import React from 'react';
import { Link } from 'found';
import styles from './StoreHeaderTabs.module.scss';
import t from './i18n';
import { classNames } from '../../../utils';
const StoreHeaderTabs = ({ tabs, storeId, active }) => (React.createElement("nav", { className: styles.container },
    React.createElement("div", { className: styles.tabs }, tabs.map((tab) => (tab.link !== null ? (React.createElement(Link, { to: `/store/${storeId}${tab.link}`, key: tab.id, className: classNames(styles, 'tab', { active: active === tab.id }) },
        React.createElement("strong", { className: styles.text },
            tab.title,
            tab.isNew && React.createElement("span", { className: styles.new }, t.new)))) : (React.createElement("div", { key: tab.id, className: styles.tab },
        React.createElement("strong", { className: styles.text },
            tab.title,
            tab.isNew && React.createElement("span", { className: styles.new }, t.new)))))))));
export default StoreHeaderTabs;
