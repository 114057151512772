import React from 'react';
import { formatPrice, getExchangePrice, classNames } from '../../../../utils';
import { ContextDecorator } from '../../../../components/App';
import styles from './ProductPrice.module.scss';
import t from './i18n';
const ProductPrice = ({ currency, price, cashback, discount, directories, buttonText = t.cashback }) => {
    const discountedPrice = discount ? price * (1 - discount) : price;
    // const discountValue = discount ? (discount * 100).toFixed(0) : null;
    // const cashbackValue = cashback ? (cashback * 100).toFixed(0) : null;
    const priceExchanged = getExchangePrice({
        price,
        currency,
        currencyExchange: directories.currencyExchange
    });
    const discountedPriceExchanged = getExchangePrice({
        price: discountedPrice,
        currency,
        currencyExchange: directories.currencyExchange
    });
    return (React.createElement("div", { className: styles.container },
        Boolean(discount)
            && discount > 0 && (React.createElement("div", { className: styles.price },
            React.createElement("div", { className: styles.title },
                React.createElement("strong", null, t.price)),
            React.createElement("small", { className: styles.lastPrice }, priceExchanged != null ? (React.createElement("span", null, priceExchanged)) : (React.createElement("span", null,
                formatPrice(price, 2),
                ' ',
                currency))))),
        React.createElement("div", { className: styles.inr },
            React.createElement("div", { className: styles.title },
                React.createElement("strong", null, Boolean(discount) && discount > 0 ? t.discountPrice : t.price)),
            React.createElement("div", { className: styles.discountPrice },
                React.createElement("b", { className: styles.basePrice }, priceExchanged != null ? (React.createElement("span", null, discountedPriceExchanged)) : (React.createElement("span", null,
                    formatPrice(discountedPrice, 2),
                    " ",
                    currency))),
                priceExchanged != null && (React.createElement("div", { className: styles.currencyPrice }, `~${formatPrice(discountedPrice, 2)} ${currency}`))),
            Boolean(cashback) && (React.createElement("div", { className: classNames(styles, 'cashback', { noCashback: !cashback }) },
                buttonText,
                " ",
                `${cashback ? (cashback * 100).toFixed(0) : 0}%`)))));
};
export default ContextDecorator(ProductPrice);
