import { t } from '../../../../translation/utils';
const translations = {
    en: {
        amount: 'Amount',
        remainingStock: 'Remaining stock:',
        inStock: 'In stock',
        preOrder: 'Pre order',
        availableForPreOrder: 'Available for pre-order.',
        leadTime: 'Lead time (days):',
        numbersInWords: {
            dozens: 'dozens',
            hundreds: 'hundreds'
        }
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
