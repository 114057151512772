import React, { Component } from 'react';
import { assoc, assocPath, isEmpty, isNil, omit, path, pathOr } from 'ramda';
import { createFragmentContainer, graphql } from 'react-relay';
import { v4 as uuidv4 } from 'uuid';
import { ContextDecorator } from '../../../../../components/App';
import { Button, Checkbox, Input, Select } from '../../../../../components/common';
import { CreateInternationalBillingInfoMutation, CreateIndiaBillingInfoMutation, UpdateInternationalBillingInfoMutation, UpdateIndiaBillingInfoMutation } from '../../../../../relay/mutations';
import { withShowAlert } from '../../../../../components/Alerts/AlertContext';
import { debug, error, fromRelayError } from '../../../../../utils';
import styles from './PaymentAccount.module.scss';
import t from './i18n';
class PaymentAccount extends Component {
    constructor(props) {
        super(props);
        let paymentAccountType = 'indian';
        const indianBillingInfo = pathOr(null, ['myStore', 'indiaBillingInfo'], props.me);
        const internationalBillingInfo = pathOr(null, ['myStore', 'internationalBillingInfo'], props.me);
        if (indianBillingInfo === null && internationalBillingInfo === null) {
            const countryCode = pathOr(null, ['myStore', 'warehouses', 0, 'addressFull', 'countryCode'], props.me);
            paymentAccountType = countryCode === 'IND' ? 'indian' : 'international';
        }
        if (internationalBillingInfo != null) {
            paymentAccountType = 'international';
        }
        this.state = {
            isLoading: false,
            paymentAccountType: paymentAccountType,
            indianForm: {
                id: indianBillingInfo ? indianBillingInfo.rawId : null,
                bankName: (indianBillingInfo && indianBillingInfo.bankName) || '',
                branchName: (indianBillingInfo && indianBillingInfo.branchName) || '',
                swiftBic: (indianBillingInfo && indianBillingInfo.swiftBic) || '',
                taxId: (indianBillingInfo && indianBillingInfo.taxId) || '',
                correspondentAccount: (indianBillingInfo && indianBillingInfo.correspondentAccount) || '',
                currentAccount: (indianBillingInfo && indianBillingInfo.currentAccount) || '',
                personalAccount: (indianBillingInfo && indianBillingInfo.personalAccount) || '',
                beneficiaryFullName: (indianBillingInfo && indianBillingInfo.beneficiaryFullName) || ''
            },
            internationalForm: {
                id: internationalBillingInfo ? internationalBillingInfo.rawId : null,
                account: (internationalBillingInfo && internationalBillingInfo.account) || '',
                currency: internationalBillingInfo && internationalBillingInfo.currency
                    ? {
                        id: internationalBillingInfo.currency,
                        label: internationalBillingInfo.currency
                    }
                    : {
                        id: 'INR',
                        label: 'INR'
                    },
                name: (internationalBillingInfo && internationalBillingInfo.name) || '',
                bank: (internationalBillingInfo && internationalBillingInfo.bank) || '',
                swift: (internationalBillingInfo && internationalBillingInfo.swift) || '',
                bankAddress: (internationalBillingInfo && internationalBillingInfo.bankAddress)
                    || '',
                country: (internationalBillingInfo && internationalBillingInfo.country) || '',
                city: (internationalBillingInfo && internationalBillingInfo.city) || '',
                recipientAddress: (internationalBillingInfo
                    && internationalBillingInfo.recipientAddress)
                    || ''
            },
            isOpenForm: false
        };
    }
    handleSaveIndiaBillingInfo = () => {
        const storeId = pathOr(null, ['myStore', 'rawId'], this.props.me);
        const indianBillingInfo = pathOr(null, ['myStore', 'indiaBillingInfo'], this.props.me);
        const { indianForm } = this.state;
        if (!storeId) {
            this.props.showAlert({
                type: 'danger',
                text: t.somethingGoingWrong,
                link: { text: t.close }
            });
            return;
        }
        this.setState({ isLoading: true });
        let input = {
            clientMutationId: uuidv4(),
            ...omit(['id'], indianForm)
        };
        if (indianBillingInfo != null) {
            input = assoc('id', indianBillingInfo.rawId, input);
        }
        else {
            input = assoc('storeId', storeId, input);
        }
        const params = {
            variables: { input },
            environment: this.props.environment,
            onCompleted: (response, errors) => {
                this.setState({ isLoading: false });
                debug({
                    response,
                    errors
                });
                const relayErrors = fromRelayError({ source: { errors } });
                debug({ relayErrors });
                const statusError = pathOr({}, ['100', 'status'], relayErrors);
                if (!isEmpty(statusError)) {
                    this.props.showAlert({
                        type: 'danger',
                        text: `${t.error} "${statusError}"`,
                        link: { text: t.close }
                    });
                    return;
                }
                const parsingError = pathOr(null, ['300', 'message'], relayErrors);
                if (parsingError) {
                    debug('parsingError:', { parsingError });
                    this.props.showAlert({
                        type: 'danger',
                        text: t.somethingGoingWrong,
                        link: { text: t.close }
                    });
                    return;
                }
                if (errors) {
                    this.props.showAlert({
                        type: 'danger',
                        text: t.somethingGoingWrong,
                        link: { text: t.close }
                    });
                    return;
                }
                this.props.showAlert({
                    type: 'success',
                    text: 'success',
                    link: { text: '' }
                });
                this.setState({ isOpenForm: false });
            },
            onError: (err) => {
                this.setState(() => ({
                    isLoading: false,
                    isOpenForm: false
                }));
                error(err);
                this.props.showAlert({
                    type: 'danger',
                    text: t.somethingGoingWrong,
                    link: { text: t.close }
                });
            }
        };
        if (indianBillingInfo != null) {
            UpdateIndiaBillingInfoMutation.commit(params);
        }
        else {
            CreateIndiaBillingInfoMutation.commit(params);
        }
    };
    handleSaveInternationalBillingInfo = () => {
        const storeId = pathOr(null, ['myStore', 'rawId'], this.props.me);
        const internationalBillingInfo = pathOr(null, ['myStore', 'internationalBillingInfo'], this.props.me);
        const { internationalForm } = this.state;
        if (!storeId) {
            this.props.showAlert({
                type: 'danger',
                text: t.somethingGoingWrong,
                link: { text: t.close }
            });
            return;
        }
        this.setState({ isLoading: true });
        let input = {
            clientMutationId: uuidv4(),
            ...omit(['currency', 'id'], internationalForm),
            currency: internationalForm.currency.label
        };
        if (internationalBillingInfo != null) {
            input = assoc('id', internationalBillingInfo.rawId, input);
        }
        else {
            input = assoc('storeId', storeId, input);
        }
        const params = {
            variables: { input },
            environment: this.props.environment,
            onCompleted: (response, errors) => {
                this.setState({ isLoading: false });
                debug({
                    response,
                    errors
                });
                const relayErrors = fromRelayError({ source: { errors } });
                debug({ relayErrors });
                const statusError = pathOr({}, ['100', 'status'], relayErrors);
                if (!isEmpty(statusError)) {
                    this.props.showAlert({
                        type: 'danger',
                        text: `${t.error} "${statusError}"`,
                        link: { text: t.close }
                    });
                    return;
                }
                const parsingError = pathOr(null, ['300', 'message'], relayErrors);
                if (parsingError) {
                    debug('parsingError:', { parsingError });
                    this.props.showAlert({
                        type: 'danger',
                        text: t.somethingGoingWrong,
                        link: { text: t.close }
                    });
                    return;
                }
                if (errors) {
                    this.props.showAlert({
                        type: 'danger',
                        text: t.somethingGoingWrong,
                        link: { text: t.close }
                    });
                    return;
                }
                this.props.showAlert({
                    type: 'success',
                    text: 'success',
                    link: { text: '' }
                });
                this.setState({ isOpenForm: false });
            },
            onError: (err) => {
                this.setState(() => ({
                    isLoading: false,
                    isOpenForm: false
                }));
                error(err);
                this.props.showAlert({
                    type: 'danger',
                    text: t.somethingGoingWrong,
                    link: { text: t.close }
                });
            }
        };
        if (internationalBillingInfo != null) {
            UpdateInternationalBillingInfoMutation.commit(params);
        }
        else {
            CreateInternationalBillingInfoMutation.commit(params);
        }
    };
    handleChangePaymentAccountType = (id) => {
        this.setState({
            paymentAccountType: id === 'indianPaymentAccountCheckbox' ? 'indian' : 'international',
            isOpenForm: false
        });
    };
    handleInputChange = (id, type) => (e) => {
        const { value } = e.target;
        this.setState((prevState) => assocPath([`${type}Form`, id], value, prevState));
    };
    handleOpenEditForm = () => {
        this.setState({ isOpenForm: true });
    };
    handleClosedEditForm = () => {
        this.setState({ isOpenForm: false });
    };
    renderItem = (props) => {
        const { id, label, type } = props;
        const { me } = this.props;
        const value = path([`${type}BillingInfo`, id], me.myStore);
        if (!value) {
            return null;
        }
        return (React.createElement("tr", { className: styles.item },
            React.createElement("td", { className: styles.label }, label),
            React.createElement("td", { className: styles.value }, value)));
    };
    renderInternatioanalItems = () => (React.createElement("table", null,
        React.createElement("tbody", null,
            this.renderItem({
                id: 'account',
                label: 'Account',
                type: 'international'
            }),
            this.renderItem({
                id: 'name',
                label: 'Name',
                type: 'international'
            }),
            this.renderItem({
                id: 'bank',
                label: 'Bank',
                type: 'international'
            }),
            this.renderItem({
                id: 'swift',
                label: 'Swift',
                type: 'international'
            }),
            this.renderItem({
                id: 'bankAddress',
                label: 'Bank address',
                type: 'international'
            }),
            this.renderItem({
                id: 'country',
                label: 'Country',
                type: 'international'
            }),
            this.renderItem({
                id: 'city',
                label: 'City',
                type: 'international'
            }),
            this.renderItem({
                id: 'recipientAddress',
                label: 'Recipient address',
                type: 'international'
            }),
            this.renderItem({
                id: 'currency',
                label: 'Currency',
                type: 'international'
            }))));
    renderIndianItems = () => (React.createElement("table", null,
        React.createElement("tbody", null,
            this.renderItem({
                id: 'bankName',
                label: 'Bank name',
                type: 'india'
            }),
            this.renderItem({
                id: 'branchName',
                label: 'Branch name',
                type: 'india'
            }),
            this.renderItem({
                id: 'swiftBic',
                label: 'SWIFT BIC',
                type: 'india'
            }),
            this.renderItem({
                id: 'taxId',
                label: 'Tax ID',
                type: 'india'
            }),
            this.renderItem({
                id: 'correspondentAccount',
                label: 'Correspondent account',
                type: 'india'
            }),
            this.renderItem({
                id: 'currentAccount',
                label: 'Current account',
                type: 'india'
            }),
            this.renderItem({
                id: 'personalAccount',
                label: 'Personal account',
                type: 'india'
            }),
            this.renderItem({
                id: 'beneficiaryFullName',
                label: 'Beneficiary’s full name',
                type: 'india'
            }))));
    renderInput = (props) => {
        const { id, label, required, type } = props;
        const hereLabel = required === true ? (React.createElement("span", null,
            label,
            ' ',
            React.createElement("span", { className: styles.asterisk }, "*"))) : (label);
        const value = path([`${type}Form`, id], this.state);
        return (React.createElement(Input, { id: id, value: value || '', label: hereLabel, onChange: this.handleInputChange(id, type), fullWidth: true }));
    };
    renderIndianForm = () => (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.input }, this.renderInput({
            id: 'bankName',
            label: 'Bank name',
            type: 'indian'
        })),
        React.createElement("div", { className: styles.input }, this.renderInput({
            id: 'branchName',
            label: 'Branch name',
            type: 'indian'
        })),
        React.createElement("div", { className: styles.input }, this.renderInput({
            id: 'swiftBic',
            label: 'SWIFT BIC',
            type: 'indian'
        })),
        React.createElement("div", { className: styles.input }, this.renderInput({
            id: 'taxId',
            label: 'Tax ID',
            type: 'indian'
        })),
        React.createElement("div", { className: styles.input }, this.renderInput({
            id: 'correspondentAccount',
            label: 'Correspondent account',
            type: 'indian'
        })),
        React.createElement("div", { className: styles.input }, this.renderInput({
            id: 'currentAccount',
            label: 'Current account',
            type: 'indian'
        })),
        React.createElement("div", { className: styles.input }, this.renderInput({
            id: 'personalAccount',
            label: 'Personal account',
            type: 'indian'
        })),
        React.createElement("div", { className: styles.input }, this.renderInput({
            id: 'beneficiaryFullName',
            label: 'Beneficiary’s full name',
            type: 'indian'
        }))));
    renderInternatioanalForm = () => (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.input }, this.renderInput({
            id: 'account',
            label: 'Account',
            type: 'international'
        })),
        React.createElement("div", { className: styles.input }, this.renderInput({
            id: 'name',
            label: 'Name',
            type: 'international'
        })),
        React.createElement("div", { className: styles.input }, this.renderInput({
            id: 'bank',
            label: 'Bank',
            type: 'international'
        })),
        React.createElement("div", { className: styles.input }, this.renderInput({
            id: 'swift',
            label: 'Swift',
            type: 'international'
        })),
        React.createElement("div", { className: styles.input }, this.renderInput({
            id: 'bankAddress',
            label: 'Bank address',
            type: 'international'
        })),
        React.createElement("div", { className: styles.input }, this.renderInput({
            id: 'country',
            label: 'Country',
            type: 'international'
        })),
        React.createElement("div", { className: styles.input }, this.renderInput({
            id: 'city',
            label: 'City',
            type: 'international'
        })),
        React.createElement("div", { className: styles.input }, this.renderInput({
            id: 'recipientAddress',
            label: 'Recipient address',
            type: 'international'
        })),
        React.createElement("div", { className: styles.input },
            React.createElement(Select, { forForm: true, fullWidth: true, label: "Currency", items: [
                    {
                        id: 'INR',
                        label: 'INR'
                    },
                    {
                        id: 'EUR',
                        label: 'EUR'
                    },
                    {
                        id: 'USD',
                        label: 'USD'
                    }
                ], activeItem: this.state.internationalForm.currency, onSelect: (selected) => {
                    this.setState((preState) => ({ internationalForm: assoc('currency', selected, preState.internationalForm) }));
                }, dataTest: "internationalBillingInfoCurrency" }))));
    render() {
        const indianBillingInfo = pathOr(null, ['myStore', 'indiaBillingInfo'], this.props.me);
        const internationalBillingInfo = pathOr(null, ['myStore', 'internationalBillingInfo'], this.props.me);
        const { paymentAccountType, isLoading, isOpenForm } = this.state;
        let isNilData = isNil(internationalBillingInfo);
        if (paymentAccountType === 'indian') {
            isNilData = isNil(indianBillingInfo);
        }
        return (React.createElement("div", null,
            React.createElement("div", { className: styles.checkboxes },
                React.createElement("div", { className: styles.checkbox },
                    React.createElement(Checkbox, { id: "internationalPaymentAccountCheckbox", label: t.international, isRadio: true, isChecked: paymentAccountType !== 'indian', onChange: this.handleChangePaymentAccountType })),
                React.createElement("div", { className: styles.checkbox },
                    React.createElement(Checkbox, { id: "indianPaymentAccountCheckbox", label: t.indian, isRadio: true, isChecked: paymentAccountType === 'indian', onChange: this.handleChangePaymentAccountType }))),
            React.createElement("div", { className: styles.items }, paymentAccountType === 'indian'
                ? this.renderIndianItems()
                : this.renderInternatioanalItems()),
            (isOpenForm || isNilData) && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles.form }, paymentAccountType === 'indian'
                    ? this.renderIndianForm()
                    : this.renderInternatioanalForm()),
                React.createElement("div", { className: styles.buttons },
                    React.createElement("div", { className: styles.button },
                        React.createElement(Button, { fullWidth: true, dataTest: "savePaymentAccountButton", isLoading: isLoading, onClick: paymentAccountType === 'international'
                                ? this.handleSaveInternationalBillingInfo
                                : this.handleSaveIndiaBillingInfo }, t.save)),
                    !isNilData && (React.createElement("button", { className: styles.cancelButton, onClick: this.handleClosedEditForm }, t.cancel))))),
            !isOpenForm
                && !isNilData && (React.createElement("div", { className: styles.buttons },
                React.createElement("div", { className: styles.button },
                    React.createElement(Button, { fullWidth: true, dataTest: "editPaymentAccountButton", onClick: this.handleOpenEditForm }, t.edit))))));
    }
}
export default createFragmentContainer(withShowAlert(ContextDecorator(PaymentAccount)), {
    me: graphql `
			fragment PaymentAccount_me on User {
				myStore {
					rawId
					warehouses {
						addressFull {
							countryCode
						}
					}
					indiaBillingInfo {
						id
						rawId
						bankName
						branchName
						swiftBic
						taxId
						correspondentAccount
						currentAccount
						personalAccount
						beneficiaryFullName
					}
					internationalBillingInfo {
						id
						rawId
						account
						currency
						name
						bank
						swift
						bankAddress
						country
						city
						recipientAddress
					}
				}
			}
    `
});
