import React from 'react';
import styles from './ModerationStatus.module.scss';
import t from '../../Manage/Store/Products/Product/Form/i18n';
import { classNames } from '../../../utils';
const ModerationStatus = (props) => (React.createElement(React.Fragment, null,
    React.createElement("div", { className: classNames(styles, 'container', {
            draft: props.status === 'DRAFT',
            moderation: props.status === 'MODERATION',
            decline: props.status === 'DECLINE',
            published: props.status === 'PUBLISHED',
            blocked: props.status === 'BLOCKED'
        }), "data-test": props.dataTest }, props.status),
    props.status !== 'PUBLISHED'
        && props.link != null && (React.createElement("div", { className: styles.previewLink },
        React.createElement("a", { href: props.link, className: styles.previewLinkButton, target: "_blank", rel: "noreferrer" }, t.preview)))));
export default ModerationStatus;
