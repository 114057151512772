import React, { Component } from 'react';
import ReactAutocomplete from 'react-autocomplete-2';
import { pick, isEmpty } from 'ramda';
import debounce from 'lodash.debounce';
import { Input } from '../Input';
import styles from './Autocomplete.module.scss';
import { classNames } from '../../../utils';
class Autocomplete extends Component {
    constructor(props) {
        super(props);
        this.state = { value: '' };
        this.handleOnChange = debounce(this.handleOnChange, 250);
    }
    handleOnChangeInput = (value) => {
        this.setState(() => ({ value }), this.handleOnChange(value));
    };
    handleOnChange = (value) => {
        this.props.onChange(value);
    };
    handleOnSet = (value) => {
        this.setState(() => ({ value }), () => this.props.onSet(value));
    };
    render() {
        const { value } = this.state;
        const { autocompleteItems, label, search, fullWidth } = this.props;
        return (React.createElement("div", { className: styles.container },
            React.createElement(ReactAutocomplete, { autoHighlight: true, id: "autocompleteId", wrapperStyle: { position: 'relative' }, items: autocompleteItems, getItemValue: (item) => item.label, renderItem: (item, isHighlighted) => (React.createElement("div", { key: item.id, className: classNames(styles, 'item', { isHighlighted }) }, item.label)), renderInput: (props) => (React.createElement(Input, { inputRef: props.ref, search: search, fullWidth: fullWidth, isAutocomplete: true, label: label, ...pick([
                        'onChange',
                        'onBlur',
                        'onFocus',
                        'onKeyDown',
                        'onClick',
                        'value'
                    ], props), id: "autocompleteInput" })), renderMenu: (items) => {
                    if (isEmpty(items)) {
                        return React.createElement("div", null);
                    }
                    return (React.createElement("div", { className: styles.items },
                        React.createElement("div", { className: styles.itemsWrap }, items)));
                }, value: value, onChange: (e) => {
                    this.handleOnChangeInput(e.target.value);
                }, onSelect: (selectedValue) => {
                    this.handleOnSet(selectedValue);
                } })));
    }
}
export default Autocomplete;
