import React, { Component } from 'react';
import { withRouter } from 'found';
import { filter, head, isEmpty, map, pathOr, prepend } from 'ramda';
import { Environment } from 'relay-runtime';
import { createFragmentContainer, graphql } from 'react-relay';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { debug, error, fromRelayError, renameKeys } from '../../../../../../utils';
import { AppContext, Page } from '../../../../../../components/App';
import { CreateBaseProductWithVariantsMutation, UpsertShippingMutation } from '../../../../../../relay/mutations';
import { withShowAlert } from '../../../../../../components/Alerts/AlertContext';
import fetchPackages from '../fetchPackages';
import Form from '../Form';
import styles from '../Product.module.scss';
import t from './i18n';
import { ManageStore } from '../../../index';
class NewProduct extends Component {
    static contextTypes = {
        directories: PropTypes.object,
        environment: PropTypes.instanceOf(Environment).isRequired
    };
    constructor(props) {
        super(props);
        this.state = {
            formErrors: {},
            isLoading: false,
            availablePackages: null,
            shippingData: null,
            customAttributes: [],
            isLoadingPackages: false
        };
    }
    componentDidMount() {
        this.handleFetchPackages();
    }
    handleFetchPackages = (metrics) => {
        const size = metrics
            ? metrics.lengthCm * metrics.widthCm * metrics.heightCm
            : 0;
        const weight = metrics ? metrics.weightG : 0;
        this.setState({ isLoadingPackages: true });
        const warehouses = pathOr(null, ['me', 'myStore', 'warehouses'], this.props);
        const warehouse = warehouses && !isEmpty(warehouses) ? head(warehouses) : null;
        const countryCode = pathOr(null, ['addressFull', 'countryCode'], warehouse);
        if (countryCode && process.env.BROWSER) {
            const variables = {
                countryCode: 'IND',
                size,
                weight
            };
            fetchPackages(this.props.environment, variables)
                .toPromise()
                .then((availablePackages) => {
                this.setState({
                    availablePackages: availablePackages.availablePackages || null,
                    isLoadingPackages: false
                });
                return true;
            })
                .catch(() => {
                this.setState({
                    availablePackages: null,
                    isLoadingPackages: false
                });
                this.props.showAlert({
                    type: 'danger',
                    text: t.somethingGoingWrongWithShipping,
                    link: { text: t.close }
                });
            });
        }
    };
    handleSave = (form, isAddVariant) => {
        this.setState({ formErrors: {} });
        const { environment } = this.props;
        const { name, categoryId, seoTitle, seoDescription, shortDescription, longDescription, currency, metrics } = form;
        if (!categoryId) {
            this.props.showAlert({
                type: 'danger',
                text: t.noCategory,
                link: { text: t.close }
            });
            return;
        }
        const { customAttributes } = this.state;
        const storeRawId = pathOr(null, ['match', 'params', 'storeId'], this.props);
        this.setState(() => ({ isLoading: true }));
        CreateBaseProductWithVariantsMutation({
            environment,
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    name: [{
                            lang: 'EN',
                            text: name
                        }],
                    storeId: parseInt(storeRawId, 10),
                    shortDescription: [{
                            lang: 'EN',
                            text: shortDescription
                        }],
                    longDescription: [{
                            lang: 'EN',
                            text: longDescription
                        }],
                    seoTitle: !seoTitle || seoTitle.length === 0
                        ? null
                        : [{
                                lang: 'EN',
                                text: seoTitle
                            }],
                    seoDescription: !seoDescription || seoDescription.length === 0
                        ? null
                        : [{
                                lang: 'EN',
                                text: seoDescription
                            }],
                    currency,
                    categoryId,
                    selectedAttributes: map((item) => item.rawId, customAttributes),
                    variants: [
                        {
                            clientMutationId: uuidv4(),
                            quantity: form.quantity || 0,
                            product: {
                                price: form.price,
                                vendorCode: form.vendorCode,
                                photoMain: form.photoMain,
                                additionalPhotos: form.photos,
                                cashback: form.cashback ? form.cashback / 100 : 0,
                                discount: form.discount ? form.discount / 100 : 0,
                                preOrder: form.preOrder,
                                preOrderDays: Number(form.preOrderDays)
                            },
                            attributes: form.attributeValues
                        }
                    ],
                    lengthCm: metrics.lengthCm || null,
                    widthCm: metrics.widthCm || null,
                    heightCm: metrics.heightCm || null,
                    weightG: metrics.weightG || null
                }
            }
        })
            .then((response) => {
            this.setState(() => ({ isLoading: false }));
            debug({ response });
            if (response) {
                const { createBaseProductWithVariants } = response;
                const baseProductRawId = createBaseProductWithVariants.rawId;
                this.handleSaveShipping({
                    baseProductRawId,
                    storeId: storeRawId,
                    isAddVariant
                });
                return true;
            }
            return true;
        })
            .catch((err) => {
            this.setState(() => ({ isLoading: false }));
            const relayErrors = fromRelayError({ source: { errors: [err] } });
            debug({ relayErrors });
            const validationErrors = pathOr({}, ['100', 'messages'], relayErrors);
            if (!isEmpty(validationErrors)) {
                const formErrors = renameKeys({
                    long_description: 'longDescription',
                    short_description: 'shortDescription',
                    seo_title: 'seoTitle',
                    seo_description: 'seoDescription',
                    vendor_code: 'vendorCode'
                }, validationErrors);
                this.setState({
                    formErrors,
                    isLoading: false
                });
                return;
            }
            const statusError = pathOr({}, ['100', 'status'], relayErrors);
            if (!isEmpty(statusError)) {
                this.props.showAlert({
                    type: 'danger',
                    text: `${t.error} "${statusError}"`,
                    link: { text: t.close }
                });
                this.setState({ isLoading: false });
                return;
            }
            const parsingError = pathOr(null, ['300', 'message'], relayErrors);
            if (parsingError) {
                debug('parsingError:', { parsingError });
                this.props.showAlert({
                    type: 'danger',
                    text: t.somethingGoingWrong,
                    link: { text: t.close }
                });
                this.setState({ isLoading: false });
                return;
            }
            this.props.showAlert({
                type: 'danger',
                text: t.somethingGoingWrong,
                link: { text: t.close }
            });
        });
    };
    handleSaveShipping = (props) => {
        const { baseProductRawId, storeId, isAddVariant } = props;
        const { shippingData } = this.state;
        if (!shippingData) {
            this.props.showAlert({
                type: 'danger',
                text: t.somethingGoingWrong,
                link: { text: t.close }
            });
            return;
        }
        const { local, international, pickup, withoutInter, withoutLocal } = shippingData;
        const params = {
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    local: withoutLocal ? [] : local,
                    international: withoutInter ? [] : international,
                    pickup: withoutLocal ? {
                        pickup: false,
                        price: 0
                    } : pickup,
                    baseProductId: baseProductRawId,
                    storeId: parseInt(String(storeId), 10)
                }
            },
            environment: this.props.environment,
            onCompleted: (response, errors) => {
                this.setState({ isLoading: false });
                debug({
                    response,
                    errors
                });
                const relayErrors = fromRelayError({ source: { errors } });
                debug({ relayErrors });
                const validationErrors = pathOr({}, ['100', 'messages'], relayErrors);
                if (!isEmpty(validationErrors)) {
                    this.props.showAlert({
                        type: 'danger',
                        text: t.validationError,
                        link: { text: t.close }
                    });
                    return;
                }
                const statusError = pathOr({}, ['100', 'status'], relayErrors);
                if (!isEmpty(statusError)) {
                    this.props.showAlert({
                        type: 'danger',
                        text: `${t.error} "${statusError}"`,
                        link: { text: t.close }
                    });
                    return;
                }
                const parsingError = pathOr(null, ['300', 'message'], relayErrors);
                if (parsingError) {
                    debug('parsingError:', { parsingError });
                    this.props.showAlert({
                        type: 'danger',
                        text: t.somethingGoingWrong,
                        link: { text: t.close }
                    });
                    return;
                }
                if (errors) {
                    this.props.showAlert({
                        type: 'danger',
                        text: t.somethingGoingWrong,
                        link: { text: t.close }
                    });
                    return;
                }
                this.props.showAlert({
                    type: 'success',
                    text: t.productCreated,
                    link: { text: '' }
                });
                this.props.router.push(`/manage/store/${storeId}/products/${parseInt(String(baseProductRawId), 10)}${isAddVariant ? '/variant/new' : ''}`);
            },
            onError: (err) => {
                this.setState({ isLoading: false });
                error(err);
                this.props.showAlert({
                    type: 'danger',
                    text: t.somethingGoingWrong,
                    link: { text: t.close }
                });
            }
        };
        UpsertShippingMutation.commit(params);
    };
    handleChangeShipping = (shippingData) => {
        this.setState({ shippingData });
    };
    handleCreateAttribute = (attribute) => {
        this.setState((prevState) => ({ customAttributes: prepend(attribute, prevState.customAttributes) }));
    };
    handleRemoveAttribute = (id) => {
        this.setState((prevState) => ({
            customAttributes: filter((item) => item.id !== id, prevState.customAttributes)
        }));
    };
    handleResetAttribute = () => {
        this.setState({ customAttributes: [] });
    };
    render() {
        const { isLoading, availablePackages, shippingData, customAttributes, formErrors, isLoadingPackages } = this.state;
        return (React.createElement(AppContext.Consumer, null, ({ directories }) => (React.createElement("div", { className: styles.wrap },
            React.createElement(Form, { baseProduct: null, isLoading: isLoading, availablePackages: availablePackages, shippingData: shippingData, customAttributes: customAttributes, formErrors: formErrors, onSave: this.handleSave, allCategories: this.props.allCategories, currencies: directories.sellerCurrencies, onChangeShipping: this.handleChangeShipping, onCreateAttribute: this.handleCreateAttribute, onRemoveAttribute: this.handleRemoveAttribute, onResetAttribute: this.handleResetAttribute, isLoadingPackages: isLoadingPackages, onFetchPackages: this.handleFetchPackages, handleFetchPackages: this.handleFetchPackages })))));
    }
}
export default createFragmentContainer(withRouter(withShowAlert(Page(ManageStore({
    OriginalComponent: NewProduct,
    active: 'goods',
    title: 'Goods'
})))), {
    me: graphql `
			fragment NewProduct_me on User {
				myStore {
					id
					logo
					name {
						text
						lang
					}
					warehouses {
						addressFull {
							countryCode
						}
					}
					baseProducts(first: 100) @connection(key: "Wizard_baseProducts") {
						edges {
							node {
								id
								rawId
								name {
									text
									lang
								}
								shortDescription {
									lang
									text
								}
								category {
									id
									rawId
								}
								storeId
								currency
								products(first: 1) @connection(key: "Wizard_products") {
									edges {
										node {
											id
											rawId
											price
											preOrder
											preOrderDays
											discount
											photoMain
											additionalPhotos
											vendorCode
											cashback
											price
											attributes {
												value
												metaField
												attribute {
													id
													rawId
													name {
														lang
														text
													}
													metaField {
														values
														translatedValues {
															translations {
																text
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
    `
});
