/**
 * @generated SignedSource<<80a02b035231a8ea4dcf337b41a503c4>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "id"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "id"
                }
            ],
            "concreteType": "GraphQLWarehouse",
            "kind": "LinkedField",
            "name": "deleteWarehouse",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "DeleteWarehouseMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "DeleteWarehouseMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "067e7a23da0d96b2d4edb9b4197176dd",
            "id": null,
            "metadata": {},
            "name": "DeleteWarehouseMutation",
            "operationKind": "mutation",
            "text": "mutation DeleteWarehouseMutation(\n  $id: String!\n) {\n  deleteWarehouse(id: $id) {\n    id\n  }\n}\n"
        }
    };
})();
node.hash = "76b94953d3a1f3f6a46c9d4552b0f454";
export default node;
