import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay';
import { ConnectionHandler, Environment } from 'relay-runtime';
import { append, assocPath, complement, concat, head, isEmpty, map, omit, path, pathOr, pick, type, where } from 'ramda';
import debounce from 'lodash.debounce';
import { withRouter } from 'found';
import { v4 as uuidv4 } from 'uuid';
import { CreateBaseProductWithVariantsMutation, CreateStoreMutation, CreateStoreSubscriptionMutation, CreateWarehouseMutation, CreateWizardMutation, DeactivateBaseProductMutation, DeleteWizardMutation, SetProductQuantityInWarehouseMutation, UpdateBaseProductMutation, UpdateProductMutation, UpdateStoreMutation, UpdateStoreSubscriptionMutation, UpdateWizardMutation } from '../../../relay/mutations';
import { debug, errorsHandler, fromRelayError } from '../../../utils';
import { Button } from '../../../components/common/Button';
import { Modal } from '../../../components/Modal';
import { Page } from '../../../components/App';
import { withShowAlert } from '../../../components/Alerts/AlertContext';
import { transformTranslated } from './utils';
import WizardHeader from './WizardHeader';
import WizardFooter from './WizardFooter';
import Step1 from './Step1/Form';
import Step2 from './Step2/Form';
import Step3 from './Step3/View';
import Step4 from './Step4';
import styles from './Wizard.module.scss';
import t from './i18n';
export const initialProductState = {
    baseProduct: {
        id: null,
        storeId: null,
        currency: 'INR',
        categoryId: null,
        name: '',
        shortDescription: '',
        product: {
            id: null,
            baseProductId: null,
            vendorCode: '',
            photoMain: '',
            additionalPhotos: [],
            price: 0,
            cashback: 5,
            quantity: 1
        },
        attributes: []
    }
};
class WizardWrapper extends Component {
    static contextTypes = { environment: PropTypes.instanceOf(Environment).isRequired };
    static getDerivedStateFromProps(nextProps, prevState) {
        const wizardStore = pathOr(null, ['me', 'wizardStore'], nextProps);
        return {
            ...prevState,
            baseProduct: {
                ...prevState.baseProduct,
                storeId: wizardStore && wizardStore.storeId
            }
        };
    }
    constructor(props) {
        super(props);
        this.state = {
            showConfirm: false,
            step: 1,
            editingProduct: false,
            ...initialProductState,
            isValid: true,
            validationErrors: null,
            isSavingInProgress: false
        };
    }
    componentDidMount() {
        window.scroll({ top: 0 });
        this.createWizard();
    }
    handleSuccess = (text) => {
        this.setState({ isValid: true });
        if (text) {
            this.props.showAlert({
                text,
                type: 'success',
                link: { text: '' }
            });
        }
    };
    handleWizardError = (messages) => {
        this.setState({
            isValid: false,
            validationErrors: messages || null
        });
    };
    clearValidationErrors = () => this.setState({
        isValid: true,
        validationErrors: null
    });
    createWizard = () => {
        CreateWizardMutation.commit({
            environment: this.context.environment,
            onCompleted: (response, errors) => {
                debug({
                    response,
                    errors
                });
                const relayErrors = fromRelayError({ source: { errors } });
                if (relayErrors) {
                    // pass showAlert for show alert errors in common cases
                    // pass handleCallback specify validation errors
                    errorsHandler(relayErrors, this.props.showAlert, this.handleWizardError);
                }
            },
            onError: (err) => {
                debug({ err });
                const relayErrors = fromRelayError(err);
                errorsHandler(relayErrors, this.props.showAlert, this.handleWizardError);
            }
        });
    };
    updateWizard = (data, callback) => {
        UpdateWizardMutation.commit({
            ...omit(['completed', 'slug'], data),
            slug: data.slug || null,
            defaultLanguage: data.defaultLanguage ? data.defaultLanguage : 'EN',
            addressFull: data.addressFull ? data.addressFull : {},
            environment: this.context.environment,
            onCompleted: (response, errors) => {
                debug({
                    response,
                    errors
                });
                const relayErrors = fromRelayError({ source: { errors } });
                if (relayErrors) {
                    // pass showAlert for show alert errors in common cases
                    // pass handleCallback specify validation errors
                    errorsHandler(relayErrors, this.props.showAlert, this.handleWizardError);
                    if (callback) {
                        callback(false);
                    }
                    return;
                }
                this.clearValidationErrors();
                if (callback) {
                    callback(true);
                }
            },
            onError: (error) => {
                debug({ error });
                const relayErrors = fromRelayError(error);
                errorsHandler(relayErrors, this.props.showAlert, this.handleWizardError);
                if (callback) {
                    callback(true);
                }
            }
        });
    };
    prepareStoreMutationInput = () => {
        const wizardStore = pathOr({ addressFull: {} }, ['me', 'wizardStore'], this.props);
        const id = pathOr(null, ['me', 'wizardStore', 'store', 'id'], this.props);
        const userId = pathOr(null, ['me', 'rawId'], this.props);
        const preparedData = transformTranslated('EN', ['name', 'shortDescription'], {
            id,
            userId,
            ...pick(['name', 'shortDescription', 'defaultLanguage', 'slug'], wizardStore),
            addressFull: wizardStore.addressFull
        });
        return preparedData;
    };
    createStore = (callback) => {
        const preparedData = this.prepareStoreMutationInput();
        CreateStoreMutation.commit({
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    ...omit(['id'], preparedData)
                }
            },
            environment: this.context.environment,
            onCompleted: (response, errors) => {
                debug('CreateStoreMutation.commit', {
                    response,
                    errors
                });
                const relayErrors = fromRelayError({ source: { errors } });
                debug({ relayErrors });
                if (relayErrors) {
                    errorsHandler(relayErrors, this.props.showAlert, this.handleWizardError);
                    callback(false);
                    return;
                }
                const createStore = pathOr(null, ['createStore'], response);
                if (!createStore) {
                    this.props.showAlert({
                        type: 'danger',
                        text: t.unknownError,
                        link: { text: t.close }
                    });
                    callback(false);
                    return;
                }
                this.clearValidationErrors();
                const storeId = pathOr(null, ['createStore', 'rawId'], response);
                this.updateWizard({
                    storeId,
                    slug: null
                }, callback);
            },
            onError: (err) => {
                debug('CreateStoreMutation.commit', { err });
                const relayErrors = fromRelayError(err);
                debug({ relayErrors });
                errorsHandler(relayErrors, this.props.showAlert, this.handleWizardError);
                callback(false);
            }
        });
    };
    updateStore = (callback) => {
        const preparedData = this.prepareStoreMutationInput();
        if (!preparedData.id) {
            return;
        }
        UpdateStoreMutation.commit({
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    ...omit(['userId'], preparedData)
                }
            },
            environment: this.context.environment,
            onCompleted: (response, errors) => {
                debug({
                    response,
                    errors
                });
                const relayErrors = fromRelayError({ source: { errors } });
                if (relayErrors) {
                    errorsHandler(relayErrors, this.props.showAlert, this.handleWizardError);
                    callback(false);
                    return;
                }
                // create storage if not exists
                const addressFull = pathOr(null, ['updateStore', 'addressFull'], response);
                const warehouses = pathOr(null, ['me', 'wizardStore', 'store', 'warehouses'], this.props);
                const storeId = pathOr(null, ['me', 'wizardStore', 'store', 'rawId'], this.props);
                if ((!warehouses || isEmpty(warehouses))
                    && addressFull
                    && addressFull.country) {
                    CreateWarehouseMutation.commit({
                        variables: {
                            input: {
                                clientMutationId: uuidv4(),
                                storeId,
                                addressFull
                            }
                        },
                        environment: this.context.environment,
                        onCompleted: (res, err) => {
                            debug('CreateWarehouseMutation', { res });
                            const relayError = fromRelayError({ source: { errors: err } });
                            if (relayErrors) {
                                errorsHandler(relayError, this.props.showAlert, this.handleWizardError);
                                callback(false);
                                return;
                            }
                            this.clearValidationErrors();
                            callback(true);
                        },
                        onError: (err) => {
                            const relayErrorz = fromRelayError(err);
                            errorsHandler(relayErrorz, this.props.showAlert, this.handleWizardError);
                            callback(false);
                        }
                    });
                }
                else {
                    this.clearValidationErrors();
                    callback(true);
                }
            },
            onError: (error) => {
                debug({ error });
                const relayErrors = fromRelayError(error);
                errorsHandler(relayErrors, this.props.showAlert, this.handleWizardError);
                callback(false);
            }
        });
    };
    handleOnChangeStep = (step) => {
        this.setState({ step });
    };
    handleOnSaveStep = (changedStep) => {
        const { step } = this.state;
        const storeId = pathOr(null, ['me', 'wizardStore', 'storeId'], this.props);
        this.setState({ isSavingInProgress: true });
        switch (step) {
            case 1:
                if (storeId) {
                    this.updateStore((success) => {
                        this.setState({ isSavingInProgress: false });
                        if (success) {
                            this.handleOnChangeStep(changedStep);
                        }
                    });
                    break;
                }
                this.createStore((success) => {
                    this.setState({ isSavingInProgress: false });
                    if (success) {
                        this.handleOnChangeStep(changedStep);
                    }
                });
                break;
            case 2:
                this.updateStore((success) => {
                    this.setState({ isSavingInProgress: false });
                    if (success) {
                        this.handleOnChangeStep(changedStep);
                    }
                });
                break;
            case 3:
                this.setState({ isSavingInProgress: false });
                this.handleOnChangeStep(changedStep);
                break;
            case 4:
                this.setState({ showConfirm: true });
                break;
            default:
                break;
        }
    };
    handleEndingWizard = () => {
        const storeId = pathOr(null, ['me', 'wizardStore', 'storeId'], this.props);
        this.setState({ showConfirm: false }, () => {
            this.props.router.push(`/manage/store/${storeId}/products`);
            DeleteWizardMutation.commit({
                environment: this.context.environment,
                onCompleted: (response, errors) => {
                    debug({
                        response,
                        errors
                    });
                    const relayErrors = fromRelayError({ source: { errors } });
                    if (relayErrors) {
                        errorsHandler(relayErrors, this.props.showAlert);
                        return;
                    }
                    this.clearValidationErrors();
                    this.handleOnClearProductState();
                },
                onError: (error) => {
                    debug({ error });
                    const relayErrors = fromRelayError(error);
                    errorsHandler(relayErrors, this.props.showAlert, this.handleWizardError);
                }
            });
        });
    };
    handleSetSubscribe = (currency) => {
        const subscriptionCurrency = pathOr(null, ['me', 'myStore', 'storeSubscription', 'currency'], this.props);
        if (subscriptionCurrency) {
            this.handleUpdateStoreSubscription(currency);
        }
        else {
            this.handleCreateStoreSubscription(currency);
        }
    };
    handleCreateStoreSubscription = (currency) => {
        const storeId = pathOr(null, ['me', 'wizardStore', 'storeId'], this.props);
        CreateStoreSubscriptionMutation({
            environment: this.context.environment,
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    storeId: parseInt(storeId, 10),
                    currency
                }
            },
            updater: (relayStore) => {
                const storeSubscription = relayStore.getRootField('createStoreSubscription');
                const me = relayStore.getRoot()
                    .getLinkedRecord('me');
                const myStore = me.getLinkedRecord('myStore');
                myStore.setLinkedRecord(storeSubscription, 'storeSubscription');
            }
        })
            .then(() => true)
            .catch(() => {
            this.props.showAlert({
                type: 'danger',
                text: t.somethingGoingWrong,
                link: { text: t.close }
            });
        });
    };
    handleUpdateStoreSubscription = (currency) => {
        const storeId = pathOr(null, ['me', 'wizardStore', 'storeId'], this.props);
        UpdateStoreSubscriptionMutation({
            environment: this.context.environment,
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    storeId: parseInt(storeId, 10),
                    currency
                }
            },
            updater: (relayStore) => {
                const storeSubscription = relayStore.getRootField('updateStoreSubscription');
                const me = relayStore.getRoot()
                    .getLinkedRecord('me');
                const myStore = me.getLinkedRecord('myStore');
                myStore.setLinkedRecord(storeSubscription, 'storeSubscription');
            }
        })
            .then(() => true)
            .catch(() => {
            this.props.showAlert({
                type: 'danger',
                text: t.somethingGoingWrong,
                link: { text: t.close }
            });
        });
    };
    // delay for block tonns of query
    handleOnSaveWizard = debounce((data) => {
        if (data) {
            const addressFull = pathOr(null, ['addressFull'], data);
            this.updateWizard({
                ...omit([
                    'id',
                    'rawId',
                    'stepOne',
                    'stepTwo',
                    'stepThree',
                    'store',
                    'addressFull'
                ], data),
                addressFull: {
                    ...pick([
                        'value',
                        'country',
                        'countryCode',
                        'administrativeAreaLevel1',
                        'administrativeAreaLevel2',
                        'locality',
                        'political',
                        'postalCode',
                        'route',
                        'streetNumber',
                        'placeId'
                    ], addressFull)
                }
            });
        }
    }, 250);
    handleChangeForm = (data) => {
        if (!data.slug) {
            this.handleWizardError();
            return;
        }
        this.handleOnSaveWizard(data);
    };
    handleOnChangeEditingProduct = (value) => {
        this.setState({ editingProduct: value });
    };
    // Product handlers
    createBaseProduct = (callback) => {
        const { baseProduct } = this.state;
        const preparedDataForBaseProduct = transformTranslated('EN', ['name', 'shortDescription'], omit(['id', 'product', 'attributes'], baseProduct));
        const prepareDataForProduct = {
            product: {
                ...omit(['id', 'quantity', 'baseProductId'], baseProduct.product),
                cashback: (baseProduct.product.cashback || 0) / 100
            },
            attributes: baseProduct.attributes
        };
        const parentID = pathOr(null, ['me', 'wizardStore', 'store', 'id'], this.props);
        this.setState({ isSavingInProgress: true });
        CreateBaseProductWithVariantsMutation({
            environment: this.context.environment,
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    ...preparedDataForBaseProduct,
                    selectedAttributes: map((item) => item.attrId, baseProduct.attributes),
                    variants: [{
                            clientMutationId: uuidv4(),
                            ...prepareDataForProduct
                        }]
                }
            },
            updater: (relayStore) => {
                if (parentID) {
                    const storeProxy = relayStore.get(parentID);
                    const conn = ConnectionHandler.getConnection(storeProxy, 'Wizard_baseProducts');
                    const newProduct = relayStore.getRootField('createBaseProductWithVariants');
                    const edge = ConnectionHandler.createEdge(relayStore, conn, newProduct, 'BaseProductsEdge');
                    ConnectionHandler.insertEdgeAfter(conn, edge);
                }
            }
        })
            .then((response) => {
            debug({ response });
            const productId = pathOr(null, [
                'createBaseProductWithVariants',
                'products',
                'edges',
                0,
                'node',
                'rawId'
            ], response);
            if (!productId) {
                this.props.showAlert({
                    type: 'danger',
                    text: 'Something going wrong :(',
                    link: { text: 'Close.' }
                });
            }
            let warehouseId = pathOr(null, ['createBaseProductWithVariants', 'store', 'warehouses', 0, 'id'], response);
            if (!warehouseId) {
                warehouseId = pathOr(null, [
                    'createBaseProductWithVariants',
                    'products',
                    'edges',
                    0,
                    'node',
                    'stocks',
                    0,
                    'warehouseId'
                ], response);
            }
            return SetProductQuantityInWarehouseMutation.promise({
                input: {
                    clientMutationId: uuidv4(),
                    warehouseId,
                    productId,
                    quantity: this.state.baseProduct.product.quantity
                }
            }, this.context.environment);
        })
            .then(() => {
            this.clearValidationErrors();
            this.handleOnClearProductState();
            this.setState({ isSavingInProgress: false });
            callback();
            return true;
        })
            .catch((errors) => {
            this.setState({ isSavingInProgress: false });
            const relayErrors = fromRelayError({ source: { errors: type(errors) === 'Array' ? errors : [errors] } });
            if (relayErrors && !isEmpty(relayErrors)) {
                errorsHandler(relayErrors, this.props.showAlert, this.handleWizardError);
            }
            else {
                this.props.showAlert({
                    type: 'danger',
                    text: `${t.error}: ${pathOr(t.unknownError, ['message'], head(errors))}`,
                    link: { text: t.close }
                });
            }
        });
    };
    updateBaseProduct = (callback) => {
        const { baseProduct } = this.state;
        const preparedData = transformTranslated('EN', ['name', 'shortDescription'], omit(['product', 'attributes'], baseProduct));
        this.setState({ isSavingInProgress: true });
        UpdateBaseProductMutation.promise({ ...preparedData }, this.context.environment)
            .then(() => {
            const prepareDataForProduct = {
                id: baseProduct.product.id,
                product: {
                    ...pick(['photoMain', 'additionalPhotos', 'vendorCode', 'price'], baseProduct.product),
                    cashback: (baseProduct.product.cashback || 0) / 100
                },
                attributes: baseProduct.attributes
            };
            const variables = {
                input: {
                    ...prepareDataForProduct,
                    clientMutationId: uuidv4()
                }
            };
            return UpdateProductMutation.promise(variables, this.context.environment);
        })
            .then((updateProductMutationResponse) => {
            let warehouseId = pathOr(null, ['updateProduct', 'baseProduct', 'store', 'warehouses', 0, 'id'], updateProductMutationResponse);
            if (!warehouseId) {
                warehouseId = pathOr(null, ['updateProduct', 'stocks', 0, 'warehouseId'], updateProductMutationResponse);
            }
            const productId = pathOr(null, ['updateProduct', 'rawId'], updateProductMutationResponse);
            return SetProductQuantityInWarehouseMutation.promise({
                input: {
                    clientMutationId: uuidv4(),
                    warehouseId,
                    productId,
                    quantity: this.state.baseProduct.product.quantity
                }
            }, this.context.environment);
        })
            .then(() => {
            this.clearValidationErrors();
            this.handleOnClearProductState();
            this.props.showAlert({
                type: 'success',
                text: t.productUpdated,
                link: { text: t.close }
            });
            this.setState({ isSavingInProgress: false });
            callback();
            return true;
        })
            .catch((errors) => {
            this.setState({ isSavingInProgress: false });
            const relayErrors = fromRelayError({ source: { errors } });
            if (relayErrors && !isEmpty(relayErrors)) {
                errorsHandler(relayErrors, this.props.showAlert, this.handleWizardError);
            }
            else {
                this.props.showAlert({
                    type: 'danger',
                    text: `${t.error}: ${pathOr(t.unknownError, ['message'], head(errors))}`,
                    link: { text: t.close }
                });
            }
        });
    };
    handleOnClearProductState = () => {
        this.setState((prevState) => {
            // storeId save for next products
            const clearedData = omit(['storeId'], initialProductState.baseProduct);
            return {
                ...prevState,
                baseProduct: {
                    ...prevState.baseProduct,
                    ...clearedData
                }
            };
        });
    };
    handleOnDeleteProduct = (ID) => {
        const variables = {
            input: {
                clientMutationId: uuidv4(),
                id: ID
            }
        };
        const storeID = path(['me', 'wizardStore', 'store', 'id'], this.props);
        DeactivateBaseProductMutation.commit({
            variables,
            parentID: storeID,
            environment: this.context.environment,
            onCompleted: (response, errors) => {
                debug({
                    response,
                    errors
                });
                const relayErrors = fromRelayError({ source: { errors } });
                if (isEmpty(relayErrors)) {
                    errorsHandler(relayErrors, this.props.showAlert, this.handleWizardError);
                    return;
                }
                if (errors) {
                    this.props.showAlert({
                        type: 'danger',
                        text: 'Something going wrong :(',
                        link: { text: 'Close.' }
                    });
                    return;
                }
                this.clearValidationErrors();
                this.handleOnClearProductState();
            },
            onError: (error) => {
                debug({ error });
                const relayErrors = fromRelayError(error);
                errorsHandler(relayErrors, this.props.showAlert, this.handleWizardError);
                if (error) {
                    this.props.showAlert({
                        type: 'danger',
                        text: t.somethingGoingWrong,
                        link: { text: t.close }
                    });
                }
            }
        });
    };
    handleOnChangeProductForm = (data) => {
        this.setState((preState) => ({
            baseProduct: {
                ...preState.baseProduct,
                ...data
            }
        }));
    };
    handleOnUploadMainPhoto = (url) => {
        this.setState((prevState) => assocPath(['baseProduct', 'product', 'photoMain'], url, prevState));
    };
    handleOnUploadAdditionalPhotos = (photosUrls) => {
        const additionalPhotos = path(['baseProduct', 'product', 'additionalPhotos'], this.state) || [];
        this.setState((prevState) => ({
            ...prevState,
            baseProduct: {
                ...prevState.baseProduct,
                product: {
                    ...prevState.baseProduct.product,
                    additionalPhotos: concat(additionalPhotos, photosUrls)
                }
            }
        }));
    };
    handleOnSaveProduct = (callback) => {
        const { baseProduct } = this.state;
        if (baseProduct.id) {
            this.updateBaseProduct(callback);
        }
        else {
            this.createBaseProduct(callback);
        }
    };
    renderForm = () => {
        const { allCategories, me } = this.props;
        const { step, isSavingInProgress } = this.state;
        const wizardStore = pathOr(null, ['me', 'wizardStore'], this.props);
        const baseProducts = pathOr(null, ['me', 'wizardStore', 'store', 'baseProducts'], this.props);
        const subscriptionCurrency = pathOr(null, ['me', 'myStore', 'storeSubscription', 'currency'], this.props);
        switch (step) {
            case 1:
                return (React.createElement(Step1, { initialData: wizardStore, onChange: this.handleChangeForm, errors: this.state.validationErrors }));
            case 2:
                return (React.createElement(Step2, { initialData: wizardStore, languages: this.props.languages, onChange: this.handleChangeForm }));
            case 3:
                return (React.createElement(Step3, { formStateData: this.state.baseProduct, products: baseProducts ? baseProducts.edges : [], onUploadMainPhoto: this.handleOnUploadMainPhoto, onUploadAdditionalPhotos: this.handleOnUploadAdditionalPhotos, onChange: this.handleOnChangeProductForm, onClearProductState: this.handleOnClearProductState, onSave: this.handleOnSaveProduct, onDelete: this.handleOnDeleteProduct, 
                    // errors={this.state.validationErrors}
                    onChangeEditingProduct: this.handleOnChangeEditingProduct, isSavingInProgress: isSavingInProgress, allCategories: allCategories }));
            case 4:
                return (React.createElement(Step4, { me: me, onSetSubscribe: this.handleSetSubscribe, subscriptionCurrency: subscriptionCurrency }));
            default:
                break;
        }
        return null;
    };
    render() {
        const { me } = this.props;
        const { step, showConfirm, isValid, editingProduct, isSavingInProgress } = this.state;
        const { wizardStore } = me;
        const baseProducts = pathOr(null, ['me', 'wizardStore', 'store', 'baseProducts'], this.props);
        const addressFull = pathOr(null, ['me', 'wizardStore', 'addressFull'], this.props);
        const isNotEmpty = complement((i) => !i);
        const stepOneChecker = where({
            name: isNotEmpty,
            slug: isNotEmpty,
            shortDescription: isNotEmpty
        });
        const steptTwoChecker = where({ defaultLanguage: isNotEmpty });
        let isReadyToNext = false;
        let allowedSteps = [];
        if (wizardStore) {
            const stepOne = pick(['name', 'shortDescription', 'slug'], wizardStore);
            const isStepOnePopulated = stepOneChecker(stepOne);
            const stepTwo = pick(['defaultLanguage'], wizardStore);
            const isStepTwoPopulated = steptTwoChecker(stepTwo);
            const isStepThreePopulated = baseProducts && baseProducts.edges.length > 0;
            // set allowed steps
            if (isStepOnePopulated && isValid) {
                allowedSteps = append(2, allowedSteps);
            }
            if (isStepTwoPopulated && isValid && addressFull && addressFull.country) {
                allowedSteps = append(3, allowedSteps);
            }
            if (isStepThreePopulated && isValid) {
                allowedSteps = append(4, allowedSteps);
            }
            if (step === 1 && isStepOnePopulated && isValid) {
                isReadyToNext = true;
            }
            if (step === 2
                && isStepTwoPopulated
                && isValid
                && addressFull
                && addressFull.country) {
                isReadyToNext = true;
            }
            if (step === 3 && isStepThreePopulated && isValid) {
                isReadyToNext = true;
            }
            if (step === 4 && me.stripeCustomer) {
                isReadyToNext = true;
            }
        }
        return (React.createElement("div", { className: styles.wizardContainer },
            React.createElement("div", { className: styles.stepperWrapper },
                React.createElement(WizardHeader, { currentStep: step, allowedSteps: allowedSteps, onChangeStep: this.handleOnChangeStep, onSaveStep: this.handleOnSaveStep })),
            React.createElement("div", { className: styles.contentWrapper }, this.renderForm()),
            !editingProduct && (React.createElement("div", { className: styles.footerWrapper },
                React.createElement(WizardFooter, { currentStep: step, onChangeStep: this.handleOnChangeStep, onSaveStep: this.handleOnSaveStep, isReadyToNext: isReadyToNext, isSavingInProgress: isSavingInProgress }))),
            React.createElement(Modal, { showModal: showConfirm, onClose: () => this.setState({ showConfirm: false }) },
                React.createElement("div", { className: styles.endingWrapper, "data-test": "publishPopupWrapper" },
                    React.createElement("div", { className: styles.endingContent },
                        React.createElement("div", { className: styles.title }, t.doYouReallyWantToLeaveThisPage),
                        React.createElement("div", { className: styles.buttonsContainer },
                            React.createElement(Button, { onClick: () => this.setState({ showConfirm: false }), dataTest: "publishWizardButton", wireframe: true, big: true },
                                React.createElement("span", null, t.cancel)),
                            React.createElement("div", { className: styles.secondButton },
                                React.createElement(Button, { onClick: this.handleEndingWizard, dataTest: "cancelPublishWizardButton", big: true },
                                    React.createElement("span", null, t.publishMyStore)))))))));
    }
}
export default createFragmentContainer(withRouter(Page(withShowAlert(WizardWrapper), { withoutCategories: true })), {
    me: graphql `
			fragment Wizard_me on User {
				...Cards_me
				id
				rawId
				myStore {
					id
					rawId
					storeSubscription {
						id
						storeId
						currency
						value
						walletAddress
						trialStartDate
						status
					}
				}
				stripeCustomer {
					id
				}
				wizardStore {
					id
					rawId
					storeId
					name
					slug
					shortDescription
					defaultLanguage
					completed
					addressFull {
						country
						countryCode
						value
						administrativeAreaLevel1
						administrativeAreaLevel2
						locality
						political
						postalCode
						route
						streetNumber
					}
					store {
						id
						rawId
						warehouses {
							id
						}
						baseProducts(first: 100) @connection(key: "Wizard_baseProducts") {
							edges {
								node {
									id
									rawId
									name {
										text
										lang
									}
									shortDescription {
										lang
										text
									}
									category {
										id
										rawId
									}
									storeId
									currency
									products(first: 1) @connection(key: "Wizard_products") {
										edges {
											node {
												id
												rawId
												price
												discount
												quantity
												photoMain
												additionalPhotos
												vendorCode
												cashback
												price
												customerPrice {
													price
													currency
												}
												attributes {
													attrId
													value
													metaField
													attribute {
														id
														rawId
														name {
															lang
															text
														}
														metaField {
															values
															translatedValues {
																translations {
																	text
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
    `
});
