import React, { Component } from 'react';
import { assocPath, filter, find, isEmpty, map, omit, pathOr, propEq, propOr, toLower, toUpper } from 'ramda';
import { validate } from '@sansare/libstqfrontend';
import { Environment } from 'relay-runtime';
import PropTypes from 'prop-types';
import ModerationStatus from '../../../../common/ModerationStatus';
import { classNames, convertSrc, uploadFile } from '../../../../../utils';
import { Button, Input, InputSlug, Select, Textarea } from '../../../../../components/common';
import { withErrorBoundary } from '../../../../../components/common/ErrorBoundaries';
import { UploadWrapper } from '../../../../../components/Upload';
import { Icon } from '../../../../../components/Icon';
import { withShowAlert } from '../../../../../components/Alerts/AlertContext';
import { RichEditor } from '../../../../../components/RichEditor';
import styles from './Form.module.scss';
import t from './i18n';
// TODO: extract to shared lib
const languagesDic = {
    en: 'English',
    ch: 'Chinese',
    de: 'German',
    hi: 'Hindi',
    es: 'Spanish',
    fr: 'French',
    ko: 'Korean',
    po: 'Portuguese',
    ja: 'Japanese'
};
class Form extends Component {
    static contextTypes = {
        environment: PropTypes.instanceOf(Environment).isRequired,
        directories: PropTypes.object,
        currentUser: PropTypes.shape({
            id: PropTypes.string,
            rawId: PropTypes.number
        })
    };
    state = {
        form: {
            name: '',
            longDescription: '',
            shortDescription: '',
            defaultLanguage: 'EN',
            slug: '',
            cover: '',
            slogan: '',
            rawId: -1
        },
        langItems: null,
        optionLanguage: 'EN',
        formErrors: {},
        isMainPhotoUploading: false,
        status: undefined
    };
    constructor(props) {
        super(props);
        const { store } = props;
        if (store) {
            this.state = {
                form: {
                    name: pathOr('', ['name', 0, 'text'])(store),
                    longDescription: pathOr('', ['longDescription', 0, 'text'])(store),
                    shortDescription: pathOr('', ['shortDescription', 0, 'text'])(store),
                    defaultLanguage: store.defaultLanguage || 'EN',
                    slug: store.slug || '',
                    cover: store.cover || '',
                    slogan: store.slogan || '',
                    rawId: store.rawId
                },
                langItems: null,
                optionLanguage: 'EN',
                formErrors: {},
                isMainPhotoUploading: false,
                status: store.status
            };
        }
    }
    UNSAFE_componentWillMount() {
        const { directories: { languages } } = this.context;
        const langItems = map((item) => ({
            id: item.isoCode,
            label: languagesDic[item.isoCode]
        }), filter((item) => item.isoCode === 'en', languages));
        this.setState({ langItems });
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const currentFormErrors = this.state.formErrors;
        const nextFormErrors = nextProps.serverValidationErrors;
        if (isEmpty(currentFormErrors) && !isEmpty(nextFormErrors)) {
            this.setState({ formErrors: nextFormErrors });
        }
        else if (nextProps.store) {
            this.setState({ status: nextProps.store.status });
        }
    }
    handleDefaultLanguage = (defaultLanguage) => {
        this.setState(assocPath(['form', 'defaultLanguage'], toUpper(defaultLanguage.id)));
    };
    handleInputChange = (id) => (e) => {
        this.setState((preState) => ({ formErrors: omit([id], preState.formErrors) }));
        const { value } = e.target;
        this.setState((prevState) => assocPath(['form', id], value, prevState));
        if (this.props.handleNewStoreNameChange && id === 'name') {
            this.props.handleNewStoreNameChange(value);
        }
    };
    handleTextareaChange = (id) => (e) => {
        this.setState((preState) => ({ formErrors: omit([id], preState.formErrors) }));
        const { value } = e.target;
        this.setState((prevState) => assocPath(['form', id], value, prevState));
    };
    handleSave = () => {
        if (!this.isSaveAvailable()) {
            return;
        }
        const { currentUser } = this.context;
        const { optionLanguage } = this.state;
        if (!currentUser || !currentUser.rawId) {
            return;
        }
        const { form: { rawId, name, defaultLanguage, longDescription, shortDescription, slug, cover, slogan }, status } = this.state;
        // TODO: вынести в либу спеки
        const { errors: formErrors } = validate({
            name: [
                [(value) => value && value.length > 0, t.nameMustNotBeEmpty]
            ],
            shortDescription: [
                [
                    (value) => value && value.length > 0,
                    t.shortDescriptionMustNotBeEmpty
                ]
            ],
            longDescription: [
                [
                    (value) => value && value.length > 0,
                    t.longDescriptionMustNotBeEmpty
                ]
            ],
            slug: [
                [(value) => value && value.length > 0, t.slugMustNotBeEmpty]
            ]
        }, {
            name,
            longDescription,
            shortDescription,
            cover,
            slug
        });
        if (formErrors) {
            this.setState({ formErrors });
            return;
        }
        this.setState({ formErrors: {} });
        this.props.onSave({
            form: {
                rawId,
                name,
                defaultLanguage,
                longDescription,
                shortDescription,
                slug,
                cover,
                slogan
            },
            optionLanguage,
            status
        });
    };
    handleOnUpload = (e) => {
        e.preventDefault();
        if (!this.isSaveAvailable()) {
            return;
        }
        this.setState({ isMainPhotoUploading: true });
        uploadFile(e.target.files[0])
            .then((result) => {
            if (!result || result.url == null) {
                alert('Error :(');
            }
            this.setState((preState) => assocPath(['form', 'cover'], result.url, preState));
            return true;
        })
            .finally(() => {
            this.setState({ isMainPhotoUploading: false });
        })
            .catch((error) => {
            this.props.showAlert({
                type: 'danger',
                text: error.message,
                link: { text: t.close }
            });
        });
    };
    handleError = (error) => {
        const { showAlert } = this.props;
        showAlert({
            type: 'danger',
            text: error.message,
            link: { text: t.close }
        });
    };
    handleDeleteCover = () => {
        this.setState((preState) => assocPath(['form', 'cover'], '', preState));
    };
    handleLongDescription = (longDescription) => {
        const { form, formErrors } = this.state;
        this.setState({
            form: {
                ...form,
                longDescription
            },
            formErrors: omit(['longDescription'], formErrors)
        });
    };
    writeSlug = (slugValue) => {
        this.setState((prevState) => assocPath(['form', 'slug'], slugValue, prevState));
    };
    // TODO: extract to helper
    renderInput = ({ id, label, limit, required }) => {
        const hereLabel = required ? (React.createElement("span", null,
            label,
            " ",
            React.createElement("span", { className: styles.asterisk }, "*"))) : (label);
        return (React.createElement("div", { className: classNames(styles, 'formItem', 'maxWidthInput') },
            React.createElement(Input, { id: id, value: propOr('', id, this.state.form), label: hereLabel, onChange: this.handleInputChange(id), errors: propOr(null, id, this.state.formErrors), limit: limit, fullWidth: true })));
    };
    renderTextarea = ({ id, label, limit, required }) => {
        const hereLabel = required ? (React.createElement("span", null,
            label,
            " ",
            React.createElement("span", { className: styles.asterisk }, "*"))) : (label);
        return (React.createElement("div", { className: classNames(styles, 'formItem', 'maxWidthTextArea') },
            React.createElement(Textarea, { id: id, value: propOr('', id, this.state.form), label: hereLabel, onChange: this.handleTextareaChange(id), errors: propOr(null, id, this.state.formErrors), limit: limit, fullWidth: true })));
    };
    isSaveAvailable = () => this.state.status === 'DRAFT' ||
        this.state.status === 'DECLINE' ||
        this.state.status === 'PUBLISHED';
    isAbleSendToModeration = () => this.state.status === 'DRAFT';
    render() {
        const { langItems, form: { defaultLanguage, cover, slug }, status, formErrors } = this.state;
        const { isLoading, store } = this.props;
        const defaultLanguageValue = find(propEq(toLower(defaultLanguage || ''), 'id'), langItems || []);
        const realSlug = pathOr('', ['store', 'slug'], this.props);
        const longDescriptionError = propOr(null, 'longDescription', formErrors);
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.form },
                status && (React.createElement("div", { className: styles.storeStatus },
                    React.createElement(ModerationStatus, { status: status, dataTest: `storeStatus_${status}`, link: process.env.REACT_APP_HOST && store && store.rawId
                            ? `${process.env.REACT_APP_HOST}/store/${store.rawId}`
                            : null }))),
                React.createElement("div", { className: styles.formHeader }, !cover ? (React.createElement("div", { className: styles.coverUploadWrap },
                    React.createElement("div", { className: styles.uploadButton },
                        React.createElement(UploadWrapper, { id: "cover-store", onUpload: this.handleOnUpload, customUnit: true, buttonHeight: "10rem", buttonWidth: "100%", buttonIconSize: 32, buttonIconType: "upload", dataTest: "storeCoverUploader", buttonLabel: "", loading: this.state.isMainPhotoUploading })),
                    React.createElement("div", null,
                        React.createElement("div", null, t.uploadMainPhoto),
                        React.createElement("div", { className: styles.uploadRec },
                            t.stronglyRecommendToUpload,
                            React.createElement("br", null),
                            t.size1360x350)))) : (React.createElement("div", { className: styles.cover },
                    React.createElement("button", { className: styles.trash, onClick: this.handleDeleteCover },
                        React.createElement(Icon, { type: "basket", size: 28 })),
                    React.createElement("div", { className: styles.image },
                        React.createElement("img", { src: convertSrc('medium', cover), alt: "Store cover" }))))),
                this.renderInput({
                    id: 'name',
                    label: t.labelStoreName,
                    limit: 50,
                    required: true
                }),
                React.createElement("div", { className: classNames(styles, 'formItem', 'maxWidthInput') },
                    React.createElement(Select, { forForm: true, label: t.labelLanguage, activeItem: defaultLanguageValue, items: langItems, onSelect: this.handleDefaultLanguage, tabIndexValue: 0, dataTest: "storeLangSelect", fullWidth: true })),
                this.renderInput({
                    id: 'slogan',
                    label: t.labelSlogan,
                    limit: 50
                }),
                React.createElement("div", { className: classNames(styles, 'formItem', 'maxWidthInput') },
                    React.createElement(InputSlug, { slug: this.state.form.slug, realSlug: realSlug, onChange: this.writeSlug })),
                this.renderTextarea({
                    id: 'shortDescription',
                    label: t.labelShortDescription,
                    limit: 170,
                    required: true
                }),
                React.createElement("h3", { className: styles.title },
                    React.createElement("strong", null, t.shopEditor)),
                React.createElement(RichEditor, { content: this.state.form.longDescription, onChange: this.handleLongDescription, onError: this.handleError }),
                longDescriptionError && (React.createElement("div", { className: styles.error }, longDescriptionError)),
                React.createElement("div", { className: styles.buttonsPanel },
                    React.createElement("div", { className: styles.saveButton },
                        React.createElement(Button, { big: true, fullWidth: true, onClick: this.handleSave, isLoading: isLoading, disabled: !slug || !this.isSaveAvailable(), dataTest: "saveStoreButton" }, t.save)),
                    this.isAbleSendToModeration() && (React.createElement("div", { className: styles.moderationButton },
                        React.createElement(Button, { big: true, fullWidth: true, onClick: this.props.onClickOnSendToModeration, isLoading: isLoading, disabled: !slug, dataTest: "sendToModerationStoreButton" }, t.sendToModeration))),
                    this.state.status === 'MODERATION' && (React.createElement("div", { className: styles.warnMessage }, t.storeIsOnModeration)),
                    this.state.status === 'BLOCKED' && (React.createElement("div", { className: styles.warnMessage }, t.storeIsBlocked))))));
    }
}
export default withErrorBoundary(withShowAlert(Form));
