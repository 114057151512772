import { graphql } from 'react-relay';
import { basicMutation } from '../../../../../relay/mutations/basicMutation';
const mutation = graphql `
	mutation SetDeliveryPackageInCartMutation(
		$input: SetDeliveryMethodInCartInput!
	) {
		setDeliveryMethodInCart(input: $input) {
			id
			totalCount
			fiat {
				id
				productsCost
				deliveryCost
				totalCount
				totalCost
				totalCostWithoutDiscounts
				productsCostWithoutDiscounts
				couponsDiscounts
				stores {
					edges {
						node {
							id
							...CartStore_store
							productsCost
							deliveryCost
							totalCost
							totalCount
							products {
								id
								selected
								baseProduct(visibility: "active") {
									id
									isShippingAvailable
								}
								quantity
							}
						}
					}
				}
			}
		}
	}
`;
const setDeliveryPackageInCartMutation = basicMutation(mutation, 'setDeliveryMethodInCart');
export default setDeliveryPackageInCartMutation;
