import React, { PureComponent } from 'react';
import { withRouter } from 'found';
import { classNames, flattenFunc, getNameText, searchPathByParent } from '../../../../utils';
import { categoryViewTracker } from '../../../../rrHalper';
import styles from './ProductBreadcrumbs.module.scss';
import t from './i18n';
class ProductBreadcrumbs extends PureComponent {
    render() {
        const { categories, categoryId, router } = this.props;
        const arr = flattenFunc(categories);
        const pathArr = searchPathByParent(arr, parseInt(categoryId, 10));
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.item, onClick: () => router.push('/categories?search='), onKeyDown: () => {
                }, role: "button", tabIndex: 0 }, t.allCategories),
            pathArr.length !== 0
                && pathArr.map((item) => (React.createElement("div", { key: item.rawId, className: styles.item },
                    React.createElement("span", { className: styles.separator }, "/"),
                    React.createElement("div", { className: classNames(styles, 'item', { active: item.rawId === parseInt(categoryId, 10) }), onClick: () => {
                            router.push(`/categories?search=&category=${item.rawId}`);
                            categoryViewTracker(parseInt(item.rawId, 10));
                        }, onKeyDown: () => {
                        }, role: "button", tabIndex: 0 }, getNameText(item.name, 'EN')))))));
    }
}
export default withRouter(ProductBreadcrumbs);
