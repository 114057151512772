import React, { Component } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { filter, find, isEmpty, map, pathOr, whereEq } from 'ramda';
import Photos from '../../../Store/Products/Product/Photos';
import { classNames, convertCurrenciesForSelect, findCategory } from '../../../../../utils';
import { Col, Container, Row } from '../../../../../layout';
import { Button, Input, InputPrice, Select } from '../../../../../components/common';
import { Textarea } from '../../../../../components/common/Textarea';
import { CategorySelector } from '../../../../../components/CategorySelector';
import { Icon } from '../../../../../components/Icon';
import { ContextDecorator } from '../../../../../components/App';
import AttributesForm from '../AttributesForm';
import styles from './ThirdForm.module.scss';
import t from './i18n';
const photoIcons = [
    {
        type: 'mainFoto',
        text: t.iconAddMainPhoto
    },
    {
        type: 'angleView',
        text: t.iconAddAngleView
    },
    {
        type: 'showDetails',
        text: t.iconShowDetails
    },
    {
        type: 'showInScene',
        text: t.iconShowInScene
    },
    {
        type: 'showInUse',
        text: t.iconShowInUse
    },
    {
        type: 'showSizes',
        text: t.iconShowSizes
    },
    {
        type: 'showVariety',
        text: t.iconShowVariety
    }
];
class ThirdForm extends Component {
    constructor(props) {
        super(props);
        const { sellerCurrencies } = this.props.directories;
        const currencies = convertCurrenciesForSelect(sellerCurrencies);
        const selectedCurrency = find((item) => item.label === this.props.data.currency, currencies);
        this.state = { selectedCurrency: selectedCurrency || currencies[0] };
    }
    handleChangeBaseProductState = (e) => {
        const { data } = this.props;
        const { target: { value, name } } = e;
        this.props.onChange({
            ...data,
            [name]: value
        });
    };
    handleChangeProductState = (e) => {
        const { data } = this.props;
        const { name } = e.target;
        let { value } = e.target;
        if (name === 'cashback') {
            if (parseInt(value, 10) > 100) {
                value = '100';
            }
            if (value === '') {
                value = '0';
            }
        }
        const lastValue = `${data.product[name]}`;
        if (parseInt(value, 10) < 0 || value.length === 0) {
            value = '0';
        }
        if (isEmpty(value)) {
            value = lastValue;
        }
        this.props.onChange({
            ...data,
            product: {
                ...data.product,
                [name]: name === 'vendorCode' ? value : parseInt(value, 10)
            }
        });
    };
    handleChangePrice = (price) => {
        const { data } = this.props;
        this.props.onChange({
            ...data,
            product: {
                ...data.product,
                price
            }
        });
    };
    handleChangeCurrency = (selectedCurrency) => {
        const { data } = this.props;
        this.setState({ selectedCurrency }, () => {
            this.props.onChange({
                ...data,
                currency: selectedCurrency.label
            });
        });
    };
    handleAttributesChange = (attrs) => {
        const { onChange } = this.props;
        onChange({ attributes: attrs });
    };
    prepareValuesForAttributes = (attributes) => map((item) => ({
        value: item.value,
        attrId: item.attribute.rawId
    }), attributes);
    checkForSave = () => {
        const { data } = this.props;
        const isNotReady = !data.name
            || !data.shortDescription
            || !data.categoryId
            || !data.product.price
            || !data.product.vendorCode;
        if (isNotReady) {
            return true;
        }
        return false;
    };
    handleAddMainPhoto = (url) => {
        this.props.onUploadMainPhoto(url);
    };
    handleAddPhoto = (photosUrls) => {
        this.props.onUploadAdditionalPhotos(photosUrls);
    };
    handleRemovePhoto = (url) => {
        const { onChange, data } = this.props;
        const { photoMain, additionalPhotos } = data.product;
        if (url === photoMain) {
            onChange({
                product: {
                    ...data.product,
                    photoMain: ''
                }
            });
            return;
        }
        onChange({
            product: {
                ...data.product,
                additionalPhotos: filter((item) => item !== url, additionalPhotos || [])
            }
        });
    };
    renderAttributes = () => {
        const { categoryId, attributes } = this.props.data;
        const catObj = findCategory(whereEq({ rawId: parseInt(String(categoryId), 10) }), this.props.categories);
        return (catObj
            && catObj.getAttributes
            && !isEmpty(catObj.getAttributes) && (React.createElement("div", { className: classNames(styles, 'section', 'correctMargin') },
            React.createElement("div", { className: styles.sectionName }, t.characteristics),
            React.createElement(AttributesForm, { attributes: catObj.getAttributes, values: attributes, onChange: this.handleAttributesChange }))));
    };
    render() {
        const { data, onSave, onClose, isSavingInProgress, allCategories, directories } = this.props;
        const { selectedCurrency } = this.state;
        const categoryId = pathOr(null, ['data', 'categoryId'], this.props);
        return (React.createElement("div", { className: styles.wrapper },
            React.createElement("div", { className: styles.formWrapper },
                React.createElement(Container, { correct: true },
                    React.createElement(Row, null,
                        React.createElement(Col, { size: 12 },
                            React.createElement("div", { className: styles.centered },
                                React.createElement("div", { className: styles.headerTitle }, t.addNewProduct),
                                React.createElement("div", { className: styles.headerDescription }, t.chooseWhatYouGonnaSale))),
                        React.createElement(Col, { size: 12 },
                            React.createElement("div", { className: styles.form },
                                React.createElement("div", { className: styles.section },
                                    React.createElement("div", { className: styles.input },
                                        React.createElement(Input, { id: "name", value: data.name, label: (React.createElement("span", null,
                                                t.labelProductName,
                                                ' ',
                                                React.createElement("span", { className: styles.red }, "*"))), onChange: this.handleChangeBaseProductState, fullWidth: true })),
                                    React.createElement("div", { className: styles.input },
                                        React.createElement(Textarea, { id: "shortDescription", value: data.shortDescription, label: (React.createElement("span", null,
                                                t.labelShortDescription,
                                                " ",
                                                React.createElement("span", { className: styles.red }, "*"))), onChange: this.handleChangeBaseProductState, fullWidth: true }))),
                                React.createElement("div", { className: classNames(styles, 'section', 'correctMargin') },
                                    React.createElement("div", { className: styles.sectionName }, t.productPhotos),
                                    React.createElement("div", { className: styles.uploadersWrapper },
                                        React.createElement(Photos, { photos: data.product.additionalPhotos || [], photoMain: data.product.photoMain, onAddMainPhoto: this.handleAddMainPhoto, onAddPhoto: this.handleAddPhoto, onRemovePhoto: this.handleRemovePhoto }))),
                                React.createElement("div", { className: styles.section },
                                    React.createElement("div", { className: styles.uploadDescriptionContainer },
                                        React.createElement("div", { className: styles.description }, t.forBetterProductAppeareance),
                                        React.createElement("div", { className: styles.iconsWrapper },
                                            React.createElement("div", { className: styles.iconsContainer }, map((icon) => (React.createElement("div", { key: icon.type, className: styles.iconBlock },
                                                React.createElement(Icon, { type: icon.type, size: 56 }),
                                                React.createElement("div", { className: styles.iconDescription }, icon.text))), photoIcons))))),
                                React.createElement("div", { className: styles.section },
                                    React.createElement("div", { className: styles.sectionName }, t.generalSettingsAndPricing),
                                    React.createElement("div", { className: styles.categorySelector },
                                        React.createElement(CategorySelector, { categories: allCategories, onSelect: (id) => this.props.onChange({ categoryId: id }), category: { rawId: data.categoryId } })),
                                    React.createElement("div", { className: classNames(styles, 'productStates', 'formItem') },
                                        React.createElement(Container, { correct: true },
                                            React.createElement(Row, null,
                                                React.createElement(Col, { size: 12, md: 6 },
                                                    React.createElement("div", { className: styles.formItem },
                                                        React.createElement(InputPrice, { id: "price", label: t.price, required: true, onChangePrice: this.handleChangePrice, price: !data.product.price ? 0 : data.product.price, min: 0, fullWidth: true, dataTest: "wizardProductPriceInput" }))),
                                                React.createElement(Col, { size: 12, md: 6 },
                                                    React.createElement("div", { className: styles.formItem },
                                                        React.createElement(Select, { items: convertCurrenciesForSelect(directories.sellerCurrencies), activeItem: selectedCurrency, label: "Currency", forForm: true, containerStyle: {
                                                                marginTop: '3rem',
                                                                width: '100%'
                                                            }, onSelect: this.handleChangeCurrency, dataTest: "step3Currency" }))),
                                                React.createElement(Col, { size: 12, md: 6 },
                                                    React.createElement("div", { className: styles.formItem },
                                                        React.createElement(Input, { id: "vendorCode", value: data.product.vendorCode == null
                                                                ? ''
                                                                : `${data.product.vendorCode}`, label: (React.createElement("span", null,
                                                                t.SKU,
                                                                " ",
                                                                React.createElement("span", { className: styles.red }, "*"))), onChange: this.handleChangeProductState, fullWidth: true }))),
                                                React.createElement(Col, { size: 12, md: 6 },
                                                    React.createElement("div", { className: styles.formItem },
                                                        React.createElement(Input, { id: "cashback", value: data.product.cashback == null
                                                                ? ''
                                                                : `${data.product.cashback}`, label: t.labelCashback, onChange: this.handleChangeProductState, fullWidth: true, type: "number", postfix: "%" }))),
                                                React.createElement(Col, { size: 12, md: 6 },
                                                    React.createElement("div", { className: styles.formItem },
                                                        React.createElement(Input, { id: "quantity", value: data.product.quantity == null
                                                                ? '0'
                                                                : `${data.product.quantity}`, label: t.labelQuantity, onChange: this.handleChangeProductState, fullWidth: true, type: "number", min: 0 }))))))),
                                categoryId && this.renderAttributes(),
                                React.createElement("div", { className: styles.buttons },
                                    React.createElement("div", { className: styles.buttonContainer },
                                        React.createElement(Button, { onClick: () => {
                                                onSave(onClose);
                                            }, dataTest: "wizardSaveProductButton", big: true, disabled: this.checkForSave(), fullWidth: true, isLoading: isSavingInProgress },
                                            React.createElement("span", null, t.save))),
                                    React.createElement("div", { className: styles.buttonContainer },
                                        React.createElement("div", { className: styles.cancelButton, onClick: onClose, onKeyDown: () => {
                                            }, role: "button", tabIndex: 0 },
                                            React.createElement("span", null, t.cancel)))))))))));
    }
}
export default createFragmentContainer(ContextDecorator(ThirdForm), {
    allCategories: graphql `
			fragment ThirdForm_allCategories on Category {
				name {
					lang
					text
				}
				children {
					id
					rawId
					parentId
					level
					name {
						lang
						text
					}
					children {
						id
						rawId
						parentId
						level
						name {
							lang
							text
						}
						children {
							id
							rawId
							parentId
							level
							name {
								lang
								text
							}
							getAttributes {
								id
								rawId
								name {
									text
									lang
								}
								metaField {
									values
									translatedValues {
										translations {
											text
										}
									}
								}
							}
						}
					}
				}
			}
    `
});
