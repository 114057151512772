import React, { Component, Fragment } from 'react';
import { withRouter } from 'found';
import { map } from 'ramda';
import { Col, Row } from '../../layout';
import styles from './StartSellingFAQ.module.scss';
import StartSellingButton from './StartSellingButton';
import { classNames } from '../../utils';
const faqsEn = [
    {
        id: '1',
        text: 'Graame marketplace what is it exactly?',
        desc: 'Graame is the&nbsp;first local marketplace for&nbsp; all kind of shops including small batch craft goods which gives access to&nbsp;the&nbsp;new markets around your&nbsp;locality and beyond, while removing the&nbsp;logistics problem for&nbsp;sellers. All you need to&nbsp;do&nbsp;is to&nbsp;register on&nbsp;the&nbsp;platform, create your own shop and begin selling. We’ll take care of&nbsp;the&nbsp;rest.'
    },
    {
        id: '2',
        text: 'What kind of items would be sold on&nbsp;Graame?',
        desc: 'Our marketplace is focused on&nbsp;items with the&nbsp;following characteriestics: all kind of items from local shops including small batch quantity of&nbsp;tailor and craft items, quality goods made with a&nbsp;passion and&nbsp;a&nbsp;story. Handmade? All the&nbsp;better! A&nbsp;good idea of&nbsp;initial types of&nbsp;items we&nbsp;will offer are: bags, backpacks, glasses, wallets and other reasonably sized items. This is just an&nbsp;idea this is by no&nbsp;means a&nbsp;limiting factor, but will give you a&nbsp;better understanding. We do have high standards for&nbsp;our sellers and&nbsp;the&nbsp;goods they offer, each will need to&nbsp;pass our standards prior to&nbsp;being listed to&nbsp;sell on&nbsp;our&nbsp;platform.'
    },
    {
        id: '3',
        text: 'I am from X city/locality can I&nbsp;sell on&nbsp;your&nbsp;platform?',
        desc: 'We are a&nbsp;local marketplace and will allow sellers from most if not all localities around the&nbsp;country to&nbsp;sell on&nbsp;Graame. However we&nbsp;need to&nbsp;start out from inital tested markets, and currently you are allowed to&nbsp;sell from Rudrapur and&nbsp;Haldwani. Sellers from&nbsp;rest of Uttarakhand and Uttar Pradesh will&nbsp;be the&nbsp;next order of&nbsp;those who can sell on&nbsp;Graame.'
    },
    {
        id: '4',
        text: 'As a&nbsp;seller in&nbsp;which currency do I&nbsp;get paid out&nbsp;in?',
        desc: 'Currently you can get paid out in&nbsp;cash/online in currency INR. Others will follow shortly.'
    }
];
const faqsRu = [
    {
        id: '1',
        text: 'Что такое маркетплейс Graame и&nbsp;где мне узнать подробности?',
        desc: 'Graame&nbsp;— первый маркетплейс на&nbsp;технологии блокчейн для мелкосерийных производителей, который даст доступ к&nbsp;новым целевым аудиториям без трудностей связанных с&nbsp;отправкой. Все что вам нужно&nbsp;— просто зарегистрироваться на&nbsp;платформе, создать магазин и&nbsp;начать продавать! Остальное мы&nbsp;берем на&nbsp;себя. Подробности можно узнать на&nbsp;<a href="graame.zendesk.com" target="_blank">storiqa.zendesk.com</a>.'
    },
    {
        id: '2',
        text: 'Какие типы товаров будут продаваться на&nbsp;Graame?',
        desc: 'Наш маркетплейс нацелен на&nbsp;товары немассового производства, т.&nbsp;е. это товары ручной работы или мелкосерийное производство. Следующий важный момент&nbsp;— качество. Мы&nbsp;тщательно отбираем производителей, которые получат доступ к&nbsp;Graame и&nbsp;проверяем товары перед тем как разместить&nbsp;их на&nbsp;нашей платформе.<br />В&nbsp;самом начале мы&nbsp;нацелены на&nbsp;следующие категории: предметы личного обихода, такие как сумки, рюкзаки, очки, кошельки и&nbsp;т.&nbsp;д., различного рода аксессуары. Также в&nbsp;отдельную категорию можно выделить предметы для дома и&nbsp;предметы искусства.'
    },
    {
        id: '3',
        text: 'В каких странах я&nbsp;смогу реализовать свои товары?',
        desc: 'Наш маркетплейс постепенно будет доступен для пользователей со&nbsp;всего мира, на&nbsp;первом этапе мы&nbsp;начнем привлекать покупателей в&nbsp;странах тихоокеанской Азии, таких как Сингапур, Малайзия, Индонезия и&nbsp;т.&nbsp;д.'
    },
    {
        id: '4',
        text: 'В каких валютах я&nbsp;смогу получать выручку?',
        desc: 'На момент первого релиза платформы вы&nbsp;сможете получить на&nbsp;свой счет как криптовалюту, так и&nbsp;традиционную валюту, такую как USD, RUR или&nbsp;SGD. Прочие валюты будут включены позже.'
    },
    {
        id: '5',
        text: 'Что такое STQ токен и&nbsp;как я&nbsp;могу конвертировать его в&nbsp;обычные валюты?',
        desc: 'Токен STQ&nbsp;— это криптовалютный токен, который будет использоваться на&nbsp;маркетплейсе Graame как инструмент для оплаты за&nbsp;операции на&nbsp;платформе, как инструмент кешбека или как способ вознаграждения за&nbsp;выполнение определенных действий. Кроме&nbsp;того, STQ токен можно обменять на&nbsp;криптовалютных биржах на&nbsp;другие криптовалюты или на&nbsp;традиционные валюты.'
    }
];
class StartSellingFAQ extends Component {
    constructor(props) {
        super(props);
        this.state = { itemId: null };
    }
    handlerOnClick = (e) => {
        const parent = e.target.parentElement;
        this.setState((prevState) => {
            const prevItemId = prevState.itemId;
            if (parent && parent.id) {
                return { itemId: prevItemId === parent.id ? null : parent.id };
            }
            return { itemId: prevState.itemId };
        });
    };
    render() {
        const { lang, router } = this.props;
        const { itemId } = this.state;
        return (React.createElement("div", { className: styles.container },
            React.createElement("h2", { className: styles.title, dangerouslySetInnerHTML: {
                    __html: lang === 'hi'
                        ? 'Часто задаваемые вопросы'
                        : 'Frequently asked questions'
                } }),
            React.createElement("p", { className: styles.subtitle, dangerouslySetInnerHTML: {
                    __html: lang === 'hi'
                        ? 'Возможно, у&nbsp;вас возникли вопросы.<br />Вот ответы на&nbsp;самые популярные:'
                        : 'Here are some common questions we receive about selling on&nbsp;Graame:'
                } }),
            React.createElement("div", { className: styles.faqs, onClick: this.handlerOnClick, onKeyDown: () => {
                }, role: "button", tabIndex: 0 }, map((item) => {
                const show = itemId === item.id;
                return (React.createElement(Fragment, { key: item.id },
                    React.createElement("div", { id: item.id, className: styles.faq },
                        React.createElement("div", { className: styles.plusMinus }, show ? '−' : '+'),
                        React.createElement("div", { className: styles.text, dangerouslySetInnerHTML: { __html: item.text } })),
                    React.createElement("div", { className: classNames(styles, 'desc', { show }), dangerouslySetInnerHTML: { __html: item.desc } })));
            }, lang === 'hi' ? faqsRu : faqsEn)),
            React.createElement(Row, null,
                React.createElement(Col, { size: 12, sm: 12, md: 12, lg: 7, xl: 7 },
                    React.createElement("h3", { className: styles.ready, dangerouslySetInnerHTML: {
                            __html: lang === 'hi'
                                ? 'Готовы начать?'
                                : 'Ready to open your store?'
                        } })),
                React.createElement(Col, { size: 12, sm: 12, md: 12, lg: 5, xl: 5 },
                    React.createElement("div", { className: styles.button },
                        React.createElement(StartSellingButton, { text: lang === 'hi' ? 'Создать магазин' : 'Start selling on Graame', onClick: () => router.push('/manage/wizard') }))))));
    }
}
export default withRouter(StartSellingFAQ);
