import React from 'react';
import { Icon } from '../../../../../components/Icon';
import styles from './Modal.module.scss';
import { classNames } from '../../../../../utils';
const Modal = ({ dark = false, children, showModal, onClose }) => {
    if (!showModal) {
        return null;
    }
    return (React.createElement("div", { className: classNames(styles, 'modalWrapper', { dark }) },
        React.createElement("div", { className: styles.modal },
            React.createElement("div", { className: styles.modalContent },
                React.createElement("div", { className: styles.closeButton, role: "button", onClick: onClose, onKeyDown: () => {
                    }, tabIndex: 0 },
                    React.createElement(Icon, { type: "cross" })),
                children))));
};
export default Modal;
