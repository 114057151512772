import React, { Component } from 'react';
import { map, addIndex } from 'ramda';
import AccordionBlock from './AccordionBlock';
import t from './i18n';
import styles from './Accordion.module.scss';
const mapIndexed = addIndex(map);
class Accordion extends Component {
    constructor(props) {
        super(props);
        this.state = { showAll: false };
    }
    handleOnToggle = () => {
        this.setState((prevState) => ({ showAll: !prevState.showAll }));
    };
    render() {
        const { items, activeId, onClick } = this.props;
        const { showAll } = this.state;
        const filteredItems = showAll ? items : items.slice(0, 5);
        return (React.createElement("div", { className: styles.wrapper },
            mapIndexed((item, index) => (React.createElement("div", { key: item.id, className: styles.blockWrapper },
                React.createElement(AccordionBlock, { tree: item, isExpanded: index < 5, active: activeId, onClick: onClick }),
                React.createElement("div", { className: styles.separator }))), filteredItems),
            items.length > 5 && (React.createElement("div", { className: styles.showAll, onClick: this.handleOnToggle, onKeyDown: () => {
                }, role: "button", tabIndex: 0 }, t.showAll))));
    }
}
export default Accordion;
