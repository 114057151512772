import React, { Component } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { filter, find, flatten, head, includes, isEmpty, isNil, keys, map, path, pathOr, pipe, whereEq } from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import { validate } from '@sansare/libstqfrontend';
import { withRouter } from 'found';
import PropTypes from 'prop-types';
import { Environment } from 'relay-runtime';
import { classNames, error, fromRelayError, getCookie, getCurrentCurrency, renameKeys } from '../../utils';
import smoothscroll from '../../libs/smoothscroll';
import { CreateOrdersMutation, CreateUserDeliveryAddressFullMutation } from '../../relay/mutations';
import { Col, Container, Row } from '../../layout';
import { transactionTracker } from '../../rrHalper';
import { Page } from '../../components/App';
import { withShowAlert } from '../../components/Alerts/AlertContext';
import { StickyBar } from '../../components/StickyBar';
import CheckoutHeader from './CheckoutHeader';
import CheckoutAddress from './CheckoutContent/CheckoutAddress';
import CheckoutProducts from './CheckoutContent/CheckoutProducts';
import { CheckoutSidebar } from './CheckoutSidebar';
// import {PaymentInfoFiat} from './PaymentInfoFiat';
import CartStore from '../Cart/CartStore';
import CartEmpty from '../Cart/CartEmpty';
import CheckoutContext from './CheckoutContext';
import updateUserPhoneMutation from './mutations/UpdateUserPhoneMutation';
import styles from './Checkout.module.scss';
import t from './i18n';
import PaymentInfo from './PaymentInfo/PaymentInfo';
import { PaymentInfoFiat } from './PaymentInfoFiat';
const emptyAddress = {
    value: '',
    country: '',
    administrativeAreaLevel1: '',
    administrativeAreaLevel2: '',
    locality: '',
    political: '',
    postalCode: '',
    route: '',
    streetNumber: '',
    placeId: ''
};
class Checkout extends Component {
    static contextTypes = { environment: PropTypes.instanceOf(Environment).isRequired };
    constructor(props) {
        super(props);
        const { deliveryAddressesFull } = props.me;
        this.state = {
            storesRef: null,
            step: 1,
            isAddressSelect: !isEmpty(deliveryAddressesFull),
            isNewAddress: isEmpty(deliveryAddressesFull),
            saveAsNewAddress: true,
            orderInput: {
                addressFull: emptyAddress,
                receiverName: (props.me && `${props.me.firstName} ${props.me.lastName}`) || '',
                receiverPhone: (props.me && props.me.phone) || ''
            },
            invoice: null,
            checkoutInProcess: false,
            errors: {},
            scrollArr: ['receiverName', 'phone', 'deliveryAddress']
        };
    }
    setStoresRef(ref) {
        if (ref && !this.state.storesRef) {
            this.setState({ storesRef: ref });
        }
    }
    storesRef;
    createAddress = () => {
        this.setState({ checkoutInProcess: true });
        const addressFull = pathOr(null, ['orderInput', 'addressFull'], this.state);
        const userId = pathOr(null, ['me', 'rawId'], this.props);
        CreateUserDeliveryAddressFullMutation.commit({
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    userId,
                    addressFull,
                    isPriority: false
                }
            },
            environment: this.context.environment,
            onCompleted: (response, errors) => {
                if (errors) {
                    this.props.showAlert({
                        type: 'danger',
                        text: t.somethingGoingWrong,
                        link: { text: t.close }
                    });
                    return;
                }
                this.props.showAlert({
                    type: 'success',
                    text: t.addressCreated,
                    link: { text: '' }
                });
                this.setState({
                    isAddressSelect: true,
                    isNewAddress: false,
                    checkoutInProcess: false,
                    step: 2
                });
            },
            onError: (err) => {
                error(err);
                this.setState(() => ({
                    isAddressSelect: true,
                    isNewAddress: false,
                    checkoutInProcess: false
                }));
                this.props.showAlert({
                    type: 'danger',
                    text: t.somethingGoingWrong,
                    link: { text: t.close }
                });
            }
        });
    };
    handleChangeStep = (step) => {
        const { saveAsNewAddress, isNewAddress } = this.state;
        if (saveAsNewAddress && isNewAddress) {
            this.createAddress();
            return;
        }
        this.setState({ step });
    };
    goToCheckout = () => {
        this.setState({ errors: {} });
        const preValidationErrors = this.validate();
        if (!isEmpty(preValidationErrors)) {
            this.setState({ errors: preValidationErrors });
            return;
        }
        const { orderInput } = this.state;
        const { me } = this.props;
        if (me != null && me.phone !== orderInput.receiverPhone) {
            updateUserPhoneMutation({
                environment: this.context.environment,
                variables: {
                    input: {
                        clientMutationId: uuidv4(),
                        id: me.id,
                        phone: orderInput.receiverPhone
                    }
                }
            })
                .then(() => {
                this.handleChangeStep(2);
                return true;
            })
                .catch((err) => {
                const relayErrors = fromRelayError({ source: { errors: [err] } });
                const validationErrors = pathOr({}, ['100', 'messages'], relayErrors);
                if (!isEmpty(validationErrors)) {
                    this.setState({
                        errors: renameKeys({ phone: 'receiverPhone' }, validationErrors)
                    });
                }
            });
            return;
        }
        this.handleChangeStep(2);
    };
    validate = () => {
        const { addressFull } = this.state.orderInput;
        let { errors } = validate({
            receiverName: [[(val) => Boolean(val), t.errors.receiverNameRequired]],
            receiverPhone: [[(val) => Boolean(val), t.errors.receiverPhoneRequired]]
        }, this.state.orderInput);
        if (!addressFull.country || !addressFull.postalCode || !addressFull.value) {
            const errorString = `
        ${!addressFull.country ? t.errors.country : ''}
        ${!addressFull.value ? `, ${t.errors.address}` : ''}
        ${!addressFull.postalCode ? `, ${t.errors.postalCode}` : ''}
        ${t.errors.areRequired}
      `;
            errors = {
                ...errors,
                deliveryAddress: [
                    errorString.replace(/^(\s+)?,\s+/, '')
                        .replace(/\s+,\s+/g, ', ')
                ]
            };
        }
        if (errors && !isEmpty(errors)) {
            const { scrollArr } = this.state;
            const oneArr = filter((item) => includes(item, keys(errors)), scrollArr);
            if (!isEmpty(oneArr) && head(oneArr)) {
                smoothscroll.scrollTo(head(oneArr));
            }
        }
        return errors || {};
    };
    handleOnChangeOrderInput = (orderInput) => {
        this.setState({
            orderInput,
            errors: {}
        });
    };
    handleOnChangeAddressType = () => {
        const { me } = this.props;
        if (isEmpty(me.deliveryAddressesFull)) {
            return;
        }
        this.setState((prevState) => ({
            isAddressSelect: !prevState.isAddressSelect,
            isNewAddress: !prevState.isNewAddress,
            orderInput: {
                ...prevState.orderInput,
                addressFull: emptyAddress
            }
        }));
    };
    handleChangeSaveCheckbox = () => {
        this.setState((prevState) => ({ saveAsNewAddress: !prevState.saveAsNewAddress }));
    };
    handleCheckout = () => {
        const actualCurrency = getCookie('CURRENCY');
        if (!actualCurrency) {
            this.props.showAlert({
                type: 'danger',
                text: t.error,
                link: { text: t.close }
            });
            return;
        }
        const { orderInput: { addressFull, receiverName, receiverPhone } } = this.state;
        this.setState({ checkoutInProcess: true }, () => {
            CreateOrdersMutation.commit({
                variables: {
                    input: {
                        clientMutationId: uuidv4(),
                        addressFull,
                        receiverName,
                        receiverPhone,
                        currency: actualCurrency
                    }
                },
                environment: this.context.environment,
                onCompleted: (response, errors) => {
                    if (response && response.createOrders) {
                        this.props.showAlert({
                            type: 'success',
                            text: t.ordersSuccessfullyCreated,
                            link: { text: t.close }
                        });
                        const { invoice } = response.createOrders;
                        this.setState({
                            invoice,
                            checkoutInProcess: false
                        });
                        this.handleChangeStep(3);
                        if (process.env.BROWSER && process.env.REACT_APP_RRPARTNERID && invoice) {
                            const items = map((item) => ({
                                id: item.productId,
                                qnt: item.quantity,
                                price: item.price
                            }), [...invoice.orders]);
                            transactionTracker({
                                transactionId: invoice.id,
                                items
                            });
                        }
                    }
                    else if (!errors) {
                        this.props.showAlert({
                            type: 'danger',
                            text: t.error,
                            link: { text: t.close }
                        });
                        this.setState({ checkoutInProcess: false });
                    }
                    else {
                        this.props.showAlert({
                            type: 'danger',
                            text: t.error,
                            link: { text: t.close }
                        });
                        this.setState({ checkoutInProcess: false });
                    }
                },
                onError: (err) => {
                    error(t.errorInDeleteFromCart);
                    error(err);
                    this.props.showAlert({
                        type: 'danger',
                        text: t.somethingWentWrong,
                        link: { text: t.close }
                    });
                    this.setState({ checkoutInProcess: false });
                    this.props.router.push('/checkout');
                }
            });
        });
    };
    checkReadyToCheckout = (cart) => {
        if (!cart) {
            return false;
        }
        const { totalCount, stores } = cart;
        const { step } = this.state;
        // check that all products have selected delivery packages
        if (step === 2 && stores && stores.edges instanceof Array) {
            const products = flatten(map((item) => {
                if (item.node && item.node.products instanceof Array) {
                    return item.node.products;
                }
                return [];
            }, stores.edges));
            const selectedProducts = filter(whereEq({ selected: true }), products);
            const isProductsWithoutPackageExist = find(whereEq({ selectPackage: null }), selectedProducts);
            if (!isNil(isProductsWithoutPackageExist)) {
                return false;
            }
        }
        if (totalCount === 0 && step === 2) {
            return false;
        }
        return true;
    };
    render() {
        const { me, cart } = this.props;
        const deliveryAddresses = pathOr(null, ['me', 'deliveryAddressesFull'], this.props);
        const { step, isAddressSelect, isNewAddress, saveAsNewAddress, orderInput, errors, invoice } = this.state;
        const actualCart = cart.fiat;
        const stores = pipe(pathOr([], ['stores', 'edges']), map(path(['node'])))(actualCart);
        const emptyCart = stores.length === 0;
        return (React.createElement(CheckoutContext.Provider, { value: {
                country: pathOr(null, ['addressFull', 'country'], this.state.orderInput)
            } },
            React.createElement("div", { className: styles.mainContainer },
                React.createElement(Container, { withoutGrow: true },
                    React.createElement(Row, { withoutGrow: true },
                        (!emptyCart || step === 3) && (React.createElement(Col, { size: 12 },
                            React.createElement("div", { className: styles.headerWrapper },
                                React.createElement(CheckoutHeader, { currentStep: step, isReadyToNext: this.checkReadyToCheckout(actualCart), onChangeStep: this.handleChangeStep })))),
                        React.createElement(Col, { size: 12 },
                            React.createElement("div", { ref: (ref) => this.setStoresRef(ref) },
                                React.createElement(Row, { withoutGrow: true },
                                    emptyCart && step !== 3 ? (React.createElement(Col, { size: 12 },
                                        React.createElement("div", { className: styles.wrapper },
                                            React.createElement("div", { className: styles.storeContainer },
                                                React.createElement(CartEmpty, null))))) : (React.createElement(Col, { size: 12, lg: step !== 3 ? 8 : 12, xl: step !== 3 ? 9 : 12 },
                                        step === 1 && (React.createElement("div", { className: styles.wrapper },
                                            React.createElement("div", { className: classNames(styles, 'container', 'addressContainer') },
                                                React.createElement(CheckoutAddress, { me: me, isAddressSelect: isAddressSelect, isNewAddress: isNewAddress, saveAsNewAddress: saveAsNewAddress, onChangeSaveCheckbox: this.handleChangeSaveCheckbox, onChangeAddressType: this.handleOnChangeAddressType, deliveryAddresses: deliveryAddresses || [], orderInput: orderInput, onChangeOrderInput: this.handleOnChangeOrderInput, errors: errors })))),
                                        step === 2 && (React.createElement("div", { className: styles.wrapper },
                                            React.createElement("div", { className: styles.container },
                                                React.createElement(CheckoutProducts, { me: me, orderInput: orderInput, onChangeStep: this.handleChangeStep })),
                                            React.createElement("div", { className: styles.storeContainer }, stores.map((store) => (React.createElement(CartStore, { onlySelected: true, unselectable: true, key: store.__id, store: store, totals: 1000, withDeliveryCompaniesSelect: true, currency: getCurrentCurrency() })))))),
                                        step === 3 && (React.createElement(PaymentInfoFiat, { invoice: invoice, me: this.props.me })),
                                        step === 3
                                            && invoice
                                            && (React.createElement(PaymentInfo, { invoiceId: invoice.id, me: this.props.me })))),
                                    !emptyCart
                                        && step !== 3 && (React.createElement(Col, { size: 12, lg: 4, xl: 3 },
                                        React.createElement(StickyBar, null,
                                            React.createElement(CheckoutSidebar, { step: step, buttonText: step === 1 ? t.next : t.checkout, isReadyToClick: this.checkReadyToCheckout(actualCart), checkoutInProcess: this.state.checkoutInProcess, onCheckout: this.handleCheckout, goToCheckout: this.goToCheckout, cart: actualCart, currency: getCurrentCurrency() }))))))))))));
    }
}
export default createFragmentContainer(Page(withShowAlert(withRouter(Checkout)), { withoutCategories: true }), {
    me: graphql `
			fragment Checkout_me on User {
				...PaymentInfo_me
				id
				rawId
				email
				firstName
				lastName
				phone
				deliveryAddressesFull {
					address {
						value
						country
						administrativeAreaLevel1
						administrativeAreaLevel2
						locality
						political
						postalCode
						route
						streetNumber
						placeId
					}
					isPriority
				}
			}
    `,
    cart: graphql `
			fragment Checkout_cart on Cart {
				id
				fiat {
					id
					productsCost
					deliveryCost
					totalCount
					totalCost
					totalCostWithoutDiscounts
					productsCostWithoutDiscounts
					couponsDiscounts
					stores {
						edges {
							node {
								id
								productsCost
								deliveryCost
								totalCost
								totalCount
								...CartStore_store
								products {
									id
									companyPackage {
										id
										rawId
									}
									selectPackage {
										id
										shippingId
									}
									selected
								}
							}
						}
					}
				}
			}
    `
});
