import React, { Component } from 'react';
import { addIndex, filter, head, isEmpty, map, omit, pathOr } from 'ramda';
import PropTypes from 'prop-types';
import { Col, Row } from '../../../../../layout';
import { classNames, getNameText } from '../../../../../utils';
import { Icon } from '../../../../../components/Icon';
import { Button } from '../../../../../components/common/Button';
import { Modal } from '../../../../../components/Modal';
import ProductLayer from '../ProductLayer';
import FormWrapper from '../../FormWrapper';
import styles from './View.module.scss';
import t from './i18n';
import ThirdForm from '../ThirdForm';
import CardProduct from '../../../../../components/CardProduct/CardProduct';
class ThirdStepView extends Component {
    static contextTypes = { directories: PropTypes.object };
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
            deleteId: null,
            priceUsd: null
        };
    }
    prepareAttributesValues = (attributes) => map((item) => ({
        value: item.value,
        attrId: item.attribute.rawId,
        metaField: item.metaField
    }), attributes || []);
    handleOnShowForm = (item) => {
        const { onChange, formStateData, onChangeEditingProduct } = this.props;
        const name = item.name ? getNameText(item.name, 'EN') : '';
        const shortDescription = item.shortDescription
            ? getNameText(item.shortDescription, 'EN')
            : '';
        const productsEdges = pathOr(null, ['products', 'edges'], item);
        const productDataFromItem = head(productsEdges)
            ? head(productsEdges).node
            : {};
        const prepareStateObj = {
            ...formStateData,
            product: {
                ...omit(['attributes'], productDataFromItem),
                cashback: Math.round((productDataFromItem.cashback || 0) * 100),
                price: parseFloat(productDataFromItem.price)
            },
            attributes: this.prepareAttributesValues(productDataFromItem.attributes),
            categoryId: item.category && item.category.rawId,
            id: item.id,
            name,
            shortDescription
        };
        return () => {
            onChange(prepareStateObj);
            this.setState({ showForm: true }, () => onChangeEditingProduct(true));
        };
    };
    handleOnCloseModal = () => {
        const { onClearProductState, onChangeEditingProduct } = this.props;
        this.setState({ showForm: false }, () => {
            onClearProductState();
            onChangeEditingProduct(false);
        });
    };
    handleOnDelete = (ID) => () => {
        const { onDelete } = this.props;
        if (ID) {
            onDelete(ID);
            this.handleOnCloseDelete();
        }
    };
    handleOnCloseDelete = () => {
        this.setState({ deleteId: null });
    };
    handleOnShowDelete = (ID) => () => {
        this.setState({ deleteId: ID });
    };
    renderUploaderItem = () => {
        const { onChangeEditingProduct } = this.props;
        return (React.createElement("div", { className: classNames(styles, 'productItem', 'uploaderItem'), role: "button", onClick: () => this.setState({ showForm: true }, () => onChangeEditingProduct(true)), onKeyDown: () => {
            }, tabIndex: 0, "data-test": "wizardUploaderProductFoto" },
            React.createElement("div", { className: styles.productContent },
                React.createElement(Icon, { type: "cameraPlus", size: 56 }),
                React.createElement("span", { className: styles.buttonLabel }, t.addNewProduct))));
    };
    renderFirstUploaderItem = () => {
        const { onChangeEditingProduct } = this.props;
        return (React.createElement("div", { className: styles.firstUploaderItem },
            React.createElement("div", { className: styles.firstUploaderItemWrapper },
                React.createElement("div", { className: styles.icon },
                    React.createElement(Icon, { type: "cameraPlus", size: 80 })),
                React.createElement("div", { className: styles.text }, t.currentlyYouHaveNoProducts),
                React.createElement("div", { className: styles.button },
                    React.createElement(Button, { onClick: () => this.setState({ showForm: true }, () => onChangeEditingProduct(true)), dataTest: "wizardUploaderProductFotoFirst", big: true, wireframe: true },
                        React.createElement("span", null, t.addFirstProduct))))));
    };
    render() {
        const { formStateData, onChange, products, onUploadMainPhoto, onUploadAdditionalPhotos, onSave, isSavingInProgress, allCategories } = this.props;
        const { showForm, deleteId, priceUsd } = this.state;
        const productsArr = map((item) => item.node, products);
        const filteredProductsArr = filter((item) => {
            const edges = pathOr([], ['products', 'edges'], item);
            return !isEmpty(edges);
        }, productsArr);
        const mapIndexed = addIndex(map);
        if (showForm) {
            return (React.createElement(ThirdForm, { data: formStateData, categories: this.context.directories.categories, onChange: onChange, onUploadMainPhoto: onUploadMainPhoto, onUploadAdditionalPhotos: onUploadAdditionalPhotos, onSave: onSave, onClose: this.handleOnCloseModal, isSavingInProgress: isSavingInProgress, allCategories: allCategories }));
        }
        return (React.createElement(FormWrapper, { thirdForm: true, title: t.fillYouStoreWithGoods, description: t.pleaseAddTheProduct },
            React.createElement("div", { className: styles.view },
                React.createElement(Row, null,
                    !isEmpty(filteredProductsArr) ? (React.createElement(Col, { size: 12, md: 4, xl: 3 }, this.renderUploaderItem())) : (this.renderFirstUploaderItem()),
                    !isEmpty(filteredProductsArr)
                        && mapIndexed((item, index) => (React.createElement(Col, { size: 12, md: 4, xl: 3, key: index },
                            React.createElement("div", { className: classNames(styles, 'productItem', 'cardItem') },
                                React.createElement("div", { className: styles.productContent },
                                    React.createElement(CardProduct, { item: item, priceUsd: priceUsd }),
                                    React.createElement(ProductLayer, { onDelete: this.handleOnShowDelete(item.id), onEdit: this.handleOnShowForm(item) }))))), filteredProductsArr)),
                React.createElement(Modal, { showModal: Boolean(deleteId), onClose: this.handleOnCloseDelete },
                    React.createElement("div", { className: styles.deleteWrapper },
                        React.createElement("div", { className: styles.deleteContent },
                            React.createElement("div", { className: styles.title }, t.deleteYourProduct),
                            React.createElement("div", { className: styles.description }, t.areYouSure),
                            React.createElement("div", { className: styles.buttonsContainer },
                                React.createElement(Button, { onClick: this.handleOnCloseDelete, dataTest: "wizardDeleteProductCancelButton", wireframe: true, big: true },
                                    React.createElement("span", null, t.cancel)),
                                React.createElement("div", { className: styles.deleteButton },
                                    React.createElement(Button, { onClick: this.handleOnDelete(deleteId), dataTest: "wizardDeleteProductButton", big: true, pink: true },
                                        React.createElement("span", null, t.yesDeletePlease))))))))));
    }
}
export default ThirdStepView;
