import React, { PureComponent } from 'react';
import { join, length, take } from 'ramda';
import { Icon } from '../../../../../../../components/Icon';
import { classNames, formatPrice } from '../../../../../../../utils';
import { convertCountriesToArrLabels } from '../utils';
import styles from './CompanyItem.module.scss';
class CompanyItem extends PureComponent {
    render() {
        const { company, onRemoveCompany, onSetEditableItem, currency } = this.props;
        const countriesLabels = convertCountriesToArrLabels(company.countries);
        const countriesText = join(', ', take(10, countriesLabels));
        const countriesLength = length(countriesLabels);
        const imgAlt = company.service ? company.service.label : '';
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.logo },
                React.createElement("img", { src: company.logo, alt: imgAlt })),
            React.createElement("div", { className: styles.rest },
                React.createElement("div", { className: styles.info },
                    React.createElement("div", { className: classNames(styles, 'td', 'tdNamePrice') },
                        React.createElement("div", { className: styles.name }, imgAlt),
                        React.createElement("div", { className: styles.price },
                            React.createElement("div", { className: styles.amount }, formatPrice(company.price)),
                            React.createElement("div", { className: styles.currency }, currency.label))),
                    React.createElement("div", { className: classNames(styles, 'td', 'tdCountry') },
                        React.createElement("span", { className: styles.text },
                            countriesText,
                            countriesLength > 10 && (React.createElement("span", { className: styles.add }, ` +${countriesLength - 10}`))))),
                React.createElement("div", { className: styles.controller },
                    React.createElement("button", { className: styles.editButton, onClick: () => {
                            onSetEditableItem(company);
                        } },
                        React.createElement(Icon, { type: "note", size: 32 })),
                    React.createElement("div", { className: styles.deleteButton },
                        React.createElement("span", { onClick: () => {
                                onRemoveCompany(company);
                            }, onKeyDown: () => {
                            }, role: "button", tabIndex: 0 },
                            React.createElement(Icon, { type: "basket", inline: true, size: 32 })))))));
    }
}
export default CompanyItem;
