import React, { PureComponent } from 'react';
import punycode from 'punycode';
import { classNames, info } from '../../../utils';
import { PasswordHints } from '../../PasswordHints';
import { CapsLockMessage } from '../../CapsLockMessage';
import { Icon } from '../../Icon';
import styles from './Input.module.scss';
import { validateField, isCapsLock } from '../utils';
import t from './i18n';
class Input extends PureComponent {
    static defaultProps = {
        onChange: () => {
        },
        onResendEmail: () => {
        },
        label: '',
        placeholder: '',
        type: 'text',
        validate: 'text',
        autocomplete: false,
        errorMessage: '',
        className: 'root',
        focus: false,
        detectCapsLock: false,
        isFocus: false,
        isFocusShow: false,
        noPasswordHints: false,
        showResendEmail: false,
        thisFocus: false
    };
    constructor(props) {
        super(props);
        this.state = {
            labelFloat: '',
            showPassword: false,
            showPasswordButton: false,
            showHints: false,
            formError: '',
            passwordQuality: {
                lowerCase: false,
                upperCase: false,
                digit: false,
                length: false
            },
            isCapsLockOn: false,
            validity: false,
            isFocus: false,
            isFocusShow: false
        };
    }
    componentDidMount() {
        if (this.input && this.props.thisFocus) {
            this.input.focus();
        }
    }
    onMouseDown = () => this.setState({ isFocusShow: true });
    onMouseUp = () => this.setState({ isFocusShow: false });
    onMouseOut = () => this.setState({ isFocusShow: false });
    /**
     * @desc Handles the onChange event by setting the model's value
     * @param {SyntheticEvent} evt
     * @return {void}
     */
    handleChange = (evt) => {
        const { name } = evt.target;
        let { value } = evt.target;
        if (name === 'email') {
            value = punycode.toUnicode(value)
                .replace(/[^\w-/@/.]/g, '');
        }
        this.validate(name, value);
        this.setState({ showPasswordButton: name === 'password' && value.length > 0 });
    };
    /**
     * @desc Handles the onKeyPress event
     * @param {KeyboardEvent} evt
     * @return {void}
     */
    handleKeyPress = (evt) => {
        if (this.props.detectCapsLock) {
            this.setState({ isCapsLockOn: isCapsLock(evt) });
            info('isCapsLock(evt)', isCapsLock(evt));
        }
    };
    /**
     * @desc make the label floats if the model isn't empty
     * and toggles 'showPasswordButton'
     * @return {void}
     */
    handleFocus = (evt) => {
        const { name, value } = evt.target;
        const { model } = this.props;
        const { validity } = this.state;
        this.validate(name, value);
        if (model === '') {
            this.setState({ labelFloat: 'labelFloat' });
        }
        if (name === 'password' && !validity) {
            this.setState({ showHints: true });
        }
        this.setState({ isFocus: true });
    };
    /**
     * @desc Puts back the label to its original position if the model is empty
     * @return {void}
     */
    handleBlur = () => {
        const { model, name } = this.props;
        if (model === '') {
            this.setState({ labelFloat: '' });
        }
        if (name === 'password') {
            this.setState((prevState) => ({ showHints: prevState.showHints ? prevState.isFocusShow : false }));
        }
        this.setState({ isFocus: false });
    };
    /**
     * @param {String} inputName - input's name
     * @param {string} inputValue - input's value
     * @return {void}
     */
    validate = (inputName, inputValue) => {
        const { validate, errorMessage, onChange } = this.props;
        const { name, value, validity, formError, passwordQuality } = validateField(inputName, inputValue, validate, errorMessage);
        this.setState({
            formError,
            passwordQuality,
            validity,
            showHints: inputName === 'password' && !validity
        }, () => onChange({ name, value, validity }));
    };
    handleShowPassword = () => {
        if (this.input) {
            this.input.focus();
        }
        this.setState((preState) => ({ showPassword: preState.showPassword }));
    };
    input = {};
    errorClass = (error) => (error.length === 0 ? '' : 'invalidInput');
    render() {
        const { autocomplete, label, model, name, placeholder, type, detectCapsLock, errors, showResendEmail, onResendEmail, noPasswordHints } = this.props;
        const { labelFloat, showPassword, showPasswordButton, showHints, formError, passwordQuality, isCapsLockOn, validity, isFocus, isFocusShow } = this.state;
        return (React.createElement("span", null,
            React.createElement("div", { className: classNames(styles, 'input', {
                    isFocus: isFocus || isFocusShow,
                    isError: (errors || formError) && !isFocus && !isFocusShow,
                    validity
                }) },
                label && (React.createElement("label", { className: classNames(styles, 'label', `${labelFloat
                        || (model && model.length > 0 && 'labelFloat')
                        || ''}`), htmlFor: name }, label)),
                React.createElement("input", { type: showPassword ? 'text' : type, name: name, value: model, ref: (node) => {
                        this.input = node;
                    }, autoComplete: autocomplete ? 'on' : 'off', onFocus: this.handleFocus, onBlur: this.handleBlur, onChange: this.handleChange, onKeyPress: this.handleKeyPress, placeholder: placeholder, "data-test": name }),
                React.createElement("hr", null)),
            formError
                && !isFocus
                && !isFocusShow && React.createElement("span", { className: styles.message }, formError),
            detectCapsLock && isCapsLockOn && React.createElement(CapsLockMessage, null),
            showPasswordButton && (React.createElement("div", { className: classNames(styles, 'icon', { openIcon: showPassword }), onClick: this.handleShowPassword, onMouseDown: this.onMouseDown, onMouseUp: this.onMouseUp, onMouseOut: this.onMouseOut, onBlur: () => {
                }, onKeyDown: () => {
                }, role: "button", tabIndex: 0 },
                React.createElement(Icon, { type: "eye", size: 28 }))),
            !noPasswordHints
                && showHints && React.createElement(PasswordHints, { ...passwordQuality }),
            errors
                && errors.length > 0 && (React.createElement("div", { className: styles.errors },
                errors.map((item, idx) => (React.createElement("div", { key: idx }, item))),
                showResendEmail ? (React.createElement("span", { tabIndex: -1, onKeyPress: () => {
                    }, className: styles.resendEmail, role: "button", onClick: onResendEmail }, t.resendVerificationEmail)) : null))));
    }
}
export default Input;
