import { t } from '../../../../../translation/utils';
const translations = {
    en: {
        name: 'Name',
        category: 'Category',
        price: 'Price',
        cashback: 'Cashback',
        characteristics: 'Characteristics'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
