import { t } from '../../../translation/utils';
const translations = {
    en: {
        goods: 'Goods',
        market_sales: 'Sales',
        market_popular: 'Popular',
        //
        marketplace: 'Marketplace',
        marketplace_termsOfUse: 'Terms of use',
        marketplace_privacyPolicy: 'Privacy Policy',
        //
        services: 'Services',
        services_sellingGuides: 'Selling Guides',
        services_blog: 'Graame Blog',
        services_helpCenter: 'Help center',
        //
        copyRight: '© Graame Marketplace.',
        allRightsReserved: 'All rights reserved.',
        startSelling: 'Start Selling',
        offscreenSections: 'Graame Sections',
        wannaSellYourGoodsGlobally: 'Wanna sell your goods in your city/locality and beyond?',
        graameIsAGlobalMarketPlace: 'Graame is a local marketplace for any kind of legal goods'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
