/**
 * @generated SignedSource<<b1cef21a884fcc34ecf08a15488abcc2>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "id"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "id"
                }
            ],
            "concreteType": "BaseProduct",
            "kind": "LinkedField",
            "name": "sendBaseProductToModeration",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rawId",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SendProductToModerationMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SendProductToModerationMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "1c82fd69631ffdb4ea37757fed60885f",
            "id": null,
            "metadata": {},
            "name": "SendProductToModerationMutation",
            "operationKind": "mutation",
            "text": "mutation SendProductToModerationMutation(\n  $id: Int!\n) {\n  sendBaseProductToModeration(id: $id) {\n    id\n    rawId\n    status\n  }\n}\n"
        }
    };
})();
node.hash = "5e36c25a92facd8fe6a10480888cabca";
export default node;
