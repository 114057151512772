import { t } from '../../../../translation/utils';
const translations = {
    en: {
        buyNow: 'Buy now',
        preOrder: 'Pre-order',
        addToCart: 'Add to cart',
        viewCart: 'View cart',
        youMustSelectAnAttribute: 'You must select an attribute(s): '
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
