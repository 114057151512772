import React from 'react';
import { Input } from '../../common/Input';
import styles from './AddressResultForm.module.scss';
import t from './i18n';
import { classNames } from '../../../utils';
const AddressResultForm = ({ address, onChangeForm }) => (React.createElement("div", null,
    React.createElement("div", { className: styles.row },
        React.createElement("div", { className: classNames(styles, 'itemWrapper', 'odd') },
            React.createElement(Input, { fullWidth: true, id: "streetNumber", label: t.labelAptSuiteOther, onChange: onChangeForm('streetNumber'), value: address ? address.streetNumber : '', limit: 50 })),
        React.createElement("div", { className: styles.itemWrapper },
            React.createElement(Input, { fullWidth: true, id: "route", label: t.labelStreetAddress, onChange: onChangeForm('route'), value: address ? address.route : '', limit: 50 }))),
    React.createElement("div", { className: styles.row },
        React.createElement("div", { className: classNames(styles, 'itemWrapper', 'odd') },
            React.createElement(Input, { fullWidth: true, id: "locality", label: t.labelLocality, onChange: onChangeForm('locality'), value: address ? address.locality : '', limit: 50 })),
        React.createElement("div", { className: styles.itemWrapper },
            React.createElement(Input, { fullWidth: true, id: "administrativeAreaLevel2", label: t.labelRegionState, onChange: onChangeForm('administrativeAreaLevel2'), value: address ? address.administrativeAreaLevel2 : '', limit: 50 }))),
    React.createElement("div", { className: styles.row },
        React.createElement("div", { className: classNames(styles, 'itemWrapper', 'odd') },
            React.createElement(Input, { fullWidth: true, id: "administrativeAreaLevel1", label: t.labelAreaDistrict, onChange: onChangeForm('administrativeAreaLevel1'), value: address ? address.administrativeAreaLevel1 : '', limit: 50 })),
        React.createElement("div", { className: styles.itemWrapper },
            React.createElement(Input, { fullWidth: true, id: "postalCode", label: t.labelPostalCode, onChange: onChangeForm('postalCode'), value: address ? address.postalCode : '', limit: 50 })))));
export default AddressResultForm;
