import { filter, find, flatten, head, includes, join, map, propEq } from 'ramda';
export const convertLocalAvailablePackages = (localAvailablePackages) => {
    const newPackages = map((item) => ({
        id: item.companyPackageId,
        label: item.name,
        currency: {
            id: item.currency,
            label: item.currency
        }
    }), localAvailablePackages);
    return newPackages;
};
export const convertInterAvailablePackages = (interAvailablePackages) => map((item) => {
    const { deliveriesTo } = item;
    return {
        id: item.companyPackageId,
        label: item.name,
        countries: deliveriesTo ? head(deliveriesTo) : null,
        currency: {
            id: item.currency,
            label: item.currency
        }
    };
}, interAvailablePackages);
export const getServiceLogo = (params) => {
    const { id, packages } = params;
    if (id == null) {
        return '';
    }
    const foundPackage = find(propEq(id, 'companyPackageId'))(packages);
    return foundPackage ? foundPackage.logo : '';
};
export const getServiceRawId = (params) => {
    const { id, packages } = params;
    if (id == null) {
        return -1;
    }
    const foundPackage = find(propEq(id, 'companyPackageId'))(packages);
    return foundPackage ? foundPackage.companyPackageRawId : -1;
};
export const getService = (params) => {
    const foundPackage = find(propEq(params.id, 'companyPackageId'))(params.packages);
    return (foundPackage ? {
        id: foundPackage.companyPackageId,
        label: foundPackage.name,
        currency: {
            id: foundPackage.currency,
            label: foundPackage.currency
        }
    }
        : null);
};
export const getCountries = (params) => {
    const foundPackage = find(propEq(params.id, 'companyPackageId'))(params.packages);
    return foundPackage && foundPackage.deliveriesTo
        ? head(foundPackage.deliveriesTo)
        : null;
};
export const convertCountriesForSelect = (params) => {
    const { countries, isSelected, checkedCountries } = params;
    if (countries) {
        let isSelectedAll = true;
        const newChildren = map((continent) => {
            let isSelectedContinent = false;
            const children = map((country) => {
                const isSelectedCountry = checkedCountries
                    ? includes(country.alpha3, checkedCountries)
                    : false;
                if (isSelectedCountry) {
                    isSelectedContinent = true;
                }
                else {
                    isSelectedAll = false;
                }
                return {
                    ...country,
                    isSelected: isSelected !== undefined ? isSelected : isSelectedCountry
                };
            }, continent.children);
            return {
                ...continent,
                children,
                isSelected: isSelected !== undefined ? isSelected : isSelectedContinent
            };
        }, countries.children);
        return {
            ...countries,
            children: newChildren,
            isSelected: isSelected !== undefined ? isSelected : isSelectedAll
        };
    }
    return null;
};
export const convertCountriesToArrCodes = (params) => {
    const { countries, isSelectedAll } = params;
    if (!countries) {
        return [];
    }
    const newCountries = flatten(map((item) => map((child) => child.alpha3, filter((child) => child.isSelected === true || Boolean(isSelectedAll), item.children)), countries.children));
    return newCountries;
};
export const convertCountriesToStringLabels = (countries) => {
    if (!countries) {
        return '';
    }
    const newCountries = flatten(map((item) => map((child) => child.label, filter((child) => Boolean(child.isSelected), item.children)), countries.children));
    return join(', ', newCountries);
};
export const convertCountriesToArrLabels = (countries) => {
    if (!countries) {
        return [];
    }
    const newCountries = flatten(map((item) => map((child) => child.label, filter((child) => Boolean(child.isSelected), item.children)), countries.children));
    return newCountries;
};
