import React, { Component } from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { SpinnerCircle } from '../../../components/common';
import styles from './StripeDecorator.module.scss';
export default (OriginalComponent) => class Stripe extends Component {
    constructor(props) {
        super(props);
        this.state = { stripe: null };
    }
    componentDidMount() {
        if (process.env.BROWSER) {
            const stripeJs = document.createElement('script');
            stripeJs.src = 'https://js.stripe.com/v3/';
            stripeJs.async = true;
            stripeJs.onload = () => {
                this.initStripeByState();
            };
            document.body && document.body.appendChild(stripeJs);
        }
    }
    initStripeByState = () => {
        this.setState({ stripe: window.Stripe(process.env.REACT_APP_STRIPE_API_KEY, { betas: ['payment_intent_beta_3'] }) });
    };
    render() {
        const { stripe } = this.state;
        if (!stripe) {
            return (React.createElement("div", { className: styles.loader },
                React.createElement(SpinnerCircle, { forPaid: true })));
        }
        return (React.createElement(StripeProvider, { stripe: this.state.stripe },
            React.createElement("div", null,
                React.createElement(Elements, { fonts: [
                        { cssSrc: 'https://fonts.googleapis.com/css?family=Roboto' }
                    ] },
                    React.createElement(OriginalComponent, { ...this.props })))));
    }
};
