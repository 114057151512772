import React, { PureComponent } from 'react';
import styles from './Checkbox.module.scss';
import { classNames } from '../../utils';
class Checkbox extends PureComponent {
    onChange = () => {
        const { id, onChange } = this.props;
        onChange(id);
    };
    static defaultProps = {
        radioStyle: false
    };
    render() {
        const { id, label, isChecked, radioStyle } = this.props;
        return (React.createElement("div", { className: classNames(styles, 'container', { radioStyle }) },
            React.createElement("input", { className: styles.input, id: id, type: "checkbox", checked: isChecked, onChange: this.onChange, "data-test": id }),
            React.createElement("label", { htmlFor: id, className: styles.label },
                React.createElement("span", { className: styles.labelText }, label))));
    }
}
export default Checkbox;
