import { graphql } from 'react-relay';
import { basicMutation } from '../../../../../relay/mutations/basicMutation';
const mutation = graphql `
	mutation SendStoreToModerationByUserMutation($id: Int!) {
		sendStoreToModeration(id: $id) {
			id
			status
		}
	}
`;
const sendStoreToModerationByUserMutation = basicMutation(mutation, 'sendStoreToModeration');
export default sendStoreToModerationByUserMutation;
