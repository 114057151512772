/**
 * @generated SignedSource<<84f91f8b1572fed41d977ff69c915d13>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "deactivateProduct",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "DeactivateProductMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "DeactivateProductMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "dd41dc051f37eab5d31475d4eb04c479",
            "id": null,
            "metadata": {},
            "name": "DeactivateProductMutation",
            "operationKind": "mutation",
            "text": "mutation DeactivateProductMutation(\n  $input: DeactivateProductInput!\n) {\n  deactivateProduct(input: $input) {\n    id\n  }\n}\n"
        }
    };
})();
node.hash = "4cae1f5bb0a733f2acb10644936f0811";
export default node;
