import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { map } from 'ramda';
import Alert from './Alert';
const alertsRootDiv = process.env.BROWSER
    ? document.getElementById('alerts-root')
    : undefined;
class AlertsContainer extends PureComponent {
    renderAlerts = map((alertProps) => (React.createElement(Alert, { key: alertProps.createdAtTimestamp, ...alertProps })));
    render() {
        return alertsRootDiv
            ? ReactDOM.createPortal(this.renderAlerts(this.props.alerts), alertsRootDiv)
            : undefined;
    }
}
export default AlertsContainer;
