import React, { Component } from 'react';
import { omit, propOr } from 'ramda';
import { Button } from '../../../../../../components/common/Button';
import { Input } from '../../../../../../components/common/Input';
import { AddressForm } from '../../../../../../components/AddressAutocomplete';
import styles from './Form.module.scss';
import t from './i18n';
import { classNames } from '../../../../../../utils';
class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: props.name || '',
            addressFull: props.addressFull,
            formErrors: props.formErrors
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const currentFormErrors = this.state.formErrors;
        const nextFormErrors = nextProps.formErrors;
        if (currentFormErrors !== nextFormErrors) {
            this.setState({ formErrors: nextFormErrors });
        }
    }
    handleInputChange = (e) => {
        this.setState((preState) => ({ formErrors: omit(['name'], preState.formErrors) }));
        const { value } = e.target;
        if (value.length > 50) {
            return;
        }
        this.setState({ name: value });
    };
    handleUpdateAddressFull = (addressFull) => {
        this.setState((preState) => ({
            addressFull: {
                ...preState.addressFull,
                ...addressFull
            }
        }));
    };
    render() {
        const { isLoading, handleSave, handleCancel } = this.props;
        const { name, addressFull, formErrors } = this.state;
        return (React.createElement("div", { className: styles.form },
            React.createElement("div", { className: classNames(styles, 'formItem', 'storageName') },
                React.createElement(Input, { id: "name", value: name || '', label: t.labelStorageName, onChange: this.handleInputChange, errors: propOr(null, 'name', formErrors), limit: 50, fullWidth: true })),
            React.createElement("div", { className: styles.formItem },
                React.createElement("div", { className: styles.subtitle }, t.storageAddress),
                React.createElement("div", { className: styles.address },
                    React.createElement(AddressForm, { isOpen: true, onChangeData: this.handleUpdateAddressFull, country: addressFull.country, address: addressFull.value, addressFull: addressFull }))),
            React.createElement("div", { className: styles.formItem },
                React.createElement("div", { className: styles.buttons },
                    React.createElement(Button, { big: true, onClick: () => {
                            handleSave({
                                name,
                                addressFull
                            });
                        }, isLoading: isLoading, dataTest: "saveStorageButton" }, t.save),
                    React.createElement("button", { className: styles.cancelButton, onClick: handleCancel, "data-test": "cancelSaveStorageButton" }, t.cancel)))));
    }
}
export default Form;
