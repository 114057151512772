import React, { Component } from 'react';
import { isEmpty, map } from 'ramda';
import { info, convertSrc, formatPrice } from '../../../../../../utils';
import ImageLoader from '../../../../../../libs/react-image-loader';
import { Col } from '../../../../../../layout';
import { Input } from '../../../../../../components/common/Input';
import { Button } from '../../../../../../components/common/Button';
// import { Checkbox } from '../../../../../../components/common/Checkbox';
import { Icon } from '../../../../../../components/Icon';
import BannerLoading from '../../../../../../components/Banner/BannerLoading';
import styles from './StorageProductsTableRow.module.scss';
class StorageProductsTableRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            storageFocusId: null,
            storageFocusCurrentValue: null,
            storageFocusValue: ''
        };
    }
    handleCheckboxClick = (id) => {
        info('id', id);
    };
    handleFocus = (e, quantity) => {
        const { id, value } = e.target;
        this.setState({
            storageFocusId: id,
            storageFocusCurrentValue: `${quantity}`,
            storageFocusValue: value
        });
    };
    handleBlur = () => {
        const { storageFocusCurrentValue, storageFocusValue } = this.state;
        if (storageFocusValue === storageFocusCurrentValue) {
            this.setState({ storageFocusId: null });
        }
    };
    handleChange = (e) => {
        const { value } = e.target;
        if (parseInt(value, 10) >= 0 && value !== '') {
            this.setState({ storageFocusValue: value.replace(/^0+/, '') });
        }
        if (value === '' || /^0+$/.test(value)) {
            this.setState({ storageFocusValue: '0' });
        }
    };
    handleSave = (productId) => {
        const { onSave } = this.props;
        const { storageFocusValue } = this.state;
        onSave(productId, storageFocusValue);
        this.setState({ storageFocusId: null });
    };
    render() {
        const { item } = this.props;
        const { storageFocusId, storageFocusValue } = this.state;
        const thisProduct = `${item.productId}` === storageFocusId;
        return (React.createElement("div", { className: styles.container },
            React.createElement(Col, { size: 6, sm: 6, md: 2, lg: 2, xl: 1 },
                React.createElement("div", { className: styles.foto }, !item || !item.photoMain ? (React.createElement(Icon, { type: "camera", size: 40 })) : (React.createElement(ImageLoader, { fit: true, src: convertSrc('small', item.photoMain), loader: React.createElement(BannerLoading, null) })))),
            React.createElement(Col, { size: 6, sm: 6, md: 4, lg: 3, xl: 2 },
                React.createElement("div", { className: styles.name },
                    React.createElement("span", null, item.name))),
            React.createElement(Col, { size: 2, sm: 2, md: 2, lg: 2, xl: 2, xlVisible: true },
                React.createElement("div", null,
                    React.createElement("span", null, item.categoryName))),
            React.createElement(Col, { size: 3, sm: 3, md: 3, lg: 3, xl: 2, mdVisible: true }, item
                && item.price && (React.createElement("span", null, `${formatPrice(item.price)} ${item.currency}`))),
            React.createElement(Col, { size: 2, sm: 2, md: 2, lg: 2, xl: 3, xlVisible: true }, !isEmpty(item.attributes) ? (React.createElement("div", null,
                React.createElement("div", { className: styles.characteristicItem },
                    React.createElement("div", { className: styles.characteristicLabels }, map((attributeItem) => (React.createElement("div", { key: attributeItem.attrId }, `${attributeItem.attributeName}: `)), item.attributes)),
                    React.createElement("div", { className: styles.characteristicValues }, map((attributeItem) => (React.createElement("div", { key: attributeItem.attrId }, attributeItem.value)), item.attributes))))) : ('No Characteristics')),
            React.createElement(Col, { size: 3, sm: 3, md: 3, lg: 3, xl: 3, lgVisible: true },
                React.createElement("div", { className: styles.quantity },
                    React.createElement(Input, { id: `${item.productId}`, type: "number", inline: true, fullWidth: true, value: thisProduct ? storageFocusValue : `${item.quantity}`, onFocus: (e) => {
                            this.handleFocus(e, item.quantity);
                        }, onBlur: this.handleBlur, onChange: this.handleChange, dataTest: "storagesVariantQuantitiInput" }),
                    thisProduct && (React.createElement(Button, { small: true, disabled: thisProduct && storageFocusValue === `${item.quantity}`, onClick: () => {
                            this.handleSave(item.productId);
                        }, dataTest: "saveQuantityButton" }, "Save"))))));
    }
}
export default StorageProductsTableRow;
