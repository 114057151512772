import React, { PureComponent } from 'react';
import { addIndex, head, isEmpty, map, pathOr } from 'ramda';
import { withRouter } from 'found';
import { createPaginationContainer, graphql } from 'react-relay';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { Environment } from 'relay-runtime';
import { ManageStore } from '../index';
import { Page } from '../../../../components/App';
import { debug, error, fromRelayError, getNameText, info } from '../../../../utils';
import { withShowAlert } from '../../../../components/Alerts/AlertContext';
import { Button } from '../../../../components/common/Button';
import { Confirmation } from '../../../../components/Confirmation';
import { DeactivateBaseProductMutation } from '../../../../relay/mutations';
import styles from './Products.module.scss';
import t from './i18n';
import ProductsHeader from './ProductsHeader';
import ProductsTableHeader from './ProductsTableHeader';
import ProductsTableRow from './ProductsTableRow';
import { withErrorBoundary } from '../../../../components/common/ErrorBoundaries';
class Products extends PureComponent {
    static contextTypes = {
        environment: PropTypes.instanceOf(Environment).isRequired,
        showAlert: PropTypes.func
    };
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            dataToDelete: undefined
        };
    }
    addProduct = () => {
        const storeId = pathOr(null, ['match', 'params', 'storeId'], this.props);
        if (storeId) {
            this.props.router.push(`/manage/store/${storeId}/product/new`);
        }
    };
    editProduct = (id) => {
        const storeId = pathOr(null, ['match', 'params', 'storeId'], this.props);
        if (storeId) {
            this.props.router.push(`/manage/store/${storeId}/products/${parseInt(String(id), 10)}`);
        }
    };
    handleCheckboxClick = (id) => {
        info('id', id);
    };
    deleteProduct = (id) => {
        const storeId = pathOr(null, ['me', 'myStore', 'id'], this.props);
        DeactivateBaseProductMutation.commit({
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    id
                }
            },
            parentID: storeId,
            environment: this.context.environment,
            onCompleted: (response, errors) => {
                debug({
                    response,
                    errors
                });
                const relayErrors = fromRelayError({ source: { errors } });
                debug({ relayErrors });
                const statusError = pathOr({}, ['100', 'status'], relayErrors);
                if (!isEmpty(statusError)) {
                    this.props.showAlert({
                        type: 'danger',
                        text: `${t.error} "${statusError}"`,
                        link: { text: t.close }
                    });
                    return;
                }
                const parsingError = pathOr(null, ['300', 'message'], relayErrors);
                if (parsingError) {
                    debug('parsingError:', { parsingError });
                    this.props.showAlert({
                        type: 'danger',
                        text: t.somethingGoingWrong,
                        link: { text: t.close }
                    });
                    return;
                }
                this.handleCloseModal();
                this.props.showAlert({
                    type: 'success',
                    text: t.deleted,
                    link: { text: '' }
                });
            },
            onError: (err) => {
                error(err);
                this.props.showAlert({
                    type: 'danger',
                    text: t.somethingGoingWrong,
                    link: { text: t.close }
                });
            }
        });
    };
    handleDelete = () => {
        const { dataToDelete } = this.state;
        this.deleteProduct(dataToDelete);
    };
    handleDeleteModal = (id, e) => {
        e.stopPropagation();
        this.setState({
            showModal: true,
            dataToDelete: id
        });
    };
    handleCloseModal = () => {
        this.setState({
            showModal: false,
            dataToDelete: null
        });
    };
    productsRefetch = () => {
        this.props.relay.loadMore(8);
    };
    render() {
        const { me } = this.props;
        const { showModal } = this.state;
        const baseProducts = pathOr([], ['myStore', 'baseProducts', 'edges'], me);
        const products = map((item) => {
            const { node } = item;
            const newItem = {
                ...item.node,
                categoryName: getNameText(pathOr(null, ['category', 'name'], node), 'EN'),
                name: getNameText(pathOr(null, ['name'], node), 'EN'),
                product: head(pathOr([], ['products', 'edges'], node))
                    ? head(node.products.edges).node
                    : null
            };
            return newItem;
        }, baseProducts);
        const mapIndexed = addIndex(map);
        return (React.createElement("div", { className: styles.container },
            React.createElement(ProductsHeader, { onAdd: this.addProduct }),
            React.createElement(ProductsTableHeader, null),
            React.createElement(Confirmation, { showModal: showModal, onClose: this.handleCloseModal, title: t.deleteYourProduct, description: t.confirmationDescription, onCancel: this.handleCloseModal, onConfirm: this.handleDelete, confirmText: t.confirmText, cancelText: t.cancelText }),
            isEmpty(products) ? (React.createElement("div", { className: styles.emptyProductsBlock }, t.noProducts)) : (mapIndexed((item, index) => (React.createElement(ProductsTableRow, { key: item.rawId, item: item, onEdit: this.editProduct, onDelete: this.handleDeleteModal, onCheckbox: this.handleCheckboxClick, index: String(index += 1) })), products)),
            this.props.relay.hasMore() && (React.createElement("div", { className: styles.loadButton },
                React.createElement(Button, { big: true, load: true, onClick: this.productsRefetch, dataTest: "storeProductsLoadMoreButton", wireframe: true }, t.loadMore)))));
    }
}
export default createPaginationContainer(withErrorBoundary(withShowAlert(withRouter(Page(ManageStore({
    OriginalComponent: Products,
    active: 'goods',
    title: 'Goods'
}))))), {
    me: graphql `
			fragment Products_me on User
			@argumentDefinitions(
				first: { type: "Int", defaultValue: 8 }
				after: { type: "ID", defaultValue: null }
			) {
				myStore {
					id
					logo
					name {
						text
						lang
					}
					baseProducts(first: $first, after: $after)
					@connection(key: "Wizard_baseProducts") {
						edges {
							node {
								id
								rawId
								name {
									text
									lang
								}
								shortDescription {
									lang
									text
								}
								category {
									id
									rawId
									name {
										lang
										text
									}
								}
								storeId
								currency
								products(first: 1) {
									edges {
										node {
											id
											rawId
											price
											discount
											photoMain
											additionalPhotos
											vendorCode
											cashback
											price
											attributes {
												value
												metaField
												attribute {
													id
													rawId
													name {
														lang
														text
													}
													metaField {
														values
														translatedValues {
															translations {
																text
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
						pageInfo {
							endCursor
						}
					}
				}
			}
    `
}, {
    direction: 'forward',
    getConnectionFromProps: (props) => props.me
        && props.me.myStore
        && props.me.myStore.baseProducts
        && props.me.myStore.baseProducts,
    getVariables: (props) => ({
        first: 8,
        after: props.me.myStore.baseProducts.pageInfo.endCursor
    }),
    query: graphql `
			query Products_Query($first: Int, $after: ID) {
				me {
					...Products_me @arguments(first: $first, after: $after)
				}
			}
    `
});
