import { commitMutation, graphql } from 'react-relay';
const mutation = graphql `
	mutation UpdateUserMutation($input: UpdateUserInput!) {
		updateUser(input: $input) {
			id
			email
			phone
			firstName
			lastName
			middleName
			birthdate
			gender
			isActive
			avatar
		}
	}
`;
const commit = (params) => commitMutation(params.environment, {
    mutation,
    variables: params.variables,
    onCompleted: params.onCompleted,
    onError: params.onError,
    updater: (relayStore) => {
        const updateUser = relayStore.getRootField('updateUser');
        const avatar = updateUser.getValue('avatar');
        const me = relayStore.getRoot()
            .getLinkedRecord('me');
        me.setValue(avatar, 'avatar');
    }
});
export default { commit };
/*
 <example of response here>
 */
