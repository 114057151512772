import React, { PureComponent } from 'react';
import { isNil } from 'ramda';
import { Icon } from '../Icon';
import styles from './MobileUpload.module.scss';
import t from './i18n';
class MobileUpload extends PureComponent {
    static defaultProps = {
        disabled: true,
        dataTest: 'dataMobileUpload'
    };
    render() {
        const { dataTest, disabled, id, onUpload, onDelete, img, iconType } = this.props;
        return (React.createElement("div", { className: styles.container },
            isNil(img) ? (React.createElement("span", { className: styles.imageArea },
                React.createElement(Icon, { type: iconType, size: 28 }))) : (React.createElement("figure", { className: styles.image },
                React.createElement("span", { onClick: onDelete, onKeyPress: () => {
                    }, role: "button", className: styles.trash, tabIndex: -1 },
                    React.createElement(Icon, { type: "basket", size: 24 })),
                React.createElement("img", { src: img, alt: "" }))),
            React.createElement("label", { htmlFor: id, className: styles.upload, "data-test": dataTest }, t.uploadPhoto),
            disabled && (React.createElement("input", { style: { display: 'none' }, id: id, type: "file", onChange: onUpload }))));
    }
}
export default MobileUpload;
