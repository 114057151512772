import React, { PureComponent } from 'react';
import { isNil } from 'ramda';
import { Icon } from '../../Icon';
import styles from './SliderHeader.module.scss';
import t from './i18n';
class SliderHeader extends PureComponent {
    render() {
        const { title, isRevealButton, handleSlide, seeAllUrl } = this.props;
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.title }, title),
            isRevealButton && (React.createElement("div", { className: styles.nav },
                React.createElement("button", { id: "prev", className: styles.button, onClick: () => handleSlide('prev') },
                    React.createElement(Icon, { type: "prev", size: 32 })),
                React.createElement("button", { id: "next", className: styles.button, onClick: () => handleSlide('next') },
                    React.createElement(Icon, { type: "next", size: 32 })))),
            !isNil(seeAllUrl) && (React.createElement("a", { className: styles.reveal, href: seeAllUrl, "data-test": "seeAllLink" }, t.seeAll)),
            false && React.createElement("a", { className: styles.settings }, t.recommendationSettings)));
    }
}
export default SliderHeader;
