import { t } from '../../../translation/utils';
const translations = {
    en: {
        labelFirstName: 'First Name',
        labelLastName: 'Last Name',
        labelEmail: 'Email',
        labelPassword: 'Password',
        signUp: 'Sign Up'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
