import { pathOr } from 'ramda';
import jwtDecode from 'jwt-decode';
/*
 token: jwt-string from cookies
 liveTime: amount of seconds since creation when token is actual
 (24 hours by default)
 */
const isTokenExpired = (token, liveTime = 24 * 60 * 60) => {
    if (!token) {
        return true;
    }
    let decodedJWT;
    let expiredAt = 0;
    try {
        decodedJWT = jwtDecode(token);
        expiredAt = pathOr(null, ['exp'], decodedJWT) || 0;
        if (!expiredAt) {
            return true;
        }
    }
    catch (e) {
        //
    }
    const currentTimestamp = parseInt(String(Date.now() / 1000), 10);
    return currentTimestamp - expiredAt > liveTime;
};
export default isTokenExpired;
