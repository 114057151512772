import { t } from '../../../../../../translation/utils';
const translations = {
    en: {
        productPhotos: 'PRODUCT PHOTOS',
        generalSettings: 'GENERAL SETTINGS',
        SKU: 'SKU',
        pricing: 'Pricing',
        price: 'Price',
        cashback: 'Cashback',
        percent: 'Percent',
        discount: 'Discount',
        characteristics: 'Characteristics',
        save: 'Save',
        cancel: 'Cancel'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
