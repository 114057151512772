import React, { Component } from 'react';
import { createPaginationContainer, graphql } from 'react-relay';
import { map, pathOr } from 'ramda';
import { withErrorBoundary } from '../../components/common/ErrorBoundaries';
import { Button } from '../../components/common/Button';
import { Col, Container, Row } from '../../layout';
import { MobileSidebar } from '../../components/MobileSidebar';
import { Page } from '../../components/App';
import { fromQueryString, fromSearchFilters } from './StoreUtils';
import StoresSidebar from './StoresSidebar';
import StoresHeader from './StoresHeader';
import StoresRow from './StoresRow';
import styles from './Stores.module.scss';
import t from './i18n';
import { storesData } from './StoresDataJson';
class Stores extends Component {
    static getDerivedStateFromProps(nextProps, nextState) {
        const { search, match } = nextProps;
        const categories = fromSearchFilters(search, ['category', 'children']);
        const category = fromQueryString(match, 'category')(categories, 'id');
        if (category) {
            return {
                ...nextState,
                category
            };
        }
        return null;
    }
    // TODO: I don't know what's for 'constructor' so I leave it in the meantime
    constructor(props) {
        super(props);
        if (storesData) {
            this.state = {
                category: null,
                isSidebarOpen: false
            };
        }
    }
    storesReFetch = () => {
        const { relay: { loadMore } } = this.props;
        loadMore(8);
    };
    handleSidebar = () => {
        this.setState(({ isSidebarOpen }) => ({ isSidebarOpen: !isSidebarOpen }));
    };
    render() {
        const { category, isSidebarOpen } = this.state;
        const stores = pathOr([], ['search', 'findStore', 'edges'], this.props);
        const totalCount = pathOr(0, ['search', 'findStore', 'pageInfo', 'searchFilters', 'totalCount'], this.props);
        const searchValue = pathOr(null, ['location', 'query', 'search'], this.props);
        const title = (React.createElement("span", null,
            React.createElement("b", null, totalCount),
            " ",
            t.storesFound));
        return (React.createElement("div", { className: styles.container },
            React.createElement(MobileSidebar, { isOpen: isSidebarOpen, onClose: this.handleSidebar, title: title },
                React.createElement(StoresSidebar, { search: this.props.search, onClose: this.handleSidebar })),
            React.createElement(Container, null,
                React.createElement(StoresHeader, { title: title, category: category, onFilter: this.handleSidebar, searchValue: searchValue }),
                React.createElement(Row, null,
                    React.createElement(Col, { sm: 1, md: 1, lg: 2, xl: 2, lgVisible: true },
                        React.createElement(StoresSidebar, { search: this.props.search })),
                    React.createElement(Col, { sm: 12, md: 12, lg: 10, xl: 10 },
                        stores && stores.length > 0 ? (map((storesItem) => (React.createElement(StoresRow, { store: storesItem.node, key: storesItem.node.id })), stores)) : (React.createElement("div", null, t.noStoresFound)),
                        this.props.relay.hasMore() && (React.createElement("div", { className: styles.button },
                            React.createElement(Button, { big: true, load: true, onClick: this.storesReFetch, dataTest: "searchStoresLoadMoreButton", wireframe: true }, t.loadMore))))))));
    }
}
export default createPaginationContainer(withErrorBoundary(Page(Stores)), {
    search: graphql `
			fragment Stores_search on Search
			@argumentDefinitions(
				text: { type: "SearchStoreInput!" }
				first: { type: "Int", defaultValue: 8 }
				after: { type: "ID", defaultValue: null }
			) {
				findStore(
					searchTerm: $text
					first: $first
					after: $after
					visibility: "published"
				) @connection(key: "Stores_findStore") {
					pageInfo {
						searchFilters {
							totalCount
							category {
								id
								rawId
								children {
									id
									rawId
									name {
										lang
										text
									}
								}
							}
							country
						}
					}
					edges {
						cursor
						node {
							id
							rawId
							name {
								lang
								text
							}
							logo
							cover
							slug
							shortDescription {
								lang
								text
							}
							findMostViewedProducts(first: 4, searchTerm: {}) {
								edges {
									node {
										id
										rawId
										products {
											edges {
												node {
													photoMain
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
    `
}, {
    direction: 'forward',
    getConnectionFromProps: (props) => props.search && props.search.findStore,
    getVariables: (props, { count }, prevFragmentVars) => ({
        text: prevFragmentVars.text,
        after: props.search.findStore.pageInfo.endCursor,
        first: count + 1
    }),
    query: graphql `
			query Stores_edges_Query(
				$first: Int
				$after: ID
				$text: SearchStoreInput!
			) {
				search {
					...Stores_search @arguments(first: $first, after: $after, text: $text)
				}
			}
    `
});
