import React, { Component } from 'react';
import { any, find, forEach, isEmpty, isNil, map, omit, pathOr, pick } from 'ramda';
import Autocomplete from 'react-autocomplete-2';
import debounce from 'lodash.debounce';
import { classNames, renameCamelCase } from '../../../utils';
import { Select } from '../../common/Select';
import { AutocompleteInput } from '../../common/AutocompleteInput';
import googleApiWrapper from '../GoogleAPIWrapper';
import AddressResultForm from '../AddressResultForm';
import { getCountryByName, getIndexedCountries } from '../utils';
import styles from './AddressForm.module.scss';
const defaultAddressFull = {
    value: '',
    country: '',
    countryCode: '',
    administrativeAreaLevel1: '',
    administrativeAreaLevel2: '',
    locality: '',
    political: '',
    postalCode: '',
    route: '',
    streetNumber: '',
    placeId: ''
};
class Form extends Component {
    static getDerivedStateFromProps(nextProps, prevState) {
        const country = find((item) => item.alpha2 === nextProps.country
            || item.alpha3 === nextProps.country
            || item.label === nextProps.country)(nextProps.countries);
        const propsCountry = country ? {
            id: country.alpha3,
            label: country.label
        } : null;
        const uneqCountry = JSON.stringify(propsCountry) !== JSON.stringify(prevState.country);
        const address = !isNil(nextProps.addressFull)
            && any((val) => !isNil(val))(Object.values(nextProps.addressFull))
            ? omit(['country', 'value'], nextProps.addressFull)
            : null;
        const uneqAddress = JSON.stringify(address) !== JSON.stringify(prevState.address);
        const autocompleteValue = nextProps.address;
        const uneqAutocompleteValue = autocompleteValue !== prevState.autocompleteValue;
        return {
            country: uneqCountry ? propsCountry : prevState.country,
            address: uneqAddress ? address : prevState.address,
            autocompleteValue: uneqAutocompleteValue
                ? autocompleteValue
                : prevState.autocompleteValue,
            ...prevState
        };
    }
    constructor(props) {
        super(props);
        const { addressFull, countries } = props;
        const country = find((item) => item.alpha2 === props.country
            || item.alpha3 === props.country
            || item.label === props.country)(countries);
        this.state = {
            country: country ? {
                id: country.alpha3,
                label: country.label
            } : null,
            address: {
                value: addressFull.value || '',
                country: addressFull.country || '',
                countryCode: country && country.alpha3 ? country.alpha3 : '',
                administrativeAreaLevel1: addressFull.administrativeAreaLevel1 || '',
                administrativeAreaLevel2: addressFull.administrativeAreaLevel2 || '',
                locality: addressFull.locality || '',
                political: addressFull.political || '',
                postalCode: addressFull.postalCode || '',
                route: addressFull.route || '',
                streetNumber: addressFull.streetNumber || '',
                placeId: addressFull.placeId || ''
            },
            autocompleteValue: props.address,
            predictions: []
        };
        this.handleAutocomplete = debounce(this.handleAutocomplete, 250);
    }
    handleOnReceiveAddress = (result) => {
        if (result && result.addressComponents) {
            const address = {};
            const populateAddressField = (addressComponent) => {
                const type = addressComponent.types && Array.isArray(addressComponent.types)
                    ? addressComponent.types[0]
                    : null;
                if (type) {
                    address[type] = addressComponent.long_name;
                }
            };
            forEach(populateAddressField, result.addressComponents);
            this.setState({ address: renameCamelCase(address) }, () => {
                this.handleOnChangeData();
            });
        }
    };
    handleOnSetAddress = (value, item) => {
        const { countries } = this.props;
        const { country } = this.state;
        const label = country ? country.label : '';
        const countryFromResource = getCountryByName(label, countries);
        const componentRestrictions = { country: countryFromResource ? countryFromResource.alpha2 : '' };
        this.setState({ autocompleteValue: value });
        this.props.geocoderService.geocode({
            address: `${item.mainText}, ${item.secondaryText}`,
            componentRestrictions
        }, (result) => {
            this.handleOnReceiveAddress(renameCamelCase(result[0]));
        });
    };
    handleOnChangeForm = (type) => (e) => {
        const { onChangeFormInput } = this.props;
        if (onChangeFormInput) {
            onChangeFormInput(type)(e);
        }
        const { address } = this.state;
        this.setState({
            address: {
                ...address,
                [type]: e.target.value
            }
        }, () => this.handleOnChangeData());
    };
    handleSearch = (predictions, status) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
            return;
        }
        const formattedResult = map((item) => ({
            mainText: pathOr(null, ['structured_formatting', 'main_text'], item),
            secondaryText: pathOr(null, ['structured_formatting', 'secondary_text'], item),
            ...pick(['place_id'], item)
        }), predictions);
        this.setState({ predictions: formattedResult });
    };
    handleAutocomplete = (value) => {
        const { autocompleteService, onUpdateForm, countries } = this.props;
        const { country } = this.state;
        const label = country ? country.label : '';
        const countryFromResource = getCountryByName(label, countries);
        if (onUpdateForm) {
            onUpdateForm({ value });
        }
        if (isEmpty(value)) {
            this.setState({ predictions: [] });
            return;
        }
        const inputObj = {
            input: value,
            componentRestrictions: { country: countryFromResource ? countryFromResource.alpha2 : '' },
            types: ['geocode']
        };
        autocompleteService.getPlacePredictions(inputObj, this.handleSearch);
    };
    handleOnChangeAddress = (value) => {
        this.setState(() => ({ autocompleteValue: value }), () => this.handleOnChangeData());
        this.handleAutocomplete(value);
    };
    handleOnChangeCountry = (value) => {
        const { onUpdateForm } = this.props;
        this.setState(() => ({ country: value, address: null, autocompleteValue: '' }), () => {
            if (onUpdateForm) {
                onUpdateForm({ country: value ? value.label : '' });
            }
            this.handleOnChangeAddress('');
        });
    };
    handleOnChangeData = () => {
        const { onChangeData } = this.props;
        const { address, country, autocompleteValue } = this.state;
        if (onChangeData) {
            const requiredData = {
                country: country && country.label ? country.label : '',
                countryCode: country && country.id ? country.id : '',
                value: autocompleteValue || ''
            };
            if (address) {
                onChangeData({
                    ...pick([
                        'value',
                        'country',
                        'countryCode',
                        'administrativeAreaLevel1',
                        'administrativeAreaLevel2',
                        'locality',
                        'political',
                        'postalCode',
                        'route',
                        'streetNumber',
                        'placeId'
                    ], address),
                    ...requiredData
                });
            }
            else {
                onChangeData({ ...defaultAddressFull, ...requiredData });
            }
        }
    };
    render() {
        const { isOpen, countries } = this.props;
        const { country, address, autocompleteValue, predictions } = this.state;
        const countriesArr = getIndexedCountries(countries);
        const countryLabel = country ? country.label : '';
        const countryFromResource = getCountryByName(countryLabel, countries);
        const addressBlock = (countryFromResource || countryLabel || isOpen) && (React.createElement("div", { className: styles.wrapper },
            React.createElement(Autocomplete, { autoHighlight: true, id: "autocompleteId", wrapperStyle: { position: 'relative' }, items: predictions, getItemValue: (item) => item.mainText, renderItem: (item, isHighlighted) => (React.createElement("div", { key: `${item.mainText}-${item.secondaryText}`, className: classNames(styles, 'item', { isHighlighted }) }, `${item.mainText}, ${item.secondaryText}`)), renderInput: (props) => (React.createElement(AutocompleteInput, { inputRef: props.ref, label: "Address", ...pick([
                        'onChange',
                        'onBlur',
                        'onFocus',
                        'onKeyDown',
                        'onClick',
                        'value'
                    ], props) })), suggestionsMenuId: "input-address-suggestions", renderMenu: (items, suggestionsMenuId, value) => (React.createElement("div", { className: styles.items, id: suggestionsMenuId },
                    React.createElement("div", { className: styles.itemsWrap }),
                    items)), value: autocompleteValue, onChange: (e) => {
                    this.handleOnChangeAddress(e.target.value);
                }, onSelect: (selectedValue, item) => {
                    this.handleOnSetAddress(selectedValue, item);
                } })));
        const autocompleteResult = (address || isOpen) && (React.createElement(AddressResultForm, { onChangeForm: this.handleOnChangeForm, address: address }));
        return (React.createElement("div", null,
            React.createElement("div", { className: styles.wrapper },
                React.createElement(Select, { forForm: true, fullWidth: true, withInput: true, label: "Country", items: countriesArr, onSelect: this.handleOnChangeCountry, activeItem: this.state.country, dataTest: "AddressFormSelect" }),
                React.createElement("div", { className: styles.result }, addressBlock)),
            autocompleteResult));
    }
}
const AddressForm = googleApiWrapper(Form);
export default AddressForm;
