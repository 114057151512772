import React from 'react';
import { setRegion } from './utils';
import styles from '../OrderInvoice.module.scss';
import t from './i18n';
const OrderInvoiceAddress = ({ value, country, administrativeAreaLevel1, administrativeAreaLevel2, locality, political, postalCode, streetNumber, email, phone }) => {
    const region = setRegion(administrativeAreaLevel1, administrativeAreaLevel2);
    return (React.createElement("div", { className: styles.address },
        React.createElement("div", { className: styles.addressField },
            React.createElement("span", null,
                t.postalCode,
                ":"),
            " ",
            React.createElement("span", null, postalCode)),
        React.createElement("div", { className: styles.addressField },
            React.createElement("span", null,
                t.country,
                ":"),
            " ",
            React.createElement("span", null, country)),
        React.createElement("div", { className: styles.addressField },
            React.createElement("span", null,
                t.region,
                ":"),
            " ",
            React.createElement("span", null,
                " ",
                region)),
        React.createElement("div", { className: styles.addressField },
            React.createElement("span", null,
                t.city,
                ":"),
            " ",
            React.createElement("span", null, locality)),
        React.createElement("div", { className: styles.addressField },
            React.createElement("span", null,
                t.areaDistrict,
                ":"),
            " ",
            React.createElement("span", null,
                " ",
                political)),
        React.createElement("div", { className: styles.addressField },
            React.createElement("span", null,
                t.street,
                ":"),
            " ",
            React.createElement("span", null,
                " ",
                value)),
        React.createElement("div", { className: styles.addressField },
            React.createElement("span", null,
                t.aptSuiteOther,
                ":"),
            " ",
            React.createElement("span", null,
                " ",
                streetNumber)),
        React.createElement("div", { className: styles.addressField },
            React.createElement("span", null,
                t.email,
                ":"),
            " ",
            React.createElement("span", null,
                " ",
                email)),
        React.createElement("div", { className: styles.addressField },
            React.createElement("span", null,
                t.phoneNumber,
                ":"),
            " ",
            React.createElement("span", null,
                " ",
                phone))));
};
export default OrderInvoiceAddress;
