import { t } from '../../../translation/utils';
const translations = {
    en: {
        aboutStore: 'About Store',
        fullShopName: 'Full shop name',
        location: 'Location',
        description: 'Description'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
