import React, { PureComponent } from 'react';
import { Environment } from 'relay-runtime';
import PropTypes from 'prop-types';
import { classNames, formatPrice, getExchangePrice } from '../../../utils';
import { Col, Row } from '../../../layout';
import { ContextDecorator } from '../../../components/App';
import { Button } from '../../../components/common/Button';
import styles from './CheckoutSidebar.module.scss';
import t from './i18n';
class CheckoutSidebar extends PureComponent {
    static contextTypes = { environment: PropTypes.instanceOf(Environment).isRequired };
    setRef(ref) {
        this.ref = ref;
    }
    setWrapperRef(ref) {
        this.wrapperRef = ref;
    }
    isMount = false;
    ref;
    wrapperRef;
    scrolling = false;
    render() {
        const { onClick, onCheckout, isReadyToClick, buttonText, checkoutInProcess, goToCheckout, step, cart, currency } = this.props;
        const { productsCost, deliveryCost, totalCost, totalCount, couponsDiscounts } = cart;
        let onClickFunction = onClick;
        if (step != null) {
            onClickFunction = step === 1 ? goToCheckout : onCheckout;
        }
        const { currencyExchange } = this.props.directories;
        const currentCurrency = currency;
        const exchangePrice = currentCurrency ? getExchangePrice({ price: totalCost, currency: currentCurrency, currencyExchange, withSymbol: true }) : '';
        return (React.createElement("div", null,
            React.createElement("div", { className: styles.paperWrapper },
                React.createElement("div", { className: classNames(styles, 'corner', 'tl') }),
                React.createElement("div", { className: styles.paper }),
                React.createElement("div", { className: classNames(styles, 'corner', 'tr') })),
            React.createElement("div", { className: styles.container },
                React.createElement("div", { className: styles.title }, t.subtotal),
                React.createElement("div", { className: styles.totalsContainer },
                    React.createElement(Row, null,
                        React.createElement(Col, { size: 12, sm: 4, lg: 12 },
                            React.createElement("div", { className: styles.attributeContainer },
                                React.createElement("div", { className: styles.label }, t.subtotal),
                                React.createElement("div", { className: styles.value }, productsCost && `${formatPrice(productsCost || 0, 2)} ${currency || ''}`))),
                        React.createElement(Col, { size: 12, sm: 4, lg: 12 },
                            React.createElement("div", { className: styles.attributeContainer },
                                React.createElement("div", { className: styles.label }, t.delivery),
                                React.createElement("div", { className: styles.value }, deliveryCost && `${formatPrice(deliveryCost || 0, 2)} ${currency || ''}`))),
                        Boolean(couponsDiscounts) && (React.createElement(Col, { size: 12, sm: 4, lg: 12 },
                            React.createElement("div", { className: styles.attributeContainer },
                                React.createElement("div", { className: styles.label }, t.couponsDiscount),
                                React.createElement("div", { className: styles.value }, `−${formatPrice(couponsDiscounts || 0, 2)} ${currency || ''}`)))),
                        React.createElement(Col, { size: 12, sm: 4, lg: 12 },
                            React.createElement("div", { className: styles.attributeContainer },
                                React.createElement("div", { className: styles.label },
                                    t.total,
                                    ' ',
                                    React.createElement("span", { className: styles.subLabel },
                                        "(",
                                        totalCount && totalCount,
                                        ' ',
                                        t.items,
                                        ")")),
                                React.createElement("div", { className: styles.totalCost },
                                    React.createElement("div", { className: classNames(styles, 'value', 'bold') }, totalCost && `${formatPrice(totalCost || 0, 2)} ${currency || ''}`),
                                    exchangePrice != null && (React.createElement("div", { className: styles.exchangePrice }, exchangePrice))))))),
                React.createElement("div", { className: styles.checkout },
                    React.createElement(Button, { id: "cartTotalCheckout", disabled: checkoutInProcess || !isReadyToClick, isLoading: checkoutInProcess, big: true, onClick: onClickFunction, dataTest: "checkoutNext" }, buttonText))),
            React.createElement("div", { className: styles.paperWrapper },
                React.createElement("div", { className: classNames(styles, 'corner', 'bl') }),
                React.createElement("div", { className: classNames(styles, 'paper', 'bottom') }),
                React.createElement("div", { className: classNames(styles, 'corner', 'br') }))));
    }
}
export default ContextDecorator(CheckoutSidebar);
