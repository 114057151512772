import React, { Component } from 'react';
import { isNil, isEmpty } from 'ramda';
import { Icon } from '../Icon';
import styles from './Stepper.module.scss';
class Stepper extends Component {
    static getDerivedStateFromProps(nextProps) {
        return { inputValue: nextProps.value };
    }
    constructor(props) {
        super(props);
        this.state = { inputValue: 0 };
    }
    valid(val) {
        return ((isNil(this.props.min) || val >= this.props.min)
            && (isNil(this.props.max) || val <= this.props.max));
    }
    // coerce intput value to valid range
    coerce(val) {
        let value = isNil(val) ? this.props.min || this.props.max || 0 : val;
        if (!isNil(this.props.min)) {
            value = Math.max(this.props.min, value);
        }
        if (!isNil(this.props.max)) {
            value = Math.min(this.props.max, value);
        }
        return value;
    }
    handleClick(action) {
        const newVal = action === 'increase' ? this.props.value + 1 : this.props.value - 1;
        if (this.valid(newVal)) {
            this.props.onChange(newVal);
        }
    }
    handleChange(e) {
        if (isEmpty(e.target.value)) {
            this.setState({ inputValue: undefined });
            return;
        }
        const value = parseInt(e.target.value, 10);
        if (isNaN(value)) {
            return;
        }
        this.setState({ inputValue: value });
    }
    handleBlur() {
        const value = this.coerce(this.state.inputValue);
        this.props.onChange(value);
    }
    render() {
        return (React.createElement("div", { className: styles.container },
            React.createElement("button", { className: styles.button, onClick: () => this.handleClick('decrease'), "data-test": "cartProductsDecreaseButton" },
                React.createElement(Icon, { type: "minus", size: 16 })),
            React.createElement("input", { className: styles.input, type: "text", onBlur: () => this.handleBlur(), onChange: (e) => this.handleChange(e), value: isNil(this.state.inputValue) ? '' : this.state.inputValue }),
            React.createElement("button", { className: styles.button, onClick: () => this.handleClick('increase'), "data-test": "cartProductIncreaseButton" },
                React.createElement(Icon, { type: "plus", size: 16 }))));
    }
}
export default Stepper;
