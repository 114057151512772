import moment from 'moment';
let debug = (...args) => { };
let info = (...args) => { };
let warn = (...args) => { };
let error = (...args) => { };
const timestamp = () => `[${moment().format('HH:mm:ss:SSS YYYY-MM-DD')}]`;
if (process.env.NODE_ENV === 'development') {
    debug = (...args) => {
        console.log('\n', timestamp(), ...args, '\n');
    };
    info = (...args) => {
        console.info(timestamp(), ...args);
    };
    warn = (...args) => {
        console.warn(timestamp(), ...args);
    };
    error = (...args) => {
        console.error('\n', timestamp(), ...args, '\n');
    };
}
export { debug, info, warn, error };
