import { t } from '../../translation/utils';
const translations = {
    en: {
        receiverNameIsRequired: 'Receiver name is required',
        receiverPhoneIsRequired: 'Receiver phone is required',
        success: 'Success',
        orderSuccessfullyCreated: 'Order Successfully Created',
        somethingWentWrong: 'Something went wrong :(',
        close: 'Close.',
        error: 'Error :( ',
        somethingGoingWrongNewAddressWasNotCreated: 'Something going wrong. New address was not created.',
        addressCreated: 'Address created!',
        couponNotFound: 'coupon not found',
        couponAlreadyApplied: 'coupon already applied',
        couponApplied: 'coupon applied!',
        deliveryApplied: 'Delivery applied!',
        deliveryInfo: 'Delivery info',
        labelReceiverName: 'Receiver name',
        labelReceiverPhone: 'Receiver phone',
        labelChooseYourAddress: 'Choose your address',
        labelAddress: 'Address',
        labelOrFillFieldsBelowAndSaveAsAddress: 'Fill fields below and save as address',
        labelSaveAsANewAddress: 'Save as a new address',
        errors: {
            receiverNameRequired: 'Receiver name is required',
            receiverPhoneRequired: 'Receiver phone is required',
            areRequired: 'are required',
            country: 'country',
            address: 'address',
            postalCode: 'postal code'
        }
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
