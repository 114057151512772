import React from 'react';
import styles from './Row.module.scss';
import { classNames } from '../utils';
const Row = ({ withoutGrow = false, reverseSm = false, reverseMd = false, reverseLg = false, reverseXl = false, noWrap = false, alignItems = undefined, children }) => (React.createElement("div", { className: classNames(styles, 'container', {
        withoutGrow,
        reverseSm,
        reverseMd,
        reverseLg,
        reverseXl,
        noWrap,
        alignItemsCenter: alignItems === 'center'
    }) }, children));
export default Row;
