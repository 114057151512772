import { t } from '../../../../../translation/utils';
const translations = {
    en: {
        balanceIn: 'Balance in',
        somethingGoingWrong: 'Some thing going wrong',
        close: 'close'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
