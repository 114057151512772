import { t } from '../../../../translation/utils';
const translations = {
    en: {
        error: 'Error: ',
        somethingGoingWrong: 'Something going wrong',
        close: 'Close.',
        deleted: 'Deleted!',
        noProducts: 'No products',
        loadMore: 'Load more',
        confirmationDescription: 'Are you sure you want to delete this listing? All the listing information will be discarded and cannot be retrieved.',
        deleteYourProduct: 'Delete your product?',
        confirmText: 'Yes, delete, please',
        cancelText: 'Cancel'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
