import React, { Component } from 'react';
import { filter, find, map, omit, pathOr } from 'ramda';
import { Select } from '../../../../components/common/Select';
import { AddressForm } from '../../../../components/AddressAutocomplete';
import FormWrapper from '../FormWrapper';
import styles from './Form.module.scss';
import t from './i18n';
import { classNames } from '../../../../utils';
const languagesDic = {
    EN: 'English',
    CH: 'Chinese',
    DE: 'German',
    HI: 'Hindi',
    ES: 'Spanish',
    FR: 'French',
    KO: 'Korean',
    PO: 'Portuguese',
    JA: 'Japanese'
};
class SecondForm extends Component {
    static getDerivedStateFromProps(nextProps, prevState) {
        const store = pathOr(null, ['initialData', 'store'], nextProps);
        const storeId = pathOr(null, ['initialData', 'storeId'], nextProps);
        const newState = {
            ...prevState,
            ...nextProps.initialData,
            store,
            storeId
        };
        return newState;
    }
    constructor(props) {
        super(props);
        this.state = { ...props.initialData };
    }
    handleOnSelectLanguage = (item) => {
        const { onChange } = this.props;
        this.setState({ defaultLanguage: item ? item.id : null }, () => {
            onChange({ ...this.state });
        });
    };
    handleChangeAddressData = (addressData) => {
        const { onChange } = this.props;
        if (onChange && addressData) {
            onChange({
                ...this.state,
                addressFull: { ...omit(['postalCodeSuffix'], addressData) }
            });
        }
    };
    render() {
        const { languages } = this.props;
        const { addressFull, defaultLanguage } = this.state;
        const languagesItems = map((item) => ({
            id: item.isoCode.toUpperCase(),
            label: languagesDic[item.isoCode.toUpperCase()]
        }), filter((l) => l.isoCode === 'en', languages));
        const findActiveItem = find((item) => item.id === defaultLanguage);
        return (React.createElement(FormWrapper, { secondForm: true, title: t.setupStore, description: t.defineFewSettings },
            React.createElement("div", { className: styles.form },
                React.createElement("div", { className: styles.formItem },
                    React.createElement(Select, { forForm: true, fullWidth: true, label: t.labelMainLanguage, activeItem: findActiveItem(languagesItems), items: languagesItems, onSelect: this.handleOnSelectLanguage, dataTest: "wizardLanguagesSelect" })),
                React.createElement("div", { className: classNames(styles, 'formItem', 'addressForm') },
                    React.createElement(AddressForm, { isOpen: true, onChangeData: this.handleChangeAddressData, country: addressFull ? addressFull.country : null, address: addressFull ? addressFull.value : null, addressFull: addressFull })))));
    }
}
export default SecondForm;
