import React, { PureComponent } from 'react';
import { Button } from '../common/Button';
import { Modal } from '../Modal';
import styles from './Confirmation.module.scss';
class Confirmation extends PureComponent {
    static defaultProps = {
        showModal: false,
        description: '',
        onConfirm: () => {
        },
        onCancel: () => {
        },
        handleCloseModal: () => {
        },
        disableConfirm: false,
        children: undefined
    };
    render() {
        const { confirmText, cancelText, onCancel, onConfirm, handleCloseModal, title, description, showModal, disableConfirm, children } = this.props;
        return (React.createElement(Modal, { showModal: showModal, onClose: handleCloseModal, render: () => (React.createElement("aside", { className: styles.container, "data-test": "confirmationPopup" },
                React.createElement("h2", { className: styles.title }, title),
                React.createElement("p", { className: styles.description }, description),
                children != null && React.createElement("div", { className: styles.body }, children),
                React.createElement("div", { className: styles.buttons },
                    React.createElement(Button, { wireframe: true, big: true, onClick: onCancel, dataTest: "cancel" }, cancelText),
                    React.createElement(Button, { big: true, onClick: onConfirm, pink: true, disabled: disableConfirm, dataTest: "confirm" }, confirmText)))) }));
    }
}
export default Confirmation;
