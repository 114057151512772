import { commitMutation } from 'react-relay';
import { head } from 'ramda';
import { debug } from '../../../utils';
export function basicMutation(mutation, field) {
    return (params) => {
        if (!params.environment || params.variables == null) {
            return Promise.reject(new Error('Please provide environment and variables'));
        }
        return new Promise((resolve, reject) => commitMutation(params.environment, {
            mutation,
            variables: params.variables,
            onCompleted: (response, errors) => {
                debug('Mutation completed', {
                    response,
                    errors
                });
                if (response != null) {
                    if (field != null) {
                        if (response[field]) {
                            resolve(response);
                        }
                        else {
                            reject(head(errors || [new Error('No root field')]));
                        }
                    }
                    else {
                        resolve(response);
                    }
                }
                else if (errors) {
                    reject(errors);
                }
                else {
                    reject(new Error('Unknown error'));
                }
            },
            onError: (error) => {
                reject(error);
            },
            optimisticResponse: params.optimisticResponse,
            updater: params.updater
        }));
    };
}
