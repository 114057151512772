import { fetchQuery, graphql } from 'react-relay';
import { isNil, map, reject } from 'ramda';
import { debug } from '../../utils';
const AVAILABLE_PACKAGES_FOR_USER = graphql `
    query fetchAvailableShippingForUser_Query(
        $userCountry: String!
        $baseProductId: Int!
    ) {
        availableShippingForUser(
            userCountry: $userCountry
            baseProductId: $baseProductId
        ) {
            packages {
                id
                name
                price
                logo
                companyPackageRawId
                shippingId
            }
        }
    }
`;
const fetchAvailableShippingForUser = (input) => {
    debug();
    return fetchQuery(input.environment, AVAILABLE_PACKAGES_FOR_USER, {
        userCountry: input.destinationCountry,
        baseProductId: input.baseProductId
    })
        .toPromise()
        .then((response) => {
        let availablePackages = [];
        if (response && response.availableShippingForUser && response.availableShippingForUser.packages instanceof Array) {
            availablePackages = map((item) => {
                const { id, companyPackageRawId, name, price, shippingId, logo } = item;
                if (!isNil(id) && !isNil(name) && !isNil(price)) {
                    return {
                        id,
                        companyPackageRawId,
                        name,
                        price,
                        logo,
                        shippingId,
                        currency: 'INR'
                    };
                }
                return null;
            }, response.availableShippingForUser.packages);
            return Promise.resolve(reject(isNil, availablePackages));
        }
        return Promise.reject(new Error('Unable to fetch packages'));
    });
};
export default fetchAvailableShippingForUser;
