import React, { Component } from 'react';
import { isEmpty } from 'ramda';
import { AppContext } from '../App';
import { buildMobileCategories } from './utils';
import styles from './MobileMenu.module.scss';
import t from './i18n';
import SidebarMenu from './SidebarMenu';
import { classNames } from '../../utils';
class MobileMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCategoriesOpen: false,
            selectedCategory: {
                name: '',
                children: []
            }
        };
    }
    handleClick = (evt) => {
        const { onClose } = this.props;
        const { id } = evt.target;
        if (id === 'overlay') {
            this.setState({ isCategoriesOpen: false }, () => onClose());
        }
    };
    handleClose = () => {
        this.setState({ isCategoriesOpen: false });
    };
    handleCategoryClick = (cat) => {
        this.setState({
            selectedCategory: cat,
            isCategoriesOpen: !isEmpty(cat.children)
        });
    };
    render() {
        const { isOpen, onClose } = this.props;
        const { isCategoriesOpen, selectedCategory } = this.state;
        return (React.createElement(AppContext.Consumer, null, ({ categories }) => (React.createElement("div", { id: "overlay", onClick: this.handleClick, onKeyPress: () => {
            }, role: "presentation", className: classNames(styles, 'container', isOpen ? 'toggled' : '') },
            React.createElement(SidebarMenu, { categories: buildMobileCategories(categories), isOpen: true, onClick: this.handleCategoryClick, onClose: onClose, title: t.title }),
            React.createElement(SidebarMenu, { categories: selectedCategory.children, isOpen: isCategoriesOpen && isOpen, isSecondary: true, onClick: this.handleCategoryClick, onClose: onClose, onCloseCategories: this.handleClose, title: selectedCategory.name })))));
    }
}
export default MobileMenu;
