import React, { PureComponent } from 'react';
import { head, isEmpty, length, map, pathOr } from 'ramda';
import { withRouter } from 'found';
import { createFragmentContainer, graphql } from 'react-relay';
import { ManageStore } from '../index';
import { debug, fromRelayError, info } from '../../../../utils';
import { DeleteWarehouseMutation } from '../../../../relay/mutations';
import { Button } from '../../../../components/common/Button';
import { Page } from '../../../../components/App';
import { withShowAlert } from '../../../../components/Alerts/AlertContext';
import styles from './Storages.module.scss';
import t from './i18n';
import StoragesHeader from './StoragesHeader';
import StoragesRow from './StoragesRow';
class Storages extends PureComponent {
    // static contextTypes = {showAlert: Function};
    createStorage = () => {
        const storeId = pathOr(null, ['match', 'params', 'storeId'], this.props);
        if (storeId) {
            this.props.router.push(`/manage/store/${storeId}/storage/new`);
        }
    };
    handleEdit = (slug, isStorageData, e) => {
        e.stopPropagation();
        const storeId = pathOr(null, ['match', 'params', 'storeId'], this.props);
        if (storeId && slug) {
            this.props.router.push(`/manage/store/${storeId}/storages/${slug}${isStorageData ? '/edit' : ''}`);
        }
    };
    handleCheckboxClick = (id) => {
        info('id', id);
    };
    handleDelete = (id, e) => {
        e.stopPropagation();
        const { environment } = this.props;
        const params = {
            variables: { id },
            environment,
            onCompleted: (response, errors) => {
                debug({
                    response,
                    errors
                });
                const relayErrors = fromRelayError({ source: { errors } });
                debug({ relayErrors });
                const validationErrors = pathOr({}, ['100', 'messages'], relayErrors);
                if (!isEmpty(validationErrors)) {
                    this.props.showAlert({
                        type: 'danger',
                        text: t.somethingGoingWrong,
                        link: { text: t.close }
                    });
                    return;
                }
                const statusError = pathOr({}, ['100', 'status'], relayErrors);
                if (!isEmpty(statusError)) {
                    this.props.showAlert({
                        type: 'danger',
                        text: `${t.error} "${statusError}"`,
                        link: { text: t.close }
                    });
                    return;
                }
                this.props.showAlert({
                    type: 'success',
                    text: t.storageDeleted,
                    link: { text: '' }
                });
            },
            onError: (error) => {
                debug({ error });
                this.props.showAlert({
                    type: 'danger',
                    text: t.somethingGoingWrong,
                    link: { text: t.close }
                });
            }
        };
        DeleteWarehouseMutation.commit(params);
    };
    render() {
        const { me } = this.props;
        const storages = pathOr([], ['myStore', 'warehouses'], me);
        return (React.createElement("div", { className: styles.container },
            React.createElement("header", { className: styles.headerBar },
                React.createElement("div", { className: styles.subtitle },
                    React.createElement("strong", null, t.itemList)),
                length(storages) === 0 && (React.createElement("div", { className: styles.addButton },
                    React.createElement(Button, { wireframe: true, big: true, onClick: this.createStorage, dataTest: "createStorageButton" }, t.addStorage)))),
            React.createElement("div", null,
                React.createElement(StoragesHeader, null),
                React.createElement("div", null, isEmpty(storages) ? (React.createElement("div", { className: styles.emptyStoragesBlock }, t.noStorages)) : (map((item) => (React.createElement(StoragesRow, { key: item.id, ...item, onEdit: this.handleEdit, onDelete: this.handleDelete, handleCheckboxClick: this.handleCheckboxClick })), [head(storages)]))))));
    }
}
export default createFragmentContainer(Page(withShowAlert(withRouter(ManageStore({
    OriginalComponent: Storages,
    active: 'storages',
    title: 'Storages'
})))), {
    me: graphql `
			fragment Storages_me on User {
				myStore {
					rawId
					warehouses {
						id
						name
						slug
						addressFull {
							country
							administrativeAreaLevel1
							administrativeAreaLevel2
							political
							postalCode
							streetNumber
							value
							route
							locality
						}
					}
				}
			}
    `
});
