import 'core-js';
import 'regenerator-runtime/runtime';
import React from 'react';
import { createInitialFarceRouter } from 'found';
import { Provider } from 'react-redux';
import { BrowserProtocol } from 'farce';
// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';
import ClientFetcher from './ClientFetcher';
import { createResolver } from '../relay/resolver';
import { render } from '../relay/render';
import { createReduxStore } from '../redux/createReduxStore';
import './index.scss';
import { routeConfig } from '../routes/routes';
import { historyMiddlewares } from '../relay/history';
import '../windowModal';
import { createRoot } from 'react-dom/client';
/* eslint no-underscore-dangle: 0 */
const init = async () => {
    if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_CONFIG_URL_SPA) {
        /*
         Sentry.init({
         dsn: process.env.REACT_APP_SENTRY_CONFIG_URL_SPA,
         integrations: [new Integrations.BrowserTracing()],
         tracesSampleRate: 1.0
         });
         */
    }
    const element = document.getElementById('root');
    const fetcher = new ClientFetcher(process.env.REACT_APP_GRAPHQL_ENDPOINT, window.__RELAY_PAYLOADS__ || {});
    delete window.__RELAY_PAYLOADS__;
    const resolver = createResolver(fetcher);
    const preloadedState = window.__PRELOADED_STATE__;
    delete window.__PRELOADED_STATE__;
    const store = createReduxStore(new BrowserProtocol(), preloadedState);
    const Router = await createInitialFarceRouter({
        historyProtocol: new BrowserProtocol(),
        historyMiddlewares,
        resolver,
        render,
        routeConfig
    });
    if (element) {
        const root = createRoot(element);
        root.render(React.createElement(Provider, { store: store },
            React.createElement(Router, { resolver: resolver })));
    }
    if (module.hot) {
        module.hot.accept();
    }
};
init();
