import React from 'react';
import { Icon } from '../../../../../components/Icon';
import { Col } from '../../../../../layout';
import styles from './StoragesHeader.module.scss';
import t from './i18n';
const StoragesHeader = () => (React.createElement("div", { className: styles.container },
    React.createElement(Col, { size: 12, sm: 6, md: 5, lg: 3, xl: 3, mdVisible: true },
        React.createElement("div", { className: styles.headerCheckbox },
            React.createElement("div", null,
                React.createElement("span", null, t.storage),
                React.createElement(Icon, { inline: true, type: "sortArrows" })))),
    React.createElement(Col, { size: 12, sm: 6, md: 4, lg: 8, xl: 8, mdVisible: true },
        React.createElement("div", null,
            React.createElement("span", null, t.address),
            React.createElement(Icon, { inline: true, type: "sortArrows" })))));
export default StoragesHeader;
