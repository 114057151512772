import React, { Component } from 'react';
import { isEmpty, map, pathOr } from 'ramda';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { Environment } from 'relay-runtime';
import { addressToString, classNames, debug, fromRelayError } from '../../../../../../utils';
import { SetProductQuantityInWarehouseMutation } from '../../../../../../relay/mutations';
import { withShowAlert } from '../../../../../../components/Alerts/AlertContext';
import { Input } from '../../../../../../components/common/Input';
import { Button } from '../../../../../../components/common/Button';
import styles from './Warehouses.module.scss';
import t from './i18n';
class Warehouses extends Component {
    static contextTypes = {
        directories: PropTypes.object,
        environment: PropTypes.instanceOf(Environment).isRequired
    };
    constructor(props) {
        super(props);
        this.state = {
            storageFocusId: null,
            storageFocusCurrentValue: '',
            storageFocusValue: ''
        };
    }
    handleFocus = (e, quantity) => {
        const { id, value } = e.target;
        this.setState({
            storageFocusId: id,
            storageFocusCurrentValue: `${quantity}`,
            storageFocusValue: value
        });
    };
    handleBlur = () => {
        const { storageFocusCurrentValue, storageFocusValue } = this.state;
        if (storageFocusValue === storageFocusCurrentValue) {
            this.setState({ storageFocusId: null });
        }
    };
    handleChange = (e) => {
        const { value } = e.target;
        if (parseInt(value, 10) >= 0 && value !== '') {
            this.setState({ storageFocusValue: value.replace(/^0+/, '') });
        }
        if (value === '' || /^0+$/.test(value)) {
            this.setState({ storageFocusValue: '0' });
        }
    };
    handleSave = (productId, warehouseId) => {
        const { storageFocusValue: quantity } = this.state;
        const { environment } = this.context;
        const params = {
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    warehouseId,
                    productId,
                    quantity: parseInt(quantity, 10)
                }
            },
            environment,
            onCompleted: (response, errors) => {
                debug({
                    response,
                    errors
                });
                const relayErrors = fromRelayError({ source: { errors } });
                debug({ relayErrors });
                const statusError = pathOr({}, ['100', 'status'], relayErrors);
                if (!isEmpty(statusError)) {
                    this.props.showAlert({
                        type: 'danger',
                        text: `${t.error}: "${statusError}"`,
                        link: { text: t.close }
                    });
                    return;
                }
                if (errors) {
                    this.props.showAlert({
                        type: 'danger',
                        text: t.somethingWentWrong,
                        link: { text: t.close }
                    });
                    return;
                }
                this.setState({ storageFocusId: null });
            },
            onError: (error) => {
                debug({ error });
                this.props.showAlert({
                    type: 'danger',
                    text: t.somethingWentWrong,
                    link: { text: t.close }
                });
            }
        };
        SetProductQuantityInWarehouseMutation.commit(params);
    };
    render() {
        const { stocks } = this.props;
        const { storageFocusId, storageFocusValue } = this.state;
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.title },
                React.createElement("strong", null, t.storages)),
            React.createElement("div", { className: styles.items }, map((item) => {
                const product = `${item.id}` === storageFocusId;
                const { addressFull } = item.warehouse;
                const warehouseName = pathOr(null, ['warehouse', 'name'], item);
                const warehouseSlug = pathOr(null, ['warehouse', 'slug'], item);
                return (React.createElement("div", { key: item.id, className: styles.item },
                    React.createElement("div", { className: classNames(styles, 'td', 'tdName') },
                        React.createElement("strong", { className: styles.name }, warehouseName || `Storage ${warehouseSlug || ''}`)),
                    React.createElement("div", { className: classNames(styles, 'td', 'tdAddress') },
                        React.createElement("div", { className: styles.address }, addressToString(addressFull) || t.noAddress)),
                    React.createElement("div", { className: classNames(styles, 'td', 'tdQuantity') },
                        React.createElement("div", { className: styles.quantityInput },
                            React.createElement(Input, { id: item.id, type: "number", inline: true, fullWidth: true, dataTest: "quantity", value: product ? storageFocusValue : `${item.quantity}`, onFocus: (e) => {
                                    this.handleFocus(e, item.quantity);
                                }, onBlur: this.handleBlur, onChange: this.handleChange })),
                        product && (React.createElement("div", null,
                            React.createElement(Button, { small: true, disabled: product && storageFocusValue === `${item.quantity}`, onClick: () => {
                                    this.handleSave(item.productId, item.warehouseId);
                                }, dataTest: "saveQuantityButton" }, t.save))))));
            }, stocks))));
    }
}
export default withShowAlert(Warehouses);
