/**
 * @generated SignedSource<<5fc2a36d7b4082634be8336f28566b8e>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawId",
        "storageKey": null
    }, v2 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lang",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "text",
            "storageKey": null
        }
    ], v3 = {
        "alias": null,
        "args": null,
        "concreteType": "Translation",
        "kind": "LinkedField",
        "name": "name",
        "plural": true,
        "selections": (v2 /*: any*/),
        "storageKey": null
    };
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "after"
            },
            {
                "defaultValue": 8,
                "kind": "LocalArgument",
                "name": "first"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "text"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                {
                    "count": "first",
                    "cursor": "after",
                    "direction": "forward",
                    "path": [
                        "findStore"
                    ]
                }
            ]
        },
        "name": "Stores_search",
        "selections": [
            {
                "alias": "findStore",
                "args": [
                    {
                        "kind": "Variable",
                        "name": "searchTerm",
                        "variableName": "text"
                    },
                    {
                        "kind": "Literal",
                        "name": "visibility",
                        "value": "published"
                    }
                ],
                "concreteType": "StoresWithTotalCountConnection",
                "kind": "LinkedField",
                "name": "__Stores_findStore_connection",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfoStoresSearch",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "StoresSearchFilters",
                                "kind": "LinkedField",
                                "name": "searchFilters",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "totalCount",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Category",
                                        "kind": "LinkedField",
                                        "name": "category",
                                        "plural": false,
                                        "selections": [
                                            (v0 /*: any*/),
                                            (v1 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Category",
                                                "kind": "LinkedField",
                                                "name": "children",
                                                "plural": true,
                                                "selections": [
                                                    (v0 /*: any*/),
                                                    (v1 /*: any*/),
                                                    (v3 /*: any*/)
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "country",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "StoresEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Store",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v0 /*: any*/),
                                    (v1 /*: any*/),
                                    (v3 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "logo",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "cover",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "slug",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Translation",
                                        "kind": "LinkedField",
                                        "name": "shortDescription",
                                        "plural": true,
                                        "selections": (v2 /*: any*/),
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": [
                                            {
                                                "kind": "Literal",
                                                "name": "first",
                                                "value": 4
                                            },
                                            {
                                                "kind": "Literal",
                                                "name": "searchTerm",
                                                "value": {}
                                            }
                                        ],
                                        "concreteType": "BaseProductsConnection",
                                        "kind": "LinkedField",
                                        "name": "findMostViewedProducts",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "BaseProductsEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "BaseProduct",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                            (v0 /*: any*/),
                                                            (v1 /*: any*/),
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "concreteType": "ProductsConnection",
                                                                "kind": "LinkedField",
                                                                "name": "products",
                                                                "plural": false,
                                                                "selections": [
                                                                    {
                                                                        "alias": null,
                                                                        "args": null,
                                                                        "concreteType": "ProductsEdge",
                                                                        "kind": "LinkedField",
                                                                        "name": "edges",
                                                                        "plural": true,
                                                                        "selections": [
                                                                            {
                                                                                "alias": null,
                                                                                "args": null,
                                                                                "concreteType": "Product",
                                                                                "kind": "LinkedField",
                                                                                "name": "node",
                                                                                "plural": false,
                                                                                "selections": [
                                                                                    {
                                                                                        "alias": null,
                                                                                        "args": null,
                                                                                        "kind": "ScalarField",
                                                                                        "name": "photoMain",
                                                                                        "storageKey": null
                                                                                    }
                                                                                ],
                                                                                "storageKey": null
                                                                            }
                                                                        ],
                                                                        "storageKey": null
                                                                    }
                                                                ],
                                                                "storageKey": null
                                                            }
                                                        ],
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": "findMostViewedProducts(first:4,searchTerm:{})"
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__typename",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Search",
        "abstractKey": null
    };
})();
node.hash = "e54538c43e27713c443bfc82fa0c943f";
export default node;
