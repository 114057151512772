import { t } from '../../../../translation/utils';
const translations = {
    en: {
        sendYourProduct: 'Send your Product',
        toCustomer: 'to customer',
        doNotForgetToAttach: 'Do not forget to attach track number you get from delivery service used for product sending',
        labelTrackNumber: 'Track Number',
        labelComment: 'Comment',
        sendOrder: 'Send Order',
        sendNow: 'Send Now',
        confirmOrder: 'Confirm order',
        cancelOrder: 'Cancel order',
        cancelOrderTitle: 'Are you sure to cancel order?',
        cancelOrderDescription: '',
        cancelOrderConfirmText: 'Cancel order',
        cancelOrderCancelText: 'No',
        manage: 'Manage',
        storiqaFee: 'Graame fee',
        bankTransactionFee: 'Bank transaction fee',
        chargeFee: 'Charge fee',
        status: 'Status',
        areYouSureToPayChargeFee: 'Are you sure to pay charge fee?',
        pleaseCheckCard: 'Please, check that you have connected payments card to your account in finances section and you have enough funds on it',
        cancel: 'Cancel',
        payFee: 'Pay fee'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
