import React, { Component } from 'react';
import { isEmpty, isNil } from 'ramda';
import ImageLoader from '../../../../libs/react-image-loader';
import { classNames, convertSrc } from '../../../../utils';
import BannerLoading from '../../../../components/Banner/BannerLoading';
import { Icon } from '../../../../components/Icon';
import { sortByProp } from '../utils';
import styles from './ProductVariantThumbnails.module.scss';
class ProductVariantThumbnails extends Component {
    static defaultProps = {
        title: '',
        row: false,
        isReset: false,
        isFirstSelected: false
    };
    static getDerivedStateFromProps(nextProps, prevState) {
        const { isReset, isFirstSelected } = nextProps;
        if (isReset) {
            return { selected: isFirstSelected ? 0 : null };
        }
        return prevState;
    }
    constructor(props) {
        super(props);
        this.state = { selected: null };
    }
    handleClick = (option, index) => {
        this.setState({ selected: index }, () => {
            this.props.onClick(option);
        });
    };
    render() {
        const { options, row, title, isOnSelected } = this.props;
        const { selected } = this.state;
        const mapOptions = (option, index) => (React.createElement("button", { key: `${option.label || option.id}`, "data-test": `productThumbail${option.label}`, onClick: () => this.handleClick(option, index) }, option.image ? (React.createElement("figure", { className: classNames(styles, 'thumbnailContainer', { 'clicked': selected === index }) },
            React.createElement(ImageLoader, { fit: true, src: convertSrc('medium', option.image), loader: React.createElement(BannerLoading, null) }))) : (React.createElement("div", { className: classNames(styles, 'emptyImg', { clicked: selected === index }) },
            React.createElement(Icon, { type: "camera", size: 40 })))));
        return (React.createElement("div", { className: classNames(styles, 'container', {
                'scroll-x': row,
                'scroll-y': !row
            }) },
            !isEmpty(title) ? (React.createElement("div", { id: title, className: classNames(styles, 'title', { 'isOnSelected': isOnSelected }) },
                React.createElement("strong", null, title))) : null,
            React.createElement("div", { className: classNames(styles, `thumbnails ${row ? 'row' : 'column'}`) }, isNil(options) ? null : sortByProp('label')(options)
                .map(mapOptions))));
    }
}
export default ProductVariantThumbnails;
