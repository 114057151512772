/**
 * @generated SignedSource<<54586950245797b4a7c0f049aa91d83b>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "id"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "id"
                }
            ],
            "concreteType": "Store",
            "kind": "LinkedField",
            "name": "sendStoreToModeration",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SendStoreToModerationByUserMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SendStoreToModerationByUserMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "e3069d6c9d7fd93fd3d4b83a90ec5260",
            "id": null,
            "metadata": {},
            "name": "SendStoreToModerationByUserMutation",
            "operationKind": "mutation",
            "text": "mutation SendStoreToModerationByUserMutation(\n  $id: Int!\n) {\n  sendStoreToModeration(id: $id) {\n    id\n    status\n  }\n}\n"
        }
    };
})();
node.hash = "2b0e2578cb0895e51b64aaaa6a22521c";
export default node;
