/**
 * @generated SignedSource<<5d92b33e077f4b7ad1f146b71a589d98>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "storeId",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawId",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "concreteType": "Translation",
        "kind": "LinkedField",
        "name": "name",
        "plural": true,
        "selections": [
            (v6 /*: any*/)
        ],
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lang",
        "storageKey": null
    }, v9 = {
        "alias": null,
        "args": null,
        "concreteType": "Translation",
        "kind": "LinkedField",
        "name": "name",
        "plural": true,
        "selections": [
            (v6 /*: any*/),
            (v8 /*: any*/)
        ],
        "storageKey": null
    }, v10 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "price",
        "storageKey": null
    }, v11 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
    }, v12 = {
        "alias": null,
        "args": null,
        "concreteType": "Translation",
        "kind": "LinkedField",
        "name": "name",
        "plural": true,
        "selections": [
            (v8 /*: any*/),
            (v6 /*: any*/)
        ],
        "storageKey": null
    }, v13 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "photoMain",
        "storageKey": null
    }, v14 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "receiverName",
        "storageKey": null
    }, v15 = {
        "alias": null,
        "args": null,
        "concreteType": "Address",
        "kind": "LinkedField",
        "name": "addressFull",
        "plural": false,
        "selections": [
            (v11 /*: any*/)
        ],
        "storageKey": null
    }, v16 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
    }, v17 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deliveryCompany",
        "storageKey": null
    }, v18 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "trackId",
        "storageKey": null
    }, v19 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "quantity",
        "storageKey": null
    }, v20 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "subtotal",
        "storageKey": null
    }, v21 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "state",
        "storageKey": null
    }, v22 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "paymentStatus",
        "storageKey": null
    }, v23 = {
        "alias": null,
        "args": null,
        "concreteType": "OrderHistoryItemsConnection",
        "kind": "LinkedField",
        "name": "history",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "OrderHistoryItemsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrderHistoryItem",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                            (v21 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "committedAt",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "committerRole",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "comment",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "CancelOrderMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "GraphQLOrder",
                    "kind": "LinkedField",
                    "name": "setOrderStatusCanceled",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Product",
                            "kind": "LinkedField",
                            "name": "product",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "BaseProduct",
                                    "kind": "LinkedField",
                                    "name": "baseProduct",
                                    "plural": false,
                                    "selections": [
                                        (v5 /*: any*/),
                                        (v7 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Category",
                                            "kind": "LinkedField",
                                            "name": "category",
                                            "plural": false,
                                            "selections": [
                                                (v5 /*: any*/),
                                                (v9 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                (v10 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ProdAttrValue",
                                    "kind": "LinkedField",
                                    "name": "attributes",
                                    "plural": true,
                                    "selections": [
                                        (v11 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Attribute",
                                            "kind": "LinkedField",
                                            "name": "attribute",
                                            "plural": false,
                                            "selections": [
                                                (v12 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                (v13 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v14 /*: any*/),
                        (v15 /*: any*/),
                        (v16 /*: any*/),
                        (v17 /*: any*/),
                        (v18 /*: any*/),
                        (v19 /*: any*/),
                        (v20 /*: any*/),
                        (v21 /*: any*/),
                        (v22 /*: any*/),
                        (v23 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "CancelOrderMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "GraphQLOrder",
                    "kind": "LinkedField",
                    "name": "setOrderStatusCanceled",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Product",
                            "kind": "LinkedField",
                            "name": "product",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "BaseProduct",
                                    "kind": "LinkedField",
                                    "name": "baseProduct",
                                    "plural": false,
                                    "selections": [
                                        (v5 /*: any*/),
                                        (v7 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Category",
                                            "kind": "LinkedField",
                                            "name": "category",
                                            "plural": false,
                                            "selections": [
                                                (v5 /*: any*/),
                                                (v9 /*: any*/),
                                                (v2 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v10 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ProdAttrValue",
                                    "kind": "LinkedField",
                                    "name": "attributes",
                                    "plural": true,
                                    "selections": [
                                        (v11 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Attribute",
                                            "kind": "LinkedField",
                                            "name": "attribute",
                                            "plural": false,
                                            "selections": [
                                                (v12 /*: any*/),
                                                (v2 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                (v13 /*: any*/),
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v14 /*: any*/),
                        (v15 /*: any*/),
                        (v16 /*: any*/),
                        (v17 /*: any*/),
                        (v18 /*: any*/),
                        (v19 /*: any*/),
                        (v20 /*: any*/),
                        (v21 /*: any*/),
                        (v22 /*: any*/),
                        (v23 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "4cc09d2c606469a1bbdf63590041a2bc",
            "id": null,
            "metadata": {},
            "name": "CancelOrderMutation",
            "operationKind": "mutation",
            "text": "mutation CancelOrderMutation(\n  $input: OrderStatusCanceledInput!\n) {\n  setOrderStatusCanceled(input: $input) {\n    id\n    slug\n    storeId\n    product {\n      baseProduct {\n        rawId\n        name {\n          text\n        }\n        category {\n          rawId\n          name {\n            text\n            lang\n          }\n          id\n        }\n        id\n      }\n      price\n      attributes {\n        value\n        attribute {\n          name {\n            lang\n            text\n          }\n          id\n        }\n      }\n      photoMain\n      id\n    }\n    receiverName\n    addressFull {\n      value\n    }\n    createdAt\n    deliveryCompany\n    trackId\n    quantity\n    subtotal\n    state\n    paymentStatus\n    history {\n      edges {\n        node {\n          state\n          committedAt\n          committerRole\n          comment\n        }\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "dc57b10ac3134213ddc09f0f55005c90";
export default node;
