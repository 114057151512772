import 'isomorphic-fetch';
import { assoc, omit, slice } from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'universal-cookie';
import { isJwtExpiredErrorInResponse, isJwtRevokedInResponse } from './fetcher.utils';
import { debug, error, removeCookie, setJWT } from '../utils';
import { grayLogger } from '../utils/graylog';
/* eslint class-methods-use-this: 0, no-underscore-dangle: 0 */
export class FetcherBase {
    url;
    constructor(url) {
        this.url = url;
    }
    getCookies() {
        return null;
    }
    getJWTFromCookies() {
        throw new Error('should be implemented in subclasses');
    }
    getSessionIdFromCookies() {
        throw new Error('should be implemented in subclasses');
    }
    getCurrencyCodeFromCookies() {
        throw new Error('should be implemented in subclasses');
    }
    getCorrelationToken() {
        throw new Error('should be implemented in subclasses');
    }
    get headers() {
        const jwt = this.getJWTFromCookies();
        const currency = this.getCurrencyCodeFromCookies();
        let headers = {
            'Content-Type': 'application/json',
            Currency: currency || 'INR',
            Authorization: ''
        };
        if (jwt) {
            headers = assoc('Authorization', `Bearer ${jwt}`, headers);
        }
        if (this.getCorrelationToken()) {
            headers = assoc('correlation-token', this.getCorrelationToken(), headers);
        }
        const sessionId = this.getSessionIdFromCookies();
        headers = assoc('session-id', sessionId, headers);
        if (!process.env.BROWSER && process.env.NODE_ENV !== 'production') {
            headers = assoc('Cookie', 'holyshit=iamcool', headers);
        }
        return headers;
    }
    logRequest(data) {
        debug('GraphQL request', data);
        grayLogger.info('GraphQL request', {
            uid: data.uid,
            url: this.url,
            operationName: data.request.name,
            operationText: slice(0, 32000, data.request.text),
            operationVariables: JSON.stringify(data.variables),
            headers: JSON.stringify(omit(['Authorization'], data.headers), null, 2)
        });
    }
    async fetch(request, variables) {
        const cookies = this.getCookies() || new Cookies();
        const uid = uuidv4();
        this.logRequest({
            url: this.url,
            headers: this.headers,
            uid,
            request,
            variables
        });
        try {
            const response = await fetch(this.url, {
                method: 'POST',
                headers: this.headers,
                body: JSON.stringify({
                    query: request.text,
                    variables
                })
            });
            const data = await response.json();
            debug('GraphQL response', { uid, ...data });
            grayLogger.info('GraphQL response', {
                uid,
                response: slice(0, 32000, JSON.stringify(data, null, 2))
            });
            if (isJwtExpiredErrorInResponse(data)) {
                debug('JwtExpiredErrorInResponse');
                const newTokenResponse = await fetch(this.url, {
                    method: 'POST',
                    headers: this.headers,
                    body: JSON.stringify({ query: 'mutation RefreshJwtMutation { refreshJwt }' }),
                    credentials: 'omit' // 'include'
                });
                const tokenData = await newTokenResponse.json();
                debug({ newToken: tokenData });
                if (tokenData?.data?.refreshJwt) {
                    setJWT(tokenData.data.refreshJwt, cookies);
                    const updatedHeaders = {
                        ...this.headers,
                        Authorization: `Bearer ${tokenData.data.refreshJwt}`
                    };
                    this.logRequest({
                        url: this.url,
                        headers: updatedHeaders,
                        uid,
                        request,
                        variables
                    });
                    const responseWithRefreshedJWT = await fetch(this.url, {
                        method: 'POST',
                        headers: updatedHeaders,
                        body: JSON.stringify({
                            query: request.text,
                            variables
                        }),
                        credentials: 'omit' // 'include'
                    });
                    const jwtData = await responseWithRefreshedJWT.json();
                    debug('GraphQL response', { uid, ...jwtData });
                    grayLogger.info('GraphQL response', {
                        uid,
                        response: slice(0, 32000, JSON.stringify(jwtData, null, 2))
                    });
                    return jwtData;
                }
                debug({ newToken: tokenData });
            }
            if (isJwtRevokedInResponse(data)) {
                removeCookie('__jwt', cookies);
                const updatedHeaders = omit(['Authorization'], this.headers);
                this.logRequest({
                    url: this.url,
                    headers: updatedHeaders,
                    uid,
                    request,
                    variables
                });
                const responseWithoutJWT = await fetch(this.url, {
                    method: 'POST',
                    headers: updatedHeaders,
                    body: JSON.stringify({
                        query: request.text,
                        variables
                    }),
                    credentials: 'omit' // 'include'
                });
                const withoutJWTData = await responseWithoutJWT.json();
                debug('GraphQL response', {
                    uid,
                    ...withoutJWTData
                });
                grayLogger.info('GraphQL response', {
                    uid,
                    response: slice(0, 32000, JSON.stringify(withoutJWTData, null, 2))
                });
                return withoutJWTData;
            }
            return data;
        }
        catch (e) {
            error('GraphQL fetching error: ', { error: e });
            grayLogger.error('GraphQL fetching error', {
                uid,
                error: e
            });
            return {
                data: null,
                errors: ['No data returned from gateway']
            };
        }
    }
}
