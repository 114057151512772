import { t } from '../../../../../translation/utils';
const translations = {
    en: {
        theStoreWasDeleted: 'The Store was deleted',
        theProductWasDeleted: 'The Product was deleted',
        paid: 'Paid',
        notPaid: 'Not Paid'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
