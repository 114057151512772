/**
 * @generated SignedSource<<4ed867577124fd6c90d82f0db36a4ac1>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "StoreSubscription",
            "kind": "LinkedField",
            "name": "updateStoreSubscription",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "storeId",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currency",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "walletAddress",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "trialStartDate",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UpdateStoreSubscriptionMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UpdateStoreSubscriptionMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "7758869c30f272ff3e7980f0941d2b95",
            "id": null,
            "metadata": {},
            "name": "UpdateStoreSubscriptionMutation",
            "operationKind": "mutation",
            "text": "mutation UpdateStoreSubscriptionMutation(\n  $input: UpdateStoreSubscriptionInput!\n) {\n  updateStoreSubscription(input: $input) {\n    id\n    storeId\n    currency\n    value\n    walletAddress\n    trialStartDate\n    status\n  }\n}\n"
        }
    };
})();
node.hash = "99021e22043a5fb70a449a2e1d8319e4";
export default node;
