import React, { Component } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { assoc, filter, find, head, includes, isEmpty, isNil, keys, map, pathOr, propEq, toUpper } from 'ramda';
import { validate } from '@sansare/libstqfrontend';
import { v4 as uuidv4 } from 'uuid';
import smoothscroll from '../../libs/smoothscroll';
import { withShowAlert } from '../../components/Alerts/AlertContext';
import { Col, Container, Row } from '../../layout';
import { Checkbox, Input, RadioButton, Select } from '../../components/common';
import { AddressForm } from '../../components/AddressAutocomplete';
import { StickyBar } from '../../components/StickyBar';
import { classNames, debug, error, fromRelayError, getCurrentCurrency, getNameText } from '../../utils';
import { BuyNowMutation, CreateUserDeliveryAddressFullMutation } from '../../relay/mutations';
import { Page } from '../../components/App';
import Header from './Header';
import AddressInfo from './AddressInfo';
import CheckoutProducts from './CheckoutProducts';
import { CartStore } from './CartStore';
import { addressesToSelect } from './utils';
import fetchBuyNow from './fetchBuyNow';
import updateUserPhoneMutation from '../Checkout/mutations/UpdateUserPhoneMutation';
import styles from './BuyNow.module.scss';
import t from './i18n';
import { CheckoutSidebar } from './CheckoutSidebar';
const emptyAddressFull = {
    value: '',
    country: '',
    countryCode: '',
    administrativeAreaLevel1: '',
    administrativeAreaLevel2: '',
    locality: '',
    political: '',
    postalCode: '',
    route: '',
    streetNumber: '',
    placeId: ''
};
class BuyNow extends Component {
    constructor(props) {
        super(props);
        const { me, calculateBuyNow } = props;
        const { couponsDiscounts, totalCost, totalCostWithoutDiscounts, totalCount, deliveryCost, subtotalWithoutDiscounts, subtotal } = calculateBuyNow;
        const { deliveryAddressesFull } = me;
        const addresses = addressesToSelect(deliveryAddressesFull);
        const selectedAddress = this.getDefaultSelectedDeliveryAddress();
        this.state = {
            step: 1,
            receiverName: `${me.firstName} ${me.lastName}`,
            phone: me.phone || '',
            addresses,
            selectedAddress: selectedAddress || null,
            deliveryAddress: this.getDeliveryAddress(selectedAddress),
            saveAsNewAddress: true,
            buyNowData: {
                couponsDiscounts,
                totalCost,
                totalCostWithoutDiscounts,
                totalCount,
                deliveryCost,
                subtotalWithoutDiscounts,
                subtotal
            },
            changeCountLoading: false,
            couponCodeValue: '',
            successCouponCodeValue: undefined,
            couponCodeButtonDisabled: true,
            isLoadingCouponButton: false,
            isLoadingCheckout: false,
            errors: {},
            scrollArr: ['receiverName', 'phone', 'deliveryAddress'],
            deliveryPackage: undefined,
            currency: getCurrentCurrency()
        };
    }
    componentDidMount() {
        window.scroll({ top: 0 });
        const { baseProduct } = this.props;
        const queryParams = pathOr([], ['match', 'location', 'query'], this.props);
        const variants = pathOr([], ['variants', 'all'], baseProduct);
        const variant = find(propEq(parseInt(queryParams.variant, 10), 'rawId'))(variants);
        if (variant && !variant.preOrder && parseInt(queryParams.quantity, 10) > variant.quantity) {
            this.handleChangeCount(variant.quantity);
        }
        // if (queryParams.delivery) {
        //   this.handleChangeDelivery(queryParams.delivery);
        // }
    }
    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.step === 1 && prevState.step === 2) {
            const { me } = this.props;
            const selectedAddressAvailable = this.state.selectedAddress;
            const { deliveryAddressesFull } = me;
            const addresses = addressesToSelect(deliveryAddressesFull);
            const selectedAddress = selectedAddressAvailable || this.getDefaultSelectedDeliveryAddress();
            const deliveryAddress = this.getDeliveryAddress(selectedAddress);
            this.updateAddressState({
                addresses,
                selectedAddress,
                deliveryAddress
            });
        }
    };
    getDefaultSelectedDeliveryAddress = () => {
        const { me } = this.props;
        const { deliveryAddressesFull } = me;
        const queryParams = pathOr([], ['match', 'location', 'query'], this.props);
        const countriesFromQuery = filter((item) => item.address.countryCode === queryParams.country, deliveryAddressesFull || []);
        const countryFromQuery = isEmpty(countriesFromQuery)
            ? null
            : head(countriesFromQuery);
        const addresses = addressesToSelect(deliveryAddressesFull);
        return !isNil(countryFromQuery)
            ? find(propEq(countryFromQuery.id, 'id'))(addresses)
            : find(propEq('0', 'id'))(addresses) || head(addresses) || null;
    };
    getDeliveryAddress = (selectedAddress) => {
        if (!selectedAddress) {
            return emptyAddressFull;
        }
        const { deliveryAddressesFull } = this.props.me;
        const newDeliveryAddresses = filter((newAddressItem) => Boolean(newAddressItem.needed), map((addressItem) => ({
            ...addressItem,
            needed: (selectedAddress
                && selectedAddress.id === '0'
                && addressItem.isPriority)
                || (selectedAddress && selectedAddress.id === addressItem.id)
        }), deliveryAddressesFull));
        if (head(newDeliveryAddresses)) {
            return head(newDeliveryAddresses).address;
        }
        return emptyAddressFull;
    };
    updateAddressState = (data) => {
        const { addresses, selectedAddress, deliveryAddress } = data;
        this.setState({
            addresses,
            selectedAddress,
            deliveryAddress
        });
    };
    handleChangeReceiver = (e) => {
        this.setState({ receiverName: e.target.value });
    };
    handleChangePhone = (e) => {
        const { value } = e.target;
        if (!/^\+?\d*$/.test(value)) {
            return;
        }
        this.setState({ phone: value });
    };
    handleOnSelectAddress = (item) => {
        this.setState({
            selectedAddress: item,
            deliveryAddress: this.getDeliveryAddress(item)
        });
    };
    handleOnChangeAddressType = (id) => {
        if (id === 'newAddressCheckbox') {
            this.setState({
                selectedAddress: null,
                deliveryAddress: emptyAddressFull
            });
            return;
        }
        const selectedAddress = this.getDefaultSelectedDeliveryAddress();
        this.setState((prevState) => {
            if (prevState.selectedAddress) {
                return {
                    selectedAddress: prevState.selectedAddress,
                    deliveryAddress: this.getDeliveryAddress(prevState.selectedAddress)
                };
            }
            return {
                selectedAddress: selectedAddress || null,
                deliveryAddress: this.getDeliveryAddress(selectedAddress)
            };
        });
    };
    handleChangeSaveCheckbox = () => {
        this.setState((prevState) => ({ saveAsNewAddress: !prevState.saveAsNewAddress }));
    };
    handleChangeData = (addressFullData) => {
        this.setState({ deliveryAddress: addressFullData });
    };
    goToCheckout = () => {
        // const { errors } = this.state;
        this.setState({ errors: {} });
        const preValidationErrors = this.validate();
        if (!isEmpty(preValidationErrors)) {
            this.setState({ errors: preValidationErrors });
            return;
        }
        const { saveAsNewAddress, selectedAddress } = this.state;
        if (saveAsNewAddress && !selectedAddress) {
            this.createAddress();
        }
        const handleFetchBuyNow = () => {
            const { deliveryAddress } = this.state;
            const queryParams = pathOr([], ['match', 'location', 'query'], this.props);
            this.setState({ isLoadingCheckout: true });
            const variables = {
                productId: parseInt(queryParams.variant, 10),
                quantity: parseInt(queryParams.quantity, 10)
            };
            fetchBuyNow(this.props.relay.environment, deliveryAddress && deliveryAddress.countryCode === queryParams.country
                ? assoc('shippingId', parseInt(queryParams.delivery, 10), variables)
                : variables)
                .toPromise()
                .then(({ calculateBuyNow }) => {
                const { couponsDiscounts, totalCost, totalCostWithoutDiscounts, totalCount, deliveryCost, subtotalWithoutDiscounts, subtotal } = calculateBuyNow;
                this.setState({
                    step: 2,
                    isLoadingCheckout: false,
                    buyNowData: {
                        couponsDiscounts,
                        totalCost,
                        totalCostWithoutDiscounts,
                        totalCount,
                        deliveryCost,
                        subtotalWithoutDiscounts,
                        subtotal
                    }
                });
                return true;
            })
                .catch(() => {
                this.props.showAlert({
                    type: 'danger',
                    text: 'Something going wrong :(',
                    link: { text: 'Close.' }
                });
                this.setState({ isLoadingCheckout: false });
            });
        };
        const { me } = this.props;
        if (me != null && me.phone !== this.state.phone) {
            updateUserPhoneMutation({
                environment: this.props.relay.environment,
                variables: {
                    input: {
                        clientMutationId: uuidv4(),
                        id: me.id,
                        phone: this.state.phone
                    }
                }
            })
                .then(() => {
                handleFetchBuyNow();
                return true;
            })
                .catch((err) => {
                const relayErrors = fromRelayError({ source: { errors: [{ data: err }] } });
                const validationErrors = pathOr({}, ['100', 'messages'], relayErrors);
                if (!isEmpty(validationErrors)) {
                    this.setState({ errors: validationErrors });
                }
            });
            return;
        }
        handleFetchBuyNow();
    };
    validate = () => {
        const { deliveryAddress } = this.state;
        let { errors } = validate({
            receiverName: [[(val) => Boolean(val), t.errors.receiverNameRequired]],
            phone: [[(val) => Boolean(val), t.errors.receiverPhoneRequired]]
        }, this.state);
        if (!deliveryAddress.country
            || !deliveryAddress.postalCode
            || !deliveryAddress.value) {
            const errorString = `
        ${!deliveryAddress.country ? t.errors.country : ''}
        ${!deliveryAddress.value ? `, ${t.errors.address}` : ''}
        ${!deliveryAddress.postalCode ? `, ${t.errors.postalCode}` : ''}
        ${t.errors.areRequired}
      `;
            errors = {
                ...errors,
                deliveryAddress: [
                    errorString.replace(/^(\s+)?,\s+/, '')
                        .replace(/\s+,\s+/g, ', ')
                ]
            };
        }
        if (errors && !isEmpty(errors)) {
            const { scrollArr } = this.state;
            const oneArr = filter((item) => includes(item, keys(errors)), scrollArr);
            if (!isEmpty(oneArr) && head(oneArr)) {
                smoothscroll.scrollTo(head(oneArr));
            }
        }
        return errors || {};
    };
    replaceAddress = () => {
        this.setState({ step: 1 });
    };
    handleCheckout = () => {
        this.setState({ isLoadingCheckout: true });
        const { deliveryAddress, receiverName, phone, successCouponCodeValue, deliveryPackage, currency } = this.state;
        const queryParams = pathOr([], ['match', 'location', 'query'], this.props);
        let input = {
            clientMutationId: uuidv4(),
            productId: parseInt(queryParams.variant, 10),
            quantity: parseInt(queryParams.quantity, 10),
            addressFull: deliveryAddress,
            receiverName,
            receiverPhone: phone,
            currency,
            shippingId: deliveryPackage ? deliveryPackage.shippingId : null
        };
        if (successCouponCodeValue) {
            input = {
                ...input,
                couponCode: successCouponCodeValue
            };
        }
        const params = {
            variables: { input },
            environment: this.props.relay.environment,
            onCompleted: (response, errors) => {
                this.setState({ isLoadingCheckout: false });
                debug('Success for BuyNowMutation');
                if (response && response.buyNow) {
                    debug('Response: ', response);
                    this.props.showAlert({
                        type: 'success',
                        text: t.orderSuccessfullyCreated,
                        link: { text: t.close }
                    });
                    const responseOrders = pathOr(null, ['invoice', 'orders'], response.buyNow);
                    const order = responseOrders[0];
                    this.props.router.push(`/profile/orders/${order.slug}/payment-info`);
                }
                else if (!errors) {
                    this.props.showAlert({
                        type: 'danger',
                        text: t.error,
                        link: { text: t.close }
                    });
                }
                else {
                    debug('Errors: ', errors);
                    this.props.showAlert({
                        type: 'danger',
                        text: t.error,
                        link: { text: t.close }
                    });
                }
            },
            onError: (err) => {
                this.setState({ isLoadingCheckout: false });
                error('Error in BuyNowMutation');
                error(err);
                this.props.showAlert({
                    type: 'danger',
                    text: t.somethingWentWrong,
                    link: { text: t.close }
                });
            }
        };
        BuyNowMutation.commit(params);
    };
    createAddress = () => {
        const userId = pathOr(null, ['me', 'rawId'], this.props);
        const params = {
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    userId,
                    addressFull: this.state.deliveryAddress,
                    isPriority: false
                }
            },
            environment: this.props.relay.environment,
            onCompleted: (response, errors) => {
                debug({
                    response,
                    errors
                });
                if (errors) {
                    this.props.showAlert({
                        type: 'danger',
                        text: t.somethingGoingWrongNewAddressWasNotCreated,
                        link: { text: t.close }
                    });
                    return;
                }
                this.props.showAlert({
                    type: 'success',
                    text: t.addressCreated,
                    link: { text: '' }
                });
            },
            onError: (err) => {
                error(err);
                this.props.showAlert({
                    type: 'danger',
                    text: t.somethingGoingWrongNewAddressWasNotCreated,
                    link: { text: t.close }
                });
            }
        };
        CreateUserDeliveryAddressFullMutation.commit(params);
    };
    handleChangeCount = (quantity) => {
        if (this.state.changeCountLoading) {
            return;
        }
        this.setState({ changeCountLoading: true });
        const queryParams = pathOr([], ['match', 'location', 'query'], this.props);
        const { successCouponCodeValue, deliveryPackage } = this.state;
        const variables = {
            productId: parseInt(queryParams.variant, 10),
            quantity,
            couponCode: successCouponCodeValue || null,
            shippingId: deliveryPackage ? deliveryPackage.shippingId : null
        };
        fetchBuyNow(this.props.relay.environment, variables)
            .toPromise()
            .then((calculateBuyNow) => {
            const { couponsDiscounts, totalCost, totalCostWithoutDiscounts, totalCount, deliveryCost, subtotalWithoutDiscounts, subtotal } = calculateBuyNow;
            this.setState({
                buyNowData: {
                    couponsDiscounts,
                    totalCost,
                    totalCostWithoutDiscounts,
                    totalCount,
                    deliveryCost,
                    subtotalWithoutDiscounts,
                    subtotal
                },
                changeCountLoading: false
            }, () => {
                this.props.router.replace(`/buy-now?product=${queryParams.product}&variant=${queryParams.variant}&quantity=${quantity}`);
            });
            return true;
        })
            .catch(() => {
            this.props.showAlert({
                type: 'danger',
                text: t.somethingWentWrong,
                link: { text: t.close }
            });
            this.setState({ changeCountLoading: false });
        });
    };
    handleChangeCoupon = (e) => {
        const value = toUpper(e.target.value);
        if (!/^[A-Za-z0-9]*$/.test(value)) {
            return;
        }
        this.setState({
            couponCodeValue: toUpper(value),
            couponCodeButtonDisabled: !value
        });
    };
    handleSetCoupon = () => {
        this.setState({ isLoadingCouponButton: true });
        const queryParams = pathOr([], ['match', 'location', 'query'], this.props);
        const { couponCodeValue, successCouponCodeValue, deliveryPackage } = this.state;
        const variables = {
            productId: parseInt(queryParams.variant, 10),
            quantity: parseInt(queryParams.quantity, 10),
            couponCode: couponCodeValue,
            shippingId: deliveryPackage ? deliveryPackage.shippingId : null
        };
        fetchBuyNow(this.props.relay.environment, variables)
            .toPromise()
            .then((calculateBuyNow) => {
            if (!calculateBuyNow) {
                throw new Error(t.couponNotFound);
            }
            if (couponCodeValue === successCouponCodeValue) {
                throw new Error(t.couponAlreadyApplied);
            }
            const { couponsDiscounts, totalCost, totalCostWithoutDiscounts, totalCount, deliveryCost, subtotalWithoutDiscounts, subtotal } = calculateBuyNow;
            this.setState({
                buyNowData: {
                    couponsDiscounts,
                    totalCost,
                    totalCostWithoutDiscounts,
                    totalCount,
                    deliveryCost,
                    subtotalWithoutDiscounts,
                    subtotal
                },
                successCouponCodeValue: couponCodeValue,
                isLoadingCouponButton: false
            }, () => {
                this.props.showAlert({
                    type: 'success',
                    text: t.couponApplied,
                    link: { text: '' }
                });
            });
            return true;
        })
            .catch((err) => {
            this.props.showAlert({
                type: 'danger',
                text: `${err}`,
                link: { text: t.close }
            });
            this.setState({ isLoadingCouponButton: false });
        });
    };
    handleDeleteProduct = () => {
        const storeId = pathOr(null, ['baseProduct', 'store', 'rawId'], this.props);
        const queryParams = pathOr([], ['match', 'location', 'query'], this.props);
        this.props.router.push(`/store/${storeId}/products/${queryParams.product}`);
    };
    handleChangeDelivery = (pkg) => {
        if (!pkg) {
            return Promise.resolve(true);
        }
        const queryParams = pathOr([], ['match', 'location', 'query'], this.props);
        const { successCouponCodeValue } = this.state;
        const variables = {
            productId: parseInt(queryParams.variant, 10),
            quantity: parseInt(queryParams.quantity, 10),
            couponCode: successCouponCodeValue || null,
            shippingId: pkg.shippingId
        };
        return fetchBuyNow(this.props.relay.environment, variables)
            .toPromise()
            .then((calculateBuyNow) => {
            if (!calculateBuyNow) {
                throw new Error();
            }
            const { couponsDiscounts, totalCost, totalCostWithoutDiscounts, totalCount, deliveryCost, subtotalWithoutDiscounts, subtotal } = calculateBuyNow;
            this.setState({
                buyNowData: {
                    couponsDiscounts,
                    totalCost,
                    totalCostWithoutDiscounts,
                    totalCount,
                    deliveryCost,
                    subtotalWithoutDiscounts,
                    subtotal
                },
                deliveryPackage: pkg
            }, () => {
                this.props.showAlert({
                    type: 'success',
                    text: t.deliveryApplied,
                    link: { text: '' }
                });
            });
            return Promise.resolve(true);
        })
            .catch(() => {
            this.props.showAlert({
                type: 'danger',
                text: t.somethingWentWrong,
                link: { text: t.close }
            });
            return Promise.reject();
        });
    };
    handlePackagesFetched = (packages) => {
        const queryParams = pathOr([], ['match', 'location', 'query'], this.props);
        if (queryParams.delivery) {
            const deliveryPackage = find(propEq(parseInt(queryParams.delivery, 10), 'shippingId'))(packages);
            this.setState({ deliveryPackage: deliveryPackage || null });
        }
    };
    render() {
        const { me, baseProduct } = this.props;
        const { step, receiverName, phone, selectedAddress, addresses, deliveryAddress, saveAsNewAddress, buyNowData, couponCodeValue, couponCodeButtonDisabled, isLoadingCouponButton, isLoadingCheckout, errors, deliveryPackage, currency } = this.state;
        const queryParams = pathOr([], ['match', 'location', 'query'], this.props);
        const variants = pathOr([], ['variants', 'all'], baseProduct);
        const variant = find(propEq(parseInt(queryParams.variant, 10), 'rawId'))(variants);
        const productName = getNameText(baseProduct.name, 'EN');
        const isEmptyDeliveryAddressesFull = isEmpty(me.deliveryAddressesFull);
        return (React.createElement("div", { className: styles.container },
            React.createElement(Container, { withoutGrow: true },
                React.createElement(Row, { withoutGrow: true },
                    React.createElement(Col, { size: 12 },
                        React.createElement("div", { className: styles.headerWrapper },
                            React.createElement(Header, { currentStep: step, isReadyToNext: false, onChangeStep: () => {
                                } }))),
                    React.createElement(Col, { size: 12, lg: step !== 3 ? 8 : 12, xl: step !== 3 ? 9 : 12 },
                        step === 1 && (React.createElement("div", { className: styles.body },
                            React.createElement("div", { className: styles.addressWrap },
                                React.createElement(Container, { correct: true },
                                    React.createElement(Row, null,
                                        React.createElement(Col, { size: 12, xl: 6 },
                                            React.createElement("div", { className: styles.address },
                                                React.createElement(Row, null,
                                                    React.createElement(Col, { size: 12 },
                                                        React.createElement("div", { className: styles.title }, t.deliveryInfo),
                                                        React.createElement("div", { className: styles.receiverItem },
                                                            React.createElement(Input, { fullWidth: true, id: "receiverName", label: (React.createElement("span", null,
                                                                    t.labelReceiverName,
                                                                    ' ',
                                                                    React.createElement("span", { className: styles.red }, "*"))), onChange: this.handleChangeReceiver, value: receiverName, limit: 50, errors: errors.receiverName })),
                                                        React.createElement("div", { className: styles.receiverItem },
                                                            React.createElement(Input, { fullWidth: true, id: "phone", label: (React.createElement("span", null,
                                                                    `${t.labelReceiverPhone} `,
                                                                    React.createElement("span", { className: styles.red }, "*"))), onChange: this.handleChangePhone, value: phone, errors: errors.phone })),
                                                        !isEmptyDeliveryAddressesFull && (React.createElement("div", { className: styles.selectItem },
                                                            React.createElement(RadioButton, { id: "existingAddressCheckbox", label: t.labelChooseYourAddress, isChecked: selectedAddress !== null, onChange: this.handleOnChangeAddressType }),
                                                            selectedAddress !== null && (React.createElement("div", { className: styles.select },
                                                                React.createElement(Select, { label: t.labelAddress, items: addresses, activeItem: selectedAddress, onSelect: this.handleOnSelectAddress, forForm: true, containerStyle: { width: '26rem' }, dataTest: "selectExistingAddress" })))))),
                                                    React.createElement(Col, { size: 12, sm: 9, md: 8, xl: 12 },
                                                        React.createElement("div", { className: classNames(styles, 'addressFormWrap', { isEmptyDeliveryAddressesFull }) },
                                                            !isEmptyDeliveryAddressesFull && (React.createElement(RadioButton, { id: "newAddressCheckbox", label: t.labelOrFillFieldsBelowAndSaveAsAddress, isChecked: selectedAddress === null, onChange: this.handleOnChangeAddressType })),
                                                            selectedAddress === null && (React.createElement(React.Fragment, null,
                                                                React.createElement("div", { id: "deliveryAddress", className: styles.addressForm },
                                                                    React.createElement(AddressForm, { isOpen: true, onChangeData: this.handleChangeData, country: deliveryAddress.country, address: deliveryAddress.value, addressFull: deliveryAddress }),
                                                                    React.createElement("div", { className: styles.addressError }, errors.deliveryAddress
                                                                        ? head(errors.deliveryAddress)
                                                                        : '')),
                                                                React.createElement("div", { className: styles.saveAddressCheckbox },
                                                                    React.createElement(Checkbox, { id: "saveAddressCheckbox", label: t.labelSaveAsANewAddress, isChecked: saveAsNewAddress, onChange: this.handleChangeSaveCheckbox }))))))))),
                                        React.createElement(Col, { size: 12, xl: 6 }, deliveryAddress
                                            && deliveryAddress.country && (React.createElement("div", { className: styles.addressInfo },
                                            React.createElement(AddressInfo, { addressFull: deliveryAddress, receiverName: receiverName, email: me.email }))))))))),
                        step === 2 && (React.createElement("div", { className: styles.body },
                            React.createElement("div", { className: styles.otherWrap },
                                React.createElement("div", { className: styles.products },
                                    React.createElement(CheckoutProducts, { addressFull: deliveryAddress, receiverName: receiverName, email: me.email, replaceAddress: this.replaceAddress })),
                                React.createElement("div", { className: styles.store },
                                    React.createElement(CartStore, { product: variant, productName: productName, store: baseProduct.store, buyNowData: buyNowData, onChangeCount: this.handleChangeCount, couponCodeValue: couponCodeValue, couponCodeButtonDisabled: couponCodeButtonDisabled, isLoadingCouponButton: isLoadingCouponButton, handleChangeCoupon: this.handleChangeCoupon, handleSetCoupon: this.handleSetCoupon, onDeleteProduct: this.handleDeleteProduct, country: deliveryAddress.country, isShippingAvailable: baseProduct.isShippingAvailable, baseProductId: baseProduct.rawId, onChangeDelivery: this.handleChangeDelivery, deliveryPackage: deliveryPackage, onPackagesFetched: this.handlePackagesFetched, currency: currency })))))),
                    React.createElement(Col, { size: 12, lg: 4, xl: 3 },
                        React.createElement(StickyBar, null,
                            React.createElement(CheckoutSidebar, { step: step, buyNowData: buyNowData, goToCheckout: this.goToCheckout, isLoadingCheckout: isLoadingCheckout, onCheckout: this.handleCheckout, shippingId: deliveryPackage ? deliveryPackage.shippingId : null, currency: currency })))))));
    }
}
export default createFragmentContainer(withShowAlert(Page(BuyNow)), {
    baseProduct: graphql `
			fragment BuyNow_baseProduct on BaseProduct {
				id
				rawId
				categoryId
				isShippingAvailable
				name {
					text
					lang
				}
				shortDescription {
					text
					lang
				}
				longDescription {
					text
					lang
				}
				store {
					rawId
					name {
						lang
						text
					}
					rating
					logo
				}
				rating
				currency
				variants {
					all {
						id
						rawId
						photoMain
						additionalPhotos
						price
						preOrder
						preOrderDays
						cashback
						discount
						quantity
						attributes {
							value
							metaField
							attribute {
								id
								name {
									text
									lang
								}
								metaField {
									values
									uiElement
								}
							}
						}
					}
				}
			}
    `
});
