import React, { PureComponent } from 'react';
import { isNil } from 'ramda';
import { off, on } from '../../utils';
import { Icon } from '../Icon';
import Portal from './Portal';
import styles from './Modal.module.scss';
// type StateTypes = {
//   showModal? :boolean,
// };
class Modal extends PureComponent {
    static defaultProps = { render: undefined };
    UNSAFE_componentWillMount() {
        // this.setState({ showModal: this.props.showModal });
        if (process.env.BROWSER) {
            window.addEventListener('keydown', this.handleKeydown);
        }
    }
    /**
     TODO: momentary comment while is fixed when the Modal is already UNMOUNT!
     */
    // componentWillReceiveProps(nextProps: Object) {
    //   const { showModal } = nextProps;
    //   if (this.props.showModal !== showModal) {
    //     this.setState({ showModal }, () => {
    //       this.toggleScroll(showModal);
    //     });
    //   }
    // }
    componentWillUnmount() {
        if (process.env.BROWSER) {
            window.removeEventListener('keydown', this.handleKeydown);
        }
    }
    onCloseModal = () => {
        this.props.onClose();
    };
    toggleScroll = (showModal) => {
        if (showModal) {
            on();
        }
        else {
            off();
        }
    };
    handleKeydown = (e) => {
        if (e.key === 'Escape') {
            this.props.onClose();
        }
    };
    handleClick = (e) => {
        const { onClose } = this.props;
        const { id } = e.target;
        if (id === 'overlay' || id === 'inner') {
            onClose();
        }
    };
    render() {
        if (!this.props.showModal) {
            return null;
        }
        const closeButton = (React.createElement("div", { className: styles.close, onClick: this.onCloseModal, onKeyDown: () => {
            }, role: "button", tabIndex: 0 },
            React.createElement(Icon, { type: "cross" })));
        return (React.createElement(Portal, null,
            React.createElement("div", { className: styles.container },
                React.createElement("div", { id: "overlay", onClick: this.handleClick, onKeyDown: () => {
                    }, role: "button", className: styles.wrap, tabIndex: 0 },
                    React.createElement("div", { id: "inner", className: styles.inner }, isNil(this.props.render) ? (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: styles.body },
                            closeButton,
                            this.props.children))) : (this.props.render()))))));
    }
}
export default Modal;
