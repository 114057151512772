import { commitMutation, graphql } from 'react-relay';
const mutation = graphql `
	mutation UpdateBaseProductMutation($input: UpdateBaseProductInput!) {
		updateBaseProduct(input: $input) {
			id
			rawId
			category {
				rawId
			}
			store {
				id
				rawId
			}
			name {
				lang
				text
			}
			shortDescription {
				lang
				text
			}
			longDescription {
				lang
				text
			}
			seoTitle {
				lang
				text
			}
			seoDescription {
				lang
				text
			}
			storeId
			currency
			status
			products(first: null) @connection(key: "Wizard_products") {
				edges {
					node {
						id
						rawId
						price
						discount
						photoMain
						additionalPhotos
						vendorCode
						cashback
						price
						attributes {
							value
							metaField
							attribute {
								id
								rawId
								name {
									lang
									text
								}
								metaField {
									values
									translatedValues {
										translations {
											text
										}
									}
								}
							}
						}
					}
				}
			}
			lengthCm
			widthCm
			heightCm
			weightG
		}
	}
`;
const commit = (params) => commitMutation(params.environment, {
    mutation,
    variables: params.variables,
    onCompleted: params.onCompleted,
    onError: params.onError
});
const promise = (variables, environment) => new Promise((resolve, reject) => {
    commit({
        variables,
        environment,
        onCompleted: (response, errors) => {
            if (response) {
                resolve(response);
            }
            else if (errors) {
                reject(errors);
            }
            else {
                reject([new Error('Unknown error')]);
            }
        },
        onError: (error) => {
            reject([error]);
        }
    });
});
export default {
    commit,
    promise
};
