import React, { PureComponent } from 'react';
import { createPaginationContainer, graphql } from 'react-relay';
import { Col, Container, Row } from '../../../layout';
import { withErrorBoundary } from '../../../components/common/ErrorBoundaries';
import { Page } from '../../../components/App';
import SearchSidebar from '../SearchSidebar';
import { SearchContent, SearchMobileMenu } from '../index';
import styles from './Categories.module.scss';
const productsPerRequest = 24;
class Categories extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { isOpen: false };
    }
    handleOpen = () => {
        this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
    };
    render() {
        const { search, relay } = this.props;
        const { isOpen } = this.state;
        return (React.createElement("div", { className: styles.container },
            React.createElement(SearchMobileMenu, { isOpen: isOpen, onClose: this.handleOpen },
                React.createElement(SearchSidebar, { isOpen: isOpen, onClose: this.handleOpen, search: search })),
            React.createElement(Container, null,
                React.createElement(Row, { noWrap: true },
                    React.createElement("div", { className: styles.sidebarWrapper },
                        React.createElement(SearchSidebar, { isOpen: true, search: search })),
                    React.createElement(Col, { sm: 12, md: 12, lg: 10, xl: 10 },
                        React.createElement(SearchContent, { onFilterMenu: this.handleOpen, productsPerRequest: productsPerRequest, relay: relay, search: search }))))));
    }
}
export default createPaginationContainer(withErrorBoundary(Page(Categories)), {
    search: graphql `
			fragment Categories_search on Search
			@argumentDefinitions(
				text: { type: "SearchProductInput!" }
				first: { type: "Int", defaultValue: 24 }
				after: { type: "ID", defaultValue: null }
			) {
				findProduct(searchTerm: $text, first: $first, after: $after)
				@connection(key: "Categories_findProduct") {
					pageInfo {
						searchFilters {
							categories {
								rawId
								level
								parentId
								name {
									text
									lang
								}
								children {
									rawId
									level
									parentId
									name {
										text
										lang
									}
									children {
										rawId
										level
										parentId
										name {
											text
											lang
										}
										children {
											rawId
											level
											parentId
											name {
												text
												lang
											}
										}
									}
								}
							}
							priceRange {
								minValue
								maxValue
							}
							attrFilters {
								attribute {
									id
									rawId
									name {
										text
										lang
									}
									metaField {
										uiElement
									}
								}
								equal {
									values
								}
								range {
									minValue
									maxValue
								}
							}
						}
					}
					edges {
						node {
							id
							rawId
							currency
							name {
								text
								lang
							}
							store {
								name {
									lang
									text
								}
							}
							category {
								rawId
							}
							storeId
							products(first: 1) {
								edges {
									node {
										id
										rawId
										discount
										photoMain
										cashback
										price
										customerPrice {
											price
											currency
										}
										attributes {
											attribute {
												id
											}
											value
										}
									}
								}
							}
							rating
						}
					}
				}
			}
    `
}, {
    direction: 'forward',
    getConnectionFromProps: (props) => props.search && props.search.findProduct,
    getVariables: (props, _, prevFragmentVars) => ({
        text: prevFragmentVars.text,
        first: productsPerRequest,
        after: props.search.findProduct.pageInfo.endCursor
    }),
    query: graphql `
			query Categories_edges_Query(
				$first: Int
				$after: ID
				$text: SearchProductInput!
			) {
				search {
					...Categories_search
					@arguments(first: $first, after: $after, text: $text)
				}
			}
    `
});
