import React, { Component } from 'react';
import { addIndex, filter, forEach, head, includes, isEmpty, map, prepend } from 'ramda';
import { convertCountriesForSelect, convertCountriesToArrCodes, convertInterAvailablePackages, getCountries, getService, getServiceLogo, getServiceRawId } from '../utils';
export default (OriginalComponent) => class HandlerShippingDecorator extends Component {
    constructor(props) {
        super(props);
        const { interShipping, onChangeShippingData } = props;
        const companies = addIndex(map)((item, idx) => this.makeCompany(item, idx), interShipping);
        const checkedCompanies = filter((item) => item.companyPackageRawId !== -1, companies || []);
        const shippingData = {
            companies: checkedCompanies,
            inter: true,
            withoutInter: Boolean(isEmpty(checkedCompanies))
        };
        onChangeShippingData(shippingData);
        const remainingServices = this.setRemainingServices(checkedCompanies);
        this.state = {
            companies: checkedCompanies,
            editableItemId: null,
            remainingServices
        };
    }
    setRemainingServices = (companies) => {
        let defaultServices = convertInterAvailablePackages(this.props.interAvailablePackages);
        forEach((company) => {
            defaultServices = map((service) => {
                if (company.service && company.service.id === service.id) {
                    const { countries } = service;
                    const companyCountriesArr = convertCountriesToArrCodes({ countries: company.countries });
                    const newContinentsChildren = map((continent) => {
                        const newCountriesChildren = filter((country) => !includes(country.alpha3, companyCountriesArr), continent.children);
                        return {
                            ...continent,
                            children: newCountriesChildren
                        };
                    }, countries ? countries.children : []);
                    const filteredNewContinentsChildren = filter((item) => !isEmpty(item.children), newContinentsChildren);
                    return {
                        ...service,
                        countries: {
                            ...countries,
                            children: filteredNewContinentsChildren
                        }
                    };
                }
                return service;
            }, defaultServices);
        }, companies);
        const filteredDefaultServices = filter((item) => !isEmpty(item.countries ? item.countries.children : []), defaultServices);
        return filteredDefaultServices;
    };
    handleOnSaveCompany = (company) => {
        if (company.id !== undefined) {
            this.setState((prevState) => {
                const { service } = company;
                const newCompany = {
                    ...company,
                    companyPackageRawId: getServiceRawId({
                        id: company.service && company.service.id,
                        packages: this.props.interAvailablePackages
                    }),
                    logo: getServiceLogo({
                        id: company.service && company.service.id,
                        packages: this.props.interAvailablePackages
                    }),
                    service: service
                        ? {
                            id: service.id,
                            label: service.label,
                            currency: service.currency
                        }
                        : null
                };
                const newCompanies = map((item) => (item.id === company.id ? newCompany : item), prevState.companies);
                const remainingServices = this.setRemainingServices(newCompanies);
                return {
                    companies: newCompanies,
                    remainingServices,
                    editableItemId: null
                };
            }, () => {
                this.props.onChangeShippingData({
                    companies: this.state.companies,
                    inter: true
                });
            });
        }
        else {
            this.setState((prevState) => {
                const { service } = company;
                const newCompany = {
                    ...company,
                    id: `${Date.now()}`,
                    companyPackageRawId: getServiceRawId({
                        id: company.service && company.service.id,
                        packages: this.props.interAvailablePackages
                    }),
                    logo: getServiceLogo({
                        id: company.service && company.service.id,
                        packages: this.props.interAvailablePackages
                    }),
                    service: service
                        ? {
                            id: service.id,
                            label: service.label,
                            currency: service.currency
                        }
                        : null
                };
                const newCompanies = prepend(newCompany, prevState.companies);
                const remainingServices = this.setRemainingServices(newCompanies);
                return {
                    companies: newCompanies,
                    remainingServices,
                    editableItemId: null
                };
            }, () => {
                this.props.onChangeShippingData({
                    companies: this.state.companies,
                    inter: true
                });
            });
        }
    };
    handleOnRemoveCompany = (company) => {
        this.setState((prevState) => {
            const newCompanies = filter((item) => company.id !== item.id, prevState.companies);
            const remainingServices = this.setRemainingServices(newCompanies);
            return {
                companies: newCompanies,
                remainingServices,
                editableItemId: null
            };
        }, () => {
            this.props.onChangeShippingData({
                companies: this.state.companies,
                inter: true
            });
        });
    };
    handleOnSetEditableItem = (company) => {
        this.setState((prevState) => {
            const newCompanies = filter((item) => company.id !== item.id, prevState.companies);
            const possibleServices = this.setRemainingServices(newCompanies);
            return {
                editableItemId: company.id,
                possibleServices
            };
        });
    };
    handleOnRemoveEditableItem = () => {
        this.setState({ editableItemId: null });
    };
    makeCompany = (data, idx) => {
        const { interAvailablePackages } = this.props;
        const service = getService({
            id: data.companyPackageId,
            packages: interAvailablePackages
        });
        let company = {
            id: `${Date.now()}-${idx}`,
            companyPackageRawId: getServiceRawId({
                id: service && service.id,
                packages: interAvailablePackages
            }),
            price: data.price,
            logo: getServiceLogo({
                id: service && service.id,
                packages: interAvailablePackages
            }),
            service
        };
        const countries = convertCountriesForSelect({
            countries: getCountries({
                id: data.companyPackageId,
                packages: interAvailablePackages
            }),
            checkedCountries: convertCountriesToArrCodes({
                countries: data.deliveriesTo ? head(data.deliveriesTo) : null,
                isSelectedAll: true
            })
        });
        company = {
            ...company,
            countries
        };
        return company;
    };
    render() {
        const { error, interAvailablePackages, interShipping, onChangeShippingData, currency } = this.props;
        const { companies, editableItemId, remainingServices, possibleServices } = this.state;
        return (React.createElement(OriginalComponent, { currency: currency, error: error, companies: companies, editableItemId: editableItemId, remainingServices: remainingServices, possibleServices: possibleServices, onChangeShippingData: onChangeShippingData, onSaveCompany: this.handleOnSaveCompany, onRemoveCompany: this.handleOnRemoveCompany, onSetEditableItem: this.handleOnSetEditableItem, onRemoveEditableItem: this.handleOnRemoveEditableItem, interAvailablePackages: interAvailablePackages, interShipping: interShipping }, this.props.children));
    }
};
