import React, { Component } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { defaultTo, head, path, pathOr, pipe } from 'ramda';
import debounce from 'lodash.debounce';
import { v4 as uuidv4 } from 'uuid';
import { Environment } from 'relay-runtime';
import PropTypes from 'prop-types';
import { Col, Container, Row } from '../../../layout';
import { convertSrc, debug, error } from '../../../utils';
import { withShowAlert } from '../../../components/Alerts/AlertContext';
import { Checkbox } from '../../../components/Checkbox';
import { Icon } from '../../../components/Icon';
import { Confirmation } from '../../../components/Confirmation';
import ProductInfo from '../ProductInfo';
import styles from './CartProduct.module.scss';
import t from './i18n';
import DeleteFromCartMutation from '../../../relay/mutations/DeleteFromCartMutation';
import SetSelectionInCartMutation from '../../../relay/mutations/SetSelectionInCartMutation';
import SetQuantityInCartMutation from '../../../relay/mutations/SetQuantityInCartMutation';
import SetCommentInCartMutation from '../../../relay/mutations/SetCommentInCartMutation';
class CartProduct extends Component {
    static contextTypes = { environment: PropTypes.instanceOf(Environment).isRequired };
    static defaultProps = { withDeliveryCompaniesSelect: false };
    constructor(props) {
        super(props);
        this.state = {
            comment: props.product && props.product.comment,
            showModal: false
        };
    }
    handleDelete = () => {
        const id = this.props.product.rawId;
        DeleteFromCartMutation.commit({
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    productId: id
                }
            },
            environment: this.context.environment,
            onCompleted: (response, errors) => {
                debug(t.successForDeleteFromCart);
                if (response) {
                    debug(t.response, response);
                }
                if (errors) {
                    debug(t.errors, errors);
                    return;
                }
                this.setState({ showModal: false });
            },
            onError: (err) => {
                error(t.errorInDeleteFromCart);
                error(err);
                this.props.showAlert({
                    type: 'danger',
                    text: t.unableToDeleteProductQuantity,
                    link: { text: t.close }
                });
            }
        });
    };
    handleSelectChange() {
        const { rawId: productId, id: nodeId } = this.props.product;
        SetSelectionInCartMutation.commit({
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    productId,
                    value: !this.props.product.selected
                }
            },
            nodeId,
            environment: this.context.environment,
            onCompleted: (response, errors) => {
                debug(t.successForSetSelectionInCart);
                if (response) {
                    debug(t.response, response);
                }
                if (errors) {
                    debug(t.errors, errors);
                }
            },
            onError: (err) => {
                error(t.errorInSetSelectionInCart);
                error(err);
                this.props.showAlert({
                    type: 'danger',
                    text: t.unableToSetProductSelection,
                    link: { text: t.close }
                });
            }
        });
    }
    handleQuantityChange = (newVal) => {
        const { rawId: productId, id: nodeId } = this.props.product;
        const { storeId } = this.props;
        SetQuantityInCartMutation.commit({
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    productId,
                    value: newVal
                }
            },
            nodeId,
            storeId,
            environment: this.context.environment,
            onCompleted: (response, errors) => {
                debug(t.successForSetQuantityInCart);
                if (response) {
                    debug(t.response, response);
                }
                if (errors) {
                    debug(t.errors, errors);
                }
            },
            onError: (err) => {
                error(t.errorInSetQuantityInCart);
                error(err);
                this.props.showAlert({
                    type: 'danger',
                    text: t.unableToSetProductQuantity,
                    link: { text: t.close }
                });
            }
        });
    };
    handleOnChangeComment = (e) => {
        const { rawId: productId } = this.props.product;
        const { target: { value } } = e;
        this.setState({ comment: value });
        this.handleOnSaveComment(productId, value);
    };
    handleOnSaveComment = debounce((productId, value) => {
        if (value) {
            SetCommentInCartMutation.commit({
                variables: {
                    input: {
                        clientMutationId: uuidv4(),
                        productId,
                        value
                    }
                },
                environment: this.context.environment,
                onCompleted: (response, errors) => {
                    debug(t.successForSetCommentInCart);
                    if (response) {
                        debug(t.response, response);
                    }
                    if (errors) {
                        debug(t.errors, errors);
                    }
                },
                onError: (err) => {
                    error(t.errorInSetCommentInCart);
                    error(err);
                    this.props.showAlert({
                        type: 'danger',
                        text: t.unableToSetComment,
                        link: { text: t.close }
                    });
                }
            });
        }
    }, 250);
    handleDeleteModal = () => {
        this.setState({ showModal: true });
    };
    handleCloseModal = () => {
        this.setState({ showModal: false });
    };
    render() {
        const { product, unselectable, isOpenInfo, withDeliveryCompaniesSelect, currency } = this.props;
        if (!product) {
            return null;
        }
        const name = pipe(pathOr([], ['name']), head, defaultTo({}), path(['text']))(product);
        debug('CartProduct', this.props);
        const { photoMain, selected } = product;
        const { showModal } = this.state;
        return (React.createElement("div", { className: styles.container },
            React.createElement(Container, { correct: true },
                React.createElement(Confirmation, { showModal: showModal, onClose: this.handleCloseModal, title: t.deleteYourProduct, description: t.confirmationDescription, onCancel: this.handleCloseModal, onConfirm: this.handleDelete, confirmText: t.confirmText, cancelText: t.cancelText }),
                React.createElement(Row, null,
                    React.createElement(Col, { size: 12, sm: 3 },
                        React.createElement(Row, null,
                            React.createElement(Col, { size: 4, sm: 12 },
                                React.createElement("div", { className: styles.leftContainer },
                                    !unselectable && (React.createElement("div", { className: styles.checkbox },
                                        React.createElement(Checkbox, { id: `Cartproduct_${product.rawId}`, label: false, isChecked: selected, onChange: () => this.handleSelectChange() }))),
                                    photoMain ? (React.createElement("div", { className: styles.picture, style: {
                                            backgroundImage: `url(${convertSrc('medium', photoMain)})`
                                        } })) : (React.createElement("div", { className: styles.noLogo },
                                        React.createElement(Icon, { type: "camera", size: 40 }))))),
                            React.createElement(Col, { size: 6, smHidden: true },
                                React.createElement("div", { className: styles.productSummaryHeader }, name)),
                            React.createElement(Col, { size: 2, smHidden: true },
                                React.createElement("div", { className: styles.recycleContainer },
                                    React.createElement("button", { className: styles.recycle, onClick: () => this.handleDeleteModal(), "data-test": "cartProductDeleteButton" },
                                        React.createElement(Icon, { type: "basket", size: 32 })))))),
                    React.createElement(Col, { size: 12, sm: 9 },
                        React.createElement(Row, { withoutGrow: true },
                            React.createElement(Col, { size: 10, sm: 11, hidden: true, smVisible: true },
                                React.createElement("div", { className: styles.productSummaryHeader }, name)),
                            React.createElement(Col, { size: 2, sm: 1, hidden: true, smVisible: true },
                                React.createElement("div", { className: styles.recycleContainer },
                                    React.createElement("button", { className: styles.recycle, onClick: () => this.handleDeleteModal(), "data-test": "cartProductDeleteButton" },
                                        React.createElement(Icon, { type: "basket", size: 32 })))),
                            React.createElement(Col, { size: 12 },
                                React.createElement("div", { className: styles.productInfoWrapper },
                                    React.createElement(ProductInfo, { product: product, onQuantityChange: this.handleQuantityChange, onChangeComment: this.handleOnChangeComment, comment: this.state.comment, isOpen: isOpenInfo, withDeliveryCompaniesSelect: withDeliveryCompaniesSelect, currency: currency })))))))));
    }
}
export default createFragmentContainer(withShowAlert(CartProduct), {
    product: graphql `
			fragment CartProduct_product on CartProduct {
				id
				rawId
				baseProduct(visibility: "active") {
					id
					isShippingAvailable
				}
				baseProductId
				subtotal
				subtotalWithoutDiscounts
				couponDiscount
				name {
					lang
					text
				}
				photoMain
				price
				preOrder
				preOrderDays
				quantity
				comment
				selected
				deliveryCost
				attributes {
					value
					metaField
					attribute {
						name {
							lang
							text
						}
						valueType
						metaField {
							values
							uiElement
							translatedValues {
								translations {
									lang
									text
								}
							}
						}
					}
				}
				coupon {
					id
					rawId
					code
					title
					scope
					percent
				}
				companyPackage {
					id
					rawId
				}
				selectPackage {
					id
					shippingId
				}
			}
    `
});
