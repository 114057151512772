import { t } from '../../../../translation/utils';
const translations = {
    en: {
        error: 'Error: ',
        close: 'Close.',
        countryIsRequiredParameter: 'Country is required parameter',
        postalCodeIsRequiredParameter: 'Postal Code is required parameter',
        countryAndPostalCodeIsRequiredParameter: 'Country and Postal Code is required parameter',
        somethingWentWrong: 'Something going wrong :(',
        addressDeleted: 'Address deleted!',
        save: 'Save',
        add: 'Add',
        cancel: 'Cancel',
        shippingAddress: 'Shipping address',
        addAddress: 'Add address',
        savedAddresses: 'Saved addresses',
        priorityAddress: 'Priority address',
        edit: 'Edit',
        delete: 'Delete'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
