export const menuItems = [
    {
        'id': 'orders',
        'title': 'Shop orders',
        'link': '/orders',
        'disabled': false,
        'dataTest': 'store-menu-orders'
    },
    {
        'id': 'finances',
        'title': 'Finances',
        'link': '/finances',
        'disabled': false,
        'dataTest': 'store-menu-orders'
    },
    {
        'id': 'goods',
        'title': 'Goods',
        'link': '/products',
        'disabled': false,
        'dataTest': 'store-menu-goods'
    },
    {
        'id': 'storages',
        'title': 'Storages',
        'link': '/storages',
        'disabled': false,
        'dataTest': 'store-menu-storages'
    },
    {
        'id': 'settings',
        'title': 'Settings',
        'link': '/',
        'disabled': false,
        'dataTest': 'store-menu-settings'
    },
    {
        'id': 'contacts',
        'title': 'Contacts',
        'link': '/contacts',
        'disabled': false,
        'dataTest': 'store-menu-contacts'
    }
];
