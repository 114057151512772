import Cookies from 'universal-cookie';
import { pathOr } from 'ramda';
import { error, info } from './log';
import { jwtCookieName } from './jwt';
export function uploadFile(file) {
    if (!file) {
        return Promise.reject(new Error('Please, select file'));
    }
    info(file);
    // 20MB
    if (file.size > 20 * 1024 * 1024) {
        return Promise.reject(new Error('Maximum file size is 20MB'));
    }
    if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
        return Promise.reject(new Error('Only JPEG/PNG allowed'));
    }
    const cookies = new Cookies();
    const jwt = pathOr(null, ['value'], cookies.get(jwtCookieName));
    const body = new FormData();
    body.append('file', file);
    if (!process.env.REACT_APP_STATIC_IMAGES_ENDPOINT || !jwt) {
        return Promise.reject(new Error('Seems that you are not logged in'));
    }
    return fetch(process.env.REACT_APP_STATIC_IMAGES_ENDPOINT, {
        method: 'POST',
        body,
        headers: { Authorization: `Bearer ${String(jwt)}` }
    })
        .then((response) => {
        if (!response.ok) {
            // muted because it used only for logging
            response
                .json()
                .then(error)
                .catch(error);
            return Promise.reject(new Error('There is a problem with uploading the photo'));
        }
        return response.json();
    });
}
