import React, { PureComponent } from 'react';
import { isNil, pathOr } from 'ramda';
import { Col, Container, Row } from '../../../../layout';
import { AppContext } from '../../../../components/App';
import { classNames, uploadFile } from '../../../../utils';
import Menu from '../ManageStoreMenu';
import styles from './ManageStoreDecorator.module.scss';
export default (prop) => {
    const { OriginalComponent, active, title, isNewStore } = prop;
    return class Manage extends PureComponent {
        constructor(props) {
            super(props);
            this.state = {
                newStoreName: null,
                newStoreLogo: null
            };
        }
        handleOnUpload = async (e) => {
            e.preventDefault();
            const file = e.target.files[0];
            const result = await uploadFile(file);
            if (!isNil(result.url)) {
                return;
            }
            this.setState({ newStoreLogo: result.url });
        };
        deleteAvatar = () => {
            this.setState({ newStoreLogo: null });
        };
        handleNewStoreNameChange = (value) => {
            this.setState({ newStoreName: value });
        };
        render() {
            const { newStoreName, newStoreLogo } = this.state;
            let menuProps = {
                activeItem: active,
                title
            };
            let formProps = { ...this.props };
            if (!isNil(isNewStore)) {
                menuProps = {
                    ...menuProps,
                    newStoreLogo,
                    newStoreName,
                    handleOnUpload: this.handleOnUpload,
                    deleteAvatar: this.deleteAvatar
                };
                formProps = {
                    ...formProps,
                    handleNewStoreNameChange: this.handleNewStoreNameChange,
                    newStoreLogo,
                    newStoreName
                };
            }
            return (React.createElement(AppContext.Consumer, null, ({ environment }) => (React.createElement("div", { className: styles.wrapper },
                React.createElement(Container, null,
                    React.createElement(Row, null,
                        React.createElement(Col, { md: 3, lg: 2, xl: 2 },
                            React.createElement(Menu, { ...menuProps, environment: environment })),
                        React.createElement(Col, { md: 9, lg: 10, xl: 10 },
                            React.createElement("div", { className: styles.container },
                                React.createElement("div", { className: styles.header },
                                    React.createElement("span", { className: styles.title }, title)),
                                React.createElement("div", { className: classNames(styles, 'form', { formOrder: pathOr(false, ['match', 'params', 'orderId'], this.props) }) },
                                    React.createElement(OriginalComponent, { environment: environment, ...formProps }))))))))));
        }
    };
};
