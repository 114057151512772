import React from 'react';
import { map } from 'ramda';
import { Col, Row } from '../../layout';
import styles from './StartSellingForSellers.module.scss';
const itemsEn = [
    {
        id: '1',
        title: 'Delivery',
        text: 'Following payment, only two more steps are required of&nbsp;the&nbsp;seller&nbsp;— pack the&nbsp;good and pass&nbsp;it to&nbsp;the&nbsp;delivery man.'
    },
    {
        id: '2',
        title: 'Processing',
        text: 'Each seller has the option to&nbsp;choose the&nbsp;exact mode of payment that will suit&nbsp;him and his business needs.'
    },
    {
        id: '3',
        title: 'Marketing',
        text: 'Your personal Graame manager will help you to&nbsp;develop an&nbsp;effective plan on&nbsp;how to&nbsp;sell goods on&nbsp;the&nbsp;Local market and attract more buyers.'
    }
];
const itemsRu = [
    {
        id: '1',
        title: 'Занимаемся доставкой',
        text: 'После оплаты заказа покупателем вам требуется только наклеить этикетку на&nbsp;товар и&nbsp;отдать его курьеру.'
    },
    {
        id: '2',
        title: 'Обеспечиваем процессинг платежей',
        text: 'Вы можете продавать товары за&nbsp;любую доступную валюту. Все хлопоты по&nbsp;процессингу мы&nbsp;берем на&nbsp;себя.'
    },
    {
        id: '3',
        title: 'Даем маркетинговое сопровождение',
        text: 'Ваш персональный менеджер совместно с&nbsp;отделом маркетинга разработает и&nbsp;поможет реализовать детальный план по&nbsp;продвижению именно ваших товаров на&nbsp;Сингапурском рынке.'
    }
];
const StartSellingForSellers = ({ lang }) => (React.createElement("div", { className: styles.container },
    React.createElement(Row, null,
        React.createElement(Col, { size: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            React.createElement("h2", { className: styles.title, dangerouslySetInnerHTML: {
                    __html: lang === 'hi'
                        ? 'Graame&nbsp;— настоящий швейцарский нож для&nbsp;продавцов'
                        : 'Graame is a&nbsp;real swiss army knife for&nbsp;sellers'
                } }),
            React.createElement("p", { className: styles.subtitle, dangerouslySetInnerHTML: {
                    __html: lang === 'hi'
                        ? 'С нашей помощью вы&nbsp;сможете эффективно управлять продажами по&nbsp;всему миру.'
                        : 'Our service will allow you to&nbsp;efficiently manage sales around the&nbsp;globe and spend less time managing your&nbsp;shop.'
                } }),
            map((item) => (React.createElement("div", { key: item.id, className: styles.item },
                React.createElement("h4", { className: styles.itemTitle, dangerouslySetInnerHTML: { __html: item.title } }),
                React.createElement("p", { className: styles.itemText, dangerouslySetInnerHTML: { __html: item.text } }))), lang === 'hi' ? itemsRu : itemsEn)),
        React.createElement(Col, { size: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            React.createElement("div", { className: styles.imageContainer },
                React.createElement("img", { src: './img/storiqa-for-sellers.png', alt: "graame market" }))))));
export default StartSellingForSellers;
