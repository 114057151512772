import { addIndex, has, map, pathOr } from 'ramda';
import { extractText } from '../../../utils';
const fromSearchFilters = (search, pathNames) => {
    const rawFiltered = pathOr([], ['findStore', 'pageInfo', 'searchFilters', ...pathNames], search);
    const mapIndexed = addIndex(map);
    return mapIndexed((item, idx) => ({
        id: `${has('rawId')(item) ? item.rawId : idx}`,
        label: has('name')(item) ? extractText(item.name) : item
    }), rawFiltered);
};
export default fromSearchFilters;
