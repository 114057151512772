import { t } from '../../translation/utils';
const translations = {
    en: {
        sorryButNotResults: 'Sorry, but no results',
        for: 'for',
        checkYourSearch: 'Check your search request for mistakes or try to find somethind else.'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
