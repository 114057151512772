import { t } from '../../../../../../translation/utils';
const translations = {
    en: {
        error: 'Error: ',
        close: 'Close.',
        somethingGoingWrong: 'Something going wrong :(',
        noProducts: 'No Products'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
