import React, { Component } from 'react';
import { Environment } from 'relay-runtime';
import PropTypes from 'prop-types';
import { convertSrc } from '../../../utils';
import { Col, Container, Row } from '../../../layout';
import { Icon } from '../../../components/Icon';
import { withShowAlert } from '../../../components/Alerts/AlertContext';
import ProductInfo from './ProductInfo';
import styles from './CartProduct.module.scss';
class CartProduct extends Component {
    static contextTypes = { environment: PropTypes.instanceOf(Environment).isRequired };
    constructor(props) {
        super(props);
        this.state = { comment: '' };
    }
    render() {
        const { product, productName, buyNowData, onChangeCount, onDeleteProduct, country, isShippingAvailable, baseProductId, onChangeDelivery, deliveryPackage, onPackagesFetched, currency } = this.props;
        if (!product) {
            return null;
        }
        const { photoMain } = product;
        return (React.createElement("div", { className: styles.container },
            React.createElement(Container, { correct: true },
                React.createElement(Row, null,
                    React.createElement(Col, { size: 12, sm: 3 },
                        React.createElement(Row, null,
                            React.createElement(Col, { size: 4, sm: 12 },
                                React.createElement("div", { className: styles.leftContainer }, photoMain != null ? (React.createElement("div", { className: styles.picture, style: {
                                        backgroundImage: `url(${convertSrc('medium', photoMain)})`
                                    } })) : (React.createElement("div", { className: styles.noLogo },
                                    React.createElement(Icon, { type: "camera", size: 40 }))))),
                            React.createElement(Col, { size: 6, smHidden: true },
                                React.createElement("div", { className: styles.productSummaryHeader }, productName)),
                            React.createElement(Col, { size: 2, smHidden: true },
                                React.createElement("div", { className: styles.recycleContainer },
                                    React.createElement("button", { className: styles.recycle, onClick: onDeleteProduct, "data-test": "cartProductDeleteButton" },
                                        React.createElement(Icon, { type: "basket", size: 32 })))))),
                    React.createElement(Col, { size: 12, sm: 9 },
                        React.createElement(Row, { withoutGrow: true },
                            React.createElement(Col, { size: 10, sm: 11, hidden: true, smVisible: true },
                                React.createElement("div", { className: styles.productSummaryHeader }, productName)),
                            React.createElement(Col, { size: 2, sm: 1, hidden: true, smVisible: true },
                                React.createElement("div", { className: styles.recycleContainer },
                                    React.createElement("button", { className: styles.recycle, onClick: onDeleteProduct, "data-test": "cartProductDeleteButton" },
                                        React.createElement(Icon, { type: "basket", size: 32 })))),
                            React.createElement(Col, { size: 12 },
                                React.createElement("div", { className: styles.productInfoWrapper },
                                    React.createElement(ProductInfo, { product: product, comment: this.state.comment, buyNowData: buyNowData, onChangeCount: onChangeCount, country: country, isShippingAvailable: isShippingAvailable, baseProductId: baseProductId, onChangeDelivery: onChangeDelivery, deliveryPackage: deliveryPackage, onPackagesFetched: onPackagesFetched, currency: currency })))))))));
    }
}
export default withShowAlert(CartProduct);
