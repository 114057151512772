import React, { Component } from 'react';
import { range, map, addIndex, filter, flatten } from 'ramda';
import { Icon } from '../../Icon';
import styles from './Paginator.module.scss';
import { classNames } from '../../../utils';
class Paginator extends Component {
    constructor(props) {
        super(props);
        this.state = { clickedItem: props.currentPage };
    }
    handlePageSelect = (item) => {
        this.setState({ clickedItem: item }, () => {
            this.props.onPageSelect(item);
        });
    };
    render() {
        const { currentPage, pagesCount, isLoading } = this.props;
        const { clickedItem } = this.state;
        let items = range(1, pagesCount + 1);
        if (pagesCount > 4) {
            const cast = [
                1,
                2,
                3,
                currentPage - 2,
                currentPage - 1,
                currentPage,
                currentPage + 1,
                currentPage + 2,
                pagesCount - 2,
                pagesCount - 1,
                pagesCount
            ];
            const mapIndexed = addIndex(filter);
            const filteredCast = mapIndexed((item, idx) => !((idx > 2 && item <= 3) || (idx < 8 && item >= pagesCount - 2)), cast);
            let currentItem = 0;
            items = flatten(map((item) => {
                if (item - currentItem === 2) {
                    currentItem = item;
                    return [item - 1, item];
                }
                if (item - currentItem > 1) {
                    currentItem = item;
                    return ['...', item];
                }
                currentItem = item;
                return item;
            }, filteredCast));
        }
        return (React.createElement("div", { className: styles.container },
            pagesCount !== 0
                && currentPage !== 1 && (React.createElement("button", { onClick: () => this.handlePageSelect(parseFloat(String(currentPage - 1))), disabled: isLoading },
                React.createElement(Icon, { type: "prev", size: 20 }))),
            this.props.pagesCount > 1
                && addIndex(map)((item, idx) => {
                    if (item === '...') {
                        return (React.createElement("div", { key: idx, className: styles.dots }, item));
                    }
                    return (React.createElement("button", { className: classNames(styles, 'item', {
                            current: item === currentPage,
                            clicked: item === clickedItem && isLoading && item !== currentPage
                        }), onClick: () => this.handlePageSelect(parseFloat(item)), disabled: isLoading || item === currentPage, key: idx }, item));
                }, items),
            pagesCount !== 0
                && currentPage !== pagesCount && (React.createElement("button", { onClick: () => this.handlePageSelect(parseFloat(String(currentPage + 1))), disabled: isLoading },
                React.createElement(Icon, { type: "next", size: 20 })))));
    }
}
export default Paginator;
