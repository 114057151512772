/**
 * @generated SignedSource<<ba1212a03eb13cb991f5bd6daef6bcb5>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawId",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
            (v2 /*: any*/),
            (v3 /*: any*/)
        ],
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
    }, v6 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lang",
            "storageKey": null
        },
        (v5 /*: any*/)
    ], v7 = {
        "alias": null,
        "args": null,
        "concreteType": "Translation",
        "kind": "LinkedField",
        "name": "name",
        "plural": true,
        "selections": (v6 /*: any*/),
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "concreteType": "Translation",
        "kind": "LinkedField",
        "name": "shortDescription",
        "plural": true,
        "selections": (v6 /*: any*/),
        "storageKey": null
    }, v9 = {
        "alias": null,
        "args": null,
        "concreteType": "Translation",
        "kind": "LinkedField",
        "name": "longDescription",
        "plural": true,
        "selections": (v6 /*: any*/),
        "storageKey": null
    }, v10 = {
        "alias": null,
        "args": null,
        "concreteType": "Translation",
        "kind": "LinkedField",
        "name": "seoTitle",
        "plural": true,
        "selections": (v6 /*: any*/),
        "storageKey": null
    }, v11 = {
        "alias": null,
        "args": null,
        "concreteType": "Translation",
        "kind": "LinkedField",
        "name": "seoDescription",
        "plural": true,
        "selections": (v6 /*: any*/),
        "storageKey": null
    }, v12 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "storeId",
        "storageKey": null
    }, v13 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
    }, v14 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
    }, v15 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "ProductsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Product",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "price",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "discount",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "photoMain",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "additionalPhotos",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "vendorCode",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cashback",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProdAttrValue",
                            "kind": "LinkedField",
                            "name": "attributes",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "value",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "metaField",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Attribute",
                                    "kind": "LinkedField",
                                    "name": "attribute",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        (v7 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "AttributeMetaField",
                                            "kind": "LinkedField",
                                            "name": "metaField",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "values",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "TranslatedValue",
                                                    "kind": "LinkedField",
                                                    "name": "translatedValues",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Translation",
                                                            "kind": "LinkedField",
                                                            "name": "translations",
                                                            "plural": true,
                                                            "selections": [
                                                                (v5 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ], v16 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lengthCm",
        "storageKey": null
    }, v17 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "widthCm",
        "storageKey": null
    }, v18 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "heightCm",
        "storageKey": null
    }, v19 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "weightG",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UpdateBaseProductMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "BaseProduct",
                    "kind": "LinkedField",
                    "name": "updateBaseProduct",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Category",
                            "kind": "LinkedField",
                            "name": "category",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v4 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        (v9 /*: any*/),
                        (v10 /*: any*/),
                        (v11 /*: any*/),
                        (v12 /*: any*/),
                        (v13 /*: any*/),
                        (v14 /*: any*/),
                        {
                            "alias": "products",
                            "args": null,
                            "concreteType": "ProductsConnection",
                            "kind": "LinkedField",
                            "name": "__Wizard_products_connection",
                            "plural": false,
                            "selections": (v15 /*: any*/),
                            "storageKey": null
                        },
                        (v16 /*: any*/),
                        (v17 /*: any*/),
                        (v18 /*: any*/),
                        (v19 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UpdateBaseProductMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "BaseProduct",
                    "kind": "LinkedField",
                    "name": "updateBaseProduct",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Category",
                            "kind": "LinkedField",
                            "name": "category",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v4 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        (v9 /*: any*/),
                        (v10 /*: any*/),
                        (v11 /*: any*/),
                        (v12 /*: any*/),
                        (v13 /*: any*/),
                        (v14 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductsConnection",
                            "kind": "LinkedField",
                            "name": "products",
                            "plural": false,
                            "selections": (v15 /*: any*/),
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "filters": null,
                            "handle": "connection",
                            "key": "Wizard_products",
                            "kind": "LinkedHandle",
                            "name": "products"
                        },
                        (v16 /*: any*/),
                        (v17 /*: any*/),
                        (v18 /*: any*/),
                        (v19 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "5157cce20c4631ae78c5fd6a7b190a07",
            "id": null,
            "metadata": {
                "connection": [
                    {
                        "count": null,
                        "cursor": null,
                        "direction": "forward",
                        "path": [
                            "updateBaseProduct",
                            "products"
                        ]
                    }
                ]
            },
            "name": "UpdateBaseProductMutation",
            "operationKind": "mutation",
            "text": "mutation UpdateBaseProductMutation(\n  $input: UpdateBaseProductInput!\n) {\n  updateBaseProduct(input: $input) {\n    id\n    rawId\n    category {\n      rawId\n      id\n    }\n    store {\n      id\n      rawId\n    }\n    name {\n      lang\n      text\n    }\n    shortDescription {\n      lang\n      text\n    }\n    longDescription {\n      lang\n      text\n    }\n    seoTitle {\n      lang\n      text\n    }\n    seoDescription {\n      lang\n      text\n    }\n    storeId\n    currency\n    status\n    products {\n      edges {\n        node {\n          id\n          rawId\n          price\n          discount\n          photoMain\n          additionalPhotos\n          vendorCode\n          cashback\n          attributes {\n            value\n            metaField\n            attribute {\n              id\n              rawId\n              name {\n                lang\n                text\n              }\n              metaField {\n                values\n                translatedValues {\n                  translations {\n                    text\n                  }\n                }\n              }\n            }\n          }\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    lengthCm\n    widthCm\n    heightCm\n    weightG\n  }\n}\n"
        }
    };
})();
node.hash = "ca9651144cae2d6b4d5594dc94b5cf41";
export default node;
