import { commitMutation, graphql } from 'react-relay';
const mutation = graphql `
	mutation CreateUserMutation($input: CreateUserInput!) {
		createUser(input: $input) {
			id
			rawId
			email
			isActive
			firstName
			lastName
			referral
			utmMarks {
				key
				value
			}
			country {
				label
				alpha2
				alpha3
			}
			referrer
		}
	}
`;
const commit = (params) => commitMutation(params.environment, {
    mutation,
    variables: params.variables,
    onCompleted: params.onCompleted,
    onError: params.onError
});
export default { commit };
/*
 {
 "data": {
 "createUser": {
 "id": "dXNlcnNfdXNlcl81",
 "rawId": "5",
 "email": "test@em.ail",
 "isActive": true
 }
 }
 }
 */
