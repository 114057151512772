/**
 * @generated SignedSource<<3fe9d413507b61de1c75a9b1a1e69f58>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "storageSlug"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "routes_EditStorage_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        {
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "storageSlug",
                                    "variableName": "storageSlug"
                                }
                            ],
                            "kind": "FragmentSpread",
                            "name": "EditStorage_me"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "routes_EditStorage_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "slug",
                                    "variableName": "storageSlug"
                                }
                            ],
                            "concreteType": "GraphQLWarehouse",
                            "kind": "LinkedField",
                            "name": "warehouse",
                            "plural": false,
                            "selections": [
                                (v1 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "slug",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Address",
                                    "kind": "LinkedField",
                                    "name": "addressFull",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "country",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "administrativeAreaLevel1",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "administrativeAreaLevel2",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "political",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "postalCode",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "streetNumber",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "value",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "route",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "locality",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "storeId",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v1 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "aad3a7efd3bcf683e65bc497c3c2f4d2",
            "id": null,
            "metadata": {},
            "name": "routes_EditStorage_Query",
            "operationKind": "query",
            "text": "query routes_EditStorage_Query(\n  $storageSlug: String!\n) {\n  me {\n    ...EditStorage_me_2YjLTh\n    id\n  }\n}\n\nfragment EditStorage_me_2YjLTh on User {\n  warehouse(slug: $storageSlug) {\n    id\n    name\n    slug\n    addressFull {\n      country\n      administrativeAreaLevel1\n      administrativeAreaLevel2\n      political\n      postalCode\n      streetNumber\n      value\n      route\n      locality\n    }\n    storeId\n  }\n}\n"
        }
    };
})();
node.hash = "1f3d3d256054266e40da6b1e3e06ccd2";
export default node;
