import React, { PureComponent } from 'react';
import { withRouter } from 'found';
import { pathOr } from 'ramda';
import Logo from '../../../components/Icon/svg/logo.svg';
import styles from './LogoutPage.module.scss';
import t from './i18n';
import { clearJWT } from '../../../utils';
class LogoutPage extends PureComponent {
    componentDidMount() {
        if (!process.env.BROWSER) {
            return;
        }
        clearJWT();
        const store = this.props.environment.getStore();
        const meId = pathOr(null, ['me', '__ref'], store.getSource()
            .get('client:root'));
        const cartId = pathOr(null, ['cart', '__ref'], store.getSource()
            .get('client:root'));
        if (meId) {
            store.getSource()
                .delete(meId);
        }
        if (cartId) {
            store.getSource()
                .delete(cartId);
        }
        this.props.router.replace('/');
    }
    render() {
        return (React.createElement("div", null,
            React.createElement("div", { className: styles.container },
                React.createElement("div", { className: styles.logo },
                    React.createElement(Logo, null)),
                React.createElement("span", { className: styles.text },
                    t.loading,
                    React.createElement("br", null),
                    t.pleaseWait),
                React.createElement("span", { className: styles.description },
                    "- ",
                    t.storiqaTeam),
                React.createElement("div", { className: styles.spinner },
                    React.createElement("div", { className: styles.doubleBounce1 }),
                    React.createElement("div", { className: styles.doubleBounce2 })))));
    }
}
export default withRouter(LogoutPage);
