import { t } from '../../translation/utils';
const translations = {
    en: {
        somethingGoingWrong: 'Something going wrong. New address was not created.',
        addressCreated: 'Address Created!',
        close: 'Closed.',
        successForDeleteFromCart: 'Success for DeleteFromCart mutation',
        errorInDeleteFromCart: 'Error in DeleteFromCart mutation',
        ordersSuccessfullyCreated: 'Orders successfully created',
        error: 'Error :(',
        somethingWentWrong: 'Something went wrong :(',
        next: 'Next',
        checkout: 'Checkout',
        errors: {
            receiverNameRequired: 'Receiver name is required',
            receiverPhoneRequired: 'Receiver phone is required',
            areRequired: 'are required',
            country: 'country',
            address: 'address',
            postalCode: 'postal code'
        }
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
