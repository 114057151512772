import React, { Component } from 'react';
import { Icon } from '../Icon';
import styles from './MobileListItems.module.scss';
import { classNames } from '../../utils';
class MobileListItems extends Component {
    static defaultProps = {
        idName: 'id',
        onClick: () => {
        }
    };
    constructor(props) {
        super(props);
        this.state = { selected: null };
    }
    handleSelected = (selected, item) => {
        const { onClick } = this.props;
        this.setState({ selected }, () => {
            onClick(item);
        });
    };
    render() {
        const { items, idName } = this.props;
        const { selected } = this.state;
        return (React.createElement("ul", { className: styles.container }, items.map((item, index) => (React.createElement("li", { key: item[idName], onClick: () => this.handleSelected(index, item), onKeyPress: () => {
            }, className: classNames(styles, 'item', `${selected === index ? 'active' : ''}`), tabIndex: -1 },
            selected === index ? React.createElement("span", { className: styles.activeBorder }) : null,
            React.createElement("span", null,
                React.createElement("p", { className: styles.linkName }, item.label)),
            React.createElement(Icon, { type: "arrowRight" }))))));
    }
}
export default MobileListItems;
