import { Component } from 'react';
import { graphql } from 'react-relay';
import { pathOr } from 'ramda';
import { setWindowTag } from '../../../utils';
import totalFragment from './__generated__/UserData_cart.graphql';
import headerFragment from './__generated__/UserData_me.graphql';
class UserData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalCount: 0,
            userData: undefined,
            isShopCreated: false
        };
        // console.log('store ->', props.environment.getStore());
        const store = props.environment.getStore();
        // const source: RecordSource = store.getSource();
        // const root = source.get('client:root');
        // console.log('root ->', root);
        // const cartId = root.cart ? (root.cart ).__ref : undefined;
        // const meId = root.me ? (root.me ).__ref : undefined;
        // console.log('cartId ->', cartId);
        // console.log('meId ->', meId);
        const getStoreData = this.storeData(store);
        const cartId = getStoreData('cart');
        console.log('cartId ->', cartId);
        // const queryNode = TOTAL_FRAGMENT.cart();
        const snapshot = store.lookup({
            dataID: cartId,
            node: totalFragment
        });
        // console.log('snapshot ->', snapshot);
        const { dispose } = store.subscribe(snapshot, (snap) => {
            // console.log('snapshot', snap.data);
            const newTotalCount = this.getTotalCount(snap);
            // console.log('newTotalCount ->', newTotalCount);
            this.setTotalCount(newTotalCount);
            // tmp code
            setWindowTag('cartCount', newTotalCount);
            // end tmp code
        });
        const totalCount = this.getTotalCount(snapshot);
        this.dispose = dispose;
        // this.setState({totalCount});
        this.state = {
            ...this.state,
            totalCount
        };
        // this.state.totalCount = totalCount;
        // tmp code
        setWindowTag('cartCount', totalCount);
        // end tmp code
        const meId = getStoreData('me');
        // console.log('meId ->', meId);
        if (!meId) {
            // tmp code
            setWindowTag('user', null);
            // end tmp code
        }
        if (meId) {
            // const queryUser = HEADER_FRAGMENT.me();
            const snapshotUser = store.lookup({
                dataID: meId,
                node: headerFragment
            });
            const { dispose: disposeUser } = store.subscribe(snapshotUser, (snap) => {
                // console.log('snapshot', snap.data);
                this.setUserData(snap.data);
                // tmp code
                setWindowTag('user', snap.data);
                // end tmp code
            });
            this.disposeUser = disposeUser;
            // this.setState({
            //   userData: snapshotUser.data,
            //   isShopCreated: this.checkIfStoreCreated(snapshotUser.data )
            // });
            // this.state.userData = snapshotUser.data;
            this.state = {
                ...this.state,
                userData: snapshotUser.data
            };
            this.state = {
                ...this.state,
                isShopCreated: this.checkIfStoreCreated(snapshotUser.data)
            };
            // this.state.isShopCreated = this.checkIfStoreCreated(snapshotUser.data);
            // tmp code
            setWindowTag('user', snapshotUser.data);
            // end tmp code
        }
    }
    componentDidUpdate(prevProps) {
        const { me } = this.props;
        if (prevProps.me === null && me != null) {
            this.setUserData({
                email: me.email,
                firstName: me.firstName,
                lastName: me.lastName,
                avatar: me.avatar,
                wizardStore: me.wizardStore,
                myStore: me.myStore
            });
        }
    }
    componentWillUnmount() {
        if (this.dispose) {
            this.dispose();
        }
        if (this.disposeUser) {
            this.disposeUser();
        }
    }
    getTotalCount = (snapshot) => pathOr(0, ['data', 'totalCount'], snapshot);
    setTotalCount = (totalCount) => {
        this.setState({ totalCount });
    };
    setUserData = (userData) => {
        this.setState({ userData });
    };
    checkIfStoreCreated = (userData) => pathOr(false, ['wizardStore', 'completed'], userData);
    storeData = (store) => (prop) => pathOr(null, [prop, '__ref'], store.getSource()
        .get('client:root'));
    dispose;
    disposeUser;
    render() {
        const { userData, totalCount, isShopCreated } = this.state;
        return this.props.children({
            userData,
            totalCount,
            isShopCreated
        });
    }
}
export default UserData;
const TOTAL_FRAGMENT = graphql `
	fragment UserData_cart on Cart {
		id
		totalCount
	}
`;
const HEADER_FRAGMENT = graphql `
	fragment UserData_me on User {
		email
		firstName
		lastName
		avatar
		wizardStore {
			completed
		}
		myStore {
			rawId
		}
	}
`;
