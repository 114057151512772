/**
 * @generated SignedSource<<e60dae04d426c21e926c6a25c260626c>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "autocompleteValue"
    }, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "currentPage"
    }, v2 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "itemsCount"
    }, v3 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "searchTerm"
    }, v4 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "storageSlug"
    }, v5 = {
        "kind": "Variable",
        "name": "currentPage",
        "variableName": "currentPage"
    }, v6 = {
        "kind": "Variable",
        "name": "itemsCount",
        "variableName": "itemsCount"
    }, v7 = {
        "kind": "Variable",
        "name": "searchTerm",
        "variableName": "searchTerm"
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v9 = {
        "alias": null,
        "args": null,
        "concreteType": "Translation",
        "kind": "LinkedField",
        "name": "name",
        "plural": true,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lang",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v10 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawId",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/),
                (v4 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "StorageProducts_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        {
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "autocompleteValue",
                                    "variableName": "autocompleteValue"
                                },
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                {
                                    "kind": "Variable",
                                    "name": "storageSlug",
                                    "variableName": "storageSlug"
                                }
                            ],
                            "kind": "FragmentSpread",
                            "name": "StorageProducts_me"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v3 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v4 /*: any*/)
            ],
            "kind": "Operation",
            "name": "StorageProducts_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "slug",
                                    "variableName": "storageSlug"
                                }
                            ],
                            "concreteType": "GraphQLWarehouse",
                            "kind": "LinkedField",
                            "name": "warehouse",
                            "plural": false,
                            "selections": [
                                (v8 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": [
                                        {
                                            "kind": "Literal",
                                            "name": "first",
                                            "value": 8
                                        },
                                        {
                                            "kind": "Variable",
                                            "name": "name",
                                            "variableName": "autocompleteValue"
                                        }
                                    ],
                                    "concreteType": "FullNameConnection",
                                    "kind": "LinkedField",
                                    "name": "autoCompleteProductName",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "FullNameEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "node",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": [
                                        (v5 /*: any*/),
                                        (v6 /*: any*/),
                                        (v7 /*: any*/),
                                        {
                                            "kind": "Literal",
                                            "name": "visibility",
                                            "value": "active"
                                        }
                                    ],
                                    "concreteType": "StocksConnection",
                                    "kind": "LinkedField",
                                    "name": "products",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "StocksEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "GraphQLStock",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "productId",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Product",
                                                            "kind": "LinkedField",
                                                            "name": "product",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "BaseProduct",
                                                                    "kind": "LinkedField",
                                                                    "name": "baseProduct",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v9 /*: any*/),
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "Category",
                                                                            "kind": "LinkedField",
                                                                            "name": "category",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                (v9 /*: any*/),
                                                                                (v8 /*: any*/)
                                                                            ],
                                                                            "storageKey": null
                                                                        },
                                                                        (v8 /*: any*/)
                                                                    ],
                                                                    "storageKey": null
                                                                },
                                                                (v8 /*: any*/),
                                                                (v10 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "price",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "photoMain",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "currency",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "ProdAttrValue",
                                                                    "kind": "LinkedField",
                                                                    "name": "attributes",
                                                                    "plural": true,
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "attrId",
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "Attribute",
                                                                            "kind": "LinkedField",
                                                                            "name": "attribute",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                (v8 /*: any*/),
                                                                                (v10 /*: any*/),
                                                                                (v9 /*: any*/)
                                                                            ],
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "value",
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "quantity",
                                                            "storageKey": null
                                                        },
                                                        (v8 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PageInfoWarehouseProductSearch",
                                            "kind": "LinkedField",
                                            "name": "pageInfo",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "totalPages",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "currentPage",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "pageItemsCount",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v8 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "231115ed3a542c7a02c59d8645378d19",
            "id": null,
            "metadata": {},
            "name": "StorageProducts_Query",
            "operationKind": "query",
            "text": "query StorageProducts_Query(\n  $autocompleteValue: String!\n  $searchTerm: SearchProductInput\n  $currentPage: Int!\n  $itemsCount: Int!\n  $storageSlug: String!\n) {\n  me {\n    ...StorageProducts_me_1Fzyy2\n    id\n  }\n}\n\nfragment StorageProducts_me_1Fzyy2 on User {\n  warehouse(slug: $storageSlug) {\n    id\n    name\n    autoCompleteProductName(first: 8, name: $autocompleteValue) {\n      edges {\n        node\n      }\n    }\n    products(searchTerm: $searchTerm, currentPage: $currentPage, itemsCount: $itemsCount, visibility: \"active\") {\n      edges {\n        node {\n          productId\n          product {\n            baseProduct {\n              name {\n                lang\n                text\n              }\n              category {\n                name {\n                  lang\n                  text\n                }\n                id\n              }\n              id\n            }\n            id\n            rawId\n            price\n            photoMain\n            currency\n            attributes {\n              attrId\n              attribute {\n                id\n                rawId\n                name {\n                  lang\n                  text\n                }\n              }\n              value\n            }\n          }\n          quantity\n          id\n        }\n      }\n      pageInfo {\n        totalPages\n        currentPage\n        pageItemsCount\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "4be234c430f69d716e045a6ac53ec9aa";
export default node;
