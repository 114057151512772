import { fetchQuery, graphql } from 'react-relay';
import { debug } from '../../utils';
const CALCULATE_PAYOUT = graphql `
	query calculatePayout_Query($input: CalculatePayoutInput!) {
		calculatePayout(input: $input) {
			orderIds
			currency
			grossAmount
		}
	}
`;
const calculatePayout = (data) => {
    debug();
    return fetchQuery(data.environment, CALCULATE_PAYOUT, data.variables)
        .toPromise()
        .then((response) => {
        if (response) {
            return Promise.resolve(response);
        }
        return Promise.reject(new Error('Unable to fetch packages'));
    });
};
export default calculatePayout;
