/**
 * @generated SignedSource<<5260e7800cfcb3bc2a9023744746896f>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "routes_Login_Query",
            "selections": (v0 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "routes_Login_Query",
            "selections": (v0 /*: any*/)
        },
        "params": {
            "cacheID": "3256883355d6c6ad70c132565b93ca72",
            "id": null,
            "metadata": {},
            "name": "routes_Login_Query",
            "operationKind": "query",
            "text": "query routes_Login_Query {\n  me {\n    id\n  }\n}\n"
        }
    };
})();
node.hash = "d59c004f4bd8ee6a1c300c544f8a6a05";
export default node;
