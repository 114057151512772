import React, { Component } from 'react';
import Facebook from './svg/logo-facebook.svg';
import Pinterest from './svg/logo-pinterest.svg';
import Twitter from './svg/logo-twitter.svg';
import Instagram from './svg/logo-instagram.svg';
import VK from './svg/logo-vk.svg';
import HeartIcon from './svg/heart.svg';
import styles from './SocialShare.module.scss';
import { classNames } from '../../utils';
class SocialShare extends Component {
    convertUrl = (url, type) => {
        switch (type) {
            case 'fb':
                if (url.search(/facebook/) === -1) {
                    const newUrl = `facebook.com/${url}`.replace(/\/\//g, '/');
                    return `https://${newUrl}`;
                }
                break;
            case 'in':
                if (url.search(/instagram/) === -1) {
                    const newUrl = `instagram.com/${url}`.replace(/\/\//g, '/');
                    return `https://${newUrl}`;
                }
                break;
            case 'tw':
                if (url.search(/twitter/) === -1) {
                    const newUrl = `twitter.com/${url}`.replace(/\/\//g, '/');
                    return `https://${newUrl}`;
                }
                break;
            default:
                break;
        }
        if (url.search(/^https?/) === -1) {
            return `https://${url}`;
        }
        return url;
    };
    static defaultProps = {
        big: false,
        noBorder: false,
        noFavorite: false,
        noBorderX: false
    };
    render() {
        const { big, noBorder, noFavorite, noBorderX, facebookUrl, pinterestUrl, twitterUrl, instagramUrl, vkontakteUrl } = this.props;
        return (React.createElement("nav", { className: classNames(styles, 'container', {
                big,
                noBorder,
                noBorderX
            }) },
            React.createElement("div", { className: classNames(styles, 'socialIcons', { big }) },
                React.createElement(React.Fragment, null,
                    Boolean(facebookUrl) && (React.createElement("a", { className: styles.link, rel: "noopener noreferrer", target: "_blank", href: this.convertUrl(facebookUrl || '', 'fb') },
                        React.createElement(Facebook, null))),
                    Boolean(pinterestUrl) && (React.createElement("a", { className: styles.link, rel: "noopener noreferrer", target: "_blank", href: this.convertUrl(pinterestUrl || '', null) },
                        React.createElement(Pinterest, null))),
                    Boolean(twitterUrl) && (React.createElement("a", { className: styles.link, rel: "noopener noreferrer", target: "_blank", href: this.convertUrl(twitterUrl || '', 'tw') },
                        React.createElement(Twitter, null))),
                    Boolean(instagramUrl) && (React.createElement("a", { className: styles.link, rel: "noopener noreferrer", target: "_blank", href: this.convertUrl(instagramUrl || '', 'in') },
                        React.createElement(Instagram, null))),
                    Boolean(vkontakteUrl) && (React.createElement("a", { className: styles.link, rel: "noopener noreferrer", target: "_blank", href: this.convertUrl(vkontakteUrl || '', null) },
                        React.createElement(VK, null))))),
            !noBorder && (React.createElement("div", { className: classNames(styles, 'favorite', {
                    big,
                    noBorder,
                    noFavorite
                }) },
                React.createElement(HeartIcon, null)))));
    }
}
export default SocialShare;
