import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { withRouter } from 'found';
import { OrderPage } from '../../../common/OrderPage';
class Order extends PureComponent {
    render() {
        const { order, email } = this.props.me;
        if (!order) {
            return null;
        }
        return (React.createElement(OrderPage, { isAbleToManageOrderForUser: true, order: order, email: email, isPaymentInfoCanBeShown: true }));
    }
}
export default createFragmentContainer(withRouter(Order), {
    me: graphql `
			fragment Order_me on User
			@argumentDefinitions(slug: { type: "Int!", defaultValue: 0 }) {
				email
				order(slug: $slug) {
					id
					currency
					slug
					deliveryCompany
					storeId
					product {
						id
						rawId
						baseProduct {
							rawId
							currency
							name {
								text
							}
							category {
								rawId
								name {
									text
									lang
								}
							}
						}
						price
						attributes {
							value
							attribute {
								name {
									lang
									text
								}
							}
						}
						photoMain
					}
					preOrder
					preOrderDays
					receiverName
					receiverPhone
					addressFull {
						value
						country
						administrativeAreaLevel1
						administrativeAreaLevel2
						locality
						political
						postalCode
						route
						streetNumber
						placeId
					}
					createdAt
					deliveryCompany
					trackId
					quantity
					subtotal
					totalAmount
					deliveryPrice
					couponDiscount
					state
					paymentStatus
					history {
						edges {
							node {
								state
								committedAt
								committerRole
								comment
							}
						}
					}
				}
			}
    `
});
