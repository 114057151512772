import { t } from '../../translation/utils';
const translations = {
    en: {
        signIn: 'Sign In',
        signUp: 'Sign Up',
        recoverPassword: 'Recover Password',
        registrationSuccessful: 'Registration successful, please confirm your email and login.',
        pleaseVerifyYourEmail: 'Please verify your email',
        passwordResetSuccessfully: 'Password Reset Successfully',
        emailNotFound: 'Email Not Found',
        verificationEmailSentSuccessfully: 'Verification Email Sent Successfully',
        resetPassword: 'Reset Password',
        pleaseTypeNewPassword: 'Please Type new password'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
