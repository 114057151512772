import { commitMutation, graphql } from 'react-relay';
import { map } from 'ramda';
const mutation = graphql `
	mutation CreateUserDeliveryAddressFullMutation(
		$input: NewUserDeliveryAddressFullInput!
	) {
		createUserDeliveryAddressFull(input: $input) {
			rawId
			id
			userId
			isPriority
			address {
				country
				administrativeAreaLevel1
				administrativeAreaLevel2
				political
				postalCode
				streetNumber
				value
				route
				locality
			}
		}
	}
`;
const commit = (params) => commitMutation(params.environment, {
    mutation,
    variables: params.variables,
    onCompleted: params.onCompleted,
    onError: params.onError,
    updater: (relayStore) => {
        const address = relayStore.getRootField('createUserDeliveryAddressFull');
        const isPriority = address.getValue('isPriority');
        const me = relayStore.getRoot()
            .getLinkedRecord('me');
        const deliveryAddresses = me.getLinkedRecords('deliveryAddressesFull');
        map((item) => {
            if (isPriority) {
                item.setValue(false, 'isPriority');
            }
            return item;
        }, deliveryAddresses);
        const newDeliveryAddresses = [address, ...deliveryAddresses];
        me.setLinkedRecords(newDeliveryAddresses, 'deliveryAddressesFull');
    }
});
export default { commit };
