import { t } from '../../../../../translation/utils';
const translations = {
    en: {
        fillYouStoreWithGoods: 'Fill your store with goods',
        pleaseAddTheProduct: 'Please add the product you would like to sell in your marketplace',
        currentlyYouHaveNoProducts: 'Currently you have no products in your store. Click ‘Add’ to start filling your store with products.',
        addFirstProduct: 'Add first product',
        addNewProduct: 'Add new product',
        deleteYourProduct: 'Delete your product?',
        areYouSure: ' Are you sure you want to delete this listing? All the listing information will be discarded and cannot be retrieved.',
        cancel: 'Cancel',
        yesDeletePlease: 'Yes, delete, please'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
