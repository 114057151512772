import React, { PureComponent } from 'react';
import { Checkbox, Input } from '../../../../../../components/common';
import t from './i18n';
import styles from './PreOrder.module.scss';
class PreOrder extends PureComponent {
    constructor(props) {
        super(props);
        const { preOrderDays, preOrder } = props;
        this.state = { preOrderDays, preOrder };
    }
    componentDidMount() {
        if (process.env.BROWSER) {
            window.addEventListener('click', this.handleClick);
        }
    }
    componentDidUpdate(prevProps) {
        const { preOrderDays, preOrder } = this.state;
        if (prevProps.preOrderDays !== preOrderDays
            || prevProps.preOrder !== preOrder) {
            this.props.onChangePreOrder({ preOrderDays, preOrder });
        }
    }
    componentWillUnmount() {
        if (process.env.BROWSER) {
            window.removeEventListener('click', this.handleClick);
        }
    }
    preOrderDaysInput;
    handleClick = (e) => {
        const isPreOrderDaysInput = this.preOrderDaysInput && this.preOrderDaysInput.contains(e.target);
        if (!isPreOrderDaysInput && !this.state.preOrderDays) {
            this.setState({ preOrder: false });
        }
    };
    handleOnChangePreOrder = () => {
        this.setState((prevState) => {
            if (this.preOrderDaysInput) {
                if (!prevState.preOrder) {
                    this.preOrderDaysInput.focus();
                }
                if (prevState.preOrder) {
                    this.preOrderDaysInput.blur();
                }
            }
            return { preOrder: !prevState.preOrder };
        });
    };
    handleOnChangePreOrderDays = (e) => {
        const { value } = e.target;
        const regexp = /(^\d*$)/;
        if (!regexp.test(value)) {
            return;
        }
        this.setState({ preOrderDays: value.replace(/^0+/, '0').replace(/^0+(\d)/, '$1') });
    };
    handleOnBlurPreOrderDays = (e) => {
        const { value } = e.target;
        if (!value || value === '0') {
            this.setState({ preOrderDays: value });
        }
    };
    render() {
        const { preOrderDays, preOrder } = this.state;
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.preOrderTitle },
                React.createElement("div", { className: styles.title },
                    React.createElement("strong", null, t.availableForPreOrder)),
                React.createElement("div", { className: styles.preOrderCheckbox },
                    React.createElement(Checkbox, { inline: true, id: "preOrderCheckbox", isChecked: preOrder, onChange: this.handleOnChangePreOrder }))),
            React.createElement("div", { className: styles.preOrderDaysInput },
                React.createElement(Input, { inputRef: (node) => {
                        this.preOrderDaysInput = node;
                    }, fullWidth: true, id: "preOrderInput", label: t.labelLeadTime, onChange: this.handleOnChangePreOrderDays, onBlur: this.handleOnBlurPreOrderDays, value: preOrderDays || '', dataTest: "variantPreOrderDaysInput" }))));
    }
}
export default PreOrder;
