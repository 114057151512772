import React, { useEffect, useState } from 'react';
import { equals, find, head, map, propEq } from 'ramda';
import moment from 'moment';
import { getCookie, setCookie } from '../../../utils';
import { Select } from '../../common/Select';
import { Button } from '../../common/Button';
import styles from './HeaderTop.module.scss';
import t from './i18n';
import { languages } from '../../../translation/languages';
import { COOKIE_CURRENCY } from '../../../constants';
const HeaderTop = ({ setLang, isShopCreated, currencies }) => {
    const [currentCurrency, setCurrentCurrency] = useState(null);
    const [currentLocale, setCurrentLocale] = useState(null);
    useEffect(() => {
        const actualCurrency = getCookie(COOKIE_CURRENCY);
        if (!actualCurrency) {
            setCookie(COOKIE_CURRENCY, 'INR');
        }
        setCurrentCurrency(getCurrencyAsItem(actualCurrency || 'INR'));
    }, []);
    useEffect(() => {
        const locale = getCookie('locale') || 'en';
        setCurrentLocale(find(propEq(locale, 'id'))(languages));
    }, []);
    const getCurrenciesItems = () => {
        if (!currencies) {
            return [];
        }
        return map((item) => ({
            id: item,
            label: item
        }), currencies);
    };
    const getCurrencyAsItem = (currency) => ({
        id: currency,
        label: currency
    });
    const handleChangeLocale = (item) => {
        if (item && item.id) {
            setLang(item.id);
        }
    };
    const handleSelect = (value) => {
        const currency = find(equals(value.id), currencies);
        if (currency) {
            setCookie(COOKIE_CURRENCY, currency, moment()
                .utc()
                .add(7, 'd')
                .toDate());
            window.location.reload();
        }
    };
    return (React.createElement("div", { className: styles.container },
        React.createElement("div", { className: styles.item },
            React.createElement(Select, { activeItem: currentCurrency || head(getCurrenciesItems()), items: getCurrenciesItems(), onSelect: handleSelect, dataTest: "headerFiat\u0421urrenciesSelect" })),
        React.createElement("div", { className: styles.item },
            React.createElement(Select, { activeItem: currentLocale, items: [{
                        id: 'en',
                        label: 'ENG'
                    }], onSelect: handleChangeLocale, dataTest: "headerLanguagesSelect" })),
        React.createElement("div", null,
            React.createElement("a", { href: "https://graame.zendesk.com", rel: "noopener noreferrer", target: "_blank" }, t.help)),
        React.createElement("div", null, isShopCreated ? null : (
        // <Button extraSmall href="https://selling.graame.com/">
        React.createElement(Button, { extraSmall: true, href: "/start-selling" }, t.startSelling)))));
};
export default HeaderTop;
