import { graphql } from 'react-relay';
import { basicMutation } from '../../../relay/mutations/basicMutation';
const mutation = graphql `
	mutation RequestPasswordResetMutation($input: ResetRequest!) {
		requestPasswordReset(input: $input) {
			success
		}
	}
`;
const requestPasswordResetMutation = basicMutation(mutation, 'requestPasswordReset');
export default requestPasswordResetMutation;
