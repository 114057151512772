import React, { Component, Fragment } from 'react';
import { isEmpty, map } from 'ramda';
import { RadioButton } from '../../../../../../../components/common/RadioButton';
import FixPriceForm from '../FixPriceForm';
import CompanyItem from '../CompanyItem';
import handlerShipping from '../handlerInterShippingDecorator';
import styles from './InterShipping.module.scss';
import t from './i18n';
import { classNames } from '../../../../../../../utils';
class InterShipping extends Component {
    constructor(props) {
        super(props);
        const { interAvailablePackages, interShipping } = props;
        const isSelectedWithout = !interAvailablePackages
            || isEmpty(interAvailablePackages)
            || isEmpty(interShipping);
        this.state = {
            isSelectedWithout,
            isSelectedFixPrice: !isSelectedWithout
        };
    }
    handleOnChangeRadioButton = (id) => {
        const { interAvailablePackages } = this.props;
        if (!interAvailablePackages || isEmpty(interAvailablePackages)) {
            this.setState({
                isSelectedWithout: true,
                isSelectedFixPrice: false
            });
            return;
        }
        this.setState({
            isSelectedWithout: id === 'interShippingWithout',
            isSelectedFixPrice: id !== 'interShippingWithout'
        }, () => {
            this.props.onChangeShippingData({
                inter: true,
                withoutInter: this.state.isSelectedWithout
            });
        });
    };
    render() {
        const { companies, editableItemId, remainingServices, possibleServices, onSaveCompany, interAvailablePackages, onRemoveCompany, onSetEditableItem, onRemoveEditableItem, error, currency } = this.props;
        const { isSelectedWithout, isSelectedFixPrice } = this.state;
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.title },
                React.createElement("strong", null, t.internationalShipping)),
            React.createElement("div", { className: styles.radioButtons },
                React.createElement("div", { className: styles.radioButton },
                    React.createElement(RadioButton, { inline: true, id: "interShippingWithout", label: t.withoutInternationalDelivery, isChecked: isSelectedWithout, onChange: this.handleOnChangeRadioButton })),
                interAvailablePackages
                    && !isEmpty(interAvailablePackages) && (React.createElement("div", { className: styles.radioButton },
                    React.createElement(RadioButton, { inline: true, id: "interShippingFixPrice", label: t.fixedSinglePriceForAll, isChecked: isSelectedFixPrice, onChange: this.handleOnChangeRadioButton })))),
            React.createElement("div", { className: classNames(styles, 'form', { hidePlane: isSelectedWithout }) },
                React.createElement("div", { className: classNames(styles, 'formWrap', { coverPlane: isEmpty(remainingServices) && !isSelectedWithout }) },
                    React.createElement(FixPriceForm, { inter: true, currency: currency, services: remainingServices, onSaveCompany: onSaveCompany, interAvailablePackages: interAvailablePackages })),
                !isEmpty(companies) && (React.createElement("div", { className: styles.companies }, map((item) => (React.createElement(Fragment, { key: item.id },
                    React.createElement(CompanyItem, { currency: currency, company: item, onRemoveCompany: onRemoveCompany, onSetEditableItem: onSetEditableItem }),
                    editableItemId === item.id && (React.createElement("div", { className: styles.editableForm },
                        React.createElement(FixPriceForm, { inter: true, currency: currency, services: possibleServices, company: item, onSaveCompany: onSaveCompany, onRemoveEditableItem: onRemoveEditableItem }))))), companies)))),
            !interAvailablePackages
                || (isEmpty(interAvailablePackages) && (React.createElement("div", { className: styles.emptyPackegesText }, t.noAvailablePackages))),
            React.createElement("div", { id: "interShippingError", className: classNames(styles, 'error', { show: interAvailablePackages && error }) }, error)));
    }
}
export default handlerShipping(InterShipping);
