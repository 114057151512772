import React from 'react';
import { isEmpty, isNil, map, pathOr } from 'ramda';
import { convertSrc, formatPrice, getNameText } from '../../../../../utils';
import ImageLoader from '../../../../../libs/react-image-loader';
import { Col } from '../../../../../layout';
import { Icon } from '../../../../../components/Icon';
import BannerLoading from '../../../../../components/Banner/BannerLoading';
import styles from './ProductsTableRow.module.scss';
import t from './i18n';
const ProductsTableRow = ({ item, onEdit, onDelete, index }) => {
    const { product } = item;
    const attributes = pathOr([], ['product', 'attributes'], item);
    return (React.createElement("div", { className: styles.itemRowWrap, onClick: () => {
            onEdit(item.rawId);
        }, onKeyDown: () => {
        }, role: "button", tabIndex: 0, "data-test": `productRow_${index}` },
        React.createElement(Col, { size: 4, sm: 4, md: 2, lg: 2, xl: 1 },
            React.createElement("div", { className: styles.foto }, !product || isNil(product.photoMain) || product.photoMain === '' ? (React.createElement(Icon, { type: "camera", size: 40 })) : (React.createElement(ImageLoader, { fit: true, src: convertSrc('small', product.photoMain), loader: React.createElement(BannerLoading, null) })))),
        React.createElement(Col, { size: 4, sm: 4, md: 4, lg: 3, xl: 2 },
            React.createElement("div", { className: styles.name },
                React.createElement("span", null, item.name))),
        React.createElement(Col, { size: 2, sm: 2, md: 2, lg: 2, xl: 2, xlVisible: true },
            React.createElement("div", null,
                React.createElement("span", null, item.categoryName))),
        React.createElement(Col, { size: 3, sm: 3, md: 3, lg: 3, xl: 2, mdVisible: true }, product
            && !isNil(product.price) && (React.createElement("span", null, `${formatPrice(product.price)} ${item.currency}`))),
        React.createElement(Col, { size: 3, sm: 3, md: 3, lg: 3, xl: 2, lgVisible: true }, product && !isNil(product.cashback) ? (React.createElement("span", null, `${(product.cashback * 100).toFixed(0)}%`)) : ('0%')),
        React.createElement(Col, { size: 2, sm: 2, md: 2, lg: 2, xl: 2, xlVisible: true }, !isEmpty(attributes) ? (React.createElement("div", { className: styles.characteristicItem },
            React.createElement("div", { className: styles.characteristicLabels }, map((attributeItem) => {
                const attributeName = getNameText(attributeItem.attribute.name, 'EN');
                return (React.createElement("div", { key: `attr-${attributeName}` }, `${attributeName}: `));
            }, attributes)),
            React.createElement("div", { className: styles.characteristicValues }, map((attributeItem) => {
                const attributeName = getNameText(attributeItem.attribute.name, 'EN');
                const val = attributeItem.value;
                return React.createElement("div", { key: `attr-${attributeName}` }, `${val}`);
            }, attributes)))) : (t.noCharacteristic)),
        React.createElement(Col, { size: 4, sm: 4, md: 3, lg: 1, xl: 1 },
            React.createElement("div", { className: styles.buttons },
                React.createElement("button", { className: styles.editButton, "data-test": `editProductButton_${index}` },
                    React.createElement(Icon, { type: "note", size: 32 })),
                React.createElement("button", { className: styles.deleteButton, onClick: (e) => onDelete(item.id, e), "data-test": `deleteProductButton_${index}` },
                    React.createElement(Icon, { type: "basket", size: 32 }))))));
};
export default ProductsTableRow;
