import { debug } from './log';
import { removeCookie, setCookie } from './cookiesOp';
const expirationTimeInDays = 14;
export const jwtCookieName = '__jwt';
export function clearJWT() {
    removeCookie(jwtCookieName);
}
export function setJWT(jwt, cookiesInstance) {
    debug('setJWT', jwt);
    const date = new Date();
    const today = date;
    const expirationDate = date;
    expirationDate.setDate(today.getDate() + expirationTimeInDays);
    removeCookie(jwtCookieName, cookiesInstance);
    setCookie(jwtCookieName, { value: jwt }, expirationDate, cookiesInstance);
}
