import React, { Component } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { clone, map, pathOr } from 'ramda';
import { Button, Input, RadioButton } from '../../../../../components/common';
import { Confirmation } from '../../../../../components/Confirmation';
import { calculatePayout } from '../../../../../relay/queries';
import { error, formatPrice } from '../../../../../utils';
import { withShowAlert } from '../../../../../components/Alerts/AlertContext';
import { ContextDecorator } from '../../../../../components/App';
import styles from './Balance.module.scss';
import t from './i18n';
class Balance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            calculatePayoutLoading: null,
            showModal: false,
            calculatePayoutData: null,
            checkedFeeEstimatedTime: null
            // walletAddress: null
        };
    }
    sendPayout = (currency) => {
        this.setState({
            calculatePayoutLoading: currency,
            showModal: true
        });
        const storeId = pathOr(null, ['me', 'myStore', 'rawId'], this.props);
        calculatePayout({
            variables: {
                input: {
                    storeId,
                    currency
                }
            },
            environment: this.props.environment
        })
            .then((data) => {
            if (data && data.calculatePayout) {
                this.setState({
                    calculatePayoutData: clone(data.calculatePayout)
                });
            }
            return true;
        })
            .finally(() => {
            // this.setState({calculatePayoutLoading: false});
        })
            .catch((err) => {
            error(err);
        });
    };
    /* handleCreateTransaction = () => {
      const {
        calculatePayoutData,
        checkedFeeEstimatedTime,
        walletAddress
      } = this.state;
  
      if (calculatePayoutData == null) {
        this.props.showAlert({
          type: 'danger',
          text: t.somethingGoingWrong,
          link: {text: t.close}
        });
        return;
      }
  
      const blockchainFeeData = find(propEq(parseFloat(checkedFeeEstimatedTime), 'estimatedTimeSeconds'))(calculatePayoutData.blockchainFeeOptions);
  
      if (blockchainFeeData == null) {
        this.props.showAlert({
          type: 'danger',
          text: t.somethingGoingWrong,
          link: {text: t.close}
        });
        return;
      }
  
      PayOutCryptoToSellerMutation({
        environment: this.props.environment,
        variables: {
          input: {
            clientMutationId: uuidv4(),
            orderIds: calculatePayoutData.orderIds,
            walletCurrency: calculatePayoutData.currency,
            walletAddress,
            blockchainFee: blockchainFeeData[0].value
          }
        },
        updater: (relayStore: any) => {
          const storeSubscription = relayStore.getRootField('updateStoreSubscription');
          const me = relayStore.getRoot()
          .getLinkedRecord('me');
          const myStore = me.getLinkedRecord('myStore');
          myStore.setLinkedRecord(storeSubscription, 'storeSubscription');
        }
      })
      .then(() => {
        this.props.showAlert({
          type: 'success',
          text: 'Все заебись!!!',
          link: {text: t.close}
        });
        return true;
      })
      .catch(() => {
        this.props.showAlert({
          type: 'danger',
          text: t.somethingGoingWrong,
          link: {text: t.close}
        });
      });
    }; */
    handleCloseModal = () => {
        this.setState({
            calculatePayoutData: null,
            checkedFeeEstimatedTime: null
            // walletAddress: null
        });
    };
    handleCheckedFee = (id) => {
        this.setState({ checkedFeeEstimatedTime: id });
    };
    handleChangeInput = (id) => (e) => {
        const { value } = e.target;
        this.setState({ [id]: value });
    };
    renderItem = (props) => {
        const { id, label, amount, value } = props;
        const { calculatePayoutLoading } = this.state;
        return (React.createElement("div", { className: styles.item },
            React.createElement("div", { className: styles.label }, `${label}:`),
            React.createElement("div", { className: styles.amount }, amount),
            Boolean(amount) && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles.input },
                    React.createElement(Input, { id: id, value: value, onChange: this.handleChangeInput(id), fullWidth: true, extraSmall: true, inline: true })),
                React.createElement("div", { className: styles.sendButton },
                    React.createElement(Button, { disabled: value === '', extraSmall: true, onClick: () => {
                            this.sendPayout(label);
                        }, isLoading: calculatePayoutLoading === label }, "Send"))))));
    };
    renderItemOther = (props) => {
        const { label, amount } = props;
        return (React.createElement("div", { className: styles.item },
            React.createElement("div", { className: styles.amount }, amount),
            React.createElement("div", { className: styles.label }, `${t.balanceIn} ${label}`)));
    };
    render() {
        const { calculatePayoutData, checkedFeeEstimatedTime } = this.state;
        const balance = pathOr([], ['myStore', 'getBalances'], this.props.me);
        return (React.createElement("div", { className: styles.container },
            React.createElement(Confirmation, { showModal: calculatePayoutData != null, handleCloseModal: this.handleCloseModal, title: "Title modal", description: "Desc modal", onCancel: this.handleCloseModal, 
                /* onConfirm={this.handleCreateTransaction} */
                confirmText: "Send", cancelText: "Cancel", disableConfirm: checkedFeeEstimatedTime == null }, calculatePayoutData != null && (React.createElement("div", { className: styles.modalBody },
                React.createElement("div", { className: styles.amount },
                    React.createElement("div", { className: styles.amountLabel }, "Amount:"),
                    React.createElement("div", { className: styles.amountValue },
                        React.createElement("span", null, `${calculatePayoutData.grossAmount} ${calculatePayoutData.currency}`))),
                React.createElement("div", { className: styles.fees },
                    React.createElement("div", { className: styles.feesLabel }, "Fee:"),
                    calculatePayout &&
                        map((item) => (React.createElement("div", { key: item.estimatedTimeSeconds, className: styles.feeRadioButton },
                            React.createElement(RadioButton, { inline: true, id: `${item.estimatedTimeSeconds}`, isChecked: `${item.estimatedTimeSeconds}` === checkedFeeEstimatedTime, onChange: this.handleCheckedFee }),
                            React.createElement("div", { className: styles.feeLabel },
                                `${formatPrice(parseFloat(item.value))} ${calculatePayoutData.currency}`,
                                React.createElement("br", null),
                                `Estimated time: ${item.estimatedTimeSeconds} sec.`))), calculatePayoutData.blockchainFeeOptions))))),
            React.createElement("div", { className: styles.wrap },
                React.createElement("div", { className: styles.line }, this.renderItemOther({
                    label: 'INR',
                    amount: balance.inr
                })))));
    }
}
export default createFragmentContainer(withShowAlert(ContextDecorator(Balance)), {
    me: graphql `
			fragment Balance_me on User {
				myStore {
					rawId
					getBalances {
						eur,
						inr
					}
				}
			}
    `
});
