import React, { Component } from 'react';
import moment from 'moment';
import { setCookie, getCookie } from '../../utils';
import { Icon } from '../Icon';
import styles from './HeaderDisclaimer.module.scss';
class HeaderDisclaimer extends Component {
    constructor(props) {
        super(props);
        this.state = { hidden: false };
    }
    componentDidMount() {
        const cookieDisclaimer = getCookie('disclaimer');
        if (cookieDisclaimer) {
            if (process.env.BROWSER) {
                this.handleHiddenDisclaimer();
            }
        }
    }
    handleHiddenDisclaimer = () => {
        this.setState({ hidden: true }, () => {
            setCookie('disclaimer', true, moment()
                .utc()
                .add(7, 'd')
                .toDate());
        });
    };
    render() {
        if (this.state.hidden) {
            return null;
        }
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.body },
                React.createElement("div", { className: styles.textWrap },
                    React.createElement("span", { className: styles.text }, "Dear users! Currently Graame is\u00A0working in\u00A0the\u00A0testing\u00A0mode. If\u00A0you have any questions or\u00A0problems, please follow the\u00A0link."),
                    React.createElement("a", { href: "https://graame.zendesk.com/hc/en-us/requests/new", className: styles.link, rel: "noopener noreferrer", target: "_blank" }, "Support")),
                React.createElement("button", { className: styles.cross, onClick: this.handleHiddenDisclaimer },
                    React.createElement(Icon, { type: "cross", size: 8 })))));
    }
}
export default HeaderDisclaimer;
