import React, { Component } from 'react';
import { assocPath, isEmpty, pathOr, pick, propOr } from 'ramda';
import { createFragmentContainer, graphql } from 'react-relay';
import { v4 as uuidv4 } from 'uuid';
import { Environment } from 'relay-runtime';
import PropTypes from 'prop-types';
import { ManageStore } from '../index';
import ModerationStatus from '../../../common/ModerationStatus';
import { withShowAlert } from '../../../../components/Alerts/AlertContext';
import { Page } from '../../../../components/App';
import { Button, Input } from '../../../../components/common';
import { AddressForm } from '../../../../components/AddressAutocomplete';
import { UpdateStoreMainMutation, UpdateStoreMutation } from '../../../../relay/mutations';
import { debug, error, fromRelayError } from '../../../../utils';
import styles from './Contacts.module.scss';
import t from './i18n';
class Contacts extends Component {
    static contextTypes = {
        environment: PropTypes.instanceOf(Environment).isRequired,
        currentUser: PropTypes.shape({
            id: PropTypes.string,
            rawId: PropTypes.number
        })
    };
    constructor(props) {
        super(props);
        this.state = {
            form: {
                email: '',
                phone: '',
                facebookUrl: '',
                instagramUrl: '',
                twitterUrl: '',
                cover: ''
            },
            addressFull: {
                value: '',
                country: '',
                countryCode: null,
                administrativeAreaLevel1: '',
                administrativeAreaLevel2: '',
                locality: '',
                political: '',
                postalCode: '',
                route: '',
                streetNumber: '',
                placeId: ''
            },
            formErrors: {},
            isLoading: false
        };
    }
    UNSAFE_componentWillMount() {
        const store = pathOr({}, ['myStore'], this.props.me);
        this.setState({
            form: pick([
                'email',
                'phone',
                'facebookUrl',
                'instagramUrl',
                'twitterUrl',
                'cover'
            ], store),
            addressFull: store.addressFull
        });
    }
    handleInputChange = (id) => (e) => {
        const { value } = e.target;
        const val = id === 'phone' ? value.replace(/\s/g, '') : value;
        this.setState((preState) => assocPath(['form', id], val, preState));
    };
    handleUpdateForm = (form) => {
        this.setState((preState) => assocPath(['addressFull', 'value'], form.address, preState));
    };
    handleChangeData = (addressFullData) => {
        this.setState((preState) => ({
            addressFull: {
                ...preState.addressFull,
                ...addressFullData
            }
        }));
    };
    handleLogoUpload = (url) => {
        const { environment } = this.context;
        const storeId = pathOr(null, ['me', 'myStore', 'id'], this.props);
        const variables = {
            input: {
                id: storeId,
                logo: url
            }
        };
        UpdateStoreMainMutation.commit({
            variables,
            environment,
            onCompleted: (response, errors) => {
                debug({
                    response,
                    errors
                });
                const relayErrors = fromRelayError({ source: { errors } });
                debug({ relayErrors });
                const statusError = pathOr({}, ['100', 'status'], relayErrors);
                if (!isEmpty(statusError)) {
                    this.props.showAlert({
                        type: 'danger',
                        text: `${t.error} "${statusError}"`,
                        link: { text: t.close }
                    });
                    return;
                }
                const parsingError = pathOr(null, ['300', 'message'], relayErrors);
                if (parsingError) {
                    debug('parsingError:', { parsingError });
                    this.props.showAlert({
                        type: 'danger',
                        text: t.somethingGoingWrong,
                        link: { text: t.close }
                    });
                    return;
                }
                this.props.showAlert({
                    type: 'success',
                    text: t.saved,
                    link: { text: '' }
                });
            },
            onError: (err) => {
                error(err);
                this.props.showAlert({
                    type: 'danger',
                    text: t.somethingGoingWrong,
                    link: { text: t.close }
                });
            }
        });
    };
    handleUpdate = () => {
        const { currentUser, environment } = this.context;
        const { me: { myStore } } = this.props;
        if (!currentUser || !currentUser.rawId || !myStore || !myStore.id) {
            this.props.showAlert({
                type: 'danger',
                text: t.somethingGoingWrong,
                link: { text: t.close }
            });
            return;
        }
        const { logoUrl, 
        // param 'country' enter for 'this.handleUpdateForm'
        form: { email, phone, facebookUrl, twitterUrl, instagramUrl, cover }, addressFull } = this.state;
        this.setState({
            formErrors: {},
            isLoading: true
        });
        const params = {
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    id: myStore.id || '',
                    logo: logoUrl,
                    email,
                    phone,
                    facebookUrl,
                    twitterUrl,
                    instagramUrl,
                    addressFull,
                    cover
                }
            },
            environment,
            onCompleted: (response, errors) => {
                debug({
                    response,
                    errors
                });
                const relayErrors = fromRelayError({ source: { errors } });
                debug({ relayErrors });
                this.setState(() => ({ isLoading: false }));
                const validationErrors = pathOr({}, ['100', 'messages'], relayErrors);
                if (!isEmpty(validationErrors)) {
                    this.setState({ formErrors: validationErrors });
                    return;
                }
                const statusError = pathOr({}, ['100', 'status'], relayErrors);
                if (!isEmpty(statusError)) {
                    this.props.showAlert({
                        type: 'danger',
                        text: `${t.error} "${statusError}"`,
                        link: { text: t.close }
                    });
                    return;
                }
                const parsingError = pathOr(null, ['300', 'message'], relayErrors);
                if (parsingError) {
                    debug('parsingError:', { parsingError });
                    this.props.showAlert({
                        type: 'danger',
                        text: t.somethingGoingWrong,
                        link: { text: t.close }
                    });
                    return;
                }
                this.props.showAlert({
                    type: 'success',
                    text: t.saved,
                    link: { text: '' }
                });
            },
            onError: (err) => {
                debug({ err });
                const relayErrors = fromRelayError(err);
                debug({ relayErrors });
                this.setState(() => ({ isLoading: false }));
                const validationErrors = pathOr({}, ['100', 'messages'], relayErrors);
                if (!isEmpty(validationErrors)) {
                    this.setState({ formErrors: validationErrors });
                    return;
                }
                const parsingError = pathOr(null, ['300', 'message'], relayErrors);
                if (parsingError) {
                    debug('parsingError:', { parsingError });
                    this.props.showAlert({
                        type: 'danger',
                        text: t.somethingGoingWrong,
                        link: { text: t.close }
                    });
                    return;
                }
                this.props.showAlert({
                    type: 'danger',
                    text: t.somethingGoingWrong,
                    link: { text: t.close }
                });
            }
        };
        UpdateStoreMutation.commit(params);
    };
    isSaveAvailable = () => {
        const status = pathOr(null, ['me', 'myStore', 'status'], this.props);
        return status === 'DRAFT' || status === 'DECLINE' || status === 'PUBLISHED';
    };
    // TODO: extract to helper
    renderInput = (input) => {
        const { id, label, icon, limit } = input;
        return (React.createElement("div", { className: styles.formItem },
            React.createElement(Input, { isUrl: Boolean(icon), icon: icon, id: id, value: propOr('', id, this.state.form) || '', label: label, onChange: this.handleInputChange(id), errors: propOr(null, id, this.state.formErrors), limit: limit, fullWidth: true })));
    };
    render() {
        const storeRawID = pathOr(null, ['me', 'myStore', 'rawId'], this.props);
        const status = pathOr(null, ['me', 'myStore', 'status'], this.props);
        const { isLoading, addressFull } = this.state;
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.form },
                status && (React.createElement("div", { className: styles.storeStatus },
                    React.createElement(ModerationStatus, { status: status, dataTest: `storeStatus_${status}`, link: process.env.REACT_APP_HOST && storeRawID
                            ? `${process.env.REACT_APP_HOST}/store/${storeRawID}`
                            : null }))),
                React.createElement("div", { className: styles.wrap },
                    this.renderInput({
                        id: 'email',
                        label: t.labelEmail,
                        limit: 50
                    }),
                    this.renderInput({
                        id: 'phone',
                        label: t.labelPhone
                    }),
                    this.renderInput({
                        id: 'facebookUrl',
                        label: 'Facebook',
                        icon: 'facebook'
                    }),
                    this.renderInput({
                        id: 'instagramUrl',
                        label: 'Instagram',
                        icon: 'instagram'
                    }),
                    this.renderInput({
                        id: 'twitterUrl',
                        label: 'Twitter',
                        icon: 'twitter'
                    }),
                    React.createElement("div", { className: styles.formItem },
                        React.createElement("div", { className: styles.address },
                            React.createElement(AddressForm, { country: addressFull.country, address: addressFull.value, addressFull: addressFull, onChangeData: this.handleChangeData })))),
                React.createElement("div", { className: styles.buttonsPanel },
                    React.createElement("div", { className: styles.saveButton },
                        React.createElement(Button, { big: true, fullWidth: true, onClick: this.handleUpdate, isLoading: isLoading, disabled: !this.isSaveAvailable() }, t.save)),
                    status
                        && status === 'MODERATION' && (React.createElement("div", { className: styles.warnMessage }, t.storeIsOnModeration)),
                    status
                        && status === 'BLOCKED' && (React.createElement("div", { className: styles.warnMessage }, t.storeIsBlocked))))));
    }
}
export default createFragmentContainer(withShowAlert(Page(ManageStore({
    OriginalComponent: Contacts,
    active: 'contacts',
    title: 'Contacts'
}))), {
    me: graphql `
			fragment Contacts_me on User {
				myStore {
					id
					rawId
					status
					name {
						lang
						text
					}
					logo
					cover
					email
					phone
					facebookUrl
					twitterUrl
					instagramUrl
					addressFull {
						value
						country
						administrativeAreaLevel1
						administrativeAreaLevel2
						locality
						political
						postalCode
						route
						streetNumber
						placeId
					}
				}
			}
    `
});
