import React, { PureComponent } from 'react';
import { addIndex, filter, isEmpty, map, pathOr } from 'ramda';
import { Icon } from '../../../../../../../components/Icon';
import { classNames, formatPrice, info } from '../../../../../../../utils';
import styles from './Row.module.scss';
class Row extends PureComponent {
    handleCheckboxClick = (id) => {
        info('id', id);
    };
    handleExpandClick = () => {
        const rawId = pathOr(null, ['variant', 'rawId'], this.props);
        if (rawId) {
            this.props.onExpandClick(rawId);
        }
    };
    handleDelete = (e) => {
        e.stopPropagation();
        const id = pathOr(null, ['variant', 'id'], this.props);
        if (id) {
            this.props.handleDeleteVariant(id);
        }
    };
    handleCopy = (variant, e) => {
        e.stopPropagation();
        this.props.onCopyVariant(variant);
    };
    render() {
        const { currency, variant } = this.props;
        const { 
        // rawId,
        vendorCode, price, cashback, discount, attributes: attrs, stocks } = variant;
        let filteredStocks = [];
        if (stocks) {
            filteredStocks = filter((item) => item.quantity > 0, stocks);
        }
        return (React.createElement("div", { className: styles.container, onClick: this.handleExpandClick, onKeyDown: () => {
            }, role: "button", tabIndex: 0, "data-test": "toggleOpenVariantButton" },
            React.createElement("div", { className: styles.variant },
                React.createElement("div", { className: classNames(styles, 'td', 'tdArticle') },
                    React.createElement("span", { className: classNames(styles, 'text', 'vendorCodeText') }, vendorCode || '')),
                React.createElement("div", { className: classNames(styles, 'td', 'tdPrice') },
                    React.createElement("span", { className: classNames(styles, 'text', 'priceText') }, `${formatPrice(price)} ${currency.label}`)),
                React.createElement("div", { className: classNames(styles, 'td', 'tdCashback') },
                    React.createElement("span", { className: classNames(styles, 'text', 'cashbackText') },
                        React.createElement("strong", null, !cashback ? 0 : Math.round(cashback * 100)),
                        "%")),
                React.createElement("div", { className: classNames(styles, 'td', 'tdDiscount') },
                    React.createElement("span", { className: classNames(styles, 'text', 'discountText') },
                        React.createElement("strong", null, !discount ? 0 : Math.round(discount * 100)),
                        "%")),
                React.createElement("div", { className: classNames(styles, 'td', 'tdCharacteristics') },
                    React.createElement("div", { className: styles.characteristicItem },
                        React.createElement("div", { className: styles.characteristicLabels }, map((item) => {
                            const name = pathOr('', ['attribute', 'name', 0, 'text'], item);
                            return React.createElement("div", { key: `attr-${name}` }, `${name}: `);
                        }, attrs)),
                        React.createElement("div", { className: styles.characteristicValues }, map((item) => {
                            const name = pathOr('', ['attribute', 'name', 0, 'text'], item);
                            const val = item.value;
                            return React.createElement("div", { key: `attr-${name}` }, `${val}`);
                        }, attrs)))),
                React.createElement("div", { className: classNames(styles, 'td', 'tdCount') },
                    React.createElement("div", { className: styles.storagesItem },
                        React.createElement("div", { className: styles.storagesLabels },
                            addIndex(map)((item, idx) => {
                                const warehouseName = pathOr('', ['warehouse', 'name'], item);
                                const warehouseSlug = pathOr('', ['warehouse', 'slug'], item);
                                return idx >= 2 ? false : (React.createElement("div", { key: item.id, className: styles.warehouseName }, warehouseName || `Storage ${warehouseSlug}`));
                            }, filteredStocks),
                            !isEmpty(filteredStocks)
                                && filteredStocks.length > 2 && (React.createElement("div", { className: styles.more }, `+${filteredStocks.length - 2} ${filteredStocks.length > 3 ? 'storages' : 'storage'}`)),
                            isEmpty(filteredStocks) && React.createElement("div", null, "No storages")),
                        React.createElement("div", { className: styles.storagesValues }, addIndex(map)((item, idx) => (idx >= 2 ? (false) : (React.createElement("div", { key: item.id },
                            React.createElement("strong", null, item.quantity)))), filteredStocks)))),
                React.createElement("div", { className: classNames(styles, 'td', 'tdBasket') },
                    React.createElement("button", { className: styles.deleteButton, onClick: (e) => this.handleCopy(this.props.variant, e), "data-test": "deleteVariantButton" },
                        React.createElement(Icon, { type: "copy", size: 32 }))),
                React.createElement("div", { className: classNames(styles, 'td', 'tdCopy') },
                    React.createElement("button", { className: styles.deleteButton, onClick: this.handleDelete, "data-test": "deleteVariantButton" },
                        React.createElement(Icon, { type: "basket", size: 32 }))))));
    }
}
export default Row;
