/**
 * @generated SignedSource<<f3799d1331ebce71f1c3735c03e26d3c>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawId",
        "storageKey": null
    }, v4 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lang",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "text",
            "storageKey": null
        }
    ], v5 = {
        "alias": null,
        "args": null,
        "concreteType": "Translation",
        "kind": "LinkedField",
        "name": "name",
        "plural": true,
        "selections": (v4 /*: any*/),
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "concreteType": "Translation",
        "kind": "LinkedField",
        "name": "shortDescription",
        "plural": true,
        "selections": (v4 /*: any*/),
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "concreteType": "Translation",
        "kind": "LinkedField",
        "name": "longDescription",
        "plural": true,
        "selections": (v4 /*: any*/),
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
    }, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "defaultLanguage",
        "storageKey": null
    }, v10 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "BaseProductsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "BaseProduct",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ], v11 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 100
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "CreateStoreMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Store",
                    "kind": "LinkedField",
                    "name": "createStore",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        (v9 /*: any*/),
                        {
                            "alias": "baseProducts",
                            "args": null,
                            "concreteType": "BaseProductsConnection",
                            "kind": "LinkedField",
                            "name": "__Wizard_baseProducts_connection",
                            "plural": false,
                            "selections": (v10 /*: any*/),
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "CreateStoreMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Store",
                    "kind": "LinkedField",
                    "name": "createStore",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        (v9 /*: any*/),
                        {
                            "alias": null,
                            "args": (v11 /*: any*/),
                            "concreteType": "BaseProductsConnection",
                            "kind": "LinkedField",
                            "name": "baseProducts",
                            "plural": false,
                            "selections": (v10 /*: any*/),
                            "storageKey": "baseProducts(first:100)"
                        },
                        {
                            "alias": null,
                            "args": (v11 /*: any*/),
                            "filters": null,
                            "handle": "connection",
                            "key": "Wizard_baseProducts",
                            "kind": "LinkedHandle",
                            "name": "baseProducts"
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "6f45cd49c0e9774cb09a9598217b057f",
            "id": null,
            "metadata": {
                "connection": [
                    {
                        "count": null,
                        "cursor": null,
                        "direction": "forward",
                        "path": [
                            "createStore",
                            "baseProducts"
                        ]
                    }
                ]
            },
            "name": "CreateStoreMutation",
            "operationKind": "mutation",
            "text": "mutation CreateStoreMutation(\n  $input: CreateStoreInput!\n) {\n  createStore(input: $input) {\n    id\n    rawId\n    name {\n      lang\n      text\n    }\n    shortDescription {\n      lang\n      text\n    }\n    longDescription {\n      lang\n      text\n    }\n    slug\n    defaultLanguage\n    baseProducts(first: 100) {\n      edges {\n        node {\n          id\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "6aae24c541a17c3a645d931dde477fe5";
export default node;
