import React, { Component } from 'react';
import styles from './Tabs.module.scss';
import { classNames } from '../../../../utils';
class Tabs extends Component {
    static defaultProps = { selected: 0 };
    constructor(props) {
        super(props);
        this.state = { selected: this.props.selected };
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.selected !== this.props.selected) {
            this.setState({ selected: nextProps.selected });
        }
    }
    /**
     * Set the selected's tabs index
     *
     * @param {any} evt
     * @param {number} index
     * @return {void}
     */
    handleClick = (evt, index) => {
        evt.preventDefault();
        this.setState({ selected: index });
    };
    /**
     * Renders the tab's
     * @return {ReactElement<any>}
     */
    renderTitles = () => {
        const { children } = this.props;
        const { selected } = this.state;
        /**
         * @param {Node} child
         * @param {number} index
         * @return {Node}
         */
        const buildLabels = (child, index) => {
            const activeClass = selected === index ? 'active' : '';
            return (React.createElement("div", { onKeyPress: (e) => e.preventDefault(), role: "none", key: index, className: classNames(styles, 'label', `${activeClass}`), onClick: (e) => this.handleClick(e, index) },
                React.createElement("span", null, child.props.label)));
        };
        return React.createElement("div", { className: styles.labels }, children.map(buildLabels));
    };
    /**
     * Renders the tab's content
     *
     * @return {Node}
     */
    renderContent = () => {
        const { selected } = this.state;
        return React.createElement("div", null, this.props.children[selected]);
    };
    render() {
        return (React.createElement("div", { className: styles.container },
            this.renderTitles(),
            this.renderContent()));
    }
}
export default Tabs;
