/**
 * @generated SignedSource<<3a5d002a299d07111c14d89dc55113b6>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "after"
    }, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "autocompleteValue"
    }, v2 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "first"
    }, v3 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "searchTerm"
    }, v4 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "storeId"
    }, v5 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "storeId"
        }
    ], v6 = {
        "kind": "Variable",
        "name": "after",
        "variableName": "after"
    }, v7 = {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
    }, v8 = {
        "kind": "Variable",
        "name": "searchTerm",
        "variableName": "searchTerm"
    }, v9 = [
        (v6 /*: any*/),
        (v7 /*: any*/),
        (v8 /*: any*/),
        {
            "kind": "Literal",
            "name": "visibility",
            "value": "active"
        }
    ], v10 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v11 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawId",
        "storageKey": null
    }, v12 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
    }, v13 = {
        "alias": null,
        "args": null,
        "concreteType": "Translation",
        "kind": "LinkedField",
        "name": "name",
        "plural": true,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lang",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v14 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "price",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/),
                (v4 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "StoreItems_shop_Query",
            "selections": [
                {
                    "alias": null,
                    "args": (v5 /*: any*/),
                    "concreteType": "Store",
                    "kind": "LinkedField",
                    "name": "store",
                    "plural": false,
                    "selections": [
                        {
                            "args": [
                                (v6 /*: any*/),
                                {
                                    "kind": "Variable",
                                    "name": "autocompleteValue",
                                    "variableName": "autocompleteValue"
                                },
                                (v7 /*: any*/),
                                (v8 /*: any*/)
                            ],
                            "kind": "FragmentSpread",
                            "name": "StoreItems_shop"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v4 /*: any*/),
                (v2 /*: any*/),
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v3 /*: any*/)
            ],
            "kind": "Operation",
            "name": "StoreItems_shop_Query",
            "selections": [
                {
                    "alias": null,
                    "args": (v5 /*: any*/),
                    "concreteType": "Store",
                    "kind": "LinkedField",
                    "name": "store",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": (v9 /*: any*/),
                            "concreteType": "BaseProductsSearchConnection",
                            "kind": "LinkedField",
                            "name": "findProduct",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "BaseProductsEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "BaseProduct",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                (v10 /*: any*/),
                                                (v11 /*: any*/),
                                                (v12 /*: any*/),
                                                (v13 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Store",
                                                    "kind": "LinkedField",
                                                    "name": "store",
                                                    "plural": false,
                                                    "selections": [
                                                        (v13 /*: any*/),
                                                        (v10 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Category",
                                                    "kind": "LinkedField",
                                                    "name": "category",
                                                    "plural": false,
                                                    "selections": [
                                                        (v11 /*: any*/),
                                                        (v10 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "storeId",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": [
                                                        {
                                                            "kind": "Literal",
                                                            "name": "first",
                                                            "value": 1
                                                        }
                                                    ],
                                                    "concreteType": "ProductsConnection",
                                                    "kind": "LinkedField",
                                                    "name": "products",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "ProductsEdge",
                                                            "kind": "LinkedField",
                                                            "name": "edges",
                                                            "plural": true,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "Product",
                                                                    "kind": "LinkedField",
                                                                    "name": "node",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v10 /*: any*/),
                                                                        (v11 /*: any*/),
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "discount",
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "photoMain",
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "cashback",
                                                                            "storageKey": null
                                                                        },
                                                                        (v14 /*: any*/),
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "CustomerPrice",
                                                                            "kind": "LinkedField",
                                                                            "name": "customerPrice",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                (v14 /*: any*/),
                                                                                (v12 /*: any*/)
                                                                            ],
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "ProdAttrValue",
                                                                            "kind": "LinkedField",
                                                                            "name": "attributes",
                                                                            "plural": true,
                                                                            "selections": [
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "concreteType": "Attribute",
                                                                                    "kind": "LinkedField",
                                                                                    "name": "attribute",
                                                                                    "plural": false,
                                                                                    "selections": [
                                                                                        (v10 /*: any*/)
                                                                                    ],
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "value",
                                                                                    "storageKey": null
                                                                                }
                                                                            ],
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": "products(first:1)"
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "rating",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "__typename",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "cursor",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PageInfoProductsSearch",
                                    "kind": "LinkedField",
                                    "name": "pageInfo",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "endCursor",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "hasNextPage",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": (v9 /*: any*/),
                            "filters": [
                                "searchTerm",
                                "visibility"
                            ],
                            "handle": "connection",
                            "key": "StoreItems_findProduct",
                            "kind": "LinkedHandle",
                            "name": "findProduct"
                        },
                        {
                            "alias": null,
                            "args": [
                                {
                                    "kind": "Literal",
                                    "name": "first",
                                    "value": 8
                                },
                                {
                                    "kind": "Variable",
                                    "name": "name",
                                    "variableName": "autocompleteValue"
                                }
                            ],
                            "concreteType": "FullNameConnection",
                            "kind": "LinkedField",
                            "name": "autoCompleteProductName",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "FullNameEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "node",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v10 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "f70009adf297f229f651f86178da55ff",
            "id": null,
            "metadata": {},
            "name": "StoreItems_shop_Query",
            "operationKind": "query",
            "text": "query StoreItems_shop_Query(\n  $storeId: Int!\n  $first: Int\n  $after: ID\n  $autocompleteValue: String!\n  $searchTerm: SearchProductInput!\n) {\n  store(id: $storeId) {\n    ...StoreItems_shop_2oke43\n    id\n  }\n}\n\nfragment StoreItems_shop_2oke43 on Store {\n  findProduct(first: $first, after: $after, searchTerm: $searchTerm, visibility: \"active\") {\n    edges {\n      node {\n        id\n        rawId\n        currency\n        name {\n          text\n          lang\n        }\n        store {\n          name {\n            text\n            lang\n          }\n          id\n        }\n        category {\n          rawId\n          id\n        }\n        storeId\n        products(first: 1) {\n          edges {\n            node {\n              id\n              rawId\n              discount\n              photoMain\n              cashback\n              price\n              customerPrice {\n                price\n                currency\n              }\n              attributes {\n                attribute {\n                  id\n                }\n                value\n              }\n            }\n          }\n        }\n        rating\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  autoCompleteProductName(first: 8, name: $autocompleteValue) {\n    edges {\n      node\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "a8566d549dcf4362fd90a2c509f7e6a9";
export default node;
