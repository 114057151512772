import React, { Component } from 'react';
import { Cards } from '../../Store/Finances';
import { RadioButton } from '../../../../components/common/RadioButton';
import styles from './Step4.module.scss';
import t from './i18n';
class Step4 extends Component {
    constructor(props) {
        super(props);
        if (props.subscriptionCurrency == null) {
            props.onSetSubscribe('INR');
        }
    }
    handleChangeRadio = (currency) => {
        this.props.onSetSubscribe(currency);
    };
    render() {
        const { me, subscriptionCurrency } = this.props;
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.topTitle },
                React.createElement("div", { className: styles.title }, t.fillTheCardInformation),
                React.createElement("div", { className: styles.desc },
                    `${t.yourCardWillBe} `,
                    React.createElement("a", { href: "https://graame.com/", target: "_blank", rel: "noopener noreferrer" }, t.learnMore))),
            React.createElement("div", { className: styles.cards },
                React.createElement(Cards, { me: me, wizard: true })),
            me.stripeCustomer && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles.bottomTitle },
                    React.createElement("div", { className: styles.title }, t.paymentMethodForPlacingGoods),
                    React.createElement("div", { className: styles.desc }, t.chooseTheMethodOfPayment)),
                React.createElement("div", { className: styles.subscribeButtons },
                    React.createElement("div", { className: styles.subscribeButton },
                        React.createElement("div", { className: styles.price },
                            React.createElement("strong", null, `${t.fiatPrice} `),
                            t.perDay),
                        React.createElement("div", { className: styles.priceDesc }, t.theMoneyWillBeWithdrawn),
                        React.createElement("div", { className: styles.radio },
                            React.createElement(RadioButton, { id: "EUR", isChecked: subscriptionCurrency !== 'STQ', onChange: this.handleChangeRadio }))),
                    React.createElement("div", { className: styles.subscribeButton },
                        React.createElement("div", { className: styles.price },
                            React.createElement("strong", null, `${t.cryptoPrice} `),
                            t.perDay),
                        React.createElement("div", { className: styles.priceDesc }, t.specialWalletWillBeCreated),
                        React.createElement("div", { className: styles.radio },
                            React.createElement(RadioButton, { id: "STQ", isChecked: subscriptionCurrency === 'STQ', onChange: this.handleChangeRadio }))))))));
    }
}
export default Step4;
