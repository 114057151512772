import { graphql, commitMutation } from 'react-relay';
const mutation = graphql `
    mutation SetCommentInCartMutation($input: SetCommentInCartInput!) {
        setCommentInCart(input: $input) {
            ...Cart_cart
        }
    }
`;
const commit = (params) => commitMutation(params.environment, {
    mutation,
    variables: params.variables,
    onCompleted: params.onCompleted,
    onError: params.onError
});
export default { commit };
