import React from 'react';
import { Button } from '../../../../components/common/Button';
import { Row, Col } from '../../../../layout';
import AddressInfo from '../AddressInfo';
import styles from './CheckoutProducts.module.scss';
import t from './i18n';
const CheckoutProducts = ({ orderInput, me, onChangeStep }) => (React.createElement(Row, null,
    React.createElement(Col, { size: 12 },
        React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.infoContainer },
                React.createElement(Row, null,
                    React.createElement(Col, { size: 12, smVisible: true },
                        React.createElement("div", { className: styles.header },
                            React.createElement("div", { className: styles.title }, t.summary),
                            React.createElement("div", { className: styles.wrapperAddressButton },
                                React.createElement("div", { className: styles.containerAddressButton },
                                    React.createElement(Button, { big: true, contour: true, onClick: () => {
                                            onChangeStep(1);
                                        }, type: "button", dataTest: "changeAddress" },
                                        React.createElement("span", null, t.replaceAddress)))))),
                    React.createElement(Col, { size: 12 }, orderInput.addressFull.value && (React.createElement("div", { className: styles.addressInfoWrapper },
                        React.createElement(AddressInfo, { addressFull: orderInput.addressFull, receiverName: orderInput.receiverName
                                || `${me.firstName} ${me.lastName}`, email: me.email })))),
                    React.createElement(Col, { size: 12, smHidden: true },
                        React.createElement("div", { className: styles.containerAddressButton2 },
                            React.createElement(Button, { big: true, contour: true, whireframe: true, onClick: () => {
                                    onChangeStep(1);
                                }, type: "button", dataTest: "changeAddress" },
                                React.createElement("span", null, t.replaceAddress))))))))));
export default CheckoutProducts;
