/**
 * @generated SignedSource<<370f40e6578004a632b1593a97268c64>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lang",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "text",
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "routes_ManageStore_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditStore_me"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "routes_ManageStore_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Store",
                            "kind": "LinkedField",
                            "name": "myStore",
                            "plural": false,
                            "selections": [
                                (v0 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "rawId",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Translation",
                                    "kind": "LinkedField",
                                    "name": "name",
                                    "plural": true,
                                    "selections": (v1 /*: any*/),
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "logo",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cover",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "slogan",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "defaultLanguage",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "slug",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "status",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Translation",
                                    "kind": "LinkedField",
                                    "name": "shortDescription",
                                    "plural": true,
                                    "selections": (v1 /*: any*/),
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Translation",
                                    "kind": "LinkedField",
                                    "name": "longDescription",
                                    "plural": true,
                                    "selections": (v1 /*: any*/),
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "c47a4233b9f1750996146f032703f75e",
            "id": null,
            "metadata": {},
            "name": "routes_ManageStore_Query",
            "operationKind": "query",
            "text": "query routes_ManageStore_Query {\n  me {\n    id\n    ...EditStore_me\n  }\n}\n\nfragment EditStore_me on User {\n  id\n  myStore {\n    id\n    rawId\n    name {\n      lang\n      text\n    }\n    logo\n    cover\n    slogan\n    defaultLanguage\n    slug\n    status\n    shortDescription {\n      lang\n      text\n    }\n    longDescription {\n      lang\n      text\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "12fbf7a80a7d0e13dc3a80517c5a40ac";
export default node;
