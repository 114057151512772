import { isEmpty, isNil, map } from 'ramda';
import { extractText } from '../../../utils';
function build({ name, rawId, children }) {
    return {
        name: extractText(name),
        rawId,
        children: !isEmpty(children) && !isNil(children) ? map(build, children) : []
    };
}
const buildMobileCategories = (categories) => map(build, categories ? categories.children : []);
export default buildMobileCategories;
