/**
 * @generated SignedSource<<cdd97a31ca7c0518f07c11ec215d1464>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [
        {
            "defaultValue": 0,
            "kind": "LocalArgument",
            "name": "slug"
        }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Profile_me",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Orders_me"
        },
        {
            "args": [
                {
                    "kind": "Variable",
                    "name": "slug",
                    "variableName": "slug"
                }
            ],
            "kind": "FragmentSpread",
            "name": "Order_me"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PersonalData_me"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ShippingAddresses_me"
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rawId",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "avatar",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "provider",
            "storageKey": null
        }
    ],
    "type": "User",
    "abstractKey": null
};
node.hash = "351d1914d674a5d76526e7ce45c796c3";
export default node;
