import React from 'react';
import styles from '../OrderInvoice.module.scss';
const OrderInvoiceTable = ({ children }) => (React.createElement(React.Fragment, null,
    React.createElement("div", { className: styles.tableHeader },
        React.createElement("div", { className: styles.tableHeaderItem }, "\u2116"),
        React.createElement("div", { className: styles.tableHeaderItem }, "Description"),
        React.createElement("div", { className: styles.tableHeaderItem }, "Quantity"),
        React.createElement("div", { className: styles.tableHeaderItem }, "Unit Price"),
        React.createElement("div", { className: styles.tableHeaderItem }, "Price")),
    children));
export default OrderInvoiceTable;
