import React, { Component } from 'react';
import { map } from 'ramda';
import PropTypes from 'prop-types';
import { Icon } from '../../../../../../components/Icon';
import { UploadWrapper } from '../../../../../../components/Upload';
import { classNames, convertSrc, error, uploadFile, uploadMultipleFiles } from '../../../../../../utils';
import { withShowAlert } from '../../../../../../components/Alerts/AlertContext';
import styles from './Photos.module.scss';
import t from './i18n';
class Photos extends Component {
    static contextTypes = { directories: PropTypes.object };
    constructor(props) {
        super(props);
        this.state = {
            isMainPhotoUploading: false,
            isAdditionalPhotoUploading: false
        };
    }
    handleOnUploadMainPhoto = (e) => {
        e.preventDefault();
        this.setState({ isMainPhotoUploading: true });
        uploadFile(e.target.files[0])
            .then((result) => {
            if (!result || result.url == null) {
                error(result);
                alert('Error :(');
            }
            this.props.onAddMainPhoto(result.url || '');
            return true;
        })
            .finally(() => {
            this.setState({ isMainPhotoUploading: false });
        })
            .catch((err) => {
            this.props.showAlert({
                type: 'danger',
                text: err.message,
                link: { text: t.close }
            });
        });
    };
    handleOnUploadPhoto = (e) => {
        e.preventDefault();
        this.setState({ isAdditionalPhotoUploading: true });
        const { files } = e.target;
        if (files.length > 1) {
            uploadMultipleFiles(files)
                .then((result) => {
                if (!result) {
                    error(result);
                    alert('Error :(');
                }
                this.props.onAddPhoto(map((item) => item.url, result));
                return true;
            })
                .finally(() => {
                this.setState({ isAdditionalPhotoUploading: false });
            })
                .catch((err) => {
                this.props.showAlert({
                    type: 'danger',
                    text: err.message,
                    link: { text: 'Close.' }
                });
            });
        }
        if (files.length === 1) {
            uploadFile(files[0])
                .then((result) => {
                if (!result || result.url == null) {
                    error(result);
                    alert('Error :(');
                }
                this.props.onAddPhoto([result.url]);
                return true;
            })
                .finally(() => {
                this.setState({ isAdditionalPhotoUploading: false });
            })
                .catch((err) => {
                this.props.showAlert({
                    type: 'danger',
                    text: err.message,
                    link: { text: 'Close.' }
                });
            });
        }
    };
    render() {
        const { photoMain, photos: items, onRemovePhoto, isMainVariant } = this.props;
        const { isMainPhotoUploading, isAdditionalPhotoUploading } = this.state;
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.mainPhoto },
                React.createElement("div", { className: classNames(styles, 'upload', 'mainPhotoUpload') },
                    React.createElement(UploadWrapper, { id: `${isMainVariant ? 'main-variant' : ''}main-photo`, onUpload: this.handleOnUploadMainPhoto, buttonHeight: 15, buttonWidth: 15, buttonIconType: "camera", buttonLabel: t.labelAddMainPhoto, loading: isMainPhotoUploading, dataTest: "productPhotosUploader" })),
                photoMain && (React.createElement("div", { className: classNames(styles, 'item', 'mainPhotoItem') },
                    React.createElement("div", { className: styles.mainPhotoItemWrap },
                        React.createElement("img", { src: convertSrc('small', photoMain), alt: "img" })),
                    React.createElement("div", { className: styles.remove, onClick: () => {
                            onRemovePhoto(photoMain);
                        }, onKeyDown: () => {
                        }, role: "button", tabIndex: 0 },
                        React.createElement(Icon, { type: "basket", size: 32 }))))),
            React.createElement("div", { className: styles.additionalPhotos },
                React.createElement("div", { className: styles.upload },
                    React.createElement(UploadWrapper, { id: `${isMainVariant ? 'main-variant' : ''}additional-photos`, multiple: true, onUpload: this.handleOnUploadPhoto, buttonHeight: 10, buttonWidth: 10, buttonIconType: "camera", buttonLabel: t.labelAddPhoto, loading: isAdditionalPhotoUploading, dataTest: "productPhotosUploader" })),
                items
                    && items.length > 0 && (React.createElement(React.Fragment, null, items.map((item) => (React.createElement("div", { key: item, className: styles.item },
                    React.createElement("div", { className: styles.itemWrap },
                        React.createElement("img", { src: convertSrc('small', item), alt: "img" })),
                    React.createElement("div", { className: styles.remove, onClick: () => {
                            onRemovePhoto(item);
                        }, onKeyDown: () => {
                        }, role: "button", tabIndex: 0 },
                        React.createElement(Icon, { type: "basket", size: 32 }))))))))));
    }
}
export default withShowAlert(Photos);
