import React, { PureComponent } from 'react';
import { classNames, formatPrice, getExchangePrice } from '../../../utils';
import { Col, Row } from '../../../layout';
import { Button } from '../../../components/common/Button';
import { ContextDecorator } from '../../../components/App';
import styles from './CheckoutSidebar.module.scss';
class CheckoutSidebar extends PureComponent {
    render() {
        const { step, goToCheckout, isLoadingCheckout, buyNowData, onCheckout, shippingId, currency, directories } = this.props;
        const { currencyExchange } = directories;
        const exchangePrice = getExchangePrice({
            price: buyNowData.totalCost,
            currency,
            currencyExchange,
            withSymbol: true
        });
        return (React.createElement("div", null,
            React.createElement("div", { className: styles.paperWrapper },
                React.createElement("div", { className: classNames(styles, 'corner', 'tl') }),
                React.createElement("div", { className: styles.paper }),
                React.createElement("div", { className: classNames(styles, 'corner', 'tr') })),
            React.createElement("div", { className: styles.container },
                React.createElement("div", { className: styles.title }, "Subtotal"),
                React.createElement("div", { className: styles.totalsContainer },
                    React.createElement(Row, null,
                        React.createElement(Col, { size: 12, sm: 4, lg: 12 },
                            React.createElement("div", { className: styles.attributeContainer },
                                React.createElement("div", { className: styles.label }, "Subtotal"),
                                React.createElement("div", { className: styles.value }, `${formatPrice(buyNowData.subtotal || 0, 2)} ${currency}`))),
                        React.createElement(Col, { size: 12, sm: 4, lg: 12 },
                            React.createElement("div", { className: styles.attributeContainer },
                                React.createElement("div", { className: styles.label }, "Delivery"),
                                React.createElement("div", { className: styles.value }, `${formatPrice(buyNowData.deliveryCost || 0, 2)} ${currency}`))),
                        buyNowData.couponsDiscounts !== 0 && (React.createElement(Col, { size: 12, sm: 4, lg: 12 },
                            React.createElement("div", { className: styles.attributeContainer },
                                React.createElement("div", { className: styles.label }, "Coupons discount"),
                                React.createElement("div", { className: styles.value }, `−${formatPrice(buyNowData.couponsDiscounts || 0, 2)} ${currency}`)))),
                        React.createElement(Col, { size: 12, sm: 4, lg: 12 },
                            React.createElement("div", { className: styles.attributeContainer },
                                React.createElement("div", { className: styles.label },
                                    "Total",
                                    ' ',
                                    React.createElement("span", { className: styles.subLabel },
                                        "(",
                                        buyNowData.totalCount || 0,
                                        " items)")),
                                React.createElement("div", { className: styles.totalCost },
                                    React.createElement("div", { className: classNames(styles, 'value', 'bold') }, `${formatPrice(buyNowData.totalCost || 0, 2)} ${currency}`),
                                    exchangePrice != null && (React.createElement("div", { className: styles.exchangePrice }, exchangePrice))))))),
                React.createElement("div", { className: styles.checkout },
                    React.createElement(Button, { id: "cartTotalCheckout", disabled: isLoadingCheckout
                            || (step === 2 && (buyNowData.totalCost === 0 || !shippingId)), isLoading: isLoadingCheckout, big: true, onClick: step === 1 ? goToCheckout : onCheckout, dataTest: "checkoutNext" }, step === 1 ? 'Next' : 'Checkout'))),
            React.createElement("div", { className: styles.paperWrapper },
                React.createElement("div", { className: classNames(styles, 'corner', 'bl') }),
                React.createElement("div", { className: classNames(styles, 'paper', 'bottom') }),
                React.createElement("div", { className: classNames(styles, 'corner', 'br') }))));
    }
}
export default ContextDecorator(CheckoutSidebar);
