import React, { Component } from 'react';
import { find, includes, isEmpty, isNil, propEq } from 'ramda';
import ImageLoader from '../../../../libs/react-image-loader';
import { classNames, convertSrc } from '../../../../utils';
import { Icon } from '../../../../components/Icon';
import styles from './ProductThumbnails.module.scss';
import { sortByProp } from '../utils';
class ProductThumbnails extends Component {
    static defaultProps = {
        title: '',
        row: false,
        isReset: false,
        isFirstSelected: false
    };
    static getDerivedStateFromProps(nextProps, prevState) {
        const { isReset, isFirstSelected } = nextProps;
        if (isReset) {
            return { selected: isFirstSelected ? 0 : null };
        }
        return prevState;
    }
    handleClick = (option) => {
        this.props.onClick(option);
    };
    state = {
        selected: null
    };
    render() {
        const { options, row, title, isOnSelected, selectedValue, availableValues, productVariant } = this.props;
        const mapOptions = (option) => {
            const productVariantAttribute = find(propEq(option.label, 'value'))(productVariant.attributes);
            const img = productVariantAttribute ? productVariantAttribute.metaField : option.image;
            const isDisabled = availableValues ? !includes(option.label, availableValues) : option.state === 'disabled';
            return (React.createElement("button", { key: `${option.label || option.id}`, className: styles.button, "data-test": `productThumbail${option.label}`, onClick: () => this.handleClick(option) }, option.image ? (React.createElement("figure", { className: classNames(styles, 'thumbnailContainer', {
                    clicked: option.label === selectedValue,
                    disabled: isDisabled
                }) },
                React.createElement(ImageLoader, { fit: true, src: convertSrc('medium', img), loader: React.createElement("div", null) }))) : (React.createElement("div", { className: classNames(styles, 'emptyImg', {
                    clicked: option.label === selectedValue,
                    disabled: isDisabled
                }) },
                React.createElement(Icon, { type: "camera", size: 24 })))));
        };
        return (React.createElement("div", { className: classNames(styles, 'container', {
                'scroll-x': row,
                'scroll-y': !row
            }) },
            !isEmpty(title) ? (React.createElement("div", { id: title, className: classNames(styles, 'title', { isOnSelected }) },
                React.createElement("strong", null, title))) : null,
            React.createElement("div", { className: classNames(styles, 'thumbnails', `${row ? 'row' : 'column'}`) }, isNil(options) ? null : sortByProp('label')(options)
                .map(mapOptions))));
    }
}
export default ProductThumbnails;
