import React, { Component } from 'react';
import { isEmpty, isNil } from 'ramda';
import { Link } from 'found';
import { categoryViewTracker } from '../../../rrHalper';
import styles from './SidebarMenu.module.scss';
import t from './i18n';
import { Icon } from '../../Icon';
import { classNames } from '../../../utils';
class SidebarMenu extends Component {
    static defaultProps = { isSecondary: false };
    constructor(props) {
        super(props);
        this.state = { selected: undefined };
    }
    handleSelected = (selected, cat) => {
        const { onClick } = this.props;
        this.setState({ selected }, () => {
            if (!isEmpty(cat.children)) {
                onClick(cat);
            }
        });
    };
    handleClick = (categoryId) => {
        if (process.env.BROWSER
            && process.env.REACT_APP_RRPARTNERID
            && categoryId) {
            categoryViewTracker(parseInt(String(categoryId), 10));
        }
        this.props.onClose();
    };
    render() {
        const { categories, onClose, onCloseCategories, isOpen, title, isSecondary } = this.props;
        const { selected } = this.state;
        return (React.createElement("aside", { className: classNames(styles, 'container', { toggled2: isOpen }) },
            React.createElement("h2", { className: styles.offscreen }, t.offscreenSidebarMenu),
            React.createElement("nav", null,
                React.createElement("header", { className: styles.header },
                    React.createElement("span", { id: "close", onClick: () => {
                            if (isSecondary && onCloseCategories) {
                                onCloseCategories();
                            }
                            else {
                                onClose();
                            }
                        }, onKeyPress: () => {
                        }, role: "button", className: styles.close, tabIndex: -1 },
                        React.createElement(Icon, { type: isSecondary ? 'arrowLeft' : 'cross', size: 24 })),
                    React.createElement("h3", null, title)),
                isNil(categories) || isEmpty(categories) ? null : (React.createElement("ul", { className: styles.menu }, categories.map((cat, index) => (React.createElement("li", { onKeyPress: () => {
                    }, key: cat.rawId, className: classNames(styles, 'item', `${selected === index ? 'active' : ''}`), tabIndex: -1 },
                    selected === index ? (React.createElement("span", { className: styles.activeBorder })) : undefined,
                    React.createElement("span", null,
                        isSecondary ? null : null,
                        React.createElement(Link, { className: styles.linkName, to: {
                                pathname: '/categories',
                                query: {
                                    search: '',
                                    category: cat.rawId
                                }
                            }, onClick: () => {
                                if (cat.rawId) {
                                    this.handleClick(cat.rawId);
                                }
                            }, "data-test": "categoryLink" }, cat.name)),
                    !isEmpty(cat.children) ? (React.createElement("button", { className: styles.icon, onClick: () => this.handleSelected(index, cat) },
                        React.createElement(Icon, { type: "arrowRight" }))) : null))))))));
    }
}
export default SidebarMenu;
