import { t } from '../../../translation/utils';
const translations = {
    en: {
        aboutProduct: 'About Product',
        availableForPreOrder: 'Available for pre-order.',
        leadTime: 'Lead time (days): ',
        price: 'Price',
        count: 'Count',
        subtotal: 'Subtotal',
        delivery: 'Delivery',
        couponDiscount: 'Coupon discount',
        labelCostumerComment: 'Customer comment',
        attention: 'Attention!',
        noShippingAvailable: 'No shipping available for this product to your region.',
        noCountryFound: 'No country found'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
