/**
 * @generated SignedSource<<696bc529d2633620c720f43f4aa2b061>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "warehouseId",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "quantity",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SetProductQuantityInWarehouseMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "GraphQLStock",
                    "kind": "LinkedField",
                    "name": "setProductQuantityInWarehouse",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Product",
                            "kind": "LinkedField",
                            "name": "product",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SetProductQuantityInWarehouseMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "GraphQLStock",
                    "kind": "LinkedField",
                    "name": "setProductQuantityInWarehouse",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Product",
                            "kind": "LinkedField",
                            "name": "product",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                (v4 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v4 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "07ae7d404b92d03755977898eff4e476",
            "id": null,
            "metadata": {},
            "name": "SetProductQuantityInWarehouseMutation",
            "operationKind": "mutation",
            "text": "mutation SetProductQuantityInWarehouseMutation(\n  $input: ProductQuantityInput!\n) {\n  setProductQuantityInWarehouse(input: $input) {\n    warehouseId\n    quantity\n    product {\n      quantity\n      id\n    }\n    id\n  }\n}\n"
        }
    };
})();
node.hash = "9611a92b8b0dea0b01ab573f2b2c3683";
export default node;
