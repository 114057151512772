/**
 * @generated SignedSource<<9d53c5b201d0624c2e5ba6c0392b4261>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "currentPage"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "itemsCount"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "searchTermOptions"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "currentPage",
            "variableName": "currentPage"
        },
        {
            "kind": "Variable",
            "name": "itemsCount",
            "variableName": "itemsCount"
        },
        {
            "kind": "Variable",
            "name": "searchTermOptions",
            "variableName": "searchTermOptions"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawId",
        "storageKey": null
    }, v4 = [
        (v3 /*: any*/),
        {
            "alias": null,
            "args": null,
            "concreteType": "Translation",
            "kind": "LinkedField",
            "name": "name",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lang",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "text",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        (v2 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "StoreOrders_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        {
                            "args": (v1 /*: any*/),
                            "kind": "FragmentSpread",
                            "name": "StoreOrders_me"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "StoreOrders_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Store",
                            "kind": "LinkedField",
                            "name": "myStore",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": (v1 /*: any*/),
                                    "concreteType": "OrderSearchConnection",
                                    "kind": "LinkedField",
                                    "name": "orders",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "OrdersEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "GraphQLOrder",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "slug",
                                                            "storageKey": null
                                                        },
                                                        (v2 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "currency",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "state",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "price",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "quantity",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "subtotal",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "totalAmount",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "createdAt",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "paymentStatus",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "deliveryCompany",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Product",
                                                            "kind": "LinkedField",
                                                            "name": "product",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "BaseProduct",
                                                                    "kind": "LinkedField",
                                                                    "name": "baseProduct",
                                                                    "plural": false,
                                                                    "selections": (v4 /*: any*/),
                                                                    "storageKey": null
                                                                },
                                                                (v2 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Store",
                                                            "kind": "LinkedField",
                                                            "name": "store",
                                                            "plural": false,
                                                            "selections": (v4 /*: any*/),
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PageInfoOrdersSearch",
                                            "kind": "LinkedField",
                                            "name": "pageInfo",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "totalPages",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "currentPage",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "pageItemsCount",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "a5f5be33c62a13fbba8ee68c4059bb7d",
            "id": null,
            "metadata": {},
            "name": "StoreOrders_Query",
            "operationKind": "query",
            "text": "query StoreOrders_Query(\n  $currentPage: Int!\n  $itemsCount: Int!\n  $searchTermOptions: SearchOrderOptionInput!\n) {\n  me {\n    ...StoreOrders_me_10L8Kc\n    id\n  }\n}\n\nfragment StoreOrders_me_10L8Kc on User {\n  myStore {\n    id\n    rawId\n    orders(currentPage: $currentPage, itemsCount: $itemsCount, searchTermOptions: $searchTermOptions) {\n      edges {\n        node {\n          slug\n          id\n          currency\n          state\n          price\n          quantity\n          subtotal\n          totalAmount\n          createdAt\n          paymentStatus\n          deliveryCompany\n          product {\n            baseProduct {\n              rawId\n              name {\n                lang\n                text\n              }\n              id\n            }\n            id\n          }\n          store {\n            rawId\n            name {\n              lang\n              text\n            }\n            id\n          }\n        }\n      }\n      pageInfo {\n        totalPages\n        currentPage\n        pageItemsCount\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "5b1f1dd6f30c59d28cd13d93042269a0";
export default node;
