import React, { PureComponent } from 'react';
import styles from './Col.module.scss';
import { classNames } from '../utils';
class Col extends PureComponent {
    static defaultProps = {
        children: null,
        noGutter: false
    };
    render() {
        const { noGutter, size, sm, md, lg, xl, hidden, smHidden, mdHidden, lgHidden, xlHidden, smHiddenOnly, mdHiddenOnly, lgHiddenOnly, xlHiddenOnly, visible, smVisible, mdVisible, lgVisible, xlVisible, smVisibleOnly, mdVisibleOnly, lgVisibleOnly, xlVisibleOnly } = this.props;
        return (React.createElement("div", { className: classNames(styles, 'col', {
                noGutter,
                'd-none': hidden,
                'd-sm-none d-md-block': smHiddenOnly,
                'd-sm-none d-md-flex': mdHiddenOnly,
                'd-lg-none d-xl-flex': lgHiddenOnly,
                'd-sm-none': smHidden,
                'd-md-none': mdHidden,
                'd-lg-none': lgHidden,
                'd-xl-none': xlHiddenOnly || xlHidden,
                'd-flex': visible,
                'd-none d-sm-flex d-md-none': smVisibleOnly,
                'd-none d-md-flex d-lg-none': mdVisibleOnly,
                'd-none d-lg-flex d-xl-none': lgVisibleOnly,
                'd-none d-sm-flex': smVisible,
                'd-none d-md-flex': mdVisible,
                'd-none d-lg-flex': lgVisible,
                'd-none d-xl-flex': xlVisibleOnly || xlVisible,
                [`col-${size || 12}`]: true,
                [`col-sm col-sm-${sm}`]: sm,
                [`col-md col-md-${md}`]: md,
                [`col-lg col-lg-${lg}`]: lg,
                [`col-xl col-xl-${xl}`]: xl
            }) }, this.props.children));
    }
}
export default Col;
