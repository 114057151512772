import React, { Component } from 'react';
import styles from './SearchMobileMenu.module.scss';
import { classNames } from '../../../utils';
class SearchMobileMenu extends Component {
    handleClick = (evt) => {
        const { onClose } = this.props;
        const { id } = evt.target;
        if (id === 'overlay') {
            onClose();
        }
    };
    render() {
        const { isOpen } = this.props;
        return (React.createElement("div", { id: "overlay", onClick: this.handleClick, onKeyPress: () => { }, role: "presentation", className: classNames(styles, 'container', ` ${isOpen ? 'toggled' : ''}`) }, this.props.children));
    }
}
export default SearchMobileMenu;
