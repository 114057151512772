import React, { Component } from 'react';
import { map, isEmpty, dissoc, take, filter, isNil, assoc } from 'ramda';
import axios from 'axios';
import { classNames, error } from '../../../../utils';
import { fetchProducts } from '../../../../relay/queries';
import { ContextDecorator } from '../../../../components/App';
import styles from './RRElement.module.scss';
import CardProduct from '../../../../components/CardProduct/CardProduct';
class RRElement extends Component {
    constructor(props) {
        super(props);
        this.state = { items: [] };
    }
    componentDidMount() {
        this.isMount = true;
        this.updateWidget();
    }
    componentDidUpdate(prevProps) {
        const { productId } = this.props;
        if (productId !== prevProps.productId) {
            this.updateWidget();
        }
    }
    componentWillUnmount() {
        this.isMount = false;
    }
    timer; // NodeJs.Timeout;
    isMount;
    updateWidget = () => {
        const { productId, dataVendor, type } = this.props;
        let params = {
            itemIds: `${productId}`,
            session: '5baa64e812ff0a000198c632',
            pvid: '561783608306378',
            format: 'json'
        };
        if (dataVendor === 'brand') {
            params = assoc('vendor', 'brand', params);
        }
        // alternative
        axios
            .get(`https://api.retailrocket.net/api/2.0/recommendation/${type}/5ba8ba0797a5281c5c422860`, { params })
            .then(({ data }) => {
            if (data && !isEmpty(data)) {
                const ids = map((item) => item.ItemId, data);
                this.fetchData(ids);
            }
            return true;
        })
            .catch(error);
    };
    fetchData = (ids) => {
        fetchProducts({
            ids,
            environment: this.props.environment
        })
            .then((products) => {
            this.setState({
                items: map((item) => {
                    if (item.baseProduct === null) {
                        return null;
                    }
                    return {
                        ...item.baseProduct,
                        products: {
                            edges: [
                                { node: { ...dissoc('baseProduct', item) } }
                            ]
                        }
                    };
                }, take(4, filter((item) => !isNil(item.baseProduct), products)))
            });
            return true;
        })
            .finally(() => {
        })
            .catch((err) => {
            error(err);
        });
    };
    render() {
        const { fullWidth, title } = this.props;
        const { items } = this.state;
        if (isEmpty(items)) {
            return null;
        }
        return (React.createElement("div", { className: styles.container },
            !isNil(title) && (React.createElement("div", { className: styles.title },
                React.createElement("strong", null, title))),
            React.createElement("div", { className: classNames(styles, 'items', { fullWidth }) }, map((item) => (React.createElement("div", { key: item.rawId, className: styles.item },
                React.createElement(CardProduct, { item: item }))), items))));
    }
}
export default ContextDecorator(RRElement);
