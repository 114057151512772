/**
 * @generated SignedSource<<961a672fdd283d72d7145221a6e1fe71>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "ResetApplyActionOutput",
            "kind": "LinkedField",
            "name": "applyPasswordReset",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "success",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "token",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ApplyPasswordResetMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ApplyPasswordResetMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "c9359ccf991082e588b2fb46ffe063ac",
            "id": null,
            "metadata": {},
            "name": "ApplyPasswordResetMutation",
            "operationKind": "mutation",
            "text": "mutation ApplyPasswordResetMutation(\n  $input: ResetApply!\n) {\n  applyPasswordReset(input: $input) {\n    success\n    token\n  }\n}\n"
        }
    };
})();
node.hash = "af6f38f24484befae7c40b70c94a766e";
export default node;
