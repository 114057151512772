import { commitMutation, graphql } from 'react-relay';
const mutation = graphql `
    mutation DeleteWizardMutation {
        deleteWizardStore {
            id
            store {
                id
                rawId
            }
            completed
        }
    }
`;
const commit = (params) => commitMutation(params.environment, {
    mutation,
    onCompleted: params.onCompleted,
    onError: params.onError
});
export default { commit };
