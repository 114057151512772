import { Component } from 'react';
import { calcPosition, grabImage } from './utils';
class ImageZoom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            backgroundImage: '',
            backgroundPosition: ''
        };
    }
    setZoom = (evt) => {
        this.setState({
            backgroundImage: grabImage(evt),
            backgroundPosition: calcPosition(evt)
        });
    };
    handleMouseMove = (evt) => this.setZoom(evt);
    handleTouchMove = (evt) => this.setZoom(evt);
    handleTouchStart = (evt) => this.setZoom(evt);
    render() {
        const { backgroundImage, backgroundPosition } = this.state;
        return this.props.children({
            backgroundImage,
            backgroundPosition,
            onMouseMove: this.handleMouseMove,
            onTouchMove: this.handleTouchMove,
            onTouchStart: this.handleTouchStart
        });
    }
}
export default ImageZoom;
