import React, { Component } from 'react';
import { prepend, uniq, sort, pathOr, map, addIndex, filter, comparator, lt, isEmpty } from 'ramda';
import { getNameText } from '../../utils';
import { Checkbox } from '../common/Checkbox';
import { ColorPicker } from '../ColorPicker';
import { Select } from '../common/Select';
import styles from './AttributeControl.module.scss';
class AttributeControll extends Component {
    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            ...prevState,
            value: nextProps.initialValues
        };
    }
    constructor(props) {
        super(props);
        this.state = { value: props ? props.initialValues : [] };
    }
    handleOnChange = (receivedVal) => {
        const { onChange, attrFilter } = this.props;
        const { value } = this.state;
        const uiElement = pathOr(null, ['attribute', 'metaField', 'uiElement'], attrFilter);
        const isMultiSelectable = uiElement === 'CHECKBOX' || uiElement === 'COLOR_PICKER';
        const splitedVal = receivedVal.split('-');
        const val = splitedVal.length > 1 ? splitedVal[1] : splitedVal[0];
        if (isMultiSelectable && value) {
            const valResult = !value.includes(val)
                ? [...value, val]
                : [...filter((v) => v !== val, value)];
            onChange(valResult);
        }
        else if (isMultiSelectable && !value) {
            onChange([val]);
        }
        else {
            onChange([receivedVal]);
        }
    };
    renderControll = () => {
        const { attrFilter } = this.props;
        const { value } = this.state;
        const uiElement = pathOr(null, ['attribute', 'metaField', 'uiElement'], attrFilter);
        const values = pathOr(null, ['equal', 'values'], attrFilter);
        const mapIndexed = addIndex(map);
        switch (uiElement) {
            case 'CHECKBOX':
                return (values && mapIndexed((v, index) => (React.createElement("div", { key: `${v}-${index}`, className: styles.valueItem },
                    React.createElement(Checkbox, { id: `CHECKBOX-${v}`, label: v, isChecked: value && value.includes(v), onChange: this.handleOnChange }))), sort((a, b) => a - b, uniq(values))));
            case 'COMBOBOX': {
                const objValues = map((v) => ({ id: v, label: v }), sort(comparator((a, b) => lt(a, b)), uniq(values)));
                // adding not selected item with empty id for reset combobox
                const preparedValues = prepend({
                    id: '',
                    label: 'not selected'
                }, objValues);
                return values ? (React.createElement(Select, { forForm: true, activeItem: !isEmpty(value) ? {
                        id: value[0],
                        label: value[0]
                    } : null, items: preparedValues, onSelect: (item) => {
                        if (item) {
                            this.handleOnChange(item.id);
                        }
                        else {
                            this.setState({ value: [] });
                        }
                    }, containerStyle: {
                        width: '100%',
                        height: '3rem',
                        marginBottom: '1rem',
                        marginTop: '0.5rem'
                    }, dataTest: "attributeControlSelect" })) : null;
            }
            case 'COLOR_PICKER':
                return values ? (React.createElement(ColorPicker, { onSelect: this.handleOnChange, items: uniq(values), value: value })) : null;
            default:
                return null;
        }
    };
    render() {
        const { attrFilter } = this.props;
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.blockTitle }, getNameText(attrFilter.attribute.name, 'EN')),
            this.renderControll()));
    }
}
export default AttributeControll;
