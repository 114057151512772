import React, { Component } from 'react';
import { pathOr } from 'ramda';
import { withRouter } from 'found';
import { v4 as uuidv4 } from 'uuid';
import { PopUpWrapper } from '../PopUpWrapper';
import { Spinner } from '../common/Spinner';
import { debug, error, errorsHandler, fromRelayError, getCookie, removeCookie, setCookie, setJWT } from '../../utils';
import { ApplyPasswordResetMutation, CreateUserMutation, ResendEmailVerificationLinkMutation } from '../../relay/mutations';
import { withShowAlert } from '../Alerts/AlertContext';
import { getJwtbyEmailMutation, requestPasswordResetMutation } from './mutations';
import { getAdditionalData, setPathForRedirectAfterLogin } from './utils';
import AuthorizationHeader from './AuthorizationHeader';
import Separator from './Separator';
import AuthorizationSocial from './AuthorizationSocial';
import RecoverPassword from './RecoverPassword';
import ResetPassword from './ResetPassword';
import SignUp from './SignUp';
import SignIn from './SignIn';
import styles from './Authorization.module.scss';
import t from './i18n';
import getEventKey from "../../utils/getEventKey";
const headerTabsItems = [
    {
        id: '0',
        name: t.signUp
    },
    {
        id: '1',
        name: t.signIn
    }
];
class Authorization extends Component {
    static defaultProps = {
        isResetPassword: false,
        isLogin: false,
        isSignUp: false
    };
    constructor(props) {
        super(props);
        const { noPopup } = props;
        const isRegistered = getCookie('registered');
        const selected = this.props.isSignUp ? 0 : 1;
        const indexByRegistered = isRegistered !== 'true' && noPopup ? 0 : selected;
        this.state = {
            email: '',
            emailValid: false,
            errors: undefined,
            firstName: '',
            firstNameValid: false,
            formValid: false,
            headerTabs: headerTabsItems,
            isLoading: false,
            isRecoverPassword: false,
            isSignUp: this.props.isSignUp,
            lastName: '',
            lastNameValid: false,
            modalTitle: this.setModalTitle(indexByRegistered),
            password: '',
            passwordValid: false,
            passwordRepeat: '',
            selected: indexByRegistered,
            isPrivacyChecked: false,
            isTermsChecked: false
        };
        if (process.env.BROWSER) {
            document.addEventListener('keydown', this.handleKeydown);
        }
    }
    componentDidMount() {
        const { match: { location: { search } } } = this.props;
        if (/\?from=/i.test(search)) {
            const from = search.replace(/\?from=/gi, '');
            if (from && from !== '') {
                setPathForRedirectAfterLogin(from);
            }
        }
    }
    componentWillUnmount() {
        if (process.env.BROWSER) {
            document.removeEventListener('keydown', this.handleKeydown);
        }
    }
    setModalTitle = (index) => {
        const { isResetPassword } = this.props;
        if (isResetPassword) {
            return t.recoverPassword;
        }
        return headerTabsItems[index].name;
    };
    handlePrivacyCheck = (privacy) => {
        this.setState((prevState) => ({
            [privacy]: !prevState[privacy]
        }), () => this.validateForm());
    };
    handleAlertOnClick = () => {
        window.location.href = '/';
    };
    handleRegistrationClick = () => {
        this.setState({
            isLoading: true,
            errors: undefined
        });
        const { email, password, firstName, lastName } = this.state;
        const params = {
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    email,
                    firstName: firstName || null,
                    lastName: lastName || null,
                    password,
                    additionalData: getAdditionalData()
                }
            },
            environment: this.props.environment,
            onCompleted: (response, errors) => {
                debug({ response, errors });
                this.setState({ isLoading: false });
                const relayErrors = fromRelayError({ source: { errors } });
                if (relayErrors) {
                    // pass showAlert for show alert errors in common cases
                    // pass handleCallback specify validation errors
                    errorsHandler(relayErrors, this.props.showAlert, (messages) => this.setState({
                        isLoading: false,
                        errors: messages || null
                    }));
                    return;
                }
                this.props.showAlert({
                    type: 'success',
                    text: t.registrationSuccessful,
                    link: { text: '' },
                    onClick: this.handleAlertOnClick
                });
                removeCookie('REFERRAL');
                removeCookie('REFERRER');
                removeCookie('UTM_MARKS');
                setCookie('registered', true);
                const { onCloseModal } = this.props;
                if (onCloseModal) {
                    onCloseModal();
                }
            },
            onError: (err) => {
                error({ err });
                this.setState({ isLoading: false });
                const relayErrors = fromRelayError(err);
                if (relayErrors) {
                    // pass showAlert for show alert errors in common cases
                    // pass handleCallback specify validation errors
                    errorsHandler(relayErrors, this.props.showAlert, (messages) => this.setState({
                        isLoading: false,
                        errors: messages || null
                    }));
                }
            }
        };
        CreateUserMutation.commit(params);
    };
    handleLoginClick = () => {
        this.setState({
            isLoading: true,
            errors: undefined
        });
        const { match: { location: { search } }, environment, showAlert } = this.props;
        const from = search.replace(/\?from=/gi, '');
        const { email, password } = this.state;
        getJwtbyEmailMutation({
            environment,
            variables: {
                input: {
                    email,
                    password,
                    clientMutationId: uuidv4()
                }
            }
        })
            .then((response) => {
            this.setState({ isLoading: false });
            debug({ response });
            const jwtStr = pathOr(null, ['getJwtbyEmail', 'token'], Object.freeze(response));
            if (jwtStr) {
                const date = new Date();
                const today = date;
                const expirationDate = date;
                expirationDate.setDate(today.getDate() + 14);
                setJWT(jwtStr);
                setCookie('registered', true);
                if (this.props.handleLogin) {
                    this.props.handleLogin();
                    if (from && from !== '') {
                        window.location.replace(from);
                    }
                    else {
                        window.location.href = '/';
                    }
                }
                // window.location.href = '/';
            }
            return response;
        })
            .catch((errs) => {
            const relayErrors = fromRelayError({ source: { errors: [errs] } });
            if (relayErrors) {
                errorsHandler(relayErrors, showAlert, (errMessages) => this.setState({
                    isLoading: false,
                    errors: errMessages || null
                }));
            }
        });
    };
    handleChange = (data) => {
        const { name, value, validity } = data;
        this.setState({
            [name]: value,
            [`${name}Valid`]: validity
        }, () => this.validateForm());
    };
    validateForm = () => {
        const { isResetPassword, isLogin } = this.props;
        const { firstNameValid, lastNameValid, emailValid, passwordValid, isSignUp, isRecoverPassword, password, passwordRepeat, isPrivacyChecked, isTermsChecked } = this.state;
        if (isSignUp) {
            this.setState({
                formValid: firstNameValid
                    && lastNameValid
                    && emailValid
                    && passwordValid
                    && isPrivacyChecked
                    && isTermsChecked
            });
        }
        else if ((isSignUp === false && isRecoverPassword === false) || isLogin) {
            this.setState({ formValid: emailValid && passwordValid });
        }
        if (isRecoverPassword) {
            this.setState({ formValid: emailValid });
        }
        if (isResetPassword) {
            this.setState({ formValid: passwordValid && password === passwordRepeat });
        }
    };
    handleClick = (modalTitle, selected) => {
        this.setState(() => ({
            isSignUp: selected === 0,
            email: '',
            firstName: '',
            lastName: '',
            password: '',
            errors: undefined,
            modalTitle,
            selected
        }));
    };
    handleKeydown = (event) => {
        const key = getEventKey(event);
        const { formValid, isSignUp } = this.state;
        if (key === 'Enter' && formValid) {
            if (isSignUp) {
                this.handleRegistrationClick();
            }
            else {
                this.handleLoginClick();
            }
        }
    };
    recoverPassword = () => {
        this.setState({ isLoading: true });
        const { environment, showAlert } = this.props;
        const { email } = this.state;
        requestPasswordResetMutation({
            environment,
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    email
                }
            }
        })
            .then(() => {
            const { onCloseModal } = this.props;
            this.props.showAlert({
                type: 'success',
                text: t.pleaseVerifyYourEmail,
                link: { text: '' },
                onClick: this.handleAlertOnClick
            });
            if (onCloseModal) {
                onCloseModal();
            }
            return undefined;
        })
            .finally(() => {
            this.setState({ isLoading: false });
        })
            .catch((errs) => {
            const relayErrors = fromRelayError({ source: { errors: [errs] } });
            if (relayErrors) {
                errorsHandler(relayErrors, showAlert, (errMessages) => this.setState({
                    isLoading: false,
                    errors: errMessages || null
                }));
            }
        });
    };
    resetPassword = () => {
        this.setState({ isLoading: true });
        const { environment, match: { params: { token } } } = this.props;
        const { password } = this.state;
        const params = {
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    password,
                    token
                }
            },
            environment,
            onCompleted: (response, errors) => {
                debug({
                    response,
                    errors
                });
                this.setState({ isLoading: false });
                const relayErrors = fromRelayError({ source: { errors } });
                if (relayErrors) {
                    // pass showAlert for show alert errors in common cases
                    // pass handleCallback specify validation errors
                    errorsHandler(relayErrors, this.props.showAlert, (messages) => this.setState({
                        isLoading: false,
                        errors: messages || null
                    }));
                    return;
                }
                this.props.showAlert({
                    type: 'success',
                    text: t.passwordResetSuccessfully,
                    link: { text: '' },
                    onClick: this.handleAlertOnClick
                });
                const jwtStr = pathOr(null, ['applyPasswordReset', 'token'], Object.freeze(response));
                if (jwtStr) {
                    const date = new Date();
                    const today = date;
                    const expirationDate = date;
                    expirationDate.setDate(today.getDate() + 14);
                    setJWT(jwtStr);
                    if (this.props.handleLogin) {
                        this.props.handleLogin();
                        window.location.href = '/';
                    }
                }
            },
            onError: (err) => {
                const relayErrors = fromRelayError(err);
                if (relayErrors) {
                    // pass showAlert for show alert errors in common cases
                    // pass handleCallback specify validation errors
                    errorsHandler(relayErrors, this.props.showAlert, () => this.setState({
                        isLoading: false,
                        errors: { email: [t.emailNotFound] }
                    }));
                }
            }
        };
        ApplyPasswordResetMutation.commit(params);
    };
    handleResendEmail = () => {
        const { environment } = this.props;
        const { email } = this.state;
        const variables = {
            input: {
                clientMutationId: uuidv4(),
                email
            }
        };
        const params = {
            variables,
            environment,
            onCompleted: (response, errors) => {
                debug({
                    response,
                    errors
                });
                this.setState({ isLoading: false });
                const relayErrors = fromRelayError({ source: { errors } });
                if (relayErrors) {
                    // pass showAlert for show alert errors in common cases
                    // pass handleCallback specify validation errors
                    errorsHandler(relayErrors, this.props.showAlert, (messages) => this.setState({
                        isLoading: false,
                        errors: messages || null
                    }));
                    return;
                }
                this.props.showAlert({
                    type: 'success',
                    text: t.verificationEmailSentSuccessfully,
                    link: { text: '' },
                    onClick: this.handleAlertOnClick
                });
            },
            onError: (err) => {
                const relayErrors = fromRelayError(err);
                if (relayErrors) {
                    // pass showAlert for show alert errors in common cases
                    // pass handleCallback specify validation errors
                    errorsHandler(relayErrors, this.props.showAlert, (messages) => this.setState({
                        isLoading: false,
                        errors: messages || null
                    }));
                }
            }
        };
        ResendEmailVerificationLinkMutation.commit(params);
    };
    handleRecoverPassword = () => {
        this.setState({
            modalTitle: t.resetPassword,
            isRecoverPassword: true,
            email: '',
            password: '',
            errors: undefined
        });
    };
    handleBack = () => {
        const { isResetPassword, router: { push } } = this.props;
        if (isResetPassword) {
            push('/');
        }
        this.setState({
            email: '',
            errors: undefined,
            modalTitle: headerTabsItems[1].name,
            isSignUp: false,
            isRecoverPassword: false
        });
    };
    passwordRecovery = () => {
        const { isResetPassword } = this.props;
        const { password, passwordRepeat, email, formValid, errors } = this.state;
        if (isResetPassword) {
            return (React.createElement(ResetPassword, { password: password, passwordRepeat: passwordRepeat, errors: errors, formValid: formValid, onBack: this.handleBack, onClick: this.resetPassword, onChange: this.handleChange, onPasswordRepeat: this.handleChange }));
        }
        return (React.createElement(RecoverPassword, { email: email, errors: errors, formValid: formValid, onBack: this.handleBack, onClick: this.recoverPassword, onChange: this.handleChange }));
    };
    handleSocialClick = () => {
        this.setState({ isLoading: true });
    };
    renderRegistration = () => {
        const { email, firstName, lastName, password, formValid, errors, selected, isPrivacyChecked, isTermsChecked } = this.state;
        return selected === 0 ? (React.createElement(SignUp, { email: email, firstName: firstName, lastName: lastName, password: password, errors: errors, formValid: formValid, onRegistrationClick: this.handleRegistrationClick, onChange: this.handleChange, onPrivacyCheck: this.handlePrivacyCheck, isPrivacyChecked: isPrivacyChecked, isTermsChecked: isTermsChecked })) : (React.createElement(SignIn, { email: email, password: password, errors: errors, formValid: formValid, onLoginClick: this.handleLoginClick, onChange: this.handleChange, onRecoverPassword: this.handleRecoverPassword, onResendEmail: this.handleResendEmail }));
    };
    render() {
        const { onCloseModal, isResetPassword, isLogin } = this.props;
        const text = t.pleaseTypeNewPassword;
        const description = isResetPassword ? text : '';
        const { isLoading, isSignUp, headerTabs, modalTitle, selected, isRecoverPassword } = this.state;
        return (React.createElement(PopUpWrapper, { title: modalTitle, description: description, onClose: onCloseModal, render: () => (React.createElement("div", { className: styles.container },
                React.createElement("div", { className: styles.wrap },
                    isLoading && (React.createElement("div", { className: styles.spinner },
                        React.createElement(Spinner, null))),
                    isRecoverPassword || isResetPassword ? null : (React.createElement(AuthorizationHeader, { fullWidth: isLogin, isSignUp: isSignUp, onClick: this.handleClick, selected: selected, tabs: headerTabs })),
                    isRecoverPassword || isResetPassword
                        ? this.passwordRecovery()
                        : this.renderRegistration(),
                    isRecoverPassword || isResetPassword ? null : (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: styles.separatorBlock },
                            React.createElement(Separator, { text: "or" })),
                        React.createElement(AuthorizationSocial, { handleSocialClick: this.handleSocialClick })))))) }));
    }
}
export default withShowAlert(withRouter(Authorization));
