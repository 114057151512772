import React, { Component } from 'react';
import { withRouter } from 'found';
import { assocPath, pathOr } from 'ramda';
import { Button } from '../../../components/common/Button';
import { Select } from '../../../components/common/Select';
import { inputToUrl, urlToInput } from '../../../utils';
import { fromQueryString, fromSearchFilters } from '../StoreUtils';
import styles from './StoresSidebar.module.scss';
import t from './i18n';
class StoresSidebar extends Component {
    constructor(props) {
        super(props);
        const { search, match } = this.props;
        const categories = fromSearchFilters(search, ['category', 'children']);
        const countries = fromSearchFilters(search, ['country']);
        this.state = {
            categories,
            countries
        };
        const country = fromQueryString(match, 'country')(countries, 'label');
        if (country) {
            this.setState({ country });
        }
        const category = fromQueryString(match, 'category')(categories, 'id');
        if (category) {
            this.setState({ category });
        }
    }
    handleClick = (stateName, item) => {
        const queryObj = pathOr('', ['match', 'location', 'query'], this.props);
        const isCountry = stateName === 'country';
        const pathPiece = isCountry ? stateName : 'categoryId';
        const propPiece = isCountry ? 'label' : 'id';
        const oldQueryObj = urlToInput(queryObj);
        const newQueryObj = assocPath(['options', pathPiece], item.id ? item[propPiece] : null, oldQueryObj);
        const newUrl = inputToUrl(newQueryObj);
        this.setState({ [stateName]: item.id ? item : null }, () => {
            this.push(newUrl);
        });
    };
    push = (url) => {
        const { router: { push } } = this.props;
        push(`/stores${url}`);
    };
    render() {
        const { onClose } = this.props;
        const { categories, countries, category, country } = this.state;
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", null,
                React.createElement("div", { className: styles.filterItem },
                    React.createElement(Select, { forSearch: true, withEmpty: true, label: t.labelCategories, activeItem: category, items: categories, onSelect: (item) => this.handleClick('category', item), dataTest: "storesCategoriesSelect" })),
                React.createElement("div", { className: styles.filterItem },
                    React.createElement(Select, { forSearch: true, withEmpty: true, label: t.labelLocation, activeItem: country, items: countries, onSelect: (item) => this.handleClick('country', item), dataTest: "storesLocationSelect" }))),
            React.createElement("div", { className: styles.apply },
                React.createElement(Button, { wireframe: true, big: true, onClick: onClose, dataTest: "apply", fullWidth: true }, t.apply))));
    }
}
export default withRouter(StoresSidebar);
