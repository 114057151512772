import React, { Component } from 'react';
import { map } from 'ramda';
import { formatPrice } from '../../../../utils';
import { Icon } from '../../../../components/Icon';
import { Checkbox } from '../../../../components/common/Checkbox';
import { Button } from '../../../../components/common';
import styles from './Dropdown.module.scss';
class Dropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    UNSAFE_componentWillMount() {
        document.addEventListener('mousedown', this.handleClick, false);
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false);
    }
    node;
    handleClick = (e) => {
        if (this.node && this.node.contains(e.target)) {
            return;
        }
        if (this.props.isOpen) {
            this.props.toggleExpand(e);
        }
    };
    render() {
        const { isOpen, selectedPackage, onPackageSelect, toggleExpand, packages, onAccept, currency } = this.props;
        return (React.createElement("div", { className: styles.dropdown },
            isOpen && (React.createElement("div", { ref: (ref) => {
                    this.node = ref;
                } },
                React.createElement("div", { className: styles.opened },
                    React.createElement("div", { className: styles.label }, selectedPackage != null
                        ? selectedPackage.name
                        : 'Choose company'),
                    React.createElement("span", null,
                        React.createElement(Icon, { type: "arrowExpand" }))),
                React.createElement("div", { className: styles.packages },
                    map((item) => (React.createElement("div", { className: styles.row, key: item.id },
                        React.createElement("div", { className: styles.title },
                            React.createElement("div", { className: styles.cb },
                                React.createElement(Checkbox, { id: item.id, onChange: onPackageSelect, isChecked: selectedPackage && item.id === selectedPackage.id, label: item.name, dataTest: "deliverySelectCheckbox" }))),
                        React.createElement("span", { className: styles.price }, `${formatPrice(item.price, 2)} ${currency || ''}`))), packages),
                    React.createElement("div", { className: styles.buttonRow },
                        this.props.isError && (React.createElement("span", { className: styles.error }, "Error :( Please try again")),
                        React.createElement(Button, { big: true, onClick: onAccept, isLoading: this.props.isLoading, dataTest: "deliverySelectAcceptButton" }, "Accept"))))),
            !isOpen && (React.createElement("div", { className: styles.closed, onClick: toggleExpand, onKeyDown: () => {
                }, role: "button", tabIndex: 0, "data-test": "deliverySelect" },
                React.createElement("div", { className: styles.label }, selectedPackage != null
                    ? selectedPackage.name
                    : 'Choose company'),
                React.createElement("button", { onClick: toggleExpand },
                    React.createElement(Icon, { type: "arrowExpand" }))))));
    }
}
export default Dropdown;
