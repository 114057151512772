import React, { Component } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import copy from 'copy-to-clipboard';
import { ManageStore } from '../index';
import { Page } from '../../../../components/App';
import { Tabs } from '../../../../components/common';
import styles from './Finances.module.scss';
import t from './i18n';
import Cards from './Cards/Cards';
import PaymentAccount from './PaymentAccount/PaymentAccount';
import Balance from './Balance/Balance';
class Finances extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 0,
            isCopiedWalletAddress: false
        };
    }
    componentWillUnmount() {
        clearTimeout(this.timer);
    }
    timer; // NodeJs.Timeout;
    handleClickTab = (selectedTab) => {
        this.setState({ selectedTab });
    };
    handleCopyWalletAddress = (walletAddress) => {
        clearTimeout(this.timer);
        this.setState({ isCopiedWalletAddress: true });
        this.timer = setTimeout(() => {
            this.setState({ isCopiedWalletAddress: false });
        }, 3000);
        copy(walletAddress);
    };
    render() {
        const { me } = this.props;
        const { selectedTab } = this.state;
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.wrap },
                React.createElement("div", { className: styles.tabs },
                    React.createElement(Tabs, { selected: selectedTab, onClick: this.handleClickTab },
                        React.createElement("div", { label: t.paymentOptions, className: styles.paymentOptions },
                            React.createElement("div", { className: styles.title },
                                React.createElement("strong", null, t.bankCards)),
                            React.createElement("div", { className: styles.cards },
                                React.createElement(Cards, { me: me }))),
                        React.createElement("div", { label: t.paymentAccount, className: styles.paymentAccount },
                            React.createElement(PaymentAccount, { me: me })),
                        React.createElement("div", { label: t.balance, className: styles.balance },
                            React.createElement(Balance, { me: me })))))));
    }
}
export default createFragmentContainer(Page(ManageStore({
    OriginalComponent: Finances,
    active: 'finances',
    title: 'Payment settings'
})), {
    me: graphql `
			fragment Finances_me on User {
				myStore {
					storeSubscription {
						walletAddress
					}
					productsCount
				}
				...Cards_me
				...PaymentAccount_me
				...Balance_me
			}
    `
});
