import React, { Component } from 'react';
import { find, head, pathOr, propEq } from 'ramda';
import { Link, withRouter } from 'found';
import { categoryViewTracker } from '../../rrHalper';
import styles from './CategoriesMenu.module.scss';
import t from './i18n';
import { classNames } from '../../utils';
import { Icon } from '../Icon';
class CategoriesMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: undefined,
            activeMid: undefined,
            activeMidGhost: undefined,
            pageX: 0,
            rightMouseDirection: false
        };
    }
    componentWillUnmount() {
        if (this.onMouseOverTimer) {
            clearTimeout(this.onMouseOverTimer);
        }
        if (this.onMouseOutTimer) {
            clearTimeout(this.onMouseOutTimer);
        }
        if (this.onMouseMoveTimer) {
            clearTimeout(this.onMouseMoveTimer);
        }
    }
    onMouseOverTimer; // NodeJs.Timeout;
    onMouseOutTimer; // NodeJs.Timeout;
    onMouseMoveTimer; // NodeJs.Timeout;
    onMouseOver = (e, childRawId) => {
        const { id } = e.currentTarget;
        const { active } = this.state;
        if (this.onMouseOutTimer) {
            clearTimeout(this.onMouseOutTimer);
        }
        if (this.onMouseOverTimer) {
            clearTimeout(this.onMouseOverTimer);
        }
        if (active !== id) {
            this.onMouseOverTimer = setTimeout(() => {
                this.setState(() => ({
                    active: id,
                    activeMid: `${childRawId}`
                }));
            }, 500);
        }
    };
    onMouseOut = () => {
        const { active } = this.state;
        if (this.onMouseOutTimer) {
            clearTimeout(this.onMouseOutTimer);
        }
        if (this.onMouseOverTimer) {
            clearTimeout(this.onMouseOverTimer);
        }
        if (active) {
            this.onMouseOutTimer = setTimeout(() => {
                this.setState(() => ({
                    active: undefined,
                    activeMid: undefined
                }));
            }, 500);
        }
    };
    onMouseOverMid = (e) => {
        const { id } = e.currentTarget;
        const { rightMouseDirection } = this.state;
        if (rightMouseDirection) {
            this.setState(() => ({ activeMidGhost: id }));
            return;
        }
        this.setState(() => ({ activeMid: id }));
    };
    onMouseOutMid = () => {
        this.setState(() => ({ activeMidGhost: null }));
    };
    onMouseMove = (e) => {
        const { pageX } = e;
        const { activeMidGhost } = this.state;
        if (pageX < this.state.pageX) {
            this.setState(() => ({ rightMouseDirection: false }));
        }
        if (pageX > this.state.pageX) {
            this.setState(() => ({ rightMouseDirection: true }));
        }
        if (this.onMouseMoveTimer) {
            clearTimeout(this.onMouseMoveTimer);
        }
        this.onMouseMoveTimer = setTimeout(() => {
            this.setState((preState) => ({
                rightMouseDirection: false,
                activeMid: activeMidGhost || preState.activeMid
            }));
        }, 50);
        this.setState(() => ({ pageX }));
    };
    handleClick = (categoryId) => {
        if (process.env.BROWSER && process.env.REACT_APP_RRPARTNERID && categoryId) {
            categoryViewTracker(parseInt(String(categoryId), 10));
        }
        this.setState(() => ({
            active: undefined,
            activeMid: undefined
        }));
    };
    renderMenu(categories, isRoot) {
        const locationQuery = pathOr(undefined, ['match', 'location', 'query'], this.props);
        const { active, activeMid } = this.state;
        const lang = 'EN';
        return categories.map((category) => {
            const { rawId } = category;
            const categoryChildren = category.children;
            const name = find(propEq(lang, 'lang'))(category.name);
            let onMouseOver = null;
            let onMouseOut = null;
            if (isRoot) {
                onMouseOver = (e) => {
                    this.onMouseOver(e, (head(categoryChildren)).rawId);
                };
                ({ onMouseOut } = this);
            }
            else if (categoryChildren) {
                onMouseOver = this.onMouseOverMid;
                onMouseOut = this.onMouseOutMid;
            }
            else {
                onMouseOver = null;
                onMouseOut = null;
            }
            const renderInnerLink = () => (React.createElement(React.Fragment, null,
                name && name.text && React.createElement("span", { className: styles.text }, name.text),
                categoryChildren
                    && !isRoot && (React.createElement("span", { className: styles.icon },
                    React.createElement(Icon, { type: "arrowRight" })))));
            return (React.createElement("li", { id: category.rawId, key: category.rawId, className: classNames(styles, {
                    rootItem: isRoot,
                    midItem: !isRoot && categoryChildren,
                    activeItem: isRoot && active === `${category.rawId}`,
                    activeItemMod: !isRoot && activeMid === `${category.rawId}`
                }), onMouseOver: onMouseOver, onMouseOut: onMouseOut, onBlur: () => {
                }, onFocus: () => {
                } },
                React.createElement(Link, { className: styles.link, to: {
                        pathname: '/categories',
                        query: {
                            ...locationQuery,
                            search: '',
                            category: rawId
                        }
                    }, onClick: () => {
                        this.handleClick(category.rawId);
                    }, "data-test": "categorieLink" }, renderInnerLink()),
                categoryChildren && (React.createElement("div", { className: styles.items, onMouseMove: this.onMouseMove },
                    React.createElement("div", { className: styles.itemsWrap },
                        name && name.text && React.createElement("div", { className: styles.title }, name.text),
                        React.createElement("ul", null, this.renderMenu(categoryChildren)))))));
        });
    }
    render() {
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: classNames(styles, 'rootItem', 'rootButtonItem') },
                React.createElement(Link, { className: styles.button, to: "/categories?search=", "data-test": "allCategoriesLink" },
                    React.createElement(Icon, { inline: true, type: "cats", size: 24 }),
                    React.createElement("span", { className: styles.buttonText }, t.all))),
            React.createElement("ul", { className: styles.root }, this.renderMenu(this.props.categories, true))));
    }
}
export default withRouter(CategoriesMenu);
