import React, { Component } from 'react';
const cache = {};
class Img extends Component {
    i;
    sourceList;
    static defaultProps = {
        fit: false,
        left: false,
        loader: false,
        unloader: false,
        decode: true,
        src: [],
        // by default, just return what gets sent in. Can be used for advanced rendering
        // such as animations
        container: (x) => x
    };
    constructor(props) {
        super(props);
        this.sourceList = this.srcToArray(this.props.src);
        // check cache to decide at which index to start
        for (let i = 0; i < this.sourceList.length; i++) {
            // if we've never seen this image before, the cache wont help.
            // no need to look further, just start loading
            /* istanbul ignore else */
            if (!(this.sourceList[i] in cache)) {
                break;
            }
            // if we have loaded this image before, just load it again
            /* istanbul ignore else */
            if (cache[this.sourceList[i]] === true) {
                this.state = {
                    currentIndex: i,
                    isLoading: false,
                    isLoaded: true
                };
            }
        }
        this.state = this.sourceList.length
            ? // 'normal' opperation: start at 0 and try to load
                {
                    currentIndex: 0,
                    isLoading: true,
                    isLoaded: false
                }
            : // if we dont have any sources, jump directly to unloaded
                {
                    isLoading: false,
                    isLoaded: false
                };
    }
    srcToArray = (src) => (Array.isArray(src) ? src : [src]).filter((x) => x);
    onLoad = () => {
        cache[this.sourceList[this.state.currentIndex]] = true;
        /* istanbul ignore else */
        if (this.i) {
            this.setState({ isLoaded: true });
        }
    };
    onError = () => {
        cache[this.sourceList[this.state.currentIndex]] = false;
        // if the current image has already been destroyed, we are probably no longer mounted
        // no need to do anything then
        /* istanbul ignore else */
        if (!this.i) {
            return false;
        }
        // before loading the next image, check to see if it was ever loaded in the past
        for (let nextIndex = this.state.currentIndex + 1; nextIndex < this.sourceList.length; nextIndex++) {
            // get next img
            const src = this.sourceList[nextIndex];
            // if we have never seen it, its the one we want to try next
            if (!(src in cache)) {
                this.setState({ currentIndex: nextIndex });
                break;
            }
            // if we know it exists, use it!
            if (cache[src] === true) {
                this.setState({
                    currentIndex: nextIndex,
                    isLoading: false,
                    isLoaded: true
                });
                return true;
            }
            // if we know it doesn't exist, skip it!
            /* istanbul ignore else */
            if (cache[src] === false) {
                continue;
            }
        }
        // currentIndex is zero bases, length is 1 based.
        // if we have no more sources to try, return - we are done
        if (this.state.currentIndex === this.sourceList.length) {
            return this.setState({ isLoading: false });
        }
        // otherwise, try the next img
        this.loadImg();
    };
    loadImg = () => {
        this.i = new Image();
        this.i.src = this.sourceList[this.state.currentIndex];
        if (this.props.decode && this.i.decode) {
            this.i
                .decode()
                .then(this.onLoad)
                .catch(this.onError);
        }
        else {
            this.i.onload = this.onLoad;
        }
        this.i.onerror = this.onError;
    };
    unloadImg = () => {
        delete this.i.onerror;
        delete this.i.onload;
        delete this.i.src;
        delete this.i;
    };
    componentDidMount() {
        // kick off process
        /* istanbul ignore else */
        if (this.state.isLoading) {
            this.loadImg();
        }
    }
    componentWillUnmount() {
        // ensure that we dont leave any lingering listeners
        /* istanbul ignore else */
        if (this.i) {
            this.unloadImg();
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const src = this.srcToArray(nextProps.src);
        const srcAdded = src.filter((s) => this.sourceList.indexOf(s) === -1);
        const srcRemoved = this.sourceList.filter((s) => src.indexOf(s) === -1);
        // if src prop changed, restart the loading process
        if (srcAdded.length || srcRemoved.length) {
            this.sourceList = src;
            // if we dont have any sources, jump directly to unloader
            if (!src.length) {
                return this.setState({
                    isLoading: false,
                    isLoaded: false
                });
            }
            this.setState({
                currentIndex: 0,
                isLoading: true,
                isLoaded: false
            }, this.loadImg);
        }
    }
    render() {
        // if we have loaded, show img
        if (this.state.isLoaded) {
            // clear non img props
            const { fit, left, src, loader, unloader, decode, container, ...rest } = this.props;
            const imgProps = { ...rest };
            return fit
                ? this.props.container(React.createElement("div", { role: "img", style: {
                        backgroundImage: `url(${src})`,
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        right: '0',
                        bottom: '0',
                        backgroundSize: 'cover',
                        backgroundPosition: left ? 'left' : 'center',
                        backgroundRepeat: 'no-repeat'
                    }, ...imgProps }))
                : this.props.container(React.createElement("img", { src: this.sourceList[this.state.currentIndex], ...imgProps, alt: this.sourceList[this.state.currentIndex] }));
        }
        // if we are still trying to load, show img and a loader if requested
        if (!this.state.isLoaded && this.state.isLoading) {
            return this.props.loader ? this.props.container(this.props.loader) : null;
        }
        // if we have given up on loading, show a place holder if requested, or nothing
        /* istanbul ignore else */
        if (!this.state.isLoaded && !this.state.isLoading) {
            return this.props.unloader
                ? this.props.container(this.props.unloader)
                : null;
        }
        return null;
    }
}
export default Img;
