import React, { PureComponent } from 'react';
import { map, concat, filter, complement, propEq, find } from 'ramda';
import { Col, Row } from '../../../../../layout';
import { CharacteristicItem } from '../CharacteristicItem';
import styles from './AttributesForm.module.scss';
class AttributesForm extends PureComponent {
    handleCharectiristicItemChange = (data) => {
        const notChangedAttrs = (data && filter(complement(propEq('attrId', data.attrId)), this.props.values)) || [];
        const result = data && data.value
            ? concat([data], notChangedAttrs)
            : notChangedAttrs;
        this.props.onChange(data && data.value ? result : notChangedAttrs);
    };
    renderCharacteristics = map((item) => {
        const value = find(propEq(item.rawId, 'attrId'), this.props.values);
        return (React.createElement(Col, { size: 12, md: 6, key: item.id },
            React.createElement("div", { className: styles.itemWrapper },
                React.createElement(CharacteristicItem, { attribute: item, onSelect: this.handleCharectiristicItemChange, value: value }))));
    });
    render() {
        const { attributes } = this.props;
        return (React.createElement(Row, null, this.renderCharacteristics(attributes)));
    }
}
export default AttributesForm;
