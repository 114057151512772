import { commitMutation, graphql } from 'react-relay';
const mutation = graphql `
	mutation SetSelectionInCartMutation($input: SetSelectionInCartInput!) {
		setSelectionInCart(input: $input) {
			...Cart_cart
		}
	}
`;
const commit = (params) => commitMutation(params.environment, {
    mutation,
    variables: params.variables,
    onCompleted: params.onCompleted,
    onError: params.onError
});
export default { commit };
