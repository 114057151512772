import React, { Component } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { isEmpty, map, pathOr } from 'ramda';
import { Link } from 'found';
import MediaQuery from 'react-responsive';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Environment } from 'relay-runtime';
import { classNames, convertSrc, error } from '../../utils';
import ImageLoader from '../../libs/react-image-loader';
import { Col, Container, Row } from '../../layout';
import { BannersSlider } from '../../components/BannersSlider';
import { BannersRow } from '../../components/BannersRow';
import BannerLoading from '../../components/Banner/BannerLoading';
import { Page } from '../../components/App';
import Goods from './Goods';
import styles from './Start.module.scss';
import t from './i18n';
const Loader = (React.createElement("div", { className: styles.loader },
    React.createElement(BannerLoading, null)));
const BannerPlaceholder = () => (React.createElement("div", { className: styles.bannerPlaceholder },
    React.createElement(BannerLoading, null)));
class Start extends Component {
    static contextTypes = {
        showAlert: PropTypes.func,
        environment: PropTypes.instanceOf(Environment).isRequired,
        directories: PropTypes.object,
        currentUser: PropTypes.shape({
            id: PropTypes.string,
            rawId: PropTypes.number
        })
    };
    state = {
        banners: {
            main: [],
            middle: [],
            bottom: []
        },
        header: [
            {
                title: 'Fancy Stuff',
                description: 'Express yourself with unique handmade items'
            },
            {
                title: 'Unique Sellers',
                description: 'Discover exclusive items from any corner of your locality'
            },
            {
                title: 'Variety of Payment Options',
                description: 'Choose any payment method, from cash to all kind of cards'
            }
        ],
        about: [
            {
                title: 'A marketplace for self-expression',
                description: 'Graame is a place for all kind of goods we buy from our local shops including unique items which allow expressing your soul. Here people come to find unique gifts or treat themselves with fancy stuff.'
            },
            {
                title: 'Nationwide community',
                description: 'Graame is an online marketplace which is local on every side. We have an countrywide community and support that help you buy and sell goods including craft items.'
            },
            {
                title: 'Payment options',
                description: 'Along with cash, you can try out paying for goods with all kind of cards and wallets on Graame.'
            }
        ]
    };
    componentDidMount() {
        this.isMount = true;
        axios
            .get('https://s3.us-east-2.amazonaws.com/dumpster.stq/banner.json')
            .then(({ data }) => {
            if (this.isMount) {
                this.setState({ banners: data });
            }
            return true;
        })
            .catch(error);
    }
    componentWillUnmount() {
        this.isMount = false;
    }
    isMount = false;
    renderStartSellingBanner = () => (React.createElement(React.Fragment, null, this.state.banners.middle instanceof Array && this.state.banners.middle[0] != null ? (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { maxWidth: 575 },
            React.createElement(ImageLoader, { fit: true, src: this.state.banners.middle[0].phone, loader: Loader })),
        React.createElement(MediaQuery, { maxWidth: 991, minWidth: 576 },
            React.createElement(ImageLoader, { fit: true, src: this.state.banners.middle[0].tablet, loader: Loader })),
        React.createElement(MediaQuery, { minWidth: 992 },
            React.createElement(ImageLoader, { fit: true, src: this.state.banners.middle[0].desktop, loader: Loader })))) : (React.createElement("div", { className: styles.placeholder },
        React.createElement(BannerLoading, null)))));
    render() {
        const { mainPage } = this.props;
        const { header, about } = this.state;
        const mostViewedProducts = pathOr([], ['findMostViewedProducts', 'edges'], mainPage);
        const mostDiscountProducts = pathOr([], ['findMostDiscountProducts', 'edges'], mainPage);
        const discountProducts = map((item) => ({ ...item.node }), mostDiscountProducts);
        const viewedProducts = map((item) => ({ ...item.node }), mostViewedProducts);
        const isCompletedWizardStore = pathOr(false, ['me', 'wizardStore', 'completed'], this.props);
        const myStoreRawId = pathOr(null, ['me', 'myStore', 'rawId'], this.props);
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.header },
                "Graame brings ",
                React.createElement("strong", null, "local, unique, custom"),
                ", and ",
                React.createElement("strong", null, "handcrafted"),
                " items to your life."),
            React.createElement("div", { className: styles.bannerSliderItem },
                React.createElement(MediaQuery, { maxWidth: 575 }, isEmpty(this.state.banners.main) ? (React.createElement(BannerPlaceholder, null)) : (React.createElement(BannersSlider, { items: map((item) => ({
                        id: item.id,
                        img: convertSrc('medium', item.phone),
                        link: item.link
                    }), this.state.banners.main) }))),
                React.createElement(MediaQuery, { maxWidth: 991, minWidth: 576 }, isEmpty(this.state.banners.main) ? (React.createElement(BannerPlaceholder, null)) : (React.createElement(BannersSlider, { items: map((item) => ({
                        id: item.id,
                        img: convertSrc('large', item.tablet),
                        link: item.link
                    }), this.state.banners.main) }))),
                React.createElement(MediaQuery, { minWidth: 992 }, isEmpty(this.state.banners.main) ? (React.createElement(BannerPlaceholder, null)) : (React.createElement(BannersSlider, { items: map((item) => ({
                        id: item.id,
                        img: item.desktop,
                        link: item.link
                    }), this.state.banners.main) })))),
            React.createElement("div", null,
                React.createElement(Container, null,
                    React.createElement(Row, null, header.map((hd) => (React.createElement(Col, { key: hd.title, size: 12, sm: 12, md: 12, lg: 4, xl: 4 },
                        React.createElement("div", { className: styles.subHeading },
                            React.createElement("strong", null, hd.title),
                            React.createElement("p", { className: styles.description }, hd.description)))))))),
            React.createElement("div", { className: styles.goodsItem }, viewedProducts
                && viewedProducts.length > 0 && (React.createElement(Goods, { items: viewedProducts, title: t.mostPopular, seeAllUrl: "/categories?search=&sortBy=VIEWS" }))),
            React.createElement("div", { className: styles.bannerImage }, isCompletedWizardStore && myStoreRawId ? (React.createElement(Link, { to: `/manage/store/${myStoreRawId}`, className: styles.sellingImage }, this.renderStartSellingBanner())) : (
            // <a href="https://selling.graame.com" className={styles.sellingImage}>
            React.createElement("a", { href: "/start-selling", className: styles.sellingImage }, this.renderStartSellingBanner()))),
            React.createElement("div", { className: styles.goodsItem }, discountProducts
                && discountProducts.length > 0 && (React.createElement(Goods, { items: discountProducts, title: t.sale, seeAllUrl: "/categories?search=&sortBy=DISCOUNT" }))),
            React.createElement("div", { className: styles.goodsItem },
                React.createElement("div", { className: styles.about },
                    React.createElement("div", { className: styles.aboutHeading }, "We all have inner spirit. We are all individuals. Stand out with Graame."),
                    React.createElement("a", { className: styles.readmoreAbout, href: "https://graame.com/about/", target: "_blank", rel: "noopener noreferrer" }, "Read more about Graame"),
                    React.createElement(Container, null,
                        React.createElement(Row, null, about.map((ab) => (React.createElement(Col, { key: ab.title, size: 12, sm: 12, md: 12, lg: 4, xl: 4 },
                            React.createElement("div", { className: classNames(styles, 'subHeading', 'short') },
                                React.createElement("div", { className: styles.aboutTitle }, ab.title),
                                React.createElement("p", { className: styles.aboutDescription }, ab.description))))))))),
            this.state.banners.bottom instanceof Array && !isEmpty(this.state.banners.bottom) && (React.createElement("div", { className: styles.bannersItem },
                React.createElement(MediaQuery, { maxWidth: 767 },
                    React.createElement(BannersRow, { items: map((item) => ({
                            ...item,
                            img: item.phone
                        }), this.state.banners.bottom), count: 2 })),
                React.createElement(MediaQuery, { maxWidth: 1199, minWidth: 768 },
                    React.createElement(BannersRow, { items: map((item) => ({
                            ...item,
                            img: item.tablet
                        }), this.state.banners.bottom), count: 2 })),
                React.createElement(MediaQuery, { minWidth: 1200 },
                    React.createElement(BannersRow, { items: map((item) => ({
                            ...item,
                            img: item.desktop
                        }), this.state.banners.bottom), count: 2 }))))));
    }
}
export default createFragmentContainer(Page(Start), {
    mainPage: graphql `
			fragment Start_mainPage on MainPage {
				findMostViewedProducts(
					searchTerm: { options: { attrFilters: [] } }
					first: 24
				) {
					edges {
						node {
							rawId
							storeId
							name {
								lang
								text
							}
							store {
								name {
									lang
									text
								}
							}
							currency
							products(first: 1) {
								edges {
									node {
										id
										rawId
										discount
										photoMain
										cashback
										price
										customerPrice {
											price
											currency
										}
									}
								}
							}
							rating
						}
					}
				}
				findMostDiscountProducts(
					searchTerm: { options: { attrFilters: [] } }
					first: 24
				) {
					edges {
						node {
							rawId
							storeId
							name {
								lang
								text
							}
							store {
								name {
									lang
									text
								}
							}
							currency
							products(first: 1) {
								edges {
									node {
										id
										rawId
										discount
										photoMain
										cashback
										price
										customerPrice {
											price
											currency
										}
									}
								}
							}
							rating
						}
					}
				}
			}
    `
});
