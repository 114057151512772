import { t } from '../../translation/utils';
const translations = {
    en: {
        personalData: 'Personal data',
        shippingAddresses: 'Shipping addresses',
        security: 'Security',
        myOrders: 'My orders'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
