import React from 'react';
import { Col } from '../../../../layout';
import { Icon } from '../../../../components/Icon';
import styles from './TableTitle.module.scss';
import t from './i18n';
const TableTitle = () => (React.createElement("div", { className: styles.container },
    React.createElement(Col, { size: 2, sm: 4, md: 3, lg: 2, xl: 1 },
        React.createElement("span", { className: styles.number },
            React.createElement("span", { className: styles.title },
                t.number,
                React.createElement(Icon, { type: "sortArrows" }))),
        React.createElement("span", { className: styles.poundSign },
            React.createElement("span", { className: styles.title },
                "#",
                React.createElement(Icon, { type: "sortArrows" })))),
    React.createElement(Col, { size: 5, sm: 4, md: 3, lg: 3, xl: 1 },
        React.createElement("span", { className: styles.title },
            t.date,
            React.createElement(Icon, { type: "sortArrows" }))),
    React.createElement(Col, { lg: 2, xl: 2, xlVisible: true },
        React.createElement("span", { className: styles.title },
            t.shop,
            React.createElement(Icon, { type: "sortArrows" }))),
    React.createElement(Col, { lg: 2, xl: 1, xlVisible: true },
        React.createElement("span", { className: styles.title },
            t.delivery,
            React.createElement(Icon, { type: "sortArrows" }))),
    React.createElement(Col, { size: 5, sm: 4, md: 3, lg: 2, xl: 2 },
        React.createElement("span", { className: styles.title },
            t.items,
            React.createElement(Icon, { type: "sortArrows" }))),
    React.createElement(Col, { md: 2, lg: 2, xl: 1, mdVisible: true },
        React.createElement("span", { className: styles.title },
            t.price,
            React.createElement(Icon, { type: "sortArrows" }))),
    React.createElement(Col, { md: 2, lg: 3, xl: 2, lgVisible: true },
        React.createElement("span", { className: styles.title },
            t.status,
            React.createElement(Icon, { type: "sortArrows" }))),
    React.createElement("div", { className: styles.border })));
export default TableTitle;
