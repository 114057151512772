import { t } from '../../../translation/utils';
const translations = {
    en: {
        somethingWentWrong: 'Something going wrong :(',
        offscreenProfileMenu: 'Profile Menu',
        clickToUploadAvatar: 'Click to upload avatar'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
