import React from 'react';
import { Col, Row } from '../../layout';
import styles from './StartSellingMarket.module.scss';
const StartSellingMarket = ({ lang }) => (React.createElement("div", { className: styles.container },
    React.createElement("h2", { className: styles.title, dangerouslySetInnerHTML: {
            __html: lang === 'hi'
                ? 'Graame дает возможность продавать ваши товары в&nbsp;Сингапуре уже сейчас'
                : 'Graame gives you an&nbsp;opportunity to&nbsp;sell all around your&nbsp;locality. Launching with select localities now!'
        } }),
    React.createElement("p", { className: styles.subtitle, dangerouslySetInnerHTML: {
            __html: lang === 'hi'
                ? 'Наша цель&nbsp;— предоставить высококлассный сервис продавцу и&nbsp;гарантировать наивысшее качество товаров покупателю.'
                : 'We guarantee quality experience for both buyers and&nbsp;sellers.'
        } }),
    React.createElement(Row, null,
        React.createElement(Col, { size: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            React.createElement("article", { className: styles.parragraphs },
                React.createElement("p", { dangerouslySetInnerHTML: {
                        __html: lang === 'hi'
                            ? 'Graame&nbsp;— это онлайн-маркетплейс, который предлагает производителям из&nbsp;России, Сингапура, Малайзии, Индонезии и&nbsp;Тайланда создать магазин на&nbsp;платформе и&nbsp;продавать свои товары в&nbsp;странах APAC: на&nbsp;первом этапе&nbsp;— в&nbsp;Сингапуре, и&nbsp;количество доступных стран будет увеличиваться.'
                            : 'Currently accepting sellers from Rudrapur and&nbsp;Haldwani localities. Up&nbsp;next all localities in Uttarakhand, and&nbsp;Uttar Pradesh states.'
                    } }),
                React.createElement("p", { dangerouslySetInnerHTML: {
                        __html: lang === 'hi'
                            ? 'Мы ищем производителей товаров ручной работы или мелкосерийные производства. Если вы&nbsp;создаете красивые, уникальные и&nbsp;вдохновляющие вещи&nbsp;— найдите новую аудиторию вместе с&nbsp;нами!'
                            : 'We are inviting small-scale all kind of shops including producers of&nbsp;craft and tailor made goods. If you are also creating unique, wonderfully made, great quality goods&nbsp;— apply today.'
                    } }))),
        React.createElement(Col, { size: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            React.createElement("div", { className: styles.imageContainer },
                React.createElement("img", { src: './img/storiqa-computer-market.png', alt: "graame market" }))))));
export default StartSellingMarket;
