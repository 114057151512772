/**
 * @generated SignedSource<<5d6ddd94370b77dfc7e31926563e3e39>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "VerifyEmailResendOutput",
            "kind": "LinkedField",
            "name": "resendEmailVerificationLink",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "success",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ResendEmailVerificationLinkMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ResendEmailVerificationLinkMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "572cf9029800e4c4217ba23d34ed5e72",
            "id": null,
            "metadata": {},
            "name": "ResendEmailVerificationLinkMutation",
            "operationKind": "mutation",
            "text": "mutation ResendEmailVerificationLinkMutation(\n  $input: VerifyEmailResend!\n) {\n  resendEmailVerificationLink(input: $input) {\n    success\n  }\n}\n"
        }
    };
})();
node.hash = "6f465b94323e285677e8bfd257e04a09";
export default node;
