import React from 'react';
import { Icon } from '../../../../components/Icon';
import { Button } from '../../../../components/common/Button';
import { Container, Col, Row } from '../../../../layout';
import styles from './WizardFooter.module.scss';
import t from './i18n';
const WizardFooter = ({ currentStep, onChangeStep, onSaveStep, isReadyToNext = true, isSavingInProgress }) => (React.createElement("div", { className: styles.footerContainer },
    React.createElement(Container, { correct: true },
        React.createElement(Row, null,
            React.createElement(Col, { size: 12, md: 2, mdVisible: currentStep !== 1, hidden: currentStep === 1 },
                React.createElement("div", { className: styles.leftButtonContainer },
                    React.createElement("div", { className: styles.leftButton, onClick: () => onChangeStep(currentStep - 1), onKeyDown: () => {
                        }, role: "button", tabIndex: 0, "data-test": `wizardGoBackButton-${currentStep}` },
                        React.createElement(Icon, { type: "arrowLeft" }),
                        React.createElement("span", null, t.goBack)))),
            React.createElement(Col, { size: 12, md: currentStep !== 1 ? 6 : 8 },
                React.createElement("div", { className: styles.footerTextContainer },
                    React.createElement("span", { className: styles.footerText }, t.thisListingIsntActiveYet))),
            React.createElement(Col, { size: 12, md: 4 },
                React.createElement("div", { className: styles.nextButtonContainer },
                    React.createElement(Button, { onClick: () => {
                            onSaveStep(currentStep + 1);
                        }, dataTest: "wizardBackButton", big: true, disabled: !isReadyToNext, isLoading: currentStep !== 4 && isSavingInProgress }, (currentStep === 4 && React.createElement("span", null, t.publishStore)) || (React.createElement("span", null, t.nextStep))))),
            React.createElement(Col, { md: 12, mdHidden: true, hidden: currentStep === 1 },
                React.createElement("div", { className: styles.leftButton, onClick: () => onChangeStep(currentStep - 1), onKeyDown: () => {
                    }, role: "button", tabIndex: 0, "data-test": `wizardGoBackButton-${currentStep}` },
                    React.createElement(Icon, { type: "arrowLeft" }),
                    React.createElement("span", null, t.goBack)))))));
export default WizardFooter;
