import React from 'react';
import { Checkbox } from '../../common/Checkbox';
import t from './i18n';
import styles from './Policy.module.scss';
const Policy = ({ isPrivacyChecked, isTermsChecked, onCheck }) => (React.createElement("div", { className: styles.container },
    React.createElement("div", { className: styles.policyText },
        React.createElement("span", { className: styles.checkbox },
            React.createElement(Checkbox, { id: "terms", isChecked: isPrivacyChecked, onChange: () => onCheck('isPrivacyChecked') })),
        React.createElement("p", null,
            t.checkHere,
            React.createElement("a", { href: "/terms", target: "_blank", rel: "noopener noreferrer", className: styles.link }, t.termsOfUse),
            t.and,
            React.createElement("a", { href: "/privacy", target: "_blank", rel: "noopener noreferrer", className: styles.link }, t.privatePolicy),
            ".")),
    React.createElement("div", { className: styles.policyText },
        React.createElement("span", { className: styles.checkbox },
            React.createElement(Checkbox, { id: "privacy", isChecked: isTermsChecked, onChange: () => onCheck('isTermsChecked') })),
        React.createElement("p", null, t.agree))));
export default Policy;
