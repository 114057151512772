import { fromPairs, map } from 'ramda';
import header from './header';
import footer from './footer';
import start from './start';
import { languages } from '../languages';
const arr = map((item) => [
    item.id,
    {
        locale: item.id,
        messages: {
            header: header[item.id],
            footer: footer[item.id],
            start: start[item.id]
        }
    }
], languages);
const locale = fromPairs(arr);
export default locale;
