import React from 'react';
import { head, map, pathOr } from 'ramda';
import { Link } from 'found';
import { Icon } from '../../../components/Icon';
import { convertSrc } from '../../../utils';
import styles from './StoresProducts.module.scss';
const StoresProducts = ({ storeId, findMostViewedProducts }) => (React.createElement("div", { className: styles.container },
    React.createElement("div", { className: styles.productsWrap }, map((baseProductNode) => {
        const baseProductId = baseProductNode.rawId;
        const products = pathOr([], ['products', 'edges'], baseProductNode);
        const product = head(products);
        const photoMain = pathOr(null, ['node', 'photoMain'], product);
        return (React.createElement(Link, { key: baseProductId, to: `/store/${storeId}/products/${baseProductId}`, className: styles.productPhoto, "data-test": "productLink" },
            React.createElement("div", { className: styles.productPhotoWrap }, photoMain ? (React.createElement("img", { src: convertSrc('small', photoMain), alt: "img" })) : (React.createElement("div", { className: styles.icon },
                React.createElement(Icon, { type: "camera", size: 32 }))))));
    }, map((baseProductItem) => baseProductItem.node, findMostViewedProducts)))));
export default StoresProducts;
