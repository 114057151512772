import React, { Component } from 'react';
import { Icon } from '../Icon';
import styles from './ShowMore.module.scss';
import t from './i18n';
import { classNames } from '../../utils';
class ShowMore extends Component {
    constructor(props) {
        super(props);
        this.state = { on: props.isOpen || false };
    }
    handleClick() {
        this.setState((prevState) => ({ on: !prevState.on }));
    }
    render() {
        const title = this.state.on ? t.showLess : t.showMore;
        const height = this.state.on ? this.props.height || 25 : 0;
        return (React.createElement("div", null,
            React.createElement("button", { className: styles.header, onClick: () => this.handleClick(), "data-test": this.props.dataTest },
                React.createElement("div", { className: styles.icon },
                    React.createElement("div", { className: classNames({ on: this.state.on, off: !this.state.on }) },
                        React.createElement(Icon, { type: "arrowExpand", size: 16, inline: true }))),
                React.createElement("div", { className: styles.text }, title)),
            React.createElement("div", { className: styles.content, style: {
                    maxHeight: `${height}rem`,
                    overflow: this.state.on ? 'unset' : 'hidden'
                } }, this.props.children)));
    }
}
export default ShowMore;
