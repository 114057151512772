import React, { PureComponent } from 'react';
import MediaQuery from 'react-responsive';
import ImageLoader from '../../libs/react-image-loader';
import BannerLoading from './BannerLoading';
import styles from './Banner.module.scss';
import { classNames } from '../../utils';
class Banner extends PureComponent {
    renderImageLoader = (item, type) => (React.createElement("div", { className: classNames(styles, 'wrap', {
            single: type === 'single',
            double: type === 'double'
        }) },
        type === 'single' && (React.createElement(React.Fragment, null,
            React.createElement(MediaQuery, { maxWidth: 575 },
                React.createElement(ImageLoader, { fit: true, left: true, src: item.img, loader: React.createElement(BannerLoading, null) })),
            React.createElement(MediaQuery, { minWidth: 576 },
                React.createElement(ImageLoader, { fit: true, src: item.img, loader: React.createElement(BannerLoading, null) })))),
        type === 'double' && (React.createElement(ImageLoader, { fit: true, left: true, src: item.img, loader: React.createElement(BannerLoading, null) }))));
    render() {
        const { item, type } = this.props;
        return (React.createElement(React.Fragment, null, item.link ? (React.createElement("a", { className: styles.container, href: item.link }, this.renderImageLoader(item, type))) : (React.createElement("div", { className: styles.container }, this.renderImageLoader(item, type)))));
    }
}
export default Banner;
