import { createRender } from 'found';
import React from 'react';
import { Error, Error404 } from '../pages/Errors';
export const render = createRender({
    // renderPending: () => <div>loading </div>,
    renderError: (error) => {
        if (error.status === 404) {
            return React.createElement(Error404, null);
        }
        return React.createElement(Error, null);
    }
});
