import React, { PureComponent } from 'react';
import { classNames, getExchangePrice } from '../../../../../utils';
import { ContextDecorator } from '../../../../../components/App';
import styles from '../OrderInvoice.module.scss';
import t from './i18n';
class InvoiceTotal extends PureComponent {
    render() {
        const { total, currency, shipping, directories } = this.props;
        const { currencyExchange } = directories;
        const exchangePrice = getExchangePrice({
            price: total,
            currency,
            currencyExchange
        });
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.totalField },
                React.createElement("div", { className: styles.totalFieldItem }),
                React.createElement("div", { className: styles.totalFieldItem }),
                React.createElement("div", { className: styles.totalFieldItem }),
                React.createElement("div", { className: classNames(styles, 'totalFieldItem', 'shipping') }, t.shippingAndHandling),
                React.createElement("div", { className: classNames(styles, 'totalFieldItem', 'shipping') }, shipping)),
            React.createElement("div", { className: styles.totalField },
                React.createElement("div", { className: styles.totalFieldItem }),
                React.createElement("div", { className: styles.totalFieldItem }),
                React.createElement("div", { className: styles.totalFieldItem }),
                React.createElement("div", { className: classNames(styles, 'totalFieldItem', 'total') }, t.total),
                React.createElement("div", { className: classNames(styles, 'totalFieldItem', 'total') }, `${total} ${currency}`)),
            React.createElement("div", { className: styles.totalField },
                React.createElement("div", { className: styles.totalFieldItem }),
                React.createElement("div", { className: styles.totalFieldItem }),
                React.createElement("div", { className: styles.totalFieldItem }),
                React.createElement("div", { className: styles.totalFieldItem }),
                React.createElement("div", { className: classNames(styles, 'totalFieldItem', 'equivalent') }, exchangePrice))));
    }
}
export default ContextDecorator(InvoiceTotal);
