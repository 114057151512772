import { graphql, commitMutation } from 'react-relay';
const mutation = graphql `
    mutation DeleteFromCartMutation($input: DeleteFromCartInput!) {
        deleteFromCart(input: $input) {
            ...Cart_cart
        }
    }
`;
const commit = (params) => commitMutation(params.environment, {
    mutation,
    variables: params.variables,
    onCompleted: params.onCompleted,
    onError: params.onError
});
export default { commit };
