import React from 'react';
import { isNil } from 'ramda';
import { Icon } from '../Icon';
import styles from './SearchNoResults.module.scss';
import t from './i18n';
const SearchNoResults = ({ value = undefined }) => (React.createElement("div", { className: styles.container },
    React.createElement(Icon, { type: "searchNoResults", size: 120 }),
    React.createElement("div", { className: styles.text, dangerouslySetInnerHTML: {
            __html: `${t.sorryButNotResults}${!isNil(value) ? ` ${t.for} ‘<strong>${value}</strong>’. ${t.checkYourSearch}` : ''}.`
        } })));
export default SearchNoResults;
