/**
 * @generated SignedSource<<5cba5039dc41ca7b4695a37d2c79c3b4>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "name"
        }
    ], v1 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "Search",
            "kind": "LinkedField",
            "name": "search",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "name",
                            "variableName": "name"
                        }
                    ],
                    "concreteType": "FullNameConnection",
                    "kind": "LinkedField",
                    "name": "autoCompleteProductName",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "FullNameEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "node",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "fetchAutoCompleteProductName_Query",
            "selections": (v1 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "fetchAutoCompleteProductName_Query",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "a8fbe9bc7ebdb522bcf993e61a188c06",
            "id": null,
            "metadata": {},
            "name": "fetchAutoCompleteProductName_Query",
            "operationKind": "query",
            "text": "query fetchAutoCompleteProductName_Query(\n  $name: String!\n) {\n  search {\n    autoCompleteProductName(name: $name) {\n      edges {\n        node\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "99cb5c5622e099fdd7d1fa08f07cd2f3";
export default node;
