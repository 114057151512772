import React from 'react';
import { includes } from 'ramda';
import { Icon } from '../../../../components/Icon';
import styles from './WizardHeader.module.scss';
import t from './i18n';
import { classNames } from '../../../../utils';
const WizardStepper = ({ currentStep, onChangeStep, onSaveStep, allowedSteps }) => {
    const StepLabel = ({ step, text }) => (React.createElement("div", { className: classNames(styles, 'item', { active: currentStep === step }), onClick: () => {
            if (includes(step, allowedSteps)) {
                if (step - currentStep === 1) {
                    onSaveStep(step);
                }
                else {
                    onChangeStep(step);
                }
            }
            if (step < currentStep) {
                onChangeStep(step);
            }
        }, onKeyDown: () => {
        }, role: "button", tabIndex: 0 },
        React.createElement("span", null, text)));
    return (React.createElement("div", { className: styles.stepperContainer },
        React.createElement(StepLabel, { step: 1, text: t.nameYourStore }),
        React.createElement(Icon, { type: "arrowRight" }),
        React.createElement(StepLabel, { step: 2, text: t.setUpStore }),
        React.createElement(Icon, { type: "arrowRight" }),
        React.createElement(StepLabel, { step: 3, text: t.fillYourStoreWithGoods }),
        React.createElement(Icon, { type: "arrowRight" }),
        React.createElement(StepLabel, { step: 4, text: t.paymentSettings })));
};
export default WizardStepper;
