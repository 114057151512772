import { commitMutation, graphql } from 'react-relay';
const mutation = graphql `
	mutation CreateOrdersMutation($input: CreateOrderInput!) {
		createOrders(input: $input) {
			cart {
				id
				totalCount
				stores {
					edges {
						node {
							id
						}
					}
				}
			}
			invoice {
				id
				amount
				amountCaptured
				currency
				priceReservedDueDateTime
				state
				transactions {
					id
					amount
				}
				orders {
					id
					slug
					productId
					quantity
					price
				}
				paymentIntent {
					id
					clientSecret
					status
				}
			}
		}
	}
`;
const commit = (params) => commitMutation(params.environment, {
    mutation,
    variables: params.variables,
    onCompleted: params.onCompleted,
    onError: params.onError
});
export default { commit };
