/**
 * @generated SignedSource<<c69643229c2e8c727ea3bc8937fc88fb>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "id"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amount",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "PaymentInfo_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        {
                            "args": (v1 /*: any*/),
                            "kind": "FragmentSpread",
                            "name": "PaymentInfo_me"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "PaymentInfo_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": (v1 /*: any*/),
                            "concreteType": "Invoice",
                            "kind": "LinkedField",
                            "name": "invoice",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "amountCaptured",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "priceReservedDueDateTime",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "state",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Transaction",
                                    "kind": "LinkedField",
                                    "name": "transactions",
                                    "plural": true,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "currency",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "fcff06fb7271933247c9188874bcbf8e",
            "id": null,
            "metadata": {},
            "name": "PaymentInfo_Query",
            "operationKind": "query",
            "text": "query PaymentInfo_Query(\n  $id: String!\n) {\n  me {\n    ...PaymentInfo_me_1Bmzm5\n    id\n  }\n}\n\nfragment PaymentInfo_me_1Bmzm5 on User {\n  invoice(id: $id) {\n    id\n    amount\n    amountCaptured\n    priceReservedDueDateTime\n    state\n    transactions {\n      id\n      amount\n    }\n    currency\n  }\n}\n"
        }
    };
})();
node.hash = "f7a09686d46fc1c30de045849e7882cf";
export default node;
