import React, { PureComponent } from 'react';
import { withRouter } from 'found';
import { Stripe } from '../Stripe';
import styles from './PaymentInfoFiat.module.scss';
import t from './i18n';
class PaymentInfoFiat extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { paidComplete: false };
    }
    componentWillUnmount() {
        if (this.paidTimer) {
            clearTimeout(this.paidTimer);
        }
    }
    paidTimer; // NodeJs.Timeout;
    handlePaid = () => {
        this.setState({ paidComplete: true }, () => {
            // if (this.paidTimer) {
            //   clearTimeout(this.paidTimer);
            // }
            // this.paidTimer = setTimeout(() => {
            //   const { orderSlug } = this.props;
            //   if (orderSlug != null) {
            //     this.props.router.push(`/profile/orders/${orderSlug}`);
            //   }
            // }, 2000);
        });
    };
    render() {
        const { invoice, me, orderState } = this.props;
        const { paymentIntent } = invoice;
        if (!invoice || !paymentIntent || (orderState && orderState === 'AMOUNT_EXPIRED')) {
            return (React.createElement("div", { className: styles.container, "data-test": "PAYMENT_INFO_FAILED" },
                React.createElement("div", { className: styles.wrap },
                    React.createElement("div", { className: styles.title }, t.error),
                    React.createElement("div", { className: styles.description }, t.yourPaymentWasFailed))));
        }
        if (paymentIntent && paymentIntent.status === 'SUCCEEDED') {
            return (React.createElement("div", { className: styles.container, "data-test": "PAYMENT_INFO_ALREADY_PAID" },
                React.createElement("div", { className: styles.wrap },
                    React.createElement("div", { className: styles.title }, t.success),
                    React.createElement("div", { className: styles.description }, t.alreadyPaid))));
        }
        const { state, amount, currency } = invoice;
        const { paidComplete } = this.state;
        const dataTest = state === 'NEW' || state === 'PAYMENT_AWAITED' || state === 'PAID' ? state : '';
        return (React.createElement("div", { className: styles.container, "data-test": `PAYMENT_INFO_${dataTest}` },
            React.createElement("div", { className: styles.wrap },
                React.createElement("div", { className: styles.info }, !paidComplete && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: styles.title }, t.payment),
                    React.createElement("div", { className: styles.fiat },
                        React.createElement(Stripe, { paymentIntent: paymentIntent, amount: amount, currency: currency, email: me.email, name: `${me.firstName} ${me.lastName}`, onPaid: this.handlePaid }))))))));
    }
}
export default withRouter(PaymentInfoFiat);
