import React, { Component } from 'react';
import { withRouter } from 'found';
import { Authorization } from '../../components/Authorization';
import { AppContext, Page } from '../../components/App';
import styles from './PasswordReset.module.scss';
class PasswordReset extends Component {
    handleCloseModal = () => {
        const { router: { push } } = this.props;
        push('/');
    };
    render() {
        return (React.createElement(AppContext.Consumer, null, ({ environment, handleLogin }) => (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.wrapper },
                React.createElement(Authorization, { isResetPassword: true, environment: environment, handleLogin: handleLogin }))))));
    }
}
export default Page(withRouter(PasswordReset));
