import React from 'react';
import { Link } from 'found';
import { Col, Row } from '../../../layout';
import { SearchInput } from '../../SearchInput';
import { UserDropdown } from '../../UserDropdown';
import { CartButton } from '../../CartButton';
import styles from './HeaderBottom.module.scss';
import { classNames } from '../../../utils';
import AuthButtons from '../AuthButtons';
import { Icon } from '../../Icon';
const HeaderBottom = ({ searchCategories, searchValue, totalCount, userData, onMobileSearch, onOpenModal }) => (React.createElement("div", { className: styles.container },
    React.createElement(Row, null,
        React.createElement(Col, { size: 6, sm: 4, md: 6, lg: 3, xl: 3 },
            React.createElement("div", { className: styles.logo },
                React.createElement("div", { className: styles.logoIcon, "data-test": "logoLink" },
                    React.createElement(Link, { to: "/" },
                        React.createElement(Icon, { type: "logo", dataTest: "storiqaIcon" }))))),
        React.createElement(Col, { size: 2, sm: 5, md: 1, lg: 6, xl: 6 },
            React.createElement("div", { className: styles.searchBar },
                React.createElement(SearchInput, { searchCategories: searchCategories, searchValue: searchValue }))),
        React.createElement(Col, { size: 4, sm: 3, md: 5, lg: 3, xl: 3 },
            React.createElement("div", { className: styles.userData },
                React.createElement("div", { onClick: onMobileSearch, onKeyPress: () => {
                    }, role: "button", className: classNames(styles, 'searchIcon', { isUserLoggedIn: userData }), tabIndex: -1 },
                    React.createElement(Icon, { type: "magnifier" })),
                userData ? (React.createElement(UserDropdown, { user: userData })) : (React.createElement(AuthButtons, { onOpenModal: onOpenModal })),
                React.createElement("div", { className: classNames(styles, 'cartIcon', { isUserLoggedIn: userData }) },
                    React.createElement(CartButton, { href: "/cart", amount: totalCount })))))));
export default HeaderBottom;
