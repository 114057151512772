import { commitMutation, graphql } from 'react-relay';
import { omit } from 'ramda';
import { v4 as uuidv4 } from 'uuid';
const mutation = graphql `
	mutation UpdateWizardMutation($input: UpdateWizardStoreInput!) {
		updateWizardStore(input: $input) {
			id
			rawId
			storeId
			name
			slug
			shortDescription
			defaultLanguage
			addressFull {
				country
				countryCode
				value
				administrativeAreaLevel1
				administrativeAreaLevel2
				locality
				political
				postalCode
				route
				streetNumber
			}
			stepThree {
				edges {
					node {
						id
					}
				}
			}
			store {
				id
				rawId
				baseProducts(first: 100) @connection(key: "Wizard_baseProducts") {
					edges {
						node {
							id
							rawId
							name {
								text
								lang
							}
							shortDescription {
								lang
								text
							}
							category {
								id
								rawId
							}
							storeId
							currency
							products(first: 1) @connection(key: "Wizard_products") {
								edges {
									node {
										id
										rawId
										price
										discount
										photoMain
										additionalPhotos
										vendorCode
										cashback
										price
										attributes {
											value
											metaField
											attribute {
												id
												rawId
												name {
													lang
													text
												}
												metaField {
													values
													translatedValues {
														translations {
															text
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
const commit = (params) => commitMutation(params.environment, {
    mutation,
    variables: {
        input: {
            clientMutationId: uuidv4(),
            ...omit(['environment', 'onCompleted', 'onError'], params)
        }
    },
    onCompleted: params.onCompleted,
    onError: params.onError
});
export default { commit };
