/**
 * @generated SignedSource<<1a5436cd7a2a00618fc9bed33634ba0d>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "companyPackageId",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "price",
        "storageKey": null
    }, v3 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "ShippingOutput",
            "kind": "LinkedField",
            "name": "upsertShipping",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "LocalShippingProducts",
                    "kind": "LinkedField",
                    "name": "local",
                    "plural": true,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "InternationalShippingProducts",
                    "kind": "LinkedField",
                    "name": "international",
                    "plural": true,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Country",
                            "kind": "LinkedField",
                            "name": "deliveriesTo",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "alpha3",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "label",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "PickupsOutput",
                    "kind": "LinkedField",
                    "name": "pickup",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "pickup",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UpsertShippingMutation",
            "selections": (v3 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UpsertShippingMutation",
            "selections": (v3 /*: any*/)
        },
        "params": {
            "cacheID": "62bff385b004b524dcfc89563fc93331",
            "id": null,
            "metadata": {},
            "name": "UpsertShippingMutation",
            "operationKind": "mutation",
            "text": "mutation UpsertShippingMutation(\n  $input: NewShippingInput!\n) {\n  upsertShipping(input: $input) {\n    local {\n      companyPackageId\n      price\n    }\n    international {\n      companyPackageId\n      price\n      deliveriesTo {\n        alpha3\n        label\n      }\n    }\n    pickup {\n      price\n      pickup\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "cb266de69329cd0b13d2137b92ce9d6d";
export default node;
