import React, { Component } from 'react';
import { isEmpty, pathOr } from 'ramda';
import { createFragmentContainer, graphql } from 'react-relay';
import { v4 as uuidv4 } from 'uuid';
import { Environment } from 'relay-runtime';
import PropTypes from 'prop-types';
import { ManageStore } from '../../../index';
import { withShowAlert } from '../../../../../../components/Alerts/AlertContext';
import { Page } from '../../../../../../components/App';
import { debug, fromRelayError } from '../../../../../../utils';
import { UpdateWarehouseMutation } from '../../../../../../relay/mutations';
import Form from '../Form';
import styles from './EditStorage.module.scss';
import t from './i18n';
class EditStorage extends Component {
    static contextTypes = { environment: PropTypes.instanceOf(Environment).isRequired };
    constructor(props) {
        super(props);
        const warehouse = pathOr({}, ['me', 'warehouse'], this.props);
        this.state = {
            isLoading: false,
            formErrors: {},
            name: warehouse.name,
            addressFull: warehouse.addressFull
        };
    }
    handleSave = (data) => {
        const { environment } = this.context;
        const storeId = pathOr(null, ['match', 'params', 'storeId'], this.props);
        const id = pathOr(null, ['me', 'warehouse', 'id'], this.props);
        const params = {
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    id,
                    name: data.name,
                    addressFull: { ...data.addressFull }
                }
            },
            environment,
            onCompleted: (response, errors) => {
                this.setState(() => ({ isLoading: false }));
                debug({
                    response,
                    errors
                });
                const relayErrors = fromRelayError({ source: { errors } });
                debug({ relayErrors });
                const validationErrors = pathOr({}, ['100', 'messages'], relayErrors);
                if (!isEmpty(validationErrors)) {
                    this.setState({ formErrors: validationErrors });
                    return;
                }
                const statusError = pathOr({}, ['100', 'status'], relayErrors);
                if (!isEmpty(statusError)) {
                    this.props.showAlert({
                        type: 'danger',
                        text: `${t.error} "${statusError}"`,
                        link: { text: t.close }
                    });
                    return;
                }
                this.props.showAlert({
                    type: 'success',
                    text: t.storageUpdated,
                    link: { text: '' }
                });
                this.props.router.push(`/manage/store/${storeId}/storages`);
            },
            onError: (error) => {
                debug({ error });
                this.props.showAlert({
                    type: 'danger',
                    text: t.somethingGoingWrong,
                    link: { text: t.close }
                });
            }
        };
        UpdateWarehouseMutation.commit(params);
    };
    handleCancel = () => {
        const storeId = pathOr(null, ['match', 'params', 'storeId'], this.props);
        if (storeId) {
            this.props.router.push(`/manage/store/${storeId}/storages`);
        }
    };
    render() {
        const { name, addressFull, isLoading, formErrors } = this.state;
        return (React.createElement("div", { className: styles.container },
            React.createElement(Form, { isLoading: isLoading, name: name, addressFull: addressFull, handleCancel: this.handleCancel, formErrors: formErrors, handleSave: this.handleSave })));
    }
}
export default createFragmentContainer(withShowAlert(Page(ManageStore({
    OriginalComponent: EditStorage,
    active: 'storages',
    title: 'Storages'
}))), {
    me: graphql `
			fragment EditStorage_me on User
			@argumentDefinitions(storageSlug: { type: "String!" }) {
				warehouse(slug: $storageSlug) {
					id
					name
					slug
					addressFull {
						country
						administrativeAreaLevel1
						administrativeAreaLevel2
						political
						postalCode
						streetNumber
						value
						route
						locality
					}
					storeId
				}
			}
    `
});
