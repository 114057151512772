import React from 'react';
import { withRouter } from 'found';
import { Container, Row } from '../../layout';
import styles from './StartSellingHeading.module.scss';
import StartSellingButton from './StartSellingButton';
const StartSellingHeading = (prop) => (React.createElement(Container, null,
    React.createElement(Row, null,
        React.createElement("div", { className: styles.container },
            React.createElement("h2", { className: styles.title, dangerouslySetInnerHTML: {
                    __html: prop.lang === 'hi'
                        ? 'Прими участие в&nbsp;программе тестирования, чтобы привлечь покупателей из&nbsp;Сингапура!'
                        : 'Join our community of&nbsp;sellers, and sell to&nbsp;buyers across&nbsp;your locality and beyond. Premium shop tools and rewards!'
                } }),
            React.createElement("div", { className: styles.titleSpacer }),
            React.createElement("p", { className: styles.subtitle, dangerouslySetInnerHTML: {
                    __html: prop.lang === 'hi'
                        ? 'Первые 50&nbsp;магазинов получат до&nbsp;5&nbsp;000$ на&nbsp;промо их&nbsp;товаров в&nbsp;Юго&#8209;Восточной Азии.'
                        : 'Act Now! First 50 registered sellers get&nbsp;up to&nbsp;₹5000 in&nbsp;credits to&nbsp;promote their shop online. '
                } }),
            React.createElement("div", { className: styles.button },
                React.createElement(StartSellingButton
                // href=""
                , { 
                    // href=""
                    text: prop.lang === 'hi' ? 'Создать магазин' : 'Start Selling', onClick: () => {
                        prop.router.push('/manage/wizard');
                    } }))))));
export default withRouter(StartSellingHeading);
