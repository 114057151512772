import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { withRouter } from 'found';
import { pathOr } from 'ramda';
import { ManageStore } from '../index';
import { OrderPage } from '../../../common/OrderPage';
import { Page } from '../../../../components/App';
class StoreOrder extends PureComponent {
    render() {
        const order = pathOr(null, ['myStore', 'order'], this.props.me);
        if (!order) {
            return null;
        }
        return React.createElement(OrderPage, { order: order, isAbleToManageOrder: true, showInvoice: true });
    }
}
export default createFragmentContainer(Page(withRouter(ManageStore({
    OriginalComponent: StoreOrder,
    active: 'orders',
    title: 'Orders'
}))), {
    me: graphql `
			fragment StoreOrder_me on User
			@argumentDefinitions(slug: { type: "Int!", defaultValue: 0 }) {
				myStore {
					rawId
					order(slug: $slug) {
						id
						currency
						slug
						deliveryCompany
						storeId
						product {
							id
							rawId
							baseProduct {
								rawId
								name {
									text
								}
								category {
									rawId
									name {
										text
										lang
									}
								}
							}
							price
							attributes {
								value
								attribute {
									name {
										lang
										text
									}
								}
							}
							photoMain
						}
						receiverName
						receiverPhone
						addressFull {
							value
							country
							administrativeAreaLevel1
							administrativeAreaLevel2
							locality
							political
							postalCode
							route
							streetNumber
							placeId
						}
						createdAt
						deliveryCompany
						trackId
						quantity
						subtotal
						state
						paymentStatus
						totalAmount
						deliveryPrice
						couponDiscount
						history {
							edges {
								node {
									state
									committedAt
									committerRole
									comment
								}
							}
						}
						fee {
							id
							orderId
							amount
							status
							currency
							chargeId
						}
						orderBilling {
							id
							state
							stripeFee
							sellerCurrency
						}
					}
				}
			}
    `
});
