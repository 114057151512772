/**
 * @generated SignedSource<<02bdce3d41e636ff35489505acc7a7eb>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "slug"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "slug",
            "variableName": "slug"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amount",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "state",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "routes_Invoice_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        {
                            "args": (v1 /*: any*/),
                            "kind": "FragmentSpread",
                            "name": "Invoice_me"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "routes_Invoice_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": [
                                {
                                    "kind": "Literal",
                                    "name": "id",
                                    "value": ""
                                }
                            ],
                            "concreteType": "Invoice",
                            "kind": "LinkedField",
                            "name": "invoice",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "amountCaptured",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "priceReservedDueDateTime",
                                    "storageKey": null
                                },
                                (v4 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Transaction",
                                    "kind": "LinkedField",
                                    "name": "transactions",
                                    "plural": true,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v5 /*: any*/)
                            ],
                            "storageKey": "invoice(id:\"\")"
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastName",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": (v1 /*: any*/),
                            "concreteType": "GraphQLOrder",
                            "kind": "LinkedField",
                            "name": "order",
                            "plural": false,
                            "selections": [
                                (v4 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "slug",
                                    "storageKey": null
                                },
                                (v5 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Invoice",
                                    "kind": "LinkedField",
                                    "name": "invoice",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        (v5 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PaymentIntent",
                                            "kind": "LinkedField",
                                            "name": "paymentIntent",
                                            "plural": false,
                                            "selections": [
                                                (v2 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "clientSecret",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "status",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        (v4 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "536b3f3e17e857d09bf99ece725be2bb",
            "id": null,
            "metadata": {},
            "name": "routes_Invoice_Query",
            "operationKind": "query",
            "text": "query routes_Invoice_Query(\n  $slug: Int!\n) {\n  me {\n    ...Invoice_me_20J5Pl\n    id\n  }\n}\n\nfragment Invoice_me_20J5Pl on User {\n  ...PaymentInfo_me\n  email\n  firstName\n  lastName\n  order(slug: $slug) {\n    state\n    slug\n    currency\n    invoice {\n      id\n      amount\n      currency\n      paymentIntent {\n        id\n        clientSecret\n        status\n      }\n      state\n    }\n    id\n  }\n}\n\nfragment PaymentInfo_me on User {\n  invoice(id: \"\") {\n    id\n    amount\n    amountCaptured\n    priceReservedDueDateTime\n    state\n    transactions {\n      id\n      amount\n    }\n    currency\n  }\n}\n"
        }
    };
})();
node.hash = "67040b6df78d2e9a7ced96bd650d0596";
export default node;
