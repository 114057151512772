import { commitMutation, graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import { debug, info } from '../../utils';
const mutation = graphql `
	mutation CreateProductWithAttributesMutation( $input: CreateProductWithAttributesInput! ) {
		createProduct(input: $input) {
			id
			rawId
			baseProduct(visibility: "active") {
				id
				store {
					warehouses {
						id
					}
				}
			}
			stocks {
				id
				productId
				warehouseId
				warehouse {
					name
					addressFull {
						country
						countryCode
						administrativeAreaLevel1
						administrativeAreaLevel2
						political
						postalCode
						streetNumber
						value
						route
						locality
					}
				}
				quantity
			}
			discount
			photoMain
			additionalPhotos
			vendorCode
			cashback
			price
			preOrder
			preOrderDays
			attributes {
				attrId
				value
				metaField
				attribute {
					id
					rawId
					name {
						lang
						text
					}
					metaField {
						values
						translatedValues {
							translations {
								text
							}
						}
					}
				}
			}
		}
	}
`;
const commit = (params) => commitMutation(params.environment, {
    mutation,
    variables: params.variables,
    onCompleted: params.onCompleted,
    onError: params.onError,
    updater: (relayStore, newData) => {
        info('>>> CreateBaseProductMutation updater params: ', {
            params,
            newData
        });
        if (!params || !params.parentID) {
            return;
        }
        const baseProductProxy = relayStore.get(params.parentID);
        const conn = ConnectionHandler.getConnection(baseProductProxy, 'Wizard_products');
        const newProduct = relayStore.getRootField('createProduct');
        const edge = ConnectionHandler.createEdge(relayStore, conn, newProduct, 'ProductsEdge');
        ConnectionHandler.insertEdgeAfter(conn, edge);
    }
});
const promise = (variables, parentID, environment) => new Promise((resolve, reject) => {
    commit({
        variables,
        parentID,
        environment,
        onCompleted: (response, errors) => {
            debug('CreateProductWithAttributesMutation', {
                response,
                errors
            });
            if (errors) {
                reject(errors);
            }
            else if (response) {
                resolve(response);
            }
            else {
                reject([new Error('Unknown error')]);
            }
        },
        onError: (error) => reject([error])
    });
});
export default {
    commit,
    promise
};
