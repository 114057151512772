/**
 * @generated SignedSource<<534d4985850af04a11f6a9379f9411e6>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "slug"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "slug",
                    "variableName": "slug"
                }
            ],
            "kind": "ScalarField",
            "name": "storeSlugExists",
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "fetchStoreSlugExists_Query",
            "selections": (v1 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "fetchStoreSlugExists_Query",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "ce807bc0563df315c3a654c165fd2e4a",
            "id": null,
            "metadata": {},
            "name": "fetchStoreSlugExists_Query",
            "operationKind": "query",
            "text": "query fetchStoreSlugExists_Query(\n  $slug: String!\n) {\n  storeSlugExists(slug: $slug)\n}\n"
        }
    };
})();
node.hash = "41fe50d3a87a2eb1168091cf64de1933";
export default node;
