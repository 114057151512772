import React, { Component } from 'react';
import { zipObj, isEmpty, filter, values } from 'ramda';
import { getNameText, searchPathByParent, flattenFunc, classNames } from '../../utils';
import LevelList from './LevelList';
import styles from './CategorySelector.module.scss';
import t from './i18n';
class CategorySelector extends Component {
    constructor(props) {
        super(props);
        const { categories, category } = props;
        let items = {
            level1Item: null,
            level2Item: null,
            level3Item: null
        };
        if (categories && category) {
            const flattenCategories = flattenFunc(categories.children);
            const currentCategories = searchPathByParent(flattenCategories, category.rawId);
            items = zipObj(['level1Item', 'level2Item', 'level3Item'], currentCategories);
        }
        this.state = {
            level1Item: null,
            level2Item: null,
            level3Item: null,
            snapshot: { ...items },
            lang: 'EN',
            isShow: false
        };
    }
    UNSAFE_componentWillMount() {
        if (process.env.BROWSER) {
            window.addEventListener('click', this.handleToggleExpand);
        }
    }
    componentWillUnmount() {
        if (process.env.BROWSER) {
            window.removeEventListener('click', this.handleToggleExpand);
        }
    }
    categoryWrapp;
    items;
    button;
    handleToggleExpand = (e) => {
        const { onlyView } = this.props;
        const { snapshot } = this.state;
        const isCategoryWrap = this.categoryWrapp && this.categoryWrapp.contains(e.target);
        const isButton = this.button && this.button.contains(e.target);
        if (isButton && !isCategoryWrap && !onlyView) {
            this.setState({
                isShow: true,
                ...snapshot
            });
            return;
        }
        if (!isCategoryWrap) {
            this.setState({ isShow: false });
        }
    };
    handleOnChoose = (category) => () => {
        const { onSelect } = this.props;
        this.setState({ [`level${category.level}Item`]: category });
        switch (category.level) {
            case 1:
                this.setState({
                    level2Item: null,
                    level3Item: null
                });
                break;
            case 2:
                this.setState({ level3Item: null });
                break;
            case 3:
                this.setState((prevState) => ({
                    isShow: false,
                    snapshot: {
                        level1Item: prevState.level1Item,
                        level2Item: prevState.level2Item,
                        level3Item: category
                    }
                }));
                onSelect(parseInt(category.rawId, 10));
                break;
            default:
                break;
        }
    };
    renderPath = () => {
        const { onlyView } = this.props;
        const { lang, snapshot } = this.state;
        return (React.createElement("div", { className: classNames(styles, 'breadcrumbs', { onlyView }), onClick: () => this.setState({ isShow: true }), onKeyDown: () => { }, role: "button", tabIndex: 0, "data-test": "categorySelector" },
            React.createElement("div", { className: styles.breadcrumbsContent },
                snapshot
                    && values(snapshot).map((item, index) => (item ? (React.createElement("span", { key: item.rawId },
                        index !== 0 && ' / ',
                        getNameText(item.name, lang))) : null)),
                snapshot
                    && isEmpty(filter((item) => Boolean(item), values(snapshot)))
                    && 'Choose category')));
    };
    render() {
        const { id, categories } = this.props;
        const { lang, level1Item, level2Item, level3Item, isShow } = this.state;
        const level1ItemName = level1Item ? getNameText(level1Item.name, lang) : '';
        const level2ItemName = level2Item ? getNameText(level2Item.name, lang) : '';
        return (React.createElement("div", { id: id || null, className: styles.container },
            React.createElement("div", { className: styles.wrapper },
                React.createElement("div", { className: styles.label }, t.category),
                React.createElement("div", { className: styles.breadcrumbsWrapper, ref: (node) => {
                        this.button = node;
                    } }, this.renderPath()),
                React.createElement("div", { className: classNames(styles, 'items', { hidden: !isShow }) },
                    React.createElement("div", { className: styles.menuContainer, ref: (node) => {
                            this.categoryWrapp = node;
                        } },
                        React.createElement("div", { className: styles.levelWrapper },
                            React.createElement("div", { className: styles.level },
                                React.createElement(React.Fragment, null,
                                    React.createElement("div", { className: styles.levelLabel }, t.categories),
                                    React.createElement(LevelList, { items: categories.children, lang: lang, onClick: this.handleOnChoose, selectedItem: level1Item }))),
                            React.createElement("div", { className: styles.level }, level1Item && (React.createElement(React.Fragment, null,
                                React.createElement("div", { className: styles.levelLabel }, level1ItemName),
                                React.createElement(LevelList, { items: level1Item.children, lang: lang, onClick: this.handleOnChoose, selectedItem: level2Item })))),
                            React.createElement("div", { className: styles.level }, level2Item && (React.createElement(React.Fragment, null,
                                React.createElement("div", { className: styles.levelLabel }, level2ItemName),
                                React.createElement(LevelList, { items: level2Item.children, lang: lang, onClick: this.handleOnChoose, selectedItem: level3Item }))))))))));
    }
}
export default CategorySelector;
