import React, { Component } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import { isNil } from 'ramda';
import styles from './Textarea.module.scss';
import { classNames } from '../../../utils';
class Textarea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            labelFloat: Boolean(this.props.value),
            isFocus: false
        };
    }
    handleChange = (e) => {
        const { onChange, limit } = this.props;
        const { value } = e.target;
        if (limit != null && value.length > limit) {
            return;
        }
        onChange(e);
    };
    handleFocus = () => {
        this.setState((prevState) => ({
            labelFloat: !prevState.labelFloat || true,
            isFocus: true
        }));
    };
    handleBlur = () => {
        const { value, onBlur } = this.props;
        this.setState({
            labelFloat: Boolean(value) && value.length > 0,
            isFocus: false
        });
        if (onBlur) {
            onBlur();
        }
    };
    render() {
        const { id, value, label, errors, fullWidth, limit } = this.props;
        const { labelFloat, isFocus } = this.state;
        return (React.createElement("label", { htmlFor: id, className: classNames(styles, 'container', {
                isError: errors,
                isFocus,
                fullWidth
            }) },
            React.createElement("span", { className: classNames(styles, 'label', { labelFloat }) }, label),
            React.createElement("div", { className: styles.textarea },
                React.createElement(TextareaAutosize, { id: id, name: id, value: value, onChange: this.handleChange, onFocus: this.handleFocus, onBlur: this.handleBlur, "data-test": id }),
                React.createElement("hr", null)),
            errors
                && errors.length > 0 && (React.createElement("div", { className: styles.errors }, errors.map((item, idx) => (React.createElement("div", { key: idx }, item))))),
            isFocus
                && !isNil(limit) && (React.createElement("div", { className: classNames(styles, 'valueLength', { maxValueLength: value && value.length === limit }) },
                value ? value.length : 0,
                ' ',
                "/",
                limit))));
    }
}
export default Textarea;
