import { t } from '../../../../../../../translation/utils';
const translations = {
    en: {
        internationalShipping: 'International shipping',
        withoutInternationalDelivery: 'Without international delivery',
        fixedSinglePriceForAll: 'Fixed, single price for all',
        noAvailablePackages: 'No available packages'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
