import React, { Component } from 'react';
import { Icon } from '../../Icon';
// import t from './i18n';
import styles from './InfoBlock.module.scss';
class InfoBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            social: [
                {
                    id: '0',
                    type: 'facebookGray',
                    size: 32,
                    href: 'https://www.facebook.com/graamemarketplace/'
                },
                {
                    id: '1',
                    type: 'mediumGray',
                    size: 32,
                    href: 'https://medium.com/graamemarketplace'
                },
                {
                    id: '2',
                    type: 'twitterGray',
                    size: 32,
                    href: 'https://twitter.com/graameMarkets'
                },
                {
                    id: '3',
                    type: 'instagramGray',
                    size: 32,
                    href: 'https://www.instagram.com/graamemarketplace/'
                },
                {
                    id: '4',
                    type: 'redditGray',
                    size: 32,
                    href: 'https://www.reddit.com/r/graamemarketplace/'
                },
                {
                    id: '5',
                    type: 'youtubeGray',
                    size: 32,
                    href: 'https://www.youtube.com/channel/UCN1E_N2bTQYuIal5KQLW0Mw'
                }
            ]
            // links: [
            //   {
            //     id: '0',
            //     href: '/',
            //     name: t.about,
            //   },
            //   {
            //     id: '1',
            //     href: '/privacy_policy.pdf',
            //     name: 'Privacy Policy',
            //   },
            //   {
            //     id: '2',
            //     href: '/',
            //     name: t.help,
            //   },
            //   {
            //     id: '3',
            //     href: '/terms_of_use.pdf',
            //     name: 'Terms of Use',
            //   },
            // ],
        };
    }
    render() {
        const { social } = this.state;
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.icons }, social.map(({ id, href, size, type }) => (React.createElement("a", { key: id, href: href, target: "_blank", rel: "noopener noreferrer" },
                React.createElement(Icon, { type: type, size: size })))))));
    }
}
export default InfoBlock;
