import React, { Component } from 'react';
import { findIndex, isEmpty, isNil, propEq, remove } from 'ramda';
import { withRouter } from 'found';
import { classNames } from '../../utils';
import { Icon } from '../Icon';
import styles from './Collapse.module.scss';
class Collapse extends Component {
    static defaultProps = {
        isDisabled: false,
        menuTitle: '',
        selected: '',
        transparent: false,
        grouped: false
    };
    static getDerivedStateFromProps(nextProps, nextState) {
        const { selected, items } = nextProps;
        const index = !isNil(selected) && !isEmpty(`${selected}`) ? findIndex(propEq(selected, 'id'))(items) : 0;
        return {
            index,
            isOpen: false,
            title: undefined,
            ...nextState
        };
    }
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            index: 0,
            title: undefined
        };
    }
    handleClick = () => {
        const { isDisabled } = this.props;
        if (!isDisabled) {
            this.setState(({ isOpen }) => ({
                isOpen: !isOpen
            }));
        }
    };
    handleSelected = (item, index) => {
        const { onSelected } = this.props;
        this.setState({
            index,
            title: item.title
        }, () => onSelected(item));
    };
    renderGroupedItems = (items) => (React.createElement("ul", null, items.map((item, idx) => (React.createElement("li", { key: item.id, onClick: () => this.handleSelected(item, idx), onKeyPress: () => {
        }, role: "none", className: styles.item },
        React.createElement("span", { className: styles.itemTitle }, item.title),
        React.createElement("ul", null, !isNil(item.links) &&
            item.links.map(({ id, name, appLink, pdfLink, link }) => (React.createElement("li", { key: id },
                appLink != null && (React.createElement("button", { className: styles.navItem, onClick: () => {
                        this.props.router.push(appLink);
                    } }, name)),
                pdfLink != null && (React.createElement("a", { href: pdfLink, target: "_blank", className: styles.navItem }, name)),
                link != null && (React.createElement("a", { href: link, target: "_blank", className: styles.navItem }, name)))))))))));
    renderTitle = () => {
        const { menuTitle, items } = this.props;
        const { title, index } = this.state;
        if (!isNil(menuTitle) && !isEmpty(menuTitle)) {
            return menuTitle;
        }
        return isNil(title) ? items[index].title : title;
    };
    render() {
        const { items, transparent, grouped } = this.props;
        const { isOpen, index } = this.state;
        return (React.createElement("div", { className: classNames(styles, 'container', { transparent }) },
            React.createElement("header", { role: "none", onKeyPress: () => {
                }, onClick: this.handleClick, className: styles.header },
                React.createElement("span", null, this.renderTitle()),
                React.createElement("span", { className: classNames(styles, 'icon', { rotate: isOpen }) },
                    React.createElement(Icon, { type: "arrowExpand" }))),
            React.createElement("nav", { className: classNames(styles, 'content', { show: isOpen }) },
                React.createElement("h3", { className: styles.offscreen }, "Collapsable menu"),
                grouped ? (this.renderGroupedItems(items)) : (React.createElement("ul", null, remove(index, 1, items)
                    .map((item, idx) => (React.createElement("li", { key: item.id, onClick: () => this.handleSelected(item, idx), onKeyPress: () => {
                    }, role: "none", className: styles.item }, item.title))))))));
    }
}
export default withRouter(Collapse);
