import React, { PureComponent } from 'react';
import { withRouter } from 'found';
import { Icon } from '../../../components/Icon';
import { Button } from '../../../components/common/Button';
import styles from './CartEmpty.module.scss';
import t from './i18n';
class CartEmpty extends PureComponent {
    onClick = () => {
        this.props.router.push('/');
    };
    render() {
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.wrap },
                React.createElement("div", { className: styles.icon },
                    React.createElement(Icon, { type: "emptyCart", size: 120 })),
                React.createElement("div", { className: styles.text },
                    t.currentlyCartIsEmpty,
                    React.createElement("br", null),
                    t.goToMarketAndPlaceSomeGoods),
                React.createElement("div", { className: styles.button },
                    React.createElement(Button, { big: true, wireframe: true, onClick: this.onClick }, t.goToMarket)))));
    }
}
export default withRouter(CartEmpty);
