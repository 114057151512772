import { t } from '../../../../../translation/utils';
const translations = {
    en: {
        postalCode: 'Postal Code',
        country: 'Country',
        region: 'Region',
        city: 'City',
        areaDistrict: 'Area/District',
        street: 'Street',
        aptSuiteOther: 'Apt/Suite/Other',
        email: 'Email',
        phoneNumber: 'Phone Number'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
