import React from 'react';
import { Link } from 'found';
import { Icon } from '../../../components/Icon';
import { convertSrc, extractText } from '../../../utils';
import styles from './StoresData.module.scss';
import t from './i18n';
const StoresData = ({ store }) => (React.createElement(Link, { to: `/store/${store.rawId}`, className: styles.container },
    React.createElement("div", { className: styles.storeLogo, "data-test": "storeLink" }, store.logo ? (React.createElement("img", { src: convertSrc('small', store.logo), alt: "img" })) : (React.createElement("div", { className: styles.icon },
        React.createElement(Icon, { type: "camera", size: 32 })))),
    React.createElement("div", { className: styles.storeInfo },
        React.createElement("div", { className: styles.storeName }, extractText(store.name)),
        React.createElement("div", { className: styles.storeAdd },
            React.createElement("span", null,
                "97,5%",
                t.reviews),
            store.productsCount && (React.createElement("span", { className: styles.productsCount },
                store.productsCount,
                " ",
                t.goods))))));
export default StoresData;
