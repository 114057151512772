import React from 'react';
import { Icon } from '../../../../../components/Icon';
import styles from '../View/View.module.scss';
import t from './i18n';
const ProductLayer = ({ onEdit, onDelete }) => (React.createElement("div", { className: styles.layer },
    React.createElement("div", { className: styles.editbutton, onClick: onEdit, role: "button", onKeyDown: () => {
        }, tabIndex: 0, "data-test": "wizardEditProductButton" },
        React.createElement(Icon, { type: "note", size: 56 }),
        React.createElement("span", { className: styles.buttonLabel }, t.editProduct)),
    React.createElement("div", { className: styles.editbutton, onClick: onDelete, role: "button", onKeyDown: () => {
        }, tabIndex: 0, "data-test": "wizardDeleteProductButton" },
        React.createElement(Icon, { type: "basket", size: 56 }),
        React.createElement("span", { className: styles.buttonLabel }, t.deleteProduct))));
export default ProductLayer;
