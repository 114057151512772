import { commitMutation, graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
const mutation = graphql `
    mutation DeactivateBaseProductMutation($input: DeactivateBaseProductInput!) {
        deactivateBaseProduct(input: $input) {
            id
        }
    }
`;
const commit = (params) => commitMutation(params.environment, {
    mutation,
    variables: params.variables,
    onCompleted: params.onCompleted,
    onError: params.onError,
    updater: (relayStore) => {
        const { parentID } = params;
        const storeProxy = relayStore.get(parentID);
        const conn = ConnectionHandler.getConnection(storeProxy, 'Wizard_baseProducts');
        ConnectionHandler.deleteNode(conn, params.variables.input.id);
    }
});
export default { commit };
