import React, { Component } from 'react';
import { addIndex, assoc, complement, filter, findIndex, isNil, map, pathOr, propEq } from 'ramda';
import { convertSrc, uploadFile } from '../../../../../utils';
import { UploadWrapper } from '../../../../../components/Upload';
import { Icon } from '../../../../../components/Icon';
import { Select } from '../../../../../components/common/Select';
import styles from './Characteristics.module.scss';
class CharacteristicItem extends Component {
    constructor(props) {
        super(props);
        this.state = { isMainPhotoUploading: false };
    }
    getSelectItems = (attribute) => {
        const values = pathOr(null, ['metaField', 'values'], attribute);
        const translatedValues = pathOr([], ['metaField', 'translatedValues'], attribute);
        const mapIndexed = addIndex(map);
        if (values) {
            return mapIndexed((item, idx) => ({
                id: `${idx}`,
                label: item
            }), values);
        }
        const items = mapIndexed((item, idx) => {
            const text = pathOr(null, ['translations', 0, 'text'], item);
            if (text) {
                return {
                    id: `${idx}`,
                    label: text
                };
            }
            return null;
        }, translatedValues || []);
        return filter(complement(isNil), items);
    };
    handleSelect = (value) => {
        this.props.onSelect({
            ...this.props.value,
            value: value ? value.label : null,
            attrId: this.props.attribute.rawId
        });
    };
    handleOnUpload = (e) => {
        e.preventDefault();
        this.setState({ isMainPhotoUploading: true });
        uploadFile(e.target.files[0])
            .then((result) => {
            if (!result || result.url == null) {
                alert('Error :(');
            }
            this.props.onSelect(assoc('metaField', result.url, this.props.value));
            return true;
        })
            .finally(() => {
            this.setState({ isMainPhotoUploading: false });
        })
            .catch(alert);
    };
    handleRemoveImg = () => {
        this.props.onSelect(assoc('metaField', '', this.props.value));
    };
    render() {
        const { attribute, value } = this.props;
        const items = this.getSelectItems(attribute);
        const selectedItem = value
            ? {
                id: `${findIndex(propEq(value.value, 'label'), items)}`,
                label: value.value
            }
            : null;
        const characteristicImg = pathOr('', ['metaField'], this.props.value);
        const name = pathOr('', ['name', 0, 'text'], attribute);
        return (React.createElement("div", { className: styles.item },
            React.createElement("div", { className: styles.characteristicImg },
                React.createElement(UploadWrapper, { id: attribute.id, onUpload: this.props.value && this.handleOnUpload, buttonHeight: 10, buttonWidth: 10, buttonIconType: "upload", overPicture: convertSrc('small', characteristicImg), dataTest: "productCharacteristicImgUploader", disabled: !this.props.value, buttonLabel: "", loading: this.state.isMainPhotoUploading }),
                characteristicImg && (React.createElement("div", { className: styles.remove },
                    React.createElement("div", { onClick: this.handleRemoveImg, onKeyDown: () => {
                        }, role: "button", tabIndex: 0 },
                        React.createElement(Icon, { type: "basket", size: 32 }))))),
            React.createElement("div", { className: styles.characteristicSelect },
                React.createElement(Select, { forForm: true, withEmpty: true, fullWidth: true, label: name, activeItem: selectedItem, items: items, onSelect: this.handleSelect, dataTest: "characteristicSelect" }))));
    }
}
export default CharacteristicItem;
