import { graphql } from 'react-relay';
import { basicMutation } from '../../../../relay/mutations/basicMutation';
const mutation = graphql `
	mutation GetJwtbyProviderMutation($input: CreateJWTProviderInput!) {
		getJwtbyProvider(input: $input) {
			token
		}
	}
`;
const GetJwtbyProviderMutation = basicMutation(mutation, 'getJwtbyProvider');
export default GetJwtbyProviderMutation;
/*
 {
 "data": {
 "getJwtbyProvider": {
 "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2VtYWlsIjoic2FsZGluLmlsaXlhQGdtYWlsLmNvbSJ9.zPpNXmoqmvLy2vvgg1SVIAVQLglFXYJK_0OPi_o1a_s"
 }
 }
 }
 */
