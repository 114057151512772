import React, { PureComponent } from 'react';
import { isEmpty, map } from 'ramda';
import TableRow from '../TableRow';
import styles from './Table.module.scss';
import t from './i18n';
class Table extends PureComponent {
    renderItems = (items) => map((item) => (React.createElement(TableRow, { key: item.number, item: item, linkFactory: this.props.linkFactory })), items);
    render() {
        const { items } = this.props;
        return (React.createElement(React.Fragment, null, items && !isEmpty(items) ? (React.createElement("div", null, this.renderItems(items))) : (React.createElement("div", { className: styles.noOrders }, t.noOrders))));
    }
}
export default Table;
