// prepareForAccordion getting array of categories with children and
// prepare data for accordion
import { reduce } from 'ramda';
import { getNameText } from '../../utils';
const prepareForAccordion = (arr, lang) => reduce((acc, next) => [
    ...acc,
    {
        name: next.name ? getNameText(next.name, lang) : 'unknown',
        id: next.rawId,
        children: next.children && next.children.length !== 0
            ? prepareForAccordion(next.children, lang)
            : []
    }
], [], arr);
export default prepareForAccordion;
