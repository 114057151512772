import React, { PureComponent } from 'react';
import { Link } from 'found';
import { Count } from '../Count';
import styles from './CartButton.module.scss';
import { Icon } from '../Icon';
class CartButton extends PureComponent {
    render() {
        const { amount, href } = this.props;
        return (React.createElement(Link, { to: href || '/', className: styles.container, "data-test": "header-cart-button" },
            Boolean(amount) && amount > 0 && (React.createElement("div", { className: styles.amount },
                React.createElement(Count, { amount: amount, type: "blue", dataTestId: "header-cart-count" }))),
            React.createElement("div", null,
                React.createElement(Icon, { type: "cart", size: 16 }))));
    }
}
export default CartButton;
