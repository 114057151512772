import React, { Component } from 'react';
import { isNil } from 'ramda';
import { Input } from '../Input';
import { AppContext } from '../../App';
import CurrencySelect from './CurrencySelect';
import styles from './InputPrice.module.scss';
import { classNames } from '../../../utils';
class InputPrice extends Component {
    static getDerivedStateFromProps(nextProps, prevState) {
        const price = `${nextProps.price}`;
        if (Number(price) !== Number(prevState.price)) {
            return {
                ...prevState,
                price
            };
        }
        return null;
    }
    constructor(props) {
        super(props);
        this.state = { price: props.price ? `${props.price}` : '0' };
    }
    handlePriceChange = (e) => {
        const { value } = e.target;
        const { onChangePrice } = this.props;
        const regexp = /(^[0-9]*[.,]?[0-9]*$)/;
        if (regexp.test(value)) {
            this.setState({
                price: value
                    .replace(/^0+/, '0')
                    .replace(/^[.,]/, '0.')
                    .replace(/^0([0-9])/, '$1')
                    .replace(/,/, '.') || '0'
            });
            onChangePrice(Number(value
                .replace(/[.,]$/, '')
                .replace(/^0([0-9])/, '$1')
                .replace(/(^0\.[0-9])0+$/, '$1')));
            return;
        }
        if (value === '') {
            this.setState({ price: '0' }, () => {
                onChangePrice(0);
            });
        }
    };
    handlePriceFocus = () => {
        const { onFocus } = this.props;
        if (onFocus) {
            onFocus();
        }
    };
    handlePriceBlur = () => {
        const value = `${this.state.price}`;
        if (Number(value) === 0) {
            this.setState({ price: '0' });
        }
        else {
            this.setState({
                price: value
                    .replace(/\.$/, '')
                    .replace(/^0([0-9])/, '$1')
                    .replace(/\.0+$/, '')
                    .replace(/(^0\.[0-9])0+$/, '$1')
            });
        }
        const { onBlur } = this.props;
        if (onBlur) {
            onBlur();
        }
    };
    render() {
        const { required, currency, onChangeCurrency, label, align, inputRef, dataTest, errors, id } = this.props;
        const { price } = this.state;
        const requiredLabel = required === true ? (React.createElement("span", null,
            label,
            ' ',
            React.createElement("span", { className: styles.asterisk }, "*"))) : (label);
        return (React.createElement(AppContext.Consumer, null, ({ directories }) => (React.createElement("div", { id: id !== undefined ? id : undefined, className: styles.container },
            React.createElement("div", { className: styles.input },
                React.createElement(Input, { inputRef: inputRef, fullWidth: true, label: requiredLabel, onChange: this.handlePriceChange, onFocus: this.handlePriceFocus, onBlur: this.handlePriceBlur, value: price, align: align, dataTest: `${dataTest}Input`, errors: errors })),
            !isNil(currency) && (React.createElement("div", { className: classNames(styles, 'select', { fixCurrencySelect: !onChangeCurrency }) }, onChangeCurrency ? (React.createElement(CurrencySelect, { currency: currency, onChangeCurrency: onChangeCurrency, currencies: directories.currencies, dataTest: `${dataTest}CurrencySelect` })) : (React.createElement("div", { className: styles.fixCurrency }, currency.label))))))));
    }
}
export default InputPrice;
