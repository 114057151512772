/**
 * @generated SignedSource<<22065de3387dd63da25d5f86c0ce8745>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "id"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amount",
        "storageKey": null
    }, v3 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "id"
                }
            ],
            "concreteType": "Invoice",
            "kind": "LinkedField",
            "name": "recalcInvoiceAmount",
            "plural": false,
            "selections": [
                (v1 /*: any*/),
                (v2 /*: any*/),
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "priceReservedDueDateTime",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "state",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Transaction",
                    "kind": "LinkedField",
                    "name": "transactions",
                    "plural": true,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "RecalcInvoiceAmountMutation",
            "selections": (v3 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "RecalcInvoiceAmountMutation",
            "selections": (v3 /*: any*/)
        },
        "params": {
            "cacheID": "f243931dd1a1249f1ead5899bba2d0e1",
            "id": null,
            "metadata": {},
            "name": "RecalcInvoiceAmountMutation",
            "operationKind": "mutation",
            "text": "mutation RecalcInvoiceAmountMutation(\n  $id: String!\n) {\n  recalcInvoiceAmount(id: $id) {\n    id\n    amount\n    priceReservedDueDateTime\n    state\n    transactions {\n      id\n      amount\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "6e893140a6ffbcd6803ebee2fc7fca5d";
export default node;
