import React, { PureComponent } from 'react';
import { withRouter } from 'found';
import { Button } from '../../../components/common/Button';
import Logo from '../svg/logo.svg';
import styles from '../error.module.scss';
import t from './i18n';
class Error404 extends PureComponent {
    handleBack = () => {
        this.props.router.go(-1);
    };
    handleToMainPage = () => {
        window.location.href = '/';
    };
    render() {
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.logoWrapper },
                React.createElement("div", { className: styles.logo },
                    React.createElement(Logo, null))),
            React.createElement("div", { className: styles.messageWrapper },
                React.createElement("div", { className: styles.code404Wrapper },
                    React.createElement("span", { className: styles.code404 }, "404")),
                React.createElement("div", { className: styles.text },
                    t.oopsItSeemsThatThePageDoesntExist,
                    React.createElement("br", null),
                    t.tryToStartAgain),
                React.createElement("div", { className: styles.button },
                    React.createElement(Button, { big: true, type: "button", onClick: this.handleToMainPage, dataTest: "404Button" }, t.startFromMainPage),
                    React.createElement("div", { role: "link", className: styles.linkBack, onClick: this.handleBack }, t.back)))));
    }
}
export default withRouter(Error404);
