import { graphql } from 'react-relay';
import { basicMutation } from './basicMutation';
const mutation = graphql `
	mutation CreateStoreSubscriptionMutation(
		$input: CreateStoreSubscriptionInput!
	) {
		createStoreSubscription(input: $input) {
			id
			storeId
			currency
			value
			walletAddress
			trialStartDate
			status
		}
	}
`;
const createStoreSubscriptionMutation = basicMutation(mutation, 'createStoreSubscription');
export default createStoreSubscriptionMutation;
