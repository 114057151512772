/**
 * @generated SignedSource<<1e890a332c1ce6094af42e0d65d4fe65>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amount",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [
            {
                "defaultValue": "",
                "kind": "LocalArgument",
                "name": "id"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "PaymentInfo_me",
        "selections": [
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Variable",
                        "name": "id",
                        "variableName": "id"
                    }
                ],
                "concreteType": "Invoice",
                "kind": "LinkedField",
                "name": "invoice",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    (v1 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "amountCaptured",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "priceReservedDueDateTime",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "state",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "Transaction",
                        "kind": "LinkedField",
                        "name": "transactions",
                        "plural": true,
                        "selections": [
                            (v0 /*: any*/),
                            (v1 /*: any*/)
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "currency",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "User",
        "abstractKey": null
    };
})();
node.hash = "4bb80b6884f14bfff5819cb9342cdcc5";
export default node;
