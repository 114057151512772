import { complement, filter, fromPairs, isEmpty, isNil, map, mapObjIndexed, pathOr, toString } from 'ramda';
const isNotNil = complement(isNil);
/*
 see tests
 */
export function fromRelayError(relayError) {
    const errorsArr = pathOr([], ['source', 'errors'], relayError);
    if (isEmpty(errorsArr)) {
        return null;
    }
    const convertedErrors = map((item) => {
        const error = item.data;
        if (!error) {
            return null;
        }
        const { code } = error;
        if (code !== 100) {
            return [toString(code), { status: (error && error.details) || item }];
        }
        const fromDetails = (prop) => pathOr('', ['details', prop], error);
        const status = fromDetails('status');
        const messagesRaw = fromDetails('message');
        let messagesData;
        try {
            messagesData = JSON.parse(messagesRaw);
        }
        catch (e) {
            //
        }
        const messages = {};
        try {
            const prependKeyAndDouble = (val, key) => {
                messages[key] = map((i) => i.message, val);
            };
            mapObjIndexed(prependKeyAndDouble, messagesData);
        }
        catch (e) {
            //
        }
        return [toString(code), {
                status,
                messages
            }];
    }, errorsArr);
    const pairs = filter(isNotNil, convertedErrors);
    return fromPairs(pairs);
}
