import React from 'react';
import { Row, Col } from '../../../layout';
import { Icon } from '../../../components/Icon';
import styles from './StoresHeader.module.scss';
import t from './i18n';
const StoresHeader = ({ category, onFilter, title, searchValue }) => (React.createElement(Row, null,
    React.createElement("span", { onClick: onFilter, onKeyPress: () => {
        }, role: "button", className: styles.container, tabIndex: -1 },
        React.createElement(Icon, { type: "controls" }),
        React.createElement("span", null, t.filters)),
    React.createElement(Col, { sm: 2, md: 2, lg: 2, xl: 2 },
        React.createElement("div", { className: styles.countInfo },
            title,
            searchValue && (React.createElement("span", null,
                " ",
                t.with,
                " ",
                searchValue,
                " ",
                t.inTheTitle)))),
    React.createElement(Col, { sm: 10, md: 10, lg: 10, xl: 10 },
        React.createElement("div", { className: styles.breadcrumbs },
            t.allStores,
            category && ` / ${category.label}`))));
export default StoresHeader;
