import { t } from '../../../../../translation/utils';
const translations = {
    en: {
        customer: 'Customer',
        order: 'Order #',
        trackID: 'Track ID',
        delivery: 'Delivery',
        status: 'Status'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
