import React, { Component } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { anyPass, filter, find, flatten, isEmpty, isNil, map, path, pathOr, pipe, prop, whereEq } from 'ramda';
import { withRouter } from 'found';
import { getCurrentCurrency } from '../../utils';
import { Col, Container, Row } from '../../layout';
import { Page } from '../../components/App';
import { StickyBar } from '../../components/StickyBar';
import CartStore from './CartStore';
import CartEmpty from './CartEmpty';
import { CheckoutSidebar } from '../Checkout/CheckoutSidebar';
import styles from './Cart.module.scss';
import { t } from '../../translation/utils';
class Cart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            storesRef: null,
            totals: {}
        };
    }
    componentDidUpdate(prevProps) {
        const { cart } = this.props;
        if (cart.totalCount !== prevProps.cart.totalCount) {
            //
        }
    }
    setStoresRef(ref) {
        if (ref && !this.state.storesRef) {
            this.setState({ storesRef: ref });
        }
    }
    storesRef;
    dispose;
    totalsForStore(id) {
        return (this.state.totals[id] || {
            productsCost: 0,
            deliveryCost: 0,
            totalCount: 0
        });
    }
    isAllSelectedProductsHaveShipping = (cart) => {
        const storeEdges = pathOr([], ['stores', 'edges'], cart);
        const stores = map(prop('node'), [...storeEdges]);
        const products = flatten(map(prop('products'), stores));
        const selectedProducts = filter(whereEq({ selected: true }), products);
        const productsWithoutShipping = find((item) => item.baseProduct.isShippingAvailable === false, selectedProducts);
        return anyPass([isEmpty, isNil])(productsWithoutShipping);
    };
    handleToCheckout = () => {
        this.props.router.push('/checkout');
    };
    render() {
        const { cart } = this.props;
        const actualCart = cart.fiat; // selectedTab === 0 ? cart.fiat : cart.crypto;
        const stores = pipe(pathOr([], ['stores', 'edges']), map(path(['node'])))(actualCart);
        // const totalCountFiat = pathOr(0, ['fiat', 'totalCount'], cart);
        const { totalCount } = actualCart;
        const emptyCart = cart.totalCount === 0 && isEmpty(stores);
        return (React.createElement("div", { className: styles.container },
            React.createElement(Container, { withoutGrow: true },
                React.createElement(Row, { withoutGrow: true },
                    React.createElement(Col, { size: 12 },
                        React.createElement("div", { className: styles.header }, t.myCart),
                        React.createElement("div", { ref: (ref) => this.setStoresRef(ref) },
                            React.createElement(Row, { withoutGrow: true },
                                emptyCart ? (React.createElement(Col, { size: 12 },
                                    React.createElement("div", { className: styles.wrapper },
                                        React.createElement("div", { className: styles.storeContainer },
                                            React.createElement(CartEmpty, null))))) : (React.createElement(Col, { size: 12, lg: 8, xl: 9 },
                                    React.createElement("div", { className: styles.wrapper },
                                        React.createElement("div", { className: styles.storeContainer }, stores.map((store) => (React.createElement(CartStore, { key: store.__id, store: store, totals: this.totalsForStore(store.__id), isOpenInfo: true, currency: getCurrentCurrency() }))))))),
                                React.createElement(Col, { size: 12, lg: 4, xl: 3 }, !emptyCart && (React.createElement("div", { className: styles.sidebarWrapper },
                                    React.createElement(StickyBar, null,
                                        React.createElement(CheckoutSidebar, { buttonText: "Checkout", onClick: this.handleToCheckout, isReadyToClick: totalCount > 0 && this.isAllSelectedProductsHaveShipping(actualCart), cart: actualCart, currency: getCurrentCurrency() }))))))))))));
    }
}
export default createFragmentContainer(withRouter(Page(Cart, { withoutCategories: true })), {
    cart: graphql `
			fragment Cart_cart on Cart {
				id
				totalCount
				fiat {
					id
					productsCost
					deliveryCost
					totalCount
					totalCost
					totalCostWithoutDiscounts
					productsCostWithoutDiscounts
					couponsDiscounts
					stores {
						edges {
							node {
								id
								...CartStore_store
								productsCost
								deliveryCost
								totalCost
								totalCount
								products {
									id
									selected
									baseProduct(visibility: "active") {
										id
										isShippingAvailable
									}
									quantity
								}
							}
						}
					}
				}
			}
    `
});
