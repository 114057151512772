import React from 'react';
import MediaQuery from 'react-responsive';
import { SocialShare } from '../../../components/SocialShare';
import ImageLoader from '../../../libs/react-image-loader';
import { convertSrc } from '../../../utils';
import BannerLoading from '../../../components/Banner/BannerLoading';
import { Icon } from '../../../components/Icon';
import StoreContext from '../StoreContext';
import StoreHeaderBottom from '../StoreHeaderBottom';
import StoreHeaderTabs from '../StoreHeaderTabs';
import styles from './StoreHeader.module.scss';
import t from './i18n';
const StoreHeader = () => (React.createElement(StoreContext.Consumer, null, ({ cover, tabs, storeId, active, facebookUrl, twitterUrl, instagramUrl }) => (React.createElement("header", { className: styles.container },
    React.createElement("div", { className: styles.imageWrapper },
        React.createElement("figure", { className: styles.image },
            cover ? (React.createElement(React.Fragment, null,
                React.createElement(MediaQuery, { maxWidth: 575 },
                    React.createElement(ImageLoader, { fit: true, src: convertSrc('medium', cover), loader: React.createElement(BannerLoading, null) })),
                React.createElement(MediaQuery, { maxWidth: 991, minWidth: 576 },
                    React.createElement(ImageLoader, { fit: true, src: convertSrc('large', cover), loader: React.createElement(BannerLoading, null) })),
                React.createElement(MediaQuery, { minWidth: 992 },
                    React.createElement(ImageLoader, { fit: true, src: cover, loader: React.createElement(BannerLoading, null) })))) : (React.createElement(Icon, { type: "camera", size: 56 })),
            React.createElement("aside", { className: styles.social },
                React.createElement("h2", { className: styles.offscreen }, t.offscreenSocialShare),
                process.env.BROWSER ? (React.createElement(SocialShare, { noFavorite: true, facebookUrl: facebookUrl, twitterUrl: twitterUrl, instagramUrl: instagramUrl })) : null))),
    React.createElement(StoreHeaderBottom, null),
    React.createElement(StoreHeaderTabs, { storeId: storeId, tabs: tabs, active: active })))));
export default StoreHeader;
