import React, { Component } from 'react';
import { adjust, any, assoc, isNil, map, pipe } from 'ramda';
import { Button } from '../../common/Button';
import Input from '../Input';
import { makeInput } from '../utils';
import styles from '../Authorization.module.scss';
import t from './i18n';
class SignIn extends Component {
    state = {
        autocomplete: false
    };
    setResendEmail = (input) => {
        const { onResendEmail } = this.props;
        const errorsArray = input.errors;
        let showResendEmail = false;
        if (!isNil(errorsArray)) {
            showResendEmail = any((i) => i === t.emailNotVerified)(errorsArray);
        }
        return {
            ...input,
            onResendEmail,
            showResendEmail
        };
    };
    handleCheckboxChange = () => {
        this.setState((preSate) => ({ autocomplete: preSate.autocomplete }));
    };
    makeInputs = () => {
        const inputs = [
            {
                label: t.labelEmail,
                type: 'email'
            },
            {
                label: t.labelPassword,
                type: 'password'
            }
        ];
        const makeInputFn = map(makeInput(this.props));
        const setFocus = adjust(0, assoc('thisFocus', true));
        const setResendEmail = adjust(0, this.setResendEmail);
        const setNoHints = adjust(1, assoc('noPasswordHints', true));
        return pipe(makeInputFn, setFocus, setResendEmail, setNoHints)(inputs);
    };
    render() {
        const { formValid, onLoginClick, onRecoverPassword } = this.props;
        return (React.createElement("div", { className: styles.signIn },
            this.makeInputs()
                .map((input) => (React.createElement("div", { key: input.name, className: styles.inputBlock },
                React.createElement(Input, { ...input, model: this.props[input.name] })))),
            React.createElement("div", { className: styles.forgotPassword },
                React.createElement("span", { onClick: onRecoverPassword, onKeyPress: () => {
                    }, role: "button", tabIndex: -1 }, t.forgotPassword)),
            React.createElement("div", { className: styles.signInGroup },
                React.createElement("div", { className: styles.signInButton },
                    React.createElement(Button, { onClick: onLoginClick, type: "button", dataTest: "signInButton", disabled: !formValid },
                        React.createElement("span", null,
                            " ",
                            t.signIn))))));
    }
}
export default SignIn;
