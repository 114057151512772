import { t } from '../../../../translation/utils';
const translations = {
    en: {
        deliveryInfo: 'Delivery Info',
        labelReceiverName: 'Receiver Name',
        labelReceiverPhone: 'Receiver Phone',
        labelChooseYourAddress: 'Choose your address',
        labelAddress: 'Address',
        labelOrFillFields: 'Fill fields below and save as address',
        labelSaveAsNewAddress: 'Save as a new address'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
