import { find, propEq } from 'ramda';
import { checkCurrencyType } from './checkCurrencyType';
import { getCookie } from './cookiesOp';
import { formatPrice } from './formatPrice';
export function getExchangePrice(props) {
    const { price, currency, currencyExchange, withSymbol } = props;
    if (!currency) {
        return null;
    }
    const currencyType = checkCurrencyType(currency);
    if (currencyType === 'crypto') {
        const actualCurrency = getCookie('CURRENCY');
        const currencyExchangeItem = find(propEq(actualCurrency, 'code'))(currencyExchange);
        if (currencyExchangeItem) {
            const ratesItem = find(propEq(currency, 'code'))(currencyExchangeItem.rates);
            if (ratesItem) {
                let actualCurrencySymbol = '';
                if (actualCurrency === 'INR') {
                    actualCurrencySymbol = '₹';
                }
                if (actualCurrency === 'USD') {
                    actualCurrencySymbol = '$';
                }
                if (actualCurrency === 'EUR') {
                    actualCurrencySymbol = '€';
                }
                return `${withSymbol ? actualCurrencySymbol : ''}${formatPrice(ratesItem.value * price, 2)} ${!withSymbol ? actualCurrency : ''}`;
            }
        }
        return 'STQ';
    }
    return null;
}
