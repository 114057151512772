import { t } from '../../../translation/utils';
const translations = {
    en: {
        invalid: 'Invalid',
        onlyNumbers: 'Only numbers',
        invalidEmail: 'Invalid Email',
        invalidPassword: 'Invalid Password'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
