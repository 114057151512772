/**
 * @generated SignedSource<<cd4fe4c99c6e84dd2cd8c0daa560f333>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "productID"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawId",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
            (v1 /*: any*/),
            (v2 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phone",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "UserDeliveryAddress",
                "kind": "LinkedField",
                "name": "deliveryAddressesFull",
                "plural": true,
                "selections": [
                    (v1 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "Address",
                        "kind": "LinkedField",
                        "name": "address",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "country",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "countryCode",
                                "storageKey": null
                            },
                            (v3 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "administrativeAreaLevel1",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "administrativeAreaLevel2",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "locality",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "political",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "postalCode",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "route",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "streetNumber",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "placeId",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isPriority",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v5 = {
        "kind": "Literal",
        "name": "visibility",
        "value": "active"
    }, v6 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "productID"
        },
        (v5 /*: any*/)
    ], v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
    }, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lang",
        "storageKey": null
    }, v10 = [
        (v8 /*: any*/),
        (v9 /*: any*/)
    ], v11 = {
        "alias": null,
        "args": null,
        "concreteType": "Translation",
        "kind": "LinkedField",
        "name": "name",
        "plural": true,
        "selections": (v10 /*: any*/),
        "storageKey": null
    }, v12 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rating",
        "storageKey": null
    }, v13 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "price",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "routes_ProductCard_Query",
            "selections": [
                (v4 /*: any*/),
                {
                    "alias": null,
                    "args": (v6 /*: any*/),
                    "concreteType": "BaseProduct",
                    "kind": "LinkedField",
                    "name": "baseProduct",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "Product_baseProduct"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "routes_ProductCard_Query",
            "selections": [
                (v4 /*: any*/),
                {
                    "alias": null,
                    "args": (v6 /*: any*/),
                    "concreteType": "BaseProduct",
                    "kind": "LinkedField",
                    "name": "baseProduct",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isShippingAvailable",
                            "storageKey": null
                        },
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v7 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "categoryId",
                            "storageKey": null
                        },
                        (v11 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Translation",
                            "kind": "LinkedField",
                            "name": "shortDescription",
                            "plural": true,
                            "selections": (v10 /*: any*/),
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Translation",
                            "kind": "LinkedField",
                            "name": "longDescription",
                            "plural": true,
                            "selections": (v10 /*: any*/),
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": [
                                (v5 /*: any*/)
                            ],
                            "concreteType": "Store",
                            "kind": "LinkedField",
                            "name": "store",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Translation",
                                    "kind": "LinkedField",
                                    "name": "name",
                                    "plural": true,
                                    "selections": [
                                        (v9 /*: any*/),
                                        (v8 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v12 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "productsCount",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "logo",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "facebookUrl",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "twitterUrl",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "instagramUrl",
                                    "storageKey": null
                                },
                                (v1 /*: any*/)
                            ],
                            "storageKey": "store(visibility:\"active\")"
                        },
                        (v12 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Variants",
                            "kind": "LinkedField",
                            "name": "variants",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Product",
                                    "kind": "LinkedField",
                                    "name": "all",
                                    "plural": true,
                                    "selections": [
                                        (v1 /*: any*/),
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "photoMain",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "additionalPhotos",
                                            "storageKey": null
                                        },
                                        (v13 /*: any*/),
                                        (v7 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "CustomerPrice",
                                            "kind": "LinkedField",
                                            "name": "customerPrice",
                                            "plural": false,
                                            "selections": [
                                                (v13 /*: any*/),
                                                (v7 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "preOrder",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "preOrderDays",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "cashback",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "discount",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "quantity",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ProdAttrValue",
                                            "kind": "LinkedField",
                                            "name": "attributes",
                                            "plural": true,
                                            "selections": [
                                                (v3 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "metaField",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Attribute",
                                                    "kind": "LinkedField",
                                                    "name": "attribute",
                                                    "plural": false,
                                                    "selections": [
                                                        (v1 /*: any*/),
                                                        (v11 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "AttributeMetaField",
                                                            "kind": "LinkedField",
                                                            "name": "metaField",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "values",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "uiElement",
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "e5a599bd8ce913a706ecd12c84b3346e",
            "id": null,
            "metadata": {},
            "name": "routes_ProductCard_Query",
            "operationKind": "query",
            "text": "query routes_ProductCard_Query(\n  $productID: Int!\n) {\n  me {\n    id\n    rawId\n    phone\n    firstName\n    lastName\n    deliveryAddressesFull {\n      id\n      address {\n        country\n        countryCode\n        value\n        administrativeAreaLevel1\n        administrativeAreaLevel2\n        locality\n        political\n        postalCode\n        route\n        streetNumber\n        placeId\n      }\n      isPriority\n    }\n  }\n  baseProduct(id: $productID, visibility: \"active\") {\n    ...Product_baseProduct\n    id\n  }\n}\n\nfragment Product_baseProduct on BaseProduct {\n  isShippingAvailable\n  id\n  rawId\n  currency\n  categoryId\n  name {\n    text\n    lang\n  }\n  shortDescription {\n    text\n    lang\n  }\n  longDescription {\n    text\n    lang\n  }\n  store(visibility: \"active\") {\n    rawId\n    name {\n      lang\n      text\n    }\n    rating\n    productsCount\n    logo\n    facebookUrl\n    twitterUrl\n    instagramUrl\n    id\n  }\n  rating\n  variants {\n    all {\n      id\n      rawId\n      photoMain\n      additionalPhotos\n      price\n      currency\n      customerPrice {\n        price\n        currency\n      }\n      preOrder\n      preOrderDays\n      cashback\n      discount\n      quantity\n      attributes {\n        value\n        metaField\n        attribute {\n          id\n          name {\n            text\n            lang\n          }\n          metaField {\n            values\n            uiElement\n          }\n        }\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "0f764975dd233850918b1a5910c94765";
export default node;
