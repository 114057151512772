// import {setConfig} from 'gelf-pro';
const grayLogger = {
    info: (title, meta) => {
    },
    error: (title, meta) => {
    }
};
/*
if (!process.env.BROWSER && process.env.NODE_ENV === 'production') {
  // eslint-disable-next-line
  const grayLogger = setConfig({
    fields: {
      cluster: `${process.env.GRAYLOG_CLUSTER || 'localhost'}`,
      type: 'ssr',
      source_type: 'frontend'
    },
    adapterName: 'tcp',
    adapterOptions: {
      host: 'graylog-tcp.internal.cloud',
      port: 12201,
      family: 4,
      timeout: 1000
    }
  });
}
*/
export { grayLogger };
