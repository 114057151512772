import React from 'react';
import { isEmpty, join, map, prop } from 'ramda';
import { extractText } from '../../../../../utils';
import styles from '../OrderInvoice.module.scss';
const OrderInvoiceTableRow = ({ slug, totalAmount, quantity, product: { attributes, currency, baseProduct: { name } } }) => {
    const description = !isEmpty(attributes)
        ? join(', ', map(prop('value'), attributes))
        : '';
    return (React.createElement("div", { className: styles.tableRow },
        React.createElement("div", { className: styles.tableRowItem },
            " ",
            slug,
            " "),
        React.createElement("div", { className: styles.tableRowItem },
            " ",
            extractText(name, 'EN'),
            " - ",
            description),
        React.createElement("div", { className: styles.tableRowItem },
            " ",
            quantity),
        React.createElement("div", { className: styles.tableRowItem }, "-"),
        React.createElement("div", { className: styles.tableRowItem },
            " ",
            totalAmount,
            " - ",
            currency)));
};
export default OrderInvoiceTableRow;
