import React, { Component } from 'react';
import { Input } from '../components/common/Input';
import { Checkbox } from '../components/common/Checkbox';
import { Button } from '../components/common/Button';
import { Icon } from '../components/Icon';
import Modal from '../pages/Manage/Wizard/Step3/Modal';
import styles from './WindowModal.module.scss';
import { classNames } from '../utils';
import { createRoot } from 'react-dom/client';
class WindowModal extends Component {
    state = {
        showModal: false,
        isDone: false,
        form: {
            email: {
                text: '',
                errors: null
            },
            eth: {
                text: '',
                errors: null
            },
            storageAccess: false,
            newsAccess: false
        }
    };
    componentDidMount() {
        if (process.env.BROWSER) {
            window.showModal = () => {
                this.setState({ showModal: true });
            };
            window.closeModal = () => {
                this.setState({ showModal: false });
            };
            window.onDone = () => {
                this.setState({ isDone: true });
            };
        }
    }
    handleOnClose = () => {
        this.setState({ showModal: false });
    };
    handleChangeEmail = (e) => {
        const { target: { value } } = e;
        const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const emailIsValid = emailReg.test(value);
        this.setState((prevState) => ({
            ...prevState,
            form: {
                ...prevState.form,
                email: {
                    text: value,
                    errors: emailIsValid ? null : ['Invalid email']
                }
            }
        }));
    };
    handleOnStorageChange = () => {
        this.setState((prevState) => ({
            ...prevState,
            form: {
                ...prevState.form,
                storageAccess: !prevState.form.storageAccess
            }
        }));
    };
    handleOnNewsChange = () => {
        this.setState((prevState) => ({
            ...prevState,
            form: {
                ...prevState.form,
                newsAccess: prevState ? !prevState.form.newsAccess : false
            }
        }));
    };
    handleCB = () => {
        const { form } = this.state;
        const result = {
            email: form.email.text,
            eth: form.eth.text,
            storageAccess: form.storageAccess,
            newsAccess: form.newsAccess
        };
        if (process.env.BROWSER) {
            window.cb(result);
        }
    };
    render() {
        const { showModal, form, isDone } = this.state;
        const newsLabel = (React.createElement("span", null,
            "I agree to my personal data being stored and used.",
            React.createElement("a", { className: styles.policy, href: "https://token.graame.com/static/docs/privacy_policy.pdf", target: "_blank", rel: "noopener noreferrer" }, "Privacy Policy")));
        const checkValid = () => {
            if (!form.email.text || !form.eth.text || form.email.errors || form.eth.errors) {
                return false;
            }
            return !(!form.newsAccess || !form.storageAccess);
        };
        return (React.createElement(Modal, { showModal: showModal, onClose: this.handleOnClose, dark: true }, (isDone && (React.createElement("div", { className: classNames(styles, 'modalContainer', 'success') },
            React.createElement("div", { className: styles.modalTitle }, "Thank you for testing Graame\u2019s beta version!"),
            React.createElement("div", { className: styles.modalDescription }, "Make sure you left your email and wallet address, we inform you later when tokens will be distributed."),
            React.createElement("div", { className: styles.modalDescription },
                "Join Telegram to get updates first!",
                React.createElement("a", { className: styles.modalLink, href: "https://t.me/graame_marketplace" },
                    React.createElement(Icon, { type: "telegram", inline: true, size: 20 }),
                    ' ',
                    "https://t.me/graame_marketplace")),
            React.createElement("div", { className: styles.buttonContainerDone },
                React.createElement(Button, { onClick: this.handleOnClose, big: true, wireframe: true },
                    React.createElement("span", null, "Ok")))))) || (React.createElement("div", { className: styles.modalContainer },
            React.createElement("div", { className: styles.modalTitle }, "Thank you for testing Graame\u2019s beta version!"),
            React.createElement("div", { className: styles.modalDescription }, "To participate in airdrop, leave your email & wallet number."),
            React.createElement("div", { className: styles.modalDescription },
                "Join Telegram to get updates first!",
                React.createElement("a", { className: styles.modalLink, href: "https://t.me/graame_marketplace" },
                    React.createElement(Icon, { type: "telegram", inline: true, size: 20 }),
                    ' ',
                    "https://t.me/graame_marketplace")),
            React.createElement("div", { className: styles.form },
                React.createElement("div", { className: styles.input },
                    React.createElement(Input, { id: "email", value: form.email.text, label: "Email", onChange: this.handleChangeEmail, errors: form.email.errors, fullWidth: true })),
                React.createElement("div", { className: styles.checkboxContainer },
                    React.createElement(Checkbox, { id: "accessStorage", label: "I agree to receive informational newsletters and commercial offers about Graame.", isChecked: form.storageAccess, onChange: this.handleOnStorageChange })),
                React.createElement("div", { className: styles.checkboxContainer },
                    React.createElement(Checkbox, { id: "accessNews", label: newsLabel, isChecked: form.newsAccess, onChange: this.handleOnNewsChange })),
                React.createElement("div", { className: styles.buttonContainer },
                    React.createElement(Button, { onClick: this.handleCB, big: true, wireframe: true, disabled: !checkValid() },
                        React.createElement("span", null, "Get your tokens"))))))));
    }
}
if (process.env.BROWSER) {
    const root = createRoot(document.getElementById('global-modal-root'));
    root.render(React.createElement(WindowModal, null));
}
