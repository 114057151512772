import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { addressToString, getNameText, sanitizeHTML } from '../../../utils';
import styles from './About.module.scss';
import t from './i18n';
class About extends PureComponent {
    render() {
        const { shop } = this.props;
        const name = getNameText(shop.name, 'EN');
        const longDescription = getNameText(shop.longDescription, 'EN');
        const address = addressToString(shop.addressFull);
        const html = sanitizeHTML(longDescription);
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.title },
                React.createElement("strong", null, t.aboutStore)),
            React.createElement("div", { className: styles.body },
                React.createElement("div", { className: styles.left },
                    name && (React.createElement("div", { className: styles.item },
                        React.createElement("div", { className: styles.subtitle }, t.fullShopName),
                        React.createElement("div", null, name))),
                    address && (React.createElement("div", { className: styles.item },
                        React.createElement("div", { className: styles.subtitle }, t.location),
                        React.createElement("div", null, address))),
                    longDescription && (React.createElement("div", { className: styles.item },
                        React.createElement("div", { className: styles.subtitle }, t.description),
                        React.createElement("div", { className: styles.description, dangerouslySetInnerHTML: { __html: html } })))))));
    }
}
export default createFragmentContainer(About, {
    shop: graphql `
			fragment About_shop on Store {
				id
				rawId
				name {
					lang
					text
				}
				addressFull {
					value
					country
					administrativeAreaLevel1
					administrativeAreaLevel2
					locality
					political
					postalCode
					route
					streetNumber
					placeId
				}
				longDescription {
					lang
					text
				}
			}
    `
});
