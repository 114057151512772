/**
 * @generated SignedSource<<8fd375e8531b8ccee8b8c7b664c4fbf8>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "StoreSubscription",
            "kind": "LinkedField",
            "name": "createStoreSubscription",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "storeId",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currency",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "walletAddress",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "trialStartDate",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "CreateStoreSubscriptionMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "CreateStoreSubscriptionMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "daaed7b29c03ea9632d65a932374730e",
            "id": null,
            "metadata": {},
            "name": "CreateStoreSubscriptionMutation",
            "operationKind": "mutation",
            "text": "mutation CreateStoreSubscriptionMutation(\n  $input: CreateStoreSubscriptionInput!\n) {\n  createStoreSubscription(input: $input) {\n    id\n    storeId\n    currency\n    value\n    walletAddress\n    trialStartDate\n    status\n  }\n}\n"
        }
    };
})();
node.hash = "4de719990d066f3e71a9ec6664725d84";
export default node;
