import React from 'react';
// import { Checkbox } from '../../../../../../components/common/Checkbox';
import { Icon } from '../../../../../../components/Icon';
import { Col } from '../../../../../../layout';
import styles from './StorageProductsTableHeader.module.scss';
import t from './i18n';
import { classNames } from '../../../../../../utils';
const StorageProductsTableHeader = () => (React.createElement("div", { className: styles.headerRowWrap },
    React.createElement(Col, { size: 1, sm: 2, md: 2, lg: 2, xl: 1, mdVisible: true }),
    React.createElement(Col, { size: 4, sm: 4, md: 4, lg: 3, xl: 2 },
        React.createElement("div", { className: classNames(styles, 'colColor', 'name') },
            React.createElement("span", null, t.name),
            React.createElement(Icon, { inline: true, type: "sortArrows" }))),
    React.createElement(Col, { size: 2, sm: 2, md: 2, lg: 2, xl: 2, xlVisible: true },
        React.createElement("div", { className: styles.colColor },
            React.createElement("span", null, t.category),
            React.createElement(Icon, { inline: true, type: "sortArrows" }))),
    React.createElement(Col, { size: 3, sm: 3, md: 3, lg: 3, xl: 2, mdVisible: true },
        React.createElement("div", { className: styles.colColor },
            React.createElement("span", null, t.price),
            React.createElement(Icon, { inline: true, type: "sortArrows" }))),
    React.createElement(Col, { size: 2, sm: 2, md: 2, lg: 3, xl: 3, xlVisible: true },
        React.createElement("div", { className: styles.colColor },
            React.createElement("span", null, t.characteristics),
            React.createElement(Icon, { inline: true, type: "sortArrows" }))),
    React.createElement(Col, { size: 3, sm: 3, md: 3, lg: 3, xl: 3, lgVisible: true },
        React.createElement("div", { className: styles.colColor },
            React.createElement("span", null, t.quantity),
            React.createElement(Icon, { inline: true, type: "sortArrows" })))));
export default StorageProductsTableHeader;
