import React, { Children, cloneElement, Component } from 'react';
import handlerSlide from '../handlerSlidesDecorator';
import styles from './SliderContainer.module.scss';
import { classNames } from '../../../utils';
import SliderArrows from '../SliderArrows';
import SliderHeader from '../SliderHeader';
class SliderContainer extends Component {
    constructor(props) {
        super(props);
        this.state = { current: 1 };
    }
    handleClick = (direction) => {
        const { handleSlide } = this.props;
        let { current } = this.state;
        const itemsLength = (this.props.children).length;
        handleSlide(direction);
        if (direction === 'prev') {
            current = current === 1 ? itemsLength : (current -= 1);
        }
        if (direction === 'next') {
            current = current === itemsLength ? 1 : (current += 1);
        }
        this.setState({ current });
    };
    render() {
        const { type, title, slidesOffset, visibleSlidesAmount, totalSlidesAmount, handleSlide, isTransition, handleDot, slidesToShow, num, animationSpeed, dots, seeAllUrl, fade, dotIdx, arrows, counter, counterNum } = this.props;
        const slideWidth = 100 / visibleSlidesAmount;
        const isRevealButton = visibleSlidesAmount < totalSlidesAmount;
        const animationSpeedSec = animationSpeed ? animationSpeed / 1000 : 0.5;
        const itemsLength = (this.props.children).length;
        return (React.createElement("div", { className: styles.container },
            arrows
                && itemsLength > 1 && React.createElement(SliderArrows, { onClick: this.handleClick }),
            counter && (React.createElement("div", { className: styles.counter },
                React.createElement("div", { className: styles.counterText }, `${counterNum}/${itemsLength}`))),
            type === 'products' && (React.createElement(SliderHeader, { title: title, isRevealButton: isRevealButton, handleSlide: handleSlide, seeAllUrl: seeAllUrl })),
            React.createElement("div", { ref: this.props.originalComponentRef, className: styles.wrapper, style: {
                    left: slidesOffset || '',
                    transition: isTransition && !fade
                        ? `left ${animationSpeedSec}s ease-out`
                        : '',
                    animationDelay: fade ? '' : '.2s'
                } }, Children.map(this.props.children, (child, idx) => (React.createElement("div", { className: classNames(styles, 'item', {
                    fadeItem: fade,
                    activeSlide: dotIdx !== undefined && `${dotIdx}` === `${child.key}`,
                    image: type === 'image'
                }), style: { flex: `0 0 ${slideWidth}%` }, key: `slider_${idx}_key` }, cloneElement(child))))),
            dots && slidesToShow === 1 && (React.createElement("div", { className: styles.dots }, Children.map(this.props.children, (child, idx) => (React.createElement("div", { className: classNames(styles, 'dot', { active: idx === num }), onClick: () => {
                    handleDot(idx);
                }, onKeyDown: () => {
                }, role: "button", tabIndex: 0, key: `slider_dot_${idx}_key` })))))));
    }
}
export default handlerSlide(SliderContainer);
