import { pathOr } from 'ramda';
import Cookies from 'universal-cookie';
import { FetcherBase } from '../relay/FetcherBase';
import { COOKIE_CURRENCY } from '../constants';
import isTokenExpired from '../utils/token';
import { jwtCookieName, removeCookie } from '../utils';
/* eslint class-methods-use-this: 0 */
class ClientFetcher extends FetcherBase {
    payloads;
    constructor(url, payloads) {
        super(url);
        console.log(`client graphql uri-> ${url}`);
        this.url = url;
        this.payloads = payloads;
        console.log(`client payloads-> ${payloads}`);
    }
    getJWTFromCookies() {
        const cookies = new Cookies();
        const jwt = pathOr(null, ['value'])(cookies.get(jwtCookieName));
        if (isTokenExpired(jwt)) {
            removeCookie(jwtCookieName, cookies);
        }
        return pathOr(null, ['value'])(cookies.get(jwtCookieName));
    }
    getSessionIdFromCookies() {
        const cookies = new Cookies();
        return cookies.get('SESSION_ID');
    }
    getCurrencyCodeFromCookies() {
        const cookies = new Cookies();
        const currency = cookies.get(COOKIE_CURRENCY);
        return currency || 'INR';
    }
    getCorrelationToken() {
        return undefined;
    }
    async fetch(request, variables) {
        if (this.payloads.length) {
            return this.payloads.shift();
        }
        return super.fetch(request, variables);
    }
}
export default ClientFetcher;
