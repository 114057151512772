import { commitMutation, graphql } from 'react-relay';
const mutation = graphql `
	mutation CreateStoreMutation($input: CreateStoreInput!) {
		createStore(input: $input) {
			id
			rawId
			name {
				lang
				text
			}
			shortDescription {
				lang
				text
			}
			longDescription {
				lang
				text
			}
			slug
			defaultLanguage
			baseProducts(first: 100) @connection(key: "Wizard_baseProducts") {
				edges {
					node {
						id
					}
				}
			}
		}
	}
`;
const commit = (params) => commitMutation(params.environment, {
    mutation,
    variables: params.variables,
    onCompleted: params.onCompleted,
    onError: params.onError,
    updater: (relayStore) => {
        const store = relayStore.getRootField('createStore');
        const me = relayStore.getRoot()
            .getLinkedRecord('me');
        me.setLinkedRecord(store, 'myStore');
    }
});
export default { commit };
