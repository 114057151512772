import { t } from '../../../../../translation/utils';
const translations = {
    en: {
        addNewProduct: 'Add new product',
        chooseWhatYouGonnaSale: 'Choose what you gonna sale in your marketplace and add it with ease',
        labelProductName: 'Product Name',
        labelShortDescription: 'Short Description',
        productMainPhoto: 'Product Main Photo',
        labelAddPhoto: 'Add Photo',
        productPhotoGallery: 'Product photo gallery',
        forBetterProductAppeareance: '* For better product appearance follow recomendations below and upload appropriate photos:',
        iconAddMainPhoto: 'Add main photo',
        iconAddAngleView: 'Add angle view',
        iconShowDetails: 'Show Details',
        iconShowInScene: 'Show in scene',
        iconShowInUse: 'Show in use',
        iconShowSizes: 'Show sizes',
        iconShowVariety: 'Show variety',
        characteristics: 'Characteristics',
        generalSettingsAndPricing: 'General settings and pricing',
        price: 'Price',
        SKU: 'SKU',
        labelCashback: 'Cash Back',
        labelQuantity: 'In stock',
        save: 'Save',
        cancel: 'Cancel',
        productPhotos: 'PRODUCT PHOTOS'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
