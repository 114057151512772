import React, { Component, createRef } from 'react';
import { assocPath, isEmpty, isNil, map, pathOr } from 'ramda';
import { withRouter } from 'found';
import { classNames, getCookie, inputToUrl, urlToInput } from '../../../utils';
import { Container } from '../../../layout';
import { MobileListItems } from '../../MobileListItems';
import { MobileMenu } from '../../MobileMenu';
import { Modal } from '../../Modal';
import { SearchInput } from '../../SearchInput';
import { CategoriesMenu } from '../../CategoriesMenu';
import { withShowAlert } from '../../Alerts/AlertContext';
import { Icon } from '../../Icon';
import { Authorization } from '../../Authorization';
import t from './i18n';
import styles from './Header.module.scss';
import { COOKIE_NAME } from '../../../constants';
import AppContext from '../AppContext';
import HeaderTop from '../HeaderTop';
import HeaderBottom from '../HeaderBottom';
import MobileSearchMenu from '../MobileSearchMenu';
const searchCategories = [
    {
        id: 'products',
        label: t.searchCategory_products
    },
    {
        id: 'stores',
        label: t.searchCategory_shops
    }
];
class Header extends Component {
    constructor(props) {
        super(props);
        const pathname = pathOr('', ['location', 'pathname'], this.props.match);
        const value = pathname.replace('/', '');
        this.state = {
            showModal: false,
            isSignUp: false,
            isMenuToggled: false,
            isMobileSearchOpen: false,
            isMobileCategoriesOpen: false,
            selectedCategory: value === 'stores' ? searchCategories[1] : searchCategories[0],
            searchItems: [],
            searchValue: props.searchValue
        };
        this.searchInputsRef = createRef();
    }
    componentDidMount() {
        if (process.env.BROWSER) {
            document.addEventListener('click', this.handleClick);
        }
        const { showAlert } = this.props;
        const cookie = getCookie(COOKIE_NAME);
        if (isNil(cookie)) {
            showAlert({
                type: 'success',
                text: t.cookiePolicy,
                link: { text: 'OK' }
                // isStatic: true,
                // longText: true
            });
        }
    }
    componentDidUpdate(prevProps) {
        const searchValue = pathOr('', ['match', 'location', 'query', 'search'], prevProps);
        const newSearchValue = pathOr('', ['match', 'location', 'query', 'search'], this.props);
        if (newSearchValue !== searchValue) {
            this.updateSearchValue(newSearchValue);
        }
    }
    componentWillUnmount() {
        if (process.env.BROWSER) {
            document.removeEventListener('click', this.handleClick);
        }
    }
    updateSearchValue = (searchValue) => {
        this.setState({ searchValue });
    };
    searchInputsRef = undefined;
    handleClick = (e) => {
        if (this.searchInputsRef && this.searchInputsRef.current && !this.searchInputsRef.current.contains(e.target)) {
            this.setState({ searchItems: [] });
        }
    };
    handleOpenModal = (isSignUp) => {
        this.setState({
            showModal: true,
            isSignUp
        });
    };
    handleCloseModal = () => {
        this.setState({ showModal: false });
    };
    closeMobileCategories = () => {
        this.setState((isMobileCategoriesOpen) => ({ isMobileCategoriesOpen: !isMobileCategoriesOpen }));
    };
    handleMobileMenu = () => {
        this.setState(({ isMenuToggled }) => ({ isMenuToggled: !isMenuToggled }));
    };
    handleMobileSearch = () => {
        this.setState(({ isMobileSearchOpen }) => ({
            isMobileSearchOpen: !isMobileSearchOpen,
            isMobileCategoriesOpen: false
        }));
    };
    handleDropDown = () => {
        this.closeMobileCategories();
    };
    handleMobileCategories = (selectedCategory) => {
        this.setState({
            selectedCategory,
            searchItems: []
        }, () => {
            this.closeMobileCategories();
        });
    };
    makeCategories = (directories) => pathOr([], ['categories', 'children'], directories);
    handleGetSearchItems = (searchItems) => {
        this.setState({ searchItems });
    };
    handleClickSearchItem = (searchItem) => {
        const { selectedCategory } = this.state;
        const pathname = pathOr('', ['match', 'location', 'pathname'], this.props)
            .replace('/', '');
        const queryObj = pathOr('', ['match', 'location', 'query'], this.props);
        const oldPreparedObj = urlToInput(queryObj);
        const newPreparedObj = assocPath(['name'], searchItem.label, oldPreparedObj);
        const newUrl = inputToUrl(newPreparedObj);
        this.setState({
            searchItems: [],
            searchValue: searchItem.label
        });
        switch (selectedCategory && selectedCategory.id) {
            case 'stores':
                if (pathname === 'stores') {
                    this.props.router.push(`/stores${newUrl}`);
                }
                else {
                    this.props.router.push(searchItem.label
                        ? `/stores?search=${searchItem.label}`
                        : '/stores?search=');
                }
                break;
            case 'products':
                if (pathname === 'categories') {
                    this.props.router.push(`/categories${newUrl}`);
                }
                else {
                    this.props.router.push(searchItem.label
                        ? `/categories?search=${searchItem.label}`
                        : '/categories?search=');
                }
                break;
            default:
                break;
        }
    };
    render() {
        const { withoutCategories, userData, totalCount, isShopCreated, setLang } = this.props;
        const { showModal, isSignUp, selectedCategory, isMenuToggled, isMobileSearchOpen, isMobileCategoriesOpen, searchItems, searchValue } = this.state;
        const BurgerMenu = () => (React.createElement("div", { onClick: this.handleMobileMenu, onKeyPress: () => {
            }, role: "button", className: styles.burgerMenu, tabIndex: -1 },
            React.createElement("span", { role: "img" },
                React.createElement(Icon, { type: "burgerMenu", size: 24 }))));
        return (React.createElement(AppContext.Consumer, null, ({ directories, environment, handleLogin }) => (React.createElement("header", { className: classNames(styles, 'container', {
                expanded: isMobileCategoriesOpen,
                withoutCategories
            }) },
            React.createElement(Container, null,
                React.createElement(BurgerMenu, null),
                React.createElement(HeaderTop, { userData: userData, currencies: directories.currencies, isShopCreated: isShopCreated, setLang: setLang }),
                React.createElement(HeaderBottom, { userData: userData, searchCategories: searchCategories, searchValue: searchValue, totalCount: totalCount, onMobileSearch: this.handleMobileSearch, onOpenModal: this.handleOpenModal }),
                this.makeCategories(directories) && !withoutCategories && (React.createElement(CategoriesMenu, { categories: this.makeCategories(directories) }))),
            React.createElement(MobileSearchMenu, { isOpen: isMobileSearchOpen, searchCategories: searchCategories, searchValue: searchValue, onClick: this.handleMobileSearch },
                React.createElement(SearchInput, { selectedCategory: selectedCategory, onDropDown: this.handleDropDown, searchCategories: searchCategories, searchValue: searchValue, getSearchItems: this.handleGetSearchItems })),
            React.createElement(MobileMenu, { isOpen: isMenuToggled, onClose: this.handleMobileMenu }),
            React.createElement(Modal, { showModal: showModal, onClose: this.handleCloseModal, render: () => (React.createElement(Authorization, { environment: environment, handleLogin: handleLogin, isSignUp: isSignUp, onCloseModal: this.handleCloseModal })) }),
            isMobileCategoriesOpen ? (React.createElement(MobileListItems, { onClick: this.handleMobileCategories, items: searchCategories })) : undefined,
            !isEmpty(searchItems)
                && !isMobileCategoriesOpen && (React.createElement("div", { ref: this.searchInputsRef, className: styles.searchItems }, map((item) => (React.createElement("div", { key: item.id, className: styles.searchItem, onClick: () => {
                    this.handleClickSearchItem(item);
                }, onKeyDown: () => {
                }, role: "button", tabIndex: 0 }, item.label)), searchItems)))))));
    }
}
export default withRouter(withShowAlert(Header));
