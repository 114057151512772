import React, { PureComponent } from 'react';
import { Link } from 'found';
import { Col, Container, Row } from '../../../layout';
import { convertSrc, formatPrice, getNameText } from '../../../utils';
import { withShowAlert } from '../../../components/Alerts/AlertContext';
import { Icon } from '../../../components/Icon';
import { Button, Input, Rating } from '../../../components/common';
import styles from './CartStore.module.scss';
import CartProduct from './CartProduct';
class CartStore extends PureComponent {
    render() {
        const { product, productName, store, buyNowData, onChangeCount, couponCodeValue, couponCodeButtonDisabled, isLoadingCouponButton, handleChangeCoupon, handleSetCoupon, onDeleteProduct, baseProductId, country, isShippingAvailable, onChangeDelivery, deliveryPackage, onPackagesFetched, currency } = this.props;
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", null,
                React.createElement(CartProduct, { baseProductId: baseProductId, product: product, productName: productName, buyNowData: buyNowData, onChangeCount: onChangeCount, onDeleteProduct: onDeleteProduct, country: country, isShippingAvailable: isShippingAvailable, onChangeDelivery: onChangeDelivery, deliveryPackage: deliveryPackage, onPackagesFetched: onPackagesFetched, currency: currency })),
            React.createElement("div", { className: styles.footer },
                React.createElement(Container, { correct: true },
                    React.createElement(Row, { alignItems: "center" },
                        React.createElement(Col, { size: 12, sm: 3 },
                            React.createElement("div", { className: styles.storeInfo },
                                React.createElement(Link, { to: `/store/${store.rawId}` },
                                    store.logo !== '' && store.logo !== null ? (React.createElement("img", { src: convertSrc('small', store.logo), alt: "store_picture", className: styles.image })) : (React.createElement("div", { className: styles.noLogo },
                                        React.createElement(Icon, { type: "camera", size: 28 }))),
                                    React.createElement("div", { className: styles.storeDescription },
                                        React.createElement("div", { className: styles.storeName }, getNameText(store.name, 'EN')),
                                        React.createElement(Rating, { value: store.rating }))))),
                        React.createElement(Col, { size: 12, sm: 6 },
                            React.createElement("div", { className: styles.coupon },
                                React.createElement("div", { className: styles.couponIcon },
                                    React.createElement(Icon, { type: "coupon", size: 28 })),
                                React.createElement("div", { className: styles.couponInput },
                                    React.createElement(Input, { id: "couponInput", inline: true, fullWidth: true, value: couponCodeValue, onChange: handleChangeCoupon })),
                                React.createElement("div", { className: styles.couponButton },
                                    React.createElement(Button, { small: true, disabled: couponCodeButtonDisabled, onClick: handleSetCoupon, isLoading: isLoadingCouponButton, dataTest: "couponButton" }, "Apply code")))),
                        React.createElement(Col, { size: 12, sm: 3 },
                            React.createElement("div", { className: styles.storeTotal },
                                React.createElement("div", { className: styles.storeTotalWrapper },
                                    React.createElement("div", { className: styles.label }, "Subtotal"),
                                    Boolean(buyNowData.couponsDiscounts) && (React.createElement("div", { className: styles.value },
                                        React.createElement("small", { className: styles.through }, `${formatPrice(buyNowData.totalCostWithoutDiscounts || 0, 2)} ${currency || ''}`))),
                                    React.createElement("div", { className: styles.value }, `${formatPrice(buyNowData.totalCost || 0, 2)} ${currency || ''}`)))))))));
    }
}
export default withShowAlert(CartStore);
