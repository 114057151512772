import { commitMutation, graphql } from 'react-relay';
const mutation = graphql `
	mutation CreateIndiaBillingInfoMutation($input: NewIndiaBillingInfoInput!) {
		createIndiaBillingInfo(input: $input) {
			id
			rawId
			storeId
			bankName
			branchName
			swiftBic
			taxId
			correspondentAccount
			currentAccount
			personalAccount
			beneficiaryFullName
		}
	}
`;
const commit = (params) => commitMutation(params.environment, {
    mutation,
    variables: params.variables,
    onCompleted: params.onCompleted,
    onError: params.onError,
    updater: (relayStore) => {
        const createIndiaBillingInfo = relayStore.getRootField('createIndiaBillingInfo');
        const me = relayStore.getRoot()
            .getLinkedRecord('me');
        const myStore = me.getLinkedRecord('myStore');
        const internationalBillingInfo = myStore.getLinkedRecord('internationalBillingInfo');
        if (internationalBillingInfo) {
            const internationalBillingInfoId = internationalBillingInfo.getValue('id');
            relayStore.delete(internationalBillingInfoId);
        }
        myStore.setLinkedRecord(createIndiaBillingInfo, 'indiaBillingInfo');
    }
});
export default { commit };
