import React, { Component } from 'react';
import { Link, withRouter } from 'found';
import { v4 as uuidv4 } from 'uuid';
import { classNames, convertSrc, debug, error, fromRelayError, uploadFile } from '../../../utils';
import { UpdateUserMutation } from '../../../relay/mutations';
import { Collapse } from '../../../components/Collapse';
import { Icon } from '../../../components/Icon';
import { MobileUpload } from '../../../components/MobileUpload';
import { UploadWrapper } from '../../../components/Upload';
import { withShowAlert } from '../../../components/Alerts/AlertContext';
import styles from './Menu.module.scss';
import t from './i18n';
class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = { isMainPhotoUploading: false };
    }
    handleOnUpload = (e) => {
        e.preventDefault();
        this.setState({ isMainPhotoUploading: true });
        uploadFile(e.target.files[0])
            .then((result) => {
            if (!result || result.url == null) {
                error(result);
                alert('Error :(');
            }
            this.handleUpdateUser(result.url || '');
            return true;
        })
            .finally(() => {
            this.setState({ isMainPhotoUploading: false });
        })
            .catch((err) => {
            this.props.showAlert({
                type: 'danger',
                text: err.message,
                link: { text: 'Close.' }
            });
        });
    };
    handleUpdateUser = (avatar) => {
        const { environment } = this.props;
        const params = {
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    avatar,
                    id: this.props.id,
                    phone: null,
                    firstName: null,
                    lastName: null,
                    birthdate: null,
                    gender: null
                }
            },
            environment,
            onCompleted: (response, errors) => {
                debug({
                    response,
                    errors
                });
                const relayErrors = fromRelayError({ source: { errors } });
                debug({ relayErrors });
            },
            onError: (err) => {
                debug({ err });
                const relayErrors = fromRelayError(err);
                debug({ relayErrors });
                alert(t.somethingWentWrong);
            }
        };
        UpdateUserMutation.commit(params);
    };
    handleSelected = (item) => {
        const { router: { push } } = this.props;
        push(`/profile/${item.id}`);
    };
    render() {
        const { activeItem, avatar, firstName, lastName, menuItems, provider } = this.props;
        return (React.createElement("aside", { className: styles.container },
            React.createElement("h3", { className: styles.offscreen }, t.offscreenProfileMenu),
            React.createElement("div", { className: styles.mobileMenu },
                React.createElement(Collapse, { selected: activeItem, items: menuItems, onSelected: this.handleSelected }),
                React.createElement("div", { style: { margin: '1rem 0' } }),
                activeItem === 'personal-data' ? (React.createElement(React.Fragment, null,
                    React.createElement(MobileUpload, { img: convertSrc('small', avatar), iconType: "user", id: this.props.id, onDelete: () => this.handleUpdateUser(''), onUpload: this.handleOnUpload }),
                    React.createElement("div", { style: { margin: '1rem 0' } }))) : null),
            React.createElement("div", { className: styles.imageArea },
                React.createElement(UploadWrapper, { id: "new-store-id", onUpload: this.handleOnUpload, customUnit: true, square: true, buttonWidth: "100%", buttonIconType: "user", buttonIconSize: 48, buttonLabel: t.clickToUploadAvatar, overPicture: convertSrc('medium', avatar), dataTest: "storeImgUploader", buttonHeight: "100%", loading: this.state.isMainPhotoUploading }),
                avatar && (React.createElement("div", { className: styles.trash, onClick: () => {
                        this.handleUpdateUser('');
                    }, onKeyDown: () => {
                    }, role: "button", tabIndex: 0 },
                    React.createElement(Icon, { type: "basket", size: 28 })))),
            React.createElement("div", { className: styles.title }, `${firstName} ${lastName}`),
            React.createElement("div", { className: styles.items }, menuItems.map((item) => {
                const isActive = item.id === activeItem;
                if ((item.id === 'security' && provider !== 'EMAIL') || item.id === 'kyc') {
                    return null;
                }
                return (React.createElement(Link, { key: item.id, to: `/profile/${item.id}`, className: classNames(styles, 'item', { isActive }), "data-test": item.title }, item.title));
            }))));
    }
}
export default withRouter(withShowAlert(Menu));
