import { t } from '../../../../translation/utils';
const translations = {
    en: {
        nameYourStore: 'Name your store',
        setUpStore: 'Set up store',
        fillYourStoreWithGoods: 'fill your store with goods',
        paymentSettings: 'Payment settings'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
