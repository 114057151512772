import React, { PureComponent } from 'react';
import { fromPairs, isNil, map, pathOr, pipe, prop, replace, split } from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { Environment } from 'relay-runtime';
import { debug, error, errorsHandler, fromRelayError, removeCookie, setCookie, setJWT } from '../../../utils';
import { withShowAlert } from '../../Alerts/AlertContext';
import Logo from '../../Icon/svg/logo.svg';
import { Spinner } from '../../common/Spinner';
import prepareQueryString from './OAuthCallback.utils';
import { clearPathForRedirectAfterLogin, getAdditionalData, getPathForRedirectAfterLogin } from '../utils';
import styles from './OAuthCallback.module.scss';
import t from './i18n';
import GetJwtbyProviderMutation from './mutations/GetJwtbyProviderMutation';
// Component that handles code from oauth-providers and fetches jwt token.
class OAuthCallback extends PureComponent {
    static contextTypes = { environment: PropTypes.instanceOf(Environment).isRequired };
    componentDidMount() {
        let accessToken;
        switch (this.props.provider) {
            case 'FACEBOOK':
                accessToken = this.extractFacebookAccessToken(window.location.href);
                break;
            case 'GOOGLE':
                accessToken = this.extractGoogleAccessToken(window.location.href);
                break;
            default:
                break;
        }
        debug({ accessToken });
        if (accessToken) {
            GetJwtbyProviderMutation({
                environment: this.context.environment,
                variables: {
                    input: {
                        provider: this.props.provider,
                        token: accessToken,
                        clientMutationId: uuidv4(),
                        additionalData: getAdditionalData()
                    }
                }
            })
                .then((response) => {
                removeCookie('REFERRAL');
                removeCookie('REFERRER');
                removeCookie('UTM_MARKS');
                setCookie('registered', true);
                debug({ response });
                const jwtStr = pathOr(null, ['getJwtbyProvider', 'token'], response);
                if (jwtStr) {
                    setJWT(jwtStr);
                    const redirectPath = getPathForRedirectAfterLogin();
                    if (!isNil(redirectPath)) {
                        clearPathForRedirectAfterLogin();
                        this.props.router.push(decodeURIComponent(redirectPath));
                    }
                    else {
                        window.location.href = '/'; // TODO: use refetch or store update
                    }
                }
                // throw new Error('no JWT');
            })
                .catch((errs) => {
                error(errs);
                const relayErrors = fromRelayError({ source: { errors: [errs] } });
                if (relayErrors) {
                    errorsHandler(relayErrors, this.props.showAlert);
                }
                this.props.router.replace('/login');
            });
        }
        else {
            window.location.href = '/login';
        }
    }
    extractFacebookAccessToken = (url) => {
        // <callback_url from .env>?#access_token=<token_here>&expires_in=6232
        const fbCallbackUri = process.env.REACT_APP_OAUTH_FACEBOOK_REDIRECT_URI;
        const queryString = prepareQueryString({
            url,
            callbackUrl: fbCallbackUri
        });
        return this.extractToken(queryString);
    };
    extractGoogleAccessToken = (url) => {
        // <callback_url from .env>#access_token=<token_here>&token_type=Bearer&expires_in=3600
        const googleCallbackUri = process.env.REACT_APP_OAUTH_GOOGLE_REDIRECT_URI;
        const queryString = replace(`${googleCallbackUri}#`, '', url);
        return this.extractToken(queryString);
    };
    // pass query string here
    extractToken = pipe(split('&'), // [access_token=<token_here>, expires_in=6232]
    map(split('=')), // [[access_token, <token_here>], [expires_in, 6232]]
    fromPairs, // {access_token: <token_here>, expires_in: 6232}
    prop('access_token') // <token_here>
    );
    render() {
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.logo },
                React.createElement(Logo, null)),
            React.createElement("span", { className: styles.text },
                t.loading,
                React.createElement("br", null),
                t.pleaseWait),
            React.createElement("span", { className: styles.description },
                "- ",
                t.storiqaTeam),
            React.createElement(Spinner, null)));
    }
}
export default withShowAlert(OAuthCallback);
