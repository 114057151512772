import { commitMutation, graphql } from 'react-relay';
const mutation = graphql `
	mutation ChargeFeeMutation($input: ChargeFeeInput!) {
		chargeFee(input: $input) {
			id
			orderId
			amount
			status
			currency
			chargeId
		}
	}
`;
const commit = (params) => commitMutation(params.environment, {
    mutation,
    variables: params.variables,
    onCompleted: params.onCompleted,
    onError: params.onError
});
export default { commit };
