import { addIndex, find, map, propEq, replace } from 'ramda';
export const getIndexedCountries = (countries) => {
    const mapIndexed = addIndex(map);
    return mapIndexed((country) => ({
        id: country.alpha3,
        label: country.label
    }), countries);
};
export const getCountryByName = (label, countries) => find(propEq(label, 'label'))(countries);
export const getScriptURL = () => {
    // workaround for storybook
    if (!process.env.REACT_APP_GOOGLE_PLACES_API_URL) {
        return 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBnT1Xbrc6sIQgr-Ar8_b2VteHRa6P_rFU&libraries=places';
    }
    const urlTemplate = process.env.REACT_APP_GOOGLE_PLACES_API_URL || '';
    const keyPlaceholder = process.env.REACT_APP_GOOGLE_PLACES_API_KEY_PLACEHOLDER || '';
    const apiKey = process.env.REACT_APP_GOOGLE_PLACES_API_KEY || '';
    return replace(keyPlaceholder, apiKey, urlTemplate);
};
