import React from 'react';
import { Spinner } from '../../components/common/Spinner';
import Logo from '../../components/Icon/svg/logo.svg';
import styles from './SpinnerPage.module.scss';
import t from './i18n';
const SpinnerPage = () => (React.createElement("div", { className: styles.container },
    React.createElement("div", { className: styles.logo },
        React.createElement(Logo, null)),
    React.createElement("span", { className: styles.text },
        t.loading,
        React.createElement("br", null),
        t.pleaseWait),
    React.createElement("span", { className: styles.description },
        "- ",
        t.storiqaTeam),
    React.createElement(Spinner, null)));
export default SpinnerPage;
