import React, { Component } from 'react';
import { isEmpty, isNil } from 'ramda';
import { Icon } from '../../Icon';
import styles from './Input.module.scss';
import { classNames } from '../../../utils';
class Input extends Component {
    static defaultProps = {
        id: 'stq-input',
        label: '',
        icon: '',
        isAutocomplete: false,
        search: false,
        isUrl: false,
        postfix: '',
        dataTest: '',
        inline: false,
        onChange: () => {
        },
        onBlur: () => {
        },
        onFocus: () => {
        },
        onKeyDown: () => {
        },
        onClick: () => {
        },
        inputRef: () => {
        }
    };
    static getDerivedStateFromProps(nextProps, prevState) {
        const value = nextProps.value == null ? '' : `${nextProps.value}`;
        if (Boolean(value) !== prevState.labelFloat) {
            return {
                ...prevState,
                labelFloat: prevState.isFocus ? true : Boolean(value)
            };
        }
        return null;
    }
    constructor(props) {
        super(props);
        this.state = {
            labelFloat: false,
            isFocus: false
        };
    }
    input;
    handleChange = (e) => {
        const { onChange, limit } = this.props;
        const { value } = e.target;
        if (limit != null && value.length > limit) {
            return;
        }
        onChange(e);
    };
    handleFocus = (e) => {
        const { onFocus } = this.props;
        const { labelFloat } = this.state;
        this.setState({
            labelFloat: !labelFloat || true,
            isFocus: true
        });
        if (onFocus) {
            onFocus(e);
        }
    };
    handleBlur = (e) => {
        const { value, onBlur } = this.props;
        this.setState({
            labelFloat: Boolean(value) && value.length > 0,
            isFocus: false
        });
        if (onBlur) {
            onBlur(e);
        }
    };
    renderInput() {
        const { inputRef, isAutocomplete, id, value, type, dataTest, align, disabled } = this.props;
        return isAutocomplete ? (React.createElement("input", { type: "text", ref: inputRef, value: value || '', disabled: disabled || false, onChange: this.handleChange, onFocus: this.handleFocus, onBlur: this.handleBlur, onKeyDown: this.props.onKeyDown, onClick: this.props.onClick, "data-test": !isEmpty(dataTest) ? dataTest : id, style: { textAlign: align || 'left' } })) : (React.createElement("input", { id: id, ref: inputRef, name: id, type: !isNil(type) ? type : 'text', value: value || '', disabled: disabled || false, onChange: this.handleChange, onFocus: this.handleFocus, onBlur: this.handleBlur, onKeyDown: this.props.onKeyDown, "data-test": !isEmpty(dataTest) ? dataTest : id, style: { textAlign: align || 'left' } }));
    }
    render() {
        const { id, value, label, errors, icon, isUrl, limit, fullWidth, postfix, inline, search, limitHidden } = this.props;
        const { labelFloat, isFocus } = this.state;
        return (React.createElement("label", { htmlFor: id, className: classNames(styles, 'container', {
                isFocus,
                isError: errors,
                isIcon: icon || search,
                isSearch: search,
                fullWidth,
                inline
            }) },
            !isEmpty(label) && (React.createElement("span", { className: classNames(styles, 'label', { labelFloat: labelFloat || value }) }, label)),
            (icon || search) && (React.createElement("div", { className: styles.icon },
                React.createElement(Icon, { type: search ? 'magnifier' : icon }))),
            React.createElement("div", { className: styles.input },
                React.createElement("div", { className: styles.inputContent },
                    this.renderInput(),
                    !isEmpty(postfix) && React.createElement("span", { className: styles.postfix }, postfix)),
                React.createElement("hr", null)),
            errors
                && errors.length > 0 && (React.createElement("div", { className: styles.errors }, errors.map((item, idx) => (React.createElement("div", { key: idx, id: `error-label-${id}` }, item))))),
            isFocus
                && !isUrl
                && !isNil(limit)
                && limitHidden !== true && (React.createElement("div", { className: classNames(styles, 'valueLength', { maxValueLength: value && value.length === limit }) },
                value ? value.length : 0,
                ' ',
                "/",
                limit))));
    }
}
export default Input;
