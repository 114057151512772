import React, { Component } from 'react';
import Autocomplete from 'react-autocomplete-2';
import debounce from 'lodash.debounce';
import { assocPath, findIndex, isEmpty, length, map, omit, pathOr, propEq, take } from 'ramda';
import { withRouter } from 'found';
import { Select } from '../common/Select';
import { classNames, inputToUrl, urlToInput } from '../../utils';
import { ContextDecorator } from '../App';
import fetchAutoCompleteProductName from './fetchAutoCompleteProductName';
import fetchAutoCompleteStoreName from './fetchAutoCompleteStoreName';
import styles from './SearchInput.module.scss';
import { Icon } from '../Icon';
import t from './i18n';
const maxSearchAmount = 5;
class SearchInput extends Component {
    static getDerivedStateFromProps(nextProps, prevState) {
        const { selectedCategory } = nextProps;
        const { activeItem } = prevState;
        if (selectedCategory && selectedCategory.id !== activeItem.id) {
            return {
                activeItem,
                items: []
            };
        }
        return null;
    }
    constructor(props) {
        super(props);
        if (process.env.BROWSER) {
            document.addEventListener('keydown', this.handleKeydown);
        }
        const { searchValue, searchCategories } = props;
        const pathname = pathOr('', ['location', 'pathname'], this.props.match);
        const value = pathname.replace('/', '');
        this.state = {
            inputValue: searchValue || '',
            items: [],
            isFocus: false,
            activeItem: value === 'stores' ? searchCategories[1] : searchCategories[0],
            arrowItem: null
        };
        this.handleFetchAutoCompleteProductName = debounce(this.handleFetchAutoCompleteProductName, 1000);
    }
    componentDidUpdate(prevProps) {
        const { searchValue } = this.props;
        if (searchValue !== null && searchValue !== prevProps.searchValue) {
            this.updateSearchValue(searchValue || '');
        }
    }
    componentWillUnmount() {
        if (process.env.BROWSER) {
            document.removeEventListener('keydown', this.handleKeydown);
        }
    }
    getSearchItems = (items) => {
        const { getSearchItems } = this.props;
        if (getSearchItems) {
            getSearchItems(items);
        }
    };
    updateSearchValue = (searchValue) => {
        this.setState({ inputValue: searchValue });
    };
    handleFocus = () => {
        this.setState({ isFocus: true });
    };
    handleBlur = () => {
        this.setState({ isFocus: false });
    };
    handleFetchAutoCompleteProductName = (value) => {
        fetchAutoCompleteProductName(this.props.environment, { name: value })
            .toPromise()
            .then((data) => {
            const items = pathOr([], ['search', 'autoCompleteProductName', 'edges'], data);
            this.setState((prevState) => {
                if (!prevState.inputValue) {
                    return { items: [] };
                }
                return {
                    items: take(maxSearchAmount, map((item) => ({
                        id: item.node,
                        label: item.node
                    }), items))
                };
            }, () => {
                this.getSearchItems(this.state.items);
            });
            return true;
        })
            .catch(() => {
            this.setState({ items: [] });
        });
    };
    handleFetchAutoCompleteStoreName = (value) => {
        fetchAutoCompleteStoreName(this.props.environment, { name: value })
            .toPromise()
            .then((data) => {
            const items = pathOr([], ['search', 'autoCompleteStoreName', 'edges'], data);
            this.setState((prevState) => {
                if (!prevState.inputValue) {
                    return { items: [] };
                }
                return {
                    items: take(maxSearchAmount, map((item) => ({
                        id: item.node,
                        label: item.node
                    }), items))
                };
            }, () => {
                this.getSearchItems(this.state.items);
            });
            return true;
        })
            .catch(() => {
            this.setState({ items: [] });
        });
    };
    handlefetchAutocomplete = (value) => {
        if (!value) {
            this.setState({ items: [] }, () => {
                this.getSearchItems([]);
            });
            return;
        }
        const { activeItem } = this.state;
        switch (activeItem.id) {
            case 'stores':
                this.handleFetchAutoCompleteStoreName(value);
                break;
            case 'products':
                this.handleFetchAutoCompleteProductName(value);
                break;
            default:
                break;
        }
    };
    handleInputChange = (e) => {
        const { value } = e.target;
        this.setState({
            inputValue: value,
            arrowItem: null,
            isFocus: true
        }, () => {
            this.handlefetchAutocomplete(value);
        });
    };
    handleSearchDropDownSelect = (activeItem) => {
        this.setState({
            items: [],
            activeItem
        });
    };
    handleSearch = () => {
        const { selectedCategory } = this.props;
        const { inputValue, activeItem } = this.state;
        const pathname = pathOr('', ['match', 'location', 'pathname'], this.props)
            .replace('/', '');
        const queryObj = pathOr('', ['match', 'location', 'query'], this.props);
        const oldPreparedObj = urlToInput(omit(['maxValue', 'minValue'], queryObj));
        const newPreparedObj = assocPath(['name'], inputValue, oldPreparedObj);
        const newUrl = inputToUrl(newPreparedObj.options ? newPreparedObj : newPreparedObj);
        switch ((selectedCategory && selectedCategory.id) || (activeItem && activeItem.id)) {
            case 'stores':
                if (pathname === 'stores') {
                    this.props.router.push(`/stores${newUrl}`);
                }
                else {
                    this.props.router.push(inputValue ? `/stores?search=${inputValue}` : '/stores?search=');
                }
                break;
            case 'products':
                if (pathname === 'categories') {
                    this.props.router.push(`/categories${newUrl}`);
                }
                else {
                    this.props.router.push(inputValue
                        ? `/categories?search=${inputValue}`
                        : '/categories?search=');
                }
                break;
            default:
                break;
        }
    };
    handleKeydown = (e) => {
        const { isFocus, items, arrowItem } = this.state;
        if (e.key === 'Enter' && isFocus) {
            if (arrowItem) {
                this.handleOnSetValue(arrowItem.label);
                return;
            }
            this.handleSearch();
            return;
        }
        if (isFocus && items && !isEmpty(items)) {
            if (e.key === 'ArrowUp') {
                this.setState((prevState) => {
                    const arrowIdx = prevState.arrowItem
                        ? findIndex(propEq(prevState.arrowItem.id, 'id'))(items) - 1
                        : length(items) - 1;
                    return {
                        arrowItem: items[arrowIdx === -1 ? length(items) - 1 : arrowIdx]
                    };
                });
                return;
            }
            if (e.key === 'ArrowDown') {
                this.setState((prevState) => {
                    const arrowIdx = prevState.arrowItem
                        ? findIndex(propEq(prevState.arrowItem.id, 'id'))(items) + 1
                        : 0;
                    return {
                        arrowItem: items[arrowIdx === maxSearchAmount ? 0 : arrowIdx]
                    };
                });
            }
        }
    };
    handleOnSetValue = (value) => {
        this.setState({
            inputValue: value,
            items: [],
            arrowItem: null
        }, this.handleSearch);
    };
    render() {
        const { onDropDown, isMobile, selectedCategory } = this.props;
        const { items: searchItems, inputValue, arrowItem } = this.state;
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.searchCategorySelect },
                React.createElement(Select, { isMobile: isMobile, onClick: onDropDown, forAutocomlete: true, activeItem: selectedCategory || this.state.activeItem, items: this.props.searchCategories || [], onSelect: this.handleSearchDropDownSelect, dataTest: "searchInputSelect" })),
            React.createElement("div", { className: styles.searchInput },
                React.createElement(Autocomplete, { wrapperStyle: { position: 'relative' }, renderInput: (props) => (React.createElement("div", { className: styles.inputWrapper },
                        React.createElement("input", { ...props, className: styles.input, onFocus: () => {
                                props.onFocus();
                                this.handleFocus();
                            }, onBlur: () => {
                                props.onBlur();
                                this.handleBlur();
                            }, placeholder: t.iFind, "data-test": "searchInput" }))), items: searchItems, getItemValue: (item) => item.label, suggestionsMenuId: "search-suggestions", renderMenu: (items, suggestionsMenuId, value) => (React.createElement("div", { id: suggestionsMenuId, role: "listbox" }, !isEmpty(items) && (React.createElement("div", { className: classNames(styles, 'items', { isMobile }) }, items)))), renderItem: (item) => (React.createElement("div", { key: item.id, className: classNames(styles, 'searchMenuItem', { highlighted: arrowItem && arrowItem.id === item.id }) },
                        React.createElement("div", { className: styles.itemText, onClick: () => {
                                this.handleOnSetValue(item.label);
                            }, onKeyDown: () => {
                            }, role: "button", tabIndex: 0 }, item.label))), value: inputValue, onChange: this.handleInputChange })),
            React.createElement("button", { className: styles.searchButton, onClick: this.handleSearch, "data-test": "searchButton" },
                React.createElement(Icon, { inline: true, type: "magnifier", size: 16 }))));
    }
}
export default withRouter(ContextDecorator(SearchInput));
