import React, { Component } from 'react';
import { isNil, propOr } from 'ramda';
import { Button } from '../../common/Button';
import Input from '../Input';
import styles from '../Authorization.module.scss';
import t from './i18n';
class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = { autocomplete: false };
    }
    render() {
        const { password, passwordRepeat, errors, formValid, onClick, onChange, onPasswordRepeat } = this.props;
        const { autocomplete } = this.state;
        return (React.createElement("div", { className: styles.signIn },
            React.createElement("div", { className: styles.inputBlock },
                React.createElement(Input, { label: t.labelNewPassword, name: "password", type: "password", model: password, validate: "password", onChange: onChange, autocomplete: autocomplete, errors: !isNil(errors) ? propOr(null, 'password', errors) : [] })),
            React.createElement("div", { className: styles.inputBlock },
                React.createElement(Input, { label: t.labelRepeatPassword, name: "passwordRepeat", type: "password", model: passwordRepeat, validate: "password", onChange: onPasswordRepeat, autocomplete: autocomplete, errors: password === passwordRepeat ? null : [t.errorNotMatch] })),
            React.createElement("div", { className: styles.resetPasswordButtons },
                React.createElement(Button, { onClick: onClick, big: true, disabled: !formValid, fullWidth: true }, t.submitNewPassword))));
    }
}
export default ResetPassword;
