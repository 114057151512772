import React, { PureComponent } from 'react';
import { find, map, propOr, whereEq } from 'ramda';
import { DeliveryCompaniesSelect } from '../../Checkout/CheckoutContent/DeliveryCompaniesSelect';
import { Col, Container, Row } from '../../../layout';
import { convertCountries, formatPrice, getNameText } from '../../../utils';
import Stepper from '../../../components/Stepper';
import { AppContext } from '../../../components/App';
import CartProductAttribute from './CartProductAttribute';
import styles from './ProductInfo.module.scss';
class ProductInfo extends PureComponent {
    static defaultProps = { withDeliveryCompaniesSelect: false };
    render() {
        const { product, buyNowData, onChangeCount, country, isShippingAvailable, baseProductId, deliveryPackage, onChangeDelivery, onPackagesFetched, currency } = this.props;
        const attrs = map((attr) => ({
            title: getNameText(attr.attribute.name, 'EN'),
            value: attr.value.toString()
        }), product.attributes);
        return (React.createElement(Container, { correct: true },
            React.createElement(Row, null,
                React.createElement(Col, { size: 12, xl: 8 },
                    React.createElement(Row, null,
                        React.createElement(Col, { size: 6, xl: 12 },
                            React.createElement("div", { className: styles.contentBlock },
                                React.createElement("div", { className: styles.productSummaryAttributes },
                                    product.preOrder
                                        && product.preOrderDays && (React.createElement("div", { className: styles.preOrder },
                                        React.createElement("div", { className: styles.preOrderText },
                                            React.createElement("div", null, "Available for pre-order."),
                                            React.createElement("div", null,
                                                "Lead time (days):",
                                                ' ',
                                                React.createElement("span", { className: styles.preOrderDays }, product.preOrderDays))))),
                                    (attrs.length > 0 && (React.createElement(Row, null, attrs.map((attr) => (React.createElement(Col, { key: `attr-${attr.value}`, size: 12, xl: 6 },
                                        React.createElement(CartProductAttribute, { ...attr }))))))) || React.createElement("div", { className: styles.empty })))),
                        React.createElement(Col, { size: 6, xlHidden: true },
                            React.createElement("div", { className: styles.contentBlock },
                                React.createElement("div", { className: styles.cartProductTitle }, "Price"),
                                React.createElement(CartProductAttribute, { title: "Count", value: (React.createElement(Stepper, { value: buyNowData.totalCount, min: 0, max: product.preOrder ? 999 : product.quantity, onChange: onChangeCount })) }),
                                React.createElement(CartProductAttribute, { title: "Price", value: `${formatPrice(buyNowData.subtotalWithoutDiscounts || 0, 2)} ${currency || ''}` }),
                                React.createElement(CartProductAttribute, { title: "Delivery", value: `${formatPrice(buyNowData.deliveryCost || 0, 2)} ${currency || ''}` }),
                                buyNowData.couponsDiscounts !== 0 && (React.createElement(CartProductAttribute, { title: "Coupon discount", value: `${formatPrice(buyNowData.couponsDiscounts || 0, 2)} ${currency || ''}` })))),
                        React.createElement(Col, { size: 12 },
                            React.createElement("div", { className: styles.contentBlock },
                                React.createElement("div", null,
                                    !isShippingAvailable && (React.createElement(React.Fragment, null,
                                        React.createElement("div", { className: styles.cartProductTitle }, "Delivery"),
                                        React.createElement(Row, null,
                                            React.createElement(Col, { size: 11 },
                                                React.createElement("div", { className: styles.noDeliveryAvailableAlert },
                                                    React.createElement("span", { className: styles.error }, "Attention!"),
                                                    "\u00A0No shipping available for this product to your region."))))),
                                    isShippingAvailable && (React.createElement(AppContext.Consumer, null, ({ directories }) => {
                                        const currentAddressCountry = find(whereEq({ label: country }), convertCountries(directories.countries));
                                        const currentCountryAlpha3 = propOr(null, 'alpha3', currentAddressCountry);
                                        return currentCountryAlpha3 != null ? (React.createElement(React.Fragment, null,
                                            React.createElement("div", { className: styles.cartProductTitle }, "Delivery"),
                                            React.createElement(Row, null,
                                                React.createElement(Col, { size: 11 },
                                                    React.createElement(DeliveryCompaniesSelect, { currency: currency, baseProductId: baseProductId, country: currentCountryAlpha3, onPackagesFetched: onPackagesFetched, onPackageSelect: onChangeDelivery, selectedCompanyShippingRawId: deliveryPackage ? deliveryPackage.shippingId : null }))))) : (React.createElement("span", null, "No country found"));
                                    }))))))),
                React.createElement(Col, { size: 4, xlVisibleOnly: true },
                    React.createElement("div", { className: styles.contentBlock },
                        React.createElement("div", { className: styles.cartProductTitle }, "Price"),
                        React.createElement(CartProductAttribute, { title: "Count", value: (React.createElement(Stepper, { value: buyNowData.totalCount, min: 0, max: product.preOrder ? 999 : product.quantity, onChange: onChangeCount })) }),
                        React.createElement(CartProductAttribute, { title: "Price", value: `${formatPrice(buyNowData.subtotal || 0, 2)} ${currency || ''}` }),
                        React.createElement(CartProductAttribute, { title: "Delivery", value: `${formatPrice(buyNowData.deliveryCost || 0, 2)} ${currency || ''}` }),
                        buyNowData.couponsDiscounts !== 0 && (React.createElement(CartProductAttribute, { title: "Coupon discount", value: `−${formatPrice(buyNowData.couponsDiscounts || 0, 2)} ${currency || ''}` })))))));
    }
}
export default ProductInfo;
