import { commitMutation, graphql } from 'react-relay';
import { map } from 'ramda';
const mutation = graphql `
	mutation UpdateUserDeliveryAddressFullMutation(
		$input: UpdateUserDeliveryAddressFullInput!
	) {
		updateUserDeliveryAddressFull(input: $input) {
			rawId
			id
			userId
			isPriority
			address {
				country
				administrativeAreaLevel1
				administrativeAreaLevel2
				political
				postalCode
				streetNumber
				value
				route
				locality
			}
		}
	}
`;
const commit = (params) => commitMutation(params.environment, {
    mutation,
    variables: params.variables,
    onCompleted: params.onCompleted,
    onError: params.onError,
    updater: (relayStore) => {
        const updatedAddress = relayStore.getRootField('updateUserDeliveryAddressFull');
        const isPriority = updatedAddress.getValue('isPriority');
        const updatedAddressId = updatedAddress.getValue('id');
        const me = relayStore.getRoot()
            .getLinkedRecord('me');
        const deliveryAddresses = me.getLinkedRecords('deliveryAddressesFull');
        const newDeliveryAddresses = map((item) => {
            if (item._dataID === updatedAddressId) {
                return updatedAddress;
            }
            if (isPriority) {
                item.setValue(false, 'isPriority');
            }
            return item;
        }, deliveryAddresses);
        me.setLinkedRecords(newDeliveryAddresses, 'deliveryAddressesFull');
    }
});
export default { commit };
/*
 <example of response here>
 */
