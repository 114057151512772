import { t } from '../../../translation/utils';
const translations = {
    en: {
        productAddedToCart: 'Product added to cart!',
        unableToAddProductToCart: 'Unable to add product to cart',
        close: 'Close.',
        productNotFound: 'Product Not Found',
        storeNotFound: 'Store Not Found',
        noDescription: 'No Description',
        noLongDescription: 'No Long Description'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
