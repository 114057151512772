/**
 * @generated SignedSource<<3ccd823c7c8556aa4ff012e452f985e6>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rawId",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
    }, v5 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lang",
            "storageKey": null
        },
        (v4 /*: any*/)
    ], v6 = {
        "alias": null,
        "args": null,
        "concreteType": "Translation",
        "kind": "LinkedField",
        "name": "name",
        "plural": true,
        "selections": (v5 /*: any*/),
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isActive",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "concreteType": "Translation",
        "kind": "LinkedField",
        "name": "shortDescription",
        "plural": true,
        "selections": (v5 /*: any*/),
        "storageKey": null
    }, v9 = {
        "alias": null,
        "args": null,
        "concreteType": "Translation",
        "kind": "LinkedField",
        "name": "longDescription",
        "plural": true,
        "selections": (v5 /*: any*/),
        "storageKey": null
    }, v10 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
    }, v11 = {
        "alias": null,
        "args": null,
        "concreteType": "Category",
        "kind": "LinkedField",
        "name": "category",
        "plural": false,
        "selections": [
            (v2 /*: any*/),
            (v3 /*: any*/)
        ],
        "storageKey": null
    }, v12 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "storeId",
        "storageKey": null
    }, v13 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "productId",
        "storageKey": null
    }, v14 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "warehouseId",
        "storageKey": null
    }, v15 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v16 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
    }, v17 = {
        "alias": null,
        "args": null,
        "concreteType": "Address",
        "kind": "LinkedField",
        "name": "addressFull",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "country",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "countryCode",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "administrativeAreaLevel1",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "administrativeAreaLevel2",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "political",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "postalCode",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "streetNumber",
                "storageKey": null
            },
            (v16 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "route",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "locality",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v18 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "quantity",
        "storageKey": null
    }, v19 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "price",
        "storageKey": null
    }, v20 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "discount",
        "storageKey": null
    }, v21 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "photoMain",
        "storageKey": null
    }, v22 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "additionalPhotos",
        "storageKey": null
    }, v23 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "vendorCode",
        "storageKey": null
    }, v24 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cashback",
        "storageKey": null
    }, v25 = {
        "alias": null,
        "args": null,
        "concreteType": "CustomerPrice",
        "kind": "LinkedField",
        "name": "customerPrice",
        "plural": false,
        "selections": [
            (v19 /*: any*/),
            (v10 /*: any*/)
        ],
        "storageKey": null
    }, v26 = {
        "alias": null,
        "args": null,
        "concreteType": "ProdAttrValue",
        "kind": "LinkedField",
        "name": "attributes",
        "plural": true,
        "selections": [
            (v16 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "metaField",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "Attribute",
                "kind": "LinkedField",
                "name": "attribute",
                "plural": false,
                "selections": [
                    (v2 /*: any*/),
                    (v3 /*: any*/),
                    (v6 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "AttributeMetaField",
                        "kind": "LinkedField",
                        "name": "metaField",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "values",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "TranslatedValue",
                                "kind": "LinkedField",
                                "name": "translatedValues",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Translation",
                                        "kind": "LinkedField",
                                        "name": "translations",
                                        "plural": true,
                                        "selections": [
                                            (v4 /*: any*/)
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v27 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    }, v28 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
    }, v29 = {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v30 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "visibility",
                "value": "active"
            }
        ],
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
            (v2 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLWarehouse",
                "kind": "LinkedField",
                "name": "warehouses",
                "plural": true,
                "selections": [
                    (v2 /*: any*/)
                ],
                "storageKey": null
            }
        ],
        "storageKey": "store(visibility:\"active\")"
    }, v31 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lengthCm",
        "storageKey": null
    }, v32 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "widthCm",
        "storageKey": null
    }, v33 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "heightCm",
        "storageKey": null
    }, v34 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "weightG",
        "storageKey": null
    }, v35 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 1
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "CreateBaseProductWithVariantsMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "BaseProduct",
                    "kind": "LinkedField",
                    "name": "createBaseProductWithVariants",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        (v9 /*: any*/),
                        (v10 /*: any*/),
                        (v11 /*: any*/),
                        (v12 /*: any*/),
                        {
                            "alias": "products",
                            "args": null,
                            "concreteType": "ProductsConnection",
                            "kind": "LinkedField",
                            "name": "__Wizard_products_connection",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ProductsEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Product",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "GraphQLStock",
                                                    "kind": "LinkedField",
                                                    "name": "stocks",
                                                    "plural": true,
                                                    "selections": [
                                                        (v2 /*: any*/),
                                                        (v13 /*: any*/),
                                                        (v14 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "GraphQLWarehouse",
                                                            "kind": "LinkedField",
                                                            "name": "warehouse",
                                                            "plural": false,
                                                            "selections": [
                                                                (v15 /*: any*/),
                                                                (v17 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        (v18 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v2 /*: any*/),
                                                (v3 /*: any*/),
                                                (v19 /*: any*/),
                                                (v20 /*: any*/),
                                                (v21 /*: any*/),
                                                (v22 /*: any*/),
                                                (v23 /*: any*/),
                                                (v24 /*: any*/),
                                                (v25 /*: any*/),
                                                (v26 /*: any*/),
                                                (v27 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v28 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v29 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v30 /*: any*/),
                        (v31 /*: any*/),
                        (v32 /*: any*/),
                        (v33 /*: any*/),
                        (v34 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "CreateBaseProductWithVariantsMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "BaseProduct",
                    "kind": "LinkedField",
                    "name": "createBaseProductWithVariants",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        (v9 /*: any*/),
                        (v10 /*: any*/),
                        (v11 /*: any*/),
                        (v12 /*: any*/),
                        {
                            "alias": null,
                            "args": (v35 /*: any*/),
                            "concreteType": "ProductsConnection",
                            "kind": "LinkedField",
                            "name": "products",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ProductsEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Product",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "GraphQLStock",
                                                    "kind": "LinkedField",
                                                    "name": "stocks",
                                                    "plural": true,
                                                    "selections": [
                                                        (v2 /*: any*/),
                                                        (v13 /*: any*/),
                                                        (v14 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "GraphQLWarehouse",
                                                            "kind": "LinkedField",
                                                            "name": "warehouse",
                                                            "plural": false,
                                                            "selections": [
                                                                (v15 /*: any*/),
                                                                (v17 /*: any*/),
                                                                (v2 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        (v18 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v2 /*: any*/),
                                                (v3 /*: any*/),
                                                (v19 /*: any*/),
                                                (v20 /*: any*/),
                                                (v21 /*: any*/),
                                                (v22 /*: any*/),
                                                (v23 /*: any*/),
                                                (v24 /*: any*/),
                                                (v25 /*: any*/),
                                                (v26 /*: any*/),
                                                (v27 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v28 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v29 /*: any*/)
                            ],
                            "storageKey": "products(first:1)"
                        },
                        {
                            "alias": null,
                            "args": (v35 /*: any*/),
                            "filters": null,
                            "handle": "connection",
                            "key": "Wizard_products",
                            "kind": "LinkedHandle",
                            "name": "products"
                        },
                        (v30 /*: any*/),
                        (v31 /*: any*/),
                        (v32 /*: any*/),
                        (v33 /*: any*/),
                        (v34 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "ee2030f522aeda9b93c5e00f8e4e186e",
            "id": null,
            "metadata": {
                "connection": [
                    {
                        "count": null,
                        "cursor": null,
                        "direction": "forward",
                        "path": [
                            "createBaseProductWithVariants",
                            "products"
                        ]
                    }
                ]
            },
            "name": "CreateBaseProductWithVariantsMutation",
            "operationKind": "mutation",
            "text": "mutation CreateBaseProductWithVariantsMutation(\n  $input: NewBaseProductWithVariantsInput!\n) {\n  createBaseProductWithVariants(input: $input) {\n    id\n    rawId\n    name {\n      lang\n      text\n    }\n    isActive\n    shortDescription {\n      lang\n      text\n    }\n    longDescription {\n      lang\n      text\n    }\n    currency\n    category {\n      id\n      rawId\n    }\n    storeId\n    products(first: 1) {\n      edges {\n        node {\n          stocks {\n            id\n            productId\n            warehouseId\n            warehouse {\n              name\n              addressFull {\n                country\n                countryCode\n                administrativeAreaLevel1\n                administrativeAreaLevel2\n                political\n                postalCode\n                streetNumber\n                value\n                route\n                locality\n              }\n              id\n            }\n            quantity\n          }\n          id\n          rawId\n          price\n          discount\n          photoMain\n          additionalPhotos\n          vendorCode\n          cashback\n          customerPrice {\n            price\n            currency\n          }\n          attributes {\n            value\n            metaField\n            attribute {\n              id\n              rawId\n              name {\n                lang\n                text\n              }\n              metaField {\n                values\n                translatedValues {\n                  translations {\n                    text\n                  }\n                }\n              }\n            }\n          }\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    store(visibility: \"active\") {\n      id\n      warehouses {\n        id\n      }\n    }\n    lengthCm\n    widthCm\n    heightCm\n    weightG\n  }\n}\n"
        }
    };
})();
node.hash = "30b5643001c942097739c28b601921be";
export default node;
