import React from 'react';
import { Checkbox } from '../../../../../../../components/Checkbox';
import { Icon } from '../../../../../../../components/Icon';
import styles from './Header.module.scss';
import { classNames } from '../../../../../../../utils';
const Header = (props) => (React.createElement("div", { className: styles.header },
    React.createElement("div", { className: classNames(styles, 'headerItem', 'tdCheckbox') },
        React.createElement(Checkbox, { id: "id-header", onChange: props.onSelectAllClick })),
    React.createElement("div", { className: classNames(styles, 'headerItem', 'tdArticle') },
        React.createElement("div", { className: styles.headerItemWrap },
            React.createElement("span", null, "SKU"),
            React.createElement(Icon, { inline: true, type: "sortArrows" }))),
    React.createElement("div", { className: classNames(styles, 'headerItem', 'tdPrice') },
        React.createElement("div", { className: styles.headerItemWrap },
            React.createElement("span", null, "Price"),
            React.createElement(Icon, { inline: true, type: "sortArrows" }))),
    React.createElement("div", { className: classNames(styles, 'headerItem', 'tdCashback') },
        React.createElement("div", { className: styles.headerItemWrap },
            React.createElement("span", null, "Cashback"),
            React.createElement(Icon, { inline: true, type: "sortArrows" }))),
    React.createElement("div", { className: classNames(styles, 'headerItem', 'tdDiscount') },
        React.createElement("div", { className: styles.headerItemWrap },
            React.createElement("span", null, "Discount"),
            React.createElement(Icon, { inline: true, type: "sortArrows" }))),
    React.createElement("div", { className: classNames(styles, 'headerItem', 'tdCharacteristics') },
        React.createElement("div", { className: styles.headerItemWrap },
            React.createElement("span", null, "Characteristics"),
            React.createElement(Icon, { inline: true, type: "sortArrows" }))),
    React.createElement("div", { className: classNames(styles, 'headerItem', 'tdCount') },
        React.createElement("div", { className: styles.headerItemWrap },
            React.createElement("span", null, "Quantity in storage"),
            React.createElement(Icon, { inline: true, type: "sortArrows" }))),
    React.createElement("div", { className: classNames(styles, 'headerItem', 'tdCopy') }),
    React.createElement("div", { className: classNames(styles, 'headerItem', 'tdBasket') })));
export default Header;
