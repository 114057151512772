import React, { Component } from 'react';
import { addIndex, difference, filter, isEmpty, map, prepend } from 'ramda';
import { convertLocalAvailablePackages, getService, getServiceLogo, getServiceRawId } from '../utils';
export default (OriginalComponent) => class HandlerShippingDecorator extends Component {
    // static getDerivedStateFromProps(nextProps: PropsType, prevState: StateType) {
    //
    // }
    constructor(props) {
        super(props);
        const { localShipping, onChangeShippingData, pickupShipping } = props;
        const companies = addIndex(map)((item, idx) => this.makeCompany(item, idx), localShipping);
        const checkedCompanies = filter((item) => item.companyPackageRawId !== -1, companies || []);
        const shippingData = {
            companies: checkedCompanies,
            withoutLocal: Boolean(isEmpty(checkedCompanies) && pickupShipping && !pickupShipping.pickup)
        };
        onChangeShippingData(shippingData);
        const remainingServices = this.setRemainingServices(checkedCompanies);
        this.state = {
            companies: checkedCompanies,
            editableItemId: null,
            remainingServices
        };
    }
    setRemainingServices = (companies) => difference(convertLocalAvailablePackages(this.props.localAvailablePackages), map((item) => item.service, companies));
    handleOnSaveCompany = (company) => {
        if (company.id !== undefined) {
            this.setState((prevState) => {
                const { service } = company;
                const newCompany = {
                    ...company,
                    companyPackageRawId: getServiceRawId({
                        id: company.service && company.service.id,
                        packages: this.props.localAvailablePackages
                    }),
                    logo: getServiceLogo({
                        id: company.service && company.service.id,
                        packages: this.props.localAvailablePackages
                    }),
                    service: service
                        ? {
                            id: service.id,
                            label: service.label,
                            currency: service.currency
                        }
                        : null
                };
                const newCompanies = map((item) => (item.id === company.id ? newCompany : item), prevState.companies);
                const remainingServices = this.setRemainingServices(newCompanies);
                return {
                    companies: newCompanies,
                    remainingServices,
                    editableItemId: null
                };
            }, () => {
                this.props.onChangeShippingData({
                    companies: this.state.companies,
                    inter: false
                });
            });
        }
        else {
            this.setState((prevState) => {
                const { service } = company;
                const newCompany = {
                    ...company,
                    id: `${Date.now()}`,
                    companyPackageRawId: getServiceRawId({
                        id: company.service && company.service.id,
                        packages: this.props.localAvailablePackages
                    }),
                    logo: getServiceLogo({
                        id: company.service && company.service.id,
                        packages: this.props.localAvailablePackages
                    }),
                    service: service
                        ? {
                            id: service.id,
                            label: service.label,
                            currency: service.currency
                        }
                        : null
                };
                const newCompanies = prepend(newCompany, prevState.companies);
                const remainingServices = this.setRemainingServices(newCompanies);
                return {
                    companies: newCompanies,
                    remainingServices,
                    editableItemId: null
                };
            }, () => {
                this.props.onChangeShippingData({
                    companies: this.state.companies,
                    inter: false
                });
            });
        }
    };
    handleOnRemoveCompany = (company) => {
        this.setState((prevState) => {
            const newCompanies = filter((item) => company.id !== item.id, prevState.companies);
            const remainingServices = this.setRemainingServices(newCompanies);
            return {
                companies: newCompanies,
                remainingServices,
                editableItemId: null
            };
        }, () => {
            this.props.onChangeShippingData({
                companies: this.state.companies,
                inter: false
            });
        });
    };
    handleOnSetEditableItem = (company) => {
        this.setState((prevState) => {
            const newCompanies = filter((item) => company.id !== item.id, prevState.companies);
            const possibleServices = this.setRemainingServices(newCompanies);
            return {
                editableItemId: company.id,
                possibleServices
            };
        });
    };
    handleOnRemoveEditableItem = () => {
        this.setState({ editableItemId: null });
    };
    makeCompany = (data, idx) => {
        const { localAvailablePackages, currency } = this.props;
        const service = getService({
            id: data.companyPackageId,
            packages: localAvailablePackages
        });
        const company = {
            id: `${Date.now()}-${idx}`,
            companyPackageRawId: getServiceRawId({
                id: service && service.id,
                packages: localAvailablePackages
            }),
            currency,
            price: data.price,
            logo: getServiceLogo({
                id: service && service.id,
                packages: localAvailablePackages
            }),
            service
        };
        return company;
    };
    render() {
        const { currency, error, localAvailablePackages, localShipping, pickupShipping, onChangeShippingData } = this.props;
        const { companies, editableItemId, remainingServices, possibleServices } = this.state;
        return (React.createElement(OriginalComponent, { currency: currency, error: error, companies: companies, editableItemId: editableItemId, remainingServices: remainingServices, possibleServices: possibleServices, onChangeShippingData: onChangeShippingData, onSaveCompany: this.handleOnSaveCompany, onRemoveCompany: this.handleOnRemoveCompany, onSetEditableItem: this.handleOnSetEditableItem, onRemoveEditableItem: this.handleOnRemoveEditableItem, localAvailablePackages: localAvailablePackages, localShipping: localShipping, pickupShipping: pickupShipping }, this.props.children));
    }
};
