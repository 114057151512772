import React, { Component } from 'react';
import { assocPath, find, isEmpty, omit, pathOr, propEq, propOr, toLower, toUpper } from 'ramda';
import { createFragmentContainer, graphql } from 'react-relay';
import copy from 'copy-to-clipboard';
import { validate } from '@sansare/libstqfrontend';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { Environment } from 'relay-runtime';
import { classNames, debug, fromRelayError, renameKeys } from '../../../../utils';
import { UpdateUserMutation } from '../../../../relay/mutations';
import { Button, Input, Select } from '../../../../components/common';
import { Icon } from '../../../../components/Icon';
import { BirthdateSelect } from '../../../../components/common/BirthdateSelect';
import { withShowAlert } from '../../../../components/Alerts/AlertContext';
import styles from '../../Profile.module.scss';
import t from './i18n';
const genderItems = [
    {
        id: 'male',
        label: 'Male'
    },
    {
        id: 'female',
        label: 'Female'
    }
];
let timer;
class PersonalData extends Component {
    static contextTypes = { environment: PropTypes.instanceOf(Environment).isRequired };
    constructor(props) {
        super(props);
        const { me } = props;
        this.state = {
            data: {
                phone: me.phone || '',
                firstName: me.firstName || '',
                lastName: me.lastName || '',
                birthdate: me.birthdate || '',
                gender: me.gender || 'UNDEFINED'
            },
            formErrors: {},
            isLoading: false,
            isCopiedRef: false
        };
    }
    componentWillUnmount() {
        clearTimeout(timer);
    }
    handleSave = () => {
        const { environment } = this.context;
        const { me: propsData } = this.props;
        const { data } = this.state;
        const { phone, firstName, lastName, birthdate, gender } = data;
        const { errors: formErrors } = validate({
            firstName: [
                [
                    (value) => value && value.length > 0,
                    t.firstNameMustNotBeEmpty
                ]
            ],
            lastName: [
                [
                    (value) => value && value.length > 0,
                    t.lastNameMustNotBeEmpty
                ]
            ]
        }, {
            firstName,
            lastName
        });
        if (formErrors) {
            this.setState({ formErrors });
            return;
        }
        this.setState(() => ({ isLoading: true }));
        const params = {
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    id: propsData.id,
                    phone: phone || null,
                    firstName: firstName || null,
                    lastName: lastName || null,
                    birthdate: birthdate || null,
                    gender: gender || null,
                    avatar: null
                }
            },
            environment,
            onCompleted: (response, errors) => {
                debug({
                    response,
                    errors
                });
                const relayErrors = fromRelayError({ source: { errors } });
                debug({ relayErrors });
                const validationErrors = pathOr({}, ['100', 'messages'], relayErrors);
                const status = pathOr('', ['100', 'status'], relayErrors);
                this.setState(() => ({ isLoading: false }));
                if (!isEmpty(validationErrors)) {
                    this.setState({
                        formErrors: renameKeys({
                            first_name: 'firstName',
                            last_name: 'lastName'
                        }, validationErrors)
                    });
                    return;
                }
                else if (status) {
                    this.props.showAlert({
                        type: 'danger',
                        text: `${t.error} "${status}"`,
                        link: { text: t.close }
                    });
                    return;
                }
                else if (errors) {
                    this.props.showAlert({
                        type: 'danger',
                        text: t.somethingGoingWrong,
                        link: { text: t.close }
                    });
                    return;
                }
                this.props.showAlert({
                    type: 'success',
                    text: t.userUpdated,
                    link: { text: '' }
                });
            },
            onError: (error) => {
                this.setState(() => ({ isLoading: false }));
                debug({ error });
                const relayErrors = fromRelayError(error);
                debug({ relayErrors });
                this.setState(() => ({ isLoading: false }));
                const validationErrors = pathOr({}, ['100', 'messages'], relayErrors);
                if (!isEmpty(validationErrors)) {
                    this.setState({
                        formErrors: renameKeys({
                            first_name: 'firstName',
                            last_name: 'lastName'
                        }, validationErrors)
                    });
                    return;
                }
                this.props.showAlert({
                    type: 'danger',
                    text: t.somethingGoingWrong,
                    link: { text: t.close }
                });
            }
        };
        UpdateUserMutation.commit(params);
    };
    handleInputChange = (id) => (e) => {
        this.setState((preState) => ({ formErrors: omit([id], preState.formErrors) }));
        const { value } = e.target;
        if (id === 'phone' && !/^\+?\d*$/.test(value)) {
            return;
        }
        this.setState((prevState) => assocPath(['data', id], value, prevState));
    };
    handleGenderSelect = (genderValue) => {
        this.setState((prevState) => assocPath(['data', 'gender'], toUpper(genderValue.id), prevState));
    };
    handleBirthdateSelect = (value) => {
        this.setState((prevState) => assocPath(['data', 'birthdate'], value, prevState));
    };
    renderInput = ({ id, label, limit }) => (React.createElement("div", { className: styles.formItem },
        React.createElement(Input, { id: id, value: propOr('', id, this.state.data), label: label, onChange: this.handleInputChange(id), errors: propOr(null, id, this.state.formErrors), limit: limit, fullWidth: true, dataTest: `${id}Input` })));
    handleCopyRefLink = (refLink) => {
        clearTimeout(timer);
        this.setState({ isCopiedRef: true });
        timer = setTimeout(() => {
            this.setState({ isCopiedRef: false });
        }, 3000);
        copy(refLink);
    };
    render() {
        const { subtitle } = this.props;
        const { data, isLoading, formErrors, isCopiedRef } = this.state;
        const refNum = pathOr('', ['me', 'rawId'], this.props);
        const genderValue = find(propEq(toLower(data.gender), 'id'))(genderItems);
        const refLink = process.env.REACT_APP_HOST ? `${process.env.REACT_APP_HOST}?ref=${refNum}` : '';
        return (React.createElement("div", { className: styles.personalData },
            React.createElement("div", { className: styles.personalDataWrap },
                React.createElement("div", { className: styles.subtitle },
                    React.createElement("strong", null, t.personalDataSettings)),
                this.renderInput({
                    id: 'firstName',
                    label: t.labelFirstName,
                    limit: 50
                }),
                this.renderInput({
                    id: 'lastName',
                    label: t.labelLastName,
                    limit: 50
                }),
                React.createElement("div", { className: styles.formItem },
                    React.createElement(Select, { forForm: true, label: t.labelGender, activeItem: genderValue, items: genderItems, onSelect: this.handleGenderSelect, dataTest: "profileGenderSelect", fullWidth: true })),
                React.createElement("div", { className: styles.formItem },
                    React.createElement(BirthdateSelect, { label: t.labelBirthdate, birthdate: data.birthdate, handleBirthdateSelect: this.handleBirthdateSelect, errors: propOr(null, 'birthdate', formErrors), dataTest: "profileBirthdate" })),
                this.renderInput({
                    id: 'phone',
                    label: t.labelPhone
                }),
                React.createElement("div", { className: styles.formItem },
                    React.createElement("div", { className: styles.inputRef },
                        React.createElement("button", { className: classNames(styles, 'copyRefButton', { isCopiedRef }), onClick: () => {
                                this.handleCopyRefLink(refLink);
                            }, "data-test": "copyRefButton" },
                            React.createElement(Icon, { type: "copy", size: 32 })),
                        React.createElement(Input, { id: "ref", disabled: true, value: refLink, label: t.labelRefLink, onChange: () => {
                            }, fullWidth: true }),
                        React.createElement("div", { className: classNames(styles, 'copyMessage', { isCopiedRef }), "data-test": "copyRefButton" }, t.copied))),
                React.createElement("div", { className: styles.formItem },
                    React.createElement("div", { className: styles.button },
                        React.createElement(Button, { big: true, fullWidth: true, onClick: this.handleSave, isLoading: isLoading, dataTest: "savePersonalDataButton" }, t.save))))));
    }
}
export default createFragmentContainer(withShowAlert(PersonalData), {
    me: graphql `
			fragment PersonalData_me on User {
				id
				rawId
				phone
				firstName
				lastName
				birthdate
				gender
			}
    `
});
