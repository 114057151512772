import React, { Component, createRef } from 'react';
import { isNil } from 'ramda';
import { InputPrice } from '../common/InputPrice';
import { setZindex } from './utils';
import styles from './RangeSlider.module.scss';
import RangeSliderTrack from './RangeSliderTrack';
class RangeSlider extends Component {
    static getDerivedStateFromProps(nextProps, prevState) {
        const { thumb1, thumb2, minValue, maxValue } = nextProps;
        if (minValue !== prevState.minValue || maxValue !== prevState.maxValue) {
            const stepPhantom = (maxValue - minValue) / 100;
            return {
                ...prevState,
                thumb1,
                thumb2,
                minValue,
                maxValue,
                thumb1InputValue: thumb1,
                thumb2InputValue: thumb2,
                thumb1Phantom: 0,
                thumb2Phantom: 100,
                stepPhantom
            };
        }
        return null;
    }
    constructor(props) {
        super(props);
        const { thumb1, thumb2, minValue, maxValue } = props;
        const stepPhantom = (maxValue - minValue) / 100;
        this.state = {
            thumb1,
            thumb2,
            minValue,
            maxValue,
            thumb1InputValue: thumb1,
            thumb2InputValue: thumb2,
            focusedInput: null,
            thumb1Phantom: Math.round((thumb1 - minValue) / stepPhantom),
            thumb2Phantom: Math.round((thumb2 - minValue) / stepPhantom),
            stepPhantom
        };
        this.thumb1Ref = createRef();
        this.thumb2Ref = createRef();
        this.sectionRef = createRef();
        if (process.env.BROWSER) {
            window.addEventListener('keydown', this.handleKeydown);
        }
    }
    componentWillUnmount() {
        if (process.env.BROWSER) {
            window.removeEventListener('keydown', this.handleKeydown);
        }
    }
    onChangeEvent = (id, value, prevValue) => {
        const event = new Event('input', { bubbles: true });
        event.simulated = true;
        this[`${id}Ref`].current.value = value.toString();
        const tracker = (this[`${id}Ref`].current)._valueTracker;
        if (tracker) {
            tracker.setValue(prevValue);
        }
        this[`${id}Ref`].current.dispatchEvent(event);
    };
    getThumbRef = (id) => (id === 'thumb1' ? this.thumb1Ref : this.thumb2Ref);
    getInputPriceRef = (id) => (id === 'thumb1Input' ? this.thumb1InputRef : this.thumb2InputRef);
    setValues = (id, value) => {
        const { stepPhantom, minValue } = this.state;
        this.setState({
            [`${id}`]: value,
            [`${id}Phantom`]: Math.round((value - minValue) / stepPhantom),
            [`${id}InputValue`]: value
        }, this.transferData);
    };
    formatNumber = (value) => Number(value.toFixed());
    transferData = () => {
        const { onChange } = this.props;
        const { thumb1, thumb2 } = this.state;
        onChange({
            thumb1: this.formatNumber(thumb1),
            thumb2: this.formatNumber(thumb2)
        });
    };
    handleKeydown = (e) => {
        const { focusedInput } = this.state;
        if (e.key === 'Enter' && !isNil(focusedInput)) {
            const inputRef = this.getInputPriceRef(focusedInput);
            if (!isNil(inputRef)) {
                inputRef.blur();
            }
        }
    };
    thumb1Ref;
    thumb2Ref;
    sectionRef;
    thumb1InputRef;
    thumb2InputRef;
    handleOnChange = (e) => {
        const { id, value } = e.target;
        const parsedValue = parseFloat(value);
        const { stepPhantom, minValue } = this.state;
        this.setState({
            [id]: minValue + parsedValue * stepPhantom,
            [`${id}Phantom`]: parsedValue,
            [`${id}InputValue`]: minValue + parsedValue * stepPhantom
        }, () => {
            this.transferData();
            if (this.state.thumb1Phantom > this.state.thumb2Phantom) {
                this.setState((prevState) => ({
                    thumb1: prevState.thumb2 ? prevState.thumb2 : prevState.minValue,
                    thumb2: prevState.thumb1,
                    thumb1Phantom: prevState.thumb2Phantom,
                    thumb2Phantom: prevState.thumb1Phantom,
                    thumb1InputValue: prevState.thumb2InputValue
                        ? prevState.thumb2InputValue
                        : prevState.minValue,
                    thumb2InputValue: prevState.thumb1InputValue
                }), this.transferData);
            }
        });
    };
    handleOnMouseDown = (e) => {
        const { id } = e.target;
        const { current } = this.thumb1Ref;
        const applyZindex = setZindex(current);
        if (id === 'thumb1Phantom') {
            applyZindex('3');
        }
        else {
            applyZindex('');
        }
    };
    handleOnMouseDownSection = (e) => {
        let DOMRect = {};
        if (!isNil(this.sectionRef.current)) {
            DOMRect = this.sectionRef.current.getBoundingClientRect();
        }
        // volume (how many in one unit of value)
        const { thumb1Phantom, thumb2Phantom } = this.state;
        const volume = 100 / (DOMRect.width - 16);
        // Coordinate
        /**
         * @desc 'x' is an experimental feature. use 'left' instead
         * @link https://github.com/facebook/flow/issues/5357
         */
        let coor = e.clientX - DOMRect.left;
        if (coor <= 8) {
            coor = 0;
        }
        else if (coor >= DOMRect.width - 8) {
            coor = DOMRect.width - 16;
        }
        else {
            coor -= 8;
        }
        // New thumb
        const thumb = coor * volume;
        if (Math.abs(thumb2Phantom - thumb) < Math.abs(thumb - thumb1Phantom)
            || (thumb2Phantom === thumb1Phantom && thumb2Phantom < thumb)) {
            this.onChangeEvent('thumb2', thumb, thumb2Phantom);
            return;
        }
        this.onChangeEvent('thumb1', thumb, thumb1Phantom);
    };
    handleOnChangeInput = (id, value) => {
        if (id === 'thumb1Input') {
            this.setState({ thumb1InputValue: value });
        }
        else {
            this.setState({ thumb2InputValue: value });
        }
    };
    handleOnFocusInput = (id) => {
        this.setState({ focusedInput: id });
    };
    handleOnBlurInput = (id) => {
        const { thumb1InputValue, thumb2InputValue, minValue, maxValue } = this.state;
        if (id === 'thumb1Input') {
            if (thumb1InputValue < minValue) {
                this.setValues('thumb1', minValue);
                return;
            }
            if (thumb1InputValue > thumb2InputValue) {
                this.setValues('thumb1', thumb2InputValue);
                return;
            }
            this.setValues('thumb1', thumb1InputValue);
            return;
        }
        if (id === 'thumb2Input') {
            if (thumb2InputValue > maxValue) {
                this.setValues('thumb2', maxValue);
                return;
            }
            if (thumb2InputValue < thumb1InputValue) {
                this.setValues('thumb2', thumb1InputValue);
                return;
            }
            this.setValues('thumb2', thumb2InputValue);
            return;
        }
        this.setState({ focusedInput: null });
    };
    makeInputRanges = (ids) => ids.map((id) => ({
        id,
        max: 100,
        min: 0,
        onChange: this.handleOnChange,
        onMouseDown: this.handleOnMouseDown,
        ref: this.getThumbRef(id),
        step: 1,
        type: 'range',
        value: this.state[`${id}Phantom`].toString()
    }));
    makeInputPrices = (ids) => ids.map((id) => ({
        inputRef: (node) => {
            this.applyInputNode(id, node);
        },
        id,
        onChangePrice: (value) => {
            this.handleOnChangeInput(id, value);
        },
        onFocus: () => {
            this.handleOnFocusInput(id);
        },
        onBlur: () => {
            this.handleOnBlurInput(id);
        },
        price: this.formatNumber(this.state[`${id}Value`]),
        align: 'left',
        dataTest: `${id}InputPrice`
    }));
    applyInputNode = (id, node) => {
        if (id === 'thumb1Input') {
            this.thumb1InputRef = node;
        }
        else {
            this.thumb2InputRef = node;
        }
    };
    render() {
        const { minValue, maxValue, thumb1Phantom, thumb2Phantom } = this.state;
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { ref: this.sectionRef, className: styles.rangeSlider, onMouseDown: this.handleOnMouseDownSection, role: "button", tabIndex: 0 },
                this.makeInputRanges(['thumb1', 'thumb2'])
                    .map((input) => (React.createElement("input", { key: input.id, ...input }))),
                React.createElement(RangeSliderTrack, { thumb1Phantom: thumb1Phantom, thumb2Phantom: thumb2Phantom }),
                React.createElement("div", { className: styles.minTooltip, title: minValue.toString() }),
                React.createElement("div", { className: styles.maxTooltip, title: maxValue.toString() })),
            React.createElement("div", { className: styles.inputs }, this.makeInputPrices(['thumb1Input', 'thumb2Input'])
                .map((input) => (React.createElement("div", { key: input.id },
                React.createElement(InputPrice, { ...input })))))));
    }
}
export default RangeSlider;
