import { commitMutation, graphql } from 'react-relay';
const mutation = graphql `
	mutation ConfirmOrderMutation($input: OrderConfirmedInput!) {
		confirmOrder(input: $input) {
			id
			slug
			storeId
			product {
				baseProduct {
					rawId
					name {
						text
					}
					category {
						rawId
						name {
							text
							lang
						}
					}
				}
				price
				attributes {
					value
					attribute {
						name {
							lang
							text
						}
					}
				}
				photoMain
			}
			receiverName
			addressFull {
				value
			}
			createdAt
			deliveryCompany
			trackId
			quantity
			subtotal
			state
			paymentStatus
			history {
				edges {
					node {
						state
						committedAt
						committerRole
						comment
					}
				}
			}
		}
	}
`;
const commit = (params) => commitMutation(params.environment, {
    mutation,
    variables: params.variables,
    onCompleted: params.onCompleted,
    onError: params.onError
});
export default { commit };
