import React, { PureComponent } from 'react';
import styles from './Checkbox.module.scss';
import { classNames } from '../../../utils';
class Checkbox extends PureComponent {
    onChange = () => {
        const { id, onChange } = this.props;
        onChange(`${id}`);
    };
    stopPropagation = (e) => {
        e.stopPropagation();
    };
    static defaultProps = {
        id: 'stq',
        label: '',
        isChecked: false,
        onChange: () => {
        }
    };
    render() {
        const { id, label, isChecked, dataTest, isRadio } = this.props;
        const dataTestValue = dataTest != null ? dataTest : id;
        return (React.createElement("div", { className: styles.container, onClick: this.stopPropagation, onKeyDown: () => {
            }, role: "button", tabIndex: 0 },
            React.createElement("input", { className: classNames(styles, 'input', { isRadio }), id: id, type: "checkbox", checked: isChecked, onChange: this.onChange, onClick: this.stopPropagation }),
            React.createElement("label", { htmlFor: id, className: styles.label, "data-test": dataTestValue },
                React.createElement("span", { className: styles.labelBefore }),
                React.createElement("span", { className: styles.labelText }, label),
                React.createElement("span", { className: styles.labelAfter }))));
    }
}
export default Checkbox;
