import React, { Component } from 'react';
import { withRouter } from 'found';
import { pathOr } from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import { VerifyEmailMutation } from '../../relay/mutations';
import { withErrorBoundary } from '../../components/common/ErrorBoundaries';
import { Spinner } from '../../components/common/Spinner';
import { debug, setJWT } from '../../utils';
import { setEmailTracker } from '../../rrHalper';
import { withShowAlert } from '../../components/Alerts/AlertContext';
import Logo from '../../components/Icon/svg/logo.svg';
import { ContextDecorator } from '../../components/App';
import styles from './VerifyEmail.module.scss';
import t from './i18n';
class VerifyEmail extends Component {
    constructor(props) {
        super(props);
        this.state = { isTokenResponseAlreadyHandled: false };
    }
    componentDidMount() {
        if (this.state.isTokenResponseAlreadyHandled) {
            return;
        }
        const params = {
            variables: {
                input: {
                    clientMutationId: uuidv4(),
                    token: this.props.match.params.token
                }
            },
            environment: this.props.environment,
            onCompleted: (response, errors) => {
                debug({
                    response,
                    errors
                });
                this.setState({ isTokenResponseAlreadyHandled: true });
                if (response && response.verifyEmail && response.verifyEmail.success) {
                    this.props.showAlert({
                        type: 'success',
                        text: t.verifiedSuccessfully,
                        link: { text: '' }
                    });
                    const { email } = response.verifyEmail;
                    if (process.env.BROWSER
                        && process.env.REACT_APP_RRPARTNERID
                        && email) {
                        setEmailTracker(email);
                    }
                    const jwtStr = response.verifyEmail.token;
                    if (jwtStr) {
                        setJWT(jwtStr);
                        this.props.router.replace('/');
                        this.props.handleLogin();
                    }
                }
                else if (errors && errors.length > 0) {
                    const errorMessage = pathOr(null, [0, 'data', 'details', 'message'], errors);
                    debug({ errorMessage });
                    this.props.showAlert({
                        type: 'danger',
                        text: errorMessage || t.somethingGoingWrong,
                        link: { text: t.close }
                    });
                    this.props.router.replace('/');
                }
            },
            onError: (err) => {
                debug({ err });
                this.setState({ isTokenResponseAlreadyHandled: true });
                if (err) {
                    this.props.showAlert({
                        type: 'danger',
                        text: t.somethingGoingWrong,
                        link: { text: t.close }
                    });
                    this.props.router.replace('/');
                }
            }
        };
        VerifyEmailMutation.commit(params);
    }
    render() {
        return (React.createElement("div", null,
            React.createElement("div", { className: styles.container },
                React.createElement("div", { className: styles.logo },
                    React.createElement(Logo, null)),
                React.createElement("span", { className: styles.text },
                    t.loading,
                    React.createElement("br", null),
                    t.pleaseWait),
                React.createElement("span", { className: styles.description },
                    "-",
                    t.storiqaTeam),
                React.createElement(Spinner, null))));
    }
}
export default withShowAlert(withErrorBoundary(withRouter(ContextDecorator(VerifyEmail))));
