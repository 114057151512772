import { graphql } from 'react-relay';
import { basicMutation } from '../../../../../../relay/mutations/basicMutation';
const mutation = graphql `
	mutation SendProductToModerationMutation($id: Int!) {
		sendBaseProductToModeration(id: $id) {
			id
			rawId
			status
		}
	}
`;
const sendProductToModerationMutation = basicMutation(mutation, 'sendBaseProductToModeration');
export default sendProductToModerationMutation;
