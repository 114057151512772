import { assoc, concat, find, flatten, has, ifElse, lensPath, map, over, pipe, prop, propEq, reduce, sortBy, uniqBy, values } from 'ramda';
export const makeWidgetsFromVariants = (variants) => {
    const allAttrs = flatten(map(prop('attributes'), variants));
    return values(reduce((acc, item) => ifElse(has(item.attribute.id), over(lensPath([item.attribute.id, 'options']), pipe(concat([
        {
            label: item.value,
            image: item.metaField
        }
    ]), uniqBy(prop('label')), sortBy(prop('label')))), assoc(item.attribute.id, {
        id: item.attribute.id,
        title: prop('text', find(propEq('EN', 'lang'), item.attribute.name) || { text: '' }),
        uiElement: item.attribute.metaField.uiElement,
        options: [
            {
                label: item.value,
                image: item.metaField
            }
        ]
    }))(acc), {}, allAttrs));
};
export default makeWidgetsFromVariants;
