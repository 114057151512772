import { t } from '../../../../translation/utils';
const translations = {
    en: {
        goBack: 'Go back',
        thisListingIsntActiveYet: 'This listing isn’t active yet. It will be available to shoppers once you open your shop.',
        nextStep: 'Next Step',
        publishStore: 'Publish Store'
    }
};
const validate = () => {
    try {
        // (json: TranslationsBundleType);
        return true;
    }
    catch (err) {
        // verbose && console.error(err);
        return false;
    }
};
export { translations, validate };
export default t(translations);
