import { commitMutation, graphql } from 'react-relay';
const mutation = graphql `
	mutation CreateInternationalBillingInfoMutation(
		$input: NewInternationalBillingInfoInput!
	) {
		createInternationalBillingInfo(input: $input) {
			id
			rawId
			storeId
			account
			currency
			name
			bank
			swift
			bankAddress
			country
			city
			recipientAddress
		}
	}
`;
const commit = (params) => commitMutation(params.environment, {
    mutation,
    variables: params.variables,
    onCompleted: params.onCompleted,
    onError: params.onError,
    updater: (relayStore) => {
        const createInternationalBillingInfo = relayStore.getRootField('createInternationalBillingInfo');
        const me = relayStore.getRoot()
            .getLinkedRecord('me');
        const myStore = me.getLinkedRecord('myStore');
        const indiaBillingInfo = myStore.getLinkedRecord('indiaBillingInfo');
        if (indiaBillingInfo) {
            const indiaBillingInfoId = indiaBillingInfo.getValue('id');
            relayStore.delete(indiaBillingInfoId);
        }
        myStore.setLinkedRecord(createInternationalBillingInfo, 'internationalBillingInfo');
    }
});
export default { commit };
