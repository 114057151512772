import React, { PureComponent } from 'react';
import { withRouter } from 'found';
import { Error } from '../../../pages/Errors';
class DefaultErrorBoundary extends PureComponent {
    componentDidMount() {
        this.props.router.push('/error');
    }
    render() {
        return React.createElement(Error, null);
    }
}
export default withRouter(DefaultErrorBoundary);
