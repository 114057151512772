import React, { PureComponent } from 'react';
import { prop, propOr } from 'ramda';
import { verifyItemCurrency } from '../../../../utils';
import { Rating } from '../../../../components/common/Rating';
import ProductContext from '../ProductContext';
import ProductPrice from '../ProductPrice';
import Delivery from '../Delivery/Delivery';
import ProductQuantity from '../ProductQuantity';
import ProductThumbnails from '../ProductThumbnails';
import ProductMaterial from '../ProductMaterial';
import ProductSize from '../ProductSize';
import { sortByProp } from '../utils';
import styles from './ProductDetails.module.scss';
class ProductDetails extends PureComponent {
    generateWidget = (widget, index) => {
        const { unselectedAttr, productVariant } = this.props;
        let WidgetComponent;
        switch (widget.uiElement) {
            case 'CHECKBOX':
                WidgetComponent = (React.createElement(ProductSize, { key: index, id: widget.id, title: widget.title, options: widget.options, onClick: this.props.onWidgetClick, selectedValue: prop(widget.id, this.props.selectedAttributes), availableValues: propOr([], widget.id, this.props.availableAttributes), isOnSelected: (unselectedAttr && unselectedAttr.indexOf(widget.title) > -1) || false }));
                break;
            case 'COMBOBOX':
                WidgetComponent = (React.createElement(ProductMaterial, { key: index, id: widget.id, title: widget.title || '', options: widget.options, onSelect: this.props.onWidgetClick, selectedValue: prop(widget.id, this.props.selectedAttributes), availableValues: propOr([], widget.id, this.props.availableAttributes), isOnSelected: (unselectedAttr && unselectedAttr.indexOf(widget.title) > -1) || false }));
                break;
            case 'COLOR_PICKER':
                WidgetComponent = (React.createElement(ProductThumbnails, { key: index, id: widget.id, title: widget.title, row: true, 
                    /* srcProp="image" */
                    options: widget.options, onClick: (option) => this.props.onWidgetClick({
                        attributeId: widget.id,
                        attributeValue: option.label
                    }), selectedValue: prop(widget.id, this.props.selectedAttributes), availableValues: propOr([], widget.id, this.props.availableAttributes), isOnSelected: (unselectedAttr && unselectedAttr.indexOf(widget.title) > -1) ||
                        false, productVariant: productVariant }));
                break;
            default:
                return null;
        }
        return WidgetComponent;
    };
    render() {
        const { productTitle, productDescription, widgets, children, onChangeQuantity, cartQuantity, userAddress, baseProductRawId, countries, onChangeDeliveryData, deliveryData } = this.props;
        return (React.createElement(ProductContext.Consumer, null, ({ productVariant, rating }) => {
            const verifiedVariant = verifyItemCurrency(productVariant);
            return (React.createElement("div", { className: styles.container },
                React.createElement("h2", null, productTitle),
                React.createElement("div", { className: styles.rating },
                    React.createElement(Rating, { value: rating })),
                React.createElement(ProductPrice, { ...verifiedVariant }),
                React.createElement("p", { className: styles.productDescription }, productDescription),
                React.createElement(Delivery, { currency: verifiedVariant.currency, userAddress: userAddress, baseProductRawId: baseProductRawId, countries: countries, onChangeDeliveryData: onChangeDeliveryData, deliveryData: deliveryData }),
                React.createElement("div", { className: styles.widgets }, sortByProp('id')(widgets)
                    .map(this.generateWidget)),
                React.createElement(ProductQuantity, { quantity: productVariant.quantity, preOrder: productVariant.preOrder, preOrderDays: productVariant.preOrderDays, onChangeQuantity: onChangeQuantity, cartQuantity: cartQuantity }),
                children));
        }));
    }
}
export default ProductDetails;
