/**
 * @generated SignedSource<<43c73f034c3aad0aff1d026b46e10a3a>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "productsCost",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deliveryCost",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCost",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
    }, v6 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "Cart",
            "kind": "LinkedField",
            "name": "removeDeliveryMethodFromCart",
            "plural": false,
            "selections": [
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/),
                (v4 /*: any*/),
                (v5 /*: any*/),
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "couponsDiscounts",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CartStoresConnection",
                    "kind": "LinkedField",
                    "name": "stores",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "CartStoresEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CartStore",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v1 /*: any*/),
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        (v5 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "totalCostWithoutDiscounts",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "couponsDiscount",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "CartProduct",
                                            "kind": "LinkedField",
                                            "name": "products",
                                            "plural": true,
                                            "selections": [
                                                (v1 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "subtotal",
                                                    "storageKey": null
                                                },
                                                (v3 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "CompaniesPackages",
                                                    "kind": "LinkedField",
                                                    "name": "companyPackage",
                                                    "plural": false,
                                                    "selections": [
                                                        (v1 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "rawId",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "AvailablePackageForUser",
                                                    "kind": "LinkedField",
                                                    "name": "selectPackage",
                                                    "plural": false,
                                                    "selections": [
                                                        (v1 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "shippingId",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "RemoveDeliveryMethodForProductMutation",
            "selections": (v6 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "RemoveDeliveryMethodForProductMutation",
            "selections": (v6 /*: any*/)
        },
        "params": {
            "cacheID": "82f3b22f1c582839e717354944f20c4f",
            "id": null,
            "metadata": {},
            "name": "RemoveDeliveryMethodForProductMutation",
            "operationKind": "mutation",
            "text": "mutation RemoveDeliveryMethodForProductMutation(\n  $input: RemoveDeliveryMethodFromCartInput!\n) {\n  removeDeliveryMethodFromCart(input: $input) {\n    id\n    productsCost\n    deliveryCost\n    totalCost\n    totalCount\n    couponsDiscounts\n    stores {\n      edges {\n        node {\n          id\n          productsCost\n          deliveryCost\n          totalCost\n          totalCount\n          totalCostWithoutDiscounts\n          couponsDiscount\n          products {\n            id\n            subtotal\n            deliveryCost\n            companyPackage {\n              id\n              rawId\n            }\n            selectPackage {\n              id\n              shippingId\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "2e3ea399016bda57c8d8c1f1a9b1aada";
export default node;
